import Button from '@mui/material/Button';
import swal from 'sweetalert';
import { useState } from 'react';
import { updateContainerAll } from 'service/api';

export default function AddDetailContainerModal({ close, containerNumber, container_price, container_cbm, scanned_cbm, setUpdate }) {
  const [containerPrice, setContainerPrice] = useState(container_price.toLocaleString('en'));
  const [containerCbm, setContainerCbm] = useState(container_cbm.toLocaleString('en'));
  
  const handleEdit = async () => {
    let numCBm = containerCbm.replaceAll(',', '')
    let floatNumCBM = parseFloat(numCBm)
    let numPrice = containerPrice.replaceAll(',', '')
    let floatNumPrice = parseFloat(numPrice)

    const body = { 
      container: containerNumber,
      container_before: containerNumber,
      container_price: floatNumPrice,
      container_cbm: floatNumCBM,
    };
    
    const data = await updateContainerAll(JSON.stringify(body));
    if (data?.status === 200) {
      swal("Success", `Update container successfully`, "success");
      setUpdate((prev) => !prev);
      close()
    } else {
      swal("Failed", data?.message || 'Gagal update container!', "error");
    }
  };

  const set_ContainerCbm = (number) => {
    let num = number.replaceAll(',', '')
    const splitComma = num.split('.')
    const newValue = number.includes('.') ? (Number(splitComma[0])).toLocaleString('en') + "." + splitComma[1].substring(0,3) : (Number(splitComma[0])).toLocaleString('en')
    setContainerCbm(newValue)
  }

  const set_ContainerPrice = (number) => {
    let num = number.replaceAll(',', '')
    const splitComma = num.split('.')
    const newValue = number.includes('.') ? (Number(splitComma[0])).toLocaleString('en') + "." + splitComma[1].substring(0,3) : (Number(splitComma[0])).toLocaleString('en')
    setContainerPrice(newValue)
  }

  return (
    <div
      className={`overflow-y-auto overflow-x-hidden pt-6 fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal h-full bg-gray-800 bg-opacity-50`}>
      <div className='mx-auto mt-32 rounded-lg bg-white p-6 shadow-2xl w-96'>
        <div className='flex justify-end'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={1.5}
            stroke='currentColor'
            className='w-5 h-5 cursor-pointer text-gray-500/80'
            onClick={close}>
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
            />
          </svg>
        </div>
        <h2 className='text-lg font-bold text-center mt-4'>
          Add Detail Container
        </h2>

        <form
          action=''
          className='mx-auto mt-8 mb-0 max-w-md space-y-4'
          onSubmit={() => {}}>
          <div className='flex justify-between items-center gap-3'>
            <label className='whitespace-nowrap'>
              Container Cost
            </label>

            <div className='relative w-2/3 flex-shrink-0 flex-grow-0'>
              <input
                type="text"
                className='w-full rounded-md border-gray-500 p-4 pr-12 text-sm shadow-md'
                placeholder='Enter No. Container'
                value={containerPrice}
                onChange={(e) => set_ContainerPrice(e.target.value)}
              />
            </div>
          </div>
          <div className='flex justify-between items-center gap-3'>
            <label className='whitespace-nowrap'>
              Total CBM
            </label>

            <div className='relative w-2/3 flex-shrink-0 flex-grow-0'>
              <input
                type='text'
                className='w-full rounded-md border-gray-500 p-4 pr-12 text-sm shadow-md'
                placeholder='Enter No. Container'
                value={containerCbm}
                onChange={(e) => set_ContainerCbm(e.target.value)}
              />
              <div className="absolute right-3" style={{ top: '1.1rem' }}>M<sup>3</sup></div>
            </div>
          </div>
          <div className='flex justify-between items-center gap-3'>
            <label className='whitespace-nowrap'>
              Scanned CBM
            </label>

            <div className='w-2/3 flex-shrink-0 flex-grow-0 text-left px-4'>
              {scanned_cbm} M<sup>3</sup>
            </div>

          </div>
          <div className='flex items-center justify-center'>
              <Button type='button' variant='contained' color='success' onClick={handleEdit}>
                Edit
              </Button>
          </div>
        </form>
      </div>
    </div>
  );
}