import React from "react";
import { Redirect } from "react-router-dom";

const LogisticChina = () => {
  return (
    <>
      <Redirect to="logistic-china/received-items" />
    </>
  );
};

export default LogisticChina;
