import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow 
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { get_oci_academy_records } from 'service/marketing';
import CloseIcon from '@mui/icons-material/Close';
// import swal from 'sweetalert';
import PaginationFilter from 'components/General/PaginationFilter';
import CheckIcon from '@mui/icons-material/Check';
import moment from 'moment';

const OciAcademyTable = () => {
  const [dataRecords, setDataRecords] = useState(null)
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [filter, setFilter] = useState({
    by: "video",
    content: "",
    search: "",
    start_date: "",
    end_date: "",
    // start_date: moment().subtract(30, 'days').format('YYYY-MM-DD'),
    // end_date: moment().format('YYYY-MM-DD'),
  })
  
  const handleFilter = (e) => {
    const { name, value } = e.target;
    setFilter((prev) => {
      return { ...prev, [name]: value };
    });
    if (name === 'content') {
      setFilter((prev) => {
        return { ...prev, search: '', start_date: '', end_date: '' };
      });
    }
  }

  const getDataRecords = async () => {
    setLoading(true)
    const byParams = filter.by !== '' && filter.by !== '' ? `by=${filter.by}&` : '';
    let contentData = '';
    if (filter.by === 'video') {
      contentData = filter.search !== '' ? `email=${filter.search}&` : '';
    }
    const startParams = filter.start_date !== '' ? `start_date=${filter.start_date}&` : ``;
    const endParams = filter.end_date !== '' ? `end_date=${filter.end_date}&` : '';

    const params =
      `page=${page}&limit=${limit}&` +
      byParams +
      contentData +
      startParams +
      endParams

    const response = await get_oci_academy_records(params);
    if (response?.status === 200) {
      setDataRecords(response?.data)
      setTotalPage(response?.total_pages)
    }
    setLoading(false)
  };

  useEffect(() => {
    getDataRecords()
  }, [page, limit, filter.by])

  const maxDate = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split('T')[0];

  const set_Filter = (value) => {
    setPage(1)
    setFilter(prev => {return {...prev, by: value}})
  }

  const renderLoading = loading ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'start',
        padding: '10px',
        borderRadius: 2,
        backgroundColor: 'white',
        marginBottom: 1,
      }}>
      <div className="flex space-x-3 items-center w-full bg-blue-100 p-4 rounded-md">
        <CircularProgress size={20} />
        <p className="text-gray-500 text-sm ">Updating data ...</p>
      </div>
    </Box>
  ) : null;

  return (
    <div>
      {renderLoading}
      <div className="flex items-center gap-5 my-2">
        <div className={`${filter.by === 'video' ? 'bg-blue-500 text-white' : 'bg-white'} w-36 py-1 text-sm font-bold text-center rounded-md`}
        onClick={() => set_Filter('video')}
        >
          BY VIDEO
        </div>
        <div className={`${filter.by === 'customer' ? 'bg-blue-500 text-white' : 'bg-white'} w-36 py-1 text-sm font-bold text-center rounded-md`}
        onClick={() => set_Filter('customer')}
        >
          BY CUSTOMER
        </div>
      </div>
      {filter.by === 'video' && (
        <div className='bg-white my-2 p-2 rounded-sm flex justify-between items-center'>
          <div className='flex items-center gap-2'>
            {/* <select
              name="content"
              value={filter?.content}
              onChange={handleFilter}
              className="w-32 px-2 py-1 text-xs rounded-md focus:outline-blue border font-bold"
            >
              <option value="">Pilih</option>
              <option value="email">Email</option>
              <option value="telepon">Telepon</option>
              <option value="id_video">Id Video</option>
              <option value="judul_video">Judul Video</option>
            </select> */}
            <div className="text-xs">Search by Email:</div>
            <input
              name="search"
              type="text"
              value={filter?.search}
              onChange={handleFilter}
              className="px-2 py-1 rounded-md text-xs focus:outline-blue border"
            />
            <input
              type="text"
              placeholder="Start date"
              name="start_date"
              value={filter?.start_date}
              max={filter?.end_date || maxDate}
              onChange={handleFilter}
              className="text-xs px-2 py-1 rounded-md w-32 border border-gray-200 focus:outline-blue cursor-pointer"
              onFocus={(e) => (e.target.type = 'date')}
              onBlur={(e) => (e.target.type = 'text')}
            />
            <input
              type="text"
              name="end_date"
              className="text-xs px-2 py-1 rounded-md border border-gray-200 w-32 focus:outline-blue cursor-pointer"
              placeholder="End date"
              value={filter?.end_date}
              min={filter?.start_date}
              max={maxDate}
              onChange={handleFilter}
              onFocus={(e) => (e.target.type = 'date')}
              onBlur={(e) => (e.target.type = 'text')}
            />
            <div
              onClick={() => (filter?.content?.trim() !== "" && filter?.search?.trim() !== "") || 
                        (filter?.start_date?.trim() !== "" || filter?.end_date?.trim() !== "")
                        ? getDataRecords() : null
                      }
              className={` ${(filter?.content?.trim() !== "" && filter?.search?.trim() !== "") || 
                              (filter?.start_date?.trim() !== "" || filter?.end_date?.trim() !== "")
                              ? "bg-blue-300" : "bg-[#b7bdc7]"} flex justify-center items-center  text-white w-40 py-1 rounded-md`}
            >
              Filter
            </div>
          </div>
        </div>
      )}
      <div className='bg-white h-full'>
        {dataRecords?.length > 0 ?
          <>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {/* <TableCell style={{ fontWeight: 'bold' }}>ID Video</TableCell> */}
                    {filter.by === 'customer' &&
                      <TableCell style={{ fontWeight: 'bold' }}>Customer</TableCell>
                    }
                    {filter.by === 'video' &&
                      <TableCell style={{ fontWeight: 'bold' }}>Video</TableCell>
                    }
                    <TableCell style={{ fontWeight: 'bold' }}>Total Klik</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>

                  {dataRecords?.map((row, index) => (
                    <TableRow
                      key={index}
                    >
                      {/* <TableCell >{row.id}</TableCell> */}
                      {filter.by === 'customer' &&
                        <TableCell>
                          <div className='text-gray-500 font-bold cursor-pointer' onClick={() => {
                            setPage(1)
                            setFilter((prev) => {return {...prev, by: 'video', search: row.email}})
                          }}>
                            {row.email}
                          </div>
                        </TableCell>
                      }
                      {filter.by === 'video' &&
                        <TableCell>{row.title}</TableCell>
                      }
                      <TableCell >{row.klik}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
          :
          <div className='flex justify-center py-14 items-center w-full flex-col'>
            <img src="/oci-data-produk-tidakada.png" />
            <p className='bg-white text-center font-poppins font-semibold text-gray-700 '>
              Data tidak ditemukan
            </p>
          </div>
        }
        <div className='py-4 flex justify-end mx-2'>
          <PaginationFilter
            page={page}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            totalPage={totalPage}
          />
        </div>
      </div>
    </div>
  )
}

export default OciAcademyTable