import Layout from 'components/Layout';
import { SubRoutesMarketing } from 'components/Marketing/SubRoutesMarketing';
import OciAcademyTable from 'components/Marketing/OciAcademyTable';

const OciAcademy = () => {
  return (
    <Layout routes={SubRoutesMarketing()} title="Marketing">
      <OciAcademyTable />
    </Layout>
  );
};

export default OciAcademy;
