import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import Cookies from "js-cookie";
import Lightbox from "react-image-lightbox";
import { CircularProgress } from "@mui/material";

const ModalUploadPaste = (props) => {
  const {
    fileUpload,
    setFileUpload,
    setShowModalUpload,
    fileUploadBlob,
    setFileUploadBlob,
    getDetailMessage,
    setGlobalMessages,
    pdf,
    setPdf,
  } = props;
  const [images, setImages] = useState(null);
  const [editor, setEditor] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [volume, setVolume] = useState(null);
  const [telpon, setTelpon] = useState("");
  const [openLightbox, setOpenLightbox] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    msg: "Ukuran gambar terlalu besar (maksimal 2MB).",
    error: true,
  });

  console.log('volume', volume)

  useEffect(() => {
    if (fileUploadBlob) {
      setVolume(fileUploadBlob);
    }
  }, [fileUploadBlob]);

  const sendMessage = async (e) => {
    // setIsLoading(true);
    e.preventDefault();
    const randomId = (Math.floor(Math.random() * (999999 - 100000 + 1) + 100000)).toString()
    if (fileUploadBlob.type === "image/jpeg") {
      const getTelpon = localStorage.getItem("no-telpon");
      const telpon = JSON.parse(getTelpon);
      var myHeaders = new Headers();
      const Xid = Cookies.get("Session_id");
      const tokenCookies = Cookies.get("oms_token");
      myHeaders.append("Authorization", `Bearer ${tokenCookies}`);
      setGlobalMessages((prev) => [{
        id: randomId,
        phone: telpon,
        from: "server",
        to: telpon,
        user: "",
        body: editor,
        // not_sent: true,
        is_read: false,
        date: new Date().toISOString(),
        media: {
            id: "",
            id_wa: "",
            file_name: fileUpload,
            mime: "." + volume.name.split('.').pop()
        }
      }, ...prev]);
      myHeaders.delete("Content-Type", "multipart/form-data");
      var formdata = new FormData();
      formdata.append("phone", telpon);
      formdata.append("file", volume);
      formdata.append("body", editor);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };
      setLoading(true);
      fetch(
        `${process.env.REACT_APP_URL_API_WA_CHAT}/send-message-image`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          if (result.status === 200) {
            swal("Success Upload Files", "Success");
            getDetailMessage();
          }
          setSelectedImage("");
          setErrorMessage({
            error: true,
          });
        })
        .catch((error) => console.log("error", error))
        .finally(() => {
          setLoading(false);
          setShowModalUpload(false);
          setFileUpload("");
        });
    } else if (
      fileUploadBlob.type === "application/pdf" ||
      fileUploadBlob.type === "application/pdf" ||
      fileUploadBlob.type === "application/msword" ||
      fileUploadBlob.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      fileUploadBlob.type === "application/vnd.ms-powerpoint" ||
      fileUploadBlob.type ===
        "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
      fileUploadBlob.type === "text/csv" ||
      fileUploadBlob.type === "application/vnd.ms-excel" ||
      fileUploadBlob.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      fileUploadBlob.type === "video/mp4"
    ) {
      const getTelpon = localStorage.getItem("no-telpon");
      const telpon = JSON.parse(getTelpon);
      var myHeaders = new Headers();
      const Xid = Cookies.get("Session_id");
      const tokenCookies = Cookies.get("oms_token");
      myHeaders.append("Authorization", `Bearer ${tokenCookies}`);
      setGlobalMessages((prev) => [{
        id: randomId,
        phone: telpon,
        from: "server",
        to: telpon,
        user: "",
        body: editor,
        // not_sent: true,
        is_read: false,
        date: new Date().toISOString(),
        media: {
            id: "",
            id_wa: "",
            file_name: fileUpload,
            mime: "." + volume.name.split('.').pop()
        }
      }, ...prev]);
      var formdata = new FormData();
      formdata.append("phone", telpon);
      formdata.append("file", volume);
      formdata.append("body", editor);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };
      setLoading(true);
      fetch(
        `${process.env.REACT_APP_URL_API_WA_CHAT}/send-message-doc`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          if (result.status === 200) {
            swal("Success Upload Files", "Success");
          }
          setErrorMessage({
            error: true,
          });
          getDetailMessage();
          setLoading(false);
        })
        .catch((error) => console.log("error", error))
        .finally(() => {
          setLoading(false);
          setShowModalUpload(false);
          setFileUpload("");
        });
    } else if (
      fileUploadBlob.type === "video/mp4" ||
      fileUploadBlob.type === "video/x-m4v"
    ) {
      const getTelpon = localStorage.getItem("no-telpon");
      const telpon = JSON.parse(getTelpon);
      var myHeaders = new Headers();
      const Xid = Cookies.get("Session_id");
      const tokenCookies = Cookies.get("oms_token");
      myHeaders.append("Authorization", `Bearer ${tokenCookies}`);
      myHeaders.delete("Content-Type", "multipart/form-data");
      setGlobalMessages((prev) => [{
        id: randomId,
        phone: telpon,
        from: "server",
        to: telpon,
        user: "",
        body: editor,
        // not_sent: true,
        is_read: false,
        date: new Date().toISOString(),
        media: {
            id: "",
            id_wa: "",
            file_name: fileUpload,
            mime: "." + volume.name.split('.').pop()
        }
      }, ...prev]);
      var formdata = new FormData();
      formdata.append("phone", telpon);
      formdata.append("file", volume);
      formdata.append("body", editor);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };
      setLoading(true);
      fetch(
        `${process.env.REACT_APP_URL_API_WA_CHAT}/send-message-video`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          if (result.status === 200) {
            swal("Success Upload Files", "Success");
            getDetailMessage();
          }
          setSelectedImage("");
          setErrorMessage({
            error: true,
          });
        })
        .catch((error) => console.log("error", error))
        .finally(() => {
          setLoading(false);
          setShowModalUpload(false);
          setFileUpload("");
        });
    } else if (fileUploadBlob.type === "audio/wav") {
      const getTelpon = localStorage.getItem("no-telpon");
      const telpon = JSON.parse(getTelpon);
      var myHeaders = new Headers();
      const Xid = Cookies.get("Session_id");
      const tokenCookies = Cookies.get("oms_token");
      myHeaders.append("Authorization", `Bearer ${tokenCookies}`);
      myHeaders.delete("Content-Type", "multipart/form-data");
      setGlobalMessages((prev) => [{
        id: randomId,
        phone: telpon,
        from: "server",
        to: telpon,
        user: "",
        body: editor,
        // not_sent: true,
        is_read: false,
        date: new Date().toISOString(),
        media: {
            id: "",
            id_wa: "",
            file_name: fileUpload,
            mime: "." + volume.name.split('.').pop()
        }
      }, ...prev]);
      var formdata = new FormData();
      formdata.append("phone", telpon);
      formdata.append("file", volume);
      formdata.append("body", editor);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };
      setLoading(true);
      fetch(
        `${process.env.REACT_APP_URL_API_WA_CHAT}/send-message-audio`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          if (result.status === 200) {
            swal("Success Upload Files", "Success");
            getDetailMessage();
          }
          setSelectedImage("");
          setErrorMessage({
            error: true,
          });
        })
        .catch((error) => console.log("error", error))
        .finally(() => {
          setLoading(false);
          setShowModalUpload(false);
          setFileUpload("");
        });
    }
  };

  const handleClose = () => {
    setShowModalUpload(false);
    setFileUpload("");
    setFileUploadBlob(null);
  };

  return (
    <div className="bg-gray-100 w-full">
      <div className="px-10 py-3">
        <div
          className="hover:bg-gray-50 w-12 h-12 flex justify-center items-center rounded-full cursor-pointer z-60"
          onClick={handleClose}>
          X
        </div>
      </div>
      <div className="flex items-center justify-between flex-col h-3/4">
        <div className="flex flex-col  w-3/4 items-center justify-center">
          <div className="mb-10 w-[400px] h-[500px] flex items-center justify-center">
            {loading ? (
              <div className="w-full h-full my-10 flex items-center justify-center text-white ">
                <CircularProgress size={30} className="text-white" />
              </div>
            ) : (
              <>
                {fileUploadBlob.type === "image/jpeg" ? (
                  <>
                    {openLightbox === fileUpload && (
                      <Lightbox
                        mainSrc={fileUpload}
                        onCloseRequest={() => setOpenLightbox(false)}
                      />
                    )}
                    <img
                      onClick={() => setOpenLightbox(fileUpload)}
                      src={fileUpload}
                      className="object-contain max-w-[500px]  max-h-[500px]"
                    />
                  </>
                ) : fileUploadBlob.type === "application/pdf" ? (
                  <div className="flex">
                    <img src={`${process.env.REACT_APP_URL_FOTO_STORAGE}/pdf-logo-oms.png`} />
                    <div className="flex items-end">
                      <p>{fileUploadBlob.name}</p>
                    </div>
                  </div>
                ) : fileUploadBlob.type === "application/msword" ||
                  fileUploadBlob.type ===
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                  <div className="flex">
                    <img src={`${process.env.REACT_APP_URL_FOTO_STORAGE}/logo-ocs-word.png`} />
                    <div className="flex items-end">
                      <p>{fileUploadBlob.name}</p>
                    </div>
                  </div>
                ) : fileUploadBlob.type === "application/vnd.ms-powerpoint" ||
                  fileUploadBlob.type ===
                    "application/vnd.openxmlformats-officedocument.presentationml.presentation" ? (
                  <div className="flex">
                    <img src={`${process.env.REACT_APP_URL_FOTO_STORAGE}/logo-ocs-ptt.png`} />
                    <div className="flex items-end">
                      <p>{fileUploadBlob.name}</p>
                    </div>
                  </div>
                ) : fileUploadBlob.type === "text/csv" ||
                  fileUploadBlob.type === "application/vnd.ms-excel" ||
                  fileUploadBlob.type ===
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? (
                  <div className="flex">
                    <img src={`${process.env.REACT_APP_URL_FOTO_STORAGE}/logo-ocs-xls.png`} />
                    <div className="flex items-end">
                      <p>{fileUploadBlob.name}</p>
                    </div>
                  </div>
                ) : fileUploadBlob.type === "video/mp4" ? (
                  <div className="flex">
                    <video className="max-w-[500px]  max-h-[500px]" controls>
                      <source src={fileUpload} type="video/mp4" />
                    </video>
                  </div>
                ) : fileUploadBlob.type === "audio/wav" ? (
                  <div className="flex">
                    <audio className="max-w-[500px]  max-h-[500px]" controls>
                      <source src={fileUpload} type="video/mp4" />
                    </audio>
                  </div>
                ) : null}
              </>
            )}
          </div>
        </div>
        <div className="w-3/4 mx-20 mb-10">
          <form onSubmit={(e) => sendMessage(e)}>
            <label for="chat" className="sr-only">
              Your message
            </label>
            <div className="flex items-center px-3 py-2 rounded-lg bg-gray-50 dark:bg-gray-700">
              {/* <button
                  type="button"
                  className="inline-flex justify-center p-2 text-gray-500 rounded-lg cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600">
                  <svg
                    className="w-5 h-5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 18">
                    <path
                      fill="currentColor"
                      d="M13 5.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0ZM7.565 7.423 4.5 14h11.518l-2.516-3.71L11 13 7.565 7.423Z"
                    />
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M18 1H2a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1Z"
                    />
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M13 5.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0ZM7.565 7.423 4.5 14h11.518l-2.516-3.71L11 13 7.565 7.423Z"
                    />
                  </svg>
                  <span className="sr-only">Upload image</span>
                </button>
                <button
                  type="button"
                  className="p-2 text-gray-500 rounded-lg cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600">
                  <svg
                    className="w-5 h-5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20">
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M13.408 7.5h.01m-6.876 0h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM4.6 11a5.5 5.5 0 0 0 10.81 0H4.6Z"
                    />
                  </svg>
                  <span className="sr-only">Add emoji</span>
                </button> */}
              <textarea
                id="chat"
                onChange={(e) => setEditor(e.target.value)}
                rows="1"
                className="block mx-4 p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Type message..."></textarea>
              <button
                type="submit"
                className="inline-flex justify-center p-2 text-blue-600 rounded-full cursor-pointer hover:bg-blue-100 dark:text-blue-500 dark:hover:bg-gray-600">
                <svg
                  className="w-5 h-5 rotate-90"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 18 20">
                  <path d="m17.914 18.594-8-18a1 1 0 0 0-1.828 0l-8 18a1 1 0 0 0 1.157 1.376L8 18.281V9a1 1 0 0 1 2 0v9.281l6.758 1.689a1 1 0 0 0 1.156-1.376Z" />
                </svg>
                <span className="sr-only">Send message</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ModalUploadPaste;
