import { useState } from "react";
import { Box, Collapse, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DetailModal from "components/General/DetailOrderModal/DetailModal";
import BadgeLevel from "components/General/BadgeLevel";
import Notes from "components/General/Notes";
// import GenerateBarcode from "./GenerateBarcode";
import EditRepackingInput from "../Repacking/EditRepackingInput";
import { getUser } from "helpers/parseJWT";
// import DetailListBoxTable from "./DetailListBoxTable";
import DetailListContainerTableV2 from "./DetailListContainerTableV2";
import GenerateBarcodeV2 from "./GenerateBarcodeV2";
import EditMarkingCodeModal from "./EditMarkingCodeModal";

export default function DetailListBoxTableV2({ dataOrder, setUpdate, isLoading, highlightIdSo, }) {
  const Row = ({ rowData, id }) => {
    const [open, setOpen] = useState(false);
    return (
      <>
        <TableRow style={{ display: 'flex', backgroundColor: ~~highlightIdSo === ~~rowData.id_order ? '#f0f0f0' : '' }}>
          <TableCell
            align="center"
            style={{
              width: "15%",
            }}>
            <div className="text-sm flex h-full flex-col justify-between">
              <div>
                <div className="flex items-center space-x-2">
                  <DetailModal id={rowData.id_order} />
                </div>
              </div>
              <div className="text-center">
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => {
                    setOpen(!open);
                  }}>
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
                <span className="text-xs">Details</span>
              </div>
            </div>
          </TableCell>
          <TableCell
            align="center"
            style={{
              width: "10%",
            }}>
            {rowData.total_carton}
          </TableCell>
          <TableCell
            align="center"
            style={{
              width: "15%",
            }}>
            {rowData.total_volume.toFixed(6)}
          </TableCell>
          <TableCell
            align="center"
            style={{
              width: "10%",
            }}>
            {getUser().roles?.includes('admin') ?
              <EditMarkingCodeModal rowData={rowData} setUpdate={setUpdate} />
              : "-"
            }
          </TableCell>
          <TableCell
            align="center"
            style={{
              width: "10%",
            }}>
            <GenerateBarcodeV2 rowData={rowData} />
          </TableCell>
          <TableCell
            align="center"
            style={{
              width: "20%",
            }}>
            {rowData.Status}
          </TableCell>
          <TableCell
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              width: '20%',
            }}>
            <div className="space-y-2 mx-3">
              <Notes id={rowData.id_order} setUpdate={setUpdate} />
              {getUser().roles?.includes('admin') &&
                <EditRepackingInput
                  RowCategory={rowData?.kategori}
                  id_so={rowData.id_order}
                  id_po={rowData.id_po}
                  products={rowData.order}
                  setUpdate={setUpdate}
                  totalQty={rowData.totalQty}
                  totalBox={rowData.totalBox}
                />
              }
            </div>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{  }}>
                <DetailListContainerTableV2
                  dataOrder={rowData}
                  id_so={rowData.id_order}
                  setUpdate={setUpdate}
                  isLoading={isLoading}
                />
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };
  
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow style={{ display: "flex" }}>
            <TableCell
              align="center"
              style={{
                width: "15%",
              }}>
              ID Order
            </TableCell>
            <TableCell
              align="center"
              style={{
                width: "10%",
              }}>
              Carton
            </TableCell>
            <TableCell
              align="center"
              style={{
                width: "15%",
              }}>
              Volume (M<sup>3</sup>)
            </TableCell>
            <TableCell
              align="center"
              style={{
                width: "10%",
              }}>
              Edit
            </TableCell>
            <TableCell
              align="center"
              style={{
                width: "10%",
              }}>
              Barcode
            </TableCell>
            <TableCell
              align="center"
              style={{
                width: "20%",
              }}>
              Status
            </TableCell>
            <TableCell
              align="center"
              style={{
                width: "20%",
              }}>
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={5} align="center" style={{ height: "50vh" }}>
                Loading...
              </TableCell>
            </TableRow>
          ) : dataOrder?.detail?.length > 0 ? (
            <>
              {dataOrder?.detail?.map((row, index) => (
                <Row rowData={row} key={index} id={index} />
              ))}
            </>
          ) : (
            <TableRow>
              <TableCell colSpan={5} align="center" style={{ height: "50vh" }}>
                No data available
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
