import ChatRoom from "./ChatRoom";
import SearchIcon from "@mui/icons-material/Search";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  getListWa,
  getListDetailWa,
  sendChatWa,
  getSalesOCS,
  getListSalesOCS,
  getRoomsSocket,
  createRoomsSocket,
} from "service/api";
import swal from "sweetalert";
import { formateDateDDMMYYhhss, formateHHSS } from "helpers/ConvertTime";
import { Editor } from "@tinymce/tinymce-react";
import FilterDate from "./FilterDate";
import { Box, CircularProgress, Tooltip } from "@mui/material";
import ChatEditorOCS from "./ChatEditorOCS";
import Lightbox from "react-image-lightbox";
import MenuRead from "./MenuRead";
import ModalOCSImage from "./ModalOCSImage";
import ModalOCSVideo from "./ModalOCSVideo";
import ModalSearch from "./ModalSearch";
import { getUser } from "helpers/parseJWT";
import { useLocation } from "react-router-dom";
import ModalOCSDoc from "./ModalOCSDoc";
import ModalOCSAudio from "./ModalOCSAudio";
import ModalUploadPaste from "./ModalUploadPaste";
import MenuDetailUser from "./MenuDetailUser";
import { useDispatch } from "react-redux";
import { triggerTelp } from "redux/dataTelponSlice";
import CryptoJS from "crypto-js";
import { useRecoilState } from "recoil";
import { triggerState } from "atom";
import { getNotifState } from "atom";
import { getRoomState } from "atom";


const useCountdown = (initialCountdown) => {
  const [countdown, setCountdown] = useState(initialCountdown);
  const [isCounting, setIsCounting] = useState(false);

  const startCountdown = () => {
    setIsCounting(true);
    setCountdown(initialCountdown);

    setTimeout(() => {
      setIsCounting(false);
    }, 2000);
  };

  useEffect(() => {
    let countdownInterval;

    if (isCounting) {
      countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }

    return () => clearInterval(countdownInterval);
  }, [isCounting, initialCountdown]);

  return { countdown, isCounting, startCountdown };
};

function Main() {
  const location = useLocation();
  const [getData, setGetData] = useState();
  const [noTelpon, setNotelpon] = useState("");
  const [saveMessages, setSaveMessages] = useState(null);
  const [globalMessages, setGlobalMessages] = useState(null);
  const [notif, setNotif] = useState(null);
  const [read, setRead] = useState([]);
  const [editor, setEditor] = useState("");
  const [content, setContent] = useState("all");
  const [activ, setActiv] = useState(false);
  const [sales, setSales] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingWa, setIsLoadingWa] = useState(false);
  const [open, setOpen] = useState(false);
  const [openVid, setOpenVid] = useState(false);
  const [openDoc, setOpenDoc] = useState(false);
  const [openAudio, setOpenAudio] = useState(false);
  const [search, setSearch] = useState("");
  const [volume, setVolume] = useState(null);
  const [fileUpload, setFileUpload] = useState("");
  const [pdf, setPdf] = useState("");
  const [fileUploadBlob, setFileUploadBlob] = useState("");
  const [showModalUpload, setShowModalUpload] = useState(false);
  const [showMenuBar, setShowMenuBar] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [rooms, setRooms] = useState(null);
  const [parameter, setParameter] = useState("");
  const refAttachmenu = useRef();
  const [showAttachmentMenu, setShowAttachmentMenu] = useState(false);
  const [triggerRooms, setTriggerRoom] = useState(false);
  const [trigger, setTrigger] = useRecoilState(triggerState);
  const [getRoom, setGetRoom] = useRecoilState(getRoomState);
  const dispatch = useDispatch();
  const [date, setDate] = useState({
    sales: "",
    start: "",
    end: "",
    status: "",
    type: "",
    id: "",
  });

  const { countdown, isCounting, startCountdown } = useCountdown(10);

  // const getRoomsSocketJs = async () => {
  //   const response = await getRoomsSocket();
  //   // setRooms(response?.collection);
  //   setGetRoom(response?.collection)
  // };

  // useEffect(() => {
  //   getRoomsSocketJs();
  // }, [triggerRooms]);

  const createRoomSocketJs = async (telpon) => {
    if (telpon) {
      const phoneNumber = telpon;
      const modifiedPhoneNumber = phoneNumber.replace(/^0/, "62");

      const payload = {
        id: modifiedPhoneNumber,
        name: modifiedPhoneNumber,
      };
      const response = await createRoomsSocket(JSON.stringify(payload));
      if (response.status === 200) {
        console.log("sukses");
      }
    }
  };

  // const fetchWebsocket = () => {
  //   const storedData = localStorage.getItem("no-telpon");
  //   const data = JSON.parse(storedData);
  //   const connection = new WebSocket(
  //     `${
  //       process.env.REACT_APP_URL_API_GET_LIST_WA_SOCKET
  //     }/ws/joinRoom/${data}?username=${getUser().user}&userId=${getUser().user}`
  //   );

  //   connection.onopen = (event) => {
  //     console.log("Koneksi WebSocket terbuka");
  //   };

  //   // Menangani event saat pesan diterima
  //   connection.onmessage = function (event) {
  //     const data = JSON.parse(event.data);
  //     setMessageSocket(data);
  //     console.log(data, "cek data");
  //   };

  //   connection.onclose = function (event) {
  //     console.error('Socket disconnect: ', event);
  //     setTriggerReConnect(prev => !prev)
  //   }
  //   connection.onerror = function (err) {
  //     console.error('Socket error: ', err);
  //     setTriggerReConnect(prev => !prev)
  //   }
  //   return () => {
  //     connection.close();
  //   };
  // };

  useEffect(() => {
    // fetchWebsocket();
    if (
      volume !== null &&
      volume !== undefined &&
      volume[0] !== null &&
      volume[0] !== undefined &&
      volume[0].trim() !== ""
    ) {
      const dataObject = JSON.parse(volume[0]);
      setParameter(dataObject);
    } else {
      console.log("volume is null, undefined, or empty");
    }
  }, [noTelpon, volume]);

  // useEffect(() => {
  //   if (messageSocket?.data) {
  //     const newDataWebsocket = {
  //       message: messageSocket.data,
  //     };

  //     // setGlobalMessages((prev) => {
  //     //   if (prev && prev.phone) {
  //     //     const phoneNumber = prev.phone;
  //     //     console.log(phoneNumber, 'phoneNumber')
  //     //     const modifiedPhoneNumber = phoneNumber.replace(/^0/, "62");

  //     //     if (modifiedPhoneNumber === messageSocket.data.phone) {
  //     //       return {
  //     //         ...prev,
  //     //         messages: [...prev.messages, messageSocket.data]
  //     //       };
  //     //     }
  //     //   }

  //     //   return prev;
  //     // });

  //     setGlobalMessages((prev) => prev.filter((chat) => !chat.not_sent));
  //     setGlobalMessages((prev) => {
  //       console.log('cek 123', prev)
  //       const NoTelp = localStorage.getItem("no-telpon");
  //       const dataTelp = JSON.parse(NoTelp);
  //       if (messageSocket.roomId === dataTelp ) {
  //         return [messageSocket.data, ...prev];
  //       } else {
  //         return prev;
  //       }
  //     });
  //     // setSaveMessages((prev) => {
  //     //   return {
  //     //     ...prev,
  //     //     data: prev?.data?.map((item) => {
  //     //       const phoneNumber = item.phone;
  //     //       const modifiedPhoneNumber = phoneNumber.replace(/^0/, "62");
  //     //       if (modifiedPhoneNumber === messageSocket.data.phone) {
  //     //         return {
  //     //           ...item,
  //     //           message: [...(item?.message ?? []), messageSocket.data],
  //     //         };
  //     //       }
  //     //       return item;
  //     //     }),
  //     //   };
  //     //   return prev;
  //     // });
  //     // setGlobalMessages((prev) => [messageSocket.data, ...prev]);
  //     // setGlobalMessages((prev) => {
  //     //   console.log(prev)
  //     //   if (prev && prev.phone) {
  //     //     const phoneNumber = prev.phone;
  //     //     const modifiedPhoneNumber = phoneNumber.replace(/^0/, "62");
  //     //     if (modifiedPhoneNumber === messageSocket.data.phone) {
  //     //       return {
  //     //         ...prev,
  //     //         message: [...(prev ?? []), messageSocket.data],
  //     //       };
  //     //     }
  //     //   }
  //     //   return prev; // Kembalikan prev as is jika tidak ada perubahan yang diperlukan
  //     // });

  //     // setGlobalMessage(prev => ({
  //     //   ...prev,
  //     //   data: prev.data.map(item => {
  //     //     const phoneNumber = item.phone;
  //     //     const modifiedPhoneNumber = phoneNumber.replace(/^0/, "62");
  //     //     if (modifiedPhoneNumber === messageSocket.data.phone) {
  //     //       return {
  //     //         ...item,
  //     //         message: [...item.message, messageSocket.data]
  //     //       };
  //     //     }
  //     //     return item;
  //     //   })
  //     // }));

  //     // setVolume((prev) => {
  //     //   // const phoneNumber = prev.phone
  //     //   // const modifiedPhoneNumber = phoneNumber.replace(/^0/, "62");
  //     //     return [
  //     //       ...prev,
  //     //       messageSocket.data
  //     //     ]
  //     // })
  //   }
  // }, [messageSocket, noTelpon]);

  // useEffect(() => {
  //   const newDataWebsocket = {
  //     message: messageSocket.data
  //   }
  //   setVolume((prev) => {
  //     return [
  //       ...prev,
  //       newDataWebsocket
  //     ]
  //   })
  // }, [messageSocket]);

  // const mergeArrayChat = () => {
  //   console.log(saveMessages, 'cek SAVE MESSAGE')
  //   const respon = saveMessages?.data?.flatMap((item) => {
  //     return item?.message?.map((msg) => {
  //       return {
  //         message: msg,
  //       };
  //     });
  //   });

  //   setVolume(respon);
  // };
  // useEffect(() => {
  //   mergeArrayChat();
  // }, [noTelpon]);

  const fetchMessage = async () => {
    const response = await getListWa();
    if (response?.status === 200) {
      setGetData(response?.data);
    } else if (response?.status === 403) {
      swal("Oops", `${response?.message}`, "error");
    }
  };

  // const memoizedParams = useMemo(() => {
  //   const contens = content !== "" ? `is_read=${content}&` : "";
  //   const salesParams =
  //     date.sales !== "" ? `is_read=${content}&user=${date.sales}&` : "";
  //   return salesParams + contens;
  // }, [content, date.sales]);

  // const fetchReadUnread = async () => {
  //   setIsLoadingWa(true);
  //   const response = await getReadUnread(memoizedParams);
  //   if (response?.status === 200) {
  //     setRead(response?.data);
  //     setNotif(response);
  //   } else if (response?.status === 403) {
  //     swal("Oops", `${response?.message}`, "error");
  //   }
  //   setIsLoadingWa(false);
  // };

  // const fetchReadUnread = async () => {
  //   setIsLoadingWa(true);
  //   const contens =
  //     content !== "" ? `is_read=${content}&` : "";
  //   const salesParams =
  //     date.sales !== "" ? `is_read=${content}&user=${date.sales}&` : "";

  //   const params = salesParams + contens;
  //   const response = await getReadUnread(params);
  //   if (response?.status === 200) {
  //     setRead(response?.data);
  //     setNotif(response);
  //   } else if (response?.status === 403) {
  //     swal("Oops", `${response?.message}`, "error");
  //   }
  //   setIsLoadingWa(false);
  // };
  useEffect(() => {
    const secretKey = "OcistokPasswordKeysCrypter"

    // const searchParams = new URLSearchParams(location.search);
    // const phoneNumber = searchParams.get("number");
    // localStorage.setItem("no-telpon", JSON.stringify(phoneNumber));
    // setParameter(phoneNumber);

    const searchParams = new URLSearchParams(location.search);
    const numberParam = searchParams.get('number');
    let decrypted = null; // Inisialisasi dengan null.
    
    if (numberParam) {
      const encoded = numberParam.replace(/p1L2u3S/g, '+').replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=');
      decrypted = CryptoJS.AES.decrypt(encoded, secretKey).toString(CryptoJS.enc.Utf8);
    }
    
    localStorage.setItem("no-telpon", JSON.stringify(decrypted));
    setParameter(decrypted);

    const contentsParam = searchParams.get("contents");
    if (contentsParam !== null) {
      setContent(contentsParam);
    }
  }, [location]);


  // useEffect(() => {
  //   const searchParams = new URLSearchParams(location.search);
  //   const phoneNumber = searchParams.get("number");
  //   setParameter(phoneNumber);
  //   localStorage.setItem("no-telpon", JSON.stringify(phoneNumber));
  //   const contentsParam = searchParams.get("contents");
  //   if (contentsParam !== null) {
  //     setContent(contentsParam);
  //   }
  // }, [location]);

  const handleNotelpon = async (telpon) => {
    setNotelpon(telpon);
    const url = new URL(window.location.href);
    url.searchParams.delete("number");
    url.searchParams.delete("contents");
    window.history.replaceState(null, "", url.toString());
    dispatch(triggerTelp({ menu: telpon }));
    setVolume(telpon);
    setShowSearch(false);
    setShowModalUpload(false);
    setTrigger(prev => !prev)
    setFileUpload("");
    setShowMenuBar(false);
    localStorage.setItem("no-telpon", JSON.stringify(telpon));
  };

  const getDetailMessage = async () => {
    setIsLoading(true);
    // fetchMessage();
    const storedData = localStorage.getItem("no-telpon");
    const data = JSON.parse(storedData);
    // getRoomsSocketJs()
    // if (rooms?.length) {
      const existingRoom = getRoom?.find((room) => room?.id === data);
      if (existingRoom) {
        // fetchWebsocket();
      } else {
        // setTriggerRoom(prev => !prev)
        await createRoomSocketJs(data);
        console.log('create rooms')
        // await fetchWebsocket();
      }
    // } else {
    //   console.log("tidak ada room");
    // }

    // if (data) {
    //   const response = await getListDetailWa(data);
    // } else {
    //   console.log("tidak ada room");
    // }

    if (data) {
      const response = await getListDetailWa(data);

      if (response.status === 200) {
        const Messages = response.data[0].message;
        const reverseChat = Messages?.reverse();
        setSaveMessages(response);
        setGlobalMessages(reverseChat);

        // setGlobalMessage(reversedList);
        const respon = response?.data?.flatMap((item) => {
          return item?.message?.map((msg) => {
            return msg.phone;
          });
        });

        setVolume(respon);
      } else {
        console.log("data tidak ada");
      }
    }

    setIsLoading(false);
  };

  const handleSearch = useCallback((e) => {
    setSearch(e);
  });

  const getSales = async () => {
    setIsLoading(true);
    const response = await getSalesOCS();
    if (response.status === 200) {
      setSales(response?.data);
    }
    setIsLoading(false);
  };

  const fetchData = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const salesParams =
      date.sales !== "" ? `is_read=${content}&user=${date.sales}&` : "";
    // const startParams = date.start !== "" ? `start=${date.start}&` : "";
    // const endParams = date.end !== "" ? `end=${date.end}&` : "";
    // const statusParams = date.status !== '' ? `status=${date.status}&` : '';
    // const idParams = date.id !== '' ? `id=${date.id}&` : '';

    const params = salesParams;
    // statusParams +
    // idParams;

    const response = await getListSalesOCS(params);
    if (response?.status === 200) {
      setRead(response?.data);
    } else if (response?.status === 500) {
      swal("Oops", response?.message || "There's a problem occured. Please try again in 15 minutes or contact IT", "error");
    } else {
      swal("Oops", `${response?.message}`, "error");
    }
    setIsLoading(false);
  };

  const handleChatEditor = (e) => {
    setEditor(e.target.value);
  };

  const submitMessage = async (value, setValue) => {
    const storedData = localStorage.getItem("no-telpon");
    const data = JSON.parse(storedData);
    const phoneNumber = data;
    const modifiedPhoneNumber = phoneNumber.replace(/^0/, "62");
    const randomId = Math.floor(
      Math.random() * (999999 - 100000 + 1) + 100000
    ).toString();

    setGlobalMessages((prev) => [
      {
        id: randomId,
        phone: modifiedPhoneNumber,
        from: "server",
        to: modifiedPhoneNumber,
        user: "",
        body: value,
        is_read: false,
        not_sent: true,
        date: new Date().toISOString(),
        media: {
          id: "",
          id_wa: "",
          file_name: "",
          mime: "",
        },
      },
      ...prev,
    ]);

    const payload = {
      to: modifiedPhoneNumber,
      body: value,
    };
    setValue("");
    const response = await sendChatWa(JSON.stringify(payload));
    // setGlobalMessages((prev) => prev.filter((chat) => !chat.not_sent));
    if (response.status === 200) {
      fetchMessage();
      startCountdown()
      // setGlobalMessages((prev) =>
      // {return prev.map((chat) => {
      //   if(chat.id === randomId) {
      //     delete chat.not_sent
      //   }
      //   return chat
      // })
      // });
    }
  };

  const submitMessageKeyDown = async (event, value, setValue) => {
    if (event.key === "Enter" && !event.shiftKey) {
      setValue("");
      const storedData = localStorage.getItem("no-telpon");
      const data = JSON.parse(storedData);
      const phoneNumber = data;
      const modifiedPhoneNumber = phoneNumber.replace(/^0/, "62");
      const randomId = Math.floor(
        Math.random() * (999999 - 100000 + 1) + 100000
      ).toString();

      setGlobalMessages((prev) => [
        {
          id: randomId,
          phone: modifiedPhoneNumber,
          from: "server",
          to: modifiedPhoneNumber,
          user: "",
          body: value,
          is_read: false,
          not_sent: true,
          date: new Date().toISOString(),
          media: {
            id: "",
            id_wa: "",
            file_name: "",
            mime: "",
          },
        },
        ...prev,
      ]);

      const payload = {
        to: modifiedPhoneNumber,
        body: value,
      };

      const response = await sendChatWa(JSON.stringify(payload));
      if (response.status === 200) {
        fetchMessage();
        startCountdown()
        // getDetailMessage();
      }
    }
 
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        refAttachmenu.current &&
        !refAttachmenu.current.contains(event.target)
      ) {
        setShowAttachmentMenu(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [refAttachmenu]);

  useEffect(() => {
    getSales();
    getDetailMessage();
  }, [noTelpon]);

  // useEffect(() => {
  //   fetchReadUnread();
  // }, [content, date, notif?.status]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (isLoadingWa === false) {
  //       fetchReadUnread();
  //     }
  //   }, 3000);
  //   // Clear the interval
  //   return () => clearInterval(interval);
  // });

  const renderLoading = isLoading ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "start",
        padding: "10px",
        borderRadius: 2,
        backgroundColor: "white",
        marginBottom: 1,
      }}></Box>
  ) : null;

  return (
    <div className="w-full">
      {/* <FilterDate
        fetchData={fetchData}
        filterSales={sales}
        setDate={setDate}
        date={date}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
      /> */}
      {/* <button onClick={handleClick}>CEK RESPON</button> */}
      <div className="flex bg-white z-60 h-full rounded-md">
        <div className="flex-shrink-0 w-1/4 border-r">
          <div
            className="bg-gray-100 flex justify-end "
            onClick={() => setShowSearch(!showSearch)}>
            <div className="p-2 mr-3 cursor-pointer">
              <svg
                width="35"
                height="35"
                viewBox="0 0 87 87"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0 0H86.28V86.28H0V0Z"
                  fill="white"
                  fillOpacity="0.01"
                />
                <path
                  d="M79.09 28.76V64.71H52.1275L43.14 73.6975L34.1525 64.71H7.19V10.785H61.115"
                  stroke="#595959"
                  strokeWidth="7.19"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M41.3425 35.95H44.942"
                  stroke="#595959"
                  strokeWidth="7.19"
                  strokeLinecap="round"
                />
                <path
                  d="M59.3195 35.95H62.9125"
                  stroke="#595959"
                  strokeWidth="7.19"
                  strokeLinecap="round"
                />
                <path
                  d="M23.3693 35.95H26.9623"
                  stroke="#595959"
                  strokeWidth="7.19"
                  strokeLinecap="round"
                />
                <path
                  d="M77.2925 17.975C80.2707 17.975 82.685 15.5607 82.685 12.5825C82.685 9.60431 80.2707 7.19 77.2925 7.19C74.3143 7.19 71.9 9.60431 71.9 12.5825C71.9 15.5607 74.3143 17.975 77.2925 17.975Z"
                  fill="black"
                />
              </svg>
            </div>
          </div>
          {showSearch && (
            <div className="">
              <ModalSearch
                fetchData={fetchData}
                filterSales={sales}
                setDate={setDate}
                date={date}
                showSearch={showSearch}
                showFilter={showFilter}
                setShowFilter={setShowFilter}
                handleNotelpon={handleNotelpon}
              />
            </div>
          )}

          <div className="">
            <div className="flex w-full text-center text-xs lg:text-base cursor-pointer">
              <div
                onClick={() => setContent("all")}
                className={
                  content === "all"
                    ? "w-1/3 p-2  py-6 border-b-4 border-blue-500 text-blue-500 font-semibold"
                    : "w-1/3 p-2 py-6 border-b-4"
                }>
                All
              </div>
              <div
                onClick={() => setContent("true")}
                className={
                  content === "true"
                    ? "w-1/3 p-2 py-6 border-b-4 border-blue-500 text-blue-500 font-semibold"
                    : "w-1/3 p-2 py-6 border-b-4"
                }>
                Read
              </div>
              <div
                onClick={() => setContent("false")}
                className={
                  content === "false"
                    ? "w-1/3 p-2 flex justify-center py-6 border-b-4 border-blue-500 text-blue-500 font-semibold"
                    : "w-1/3 flex p-2 py-6 border-b-4 flex justify-center"
                }>
                Unread
                <div className="justify-end flex ">
                  <div className="ml-1 flex flex-shrink-0 max-w-6 h-6 px-1 z-60 text-white bg-red-500 xl:text-base rounded-full justify-center items-center ">
                    <p className="line-clamp-1">{notif === null ? 0 : notif.total_unread}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white p-2">
              <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <SearchIcon />
                </div>
                <input
                  type="text"
                  onChange={(e) => handleSearch(e.target.value)}
                  id="email-address-icon"
                  className="bg-gray-200 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Search"
                />
              </div>
            </div>
            <MenuRead
              setIsLoadingWa={setIsLoadingWa}
              setRead={setRead}
              search={search}
              setNotif={setNotif}
              content={content}
              menu="all"
              isLoadingWa={isLoadingWa}
              read={read}
              date={date}
              getData={getData}
              setActiv={setActiv}
              activ={activ}
              handleNotelpon={handleNotelpon}
            />
          </div>
        </div>
        {
        isLoading === true ||
        (saveMessages && saveMessages.data) ? (
          <>
            {showModalUpload ? (
              <ModalUploadPaste
                setFileUploadBlob={setFileUploadBlob}
                fileUploadBlob={fileUploadBlob}
                setFileUpload={setFileUpload}
                fileUpload={fileUpload}
                pdf={pdf}
                setPdf={setPdf}
                setShowModalUpload={setShowModalUpload}
                setGlobalMessages={setGlobalMessages}
              />
            ) : (
              <div className="w-full flex bg-gray-100">
                <div className="w-full h-full">
                  {isLoading ? (
                    <div className="h-fit">
                      <div className="bg-gray-400 pt-24 animate-pulse text-white p-1 rounded-lg mb-7"></div>
                      <div className="w-2/5 px-5">
                        <div className="bg-gray-400 pt-44 animate-pulse text-white p-1 rounded-lg mb-7"></div>
                      </div>
                      <div className="w-2/5 px-5">
                        <div className="bg-gray-400 pt-10 animate-pulse text-white p-1 rounded-lg mb-7"></div>
                      </div>
                      <div className="w-2/5 px-5">
                        <div className="bg-gray-400 pt-44 animate-pulse text-white p-1 rounded-lg mb-7"></div>
                      </div>
                      <div className="w-2/5 px-5">
                        <div className="bg-gray-400 pt-10 animate-pulse text-white p-1 rounded-lg mb-7"></div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {(saveMessages && saveMessages.data) ||
                      isLoading === true ? (
                        saveMessages.data.map((item, index) => {
                          return (
                            <div key={index}>
                              <div className="bg-custom-100 text-white py-5 flex px-5 drop-shadow-md">
                                <div className="flex w-3/4">
                                  <div className="relative w-12 h-12 flex-shrink-0">
                                    <img
                                      className="w-12 h-12 rounded-full"
                                      src={`${process.env.REACT_APP_URL_FOTO_STORAGE}/9b04ed370f56c14fe3dd9011ddaa062d.png`}
                                      alt=""
                                    />
                                  </div>
                                  <div className="flex items-center mx-2">
                                    <h1>{item.name}</h1>
                                  </div>
                                </div>

                                <div
                                  className="w-1/4 flex justify-end items-center cursor-pointer"
                                  onClick={() => setShowMenuBar(!showMenuBar)}>
                                  <svg
                                    width="36"
                                    height="37"
                                    viewBox="0 0 36 37"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      d="M3 5H33C34.575 5 36 6.425 36 8V29C36 30.575 34.575 32 33 32H3C1.425 32 0 30.575 0 29V8C0 6.425 1.425 5 3 5ZM21 9.5V11H33V9.5H21ZM21 12.5V14H33V12.5H21ZM21 15.5V17H31.5V15.5H21ZM12 21.365C9 21.365 3 23 3 26V27.5H21V26C21 23 15 21.365 12 21.365ZM12 9.5C10.8065 9.5 9.66193 9.97411 8.81802 10.818C7.97411 11.6619 7.5 12.8065 7.5 14C7.5 15.1935 7.97411 16.3381 8.81802 17.182C9.66193 18.0259 10.8065 18.5 12 18.5C13.1935 18.5 14.3381 18.0259 15.182 17.182C16.0259 16.3381 16.5 15.1935 16.5 14C16.5 12.8065 16.0259 11.6619 15.182 10.818C14.3381 9.97411 13.1935 9.5 12 9.5Z"
                                      fill="#EBEBEB"
                                    />
                                  </svg>
                                </div>
                              </div>
                              <div className="w-full h-fit bg-gray-200">
                                <ChatRoom
                                  createRoomSocketJs={createRoomSocketJs}
                                  rooms={getRoom}
                                  noTelpon={noTelpon}
                                  isLoading={isLoading}
                                  setIsLoading={setIsLoading}
                                  globalMessages={globalMessages}
                                  setGlobalMessages={setGlobalMessages}
                                  // globalMessage={globalMessage}
                                  handleNotelpon={handleNotelpon}
                                />
                              </div>
                              <div
                                className="w-full relative"
                                ref={refAttachmenu}>
                                <ChatEditorOCS
                                  setOpenAudio={setOpenAudio}
                                  openAudio={openAudio}
                                  setFileUploadBlob={setFileUploadBlob}
                                  isLoading={isLoading}
                                  setIsLoading={setIsLoading}
                                  setOpen={setOpen}
                                  open={open}
                                  openVid={openVid}
                                  openDoc={openDoc}
                                  setOpenDoc={setOpenDoc}
                                  setOpenVid={setOpenVid}
                                  submitMessageKeyDown={submitMessageKeyDown}
                                  submitMessage={submitMessage}
                                  setEditor={setEditor}
                                  editor={editor}
                                  setFileUpload={setFileUpload}
                                  pdf={pdf}
                                  setShowAttachmentMenu={setShowAttachmentMenu}
                                  showAttachmentMenu={showAttachmentMenu}
                                  setPdf={setPdf}
                                  setShowModalUpload={setShowModalUpload}
                                  isCounting={isCounting}
                                />
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="h-fit">
                          <div className="bg-gray-400 pt-24 animate-pulse text-white p-1 rounded-lg mb-7"></div>
                          <div className="w-2/5 px-5">
                            <div className="bg-gray-400 pt-44 animate-pulse text-white p-1 rounded-lg mb-7"></div>
                          </div>
                          <div className="w-2/5 px-5">
                            <div className="bg-gray-400 pt-10 animate-pulse text-white p-1 rounded-lg mb-7"></div>
                          </div>
                          <div className="w-2/5 px-5">
                            <div className="bg-gray-400 pt-44 animate-pulse text-white p-1 rounded-lg mb-7"></div>
                          </div>
                          <div className="w-2/5 px-5">
                            <div className="bg-gray-400 pt-10 animate-pulse text-white p-1 rounded-lg mb-7"></div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  <ModalOCSImage
                    setGlobalMessages={setGlobalMessages}
                    isLoading={isLoading}
                    open={open}
                    setOpen={setOpen}
                    setIsLoading={setIsLoading}
                  />
                  <ModalOCSVideo
                    setGlobalMessages={setGlobalMessages}
                    renderLoading={renderLoading}
                    isLoading={isLoading}
                    openVid={openVid}
                    setOpenVid={setOpenVid}
                    setIsLoading={setIsLoading}
                  />
                  <ModalOCSDoc
                    setGlobalMessages={setGlobalMessages}
                    renderLoading={renderLoading}
                    isLoading={isLoading}
                    openDoc={openDoc}
                    setOpenDoc={setOpenDoc}
                    setIsLoading={setIsLoading}
                  />
                  <ModalOCSAudio
                    setOpenAudio={setOpenAudio}
                    openAudio={openAudio}
                    setGlobalMessages={setGlobalMessages}
                    renderLoading={renderLoading}
                    isLoading={isLoading}
                    openDoc={openDoc}
                    setOpenDoc={setOpenDoc}
                    setIsLoading={setIsLoading}
                  />
                </div>
                {showMenuBar && (
                  <>
                    <MenuDetailUser
                      saveMessages={saveMessages}
                      setContent={setContent}
                      setShowMenuBar={setShowMenuBar}
                      showMenuBar={showMenuBar}
                      content={content}
                    />
                  </>
                )}
              </div>
            )}
          </>
        ) : (
          <div className="w-full bg-gray-100 flex-col flex justify-center items-center">
            <img
              src={`${process.env.REACT_APP_URL_FOTO_STORAGE}/logo-ocistok.png`}
            />
            <br />
            <div className="text-center text-xl flex ">
              Tekan icon{" "}
              <svg
                className="mx-2"
                width="35"
                height="35"
                viewBox="0 0 87 87"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0 0H86.28V86.28H0V0Z"
                  fill="white"
                  fillOpacity="0.01"
                />
                <path
                  d="M79.09 28.76V64.71H52.1275L43.14 73.6975L34.1525 64.71H7.19V10.785H61.115"
                  stroke="#595959"
                  strokeWidth="7.19"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M41.3425 35.95H44.942"
                  stroke="#595959"
                  strokeWidth="7.19"
                  strokeLinecap="round"
                />
                <path
                  d="M59.3195 35.95H62.9125"
                  stroke="#595959"
                  strokeWidth="7.19"
                  strokeLinecap="round"
                />
                <path
                  d="M23.3693 35.95H26.9623"
                  stroke="#595959"
                  strokeWidth="7.19"
                  strokeLinecap="round"
                />
                <path
                  d="M77.2925 17.975C80.2707 17.975 82.685 15.5607 82.685 12.5825C82.685 9.60431 80.2707 7.19 77.2925 7.19C74.3143 7.19 71.9 9.60431 71.9 12.5825C71.9 15.5607 74.3143 17.975 77.2925 17.975Z"
                  fill="black"
                />
              </svg>{" "}
              untuk tetap terhubung dengan customer
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Main;
