import { useState, useRef, useMemo, useEffect } from 'react';
import JoditEditor from 'jodit-react';

export default function JoditTextEditor({ initialValue, onChange, placeholder }) {
	const editor = useRef(null);

	const config ={
    readonly: false, // all options from https://xdsoft.net/jodit/docs/,
    placeholder: placeholder || 'Start typings...',
    height: 500,
    removeButtons: ['image', 'source', 'video', 'print', 'preview', 'speechRecognize', 'spellcheck', 'font', 'file', 'fullsize', 'about']
  }

	return (
		<JoditEditor
			ref={editor}
			value={initialValue}
			config={config}
			tabIndex={1} // tabIndex of textarea
			onBlur={onChange} // preferred to use only this option to update the content for performance reasons
			// onChange={newContent => {}}
		/>
	);
}