import { useEffect } from "react";
import Routes from "components/Routes";
import { getUserObe } from "service/api";
import { setAdditonalData } from "redux/addDataSlice";
import { useDispatch } from "react-redux";
import getBrowserFingerprint from 'get-browser-fingerprint';
import Cookies from "js-cookie";
import { RecoilRoot } from "recoil";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    const getDataObe = async () => {
      const response = await getUserObe();
      if (response?.status === 200) {
        dispatch(
          setAdditonalData({ 
            category: response.category, 
            // obe: response.data 
          })
        );
      }
    };

    getDataObe();
  }, [dispatch]);

  useEffect(() => {
    if (!Cookies.get('Session_id')) {
      const fingerprint = getBrowserFingerprint();
      Cookies.set('Session_id', fingerprint);
      Cookies.set('language', "en");
    }
  }, []);

  return (
    <RecoilRoot>
      <Routes />
    </RecoilRoot>
  );
}

export default App;
