import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import {
  IconButton,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import swal from 'sweetalert';
import { useState } from 'react';
import { request_unpaid } from 'service/api';
import { styled } from '@material-ui/styles';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Cookies from 'js-cookie';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  maxWidth: '90vw',
  bgcolor: 'background.paper',
  border: '1px solid lightgray',
  boxShadow: 24,
  borderRadius: '5px',
  p: 4,
};

const Input = styled('input')({
  display: 'none',
});

export default function ModalRequestUnpaid({
  id,
  id_group,
  setUpdate,
}) {
  const [open, setOpen] = useState(false);
  const [notes, setNotes] = useState('');
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setNotes('')
    setImage('')
    setOpen(false)
  };
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState('');


  const handleChange = (e) => {
    setNotes(e.target.value);
  };

  const handleRequestUnpaid = async (e) => {
    e.preventDefault();
    if (notes.trim() === '') {
      swal('Oops', 'Keterangan is required!', 'error');
      return
    }
    if (image === '') {
      swal('Oops', 'Image is required!', 'error');
      return
    }
    
    setIsLoading(true)
    const payload = {
      id_so: id,
      id_group,
      note: notes,
      image
    }
    
    const response = await request_unpaid(JSON.stringify(payload))
    if (response?.status === 200) {
      swal('Request has been added.', {
        icon: 'success',
      });
      setNotes('');
      setImage('');
      setUpdate((prev) => !prev);
    } else {
      swal('Oops', response?.message, 'error');
    }
    setIsLoading(false)
  };

  const submitImage = (e) => {
    if (e.target.files[0].size > 3000000) {
      swal('Oops', 'Image size over 3MB', 'error');
      return;
    }
    let formData = new FormData();
    let fileNameSplit = e.target.files[0].name.split('.');
    fileNameSplit.pop()
    const fileType = e.target.files[0].type.split('/').pop()
    const randomString = Math.floor((Math.random() * 99999) + 10000)
    const newName = `request-unpaid-proof-${randomString}.${fileType}`;

    formData.append('gambar', e.target.files[0], newName);
    fetch(`${process.env.REACT_APP_URL_API_IMAGE_UPLOAD2}`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${Cookies.get('oms_token')}`,
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          setImage(data.file);
        } else {
          swal('Oops', data.message, 'error');
        }
        e.target.value = ''
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div>
      <div
        className={`bg-blue-500 py-2 px-1 rounded-md text-white shadow hover:bg-blue-700`}
        onClick={() => handleOpen()}>
          Request Unpaid
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={open}>
          <Box sx={style}>
            <div className="flex justify-end -mt-5">
              <IconButton onClick={handleClose} style={{ textAlign: 'right' }}>
                <CloseIcon />
              </IconButton>
            </div>
            <Typography id="transition-modal-title" className='text-center' variant="h6" component="h2">
              Request Unpaid
            </Typography>
            <div className={`${image ? 'w-52 h-52' : 'h-20'} flex flex-col justify-center items-center mx-auto border-2 border-dashed rounded-lg overflow-hidden mt-2 mb-1 space-x-1 relative`}>
              {!image ? null :
                <img src={image} className="absolute w-full h-full object-contain" />
              }
              <label
                htmlFor="icon-button-file"
                className={`${image ? 'opacity-50 hover:opacity-100 hover:shadow transition-all text-gray-800 bg-white' : 'opacity-100 text-white bg-blue-500'} flex items-center text-sm p-2 rounded-md space-x-2 relative cursor-pointer`}>
                <p>Upload Image</p>
                <Input
                  accept="image/*"
                  id="icon-button-file"
                  onChange={(e) => submitImage(e)}
                  type="file"
                />
                <CameraAltIcon fontSize="small" />
              </label>
              {/* {image && <em>{image} uploaded</em>} */}
            </div>
            <form
              onSubmit={handleRequestUnpaid}
              className="notes mt-3">
              <div className='text-gray-600'>Keterangan</div>
              <textarea
                // id="outlined-basic"
                placeholder={'Tambah keterangan'}
                // variant="outlined"
                onChange={handleChange}
                value={notes}
                className="w-full mt-1 border border-gray-400 rounded-lg p-2"
                size="small"
                rows={2}
                // color="primary"
              />
              <button
                type="submit"
                className="w-full mx-auto mt-5 p-2 bg-blue-400 hover:bg-blue-500 text-white rounded-md cursor-pointer">
                Submit
              </button>
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
