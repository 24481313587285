import React from "react";
import { Redirect } from "react-router-dom";

const Catalog = () => {
  return (
    <>
      <Redirect to="catalog/catalog-product" />
    </>
  );
};

export default Catalog;
