// import { getUser } from "helpers/parseJWT";
// const params = new Proxy(new URLSearchParams(window.location.search), {
//   get: (searchParams, prop) => searchParams.get(prop),
// });

import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from 'react-router-dom';

const Filters = ({
  filterToko,
  filterSorting,
  idSo,
  toko  = "all",
  setToko,
  auto ,
  setAuto,
  date,
  setDate,
  setUpdate,
  actionSubmit
}) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(search);

  const maxDate = new Date(
    new Date().getTime() - new Date().getTimezoneOffset() * 60000
  )
    .toISOString()
    .split("T")[0];

  const handleChangeDate = (event) => {
    const { value, name } = event.target;
    setDate({ ...date, [name]: value });
    
    if (name === 'sorting') {
      query.set('sort', value);
      history.replace({
        search: query.toString(),
      });
    }
  };

  return (
    <>

      {/* {filterToko &&
      <>
        <div className="filter-toko">
          <select
            name="toko"
            id="toko"
            value={toko}
            className="border rounded px-2 py-1"
            onChange={(e) => setToko(e.target.value)}>
            <option value="all">All</option>
            <option value="1688">1688</option>
            <option value="taobao">Taobao</option>
            <option value="alibaba">Alibaba</option>
          </select>
        </div>
        {toko === "1688" && (
          <div className="flex items-center gap-x-1">
            <input
              type="checkbox"
              name="auto"
              id="auto"
              checked={auto}
              onChange={(e) => setAuto(e.target.checked)}
            />{" "}
            <label htmlFor="auto" className="text-normal text-xs">
              Errors Only
            </label>
          </div>
        )}
      </>
      } */}
      <input
        type="text"
        placeholder={t("Start date")}
        name="start"
        value={date?.start}
        max={maxDate}
        onChange={handleChangeDate}
        className="py-1 px-2 rounded-md w-32 border border-gray-200 focus:outline-blue cursor-pointer"
        onFocus={(e) => (e.target.type = "date")}
        onBlur={(e) => (e.target.type = "text")}
      />
      <input
        type="text"
        name="end"
        className="py-1 px-2 rounded-md border border-gray-200 w-32 focus:outline-blue cursor-pointer"
        placeholder={t("End date")}
        value={date?.end}
        max={maxDate}
        onChange={handleChangeDate}
        onFocus={(e) => (e.target.type = "date")}
        onBlur={(e) => (e.target.type = "text")}
      />
      <div className="flex items-center text-sm space-x-3">
        <div className="flex items-center space-x-3">
          <p>{t("Sort order")}:</p>
          <select
            name="sorting"
            value={date?.sorting}
            onChange={handleChangeDate}
            className="border border-gray-300 p-1 rounded-md focus-within:outline-blue"
          >
            {/* <option value="" disabled>
              Select Sorting
            </option> */}
            <option value="asc">{t("Oldest")}</option>
            <option value="desc">{t("Latest")}</option>
          </select>
        </div>
      </div>

      <button
        className="text-xs px-3 py-1 text-white rounded-md cursor-pointer bg-blue-500 hover:bg-blue-600"
        onClick={e => actionSubmit(idSo, toko, auto)}>
        {t("Filter")}
      </button>

    </>
  );
};

export default Filters;
