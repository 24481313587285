import { useEffect, useRef, useState } from "react";
// import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import {
  IconButton,
  // Tooltip,
  // Chip,
  // CircularProgress,
  Typography,
  Fade,
  Modal,
  Backdrop,
} from "@mui/material";
import swal from "sweetalert";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";
import { add_multi_sales_data, get_atasans, get_departments, submit_oms_accounts_list, update_oms_accounts_list } from "service/api";
import { getUser } from "helpers/parseJWT";
import Cookies from "js-cookie";
// import GppBadIcon from "@mui/icons-material/GppBad";
// import MaterialTable from "material-table";
// import tableIcons from "helpers/materialTableIcons";
// import { FormatDate } from "helpers/ConvertTime";
// import { MuiThemeProvider } from "@material-ui/core";
// import { createTheme } from "@material-ui/core/styles";
// import moment from "moment";
// import { AddCircle, KeyboardReturnRounded } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  maxHeight: "90vh",
  overflowY: "auto",
  bgcolor: "white",
  border: "1px solid lightgray",
  boxShadow: 24,
  borderRadius: "5px",
  p: 4,
};

const ModalAddOmsAccountUser = ({
  selectedUser,
  showModal,
  setShowModal,
  setChangeData,
  listUserAttribute,
  isEditing
}) => {
  const [newUser, setNewUser] = useState({
    user: '',
    nama: '',
    password: '',
    jabatan: '',
    department: '',
    foto: '',
    atasan: '',
    telepon: '',
    quota: '',
  });
  const [departments, setDepartments] = useState([])
  const [atasans, setAtasans] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const imageUpload = useRef(null);
  
  useEffect(() => {
    if (isEditing) {
      setNewUser({
        user: selectedUser.user,
        nama: selectedUser.nama,
        jabatan: selectedUser.jabatan,
        department: selectedUser.department,
        foto: selectedUser.foto,
        atasan: selectedUser.atasan,
        telepon: selectedUser.telepon,
        quota: ~~selectedUser.quota,
      })
      if (selectedUser.jabatan) {
        getDepartments(selectedUser.jabatan)
      }
    }
  }, [isEditing])

  const handleChange = (e) => {
    const {name, value} = e.target

    if (name === 'department') {
      if (value !== 'obe-trainee' && value !== 'obe-staff') {
        setNewUser((prev) => {return {...prev, quota: 0}})
      }
    }
    setNewUser((prev) => {return {...prev, [name]: value}})
  }

  const handleChangeJabatan = (e) => {
    const {name, value} = e.target
    setNewUser((prev) => {return {...prev, [name]: value, department: '', atasan: ''}})
    getDepartments(value)
  }

  const getDepartments = async (value) => {
    const response = await get_departments(value)
    console.log("jabatan", response)
    if (response?.status === 200) {
      setDepartments(response?.data)
      setAtasans([])
    }
  }

  const handleChangeDepartment = async (e) => {
    const {name, value} = e.target
    setNewUser((prev) => {return {...prev, [name]: value, atasan: ''}})
    const response = await get_atasans(newUser.jabatan, value)
    console.log("atasan", response)
    if (response?.status === 200) {
      setAtasans(response?.data)
    }
  }

  const handleChangeImages = async (event) => {
    let formData = new FormData();
    formData.append('gambar', event.target.files[0]);
    const response = await fetch(
      `${process.env.REACT_APP_URL_API_IMAGE_UPLOAD2}`,
      {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${Cookies.get('oms_token')}`,
        }
      }
    );
    
    const data = await response.json();

    if (data?.status === 200) {
      setNewUser((prev) => {return {...prev, foto: data?.file}})
    } else {
      swal('Oops', `Images ${data.message}`, 'error');
    }
  };
  
  const submitUser = async (e) => {
    e.preventDefault()
    const {user, nama, password, jabatan, department, foto, atasan, telepon, quota} = newUser
    if(user === '' || 
        nama === '' || 
        jabatan === '' || 
        department === '' || 
        telepon === '' || 
        (!isEditing && password === '') || 
        (department === 'obe-trainee' && atasan === '') || 
        (department === 'obe-staff' && atasan === '')
      ) {
      swal('Error', 'Harap lengkapi form', 'error')
      return
    }
    
    setIsLoading(true)
    const payload = {...newUser, quota: ~~newUser.quota}
    
    let response
    if (!isEditing) {
      response = await submit_oms_accounts_list(JSON.stringify(payload))
    }
    if (isEditing) {
      response = await update_oms_accounts_list(JSON.stringify(payload))
    }
    if(response.status === 200) {
      setNewUser({
        user: '',
        nama: '',
        password: '',
        jabatan: '',
        department: '',
        foto: '',
        atasan: '',
        telepon: '',
        quota: '',
      })
      setShowModal(false)
      setChangeData(prev => !prev)
    } else {
      swal('Oops', `${response.message}`, 'error');
    }
    setIsLoading(false)
  };
  
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={showModal}
      onClose={() => setShowModal(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={showModal}>
        <Box sx={style}>
          <div className="flex justify-end -mt-5">
            <IconButton
              onClick={() => setShowModal(false)}
              style={{ textAlign: "right" }}>
              <CloseIcon />
            </IconButton>
          </div>
          <Typography id="transition-modal-title" variant="h6" component="h2">
            {`${isEditing ? 'EDIT' : 'ADD'}`} OMS ACCOUNT USER
          </Typography>
          <form
            onSubmit={(e) =>
              !isLoading && ["admin"].some((i) => getUser()?.roles?.includes(i)) && submitUser(e)
            }
            className="flex flex-col items-center space-y-3 mt-3">
            <div className="flex items-center gap-x-3 w-full">
              <label htmlFor="user" className="w-28 flex-shrink-0">Username</label>
              <input type="text" name="user" id="user" 
                className="w-full border border-gray-300 rounded-md px-2"
                value={newUser.user} 
                onChange={(e) => !isEditing && handleChange(e)} 
                disabled={isEditing}
              />
            </div>
            <div className="flex items-center gap-x-3 w-full">
              <label htmlFor="nama" className="w-28 flex-shrink-0">Nama Lengkap</label>
              <input type="text" name="nama" id="nama" 
                className="w-full border border-gray-300 rounded-md px-2"
                value={newUser.nama} 
                onChange={handleChange} 
              />
            </div>
            {!isEditing &&
              <div className="flex items-center gap-x-3 w-full">
                <label htmlFor="password" className="w-28 flex-shrink-0">Password</label>
                <input type="text" name="password" id="password" 
                  className="w-full border border-gray-300 rounded-md px-2"
                  value={newUser.password} 
                  onChange={handleChange} 
                />
              </div>
            }
            <div className="flex items-center gap-x-3 w-full">
              <label htmlFor="telepon" className="w-28 flex-shrink-0">Telepon</label>
              <input type="text" name="telepon" id="telepon" 
                className="w-full border border-gray-300 rounded-md px-2"
                value={newUser.telepon} 
                onChange={handleChange} 
              />
            </div>
            <div className="flex items-center gap-x-3 w-full">
              <label htmlFor="jabatan" className="w-28 flex-shrink-0">Jabatan</label>
              <select name="jabatan" id="jabatan" 
                className="w-full border border-gray-300 rounded-md px-2"
                value={newUser.jabatan} 
                onChange={handleChangeJabatan} 
              >
                <option value="">-</option>
                {listUserAttribute?.jabatan?.map((item, index) => 
                  <option key={item.jabatan} value={item.jabatan} className="capitalize">{item.jabatan}</option>
                )}
              </select>
            </div>
            <div className="flex items-center gap-x-3 w-full">
              <label htmlFor="department" className="w-28 flex-shrink-0">Department</label>
              <select name="department" id="department" 
                className="w-full border border-gray-300 rounded-md px-2"
                value={newUser.department} 
                onChange={handleChangeDepartment} 
                disabled={!newUser.jabatan || departments?.length === 0}
              >
                <option value="">-</option>
                {departments?.map((item, index) => 
                  <option key={item.department} value={item.department} className="capitalize">{item.department_name}</option>
                )}
              </select>
            </div>
            {(getUser().division.includes("bd") && (newUser.department.toLowerCase() === "obe-trainee" || newUser.department.toLowerCase() === "obe-staff" || newUser.department.toLowerCase() === "obe")) &&
              <div className="flex items-center gap-x-3 w-full">
                <label htmlFor="quota" className="w-28 flex-shrink-0">Quota</label>
                <input type="number" name="quota" id="quota" 
                  className="w-full border border-gray-300 rounded-md px-2"
                  value={newUser.quota} 
                  onChange={handleChange} 
                />
              </div>
            }
            <div className="flex items-center gap-x-3 w-full">
              <label htmlFor="atasan" className="w-28 flex-shrink-0">Atasan</label>
              <select name="atasan" id="atasan" 
                className="w-full border border-gray-300 rounded-md px-2"
                value={newUser.atasan} 
                onChange={handleChange} 
                disabled={!newUser.department || atasans?.length === 0}
                >
                <option value="">-</option>
                {atasans?.map((item, index) => 
                  <option key={item.user} value={item.user} className="capitalize">{item.user}</option>
                )}
              </select>
            </div>
            
            <label htmlFor="foto" className="w-28 h-32 border rounded-lg relative hover:scale-105 hover:shadow duration-300">
              <img
                src={newUser.foto || '/default_image.png'}
                alt=""
                className="w-full h-full object-contain"
              />
              <div
                className="absolute text-xs bottom-4 w-full text-center font-semibold cursor-pointer hover:font-bold text-gray-300 hover:text-gray-400"
                style={{
                  textShadow:
                    '1px 1px 0px white, -1px -1px 0 white, -1px 1px 0 white, 1px -1px 0 white',
                }}
                // onClick={() => imageUpload.current.click()}
              >
                {newUser.foto === '' ? 'Upload foto' : 'Ganti foto'}
              </div>
            </label>
            <input
              name="foto"
              id="foto"
              onChange={(e) => handleChangeImages(e)}
              className="hidden"
              type="file"
              ref={imageUpload}
            />

            <button
              className="p-2 bg-blue-400 text-white rounded-md w-full hover:bg-blue-500 transition-alll"
              type="submit"
              disabled={isLoading}>
              {isLoading ? 'Loading...' : 'Submit'}
            </button>
          </form>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ModalAddOmsAccountUser;
