import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import MemberRoute from './MemberRoute';
import Login from 'pages/Login';
import Dashboard from 'pages/Dashboard';
import GlobalSearch from 'pages/GlobalSearch';

// Management Sales / CRM
import ManagementSales from 'pages/ManagementSales';
import CustomerManagement from 'pages/ManagementSales/CustomerManagement';
import SalesTarget from 'pages/ManagementSales/SalesTarget';
import OmsAccounts from 'pages/ManagementSales/OmsAccounts';
import OBE from 'pages/ManagementSales/OBE';
import NewCustomer from 'pages/ManagementSales/NewCustomer';
import EditNewCustomer from 'pages/ManagementSales/EditNewCustomer';
import AllCustomerRequest from 'pages/ManagementSales/AllCustomerRequest';
import VoucherGenerator from 'pages/ManagementSales/VoucherGenerator';
import SalesRequest from 'pages/ManagementSales/SalesRequest';
import AllOrders from 'pages/ManagementSales/AllOrders';
import OCS from 'pages/ManagementSales/OCS';
// FITUR EXPIRED ORDERS DI-CANCEL
// import ExpiredOrders from 'pages/ManagementSales/ExpiredOrders';

//Quotation
import Quotation from 'pages/Quotation';
import Performance from 'pages/Quotation/Performance';
import CheckVolume from 'pages/Quotation/CheckVolume';

// Pre Sales
// import PreSales from 'pages/PreSales';

// Purchasing
import NewOrders from 'pages/Purchasing/NewOrders';
import AlreadyPO from 'pages/Purchasing/AlreadyPO';
import PoPaid from 'pages/Purchasing/PoPaid';

// Tracking
import TrackingClick from 'pages/Tracking/TrackingClick';

// Uang Me
// import UangMe from "pages/UangMe/UangMe";
// import UangMeTransaction from "pages/UangMe/UangMeTransaction";
import NotFound from 'pages/NotFound';

//PURCHASHING
import Purchasing from 'pages/Purchasing';
import AbnormalPurchasing from 'pages/Purchasing/AbnormalPurchasing';
import Approval from 'pages/Purchasing/Approval';
//LOGISTIC CHINA
import LogisticChina from 'pages/LogisticChina';
import ReceivedItems from 'pages/LogisticChina/ReceivedItems';
import Repacking from 'pages/LogisticChina/Repacking';
import SendToIdn from 'pages/LogisticChina/SendToIdn';
import Container from 'pages/LogisticChina/Container';
import OtwIdn from 'pages/LogisticChina/OtwIdn';
import ArrivedIdn from 'pages/LogisticChina/ArrivedIdn';
import SendToIdnV2 from 'pages/LogisticChina/SendToIdnV2';
import SendToIdnDetained from 'pages/LogisticChina/SendToIdnDetained';
import AbnormalLogisticChina from 'pages/LogisticChina/Abnormal';
//LOGISTIC INDO
import LogiscticIndo from 'pages/LogisticIndo';
import PreScanWhIndo from 'pages/LogisticIndo/PreScanWhIndo';
import ReceivedWhIndo from 'pages/LogisticIndo/ReceivedWhIndo';
import ApprovalWhIndo from 'pages/LogisticIndo/ApprovalWhIndo';
import PalletManagement from 'pages/LogisticIndo/PalletManagement';
import WarehouseSorting from 'pages/LogisticIndo/WarehouseSorting';
import DeliveryWhIndo from 'pages/LogisticIndo/DeliveryWhIndo';
//import DeliveryWhIndoV2 from 'pages/LogisticIndo/DeliveryWhIndoV2';
import PaymentLogisticIndo from 'pages/LogisticIndo/PaymentLogisticIndo';
import FormActualPrice from 'pages/LogisticIndo/FormActualPrice';
import AbnormalLogisticIndo from 'pages/LogisticIndo/Abnormal';
import UserActivity from 'pages/LogisticIndo/UserActivity';
//AFTER SALES
import AfterSales from 'pages/AfterSales';
import RefundCustomer from 'pages/AfterSales/RefundCustomer';
import CancelOrder from 'pages/AfterSales/CancelOrder';
import CompletedOrder from 'pages/AfterSales/CompletedOrder';
import RefundApproval from 'pages/AfterSales/RefundApproval';
import RefundOci from 'pages/AfterSales/RefundOci';
//WAREHOUSE
import Warehouse from 'pages/Warehouse';
import WarehouseChina from 'pages/Warehouse/WarehouseChina';
import WarehouseIndo from 'pages/Warehouse/WarehouseIndo';

// MY TASKS
import MyTasks from 'pages/MyTasks';
import MyTasksSales from 'pages/MyTasks/Sales';
import MyTasksAfterSales from 'pages/MyTasks/AfterSales';
import MyTasksProductDevelopment from 'pages/MyTasks/ProductDevelopment';
import MyTasksApproval from 'pages/MyTasks/Approval';

//CATALOG
import Catalog from 'pages/Catalog';
import CatalogProduct from 'pages/Catalog/CatalogProduct';
import PoolProduct from 'pages/Catalog/PoolProduct';

// Blog
import Blog from 'pages/Blog';
import BlogPosts from 'pages/Blog/BlogPosts';
import AddBlogPost from 'pages/Blog/AddBlogPost';
import UpdateBlogPost from 'pages/Blog/UpdateBlogPost';
// REPORT
import Report from 'pages/Report';
import ReportPenjualan from 'pages/Report/ReportPenjualan';
import ReportRegister from 'pages/Report/ReportRegister';
import ReportCustomerLevel from 'pages/Report/ReportCustomerLevel';
import ReportPengajuan from 'pages/Report/ReportPengajuan';

// CMS
import Cms from 'pages/Cms';
import Categories from 'pages/Cms/Categories';
import Material from 'pages/Cms/Material';
import Banner from 'pages/Cms/Banner';
import Popup from 'pages/Cms/Popup';
import CategoryOciLogistic from 'pages/Cms/CategoryOciLogistik';

// Marketing
import Marketing from 'pages/Marketing';
import BlastEmail from 'pages/Marketing/BlastEmail';
import EmailTemplate from 'pages/Marketing/EmailTemplate';
import VoucherPage from 'pages/Marketing/Voucher';
import OciAcademy from 'pages/Marketing/OciAcademy';

// Notifications
// import Notification from 'pages/Notification';
// import DeviceOne from 'pages/Notification/DeviceOne';
// import DeviceTwo from 'pages/Notification/DeviceTwo';


import { allowAll, allowAdmin, allowAsmen, allowSupervisor, allowSales, allowSalesSupport } from 'helpers/AccessRoles'
import CmsWebConfig from 'pages/Cms/CmsWebConfig';

const Routes = () => {
  return (
    <Router basename="/oms">
      <Switch>
        <Route exact path="/">
          <Redirect to="/dashboard" />
        </Route>
        {/* UangMe */}
        {/* <MemberRoute exact path="/uangme-dashboard" component={UangMe} />
        <MemberRoute
          exact
          path="/uangme-dashboard/tracking-payment"
          component={UangMe}
        />
        <MemberRoute
          exact
          path="/uangme-dashboard/uangme-transaction"
          component={UangMeTransaction}
        /> */}
        {/* Global search */}

        <MemberRoute exact path="/search"
          component={GlobalSearch}
          isAllowed={allowAdmin}
        />


        {/* Management Sales */}
        <MemberRoute
          exact
          path="/management-sales"
          component={ManagementSales}

          isAllowed={[...new Set([...allowSales, ...allowSalesSupport])]}

        />
        <MemberRoute
          path="/management-sales/customer-management"
          component={CustomerManagement}
          isAllowed={allowSales}
        />
        <MemberRoute
          path="/management-sales/sales-target"
          component={SalesTarget}
          isAllowed={allowSales}
        />
        <MemberRoute
          path="/management-sales/oms-accounts"
          component={OmsAccounts}
          isAllowed={allowAdmin}
        />
        <MemberRoute
          path="/management-sales/new-customer"
          component={NewCustomer}
          isAllowed={allowSales}
        />
        <MemberRoute path="/management-sales/obe" 
          component={OBE}
          isAllowed={allowSales}
        />
        <MemberRoute
          exact
          path="/management-sales/all-customer-request"
          component={AllCustomerRequest}
          isAllowed={allowSales}
        />
        <MemberRoute
          exact
          path="/management-sales/voucher-generator"
          component={VoucherGenerator}
          isAllowed={allowAdmin}
        />
        <MemberRoute
          exact
          path="/management-sales/sales-request"
          component={SalesRequest}
          isAllowed={[...new Set([...allowSales, ...allowSalesSupport])]}
        />
        <MemberRoute
          exact
          path="/management-sales/all-orders"
          component={AllOrders}
          isAllowed={allowSales}
        />
        <MemberRoute
          path="/management-sales/edit-new-customer"
          component={EditNewCustomer}

          isAllowed={allowSales}

        />
        <MemberRoute
          exact
          path="/management-sales/ocs"
          component={OCS}
          isAllowed={allowSales}
        />
        {/* FITUR EXPIRED ORDERS DI-CANCEL */}
        {/* <MemberRoute
          exact
          path="/management-sales/expired-orders"
          component={ExpiredOrders}
          isAllowed={allowAsmen}
          byDivision={true}
        /> */}

        {/* Pre Sales */}
        {/* <MemberRoute exact path='/pre-sales' component={PreSales} /> */}

        {/* Quotation */}
        <MemberRoute exact path="/quotation" 
          component={Quotation} 
          isAllowed={[...new Set([...allowSales, ...allowSalesSupport])]}
        />
        <MemberRoute path="/quotation/performance" 
          component={Performance}
          isAllowed={allowAdmin}
        />
        <MemberRoute exact path="/quotation/check-volume"
          component={CheckVolume}
          isAllowed={[...new Set([...allowSales, ...allowSalesSupport])]}
        />

        {/* Purchasing */}
        <MemberRoute exact  path="/purchasing" 
          component={Purchasing}
          isAllowed={allowSales}
        />
        <MemberRoute exact path="/purchasing/new-order" 
          component={NewOrders}
          isAllowed={allowSales}
        />
        <MemberRoute exact path="/purchasing/already-po"
          component={AlreadyPO}
          isAllowed={allowSales}
        />
        <MemberRoute exact path="/purchasing/po-paid" 
          component={PoPaid}
          isAllowed={allowSales}

        />
        <MemberRoute
          exact
          path="/purchasing/abnormal"
          component={AbnormalPurchasing}
          isAllowed={allowAdmin}
        />
        <MemberRoute exact 
          path="/purchasing/approval" 
          component={Approval} 
          isAllowed={allowAdmin}
        />

        {/* Logistic china */}
        <MemberRoute exact path="/logistic-china" 
          component={LogisticChina}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/logistic-china/received-items" 
          component={ReceivedItems}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/logistic-china/repacking" 
          component={Repacking}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/logistic-china/send-to-idn" 
          component={SendToIdn}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/logistic-china/container"
          component={Container}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/logistic-china/otw-idn" 
          component={OtwIdn}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/logistic-china/arrived-idn" 
          component={ArrivedIdn}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/logistic-china/send-to-idn-v2" 
          component={SendToIdnV2}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/logistic-china/send-to-idn-detained" 
          component={SendToIdnDetained}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/logistic-china/abnormal" 
          component={AbnormalLogisticChina}
          isAllowed={allowAdmin} 
        />
        {/*  Logistic Indo */}
        <MemberRoute exact path="/logistic-indo" 
          component={LogiscticIndo}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/logistic-indo/warehouse-sorting" 
          component={WarehouseSorting}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/logistic-indo/pre-scan-wh-indo" 
          component={PreScanWhIndo}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/logistic-indo/received-wh-indo" 
          component={ReceivedWhIndo}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/logistic-indo/approval-whindo" 
          component={ApprovalWhIndo}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/logistic-indo/pallet-management" 
          component={PalletManagement}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/logistic-indo/wh-indo-delivery" 
          component={DeliveryWhIndo}
          isAllowed={allowAdmin} 
        />
        {/* <MemberRoute exact path="/logistic-indo/wh-indo-delivery-v2" 
          component={DeliveryWhIndoV2}
          isAllowed={allowAdmin} 
        /> */}
        <MemberRoute exact path="/logistic-indo/logistics-indo" 
          component={PaymentLogisticIndo}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/logistic-indo/form-actual-price" 
          component={FormActualPrice}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/logistic-indo/abnormal" 
          component={AbnormalLogisticIndo}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/logistic-indo/user-activity" 
          component={UserActivity}
          isAllowed={allowAdmin} 
        />

        {/* After Sales */}
        <MemberRoute exact path="/after-sales" 
          component={AfterSales}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/after-sales/refund-approval" 
          component={RefundApproval}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/after-sales/refund-customer" 
          component={RefundCustomer}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/after-sales/refund-oci" 
          component={RefundOci}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/after-sales/cancel-order" 
          component={CancelOrder}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/after-sales/completed-order" 
          component={CompletedOrder}
          isAllowed={allowAdmin} 
        />
        {/* Warehouse */}
        <MemberRoute exact path="/warehouse" 
          component={Warehouse}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/warehouse/warehouse-china" 
          component={WarehouseChina}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/warehouse/warehouse-indo" 
          component={WarehouseIndo}
          isAllowed={allowAdmin} 
        />

        {/* Tracking */}
        <MemberRoute exact path="/tracking" 
          component={TrackingClick}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/tracking/tracking-click" 
          component={TrackingClick}
          isAllowed={allowAdmin} 
        />
        {/* Catalog */}
        <MemberRoute exact path="/catalog" 
          component={Catalog}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/catalog/catalog-product" 
          component={CatalogProduct}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/catalog/pool-product" 
          component={PoolProduct}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/dashboard" 
          component={Dashboard}
          isAllowed={allowAll} 
        />
        {/* Blog */}
        <MemberRoute exact path="/blog" 
          component={Blog}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/blog/blog-posts" 
          component={BlogPosts}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/blog/add-blog-post" 
          component={AddBlogPost}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/blog/update-blog-post" 
          component={UpdateBlogPost}
          isAllowed={allowAdmin} 
        />
        
        {/* Report */}
        <MemberRoute exact path="/report" 
          component={Report}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/report/penjualan" 
          component={ReportPenjualan}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/report/register" 
          component={ReportRegister}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/report/customer-level-spending" 
          component={ReportCustomerLevel}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/report/pengajuan" 
          component={ReportPengajuan}
          isAllowed={allowAdmin} 
        />

        {/* My Tasks */}
        <MemberRoute exact path="/my-tasks" 
          component={MyTasks}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/my-tasks/sales" 
          component={MyTasksSales}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/my-tasks/after-sales" 
          component={MyTasksAfterSales}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/my-tasks/product-development" 
          component={MyTasksProductDevelopment}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/my-tasks/approval" 
          component={MyTasksApproval}
          isAllowed={allowAdmin} 
        />

        {/* CMS */}
        <MemberRoute exact path="/cms" 
          component={Cms}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/cms/web-config" 
          component={CmsWebConfig}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/cms/category-oci-logistic" 
          component={CategoryOciLogistic}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/cms/categories" 
          component={Categories}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/cms/materials" 
          component={Material}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/cms/banner" 
          component={Banner}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/cms/popup" 
          component={Popup}
          isAllowed={allowAdmin} 
        />

        {/* Marketing */}
        <MemberRoute exact path="/marketing" 
          component={Marketing}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/marketing/blast-email" 
          component={BlastEmail}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/marketing/email-template" 
          component={EmailTemplate}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/marketing/voucher" 
          component={VoucherPage}
          isAllowed={allowAdmin} 
        />
        <MemberRoute exact path="/marketing/oci-academy" 
          component={OciAcademy}
          isAllowed={allowAdmin} 
        />
        
        {/* Notification */}
        {/* <MemberRoute exact path="/notification" component={Notification} />
        <MemberRoute exact path="/notification/device-1" component={DeviceOne} />
        <MemberRoute exact path="/notification/device-2" component={DeviceTwo} /> */}

        <Route path="/login" component={Login} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};

export default Routes;
