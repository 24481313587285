import {
    CircularProgress,
    Box,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    Paper,
    TableCell,
  } from '@mui/material';
import { useState, useEffect } from 'react';
import { get_user_activity } from 'service/api';
import PaginationFilter from '../../General/PaginationFilter';
import FilterUserActivity from './FilterUserActivity';
  
  export default function UserActivityTable() {
    const [isLoading, setIsLoading] = useState(false);
    const [dataOrder, setDataOrder] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const [update, setUpdate] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(5);
    const [pageInfo, setPageInfo] = useState({});
    const [selectData, setSelectData] = useState({
      selected: 'user',
      user: '',
      start: '',
      end: '',
    });
  
    const fetchOrderData = async () => {
      setIsLoading(true);
      
      const startDate = selectData.start !== '' ? `start_date=${selectData.start}` : '';
      const endDate = selectData.end !== '' ? `&end_date=${selectData.end}` : '';
      const userParams = selectData.user !== '' ? `&user=${selectData.user}` : '';
      const limitFilter = limit > 0 ? `&page=${page}&limit=${limit}` : ``;
      
      let params = startDate + endDate + userParams + limitFilter;
      const data = await get_user_activity(params);
            
      if (data.status === 200) {
        setDataOrder(data.data.activity_work);
        setUsersList(data.data.user_list);
        setPageInfo({
          dataInPage: data.data.dataInPage,
          totalData: data.data.totalData,
          totalPage: data.data.totalPage,
        });
      }
      setIsLoading(false);
    };
    useEffect(() => {
      fetchOrderData();
    }, [update, limit, page]);
  
    const renderLoading = isLoading ? (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'start',
          padding: '10px',
          borderRadius: 2,
          backgroundColor: 'white',
          marginBottom: 1,
        }}>
        <div className="flex space-x-3 items-center w-full bg-blue-100 p-3 rounded-md">
          <CircularProgress size={20} />
          <p className="text-gray-500 text-sm ">Updating data ...</p>
        </div>
      </Box>
    ) : null;
  
    const Row = ({ rowData }) => {
      return (
        <>
          <TableRow style={{ display: 'flex' }}>
            <TableCell
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                width: '20%',
              }}>
                {rowData.user}
            </TableCell>
            <TableCell
              align='center'
              style={{
                width: '20%',
              }}>
                {rowData.tanggal}
            </TableCell>
            <TableCell
              align='center'
              style={{
                width: '20%',
              }}>
                {rowData.total_sort_work_hour} koli
            </TableCell>
            <TableCell
              align='center'
              style={{
                width: '20%',
              }}>
                {rowData.total_sort_outside_work_hour} koli
            </TableCell>
            <TableCell
              align='center'
              style={{
                width: '20%',
              }}>
                {rowData.total_sort} koli
            </TableCell>
          </TableRow>
        </>
      );
    };
  
    return (
      <>
        {renderLoading}
        <div className="flex items-center justify-between bg-white p-2 px-3 rounded-md my-2">
          <div className='flex items-center'>
            {pageInfo?.dataInPage &&
              <div className="text-sm font-semibold">
                  Showing {pageInfo?.dataInPage} data of {pageInfo?.totalData}
              </div>
            }

            <FilterUserActivity
              page={page}
              setPage={setPage}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              selectData={selectData}
              setSelectData={setSelectData}
              usersList={usersList}
              fetchNewDataList={fetchOrderData}
            />
          </div>
          <PaginationFilter
            page={page}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            totalPage={pageInfo.totalPage}
          />
        </div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow style={{ display: 'flex' }}>
                <TableCell
                  style={{
                    width: '20%',
                  }}>
                  User
                </TableCell>
                <TableCell
                  align='center'
                  style={{
                    width: '20%',
                  }}>
                  Date
                </TableCell>
                <TableCell
                  align='center'
                  style={{
                    width: '20%',
                  }}>
                  Total sorting<br />
                  <span className='text-xs'>(working hours)</span>
                </TableCell>
                <TableCell
                  align='center'
                  style={{
                    width: '20%',
                  }}>
                  Total sorting<br />
                  <span className='text-xs'>(outside working hours)</span>                  
                </TableCell>
                <TableCell
                  align='center'
                  style={{
                    width: '20%',
                  }}>
                  Total sorting<br />
                  <span className='text-xs'>(total)</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataOrder?.length > 0 ? (
                <>
                  {dataOrder?.map((row, id) => (
                    <Row rowData={row} key={id} />
                  ))}
  
                  {dataOrder?.length <= 3 && (
                    <TableRow>
                      <TableCell
                        colSpan={5}
                        align="center"
                        style={{ height: '50vh' }}></TableCell>
                    </TableRow>
                  )}
                </>
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={5}
                    align="center"
                    style={{ height: '70vh' }}>
                    No data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
  