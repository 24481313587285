import React, { useState } from 'react';
import { delete_scan_carton, getScanDetailCarton, insert_scan_carton } from 'service/api';
import DeleteIcon from '@mui/icons-material/Delete';
import swal from 'sweetalert';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { IconButton, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  maxHeight: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
};

const ModalScanCartons = ({
  inputContainerData,
  scannedData,
  setScannedData,
  inputs,
  openModalScanCarton,
  setOpenModalScanCarton,
  getScannedCartons,
  tempScannedData,
  setTempScannedData,
  cartonIdInput,
  setCartonIdInput,
  closeModalScanCartons,
}) => {
  // const [tempScannedData, setTempScannedData] = useState(scannedData.scanned)
  // const [cartonIdInput, setCartonIdInput] = useState('')

  const handleInputCarton = async (e) => {
    e.preventDefault();
    let hasAllreadyValue = tempScannedData?.some(
      (dataToTable) => dataToTable === cartonIdInput
    );

    if (cartonIdInput === '') {
      swal('Oops', `Please input Carton Id`, 'warning');
      return;
    }

    if (hasAllreadyValue) {
      swal(
        'Oops',
        `ID Carton ${cartonIdInput} Already Input`,
        'warning'
      );
      return;
    }

    if (!hasAllreadyValue) {
      const params = cartonIdInput;

      const response = await getScanDetailCarton(params);
      if (response?.status === 200) {
        if (response?.data[0].id_carton === '') {
          swal('Oops', `Details Carton ID, Not Found`, 'warning');
        } else {
          if(response?.data[0].marking_code !== inputContainerData?.marking_code) {
            swal('Oops', `Carton ID ${cartonIdInput} is not part of Marking Code ${inputContainerData?.marking_code}`, 'warning');
          } else {
            const oldArray = tempScannedData;
            setTempScannedData([...oldArray, response?.data?.[0].id_carton]);
          }
        }
      } else {
        swal('Oops', `${response?.message}`, 'warning');
      }
      setCartonIdInput('')
    }
  };

  const handleDeleteCarton = (e, id_karton) => {
    swal({
      title: `Are you sure want to delete this carton: ${id_karton}?`,
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        removeCarton(id_karton)
      }
    });
  };

  const removeCarton = async (id_karton) => {
    const body = {
      id_karton: id_karton
    }
    const response = await delete_scan_carton(JSON.stringify(body))
    if(response.status === 200) {
      getScannedCartons(inputContainerData.marking_code)
      let newDataToMap = tempScannedData.filter((i) => i !== id_karton);
      setTempScannedData(newDataToMap);
    }
  }

  const handleSubmitTableContainer = async () => {
    const body = {
      id_karton: tempScannedData,
    };

    if (tempScannedData?.length === 0) {
      swal('Oops', `Tabel Container Can't Be Empty`, 'warning');
      return;
    }

    if (tempScannedData?.length !== 0) {
      swal({
        title: 'Are you sure?',
        text: 'Submit data on Table Container',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      }).then((yes) => {
        if (yes) {
          const submit = async () => {
            const response = await insert_scan_carton(JSON.stringify(body));
            console.log('resp', response)
            if (response.status === 200) {
              swal('Table Container has been Submited', {
                icon: 'success',
              });
              await getScannedCartons(inputContainerData.marking_code)
              closeModalScanCartons()
              // setOpenModalScanCarton(false)
              // setTempScannedData([])
              // setCartonIdInput('')
            } else {
              swal(
                `Failed Submit Table Container, Error : ${response?.message}`,
                {
                  icon: 'error',
                }
              );
            }
          };

          submit();
        } else {
          swal('Your canceled to Submit Table Container');
        }
      });
    }
  };

  return (
    <>
      <Modal
        name='modal_scanCarton'
        open={openModalScanCarton}
        onClose={() => setOpenModalScanCarton(false)}>
        <Box sx={style}>
          <div className='flex justify-end -mt-5'>
            <IconButton
              name='modal_scanCarton'
              onClick={() => setOpenModalScanCarton(false)}
              style={{ textAlign: 'right' }}>
              <CloseIcon className='hover:text-red-600' />
            </IconButton>
          </div>
          <div className="w-full h-full">
            <div className='text-gray-800 font-bold text-xl'>
              Container : {inputs.container}
            </div>
            <form onSubmit={(e) => handleInputCarton(e)}>
              <input
                autoFocus
                name='input_cartonId'
                value={cartonIdInput}
                onChange={(e) => setCartonIdInput(e.target.value)}
                type='text'
                className='border-2 rounded-sm w-3/12 p-px px-1 hover:outline-blue focus:outline-blue text-gray-800 text-lg'
                placeholder='Input Carton id'
              />
              <div className='text-xs text-red-400 mt-1'>
                * Press Enter to submit
              </div>
            </form>
            <div className='border mt-6 max-h-[215px] overflow-y-auto z-50'>
              <div className='flex items-center bg-blue-200 w-full border-b-2 sticky top-0 z-50'>
                <p className='w-[16.6%] text-gray-700 font-semibold text-base text-center p-1'>
                  No.
                </p>
                <p className='w-[33.2%] text-gray-700 font-semibold text-base text-center p-1'>
                  ID Karton
                </p>
                <p className='w-[16.6%] text-gray-700 font-semibold text-base text-center p-1'>
                  Options
                </p>
              </div>
              {tempScannedData?.map((data, i) => (
                <div key={i}>
                  <div
                    // onClick={(e) => console.log(e, i, data)}
                    className={`${
                      i % 2 ? 'bg-blue-50' : 'bg-gray-50'
                    } flex items-center w-full hover:bg-blue-100 cursor-pointer z-[9999]`}>
                    <p className='w-[16.6%] text-gray-700 font-semibold text-base text-center p-1 '>
                      {i + 1}
                    </p>
                    <p className='w-[33.2%] text-gray-700 font-semibold text-base text-center p-1 '>
                      {data}
                    </p>
                    <p className='w-[16.6%] text-gray-700 font-semibold text-base text-center p-1'>
                      <IconButton
                        onClick={(e) => handleDeleteCarton(e, data)}
                        className='hover:text-red-400 z-50'>
                        <DeleteIcon />
                      </IconButton>
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <div className="total-carton mt-1">
              Total Karton: <span className='font-bold'>{tempScannedData?.length}</span>
            </div>
            <div className='flex justify-end mt-5'>
              <button
                onClick={handleSubmitTableContainer}
                className='p-2 px-6 rounded-md cursor-pointer text-white bg-blue-500 text-center mb-2 hover:bg-blue-600 text-lg'>
                Submit
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ModalScanCartons;
