import {
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Paper,
  IconButton,
  Collapse,
  Box,
} from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckIcon from '@mui/icons-material/Check';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { height } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { InputData } from './InputData';
import Lightbox from 'react-image-lightbox';
import UnreceivedItems from './UnreceivedItems';
import { confirm_acknowledge_returned_items } from 'service/api';
import swal from 'sweetalert';
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie';
  
  export default function EditRepackingInputTable({
    id_so,
    box,
    setBox,
    setUpdate,
    products,
    // newDataProduct,
    // setNewDataProduct,
    total_received_init,
    totalUnreceivedItemQty,
    detailOrderProducts,
    detailOrder,
    selectedSku,
    setSelectedSku,
    setValidQtyDiff,
    isEditing,
    addNewCarton,
    totalLeftoversProductsQty,
    setTotalLeftoversProductsQty,
    confirmAcknowledge,
    setConfirmAcknowledge,
  }) {
    const [massUpdate, setMassUpdate] = useState({
      panjang: '',
      width: '',
      height: '',
      weight: '',
    });
    // const [totalOrdered, setTotalOrdered] = useState(detailOrder.totalQty)
    const [totalReceived, setTotalReceived] = useState(box?.reduce((total, curr) => curr.total_received + total, 0))
    const [searchSKU, setSearchSKU] = useState('')
    const [showVariants, setShowVariants] = useState(false)
    const [open, setOpen] = useState(false);
    const [leftoversProducts, setLeftoversProducts] = useState(detailOrder?.leftovers_products)
    // const [openLightbox, setOpenLightbox] = useState(false);

  const { t } = useTranslation();
    
    const handleChange = (e, i) => {
      const { name, value } = e.target;
      const values = [...box];
      if (i !== undefined) {
        values[i][name] = value;
        setBox(values);
      } else {
        setMassUpdate((prev) => {
          return { ...prev, [name]: value };
        });
        setBox(
          box.map((prev) => {
            return { ...prev, [name]: value };
          })
        );
      }
    };
    
    useEffect(() => {
      if(totalReceived) {
        handleChangeReceivedQty()
      }
    }, [totalReceived])
    
    useEffect(() => {
      if(leftoversProducts && leftoversProducts.length !== 0) {
        setTotalLeftoversProductsQty(leftoversProducts.reduce((total, curr) => total + curr.qty, 0))
      }
    }, [leftoversProducts])
    
    const handleChangeReceivedQty = () => {
      let diff = total_received_init - totalReceived
      const tempBox = [...box]
      tempBox.forEach(
        (order, i) => {
          // totalOrdered += order.qty
          // totalReceived += order.qty_whchina
          let finalInputQty = 0
          if (diff >= box.length) {
            const qtyModulo = diff % box.length
            const restQty = diff - qtyModulo
            finalInputQty = i == 0 ? (restQty / box.length) + qtyModulo : restQty / box.length
          } else {
            if (i < diff) {
              finalInputQty++
            }
          }
          order.total_received = order.total_ordered - finalInputQty
        }
      )
      setBox(tempBox)
      
      // const indexTobeChanged = box.findIndex((item) => item.total_ordered >= diff)
      // const tempBox = [...box]
  
      // if(indexTobeChanged >= 0) {
      //   tempBox[indexTobeChanged].total_received = parseInt(tempBox[indexTobeChanged].total_ordered) - parseInt(diff)
      //   setBox(tempBox)
      // }
    }
  
    const set_TotalReceived = (value) => {
        if (value >= 0 && value <= total_received_init) {
        setTotalReceived(value)
      }
    }
  
    const addNewVariant = () => {
      setSelectedSku(prev => [...prev, 
        {
          id: prev[prev.length-1].id + 1,
          product: '',
          quantity: '',
          reason: '',
        }
      ])
    }
  
    const removeLastVariant = () => {
      const newSelectedSku = [...selectedSku]
      newSelectedSku.pop()
      setSelectedSku(newSelectedSku)
    }
    
    const setSelectedSKU = (variant, index) => {
      const findIndex = selectedSku.findIndex((sku) => sku.product.sku === variant.sku)
      // if (findIndex < 0) {
        const newSelectedVariant = [...selectedSku]
        newSelectedVariant[index].product = variant
        setSelectedSku(newSelectedVariant)
      // }
    }
    
    const setSelectedSKUQty = (qty, id, index) => {
      const currentTotalSelectedSKUQty = selectedSku.filter((sSku) => sSku.id !== id).reduce((total, curr) => total + ~~curr.quantity, 0)
        if(currentTotalSelectedSKUQty + ~~qty <= total_received_init - totalReceived) {
          const newSelectedVariant = [...selectedSku]
          newSelectedVariant[index].quantity = qty
          setSelectedSku(newSelectedVariant)
        }
    }
  
    const setSelectedSKUReason = (variant, index, value) => {
      const findIndex = selectedSku.findIndex((sku) => sku.product.sku === variant.sku)
      // if (findIndex < 0) {
        const newSelectedVariant = [...selectedSku]
        newSelectedVariant[index].reason = value
        setSelectedSku(newSelectedVariant)
      // }
    }
    
    // FUNCTION TO JUMP INTO NEXT INPUT FIELD ON ENTER KEY PRESSED
    // const handleKeyUp = (e, targetElem) => {
    //   if (targetElem !== 'null' && e.key === "Enter") {
    //     document.getElementById(targetElem).focus()
    //   }
    // };
  
    // const totalUnreceivedItemQty = ~~selectedSku.reduce((total, curr) => ~~curr.quantity + total, 0)
    
    useEffect(() => {
      // if(totalUnreceivedItemQty !== 0) {
        if(~~total_received_init - ~~totalReceived === ~~totalUnreceivedItemQty) {
          setValidQtyDiff(true)
        }else {
          setValidQtyDiff(false)
        }
      // }
    }, [totalReceived, totalUnreceivedItemQty])

    const removeCarton = (rowData, boxIndex) => {
      const totalProductRowQty = rowData.box_product.reduce((total, curr) => total + ~~curr.qtyInput, 0)
      if (totalProductRowQty === 0) {
        setBox(box.slice(0, box.length - 1))
      } else {
        const lastValue = JSON.parse(JSON.stringify(box.slice(-1)[0]));
        const values = JSON.parse(JSON.stringify(box.slice(0, box.length - 1)));
        
        rowData.box_product.forEach((data, index) => {
          for (let i = 0; i < values.length; i++) {
            values[i].box_product[index].avQty = Number(values[i].box_product[index].qty) + Number(data.qtyInput);
            values[i].box_product[index].qty = values[i].box_product[index].avQty;
          }
        })
  
        setBox(values);
      }
    }

    const confirm_Acknowledge = async () => {
      swal({
        title: 'Are you sure?',
        text: 'By confirming this, you acknowledge that all items from abnormal are completed',
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (confirmed) => {
        if (confirmed) {
          const response = await confirm_acknowledge_returned_items(id_so)
          if (response.status === 200) {
            setTotalLeftoversProductsQty()
            setConfirmAcknowledge(true)
          } else {
            swal('Fail', response.message, 'error')
          }
        } 
      });
    }
    
  const submitImage = (e, i) => {
    let formData = new FormData();
    
    let fileNameSplit = e.target.files[0].name.split('.');
    fileNameSplit.pop()
    const fileName = fileNameSplit.join('').split(' ').join('')
    const fileType = e.target.files[0].type.split('/').pop()
    const randomString = Math.floor((Math.random() * 99999) + 10000)

    const newName = `${fileName}-${randomString}.${fileType}`;

    formData.append('gambar', e.target.files[0], newName);
    fetch(`${process.env.REACT_APP_URL_API_IMAGE_UPLOAD2}`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${Cookies.get('oms_token')}`,
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          const values = [...box];
          values[i].image_packing_kayu = data.file
          setBox(values);
        } else {
          swal("Oops", data.message, "error");
        }
      })
      .catch((error) => {
        swal("Oops", error, "error");
      });
      e.target.value = ""
  };

  const removeImage = (i) => {
    swal({
      title: "Are you sure?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then(async (willDelete) => {
      if (willDelete) {
        const values = [...box];
        values.forEach((item, index) => {
          if (index === i) {
            delete item['image_packing_kayu']
          }
        })
        setBox(values);
      } 
  });
  }

    return (
      <>
        <div className="totals mb-3">
          <div className="flex items-center gap-x-2">
            <div className="w-36 flex-shrink-0 font-bold">{t("Total Ordered")}</div>
            <div className="font-semibold">:</div>
            <div className="font-semibold">{detailOrder.ordered_qty}</div>
          </div>
          <div className="flex items-center gap-x-2">
            <div className="w-36 flex-shrink-0 font-bold">{t("Total Received")}</div>
            <div className="font-semibold">:</div>
            <div className="font-semibold">
              <div className="flex items-center justify-between border rounded">
                <div className={`${totalLeftoversProductsQty && totalLeftoversProductsQty !== 0 && !confirmAcknowledge ? "bg-gray-300" : "bg-blue-200 hover:bg-blue-500 cursor-pointer"} w-5 text-center text-white font-bold rounded-l`} 
                onClick={() =>  (!totalLeftoversProductsQty || confirmAcknowledge) && set_TotalReceived(totalReceived - 1)}
                >-</div>
                <input type="number" name="totalReceived" id="totalReceived" 
                  className='w-12 text-center focus:outline-none'
                  value={totalReceived} 
                  onChange={(e) => e.target.value >= 0 && e.target.value <= total_received_init && e.target.value >= box.length ? setTotalReceived(e.target.value) : null} 
                  disabled={totalLeftoversProductsQty && totalLeftoversProductsQty !== 0 && !confirmAcknowledge}
                />
                <div className={`${totalLeftoversProductsQty && totalLeftoversProductsQty !== 0 && !confirmAcknowledge ? "bg-gray-300" : "bg-blue-200 hover:bg-blue-500 cursor-pointer"} w-5 text-center text-white font-bold rounded-r`} 
                onClick={() => (!totalLeftoversProductsQty || confirmAcknowledge) && set_TotalReceived(totalReceived + 1)}
                >+</div>
              </div>
            </div>
          </div>
          
          {totalLeftoversProductsQty && totalLeftoversProductsQty !== 0 &&
          <>
          <div className="flex flex-col items-start gap-x-2 mt-2">
            <div className="font-semibold text-red-500 flex items-center gap-2">
              <span><WarningAmberIcon className='' /></span> 
              There {totalLeftoversProductsQty > 1 ? 'are' : 'is'} {totalLeftoversProductsQty} item{totalLeftoversProductsQty > 1 && 's'} that were canceled from abnormal.<br />
              Please confirm to acknowledge!
            </div>
            <div className="confirm-btn bg-blue-600 hover:bg-blue-500 text-white px-3 py-1 rounded-md cursor-pointer" onClick={confirm_Acknowledge}>Confirm</div>
          </div>
          <div className="my-1">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => {
                setOpen(prev => !prev);
              }}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            <span className="text-xs">Details</span>
          </div>
          </>
          }
          {(!totalLeftoversProductsQty && confirmAcknowledge) && 
            <div className="flex flex-col items-start gap-x-2 mt-2">
              <div className="font-semibold text-green-500 flex items-center gap-2">
                <span><CheckIcon className='' /></span> 
                You have confirmed to acknowledge the item(s) that canceled from abnormal and make sure those items exist.
              </div>
            </div>
          }
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div className='w-1/2 border rounded-md divide-y p-2 shadow'>
              {leftoversProducts?.map((prod, index) => (
                <div key={prod.sku} className="text-xs py-1 flex gap-2">
                  <img src={prod.gambar} alt={prod.gambar} className='flex-shrink-0 w-14 h-14 object-cover rounded-md border' />
                  <div>
                    <div className="line-clamp-1" title={prod.product}>
                      <strong>Product Name:</strong> {prod.product} <br />
                    </div>
                    <div className="line-clamp-1" title={prod.sku}>
                      <strong>SKU:</strong> {prod.sku} <br />
                    </div>
                    <strong>Qty:</strong> {prod.qty}x
                  </div>
                </div>
              ))}
            </div>
          </Collapse>
        </div>
        <TableContainer
          component={Paper}
          sx={{ maxHeight: 600 }}
          className="variant-scroll">
          <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Carton Number</TableCell>
                {/* <TableCell align="center">Ordered Qty</TableCell>
                <TableCell align="center">Received Qty</TableCell> */}
                {/* <TableCell align="center" colSpan={4}>
                  <div className="flex">
                    <p className="shrink-0 w-24 px-1">Product Name</p>
                    //<p className="shrink-0 w-1/7">Option</p>
                    <p className="shrink-0 w-20 px-1">ordered Qty</p>
                    <p className="shrink-0 w-20 px-1">Available Qty</p>
                    <p className="shrink-0 w-16 px-1">QTY</p>
                  </div>
                </TableCell> */}
                <TableCell align="center">
                  <p>Width (cm)</p>
                  <div className="flex flex-col text-blue-600 items-center">
                    <p>Mass Update</p>
                    <input
                      type="number"
                      name="width"
                      value={massUpdate.width}
                      onChange={handleChange}
                      className="border border-blue-600 w-20 px-1 focus:outline-blue"
                      disabled={totalLeftoversProductsQty && totalLeftoversProductsQty !== 0 && !confirmAcknowledge}
                      onKeyPress={(e) => {
                        if (!/[0-9]|\./.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      min='0'
                    />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div>
                    <p>Length (cm)</p>
                    <div className="flex flex-col text-blue-600 items-center">
                      <p>Mass Update</p>
                      <input
                        name="panjang"
                        value={massUpdate.panjang}
                        type="number"
                        onChange={handleChange}
                        className="border border-blue-600 w-20 px-1 focus:outline-blue"
                        disabled={totalLeftoversProductsQty && totalLeftoversProductsQty !== 0 && !confirmAcknowledge}
                        onKeyPress={(e) => {
                          if (!/[0-9]|\./.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        min='0'
                      />
                    </div>
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div>
                    <p>Height (cm)</p>
                    <div className="flex flex-col text-blue-600 items-center">
                      <p>Mass Update</p>
                      <input
                        name="height"
                        value={massUpdate.height}
                        type="number"
                        onChange={handleChange}
                        className="border border-blue-600 w-20 px-1 focus:outline-blue"
                        disabled={totalLeftoversProductsQty && totalLeftoversProductsQty !== 0 && !confirmAcknowledge}
                        onKeyPress={(e) => {
                          if (!/[0-9]|\./.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        min='0'
                      />
                    </div>
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div>
                    <p>Weight (Kg)</p>
                    <div className="flex flex-col text-blue-600 items-center">
                      <p>Mass Update</p>
                      <input
                        name="weight"
                        step="0.001"
                        value={massUpdate.weight}
                        type="number"
                        onChange={handleChange}
                        className="border border-blue-600 w-20 px-1 focus:outline-blue"
                        disabled={totalLeftoversProductsQty && totalLeftoversProductsQty !== 0 && !confirmAcknowledge}
                        onKeyPress={(e) => {
                          if (!/[0-9]|\./.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        min='0'
                      />
                    </div>
                  </div>
                </TableCell>
                {/* <TableCell align="center" style={{ width: '11%' }}>PIC</TableCell> */}
                
                {(!totalLeftoversProductsQty || confirmAcknowledge) &&
                  <TableCell align="center" style={{ width: '11%' }}></TableCell>
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {/* <TableRow>
                <TableCell style={{ width: '10%' }} align="center" sx={{ fontWeight: 'bold' }}>
                  Totals
                </TableCell>
                <TableCell style={{ width: '10%' }} align="center">
                  {detailOrder.ordered_qty}
                </TableCell>
                <TableCell style={{ width: '10%' }} align="center">
                  <div className="flex items-center justify-between border">
                    <div className="w-5 bg-gray-400 hover:bg-gray-500 text-white cursor-pointer font-bold" 
                    onClick={() =>  set_TotalReceived(totalReceived - 1)}
                    >-</div>
                    <input type="number" name="totalReceived" id="totalReceived" 
                      className='w-12 text-center focus:outline-none'
                      value={totalReceived} 
                      onChange={(e) => e.target.value >= 0 && e.target.value <= total_received_init ? setTotalReceived(e.target.value) : null} />
                    <div className="w-5 bg-gray-400 hover:bg-gray-500 text-white cursor-pointer font-bold" 
                    onClick={() => set_TotalReceived(totalReceived + 1)}
                    >+</div>
                  </div>
                </TableCell>
              </TableRow> */}
              {box.map((rowData, i) => (
                <TableRow key={i}>
                  <TableCell style={{ width: '10%' }} align="center">
                    {rowData.id_box}
                  </TableCell>
                  {/* <TableCell style={{ width: '10%' }} align="center">
                    {rowData.total_ordered}
                  </TableCell>
                  <TableCell style={{ width: '10%' }} align="center">
                    {rowData.total_received}
                  </TableCell> */}
                  {/* <TableCell colSpan={4}>
                    <InputData
                    rowData={rowData}
                    boxIndex={i}
                    box={box}
                    setBox={setBox}
                    products={products}
                    setUpdate={setUpdate}
                    newDataProduct={newDataProduct}
                    setNewDataProduct={setNewDataProduct}
                    />
                  </TableCell> */}
                  <TableCell align="center" style={{ width: '13%' }}>
                    <input
                      id={`${rowData.id_box}-repacking-0`}
                      name="width"
                      className="w-20 border border-gray-300 p-1 focus:outline-blue"
                      onChange={(e) => handleChange(e, i)}
                      // onKeyUp={(e) => handleKeyUp(e, rowData.id_box + "-repacking-1")}
                      value={box[i]?.width}
                      disabled={totalLeftoversProductsQty && totalLeftoversProductsQty !== 0 && !confirmAcknowledge}
                      onKeyPress={(e) => {
                        if (!/[0-9]|\./.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      min='0'
                    />
                  </TableCell>
                  <TableCell align="center" style={{ width: '13%' }}>
                    <input
                      id={`${rowData.id_box}-repacking-1`}
                      name="panjang"
                      value={box[i]?.panjang}
                      className="w-20 border border-gray-300 p-1 focus:outline-blue"
                      // onKeyUp={(e) => handleKeyUp(e, rowData.id_box + "-repacking-2")}
                      onChange={(e) => handleChange(e, i)}
                      disabled={totalLeftoversProductsQty && totalLeftoversProductsQty !== 0 && !confirmAcknowledge}
                      onKeyPress={(e) => {
                        if (!/[0-9]|\./.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      min='0'
                    />
                  </TableCell>
                  <TableCell align="center" style={{ width: '13%' }}>
                    <input
                      id={`${rowData.id_box}-repacking-2`}
                      name="height"
                      value={box[i]?.height}
                      className="w-20 border border-gray-300 p-1 focus:outline-blue"
                      // onKeyUp={(e) => handleKeyUp(e, rowData.id_box + "-repacking-3")}
                      onChange={(e) => handleChange(e, i)}
                      disabled={totalLeftoversProductsQty && totalLeftoversProductsQty !== 0 && !confirmAcknowledge}
                      onKeyPress={(e) => {
                        if (!/[0-9]|\./.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      min='0'
                    />
                  </TableCell>
                  <TableCell align="center" style={{ width: '13%' }}>
                    <input
                      id={`${rowData.id_box}-repacking-3`}
                      name="weight"
                      value={box[i]?.weight}
                      type="number"
                      step="0.001"
                      className="w-20 border border-gray-300 p-1 focus:outline-blue"
                      // onKeyUp={(e) => handleKeyUp(e, `${i === box.length - 1 ? null : box[i+1].id_box + "-0"}`)}
                      onChange={(e) => handleChange(e, i)}
                      disabled={totalLeftoversProductsQty && totalLeftoversProductsQty !== 0 && !confirmAcknowledge}
                      onKeyPress={(e) => {
                        if (!/[0-9]|\./.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      min='0'
                    />
                  </TableCell>
                  {detailOrder.is_packing_kayu &&
                    <TableCell align="center" style={{ width: '11%' }}>
                      <div className="flex justify-center items-center">
                        {rowData.image_packing_kayu ? 
                          <div className="relative">
                            <img src={rowData.image_packing_kayu} alt="image packing kayu" className='w-8 h-8 mx-auto object-contain' />
                            {totalLeftoversProductsQty && totalLeftoversProductsQty !== 0 && !confirmAcknowledge ? null :
                            <div className="absolute -top-2 -right-2 font-bold text-gray-300 hover:text-gray-500 cursor-pointer" onClick={() => removeImage(i)}>x</div>
                            }
                          </div>
                          :
                          <>
                            <label htmlFor={`${rowData.id_box}-wooden-packing-image`}>
                              <AddAPhotoIcon className={`${totalLeftoversProductsQty && totalLeftoversProductsQty !== 0 && !confirmAcknowledge ? "text-gray-200 shadow" : "text-gray-400 hover:text-gray-500 shadow hover:shadow-md cursor-pointer"} `} />
                            </label>
                            <input
                              accept="image/*"
                              id={`${rowData.id_box}-wooden-packing-image`}
                              onChange={(e) => submitImage(e, i)}
                              type="file"
                              hidden
                              className="p-1 border border-gray-400 rounded-md focus:outline-blue"
                              disabled={totalLeftoversProductsQty && totalLeftoversProductsQty !== 0 && !confirmAcknowledge}
                            />
                          </>
                        }
                      </div>
                    </TableCell>
                  }
                  {/* <TableCell align="center" style={{ width: '11%' }}>
                    <div className="flex shrink-0 flex-col items-center space-y-2 w-12 px-1">
                      {rowData.box_product.map((boxData, id) => {
                        return (
                          <div key={id}>
                          <img
                            key={id}
                            src={boxData.image}
                            className="w-full h-[30px] object-contain"
                            onClick={() => setOpenLightbox(boxData.idProduct)}
                            />
                            
                            {openLightbox === boxData.idProduct && (
                              <Lightbox
                                mainSrc={boxData.image}
                                onCloseRequest={() => setOpenLightbox(false)}
                              />
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </TableCell> */}
                  
                  {(!totalLeftoversProductsQty || confirmAcknowledge) &&
                    <TableCell align="center" style={{ width: '11%' }}>
                      {box.length > 1 && i === box.length - 1 &&
                        <div className="action-buttons flex justify-end items-center gap-x-2 mt-2">
                            <div className="remove-button px-3 py-1 rounded-md text-white bg-red-600 hover:bg-red-700 cursor-pointer whitespace-nowrap" onClick={() => removeCarton(rowData, i)}>Remove Carton</div>
                        </div>
                      }
                    </TableCell>
                  }
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {(!totalLeftoversProductsQty || confirmAcknowledge) &&
          <div className="action-buttons flex justify-end items-center gap-x-2 mt-3">
              <div className="remove-button px-3 py-1 rounded-md text-white bg-green-600 hover:bg-green-700 cursor-pointer" onClick={addNewCarton}>{t("Add Carton")}</div>
          </div>
        }
        
        {total_received_init - totalReceived > 0 &&
          <UnreceivedItems
            totalUnreceivedItemQty={totalUnreceivedItemQty}
            totalReceived={totalReceived}
            totalReceivedInit={total_received_init}
            detailOrderProducts={detailOrderProducts}
            selectedSku={selectedSku}
            setSelectedSKU={setSelectedSKU}
            showVariants={showVariants}
            setShowVariants={setShowVariants}
            searchSKU={searchSKU}
            setSearchSKU={setSearchSKU}
            isEditing={true}
            setSelectedSKUQty={setSelectedSKUQty}
            setSelectedSKUReason={setSelectedSKUReason}
            addNewVariant={addNewVariant}
            removeLastVariant={removeLastVariant}
          />
        }
      </>
    );
  }
  