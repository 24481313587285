import Layout from 'components/Layout';
import { SubRoutesMarketing } from 'components/Marketing/SubRoutesMarketing';
import Voucher from 'components/Marketing/Voucher';

const VoucherPage = () => {
  return (
    <Layout routes={SubRoutesMarketing()} title="Marketing">
      <Voucher/>
    </Layout>
  );
};

export default VoucherPage;
