import { getUser } from "helpers/parseJWT";
import { allowAll, allowAdmin, allowSupervisor, allowSales, allowSalesSupport } from 'helpers/AccessRoles'
import { useTranslation } from "react-i18next";

export const SubRoutesQuotation = () => {
  const { t } = useTranslation()
  return[
  {
    name: `${[...new Set([...allowSales, ...allowSalesSupport])].some((i) => getUser().roles?.includes(i)) ? t("Check Details") : ""}`,
    pathname: "/quotation/check-volume",
  },
  {
    name: `${allowAdmin.some((i) => getUser().roles?.includes(i)) ? "Performance" : ""}`,
    pathname: "/quotation/performance",
  },
]};
