import { getUser } from "helpers/parseJWT";
import { Redirect } from "react-router-dom";

const ManagementSales = () => {

  return (
    <>
      {['obe'].some((i) => getUser().division?.includes(i)) ? (
        <Redirect to="management-sales/OBE" />
      ) : (
        <Redirect to="management-sales/customer-management" />
      )}
    </>
  );
};

export default ManagementSales;
