import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

export default function LanguageSwitcher() {
  const [chooseOtherLanguage, setChooseOtherLanguage] = useState(false)
  const buttonSwitcherRef = useRef(null)
  
  const { i18n } = useTranslation();

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleClickOutside = (e) => {
    if (buttonSwitcherRef.current && !buttonSwitcherRef.current.contains(e.target)) {
      setChooseOtherLanguage(false);
    }
  };

  const handleLanguageChange = () => {
    const newLang = i18n.language === 'en' ? 'cn' : 'en';
    i18n.changeLanguage(newLang);
    Cookies.set('language', newLang);
    setChooseOtherLanguage(false)
  };

  return (
    <div ref={buttonSwitcherRef} className="relative w-[24px] h-[16px] flex justify-center items-center">
      <img src={`${i18n.language === 'en' ? '/english-language.webp' : '/chinese-language.webp'}`}
        alt="english language switcher"
        className="w-[24px] h-[16px] object-cover object-center"
        onClick={() => setChooseOtherLanguage(prev => !prev)}
      />
      {chooseOtherLanguage &&
        <img src={`${i18n.language !== 'en' ? '/english-language.webp' : '/chinese-language.webp'}`}
        alt="english language switcher"
        className="w-[24px] h-[16px] object-cover object-center absolute bottom-0 left-0 transform translate-y-[150%]"
        onClick={handleLanguageChange}
        />
      }
    </div>
  );
}
