import React from "react";
import { Redirect } from "react-router-dom";

const MyTasks = () => {
  return (
    <>
      <Redirect to="my-tasks/sales" />
    </>
  );
};

export default MyTasks;
