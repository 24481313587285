import { useState } from 'react';
import Layout from 'components/Layout';
import AlreadyPOTable from 'components/Purchasing/AlreadyPO/AlreadyPOTable';
import { exportAlreadyPo } from 'service/api';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import exportFromJSON from 'export-from-json';
import { SubRoutesPurchasing as SUBROUTES } from 'components/Purchasing/SubRoutesPurchasing';
import swal from 'sweetalert';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const AlreadyPO = () => {
  const [isLoading, setIsLoading] = useState(false)
  
  const { t } = useTranslation();

  const currentUrl = window.location.href;
  const path = currentUrl.split("?")[0];
  const pathfilter1688 = path + "?toko=1688";
  const pathfilter1688Error = path + "?toko=all&auto=true";
  const pathfilterTaobao = path + "?toko=taobao";
  const pathfilterAlibaba = path + "?toko=alibaba";

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const toko = query.get('toko');
  const auto = query.get('auto');
  const canceled = query.get('canceled');
  const handleExport = async () => {
    setIsLoading(true)
    const response = await exportAlreadyPo();
    const exportType = exportFromJSON.types.xls;
    if (response.status === 200) {
      exportFromJSON({
        data: response.data,
        fileName: response.fileName,
        exportType,
      });
    } else {
      swal('Oops', response.message, 'error');
    }
    setIsLoading(false)
  };

  return (
    <Layout routes={SUBROUTES()} title="Purchasing">
      <div className="flex justify-between items-center">
        {/* <p className="my-4 bg-white w-32 p-2 rounded-md cursor-pointer text-center">
          Already PO
        </p> */}
        {/* <button
          disabled={isLoading}
          onClick={handleExport}
          className={`${isLoading ? 'bg-gray-300 cursor-default' : 'bg-blue-500 hover:bg-blue-300'} my-4 p-2 rounded-md text-white transiton-all duration-300 ml-auto`}>
            {isLoading? 'Exporting...' :
            <>
              <FileDownloadIcon fontSize="small" />
              <span>Export</span>
            </>
            }
        </button> */}
      </div>
      
      <div className='bg-white mt-2 rounded'>
        <div className= "flex items-center space-x-3 px-2">
          <span className='p-1'>{t("Marketplace")}:</span>
          <Link to={{search : "?toko=all"}}>
            <a className={`${isLoading ? 'bg-gray-300 cursor-default' : (toko == "all" || toko == null)? 'bg-orange-500' : 'bg-blue-500'} text-xs px-3 py-1 text-white rounded-md cursor-pointer bg-blue-500 hover:bg-orange-400`}>
                All
            </a>
          </Link>
          <Link to={{search : "?toko=1688"}}>
            <a className={`${isLoading ? 'bg-gray-300 cursor-default' : toko == "1688" && (auto == 'false' || auto == null) ? "bg-orange-500" :'bg-blue-500'} text-xs px-3 py-1 text-white rounded-md cursor-pointer bg-blue-500 hover:bg-orange-400`}>
                1688
            </a>
          </Link>

          {/* <Link to={{search : "?toko=1688&auto=true"}}>
            <a className={`text-xs px-3 py-1 text-white rounded-md cursor-pointer ${toko == "1688" && auto == "true" ? "bg-orange-500" :"bg-blue-500"} hover:bg-orange-400`}>
            1688 Error
            </a>
          </Link> */}

          <Link to={{search : "?toko=taobao"}}>
            <a className={`text-xs px-3 py-1 text-white rounded-md cursor-pointer  ${toko == "taobao" ? "bg-orange-500" :"bg-blue-500"} hover:bg-orange-400`}>
              Taobao
            </a>
          </Link>

          <Link to={{search : "?toko=alibaba"}}>
            <a className={`text-xs px-3 py-1 text-white rounded-md cursor-pointer  ${toko == "alibaba" ? "bg-orange-500" :"bg-blue-500"} hover:bg-orange-400`}>
              Alibaba
            </a>
          </Link>
        </div>
        {/* <div className= "flex items-center space-x-3 px-2">
          <span className='p-1'>Origin:</span>
          <Link to={{search : `?toko=${toko}`}}>
            <a className={`${isLoading ? 'bg-gray-300 cursor-default' : (canceled == null && auto == null)? "bg-orange-500" :"bg-blue-500"} text-xs px-3 py-1 text-white rounded-md cursor-pointer bg-blue-500 hover:bg-orange-400`}>
                All
            </a>
          </Link>
          <Link to={{search : `?toko=${toko ? toko : "all"}&canceled=false`}}>
            <a className={`${isLoading ? 'bg-gray-300 cursor-default' : ( canceled == 'false')? "bg-orange-500" :"bg-blue-500"} text-xs px-3 py-1 text-white rounded-md cursor-pointer bg-blue-500 hover:bg-orange-400`}>
                New
            </a>
          </Link>

          <Link to={{search : `?toko=${toko ? toko : "all"}&canceled=true`}}>
            <a className={`text-xs px-3 py-1 text-white rounded-md cursor-pointer  ${(canceled == 'true')? "bg-orange-500" :"bg-blue-500"} hover:bg-orange-400`}>
              Canceled
            </a>
          </Link>
        </div> */}
      </div>
      <AlreadyPOTable />
    </Layout>
  );
};
export default AlreadyPO;
