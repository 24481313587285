const allowAll          = ['admin', 'supervisor', 'sales', 'asmen', 'inbound', 'outbound', 'supervisor', 'akunting', 'driver', 'updater', 'aftersales', 'manager', 'trainee', 'staff', 'sales_support',]
const allowAdmin        = ['admin'] //based on level
const allowAsmen        = ['manager', 'asmen'] //based on jabatan/division
const allowSupervisor   = ['admin', 'supervisor'] //based on level
const allowSales        = ['admin', 'supervisor', 'sales'] //based on level
const allowSalesSupport = ['admin', 'supervisor', 'sales_support'] //based on level

export {
    allowAll,
    allowAdmin,
    allowAsmen,
    allowSupervisor,
    allowSales,
    allowSalesSupport,
}
