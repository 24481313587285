import { configureStore } from "@reduxjs/toolkit";
import dataReducer from "./dataSlice";
import addDataReducers from "./addDataSlice";
import dataSocketReducers from "./dataSocketSlice";
import dataRoomsReducers from "./dataRoomsSlice";
import dataTelponReducers from "./dataTelponSlice";

export default configureStore({
  reducer: {
    data: dataReducer,
    addData: addDataReducers,
    dataSocket: dataSocketReducers,
    dataRooms: dataRoomsReducers,
    dataTelp: dataTelponReducers
  },
});
