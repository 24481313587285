import { useEffect, useState } from 'react';
import { getDataPrintLabelDetails, getDetailOrderData } from 'service/api';
import { LoadingComponentDefault } from 'components/UI/LoadingComponent';
import PrintBarcodeModal from './PrintBarcodeModal';
import swal from 'sweetalert';
import { PrintRounded } from '@mui/icons-material';
import { IconButton } from '@mui/material';

export default function GenerateLabelDirectSearch({
    id_group,
    id_so,
    customBarcode,
    setIdBarcode,
    idBarcode
}) {

  const [customerData, setCustomerData] = useState({});
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);
  const [isBolean, setIsBolean] = useState({
    isLoadingBarcode: false,
  });
  const [isLoading, setLoading] = useState();

  const handleOpen = async () => {
    const params = id_group ? id_group : id_so;

    setIsBolean({
      isLoadingBarcode: true,
    });

    const response = await getDataPrintLabelDetails(params);
    if (response?.status === 200) {
      setIsBolean({
        isLoadingBarcode: false,
      });
      setData(response?.data);
    } else {
      setIsBolean({
        isLoadingBarcode: false,
      });
    }

    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const getDetailOrder = async (id) => {
    setLoading(true);
    const data = await getDetailOrderData(id);
    if (data?.status === 200) {
      setCustomerData(data?.data?.customerDetail);
      handleOpen()
    } else {
      handleClose();
      swal('Oops', data?.message, 'error');
    }
    setLoading(false);
  };

  return (
    <div>
      <LoadingComponentDefault
        textLoading={''}
        setIsLoading={isLoading}>
          <IconButton onClick={() => getDetailOrder(id_so)}>
            <PrintRounded />
          </IconButton>
      </LoadingComponentDefault>

      <PrintBarcodeModal
        customerData={customerData}
        id_group={id_group}
        id_so={id_so}
        open={open}
        handleClose={handleClose}
        data={data}
        isBolean={isBolean}
        customBarcode={customBarcode}
        setIdBarcode={setIdBarcode}
        idBarcode={idBarcode}
      />
    </div>
  );
}
