import { getMyTasks, get_mytask_product } from 'service/api';
import swal from 'sweetalert';

export const fetchData = async (date, setData, setIsLoading, page, limit, menu) => {
    setIsLoading(true);
    const startParams = date.start !== '' ? `start=${date.start}&` : '';
    const endParams = date.end !== '' ? `end=${date.end}&` : '';
    const status = date.status !== '' ? `status=${date.status}&` : '';
    const type = date.type !== '' ? `type=${date.type}&` : '';
    const idParams = date.id !== '' ? `id_so=${date.id}&` : '';

    const params =
      `page=${page}&limit=${limit}&menu=${menu}&` +
      startParams +
      endParams +
      status +
      type +
      idParams;

    const response = await getMyTasks(params);
    if (response?.status === 200) {
      setData(response?.data);
    } else if (response?.status === 500) {
      swal('Oops', response?.message || "There's a problem occured. Please try again in 15 minutes or contact IT", 'error');
      setData([]);
    } else {
      swal('Oops', `${response?.message}`, 'error');
      setData([]);
    }
    setIsLoading(false);
}

export const getMytaskProduct = async (idOrder, setMytaskProducts, setOriginalMytaskProducts, setIsLoading, setNewLink) => {
    setIsLoading(true);

    const response = await get_mytask_product(idOrder);
    if (response?.status === 200) {
      setMytaskProducts(response?.data);
      setOriginalMytaskProducts(response?.data);
      if (response?.data?.link !== '') {
        setNewLink(response?.data?.link)
      }
    } else if (response?.status === 500) {
      setMytaskProducts(null);
      swal('Oops', response?.message || "There's a problem occured. Please try again in 15 minutes or contact IT", 'error');
    } else {
      console.log("567567", response)
      swal('Oops', `${response?.message}`, 'error');
    }
    setIsLoading(false);
}
