import { getUser } from 'helpers/parseJWT';
import { allowAll, allowAdmin, allowAsmen, allowSupervisor, allowSales, allowSalesSupport } from 'helpers/AccessRoles'
import { useTranslation } from 'react-i18next';

export const SubRoutesManagementSales = () => {
  const { t } = useTranslation()
  return [
  // {
  //   name: `${
  //     getUser().user === 'hendro' || getUser().user === 'damai'
  //       ? 'Customer Management'
  //       : ''
  //   }`,
  //   pathname: '/management-sales/customer-management',
  // },
  {
    name: `${['ae'].some((i) => getUser().division?.includes(i)) ||
            (allowAdmin.some((i) => getUser().roles?.includes(i)) && !['obe', 'ae'].some((i) => getUser().division?.includes(i))) ? 
            'Customer Management' : ''}`,
    pathname: '/management-sales/customer-management',
  },
  {
    name: `${allowSales.some((i) => getUser().roles?.includes(i)) ? 'Sales Target' : ''}`,
    pathname: '/management-sales/sales-target',
  },
  {
    name: `${allowAdmin.some((i) => getUser().roles?.includes(i)) ? 'OMS Accounts' : ''}`,
    pathname: '/management-sales/oms-accounts',
  },
  {
    name: `${allowSales.some((i) => getUser().roles?.includes(i)) ? 'New Customer' : ''}`,
    pathname: '/management-sales/New-customer',
  },
  {
    name: `${['obe'].some((i) => getUser().division?.includes(i)) || 
            (allowAdmin.some((i) => getUser().roles?.includes(i)) && !['obe', 'ae'].some((i) => getUser().division?.includes(i))) ? 
            'Customer Management OBE' : ''}`,
    pathname: '/management-sales/obe',
  },

  // New From Presales
  {
    name: `${allowSales.some((i) => getUser().roles?.includes(i)) ? 'All Customer Request' : ''}`,
    pathname: '/management-sales/all-customer-request',
  },
  {
    name: `${allowAdmin.some((i) => getUser().roles?.includes(i)) ? 'Voucher Generator' : ''}`,
    pathname: '/management-sales/voucher-generator',
  },
  { 
    name: `${[...new Set([...allowSales, ...allowSalesSupport])].some((i) => getUser().roles?.includes(i)) ? t("Sales Request") : ''}`,
    pathname: '/management-sales/sales-request' },
  {
    name: `${allowSales.some((i) => getUser().roles?.includes(i)) ? 'All Orders' : ''}`,
    pathname: '/management-sales/all-orders',
  },
  {
    name: `${allowSales.some((i) => getUser().roles?.includes(i)) ? 'OCS' : ''}`,
    pathname: '/management-sales/ocs',
  },
  {/* FITUR EXPIRED ORDERS DI-CANCEL */}
  // {
  //   name: `${allowAsmen.some((i) => getUser().division?.includes(i)) || getUser().user === 'wahyu' ? 'Expired Orders' : ''}`,
  //   pathname: '/management-sales/expired-orders',
  // },
  // {
  //   name: `${allowSupervisor.some((i) => getUser().roles?.includes(i)) ? 'My Tasks' : ''}`,
  //   pathname: '/management-sales/my-tasks',
  // },
  // {
  //   name: `${['quotation'].some((i) => getUser().division.includes(i)) ? 'My Tasks' : ''}`,
  //   pathname: '/management-sales/my-tasks',
  // },
]};