import Layout from 'components/Layout';
import { useEffect, useState } from 'react';
import { filterNewCustomerGolang, getNewCustomerData } from 'service/api';
import NewCustomerTable from 'components/ManagementSales/NewCustomer/NewCustomerTable';
import FilterNewCustomer from 'components/ManagementSales/NewCustomer/FilterNewCustomer';
import swal from 'sweetalert';
import { Tooltip } from '@mui/material';
import { SubRoutesManagementSales as SUBROUTES } from 'components/ManagementSales/SubRoutesManagementSales';

const NewCustomer = () => {
  const [dataNewCustomer, setdataNewCustomer] = useState([]);
  const [dataSales, setdataSales] = useState(null);
  const [changeData, setChangeData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [dataFiltered, setDataFiltered] = useState([]);
  const [isLimit] = useState('100');
  const [selectData, setSelectData] = useState({
    selected: 'email',
    email: '',
    phone: '',
    start: '',
    end: '',
    sales: '',
    limit: 50,
  });
  const [totalPages, setTotalPages] = useState(0);
  const [isPage, setIsPage] = useState(1);
  const [showModalAssigneSales, setShowModalAssigneSales] = useState(false)
  const [showModalResponse, setShowModalResponse] = useState(false)
  const [selectedRowsNumber, setSelectedRowsNumber] = useState(0);

  const fetchDataNewCustomer = async () => {
    const { start, end, email, limit, phone, sales, selected } = selectData;

    setIsLoading(true);

    const emailFilter = email !== '' ? `email=${email}` : '';
    const phoneFilter = phone !== '' ? `&phone=${phone}` : '';
    const startDate = start !== '' ? `start=${start}` : '';
    const endDate = end !== '' ? `&end=${end}` : '';
    const salesParams = sales !== '' ? `&sales=${sales}` : '';
    const limitFilter = limit > 0 ? `&page=${isPage}&limit=${limit}` : ``;

    let params = startDate + endDate + emailFilter + limitFilter + phoneFilter + salesParams;
    const response = await filterNewCustomerGolang(params);
    if (response?.status === 200) {
      setdataNewCustomer(response?.data);
      response?.total >= 1 && setTotalPages(Math.ceil(response?.total / limit));
      setSelectedRowsNumber(0)
    } else {
      swal(
        'Sorry',
        `${response?.message}`,
        // 'error'
      );
      setdataNewCustomer([]);
    }
    if (response.listSales?.length !== 0) {
      setdataSales(response?.listSales);
    }
    // const response = await getNewCustomerData();
    // console.log(response)
    // if (response?.status === 200) {
    //   setdataNewCustomer(response?.data);
    //   if(!dataSales) {
    //     setdataSales(response?.listSales);
    //   }
    // } else {
    //   swal('Oops', `Gagal Mengambil Data, Coba lagi`, 'error');
    // }

    setIsLoading(false);
  };

  useEffect(() => {
    setdataNewCustomer(dataFiltered);
  }, [dataFiltered, isFiltered]);

  useEffect(() => {
    fetchDataNewCustomer();
  }, [changeData]);

  return (
    <Layout routes={SUBROUTES()} title="CRM">
      <Tooltip title="Refresh table" placement="right">
        <p
          className="my-4 bg-white w-60 p-2 rounded-md cursor-pointer text-center"
          onClick={() => {}}>
          New Customer Management
        </p>
      </Tooltip>
      <FilterNewCustomer
        isUseAssigneSales={true}
        filterBy={['email', 'phone', 'tanggal', 'sales']}
        setDataFiltered={setDataFiltered}
        setIsFiltered={setIsFiltered}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        dataSales={dataSales}
        setdataSales={setdataSales}
        selectData={selectData}
        setSelectData={setSelectData}
        totalPages={totalPages}
        setTotalPages={setTotalPages}
        isPage={isPage}
        setIsPage={setIsPage}
        fetchDataNewCustomer={fetchDataNewCustomer}
        setChangeData={setChangeData}
        showModalAssigneSales={showModalAssigneSales}
        setShowModalAssigneSales={setShowModalAssigneSales}
        showModalResponse={showModalResponse}
        setShowModalResponse={setShowModalResponse}
      />
      <NewCustomerTable
        isLimit={isLimit}
        dataNewCustomer={dataNewCustomer}
        listSalesData={dataSales}
        setChangeData={setChangeData}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        selectedRowsNumber={selectedRowsNumber}
        setSelectedRowsNumber={setSelectedRowsNumber}
      />
    </Layout>
  );
};

export default NewCustomer;
