import { useState, useEffect, useRef, useMemo, Fragment } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CloseIcon from "@mui/icons-material/Close";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Typography from "@mui/material/Typography";
import RichEditor from "components/Blog/RichEditor";
import swal from "sweetalert";
import { CircularProgress, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import {
  getVariants,
  inputVolumeData,
  getCategoryData,
  getChinaRawDetail,
  getChinaTestDetail,
  changeQuotation,
} from "service/api";
import UpdateModal from "./UpdateModal";
import AddVariant from "./AddVariant";
import ModalProductCategory from "./ModalProductCategory";
// import AddVariantAuto from "./AddVariantAuto";
import AddVariantAutoDev from "./AddVariantAutoDev";
import { useSelector } from "react-redux";
import { selectAddData } from "redux/addDataSlice";
import { handleBigIntPayload } from "helpers/handleBigInt";
import { GetDataFromLink } from "helpers/BindOcistockLinks";
import { findIndex } from "lodash";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import JoditTextEditor from "components/Blog/JoditTextEditor";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "95dvw",
  maxHeight: "95dvh",
  bgcolor: "background.paper",
  borderRadius: 1,
  boxShadow: 24,
  p: 4,
};

const styleModalImage = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
};

const styleModalSelectCategory = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  minWidth: 900,
};

export default function OptionModal({
  id,
  link,
  setUpdate,
  boxValue,
  category,
  addData,
  dataQty,
  imageProduct,
  productName,
  rowData,
  productCategoryData,
  materialData,
  num_iid,
}) {
  const [idRequest, setIdRequest] = useState(id);
  const [oldNumIid, setOldNumIid] = useState("");
  const imageUpload = useRef(null);
  const { category: Category } = useSelector(selectAddData);
  const [open, setOpen] = useState(false);
  const [box, setBox] = useState(boxValue);
  const [volume, setVolume] = useState([]);
  const [selectCategory, setSelectCategory] = useState(category ? category : 0);
  const [dataLink, setDataLink] = useState("");
  const [supplierLink, setSupplierLink] = useState();
  const [deskripsi, setDeskripsi] = useState();
  const [minQty, setMinQty] = useState(dataQty ?? "");
  const [productNameCN, setProductNameCN] = useState("");
  const [productNameID, setProductNameID] = useState("");
  const [selectProductCategory, setSelectProductCategory] = useState([
    rowData.productCategory,
    rowData.productSubcategory,
  ]);
  const [expiryDate, setExpiryDate] = useState("");
  const [selectMaterial, setSelectMaterial] = useState(0);
  const [material, setMaterial] = useState("");
  const [fileType, setFileType] = useState([]);
  const [hpixel, setHpixel] = useState(0);
  const [wpixel, setWpixel] = useState(0);
  const [isLoading, setIsLoading] = useState({
    checkProductLink: false,
    image: false,
  });
  const [productGambar, setProductGambar] = useState("");
  const [linkChange, setLinkChange] = useState(false);
  const [variantData, setVariantData] = useState([]);
  const [minOrder, setMinOrder] = useState("");
  const [loadingChina, setLoadingChina] = useState(false);
  const [updateAll, setUpdateAll] = useState({
    quantity: "",
    kuantiti_kelipatan: "",
    width: "",
    length: "",
    height: "",
    weight: "",
    price: "",
    min_purchase: "",
    customize_logo: "",
    is_lartas: false,
    hscode: "",
    price: "",
    isChecked: false,
    shippingfee: "",
  });
  const [discount, setDiscount] = useState([]);
  const [getAddData, setGetAddData] = useState({});
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [errorProductLink, setErrorProductLink] = useState("");
  const [listVariantTemp, setListVariantTemp] = useState(null);
  const [isValidCheckLink, setIsValidCheckLink] = useState(false);
  const [checked, setChecked] = useState({});
  const [checkAll, setCheckAll] = useState("");
  const [checkboxes, setCheckboxes] = useState({
    jpg: false,
    jpeg: false,
    svg: false,
    psd: false,
    cdr: false,
    ai: false,
    gif: false,
    pdf: false,
    png: false,
    raw: false,
  });
  const [isLoadingQuotationChange, setIsLoadingQuotationChange] = useState(false);
  const [changeQuotationNewLink, setChangeQuotationNewLink] = useState("");
  const { t } = useTranslation()

  const linkData = GetDataFromLink(dataLink);

  const handleOpen = () => {
    setOpen(true);

  }

  const handleClose = () => {
    setOpen(false);
    setUpdate((prev) => !prev);
  };

  const handleSelectChange = (e) => {
    setSelectCategory(e.target.value);
  };

  const handleChangeProductCategory = (val) => {
    setSelectProductCategory(val);
    setSelectMaterial(0);
  };

  const getVariantData = async (id, isOpen) => {
    const response = await getVariants(id);

    if (response?.status === 200 && response.is_complete) {
      swal('Oops', 'Quotation is completed.', 'error').then(() => {
        window.location.reload()
      });
      return
    } else if (
      response?.status === 200 &&
      response?.data != null &&
      response?.data?.data != null
    ) {

      const vd = response?.data?.data?.filter(
        (data) => data?.status === "open"
      );
      if (vd.length === 0) {
        swal(
          "Oops",
          "The product link you just entered has already been quoted before. ",
          "success"
        ).then((v) => {
          handleClose();
          setUpdate((prev) => !prev);
        });
        return;
      }
      setVolume(response?.data?.data);
      setVariantData(response?.data?.data);
      setDiscount(response?.data?.discount);
      if (isOpen) {
        setIsLoading({
          ...isLoading,
          checkProductLink: true,
        });
        const { toko, id } = GetDataFromLink(dataLink);
        const response2 = await getChinaTestDetail(toko, id);
        setMinOrder(response2?.min_order);
        if (response2?.status == 200) {
          setDeskripsi(response2.desc);
          setErrorProductLink("valid");
          setMaterial(response2.category)
        } else {
          setErrorProductLink("error");
        }
        setIsLoading({
          ...isLoading,
          checkProductLink: false,
        });
      }
    } else {
      if (response?.data == null) {
        swal("Oops", "Data not found !", "error");
      }
    }
  };

  const handleChangeImages = (e) => {
    const { name } = e.target;
    e.preventDefault();
    if (name === "gambar") {
      const imageInput = e.target.files[0];
      handleUploadImages(imageInput);
    }
  };

  const handleInputChange = (e, i, type) => {
    let { name, value } = e.target;

    if (e.target.type !== 'number' || (e.target.type === 'number' && value >= 0)) {
      if (type === "discount") {
        const values = [...discount];
        values[i][name] = value;
        setDiscount(values);
      }

      if (i === undefined) {
        setUpdateAll((prev) => {
          return { ...prev, [name]: value };
        });
        setVolume(
          volume.map((data) => {
            return { ...data, [name]: value };
          })
        );
      } else {
        const values = [...volume];
        values[i][name] = value;
        setVolume(values);
      }
    }
  };

  const allValue = [
    "jpg",
    "jpeg",
    "svg",
    "psd",
    "cdr",
    "ai",
    "gif",
    "pdf",
    "png",
    "raw",
  ];

  const handleCheckboxChange = (event) => {
    const checkboxValue = event.target.value;
    const id = event.target.id;
    const { name, checked } = event.target;
    setCheckboxes((prevState) => ({
      ...prevState,
      [name]: checked,
    }));

    if (id === "all") {
      setCheckboxes({
        jpg: false,
        jpeg: false,
        svg: false,
        psd: false,
        cdr: false,
        ai: false,
        gif: false,
        pdf: false,
        png: false,
        raw: false,
      });
      if (event.target.checked) {
        setFileType(allValue);
        setChecked({
          jpg: true,
          jpeg: true,
          svg: true,
          psd: true,
          cdr: true,
          ai: true,
          gif: true,
          pdf: true,
          png: true,
          raw: true,
        });
      } else {
        setFileType([]);
        setChecked({
          jpg: false,
          jpeg: false,
          svg: false,
          psd: false,
          cdr: false,
          ai: false,
          gif: false,
          pdf: false,
          png: false,
          raw: false,
        });
      }
    } else {
      setFileType((prevSelectedFileTypes) => {
        if (
          prevSelectedFileTypes &&
          prevSelectedFileTypes.includes(checkboxValue)
        ) {
          const updatedFileTypes = prevSelectedFileTypes.filter(
            (value) => value !== checkboxValue
          );
          return updatedFileTypes.length > 0 ? updatedFileTypes : null;
        } else {
          return [...(prevSelectedFileTypes || []), checkboxValue];
        }
      });
    }
  };

  const handleLinkChange = (e) => {
    setErrorProductLink("");
    setDataLink(e.target.value);
  };
  const handleSupplierLinkChange = (e) => {
    setSupplierLink(e.target.value);
  };
  const handleCheckProductLink = async () => {
    setErrorProductLink("");
    // const {toko, id } = GetDataFromLink("https://detail.1688.com/offer/594649193619.html")
    setIsLoading({
      ...isLoading,
      checkProductLink: true,
    });
    const { toko, id } = GetDataFromLink(dataLink);
    // if(toko === "1688") {
    //   const response = await getChinaRawDetail(toko,id);
    //   if (response?.success == true) {
    //     setListVariantTemp(response.productInfo.skuInfos.map(item => {
    //       return {
    //         variant: item.attributes[0].attributeDisplayName + " - " + item.attributes[0].attributeValue,
    //         image: item.attributes[0].skuImageUrl,
    //         id_product: item.skuId,
    //         quantity: item.amountOnSale,
    //         price: item.price === 0 ? item.consignPrice : item.price,
    //         width: '',
    //         min_purchase:'',
    //         leth: '',
    //         height: '',
    //         weight: '',
    //         customize_logo: false,
    //         hscode: '',
    //         isChecked: false,
    //       }
    //     }))
    //   }
    // }
    const response2 = await getChinaTestDetail(toko, id);
    if (response2?.status == 200) {
      // setProductNameCN(response2.title_china)
      // setProductNameID(response2.title)
      // setProductGambar(response2.item_imgs[0])
      // setDeskripsi(response2.desc)
      // setSelectCategory(0)
      // setSelectProductCategory([0,0])
      // setExpiryDate('');
      // setSelectMaterial(0);
      // setMaterial('');

      // if(toko != "1688") {

      //   const variantTemp = []
      //   response2.PropSku.forEach(data => {
      //     data.children.forEach(data2=> {
      //     variantTemp.push({
      //         variant: data.value + " - "+ data2.variantIdn,
      //         image: data.url,
      //         id_product: data2.sku_id,
      //         quantity: data2.qty === 0 ? '' : data2.qty,
      //         price: data2.harga === 0 ? '' : data2.harga,
      //         width: '',
      //         min_purchase:'',
      //         leth: '',
      //         height: '',
      //         weight: '',
      //         customize_logo: false,
      //         hscode: '',
      //         isChecked: false,
      //       })
      //     })

      //   })
      //   setListVariantTemp(variantTemp)
      // }

      // setVolume(
      //   response2.PropSku.map((data) => {
      //       return {
      //         variant: data.value + " - "+ data.valueidn,
      //         image: data.url,
      //         id_product: data.children[0].sku_id,
      //         quantity: data.children[0].qty === 0 ? '' : data.children[0].qty,
      //         price: data.children[0].harga === 0 ? '' : data.children[0].harga,
      //         width: '',
      //         min_purchase:'',
      //         leth: '',
      //         height: '',
      //         weight: '',
      //         customize_logo: false,
      //         hscode: '',
      //         isChecked: false,
      //       };
      //     })
      // );
      // setUpdateAll({
      //   quantity: '',
      //   width: '',
      //   leth: '',
      //   height: '',
      //   weight: '',
      //   min_purchase: '',
      //   hscode: '',
      //   isChecked: false,
      //   customize_logo: false,
      // });
      setIsValidCheckLink(true);
      setErrorProductLink("valid");
    } else {
      setIsValidCheckLink(false);
      setErrorProductLink("error");
    }

    setIsLoading({
      ...isLoading,
      checkProductLink: false,
    });
  };

  const handleQuotationChange = async () => {
    setIsLoadingQuotationChange(true);
    const dataFromLink = GetDataFromLink(dataLink);
    const productChange = volume
      .filter((v) => {
        return !!v.new_variant;
      })
      .map((v) => {
        return {
          old_id_product: v.id_variant,
          new_id_product: v.new_variant,
        };
      });
    // if(productChange.length == 0){
    //   swal('Oops', 'Please select new variant before changing quotation !', 'error');
    // }

    const payload = {
      id_request: id.split("-")[0],
      new_link: dataLink,
      email_customer: rowData.customer.email,
      id_barang: dataFromLink.id,
      toko: dataFromLink.toko,
      product: productChange,
    };
    const res = await changeQuotation(JSON.stringify(payload));
    if (res?.status == 200) {
      setChangeQuotationNewLink(dataLink);
      setListVariantTemp(null);
      getVariantData(res?.data.new_id_request, true);
      setIdRequest(res?.data.new_id_request);
      const { toko, id } = GetDataFromLink(dataLink);
      const response2 = await getChinaTestDetail(toko, id);
      if (response2?.status == 200) {
        setOldNumIid(id);
        setMinOrder(response2?.min_order);
        setProductNameCN(response2.title_china);
        setProductNameID(response2.title);
        setProductGambar(response2.item_imgs[0]);
        setDeskripsi(response2.desc);
        setSelectCategory(0);
        setSelectProductCategory([0, 0]);
        setExpiryDate("");
        setFileType([]);
        setWpixel("");
        setHpixel("");
        setSelectMaterial(0);
        setMaterial("");

        setVolume(
          response2.PropSku.map((data) => {
            return {
              variant: data.value + " - " + data.valueidn,
              image: data.url,
              id_product: data.children[0].sku_id,
              quantity: data.children[0].qty === 0 ? "" : data.children[0].qty,
              price: data.children[0].harga === 0 ? "" : data.children[0].harga,
              width: "",
              min_purchase: "",
              kuantiti_kelipatan: "",
              length: "",
              height: "",
              weight: "",
              customize_logo: false,
              is_lartas: false,
              hscode: "",
              isChecked: false,
            };
          })
        );
        setUpdateAll({
          quantity: "",
          width: "",
          length: "",
          height: "",
          weight: "",
          min_purchase: "",
          kuantiti_kelipatan: "",
          hscode: "",
          isChecked: false,
          customize_logo: false,
          is_lartas: false,
        });
      }
      setIsValidCheckLink(false);
    } else if (res?.status == 400) {
      swal("Oops", res.message, "error");
    }
    setIsLoadingQuotationChange(false);
  };

  const submitRequest = async () => {
    const { id } = GetDataFromLink(dataLink);
    // console.log(id, oldNumIid, oldNumIid != id)
    if (oldNumIid != id) {
      swal("Oops", "Click Quotation Change First", "error");
      return;
    }
    if (!selectCategory) {
      swal("Oops", "Please select category before submit !", "error");
      return;
    }

    if (productGambar === "") {
      swal("Oops", "Please select image before submit !", "error");
      return;
    }
    if (!selectProductCategory[1]) {
      swal("Oops", "Please select product category before submit !", "error");
      return;
    }
    // if (deskripsi === "") {
    //   swal("Oops", "Please input product description before submit !", "error");
    //   return;
    // }

    const validateChecked = volume.filter((data) => data.isChecked === true);

    if (validateChecked.length === 0) {
      swal("Oops", "Please choose at least 1 data before submit !", "error");
      return;
    }

    for (let i = 0; i < validateChecked.length; i++) {
      if (validateChecked[i].price === "") {
        swal(
          "Oops",
          `Please fill valid "Price" data in row ${i + 1} before submit !`,
          "error"
        );
        return;
      }
      if (validateChecked[i].qty === "") {
        swal(
          "Oops",
          `Please fill valid "Qty" data in row ${i + 1} before submit !`,
          "error"
        );
        return;
      }
      if (validateChecked[i].weight === "") {
        swal(
          "Oops",
          `Please fill valid "Weight" data in row ${i + 1} before submit !`,
          "error"
        );
        return;
      }
      if (validateChecked[i].weight <= 0) {
        swal("Oops", `Please input must be more than 0`, "error");
        return;
      }
      if (validateChecked[i].width === "") {
        swal(
          "Oops",
          `Please fill valid "Width" data in row ${i + 1} before submit !`,
          "error"
        );
        return;
      }
      if (validateChecked[i].length === "") {
        swal(
          "Oops",
          `Please fill valid "Length" data in row ${i + 1} before submit !`,
          "error"
        );
        return;
      }
      if (validateChecked[i].height === "") {
        swal(
          "Oops",
          `Please fill valid "Height" data in row ${i + 1} before submit !`,
          "error"
        );
        return;
      }
      if (validateChecked[i].hscode === "") {
        swal(
          "Oops",
          `Please fill valid "HS-Code" data in row ${i + 1} before submit !`,
          "error"
        );
        return;
      }

      // if (!fileType) {
      //   swal('Oops', 'Please select file type before submit !', 'error');
      //   return
      // }

      // if (hpixel === '') {
      //   swal('Oops', 'Please select h-pixel before submit !', 'error');
      //   return
      // } else if(hpixel <= 0) {
      //   swal('Oops', 'Pixel must be greater than 0', 'error');
      //   return
      // }

      // if (wpixel === '') {
      //   swal('Oops', 'Please select w-pixel before submit !', 'error');
      //   return
      // } else if(wpixel <= 0) {
      //   swal('Oops', 'Pixel must be greater than 0', 'error');
      //   return
      // }
    }

    const body = handleBigIntPayload({
      id_request: idRequest.toString(),
      category: Number(selectCategory),
      product_category: selectProductCategory[0],
      product_subcategory: selectProductCategory[1],
      gambar: productGambar,
      link: dataLink,
      product_name_cn: productNameCN,
      product_name_id: productNameID,
      supplier_link: supplierLink,
      product_description: deskripsi,
      expiring_date: expiryDate,
      material: selectMaterial == 0 ? material : selectMaterial,
      box,
      minQty: Number(updateAll.min_purchase),
      // ket_json: {
      //   file_type: fileType,
      //   Hpixel: hpixel,
      //   Wpixel: wpixel
      // },
      ket_json: {
        file_type: fileType,
        hpixel: Number(hpixel),
        wpixel: Number(wpixel),
      },
      discount: discount?.map((data) => {
        return {
          ...data,
          value: Number(data.value),
          minQty: Number(data.minQty),
        };
      }),
      product: validateChecked.map((data) => {
        return {
          id_product: data.id_product,
          min_purchase: Number(
            data.min_purchase == "" || data.min_purchase == null
              ? 0
              : data.min_purchase
          ),
          kuantiti_kelipatan: Number(
            data.kuantiti_kelipatan == "" || data.kuantiti_kelipatan == null
              ? 0
              : data.kuantiti_kelipatan
          ),
          customize_logo: data.customize_logo,
          is_lartas: data.is_lartas,
          hscode: Number(
            data.hscode == "" || data.hscode == null ? 0 : data.hscode
          ),
          length: Number(
            data.leth == "" || data.length == null ? 0 : data.length
          ),
          height: Number(
            data.height == "" || data.height == null ? 0 : data.height
          ),
          width: Number(
            data.width == "" || data.width == null ? 0 : data.width
          ),
          weight: Number(
            data.weight == "" || data.weight == null ? 0 : data.weight
          ),
          price: Number(
            data.price == "" || data.price == null ? 0 : data.price
          ),
          qty: Number(
            data.quantity == "" || data.quantity == null ? 0 : data.quantity
          ),
          shippingfee: Number(
            data.shippingfee == "" || data.shippingfee == null
              ? 0
              : data.shippingfee
          ),
        };
      }),
    });

    setIsLoadingButton(true);
    const data = await inputVolumeData(body);
    if (data?.status === 200) {
      swal("Submitted", " Request submit succesfully", "success");
      setVolume(
        variantData.map(() => {
          return {
            quantity: "",
            kuantiti_kelipatan: "",
            width: "",
            length: "",
            height: "",
            weight: "",
            min_purchase: "",
            hscode: "",
            isChecked: false,
            customize_logo: false,
            is_lartas: false,
          };
        })
      );
      setUpdateAll({
        quantity: "",
        kuantiti_kelipatan: "",
        width: "",
        length: "",
        height: "",
        weight: "",
        min_purchase: "",
        hscode: "",
        isChecked: false,
        customize_logo: false,
        is_lartas: false,
      });
      setSelectCategory("");
      setSelectProductCategory([0, 0]);
      setDataLink("");
      setProductNameCN("");
      setProductNameID("");
      setProductGambar("");
      setSupplierLink("");
      setDeskripsi("");
      setExpiryDate("");
      setMaterial("");
      setFileType([]);
      setWpixel("");
      setHpixel("");
      setBox(false);
      setCheckboxes("");
      setCheckAll("");
      setChecked("");
      handleClose();
      setIsLoadingButton(false);
    }
    if (data?.status === 400) {
      swal("Oops", data.message, "error");
      setIsLoadingButton(false);
    }

    setIsLoadingButton(false);
  };

  const handleChecked = (e, id) => {
    const { name, checked } = e.target;
    if (id !== undefined) {
      const values = [...volume];
      values[id][name] = checked;
      setVolume(values);
      const checkAll = values.filter((data) => data[name] === false);
      if (checkAll.length > 0) {
        setUpdateAll((prev) => {
          return { ...prev, [name]: false };
        });
      } else {
        setUpdateAll((prev) => {
          return { ...prev, [name]: true };
        });
      }
    } else {
      setUpdateAll((prev) => {
        return { ...prev, [name]: checked };
      });
      setVolume(
        volume.map((data) => {
          return { ...data, [name]: checked };
        })
      );
    }
  };

  useEffect(() => {
    if (
      dataLink.match(/1688/) ||
      dataLink.match(/taobao/) ||
      dataLink.match(/tmall/)
    ) {
      setLinkChange(true);
    } else {
      setLinkChange(false);
    }
  }, [dataLink]);

  useEffect(() => {
    setIdRequest(id);
    setDataLink(link);
    setOldNumIid(num_iid);
    setSupplierLink(rowData.supplierLink);
    setDeskripsi(rowData.deskripsi);
    setProductNameCN(productName);
    setProductNameID(rowData.product_indo);
    setProductGambar(imageProduct);
    setSelectCategory(category ?? "");
    // setMinQty(dataQty);
    setUpdateAll({
      quantity: "",
      kuantiti_kelipatan: "",
      width: "",
      length: "",
      height: "",
      weight: "",
      min_purchase: "",
      hscode: "",
      isChecked: false,
      customize_logo: rowData.is_oem,
      is_lartas: false,
    });

    setGetAddData(addData);
  }, [rowData, id, link, category, num_iid, addData, dataQty]);

  useEffect(() => {
    setVolume(
      variantData
        ?.filter((data) => data?.status === "open")
        .map((data) => {
          return {
            variant: data.variant,
            image: data.image,
            id_product: data.id_product,
            id_variant: data.id_variant,
            quantity: data.quantity === 0 ? 0 : data.quantity,
            price: data.price === 0 ? "" : data.price,
            width: data.width,
            min_purchase: data.minQty,
            kuantiti_kelipatan: 1,
            quantity_customer: data.quantity_customer,
            length: data.length,
            height: data.height,
            weight: data.weight,
            logo: data.logo,
            customize_logo: rowData.is_oem,
            is_lartas: rowData.is_lartas,
            shippingfee: data.shippingfee,
            hscode: data.hscode,
            isChecked: false,
            rollback: data.rollback,
          };
        })
    );
  }, [variantData, minOrder]);

  const download = (logos) => {
    try {
      const a = logos.map((logo) => "gambar[]=" + logo);
      const join = a.join("&");
      return "https://control-panel.ocistok.com/download.php?" + join;
    } catch (error) {
      console.log("file not found");
    }
  };

  const handleUploadImages = async (image) => {
    setIsLoading({
      ...isLoading,
      image: true,
    });
    let formData = new FormData();
    formData.append("gambar", image);
    const response = await fetch(
      `${process.env.REACT_APP_URL_API_IMAGE_UPLOAD2}`,
      {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${Cookies.get('oms_token')}`,
        }
      }
    );
    const data = await response.json();
    if (data?.status === 200) {
      setProductGambar(data?.file);
      setIsLoading({
        ...isLoading,
        image: false,
      });
    } else {
      swal("Oops", `Images ${data.message}`, "error");
      setIsLoading({
        ...isLoading,
        image: false,
      });
    }
  };

  const materialDropdownData = useMemo(() => {
    if (productCategoryData) {
      const cat1 = productCategoryData.find(
        (v) => v.id === selectProductCategory[0]
      );
      if (cat1) {
        const cat2 = cat1.child.find((v) => v.id === selectProductCategory[1]);
        return materialData?.filter((v) =>
          v.subcategory_material_ids.find(
            (v) => v == cat2.subcategory_material_id
          )
        );
      }
    } else {
      return [];
    }
  }, [selectProductCategory]);
  return (
    <>
      <div
        className="bg-blue-500  cursor-pointer text-xs p-2 uppercase rounded-md text-white text-center"
        onClick={() => {
          handleOpen();
          getVariantData(id, true);
        }}>
        {t("INPUT DETAILS")}
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={open}>
          <Box sx={style} className="overflow-y-scroll variant-scroll">
            <div className="flex justify-end -mt-5">
              <IconButton onClick={handleClose} style={{ textAlign: "right" }}>
                <CloseIcon />
              </IconButton>
            </div>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              {t("Form Input Details")}
            </Typography>
            <div className="space-y-4 mt-5">
              <div className="flex flex-col space-y-3">
                <div>
                  <h3 className="text-lg text-orange-500 font-bold mb-6">
                    {t("Product Information")}
                  </h3>
                  <div className="md:flex md:items-center mb-6">
                    <div className="w-1/3">
                      <label
                        className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                        htmlFor="inline-full-name">
                        *{t("Product Name (CN)")}
                      </label>
                    </div>
                    <div className="md:w-full">
                      <input
                        type="text"
                        name="productName"
                        // disabled={dataQty}
                        placeholder={t("Product Name (CN)")}
                        value={productNameCN}
                        onChange={(e) => setProductNameCN(e.target.value)}
                        className="border border-gray-400 rounded-md p-3 focus:outline-blue w-full"
                      />
                    </div>
                  </div>
                  <div className="md:flex md:items-center mb-6">
                    <div className="w-1/3">
                      <label
                        className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                        htmlFor="inline-full-name">
                        *{t("Product Name (ID)")}
                      </label>
                    </div>
                    <div className="w-full">
                      <input
                        type="text"
                        name="productName"
                        placeholder={t("Product Name (ID)")}
                        value={productNameID}
                        onChange={(e) => setProductNameID(e.target.value)}
                        className="border border-gray-400 rounded-md p-3 focus:outline-blue w-full"
                      />
                    </div>
                  </div>
                  <div className="mb-6">
                    <div className="flex md:items-center">
                      <div className="w-1/3">
                        <label
                          className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                          htmlFor="inline-full-name">
                          *{t("Product Link")}
                        </label>
                      </div>
                      <div className="w-full relative flex items-center">
                        <input
                          type="text"
                          name="link"
                          pattern="https:.*"
                          placeholder="Link"
                          value={dataLink}
                          onChange={handleLinkChange}
                          className={`border-gray-400 focus:outline-blue border rounded-md p-3 outline-none w-full`}
                        />
                        <div className="absolute border border-gray-400 right-0 p-2 bg-white">
                          <button
                            disabled={isLoading.checkProductLink}
                            onClick={handleCheckProductLink}
                            className="border px-5 py-1 mr-2 rounded-md font-medium bg-white hover:bg-gray-100">
                            {isLoading.checkProductLink
                              ? "Loading..."
                              : t("Check Link")}
                          </button>
                          {isValidCheckLink && link != dataLink && (
                            <button
                              className="py-1 w-40 border border-blue-500 bg-blue-500 text-white rounded mr-3"
                              onClick={handleQuotationChange}>
                              {isLoadingQuotationChange
                                ? "Loading..."
                                : "Quotation Change"}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                    {errorProductLink == "valid" ? (
                      <div className="flex md:items-center">
                        <div className="w-1/3"></div>
                        <p className="w-full text-green-600 mt-1">
                          *Link is valid
                        </p>
                      </div>
                    ) : (
                      errorProductLink == "error" && (
                        <div className="flex md:items-center">
                          <div className="w-1/3"></div>
                          <p className="w-full text-red-600 mt-1">
                            *Link is invalid, please find another supplier &
                            paste the link in the text box above
                          </p>
                        </div>
                      )
                    )}
                  </div>
                  <div className="md:flex md:items-center mb-6">
                    <div className="w-1/3">
                      <label
                        className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                        htmlFor="inline-full-name">
                        {t("Supplier Link")}
                      </label>
                    </div>
                    <div className="w-full">
                      <input
                        type="text"
                        name="supplierLink"
                        pattern="https:.*"
                        placeholder="Link"
                        value={supplierLink}
                        onChange={handleSupplierLinkChange}
                        className={`border-gray-400 focus:outline-blue border rounded-md p-3 outline-none w-full`}
                      />
                    </div>
                  </div>
                  <div className="md:flex md:items-center mb-6">
                    <div className="w-1/3">
                      <label
                        className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                        htmlFor="inline-full-name">
                        *{t("Logistics Category")}
                      </label>
                    </div>
                    <div className="w-full">
                      <select
                        // disabled={category}
                        className="border border-gray-400 rounded-md p-3 focus:outline-blue w-full"
                        onChange={handleSelectChange}
                        value={selectCategory}>
                        <option value={0} disabled>
                          Choose Category
                        </option>
                        {Category?.map((cat, index) => (
                          <option key={index} value={cat.value}>
                            {cat.category}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="md:flex md:items-center mb-6">
                    <div className="w-1/3">
                      <label
                        className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                        htmlFor="inline-full-name">
                        *{t("Product Category")}
                      </label>
                    </div>
                    <div className="w-full">
                      <ModalProductCategory
                        productCategoryData={productCategoryData}
                        selectProductCategory={selectProductCategory}
                        handleChangeProductCategory={
                          handleChangeProductCategory
                        }
                      />
                    </div>
                  </div>
                  <div className="md:flex md:items-center mb-6">
                    <div className="w-1/3">
                      <label
                        className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                        htmlFor="inline-full-name">
                        *{t("Product Picture")}
                      </label>
                    </div>
                    <div className="w-full flex items-center">
                      <div className="w-52 mr-2">
                        <ModalImage image={productGambar} />
                      </div>
                      <button
                        onClick={(e) => imageUpload.current.click()}
                        className="py-2 px-3 border rounded hover:shadow">
                        Update
                      </button>
                      <input
                        name="gambar"
                        onChange={handleChangeImages}
                        className="hidden"
                        type="file"
                        ref={imageUpload}
                        accept="image/*"
                      />
                    </div>
                  </div>
                  <div className="md:flex md:items-center mb-6">
                    <div className="w-1/3">
                      <label
                        className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                        htmlFor="inline-full-name">
                        {t("Product Detail")}
                      </label>
                    </div>
                    <div className="w-full">
                      {/* <RichEditor
                        initialValue={deskripsi}
                        onChange={(evt, editor) =>
                          setDeskripsi(editor.getContent())
                        }
                      /> */}
                      <JoditTextEditor
                        initialValue={deskripsi}
                        onChange={(newContent) => setDeskripsi(newContent)}
                      />
                    </div>
                  </div>

                  <h3 className="text-lg text-orange-500 font-bold mb-6">
                    {t("Specification")}
                  </h3>
                  <div className="md:flex md:items-center mb-6">
                    <div className="w-1/3">
                      <label
                        className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                        htmlFor="inline-full-name">
                        {t("Expiring Date")}
                      </label>
                    </div>
                    <div className="w-full">
                      <input
                        type="date"
                        name="expiryDate"
                        placeholder="Expiry Date"
                        value={expiryDate}
                        onChange={(e) => setExpiryDate(e.target.value)}
                        className="border border-gray-400 rounded-md p-3 focus:outline-blue w-full"
                      />
                    </div>
                  </div>
                  <div className="md:flex md:items-center mb-6">
                    <div className="w-1/3">
                      <label
                        className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                        htmlFor="inline-full-name">
                        {t("Material")}
                      </label>
                    </div>
                    <div className="w-full flex">
                      <div className="w-72">
                        <select
                          // disabled={material}
                          className="border border-gray-400 rounded-md p-3 focus:outline-blue w-full"
                          onChange={(e) => setSelectMaterial(e.target.value)}
                          value={selectMaterial}>
                          <option value={0}>Others</option>
                          {materialDropdownData?.map((item, index) => (
                            <option key={index} value={item.display_name}>
                              {item.display_name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="w-72 ml-3">
                        {selectMaterial == 0 && (
                          <input
                            type="text"
                            name="material"
                            placeholder="Material"
                            value={material}
                            onChange={(e) => setMaterial(e.target.value)}
                            className="border border-gray-400 rounded-md p-3 focus:outline-blue w-full"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <h3 className="text-lg text-orange-500 font-bold">
                  {t("Sales & Shipping Information")}
                </h3>

                {discount?.length > 0 && (
                  <div className="grid grid-cols-3 gap-5 text-sm">
                    {Array(3)
                      .fill()
                      .map((_, id) => (
                        <div key={id} className="flex items-center space-x-2">
                          <div>
                            <p>Discount Price: </p>
                            <input
                              disabled={!discount[id]?.value}
                              onChange={(e) =>
                                handleInputChange(e, id, "discount")
                              }
                              value={discount[id]?.value}
                              name="value"
                              className="border border-gray-300 p-1 w-full focus:outline-blue"
                            />
                          </div>
                          <div>
                            <p>Min. Quantity : </p>
                            <input
                              disabled={!discount[id]?.minQty}
                              onChange={(e) =>
                                handleInputChange(e, id, "discount")
                              }
                              value={discount[id]?.minQty}
                              name="minQty"
                              className="border border-gray-300 p-1 w-full focus:outline-blue"
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                )}

                <div className="border border-gray-300 rounded-md">
                  <div className="p-5 border-b">
                    <div className="flex items-center justify-between">
                      <p
                        className={` ${variantData?.length === 0
                          ? "text-red-500"
                          : linkChange && variantData?.length === 0
                            ? "text-orange-500"
                            : "text-black-500"
                          } font-semibold text-sm`}>
                        {variantData?.length !== 0
                          ? t("Mass Update")
                          : linkChange && variantData?.length === 0
                            ? "Please add variant"
                            : "Please Update link before add variants !"}
                      </p>
                      <div className="flex items-center space-x-2">
                        <AddVariant
                          link={dataLink}
                          linkChange={
                            getAddData.store === "custom" ? linkChange : true
                          }
                          store={getAddData.store}
                          email={getAddData.email}
                          setUpdate={setUpdate}
                          getVariantData={getVariantData}
                          id_request={id}
                        />
                        {getAddData.type === "auto" && (
                          <AddVariantAutoDev
                            link={dataLink}
                            linkChange={
                              getAddData.store === "custom" ? linkChange : true
                            }
                            store={getAddData.store}
                            email={getAddData.email}
                            setUpdate={setUpdate}
                            getVariantData={getVariantData}
                            id_request={id}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <TableContainer
                    className='table-scroll variant-scroll'
                    sx={{ maxHeight: 500 }}>
                    <Table

                      sx={{
                        '& .MuiTableCell-sizeSmall': {
                          padding: '6px 0px 6px 16px',
                        },
                      }}
                      size="small"
                      stickyHeader
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <input
                              type="checkbox"
                              name="isChecked"
                              style={{ width: 20, height: 20 }}
                              checked={updateAll.isChecked}
                              onChange={handleChecked}
                            />
                          </TableCell>
                          <TableCell>
                            <div className="grid gap-1">
                              <div>*{t("Price")}</div>
                              <div>
                                <input
                                  type="number"
                                  name="price"
                                  value={updateAll.price}
                                  placeholder="RMB/pcs"
                                  className="border border-gray-400 rounded-md p-2 w-32  focus:outline-blue"
                                  onChange={handleInputChange}
                                  onKeyPress={(e) => {
                                    if (!/[0-9]|\./.test(e.key)) {
                                      e.preventDefault();
                                    }
                                  }}
                                  onWheel={(e) => e.target.blur()}
                                />
                              </div>
                            </div>
                          </TableCell>
                          {rowData.is_oem && (
                            <>
                              <TableCell>
                                <div className="grid gap-1">
                                  <div>{t("Customize Logo")}</div>
                                  <div>
                                    <ToggleComp
                                      name="customize_logo"
                                      checked={updateAll.customize_logo}
                                      onChange={handleChecked}
                                    />
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell>{t("Download")}</TableCell>
                            </>
                          )}
                          <TableCell>
                            <div className="grid gap-1">
                              <div>{t("Multiple Qty")}</div>
                              <div>
                                <input
                                  type="number"
                                  name="kuantiti_kelipatan"
                                  value={updateAll.kuantiti_kelipatan}
                                  placeholder="Multiple Qty"
                                  className="border border-gray-400 rounded-md p-2 w-32 focus:outline-blue"
                                  onChange={handleInputChange}
                                  onKeyPress={(e) => {
                                    if (!/[0-9]/.test(e.key)) {
                                      e.preventDefault();
                                    }
                                  }}
                                  onWheel={(e) => e.target.blur()}
                                />
                              </div>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="grid gap-1">
                              <div>{t("Min. Purchase")}</div>
                              <div>

                                <input
                                  type="number"
                                  name="min_purchase"
                                  value={updateAll.min_purchase}
                                  placeholder="Min Purchase"
                                  className="border border-gray-400 rounded-md p-2 w-32 focus:outline-blue"
                                  onChange={handleInputChange}
                                  onKeyPress={(e) => {
                                    if (!/[0-9]/.test(e.key)) {
                                      e.preventDefault();
                                    }
                                  }}
                                  onWheel={(e) => e.target.blur()}
                                />
                              </div>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="grid gap-1">
                              <div>*{t("Qty/carton")}</div>
                              <div>
                                <input
                                  type="number"
                                  name="quantity"
                                  value={updateAll.quantity}
                                  placeholder="Qty/Carton"
                                  className="border border-gray-400 rounded-md p-2 w-32 focus:outline-blue"
                                  onChange={handleInputChange}
                                  onKeyPress={(e) => {
                                    if (!/[0-9]/.test(e.key)) {
                                      e.preventDefault();
                                    }
                                  }}
                                  onWheel={(e) => e.target.blur()}
                                />
                              </div>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="grid gap-1">
                              <div>*{t("Weight")}</div>
                              <div>
                                <input
                                  type="number"
                                  name="weight"
                                  value={updateAll.weight}
                                  placeholder="Weight (Gram)"
                                  className="border border-gray-400 rounded-md p-2 w-32 focus:outline-blue"
                                  onChange={handleInputChange}
                                  onKeyPress={(e) => {
                                    if (!/[0-9]|\./.test(e.key)) {
                                      e.preventDefault();
                                    }
                                  }}
                                  onWheel={(e) => e.target.blur()}
                                />
                              </div>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="grid gap-1">
                              <div>*{t("Width")}</div>
                              <div>
                                <input
                                  type="number"
                                  name="width"
                                  value={updateAll.width}
                                  placeholder="Width (CM)"
                                  className="border border-gray-400 rounded-md p-2 w-32 focus:outline-blue"
                                  onChange={handleInputChange}
                                  onKeyPress={(e) => {
                                    if (!/[0-9]|\./.test(e.key)) {
                                      e.preventDefault();
                                    }
                                  }}
                                  onWheel={(e) => e.target.blur()}
                                />
                              </div>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="grid gap-1">
                              <div>*{t("Length")}</div>
                              <div>
                                <input
                                  type="number"
                                  name="length"
                                  value={updateAll.length}
                                  placeholder="Length (CM)"
                                  className="border border-gray-400 rounded-md p-2 w-32 focus:outline-blue"
                                  onChange={handleInputChange}
                                  onKeyPress={(e) => {
                                    if (!/[0-9]|\./.test(e.key)) {
                                      e.preventDefault();
                                    }
                                  }}
                                  onWheel={(e) => e.target.blur()}
                                />
                              </div>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="grid gap-1">
                              <div>*{t("Height")}</div>
                              <div>
                                <input
                                  type="number"
                                  name="height"
                                  value={updateAll.height}
                                  placeholder="Height (CM)"
                                  className="border border-gray-400 rounded-md p-2 w-32 focus:outline-blue"
                                  onChange={handleInputChange}
                                  onKeyPress={(e) => {
                                    if (!/[0-9]|\./.test(e.key)) {
                                      e.preventDefault();
                                    }
                                  }}
                                  onWheel={(e) => e.target.blur()}
                                />
                              </div>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="grid gap-1">
                              <div>*HS-Code</div>
                              <div>

                                <input
                                  type="number"
                                  name="hscode"
                                  value={updateAll.hscode}
                                  onKeyPress={(e) => {
                                    if (!/[0-9]/.test(e.key)) {
                                      e.preventDefault();
                                    }
                                  }}
                                  placeholder="HS-Code"
                                  className="border border-gray-400 rounded-md p-2 w-32 focus:outline-blue"
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="grid gap-1">
                              <div>{t("Is Lartas")}</div>
                              <div className="text-center">
                                <input
                                  type="checkbox"
                                  name="is_lartas"
                                  style={{ width: 20, height: 20 }}
                                  checked={updateAll.is_lartas}
                                  onChange={handleChecked}
                                />
                              </div>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="grid gap-1">
                              <div>{t("Shipping fee/kg")}</div>
                              <div>

                                <input
                                  type="number"
                                  name="shippingfee"
                                  onKeyPress={(e) => {
                                    if (!/[0-9]|\./.test(e.key)) {
                                      e.preventDefault();
                                    }
                                  }}
                                  value={updateAll.shippingfee}
                                  placeholder="RMB"
                                  className="border border-gray-400 rounded-md p-2 w-32 focus:outline-blue"
                                  onChange={handleInputChange}
                                  onWheel={(e) => e.target.blur()}
                                />
                              </div>
                            </div>
                          </TableCell>

                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {isLoadingQuotationChange ? (
                          <TableRow>
                            <TableCell colSpan={13} align='center'>
                              <CircularProgress size={30} className="text-white" />
                            </TableCell>
                          </TableRow>
                        ) : volume?.map((t, i) => {
                          return (
                            <Fragment key={i}>
                              <TableRow>
                                <TableCell colSpan={13} sx={{ borderBottom: "none !important" }}>
                                  <div className="flex flex-col">
                                    <div className="flex space-x-2 items-center">
                                      <div className="w-10">
                                        <img
                                          src={`${t.image ? t.image : "/no-image.png"
                                            } `}
                                          alt="product"
                                          className="rounded-md w-full object-cover"
                                        />
                                      </div>
                                      <div className="flex flex-col">
                                        <div>
                                          <Tooltip title={t.variant}>
                                            <p className="font-semibold text-sm line-clamp-1 cursor-pointer">
                                              {t.variant}
                                            </p>
                                          </Tooltip>
                                          {t.rollback && (
                                            <p className="text-red-500 text-sm">
                                              {JSON.parse(t.rollback).reason}
                                            </p>
                                          )}
                                        </div>
                                        <div
                                          className={`${rowData.is_oem
                                            ? ""
                                            : "hidden"
                                            }`}>
                                          <p className="font-bold text-red-600 text-sm ">
                                            Ordered quantity :{" "}
                                            {t.quantity_customer}
                                          </p>
                                        </div>
                                      </div>
                                      {/* {
                                          listVariantTemp && (
                                            <select 
                                              name='new_variant'
                                              value={volume[i]?.new_variant}
                                              onChange={(e) => handleInputChange(e, i,'variant')}
                                              className='bg-white shadow rounded'>
                                              <option value="">select variant</option>
                                              {
                                                listVariantTemp
                                                  .filter(item => {
                                                    if(item.id_product == t.new_variant){
                                                      return true
                                                    }
                                                    const cvItem = volume.find(v =>v.new_variant == item.id_product)
                                                    if (cvItem) {
                                                      return false
                                                    } else {
                                                      return true
                                                    }
                                                  })
                                                  .map(item => {
                                                  return (
                                                    <option value={item.id_product}>{item.variant}</option>
                                                  )
                                                })
                                              }
                                            </select>
                                          )
                                        } */}
                                    </div>
                                  </div>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <input
                                    type="checkbox"
                                    name="isChecked"
                                    style={{ width: 20, height: 20 }}
                                    checked={t?.isChecked}
                                    onChange={(e) => handleChecked(e, i)}
                                  />
                                </TableCell>

                                <TableCell>
                                  <input
                                    type="number"
                                    name="price"
                                    value={volume[i]?.price}
                                    onKeyPress={(e) => {
                                      if (!/[0-9]|\./.test(e.key)) {
                                        e.preventDefault();
                                      }
                                    }}
                                    placeholder="RMB"
                                    className="border border-gray-400 rounded-md p-2 w-32 focus:outline-blue"
                                    onChange={(e) =>
                                      handleInputChange(e, i, "variant")
                                    }
                                    onWheel={(e) => e.target.blur()}
                                  />
                                </TableCell>
                                {
                                  rowData.is_oem && (
                                    <TableCell>
                                      <ToggleComp
                                        name="customize_logo"
                                        checked={t?.customize_logo}
                                        onChange={(e) => handleChecked(e, i)}
                                      />
                                    </TableCell>
                                  )
                                }
                                {rowData.is_oem && t?.logo !== null && (
                                  <TableCell>
                                    <div className="cursor-pointer">
                                      <a
                                        href={download(t?.logo)}
                                        className="px-2 py-1 rounded bg-gray-400 text-white">
                                        Download
                                      </a>
                                    </div>
                                  </TableCell>
                                )}

                                <TableCell>
                                  <input
                                    type="number"
                                    name="kuantiti_kelipatan"
                                    onKeyPress={(e) => {
                                      if (!/[0-9]/.test(e.key)) {
                                        e.preventDefault();
                                      }
                                    }}
                                    value={volume[i]?.kuantiti_kelipatan}
                                    placeholder="Multiple Qty"
                                    className="border border-gray-400 rounded-md p-2 w-32 focus:outline-blue"
                                    onChange={(e) =>
                                      handleInputChange(e, i, "variant")
                                    }
                                    onWheel={(e) => e.target.blur()}
                                  />
                                </TableCell>
                                <TableCell>
                                  <input
                                    type="number"
                                    name="min_purchase"
                                    onKeyPress={(e) => {
                                      if (!/[0-9]/.test(e.key)) {
                                        e.preventDefault();
                                      }
                                    }}
                                    value={volume[i]?.min_purchase}
                                    placeholder="Min Purchase"
                                    className="border border-gray-400 rounded-md p-2 w-32 focus:outline-blue"
                                    onChange={(e) =>
                                      handleInputChange(e, i, "variant")
                                    }
                                    onWheel={(e) => e.target.blur()}
                                  />
                                </TableCell>
                                <TableCell>
                                  <input
                                    type="number"
                                    name="quantity"
                                    onKeyPress={(e) => {
                                      if (!/[0-9]/.test(e.key)) {
                                        e.preventDefault();
                                      }
                                    }}
                                    value={volume[i]?.quantity}
                                    placeholder="Qty/Carton"
                                    className="border border-gray-400 rounded-md p-2 w-32 focus:outline-blue"
                                    onChange={(e) =>
                                      handleInputChange(e, i, "variant")
                                    }
                                    onWheel={(e) => e.target.blur()}
                                  />
                                </TableCell>
                                <TableCell>
                                  <input
                                    type="number"
                                    name="weight"
                                    value={volume[i]?.weight}
                                    onKeyPress={(e) => {
                                      if (!/[0-9]|\./.test(e.key)) {
                                        e.preventDefault();
                                      }
                                    }}
                                    placeholder="Weight (Gram)"
                                    className="border border-gray-400 rounded-md p-2 w-32 focus:outline-blue"
                                    onChange={(e) =>
                                      handleInputChange(e, i, "variant")
                                    }
                                    onWheel={(e) => e.target.blur()}
                                  />
                                </TableCell>

                                <TableCell>
                                  <input
                                    type="number"
                                    name="width"
                                    value={volume[i]?.width}
                                    onKeyPress={(e) => {
                                      if (!/[0-9]|\./.test(e.key)) {
                                        e.preventDefault();
                                      }
                                    }}
                                    placeholder="Width (CM)"
                                    className="border border-gray-400 rounded-md p-2 w-32 focus:outline-blue"
                                    onChange={(e) =>
                                      handleInputChange(e, i, "variant")
                                    }
                                    onWheel={(e) => e.target.blur()}
                                  />
                                </TableCell>
                                <TableCell>
                                  <input
                                    type="number"
                                    name="length"
                                    value={volume[i]?.length}
                                    onKeyPress={(e) => {
                                      if (!/[0-9]|\./.test(e.key)) {
                                        e.preventDefault();
                                      }
                                    }}
                                    placeholder="Length (CM)"
                                    className="border border-gray-400 rounded-md p-2 w-32 focus:outline-blue my-2"
                                    onChange={(e) =>
                                      handleInputChange(e, i, "variant")
                                    }
                                    onWheel={(e) => e.target.blur()}
                                  />
                                </TableCell>
                                <TableCell>
                                  <input
                                    type="number"
                                    name="height"
                                    value={volume[i]?.height}
                                    onKeyPress={(e) => {
                                      if (!/[0-9]|\./.test(e.key)) {
                                        e.preventDefault();
                                      }
                                    }}
                                    placeholder="Height (CM)"
                                    className="border border-gray-400 rounded-md p-2 w-32 focus:outline-blue"
                                    onChange={(e) =>
                                      handleInputChange(e, i, "variant")
                                    }
                                    onWheel={(e) => e.target.blur()}
                                  />
                                </TableCell>
                                <TableCell>
                                  <input
                                    type="number"
                                    name="hscode"
                                    value={volume[i]?.hscode}
                                    onKeyPress={(e) => {
                                      if (!/[0-9]/.test(e.key)) {
                                        e.preventDefault();
                                      }
                                    }}
                                    placeholder="HS-Code"
                                    className="border border-gray-400 rounded-md p-2 w-32 focus:outline-blue"
                                    onChange={(e) =>
                                      handleInputChange(e, i, "variant")
                                    }
                                    onWheel={(e) => e.target.blur()}
                                  />
                                </TableCell>
                                <TableCell>
                                  <div
                                    className={`w-16 flex justify-center`}>
                                    <input
                                      type="checkbox"
                                      name="is_lartas"
                                      style={{ width: 20, height: 20 }}
                                      checked={t?.is_lartas}
                                      onChange={(e) => handleChecked(e, i)}
                                    />
                                  </div>
                                </TableCell>

                                <TableCell>
                                  <input
                                    type="number"
                                    name="shippingfee"
                                    onKeyPress={(e) => {
                                      if (!/[0-9]|\./.test(e.key)) {
                                        e.preventDefault();
                                      }
                                    }}
                                    value={volume[i]?.shippingfee}
                                    placeholder="RMB"
                                    className="border border-gray-400 rounded-md p-2 w-32 focus:outline-blue"
                                    onChange={(e) =>
                                      handleInputChange(e, i, "variant")
                                    }
                                    onWheel={(e) => e.target.blur()}
                                  />
                                </TableCell>
                              </TableRow>
                            </Fragment>
                          )
                        })
                        }
                      </TableBody>

                    </Table>
                  </TableContainer>

                </div>
              </div>
              <div className="flex justify-between">
                <div className="flex items-center space-x-2">
                  <input
                    disabled={boxValue}
                    type="checkbox"
                    style={{ width: 20, height: 20 }}
                    checked={box}
                    onChange={() => setBox(!box)}
                  />
                  <label className="font-semibold">
                    {t("Customer is required to buy per box")}
                  </label>
                </div>
                <div className="flex">
                  {/* { errorProductLink == 'valid' && (
                    <button className='px-3 py-2 bg-blue-500 text-white rounded mr-3' onClick={handleQuotationChange}>Quotation Change</button>
                  )} */}
                  <UpdateModal
                    id={id}
                    link={link}
                    setUpdate={setUpdate}
                    variantsUpdate={variantData
                      ?.filter((data) => data?.status !== "open")
                      .map((data) => {
                        return {
                          variant: data.variant,
                          status: data.status,
                          reason: data.reason,
                          image: data.image,
                          id_product: data.id_product,
                          quantity: data.quantity,
                          width: data.width ?? "",
                          length: data.length ?? "",
                          height: data.height ?? "",
                          weight: data.weight ?? "",
                          price: data.price ?? "",
                          isChecked: false,
                        };
                      })}
                    minQty={minQty}
                    boxValue={boxValue}
                    category={category}
                  />
                </div>
              </div>
              {/* Check-out Notes for customers */}

              {rowData.is_oem && (
                <div>
                  <div className="rounded ml-5 mb-10 text-[19px]">
                    <div className="border w-1/2 py-5 px-5">
                      <p className="mb-3">
                        <b>Check-out Notes for Customer</b>
                      </p>
                      <p>
                        Please fill these columns out if there are any
                        requirements from supplier
                      </p>
                      <div className="flex items-center mb-2">
                        <p>File type:</p>
                        <div className="h-full w-24 h-10 flex justify-center border-r-4">
                          <div className="flex items-center">
                            <input
                              id="all"
                              type="checkbox"
                              disabled={checkAll}
                              onClick={handleCheckboxChange}
                              value="all"
                              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-full  dark:focus:ring-blue-600 dark:ring-offset-gray-800  dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label
                              htmlFor="all"
                              className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                              All
                            </label>
                          </div>
                        </div>
                        <div className="flex flex-col">
                          <div className="flex ml-3 mb-2">
                            <div className="flex items-center w-16">
                              <input
                                id="jpg"
                                disabled={checked.jpg}
                                checked={checkboxes.jpg}
                                type="checkbox"
                                name="jpg"
                                onClick={handleCheckboxChange}
                                value="jpg"
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded  dark:focus:ring-blue-600 dark:ring-offset-gray-800  dark:bg-gray-700 dark:border-gray-600"
                              />
                              <label
                                htmlFor="jpg"
                                className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                JPG
                              </label>
                            </div>
                            <div className="flex items-center w-16">
                              <input
                                id="checkbox"
                                type="checkbox"
                                name="jpeg"
                                checked={checkboxes.jpeg}
                                disabled={checked.jpeg}
                                onClick={handleCheckboxChange}
                                value="jpeg"
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded  dark:focus:ring-blue-600 dark:ring-offset-gray-800  dark:bg-gray-700 dark:border-gray-600"
                              />
                              <label
                                htmlFor="checkbox"
                                className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                JPEG
                              </label>
                            </div>
                            <div className="flex items-center w-16">
                              <input
                                id="pdf"
                                type="checkbox"
                                name="pdf"
                                disabled={checked.pdf}
                                checked={checkboxes.pdf}
                                onClick={handleCheckboxChange}
                                value="pdf"
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded  dark:focus:ring-blue-600 dark:ring-offset-gray-800  dark:bg-gray-700 dark:border-gray-600"
                              />
                              <label
                                htmlFor="PDF"
                                className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                PDF
                              </label>
                            </div>
                            <div className="flex items-center w-16">
                              <input
                                id="png"
                                name="png"
                                type="checkbox"
                                disabled={checked.png}
                                checked={checkboxes.png}
                                onClick={handleCheckboxChange}
                                value="png"
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 roundeddark:focus:ring-blue-600 dark:ring-offset-gray-800  dark:bg-gray-700 dark:border-gray-600"
                              />
                              <label
                                htmlFor="png"
                                className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                PNG
                              </label>
                            </div>
                            <div className="flex items-center w-16">
                              <input
                                id="gif"
                                name="gif"
                                type="checkbox"
                                disabled={checked.gif}
                                checked={checkboxes.gif}
                                onClick={handleCheckboxChange}
                                value="gif"
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded dark:focus:ring-blue-600 dark:ring-offset-gray-800  dark:bg-gray-700 dark:border-gray-600"
                              />
                              <label
                                htmlFor="gif"
                                className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                GIF
                              </label>
                            </div>
                          </div>
                          <div className="flex ml-3">
                            <div className="flex items-center w-16">
                              <input
                                id="svg"
                                type="checkbox"
                                name="svg"
                                disabled={checked.svg}
                                checked={checkboxes.svg}
                                onClick={handleCheckboxChange}
                                value="svg"
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded dark:focus:ring-blue-600 dark:ring-offset-gray-800  dark:bg-gray-700 dark:border-gray-600"
                              />
                              <label
                                htmlFor="svg"
                                className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                SVG
                              </label>
                            </div>
                            <div className="flex items-center w-16">
                              <input
                                id="psd"
                                type="checkbox"
                                name="psd"
                                disabled={checked.psd}
                                checked={checkboxes.psd}
                                onClick={handleCheckboxChange}
                                value="psd"
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                              />
                              <label
                                htmlFor="psd"
                                className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                PSD
                              </label>
                            </div>
                            <div className="flex items-center w-16">
                              <input
                                id="cdr"
                                type="checkbox"
                                name="cdr"
                                disabled={checked.cdr}
                                checked={checkboxes.cdr}
                                onClick={handleCheckboxChange}
                                value="cdr"
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                              />
                              <label
                                htmlFor="cdr"
                                className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                CDR
                              </label>
                            </div>
                            <div className="flex items-center w-16">
                              <input
                                id="raw"
                                type="checkbox"
                                disabled={checked.raw}
                                checked={checkboxes.raw}
                                name="raw"
                                onClick={handleCheckboxChange}
                                value="raw"
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800  dark:bg-gray-700 dark:border-gray-600"
                              />
                              <label
                                htmlFor="raw"
                                className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                RAW
                              </label>
                            </div>
                            <div className="flex items-center w-16">
                              <input
                                id="ai"
                                type="checkbox"
                                disabled={checked.ai}
                                checked={checkboxes.ai}
                                name="ai"
                                onClick={handleCheckboxChange}
                                value="ai"
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800  dark:bg-gray-700 dark:border-gray-600"
                              />
                              <label
                                htmlFor="ai"
                                className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                AI
                              </label>
                            </div>
                          </div>
                        </div>
                        {/* 
                        <div className="w-40">
                          <select
                            value={fileType}
                            onChange={(e) => setFileType(e.target.value)}
                            className="ml-3 border border-gray-400 rounded-md p-1 focus:outline-blue w-full"
                            name="file_type"
                            id="lang">
                            <option value="">Select Type</option>
                            <option value="JPG">JPG</option>
                            <option value="JPEG">JPEG</option>
                            <option value="PDF">PDF</option>
                            <option value="PNG">PNG</option>
                            <option value="GIF">GIF</option>
                            <option value="SVG">SVG</option>
                            <option value="PSD">psd</option>
                            <option value="CDR">CDR</option>
                            <option value="AI">AI</option>
                            <option value="RAW">RAW</option>
                          </select>
                        </div> */}
                      </div>
                      <div className="flex items-center">
                        <p>Resolutions :</p>
                        <input
                          type="number"
                          name="hpixel"
                          min="1"
                          oninput="validity.valid||(value='');"
                          value={hpixel}
                          onChange={(e) => setHpixel(e.target.value)}
                          className="ml-3 border border-gray-400 rounded-md p-1 w-28 focus:outline-blue"
                        />
                        <p className="ml-2">pixels</p>{" "}
                        <p className="ml-3">
                          <b>X</b>
                        </p>
                        <input
                          type="number"
                          name="wpixel"
                          min="1"
                          oninput="validity.valid||(value='');"
                          value={wpixel}
                          className="ml-2 border border-gray-400 rounded-md p-1 w-28 focus:outline-blue"
                          onChange={(e) => setWpixel(e.target.value)}
                        />
                        <p className="ml-3">pixels</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* End Check out */}

              <div className="text-center">
                {isLoadingButton ? (
                  <div className="inline-flex items-center justify-center gap-x-2 bg-blue-400 text-white  rounded-md py-2 px-6">
                    <CircularProgress size={20} className="text-white" />
                  </div>
                ) : (
                  <button
                    // disabled={link != dataLink && changeQuotationNewLink == ""}
                    className="bg-blue-400 hover:bg-blue-300 text-white  rounded-md py-2 px-6"
                    onClick={submitRequest}>
                    Submit
                  </button>
                )}
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}

function ModalImage({ image }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <button onClick={handleOpen}>
        <img
          src={image ? image : "/no-image.png"}
          alt="product"
          className="w-full rounded-md"
        />
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={styleModalImage}>
          <div className="w-full h-full relative">
            <IconButton
              onClick={handleClose}
              style={{
                position: "absolute",
                top: 5,
                right: 5,
                color: "red",
              }}>
              <CloseIcon />
            </IconButton>
            <img
              src={image ? image : "/no-image.png"}
              alt="product"
              className="object-contain"
            />
          </div>
        </Box>
      </Modal>
    </div>
  );
}

function ToggleComp({ checked, name, onChange }) {
  const inpRef = useRef(null);
  return (
    <div>
      <input
        type="checkbox"
        checked={checked}
        name={name}
        onChange={onChange}
        className={"hidden"}
        style={{ appearance: "none" }}
        ref={inpRef}
      />
      <div
        className={
          "rounded-full border border-gray-400 cursor-pointer transition " +
          (checked ? "bg-green-500" : "bg-gray-200")
        }
        onClick={(e) => {
          inpRef.current.click();
        }}
        style={{ width: "calc(4rem)" }}>
        <div
          className={
            "w-8 h-8 bg-white shadow rounded-full transition " +
            (checked && "translate-x-full")
          }></div>
      </div>
    </div>
  );
}
