import React, { useEffect, useState } from 'react';
import Layout from 'components/Layout';
import SalesTable from 'components/ManagementSales/SalesTarget/SalesTable';
import { getSalesData } from 'service/api';
import FilterSalesTarget from 'components/ManagementSales/SalesTarget/FilterSalesTarget';
import { SubRoutesManagementSales as SUBROUTES } from 'components/ManagementSales/SubRoutesManagementSales';

const SalesTarget = () => {
  const [salesData, setSalesData] = useState([]);
  const [salesDataNew, setsalesDataNew] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  const fetchSalesData = async () => {
    setIsLoading(true);

    const data = await getSalesData();

    if (data?.status === 200) {
      setSalesData(data.data);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchSalesData();
  }, [update]);

  useEffect(() => {
    setSalesData(salesDataNew);
  }, [salesDataNew, isFiltered]);

  return (
    <Layout routes={SUBROUTES()} title="CRM">
      <p className="my-4 bg-white w-28 p-2 rounded-md cursor-pointer text-center">
        Sales Target
      </p>
      <FilterSalesTarget
        setIsLoading={setIsLoading}
        setIsFiltered={setIsFiltered}
        setsalesDataNew={setsalesDataNew}
      />

      <SalesTable
        isLoading={isLoading}
        setUpdate={setUpdate}
        salesData={salesData}
      />
    </Layout>
  );
};

export default SalesTarget;
