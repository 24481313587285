import {
  CircularProgress,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  TableCell,
  IconButton,
  Collapse,
  Typography,
  Modal,
} from "@mui/material";
import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// import Notes from "../../General/Notes";
// import DetailListBoxTable from "./DetailListBoxTable";
// import DetailModal from "../../General/DetailOrderModal/DetailModal";
import PaginationFilter from "../../General/PaginationFilter";
// import GenerateBarcode from "./GenerateBarcode";
// import EditRepackingInput from "../Repacking/EditRepackingInput";
// import Filters from "components/General/Filters";
// import BadgeLevel from "components/General/BadgeLevel";
import { getUser } from "helpers/parseJWT";
import DetailListBoxTableV2 from "./DetailListBoxTableV2";
// import { export_marking_code_to_excel } from "service/api";
import Cookies from "js-cookie";
import CloseIcon from '@mui/icons-material/Close';
import { Check } from '@mui/icons-material';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

export default function SendToIdnTableV2({
  isLoading,
  idSo,
  setIdSo,
  page,
  pageInfo,
  setPage,
  limit,
  setLimit,
  date,
  setDate,
  dataOrder,
  setUpdate,
  select,
  setSelect,
  isActive,
  setIsActive,
  actionSubmit,
  highlightIdSo,
  setInputContainerData,
  setShowModalInputContainer,
}) {
  const [loadingExport, setLoadingExport] = useState(false)

  const { t } = useTranslation()
  
  const renderLoading = isLoading ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "start",
        padding: "10px",
        borderRadius: 2,
        backgroundColor: "white",
        marginBottom: 1,
      }}>
      <div className="flex space-x-3 items-center w-full bg-blue-100 p-3 rounded-md">
        <CircularProgress size={20} />
        <p className="text-gray-500 text-sm ">Updating data ...</p>
      </div>
    </Box>
  ) : null;

  const Row = ({ rowData, id }) => {
    const [open, setOpen] = useState(false);
    return (
      <>
        <TableRow style={{ display: "flex" }}>
          <TableCell
            align= 'center'
            style={{
              width: "20%",
            }}>
            <div className="h-full flex flex-col justify-between">
              <div className="text-lg font-bold">
                {rowData.forwarder}-{rowData.zone}-{rowData.marking_code}
              </div>
              <div className="text-center">
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => {
                    setOpen(!open);
                  }}>
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
                <span className="text-xs">Details</span>
              </div>
            </div>
          </TableCell>
          <TableCell
            align= 'center'
            style={{
              width: "15%",
            }}>
            {rowData.container}
          </TableCell>
          <TableCell
            align= 'center'
            style={{
              width: "15%",
            }}>
            {rowData.total_volume}
          </TableCell>
          {/* <TableCell
            style={{
              width: "13%",
            }}>
            Rp. {parseInt(rowData.estimed_cost_shipping).toLocaleString('id-ID')}
          </TableCell>
          <TableCell
            style={{
              width: "13%",
            }}>
            Rp. {parseInt(rowData.cogs).toLocaleString('id-ID')}
          </TableCell>
          <TableCell
            style={{
              width: "15%",
            }}>
            Rp. {parseInt(rowData.income).toLocaleString('id-ID')}
          </TableCell> */}
          <TableCell
            align= 'center'
            style={{
              width: "20%",
            }}>
            {rowData.status ?? '-'}
          </TableCell>
          {/* <TableCell
            style={{
              width: "13%",
            }}>
            Rp. {parseInt(rowData.profit).toLocaleString('id-ID') ?? "0"}
          </TableCell> */}
          <TableCell
            align= 'center'
            style={{
              width: "15%",
            }}>
            {rowData.is_active ? <Check className="text-green-600" /> : <CloseIcon className="text-red-600" />}
          </TableCell>
          <TableCell
            align= 'center'
            style={{
              width: "15%",
            }}>
            <div className="mb-2 bg-blue-500 text-white p-2 rounded-md cursor-pointer text-center"
              onClick={() => {
                setInputContainerData(rowData)
                setShowModalInputContainer(true)
              }}
            >
              {t("Input")}
            </div>
            <div className="mb-2 bg-orange-500 text-white p-2 rounded-md cursor-pointer text-center"
              onClick={() => !loadingExport && exportToExcel(rowData.marking_code)}
            >
              {loadingExport === rowData.marking_code ? "Loading..." : "Export"}
            </div>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Detail List Box
                </Typography>
                <DetailListBoxTableV2
                  dataOrder={rowData}
                  setUpdate={setUpdate}
                  isLoading={isLoading}
                  highlightIdSo={highlightIdSo}
                />
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  const exportToExcel = (code) => {
    setLoadingExport(code);
    const URL_DOWNLOAD = `${process.env.REACT_APP_URL_API_GATEWAY_NEW_GOLANG}/logistic-china/send-to-idn/export?marking_code=${code}`;
    const tokenCookies = Cookies.get('oms_token');

    let header = {
      Authorization: `Bearer ${tokenCookies}`,
    }

    if(process.env.REACT_APP_ENV === 'DEVELOPMENT') {
      header = {
        ...header,
        key: 'omsjaya',
        Xuser: getUser()?.user,
        Xrole: getUser()?.role
      }
    }
    
    fetch(URL_DOWNLOAD, {
      method: 'GET',
      responseType: 'blob',
      headers: header,
    })
      .then((response) => {
        if (response.status === 200) {
          return response.blob();
        } else {
          toast.error(`failed export file : ${response?.status}`, {className: "bg-red-500"})
        }
        setLoadingExport(false);
      })
      .then((data) => {
        var a = document.createElement('a');
        a.href = window.URL.createObjectURL(data);
        a.download = `MARKING_CODE_${code}`;
        a.click();
      })
      .catch((err) => {
        setLoadingExport(false);
        toast.error('Oops, gagal export data. Coba lagi!', {className: "bg-red-500"})
      });
  };

  return (
    <>
      {renderLoading}
      <div className="flex items-center justify-between flex-wrap gap-y-3 bg-white p-2 px-3 rounded-md my-2">
        <div className="shrink-0 flex items-center gap-x-3 text-sm font-semibold">
          {pageInfo?.dataInPage
            ? `${t("Showing data")} ${pageInfo?.dataInPage} ${t("of")} ${pageInfo?.totalData}`
            : null}

          <span>Filter :</span>

          <div className="filter-idso">
            {/* Search: */}
            <input
              type="text"
              placeholder={t("search by order id")}
              className="border rounded-md px-2 py-1 ml-1"
              onChange={(e) => setIdSo(e.target.value)}
              // onKeyDown={searchOrderById}
            />
          </div>

          <div className="flex items-center text-sm space-x-3">
            <div className="flex items-center space-x-3">
              <select
                name="is_active"
                value={isActive}
                onChange={(e) => setIsActive(e.target.value)}
                className="border border-gray-300 p-1 rounded-md focus-within:outline-blue"
              >
                <option value="">Select status active</option>
                <option value="true">Active</option>
                <option value="false">In-Active</option>
              </select>
            </div>
          </div>
          {/* <Filters
            filterToko={false}
            date={date}
            setDate={setDate}
            setUpdate={setUpdate}
            actionSubmit={actionSubmit}
          /> */}
          
        <button
          className="text-xs px-3 py-1 text-white rounded-md cursor-pointer bg-blue-500 hover:bg-blue-600"
          onClick={e => actionSubmit(idSo)}>
          Filter
        </button>
        </div>
        <div className="shrink-0 flex items-center text-sm space-x-3 ml-auto">
          {/* <div className="flex items-center space-x-3">
            <p>Group By:</p>
            <select
              name="select"
              value={select}
              onChange={(e) => setSelect(e.target.value)}
              className="border border-gray-300 p-1 rounded-md focus-within:outline-blue">
              <option value="" disabled>
                Select Group
              </option>
              <option value="invoice">PO Number</option>
              <option value="id-order">ID Order</option>
            </select>
          </div> */}
          <PaginationFilter
            page={page}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            totalPage={pageInfo.totalPage}
          />
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow style={{ display: "flex" }}>
              <TableCell
                align= 'center'
                style={{
                  width: "20%",
                }}>
                Marking Code
              </TableCell>
              <TableCell
                align= 'center'
                style={{
                  width: "15%",
                }}>
                Container
              </TableCell>
              <TableCell
                align= 'center'
                style={{
                  width: "15%",
                }}>
                Total Volume (M<sup>3</sup>)
              </TableCell>
              {/* <TableCell
                align= 'center'
                style={{
                  width: "13%",
                }}>
                Estimasi Ongkir
              </TableCell>
              <TableCell
                align= 'center'
                style={{
                  width: "13%",
                }}>
                Total COGS Value
              </TableCell>
              <TableCell
                align= 'center'
                style={{
                  width: "15%",
                }}>
                Total Order Value
              </TableCell> */}
              <TableCell
                align= 'center'
                style={{
                  width: "20%",
                }}>
                Status
              </TableCell>
              {/* <TableCell
                align= 'center'
                style={{
                  width: "13%",
                }}>
                Profit
              </TableCell> */}
              <TableCell
                align= 'center'
                style={{
                  width: "15%",
                }}>
                Active
              </TableCell>
              <TableCell
                align= 'center'
                style={{
                  width: "15%",
                }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell
                  colSpan={5}
                  align="center"
                  style={{ height: "70vh" }}>
                  Loading...
                </TableCell>
              </TableRow>
            ) : dataOrder?.length > 0 ? (
              <>
                {dataOrder?.map((row, index) => (
                  <Row rowData={row} key={index} id={index} />
                ))}

                {dataOrder?.length <= 3 && (
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      align="center"
                      style={{ height: "50vh" }}
                    />
                  </TableRow>
                )}
              </>
            ) : (
              <TableRow>
                <TableCell
                  colSpan={5}
                  align="center"
                  style={{ height: "70vh" }}>
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <ToastContainer />
    </>
  );
}
