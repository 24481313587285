import Layout from 'components/Layout';
import React, { useState, useEffect } from 'react';
import { SubRoutesManagementSales as SUBROUTES } from 'components/ManagementSales/SubRoutesManagementSales';
import Main from 'components/ManagementSales/OCS/Main';

const OCS = () => {
  return (
    <Layout routes={SUBROUTES()} title="OCS">
        <Main/>
    </Layout>
  );
};

export default OCS;
