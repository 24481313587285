import React from 'react'

function BadgeLevel({level}) {
    
  return level == "SILVER" ? (
        <p className='bg-gray-400 border border-gray-600 text-white rounded px-2 py-1 mt-1 inline-block'>{level}</p>
    ) : level == "GOLD" ? (
        <p className='bg-yellow-300 border border-yellow-500 text-white rounded px-2 py-1 mt-1 inline-block'>{level}</p>
    ) : level == "DIAMOND" && (
        <p className='bg-blue-200 border border-blue-500 text-white rounded px-2 py-1 mt-1 inline-block'>{level}</p>
    )
}

export default BadgeLevel