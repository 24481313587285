import React, { useState } from 'react';
import GudangTabel from './GudangTabel';
import { getUser } from 'helpers/parseJWT';

const LayoutTable = ({ isLoading, inputs, data, listDriver, handleChangeDate, handleSubmit }) => {

  return (
    <>
    <div className="w-full h-[730px] rounded-t-xl border-2 border-gray-300 overflow-y-scroll relative">
      <div className="bg-blue-200 sticky top-0">
        <div className="flex justify-center items-center py-2 px-2 border-b-4 border-gray-200">
          <div className="text-sm text-center w-[10%]">No. Palet</div>
          <div className="text-sm text-center w-[10%]">ID Karton</div>
          <div className="text-sm text-center w-[10%]">Wilayah</div>
          <div className="text-sm text-center w-[15%]">Alamat</div>
          <div className="text-sm text-center w-[10%]">Kurir</div>
          <div className="text-sm text-center w-[10%]">Customer</div>
          <div className="text-sm text-center w-[10%]">No Kontainer</div>
          <div className="text-sm text-center w-[5%]">Palet</div>
          <div className="text-sm text-center w-[10%]">Supir</div>
          {getUser().roles?.includes('admin') &&
            <div className="text-sm text-center w-[10%] flex flex-col justify-center items-center">
              Jadwal
              {/* <input
                type="text"
                min={new Date().toLocaleDateString('en-ca')}
                name={`${inputs?.resultPencarian ? 'allFiltered' : 'all'}`}
                onChange={(e) => handleChangeDate(e)}
                className="w-full rounded-md text-center border border-gray-200 focus:outline-blue cursor-pointer hover:outline-blue"
                onFocus={(e) => (e.target.type = 'date')}
                onBlur={(e) => (e.target.type = 'text')}
              /> */}
            </div>
          }
        </div>
      </div>

      {isLoading ? (
        <div className="flex items-center justify-center h-full mt-10">
          <p className="text-gray-500 text-xl animate-pulse">
            Sedang Mengambil Data . . .
          </p>
        </div>
      ) : inputs?.resultPencarian?.length === 0 && data?.length === 0 ? (
        <div className="flex items-center justify-center h-full mt-10">
          <p className="text-gray-500 text-xl animate-bounce text-center">
            Oops, Data Tidak DiTemukan.
          </p>
        </div>
      ) : (
        <GudangTabel
          name=""
          dataToMap={inputs?.resultPencarian ? inputs?.resultPencarian : data}
          listDriver={listDriver}
          handleChangeDate={handleChangeDate}
          handleSubmit={handleSubmit}
        />
      )}
    </div>
    </>
  );
};

export default LayoutTable;
