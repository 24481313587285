import Layout from 'components/Layout';
import GenerateVoucher from 'components/ManagementSales/VoucherGenartor/GenerateVoucher';
import ListVoucherTable from 'components/ManagementSales/VoucherGenartor/ListVoucherTable';
import { useState, useEffect } from 'react';
import { getVoucherData } from 'service/api';
import { Tooltip } from '@mui/material';
import { SubRoutesManagementSales as SUBROUTES } from 'components/ManagementSales/SubRoutesManagementSales';

const VoucherGenerator = () => {
  const [voucherData, setVoucherData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [update, setUpdate] = useState(false);

  const fetchVoucherData = async () => {
    setIsLoading(true);
    const data = await getVoucherData();
    if (data) {
      setVoucherData(data.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchVoucherData();
  }, [update]);
  return (
    <Layout routes={SUBROUTES()} title="CRM">
      <Tooltip title="Refresh table" placement="right">
        <p
          className="my-4 bg-white w-40 p-2 text-center rounded-md cursor-pointer"
          onClick={fetchVoucherData}>
          Voucher Generator
        </p>
      </Tooltip>
      <GenerateVoucher setUpdate={setUpdate} />
      <ListVoucherTable isLoading={isLoading} voucherData={voucherData} />
    </Layout>
  );
};

export default VoucherGenerator;
