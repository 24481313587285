import { useEffect, useState, useRef } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  CircularProgress,
  IconButton,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  Paper,
  TableRow,
  Typography,
  Modal,
  Fade,
  Box,
  Backdrop,
} from "@mui/material";
import { getReportDetailProduct, get_order_report_product, solve_issue_sales_table, submit_approve_or_reject } from 'service/api';
import swal from 'sweetalert';
import NumberFormat from 'react-number-format';
import AdjustmentApprovalTable from './AdjustmentApprovalTable';
import AddAdjustmentsModal from '../AddAdjustmentsModal';

const style = {
  position: 'absolute',
  top: 25,
  left: '5%',
  // left: '50%',
  // transform: 'translate(0, -50%)',
  width: '90vw',
  height: 'auto',
  maxHeight: "90vh",
  overFlowY: "auto",
  bgcolor: '#f8f8f8',
  border: '1px solid lightgray',
  boxShadow: 24,
  borderRadius: '5px',
  p: 4,
};
const styleAddAdjustments = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  height: 'auto',
  maxHeight: "85vh",
  overFlowY: "auto",
  bgcolor: '#f8f8f8',
  border: '1px solid lightgray',
  boxShadow: 24,
  borderRadius: '5px',
  p: 4,
};

const ApprovalModal = ({ data, idOrder, status, issue, setUpdate }) => {
  const [products, setProducts] = useState(null);
  const [productsAfter, setProductsAfter] = useState(null);
  const [totalPaid, setTotalPaid] = useState(0);
  const [finalTotal, setFinalTotal] = useState(0)
  const [finalQtyAll, setFinalQtyAll] = useState("");
  const [isQtyChanged, setIsQtyChanged] = useState(false);
  const [solution, setSolution] = useState('');
  const [additionalShipping, setAdditionalShipping] = useState(0);
  const [localShipping, setLocalShipping] = useState(0);
  const [initialShippingFee, setInitialShippingFee] = useState(0);
  const [open, setOpen] = useState(false);
  const [isChangeSupplier, setIsChangeSupplier] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showAddAdjustments, setShowAddAdjustments] = useState(false);
  const [dataChanged, setDataChanged] = useState(false);
  const [adjustment, setAdjustment] = useState({
    asuransi: '',
    inspeksi: '',
    packingKayu: '',
    ongkir_international: '',
  });
  const [total_adjustment, setTotal_adjustment] = useState(0)
  // const total_adjustment = useRef(0) 
  const handleOpen = () => fetchData();
  const handleClose = () => setOpen(false);

  useEffect(() => {
    let asuransi = ~~(adjustment.asuransi.toString().replaceAll(".", ""))
    let inspeksi = ~~(adjustment.inspeksi.toString().replaceAll(".", ""))
    let packingKayu = ~~(adjustment.packingKayu.toString().replaceAll(".", ""))
    let ongkir_international = ~~(adjustment.ongkir_international.toString().replaceAll(".", ""))
  
    setTotal_adjustment(~~asuransi + ~~inspeksi + ~~packingKayu + ~~ongkir_international)
  }, [adjustment])

  const fetchData = async () => {
    setIsLoading(true);
    const response = await get_order_report_product(idOrder);
    if (response?.status === 200) {
      if(response?.data.is_change_supplier) {
        let newProductWithFinalQty = response.data.change_variant.product_before
        newProductWithFinalQty?.forEach((prod, index) => {
          prod.final_qty = response.data.change_variant.product_after[index].qty < response.data.change_variant.product_after[index].moq ? 0 : response.data.change_variant.product_after[index].qty
          prod.disabled = response.data.change_variant.product_after[index].qty < response.data.change_variant.product_after[index].moq ? true : false
        })
        setProducts(newProductWithFinalQty);
        setProductsAfter(response.data.change_variant.product_after);
        setInitialShippingFee(response.data.local_shipping_cost)
        setAdditionalShipping(response.data.actual_shipping_cost)
        setIsChangeSupplier(true)
      } else {
        let newProductWithFinalQty = response.data.product
        newProductWithFinalQty?.forEach((prod, index) => {
          prod.final_qty = prod.qty < prod.moq ? 0 : prod.qty
          // prod.disabled = prod.qty < prod.moq ? true : false
        })
        setProducts(newProductWithFinalQty);
        setInitialShippingFee(response.data.local_shipping_cost)
        setAdditionalShipping(response.data.actual_shipping_cost)
        setLocalShipping(response.data.local_shipping_cost)
        setIsChangeSupplier(false)
      }

      if (response.data.total_adjustment !== 0) {
        setAdjustment(prev => {
          return {
            ...prev,
            asuransi: response.data?.adjustment?.asuransi,
            inspeksi: response.data?.adjustment?.inspeksi,
            ongkir_international: response.data?.adjustment?.ongkir_international,
            packingKayu: response.data?.adjustment?.packing_kayu,
          }
        })
      }
      setTotalPaid(response.data.total_paid)
      setOpen(true)
    } else if (response?.status === 500) {
      swal('Oops', response?.message || "There's a problem occured. Please try again in 15 minutes or contact IT", 'error');
    } else {
      swal('Oops', `${response?.message}`, 'error');
    }
    setIsLoading(false);
}

useEffect(() => {
  if(products?.length) {
    let total_initial_price
    let total_changed_price
    let check_changed_qty
    let local_shipping

    if(!isChangeSupplier) {
      total_initial_price = products.reduce((total, curr) => total + (curr.qty * curr.price), 0)
      total_changed_price = products.reduce((total, curr) => 
                            total + (curr.final_qty * Math.ceil(
                              (curr.new_price * data.currency) + 
                              ((curr.new_price * data.currency) * 0.11) + 
                              ((curr.new_price * data.currency) * curr.level_discount) + 
                              curr.cbm_calc)
                            ), 0)
      check_changed_qty = products.filter(prod => prod.qty_available && prod.final_qty !== prod.qty_available)
    } else {
      total_initial_price = products.reduce((total, curr) => total + (curr.qty * Math.ceil(curr.price)), 0)
      total_changed_price = products.reduce((total, curr, index) => total + (curr.final_qty * Math.ceil(productsAfter[index].price + productsAfter[index].tax + productsAfter[index].processing_fee + productsAfter[index].cbm_calc)), 0)
      check_changed_qty = products.filter(prod => prod.qty !== prod.final_qty)
      
      //count local_shipping
      const totalWeightAfter = products.reduce((total, curr, index) => total + (~~productsAfter[index].weight * ~~curr.final_qty), 0)
      const ongkirAfter = productsAfter[0].ongkir
      local_shipping = Math.ceil(totalWeightAfter / 1000) * ongkirAfter
      
      setLocalShipping(~~local_shipping)
    }

    const final_total = total_changed_price

    setFinalTotal(final_total)

    if(final_total + total_adjustment === 0) {
      setSolution('FULL REFUND')
    }
    if (total_initial_price < final_total + total_adjustment + ~~local_shipping) {
      setSolution('ADJUSTMENT')
    }
    if (final_total + total_adjustment + ~~local_shipping > 0 && total_initial_price > final_total + total_adjustment + ~~local_shipping) {
      setSolution('PARTIAL REFUND')
    }
    if (final_total + total_adjustment + ~~local_shipping > 0 && check_changed_qty.length) {
      setIsQtyChanged(true)
    } else {
      setIsQtyChanged(false)
    }
  }
}, [products, productsAfter, isChangeSupplier, total_adjustment])

const handleSubmitSolveIssue = async () => {
  const finalQtyObj = products.map((prod, index) => {
    return {
      id_product: isChangeSupplier ? productsAfter[index].id_product : prod.id_product,
      qty: ~~prod.final_qty
    }
  })
  
  const payload = {
    id_so: idOrder,
    solution: solution,
    product: finalQtyObj,
    aditional_shipping: ~~additionalShipping,
    local_shipping: localShipping,
    adjustment: {
      asuransi: ~~(adjustment.asuransi?.replaceAll('.','')),
      inspeksi: ~~(adjustment.inspeksi?.replaceAll('.','')),
      ongkir_international: ~~(adjustment.ongkir_international?.replaceAll('.','')),
      packing_kayu: ~~(adjustment.packingKayu?.replaceAll('.','')),
    }
  }
  
  const response = await solve_issue_sales_table(JSON.stringify(payload))
  if (response.status === 200) {
    setDataChanged(false)
    setUpdate((prev) => !prev);
  } else {
    swal("Failed", response.message, "error")
  }
}

const handleSubmitApproveOrReject = async (value) => {
  const payload = {
    id_so: idOrder,
    status: value
  }
  
  const response = await submit_approve_or_reject(JSON.stringify(payload))
  if (response.status === 200) {
    setUpdate((prev) => !prev);
    handleClose()
  } else {
    swal("Failed", response.message, "error")
  }
}

  return (
    <>
      <button
        className={`${status == 'paid' ? 'bg-gray-300 text-white' : 'border text-blue-500 border-blue-500'} py-2 px-5 border text-blue-500 border-blue-500 rounded-md text-center cursor-pointer whitespace-nowrap`}
        onClick={() => {
        //   get_ReportDetailProduct()
          handleOpen();
        }}
        // disabled={status !== 'paid'}
      >
        Approval
      </button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box sx={style} className='overflow-y-scroll variant-scroll'>
          <div className='flex justify-end -mt-5'>
            <IconButton onClick={handleClose} style={{ textAlign: 'right' }}>
              <CloseIcon />
            </IconButton>
          </div>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Approval
          </Typography>
            {isLoading ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <div className='flex flex-col'>
                  <CircularProgress />
                  <p className='text-gray-500 text-sm mt-2'>Loading ...</p>
                </div>
              </Box>
            ) : (
                <AdjustmentApprovalTable 
                  data={data}
                  products={products} 
                  setProducts={setProducts} 
                  productsAfter={productsAfter} 
                  setProductsAfter={setProductsAfter} 
                  isChangeSupplier={isChangeSupplier}
                  totalPaid={totalPaid}
                  setTotalPaid={setTotalPaid}
                  finalTotal={finalTotal}
                  setFinalTotal={setFinalTotal}
                  finalQtyAll={finalQtyAll}
                  setFinalQtyAll={setFinalQtyAll}
                  // isQtyChanged={isQtyChanged}
                  // setIsQtyChanged={setIsQtyChanged}
                  solution={solution}
                  // setSolution={setSolution}
                  additionalShipping={additionalShipping}
                  setAdditionalShipping={setAdditionalShipping}
                  initialShippingFee={initialShippingFee}
                  localShipping={localShipping}
                  setShowAddAdjustments={setShowAddAdjustments}
                  adjustment={adjustment}
                  setAdjustment={setAdjustment}
                  total_adjustment={total_adjustment}
                  handleSubmitSolveIssue={handleSubmitSolveIssue}
                  dataChanged={dataChanged}
                  setDataChanged={setDataChanged}
                  handleSubmitApproveOrReject={handleSubmitApproveOrReject}
                />
            )}
        </Box>
      </Modal>
      <AddAdjustmentsModal
        adjustment={adjustment}
        setAdjustment={setAdjustment}
        showAddAdjustments={showAddAdjustments}
        setShowAddAdjustments={setShowAddAdjustments}
        dataChanged={dataChanged}
        setDataChanged={setDataChanged}
      />
    </>
  );
};

export default ApprovalModal;
