import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  reFetch: true 
};

const dataSocketSlice = createSlice({
  name: "additionalData",
  initialState,
  reducers: {
    triggerRefetch: (state) => {
      state.reFetch = !state.reFetch;
    }
  },
});

export const { triggerRefetch } = dataSocketSlice.actions;

export const selectSocketData = (state) => state.addData.addData;

export default dataSocketSlice.reducer;