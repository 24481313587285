import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import {
  CircularProgress,
  Box,
  IconButton,
  Modal,
  Fade,
  Backdrop,
  Collapse,
} from '@mui/material';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import tableIcons from 'helpers/materialTableIcons';
import DetailModal from 'components/General/DetailOrderModal/DetailModal';
// import ManualPayment from './ManualPayment';
// import Notes from 'components/General/Notes';
import PaginationFilter from 'components/General/PaginationFilter';
// import CloseIcon from '@mui/icons-material/Close';
// import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import CancelIcon from '@mui/icons-material/Cancel';
import ReduceQuantityModal from './ReduceQuantity/ReduceQuantityModal';
import ChangeSupplierModal from './ChangeSupplier/ChangeSupplierModal';
import SolveIssueSalesTableModal from './SolveIssue/SolveIssueSalesTableModal';
import { getUser } from 'helpers/parseJWT';
import swal from 'sweetalert';
import { submitFullRefund } from 'service/api';
import ApprovalModal from './Approval/ApprovalModal';
import Lightbox from 'react-image-lightbox';

const ApprovalTable = ({
  dataTable,
  isLoading,
  setUpdate,
  page,
  limit,
  setPage,
  setLimit,
  totalPage,
}) => {  
  const [openDetailAdjustment, setOpenDetailAdjustment] = useState(false)
  const [openLightbox, setOpenLightbox] = useState(false);
  const renderLoading = isLoading ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'start',
        padding: '10px',
        borderRadius: 2,
        backgroundColor: 'white',
        marginBottom: 1,
      }}>
      <div className="flex space-x-3 items-center w-full bg-blue-100 p-4 rounded-md">
        <CircularProgress size={20} />
        <p className="text-gray-500 text-sm ">Updating data ...</p>
      </div>
    </Box>
  ) : null;

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '1px solid lightgray',
    boxShadow: 24,
    borderRadius: '5px',
    p: 4,
  };

  return (
    <>
      {renderLoading}
      <div className="flex justify-end bg-white mb-2 p-2 rounded-md">
        <PaginationFilter
          page={page}
          limit={limit}
          setPage={setPage}
          setLimit={setLimit}
          totalPage={totalPage}
        />
      </div>
      <MaterialTable
        localization={{
          toolbar: {
            searchPlaceholder: 'Search Table',
          },
        }}
        isLoading={isLoading}
        icons={tableIcons}
        title="Sales Table"
        columns={[
          {
            title: 'ID Order',
            field: 'id_so',
            render: (rowData) => (
              <DetailModal id={rowData.id_so} fontSmall widthSmall />
            ),
          },
          {
            title: 'Sales',
            field: 'sales',
            render: (rowData) => (
              <p className="text-sm font-bold capitalize">{rowData.sales ?? "-"}</p>
            ),
          },
          {
            title: 'Order Status',
            field: 'endpoint',
            align: 'center',
            sorting: false,
            render: (rowData) => (
              <p className="text-sm">{rowData.endpoint ?? '-'}</p>
            ),
          },
          {
            title: 'Issue',
            field: 'type',
            render: (rowData) => (
              <p className="text-sm capitalize">{rowData.type ?? '-'}</p>
            ),
          },
          {
            title: 'Reported Date',
            field: 'date',
            align: 'center',
            render: (rowData) => (
              <div>
                <p className="text-xs capitalize">{rowData.date ?? '-'}</p>
              </div>
            ),
          },
          {
            title: 'Submitted Date',
            field: 'submitted_date',
            align: 'center',
            render: (rowData) => (
              <div>
                <p className="text-xs capitalize">{rowData.submitted_date ?? '-'}</p>
              </div>
            ),
          },
          {
            title: 'Adjustment Amount',
            field: 'adjustment',
            align: 'center',
            render: (rowData) => (
              <div className='relative'>
                <div className="text-sm capitalize whitespace-nowrap flex justify-center gap-3">
                  Rp. {rowData?.total_adjustment?.toLocaleString('id-ID') ?? '-'}
                  {rowData?.total_adjustment !== 0 &&
                    <div onClick={() => setOpenDetailAdjustment(prev => prev === rowData.id_so ? false : rowData.id_so)}>
                      {openDetailAdjustment === rowData.id_so ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </div>
                  }
                </div>
                <Collapse in={openDetailAdjustment === rowData.id_so} timeout="auto" unmountOnExit>
                  <div className="detail-adjustment text-xs border bg-white shadow-md rounded-md divide-y p-2 absolute top-6 left-0 z-50">
                    <div className="flex gap-2">
                      <div className="w-28 flex-shrink-0 text-left">Asuransi</div>
                      <div>:</div>
                      <div className='whitespace-nowrap'>Rp. {rowData.adjustment.asuransi?.toLocaleString('id-ID')}</div>
                    </div>
                    <div className="flex gap-2">
                      <div className="w-28 flex-shrink-0 text-left">Inspeksi</div>
                      <div>:</div>
                      <div className='whitespace-nowrap'>Rp. {rowData.adjustment.inspeksi?.toLocaleString('id-ID')}</div>
                    </div>
                    <div className="flex gap-2">
                      <div className="w-28 flex-shrink-0 text-left">Ongkir International</div>
                      <div>:</div>
                      <div className='whitespace-nowrap'>Rp. {rowData.adjustment.ongkir_international?.toLocaleString('id-ID')}</div>
                    </div>
                    <div className="flex gap-2">
                      <div className="w-28 flex-shrink-0 text-left">Packing Kayu</div>
                      <div>:</div>
                      <div className='whitespace-nowrap'>Rp. {rowData.adjustment.packing_kayu?.toLocaleString('id-ID')}</div>
                    </div>
                  </div>
                </Collapse>
              </div>
            ),
          },
          {
            title: 'Change Supplier',
            field: 'change_supplier',
            align: 'center',
            render: (rowData) => (
              <p className="text-sm capitalize">{rowData.is_change_supplier ? 'Yes' : 'No'}</p>
            ),
          },
          {
            title: 'Status',
            field: 'status',
            align: 'center',
            render: (rowData) => (
              <p className="text-sm capitalize">{rowData.status}</p>
            ),
          },
          {
            title: 'Proof',
            field: 'proof',
            align: 'center',
            render: (rowData) => (
              !rowData.proff ? "-" 
              :
              <>
                <img src={rowData.proff}
                  alt="proof image" 
                  className="w-6 h-6 mx-auto overflow-hidden rounded object-content" 
                  onClick={() => setOpenLightbox(rowData.proff)}
                />
                {openLightbox === rowData.proff && (
                  <Lightbox
                    mainSrc={rowData.proff}
                    onCloseRequest={() => setOpenLightbox(false)}
                  />
                )}
              </>
            ),
          },
          {
            title: 'Action',
            field: 'action',
            align: 'center',
            sorting: false,
            render: (rowData) => (
              <div className="flex flex-col space-y-2">
                {rowData.status === 'Need Approval' &&
                  <ApprovalModal
                  data={rowData}
                  status={rowData.status}
                  issue={rowData.type}
                  idOrder={rowData.id_so}
                  setUpdate={setUpdate}
                  />
                }
                {/* {getUser().roles?.includes('admin') &&
                <>
                  <ReduceQuantityModal
                    data={rowData}
                    status={rowData.status}
                    issue={rowData.type}
                    idOrder={rowData.id_so}
                    setUpdate={setUpdate}
                  />
                  <ChangeSupplierModal
                    status={rowData.status}
                    issue={rowData.type}
                    idOrder={rowData.id_so}
                    setUpdate={setUpdate}
                  />
                  {getUser().user === 'ricky' && rowData.status === "submitted" &&
                    <SolveIssueSalesTableModal
                    data={rowData}
                    status={rowData.status}
                    issue={rowData.type}
                    idOrder={rowData.id_so}
                    setUpdate={setUpdate}
                    />
                  }
                </>
                } */}
              </div>
            ),
          },
        ]}
        data={dataTable}
        options={{
          // exportButton: true,
          // exportCsv: () => {
          //   setIsModal(true);
          // },
          paging: false,
          searchFieldPlaceholder: 'Search Table',
        }}
      />

      {/* <Modal
        open={isModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={isModal}>
          <Box sx={style}>
            <div className="flex justify-between items-center -mt-5">
              <div className="text-black font-bold text-2xl">Export Order</div>
              <IconButton onClick={handleClose} style={{ textAlign: 'right' }}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className="w-full mt-5 mb-4 px-5 py-4 border-t border-b border-gray-300 text-gray-600 font-semibold">
              <div className="text-lg mb-2">Export :</div>
              <RadioGroup
                className="flex gap-2 mx-2 "
                value={isExportAll}
                onChange={(e) => setisExportAll(e.target.value)}>
                <FormControlLabel
                  className="flex gap-2"
                  value={true}
                  control={<Radio />}
                  label="Current Page"
                />
                <FormControlLabel
                  className="flex gap-2"
                  value={false}
                  control={<Radio />}
                  label="All Orders"
                />
              </RadioGroup>
            </div>
            <div className="flex justify-end gap-2">
              <button
                name="cancel"
                onClick={handleSubmitModal}
                className="bg-white border border-gray-300 rounded-md font-semibold text-sm text-gray-800 px-4 py-2 hover:bg-gray-200 ">
                Cancel
              </button>
              <button
                name="export_transaction"
                onClick={handleSubmitModal}
                className="bg-white border border-gray-300 rounded-md font-semibold text-sm text-gray-800 px-4 py-2 hover:bg-gray-200">
                Export Transaction Histories
              </button>
              <button
                name="export_orders"
                onClick={handleSubmitModal}
                className="bg-green-600 text-white border-black rounded-[4px] font-semibold text-sm px-4 py-2 hover:bg-green-400">
                Export Orders
              </button>
            </div>
          </Box>
        </Fade>
      </Modal> */}
    </>
  );
};

export default ApprovalTable;
