import { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
// import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { IconButton } from '@mui/material';
import ReactToPrint from 'react-to-print';
import BarcodeCardList from './BarcodeCardList';
import swal from 'sweetalert';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: 1000,
  height: 600,
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid lightgray',
  boxShadow: 24,
  borderRadius: '5px',
  p: 4,
};

export default function PrintBarcodeModal({
  // customerData,
  //   id_group,
  //   id_so,
  open,
  handleClose,
  data,
  customBarcode,
  idBarcode
}) {
  const [pdfFile, setPdfFile] = useState(null)
  let componentRef = useRef();


  useEffect(() => {
    if (data?.is_pdf === true) {
      setPdfFile(data?.url)
      // getThePdfFile(data?.url)
    }
  }, [data])

  // const getThePdfFile = async (url) => {
  //   fetch(url, {
  //     method: 'GET',
  //     responseType: 'blob',
  //     // headers: {
  //     //   Authorization: `Bearer ${tokenCookies}`,
  //     // },
  //   }).then((response) => {
  //       if(!response) {
  //         swal('Sorry. pdf file not found.')
  //         return false;
  //       } else {
  //         return response.blob();
  //       }
  //     }).then((data) => {
  //       console.log("data pdf", data)
  //       if(data) {   
  //         setPdfFile(window.URL.createObjectURL(data))
  //       }
  //     }).catch((err) => {
  //       console.log(err.message)
  //     });
  // }

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={style}
          className="overflow-y-scroll variant-scroll table-scroll">
          <div className="flex justify-end -mt-5 w-full">
            <IconButton onClick={handleClose} style={{ textAlign: 'right' }}>
              <CloseIcon />
            </IconButton>
          </div>

          {data?.is_pdf === true ? (
            <div className='w-full pb-10 border-b-2 mb-5' style={{ height: 'calc(100% - 40px)' }}>
              <object data={pdfFile} type="application/pdf" width="100%" height="100%">
                <p>Unable to display PDF file. <a href={data?.url}>Download</a> instead.</p>
              </object>
            </div>
          ) : (
            <div className='w-96'>
              <div ref={(el) => (componentRef = el)} className='mb-3'>
                <BarcodeCardList
                  data={data}
                  idBarcode={idBarcode}
                  customBarcode={customBarcode}
                // customerData={customerData} 
                />
              </div>

              <ReactToPrint
                trigger={() => (
                  <div className="w-[10rem] text-white text-center bg-blue-500 hover:bg-blue-600 cursor-pointer rounded mx-auto px-5 py-2">
                    Print Barcode
                  </div>
                )}
                content={() => componentRef}
              />
            </div>
          )}



          <div className="text-center my-2">
            <hr className="my-2" />
          </div>
        </Box>
      </Modal>
    </div>
  );
}
