import React from "react";
import { Redirect } from "react-router-dom";

const Warehouse = () => {
  return (
    <>
      <Redirect to="warehouse/warehouse-china" />
    </>
  );
};

export default Warehouse;
