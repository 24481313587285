import MaterialTable from 'material-table';
import {
  IconButton,
  Tooltip,
  Chip,
  CircularProgress,
  Box,
  Typography,
  Fade,
  Modal,
  Backdrop,
} from '@mui/material';
import swal from 'sweetalert';
import AddSalesModal from './AddSalesModal';
import NotesModal from './NotesModal';
import ViewModal from './ViewModal';
import DeleteIcon from '@mui/icons-material/Delete';
import tableIcons from 'helpers/materialTableIcons';
import { useState, useEffect } from 'react';
import { add_multi_sales_data, releaseSalesData } from 'service/api';
import WhatsAppDetail from './WhatsAppDetail';
import SetFlag from './SetFlag';
import FlagIcon from '@mui/icons-material/Flag';
import CloseIcon from "@mui/icons-material/Close";
import moment from 'moment';
import { getUser } from 'helpers/parseJWT';
import { MuiThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles'

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  border: "1px solid lightgray",
  boxShadow: 24,
  borderRadius: "5px",
  p: 4,
};

const CustomerTable = ({
  customerData,
  setChangeData,
  isLoading,
  setSearchData,
  selectedRowsNumber,
  setSelectedRowsNumber,
}) => {
  const [sales, setSales] = useState("");
  const [selectedRow, setSelectedRow] = useState([]);
  const [showModalAddSalesMulti, setShowModalAddSalesMulti] = useState(false)
  const [loading, setLoading] = useState(false);
  const { data, listSales } = customerData;
  const dataFromSearch = data?.length === 1;

  // const newData = data?.map((customer) => {
  //   return {
  //     ...customer,
  //     listSales,
  //   };
  // });

  const releaseSales = async (email, sales) => {
    setLoading(true);
    const body = JSON.stringify({ email: email, sales: sales });
    const data = await releaseSalesData(body);
    if (dataFromSearch) {
      setSearchData(`/${email}`);
    }
    if (data.status === 200) {
      swal('Sales released successfully', {
        icon: 'success',
      });
      setChangeData((prev) => !prev);
    }
  };

  const handleRelease = (email, sales) => {
    sales === null
      ? swal(
          'Oops',
          "Customer has no sales, you can't do this action!",
          'error'
        )
      : swal({
          title: 'Are you sure?',
          text: 'Once release sales, you will not be able to revert this change!',
          icon: 'warning',
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            releaseSales(email, sales);
          } else {
            swal('You revert this change!');
          }
        });
  };

  useEffect(() => {
    if (!isLoading) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [isLoading]);

  const renderLoading = loading ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'start',
        padding: '10px',
        borderRadius: 2,
        backgroundColor: 'white',
        marginBottom: 1,
      }}>
      <div className='flex space-x-3 items-center w-full bg-blue-100 p-4 rounded-md'>
        <CircularProgress size={20} />
        <p className='text-gray-500 text-sm '>Updating data ...</p>
      </div>
    </Box>
  ) : null;
  
  const selectRowsData = (evt, data) => {
    setSelectedRow(data)
    setShowModalAddSalesMulti(true)
  }

  const theme = createTheme({
    palette: {
      primary: {
        main: '#4caf50',
      },
      secondary: {
        main: '#4caf50',
      },
    },

  });

  const handleChange = (event) => {
    setSales(event.target.value);
  };
  
  const addSalesMultiple = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "Once add sales, you will not be able to revert this change!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        postSalesDataMultiple();
      } else {
        swal("Action canceled");
      }
    });
  };
  
  const postSalesDataMultiple = async () => {
    setLoading(true);
    const payload = selectedRow.map((row) => {
      return {
        email: row.email,
        sales: sales
      }
    })
    
    const body = JSON.stringify(payload);
    const data = await add_multi_sales_data(body);

    if (data?.status === 200) {
      swal("Sales added successfully", {
        icon: "success",
      });
      setShowModalAddSalesMulti(false)
      setChangeData((prev) => !prev);
    } else {
      setLoading(false);
    }
  };
  
  const onSelectionChange = (selectedRows) => {
    const selectedRowsFiltered = selectedRows.filter((rowData) => rowData.email !== null)
    setSelectedRowsNumber(selectedRowsFiltered.length)
  }

  return (
    <>
      {renderLoading}
      <MuiThemeProvider theme={theme}>
        <MaterialTable
          isLoading={loading}
          localization={{
            toolbar: {
              searchPlaceholder: 'Search Table',
            },
          }}
          icons={tableIcons}
          title={selectedRowsNumber > 0 ? 
            <div className='text-green-500 text-xl'>{selectedRowsNumber} row{selectedRowsNumber > 1 && 's'} selected</div>
            : 'Customer Table'}
          columns={[
            {
              title: 'Flag',
              field: 'flag',
              render: (rowData) => (
                <FlagIcon
                  fontSize='medium'
                  className={`${
                    rowData.level === 1
                      ? 'text-red-500'
                      : rowData.level === 2
                      ? 'text-blue-500'
                      : rowData.level === 3
                      ? 'text-green-500'
                      : 'text-gray-400'
                  }`}
                />
              ),
            },
            {
              title: 'Customer',
              field: 'name',
              render: (rowData) => (
                <div>
                  <p>{rowData.name}</p>
                  <p className='text-xs'>{rowData.email}</p>
                </div>
              ),
            },
            {
              title: 'Sales',
              field: 'sales',
              render: (rowData) => (
                <p className='text-sm'>
                  {rowData?.sales !== null
                    ? rowData?.sales?.slice(0, 1).toUpperCase() +
                      rowData?.sales?.slice(1)
                    : 'No Sales'}
                </p>
              ),
            },
            {
              title: 'Total Spend',
              field: 'totalSale',
              render: (rowData) => (
                <p className='text-sm'>
                  {`IDR ${rowData.totalSale.toLocaleString('id-ID')}`}
                </p>
              ),
            },
            {
              title: 'Refund',
              field: 'refund',
              render: (rowData) => (
                <p className='text-sm'>
                  {!rowData.refund
                    ? 'No Data'
                    : 'IDR ' + rowData.refund.toLocaleString('id-ID')}
                </p>
              ),
            },
            {
              title: 'Last Transaction',
              field: 'lastTransactionDate',
              render: (rowData) => (
                <div className='text-sm'>
                  <p>{rowData?.lastTransactionDate}</p>
                  <p>{`IDR ${rowData?.lastTransactionValue?.toLocaleString(
                    'id-ID'
                  )}`}</p>
                </div>
              ),
            },
            {
              title: 'Status',
              field: 'status',
              render: (rowData) =>
                rowData.status === 'Active' ? (
                  <Chip label={rowData.status} color='success' />
                ) : rowData.status === 'Passive' ? (
                  <Chip
                    label={rowData.status}
                    color='primary'
                    sx={{ backgroundColor: 'gray' }}
                  />
                ) : (
                  <Chip
                    label={rowData.status}
                    color='primary'
                    sx={{ backgroundColor: 'red' }}
                  />
                ),
            },
            {
              title: 'Grade',
              field: 'levelCust',
              render: (rowData) => (
                <p
                  className={`text-sm text-white p-1 w-24 border text-center rounded-lg  ${
                    rowData.levelCust === 'DIAMOND'
                      ? 'border-blue-500 bg-blue-200'
                      : rowData.levelCust === 'GOLD'
                      ? 'bg-yellow-300 border-yellow-500'
                      : 'border-gray-600 bg-gray-400'
                  }`}>
                  {rowData.levelCust}
                </p>
              ),
            },
            {
              title: 'Last Contact',
              field: 'lastContact',
              render: (rowData) =>
                rowData.lastContact ? (
                  <div className='space-y-2 text-sm '>
                    <p>{rowData.lastContact}</p>
                  </div>
                ) : (
                  <p className='text-sm'>No Activity</p>
                ),
            },
            {
              title: 'Time Elapse',
              field: 'daysAgo',
              render: (rowData) =>
                rowData.daysAgo ? (
                  <div className='space-y-2 text-sm '>
                    <p>
                      {moment(
                        rowData.lastContact,
                        'DD/MM/YYYY hh.mm.ss'
                      ).fromNow()}
                    </p>
                  </div>
                ) : (
                  <p className='text-sm'>No Activity</p>
                ),
            },
            {
              title: 'Action',
              field: 'action',
              align: 'center',
              render: (rowData) => {
                return (
                  <>
                    <div className='flex items-center text-lg'>
                      {['admin', 'supervisor'].some((i) => getUser()?.roles?.includes(i)) &&
                      <>
                        <AddSalesModal
                          listData={listSales}
                          email={[rowData.email]}
                          setLoading={setLoading}
                          changeData={setChangeData}
                          dataFromSearch={dataFromSearch}
                          setSearchData={setSearchData}
                        />
                        <Tooltip title='Release Sales'>
                          <IconButton
                            aria-label='delete'
                            onClick={() =>
                              ['admin', 'supervisor'].some((i) => getUser()?.roles?.includes(i)) && handleRelease(rowData.email, rowData.sales)
                            }>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                      }
                      <NotesModal
                        changeData={setChangeData}
                        notesData={rowData.notes}
                        email={rowData.email}
                        customer={rowData.name}
                        setSearchData={setSearchData}
                        dataFromSearch={dataFromSearch}
                      />
                      <ViewModal email={rowData.email} />
                      <SetFlag
                        email={rowData.email}
                        setChangeData={setChangeData}
                        dataFromSearch={dataFromSearch}
                        setSearchData={setSearchData}
                        setLoading={setLoading}
                      />
                      <WhatsAppDetail
                        phoneNumber={rowData.customerPhone}
                        email={rowData.email}
                        changeData={setChangeData}
                        dataFromSearch={dataFromSearch}
                        setSearchData={setSearchData}
                        setLoading={setLoading}
                      />
                    </div>
                    {/* <a href={`https://desktop-tshoot.ocistok.com/set-xemail?email=${rowData.email}`} 
                      className="block px-2 py-1 rounded-md text-sm text-white bg-blue-500 hover:bg-blue-600 cursor-pointer"
                    >
                      Order Manual
                    </a> */}
                  </>
                );
              },
            },
          ]}
          data={data}
          options={{
            // exportButton: true,
            pageSizeOptions: [],
            pageSize: 50,
            searchFieldPlaceholder: 'Search Table',
            isLoading: true,
            selection: ['admin', 'supervisor'].some((i) => getUser()?.roles?.includes(i)),
            showTextRowsSelected: false,
            selectionProps: (rowData) => {
              const checked = rowData.email === null ? {checked: false} : {};
              return {
              disabled: rowData.email === null,
               ...checked
              }
           }
          }}
          onSelectionChange={onSelectionChange}
          actions={['admin', 'supervisor'].some((i) => getUser()?.roles?.includes(i)) && [
            {
              tooltip: 'Add Sales',
              icon: tableIcons.AddCircle,
              onClick: (evt, data) => selectRowsData(evt, data)
            }
          ]}
        />
      </MuiThemeProvider>

      {/* MODAL ADD SALES TO MULTIPLE USER */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showModalAddSalesMulti}
        onClose={() => setShowModalAddSalesMulti(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showModalAddSalesMulti}>
          <Box sx={style}>
            <div className="flex justify-end -mt-5">
              <IconButton onClick={() => setShowModalAddSalesMulti(false)} style={{ textAlign: "right" }}>
                <CloseIcon />
              </IconButton>
            </div>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Add Sales
            </Typography>
            <form
              onSubmit={(e) => ['admin', 'supervisor'].some((i) => getUser()?.roles?.includes(i)) && addSalesMultiple(e)}
              className="flex flex-col items-center space-y-2"
            >
              <select
                value={sales}
                onChange={handleChange}
                className="p-2 border border-gray-300 outline-none rounded-md w-full"
              >
                <option value="" disabled>
                  Select Sales
                </option>
                {listSales?.map((sales, id) => (
                  <option value={sales} key={id}>
                    {sales.slice(0, 1).toUpperCase() + sales.slice(1)}
                  </option>
                ))}
              </select>
              <button
                className="p-2 bg-blue-300 text-white rounded-md  w-full hover:bg-blue-200 transition-alll"
                type="submit"
              >
                Submit
              </button>
            </form>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default CustomerTable;
