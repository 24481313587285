import React from "react";
import { Redirect } from "react-router-dom";

const Report = () => {
  return (
    <>
      <Redirect to="report/penjualan" />
    </>
  );
};

export default Report;
