import { Box, IconButton, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useState } from 'react';

const OrderDetailHeader = ({ detailOrder, orderData, id_group, financialData }) => {
  const { income } = financialData;
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3
  };
  return (
    <div className='grid grid-cols-2 gap-4 my-2'>
      <div className='flex flex-col text-xs space-y-2 '>
        <div className='flex'>
          <p className='w-2/6'>Order Number</p>
          <p className='w-1/6'>:</p>
          <p className='w-3/6'>{orderData.orderNumber}</p>
        </div>
        {id_group && (
          <div className='flex'>
            <p className='w-2/6'>Order Group</p>
            <p className='w-1/6'>:</p>
            <p className='w-3/6'>{id_group ?? 'N/A'}</p>
          </div>
        )}
        <div className='flex'>
          <p className='w-2/6'>Sales</p>
          <p className='w-1/6'>:</p>
          <p className='w-3/6 capitalize'> {orderData.sales}</p>
        </div>
        <div className='flex'>
          <p className='w-2/6'>Refund Amount</p>
          <p className='w-1/6'>:</p>
          <p className='w-3/6'>
            IDR {orderData.refund.toLocaleString('id-ID')}
          </p>
        </div>
        <div className='flex'>
          <p className='w-2/6'>Order Income</p>
          <p className='w-1/6'>:</p>
          {/* <p className='w-3/6'>IDR {(income?.customerPayment + income?.shippingCustomer + income?.discount + income?.others + income.processing_fee).toLocaleString('id-ID')}</p> */}
          <p className='w-3/6'>Rp. {detailOrder.orderDetail.income.toLocaleString('id-ID')}</p>
        </div>
        <div className='flex'>
          <p className='w-2/6'>Total Adjustment</p>
          <p className='w-1/6'>:</p>
          <p className='w-3/6'>
            IDR {detailOrder.total_adjustment?.toLocaleString('id-ID')}
          </p>
        </div>
      </div>
      <div className='flex flex-col text-xs space-y-2 '>
        <div className='flex'>
          <p className='w-2/6'>Payment Time</p>
          <p className='w-1/6'>:</p>
          <p className='w-3/6 line-clamp-1' title={orderData.paymentDate}>
            {orderData.paymentDate}
          </p>
        </div>
        <div className='flex'>
          <p className='w-2/6'>Status</p>
          <p className='w-1/6'>:</p>
          <p className='w-3/6 capitalize'> {orderData.status}</p>
        </div>
        <div className='flex'>
          <p className='w-2/6'>Order Expense</p>
          <p className='w-1/6'>:</p>
          <p className='w-3/6'>
            <span className='font-semibold'>IDR:</span>{' '}
            {orderData.expenseIdr.toLocaleString('id-ID')}{' '}
            <span className='ml-2 font-semibold'>RMB:</span>{' '}
            {Math.round(orderData.expenseRmb)}
          </p>
        </div>
        <div className='flex'>
          <p className='w-2/6'>Delivery Time</p>
          <p className='w-1/6'>:</p>
          <p className='w-3/6'>{orderData.eta ? orderData.eta : 'N/A'}</p>
        </div>
        {detailOrder.request_status == "Forbiden" &&
          <div className='flex'>
            <p className='w-2/6'></p>
            <p className='w-1/6'></p>
            <p className='w-3/6'><b className='bg-red-500 text-white px-2 rounded-full'>{detailOrder.request_status}</b></p>
          </div>
        }
        {detailOrder.rumus_ver &&
          <div className='flex'>
            <p className='w-2/6'>Formula Type</p>
            <p className='w-1/6'>:</p>
            <p className='w-3/6'>{detailOrder.rumus_ver}</p>
          </div>
        }
        {
          detailOrder.orderDetail.bukti && (
            <div className='flex'>
              <p className='w-2/6'>Recieving WH ID</p>
              <p className='w-1/6'>:</p>
              <p className='w-3/6 flex overflow-x-auto'>
              <ModalImage images={detailOrder.orderDetail.bukti} image={detailOrder.orderDetail.bukti[0]}/>
              </p>
            </div>
          )
        }
      </div>
    </div>
  );
};



const styleModalImage = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  overflow : 'auto',
  transform: 'translate(-50%, -50%)',
};

function ModalImage({ images, image }) {

  const [selectedImage, setSelectedImage] = useState(image)

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className='flex-sh'>
      <button onClick={handleOpen}>
        <img src={image} className="w-10 mr-1 shadow border" style={{maxHeight :"20px", objectFit : "cover"}}/>
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box sx={styleModalImage}>
          <div className='w-full h-full relative flex items-center bg-white'>
            <IconButton
              onClick={handleClose}
              style={{
                position: 'absolute',
                top: 5,
                right: 5,
                color: 'red',
              }}>
              <CloseIcon />
            </IconButton>
            <div className='overflow-auto flex items-center' style={{height : "calc(780px)",}}>
              <a href={selectedImage} target="_blank">
                <img
                  src={selectedImage ? selectedImage : '/no-image.png'}
                  alt='product'
                  className='object-contain'
                />
              </a>
            </div>
          </div>
          <div className='flex overflow-x-auto bg-white'>
            {
              images.map((bukti,i) => {
                return <img className='w-20 h-12 m-2 rounded flex-shrink-0' src={bukti} alt="" onClick={e => setSelectedImage(bukti)}/>
              })
            }
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default OrderDetailHeader;
