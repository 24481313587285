export default function ModalConfirm({
    show,
    onClose,
    title = 'Konfirmasi',
    text = 'Anda yakin?',
    icon,
    cancelButtonText = 'Cancel',
    confirmButtonText = 'Yes',
    action,
  }) {
    
    if (!show) {
      return null;
    }
    return (
      <div className="fixed top-0 left-0  w-full h-full bg-black bg-opacity-30 flex justify-center items-center z-20" onClick={onClose}>
        <div className="w-60 p-5 text-gray-700 bg-white rounded-xl space-y-3">
          <div className="flex justify-center items-center gap-3">
            {icon}
            <div className="text-xl font-bold capitalize">{title}</div>
          </div>
          <div className="text-sm text-center">{text}</div>
          <div className="flex items-center gap-2 text-sm">
            <div className="w-1/2 border border-blue-400 rounded-lg text-blue-400 text-center cursor-pointer">{cancelButtonText}</div>
            <div className="w-1/2 border border-blue-400 rounded-lg bg-blue-400 text-white text-center cursor-pointer" onClick={(e) => {
              e.stopPropagation()
              action(show)
            }}>{confirmButtonText}</div>
          </div>
        </div>
      </div>
    );
  }