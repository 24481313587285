import React, { useCallback, useEffect, useMemo, useState } from "react";
import Sanitized from "react-sanitized";
import { getListDetailWa } from "service/api";
import swal from "sweetalert";
import ModalDetail from "./ModalDetail";

const MenuDetailChat = (props) => {
  const { menu } = props;
  const [volumeData, setVolumeData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState("");
  const [video, setVideo] = useState("");
  const [audio, setAudio] = useState("");

  const storedData = localStorage.getItem("no-telpon");
  const noTelpon = JSON.parse(storedData);

  const memoizedParams = useMemo(() => {
    const detail = menu !== "" ? `${noTelpon}?media=${menu}` : "";
    return detail;
  }, [menu]);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const response = await getListDetailWa(memoizedParams);
    if (response?.status === 200) {
      setVolumeData(response?.data[0]?.message?.reverse());
      setIsLoading(false);
    } else if (response?.status === 403) {
      swal("Oops", `${response?.message}`, "error");
      setIsLoading(false);
    }
  }, [memoizedParams, setIsLoading, volumeData]);

  const handleAudio = (file) => {
    setAudio(file);
    setOpen(true);
  };

  const handleVideo = (file) => {
    setVideo(file);
    setOpen(true);
  };

  const handleImage = (file) => {
    setImage(file);
    setOpen(true);
  };

  useEffect(() => {
    fetchData();
  }, [memoizedParams]);

  function convertTextToLink(text) {
    const urlPattern =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    return text.replace(
      urlPattern,
      '<a href="$1" target="_blank" style="color: #4C89FF; text-decoration: underline;">$1</a>'
    );
  }

  const UserChat = React.memo((props) => {
    const { chat } = props;
    return (
      <div>
        <div className="flex items-center mb-3 mt-5">
          <div>
            <div
              className={`flex-1 bg-gray-200 p-2 rounded-lg mb-2 p-4 pr-3 relative max-w-lg text-gray-800`}>
              <div className="flex justify-between">
                <div className="absolute left-0 top-1/2 transform -translate-x-1/2 rotate-45 w-2 h-2 bg-gray-200"></div>

                {chat?.body !== "" && chat?.body !== null ? (
                  <Sanitized
                    className="break-all"
                    html={convertTextToLink(chat?.body)}
                    style={{ whiteSpace: "pre-wrap" }}
                    options={{
                      allowedAttributes: {
                        a: ["href", "style"],
                      },
                    }}
                  />
                ) : (
                  <div className="hidden"></div>
                )}

                {[".png", ".jpg", ".jpeg", ".jfif", ".bmp", ".gif"].includes(
                  chat?.media?.mime
                ) ? (
                  <div className="w-full w-1/2 my-1 h-fit max-h-[200px] ">
                    <img
                      // onClick={() => handleImage(chat?.media?.file_name)}
                      className="w-full h-full max-h-[200px] object-contain cursor-pointer"
                      src={chat?.media?.file_name}
                    />
                  </div>
                ) : (
                  <div className="hidden"></div>
                )}
              </div>
            </div>
            <div className="text-sm m-2">
              <span className="text-sm">
                <b>13.10</b>
                {/* <b>{formateHHSS(chat?.date)}</b> */}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  });

  const ServerChat = React.memo((props) => {
    const { chat } = props;
    return (
      <div className="flex flex-col flex-row-reverse justify-end">
        <div className="flex items-center flex-row-reverse  mt-5">
          <div>
            <div
              className={`flex-1 pl-3  bg-custom-100 text-white p-3 rounded-lg mb-2 relative max-w-lg`}>
              {chat?.media.file_name !== "" ? (
                <>
                  {[".png", ".jpg", ".jpeg", ".jfif", ".bmp", ".gif"].includes(
                    chat?.media?.mime
                  ) ? (
                    <div className="w-full w-1/2 my-1 h-fit max-h-[200px] ">
                      <img
                        // onClick={() => handleImage(chat?.media?.file_name)}
                        className="w-full h-full max-h-[200px] object-cover cursor-pointer"
                        src={chat?.media?.file_name}
                      />
                    </div>
                  ) : (
                    <div className="hidden"></div>
                  )}
                </>
              ) : (
                <div></div>
              )}

              {chat?.media.file_name != "" ? (
                <div className="w-[200px] h-fit max-h-[200px] my-1">
                  {[".pdf"].includes(chat?.media?.mime) ? (
                    <a href={chat?.media?.file_name} target="_blank">
                      <div className="flex">
                        <img
                          src={`${process.env.REACT_APP_URL_FOTO_STORAGE}/pdf (1).png`}
                        />
                      </div>
                    </a>
                  ) : (
                    <div className="hidden"></div>
                  )}

                  {[".xlsx", ".xls", ".csv"].includes(chat?.media?.mime) ? (
                    <a href={chat?.media?.file_name} target="_blank">
                      <div className="flex my-1">
                        <img
                          src={`${process.env.REACT_APP_URL_FOTO_STORAGE}/xls (1) 1.png`}
                        />
                      </div>
                    </a>
                  ) : (
                    <div className="hidden"></div>
                  )}

                  {[".mp2", ".oga", ".wav"].includes(chat?.media?.mime) ? (
                    <a onClick={() => handleAudio(chat?.media?.file_name)}>
                      <div className="flex my-1">
                        <img
                          src={`${process.env.REACT_APP_URL_FOTO_STORAGE}/record 1.png`}
                        />
                      </div>
                    </a>
                  ) : (
                    <div className="hidden"></div>
                  )}

                  {[".doc", ".docx"].includes(chat?.media?.mime) ? (
                    <a href={chat?.media?.file_name} target="_blank">
                      <div className="flex my-1">
                        <img
                          src={`${process.env.REACT_APP_URL_FOTO_STORAGE}/word 1.png`}
                        />
                      </div>
                    </a>
                  ) : (
                    <div className="hidden"></div>
                  )}

                  {[".ppt", ".pptx"].includes(chat?.media?.mime) ? (
                    <a onClick={() => handleVideo(chat?.media?.file_name)}>
                      <div className="flex w-3/4 h-3/4 my-1">
                        <img
                          src={`${process.env.REACT_APP_URL_FOTO_STORAGE}/ppt 1.png`}
                        />
                      </div>
                    </a>
                  ) : (
                    <div className="hidden"></div>
                  )}

                  {[".m4v", ".mp4", ".avi"].includes(chat?.media?.mime) ? (
                    <a onClick={() => handleVideo(chat?.media?.file_name)}>
                      <div className="flex w-3/4 h-3/4 my-1">
                        <img
                          src={`${process.env.REACT_APP_URL_FOTO_STORAGE}/multimedia.png`}
                        />
                      </div>
                    </a>
                  ) : (
                    <div className="hidden"></div>
                  )}
                </div>
              ) : (
                <div className="hidden"></div>
              )}

              {chat?.body !== "" && chat?.body !== null ? (
                <Sanitized
                  html={convertTextToLink(chat?.body)}
                  style={{ whiteSpace: "pre-wrap" }}
                  className="break-all"
                  options={{
                    allowedAttributes: {
                      a: ["href", "style"],
                    },
                  }}
                />
              ) : (
                // <div dangerouslySetInnerHTML={{ __html: cleanHtml }}/>

                <div className="hidden"></div>
              )}

              <div className="absolute right-0 top-1/2 transform translate-x-1/2 rotate-45 w-2 h-2 bg-custom-100"></div>
            </div>
          </div>
        </div>
        <div className="text-sm flex justify-end mr-2">
          <span className="text-sm">
            <b>13.10</b>
          </span>
        </div>
      </div>
    );
  });

  // const chatUser = [
  //   {
  //     from: "server",
  //     body: "",
  //     media: {
  //       mime: ".jpeg",
  //       file_name:
  //         "https://control-panel.ocistok.com/control-panel/foto/image_senS3gz6XC.jpeg",
  //     },
  //   },
  //   {
  //     from: "server",
  //     body: "",
  //     media: {
  //       mime: ".jpeg",
  //       file_name:
  //         "https://control-panel.ocistok.com/control-panel/foto/image_senS3gz6XC.jpeg",
  //     },
  //   },
  //   {
  //     from: "Erwin Yuliyanto",
  //     body: "",
  //     media: {
  //       mime: ".jpeg",
  //       file_name:
  //         "https://control-panel.ocistok.com/control-panel/foto/image_senS3gz6XC.jpeg",
  //     },
  //   },
  //   {
  //     from: "ErwinYuliyanto",
  //     body: "1231232131231232131231312312123123 uhuyyy",
  //     media: {
  //       mime: "",
  //       file_name: "",
  //     },
  //   },
  //   {
  //     from: "server",
  //     body: "",
  //     media: {
  //       mime: ".jpeg",
  //       file_name:
  //         "https://control-panel.ocistok.com/control-panel/foto/image_senS3gz6XC.jpeg",
  //     },
  //   },
  //   {
  //     from: "server",
  //     body: "",
  //     media: {
  //       mime: ".jpeg",
  //       file_name:
  //         "https://control-panel.ocistok.com/control-panel/foto/image_senS3gz6XC.jpeg",
  //     },
  //   },
  //   {
  //     from: "server",
  //     body: "",
  //     media: {
  //       mime: ".jpeg",
  //       file_name:
  //         "https://images.unsplash.com/photo-1566438480900-0609be27a4be?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8aW1hZ2V8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
  //     },
  //   },
  //   {
  //     from: "server",
  //     body: "",
  //     media: {
  //       mime: ".jpeg",
  //       file_name:
  //         "https://images.unsplash.com/photo-1633621412960-6df85eff8c85?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fGltYWdlfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60",
  //     },
  //   },
  // ];

  return (
    <>
      {isLoading ? (
        <div className="h-fit py-3">
          <div className="w-full px-5">
            <div className="bg-gray-400 pt-44 animate-pulse text-white p-1 rounded-lg mb-7"></div>
          </div>
          <div className="w-2/5 px-5">
            <div className="bg-gray-400 pt-10 animate-pulse text-white p-1 rounded-lg mb-7"></div>
          </div>
          <div className="w-full px-5">
            <div className="bg-gray-400 pt-44 animate-pulse text-white p-1 rounded-lg mb-7"></div>
          </div>
          <div className="w-2/5 px-5">
            <div className="bg-gray-400 pt-10 animate-pulse text-white p-1 rounded-lg mb-7"></div>
          </div>
        </div>
      ) : (
        <>
          {volumeData === null  || volumeData === undefined ? (
            <div className="flex w-full h-[60vh] justify-center items-center my-1 flex-col">
              <div className="flex w-3/4 bg-blue-200 justify-center my-1">
                <img
                  src={`${process.env.REACT_APP_URL_FOTO_STORAGE}/undraw_No_data_re_kwbl-ocistok.png`}
                />
              </div>
              <div>
                Tidak ada data
              </div>
            </div>
          ) : (
            <div className="overflow-y-auto h-[60vh]">
              {menu === "media" ? (
                <div className="px-3 text-sm">
                  {volumeData && volumeData.map((chat, index) => (
                    <div className="my-2 flex flex-row-reverse" key={index}>
                      {chat.from === "server" ? (
                        <>
                          <ServerChat chat={chat} />
                        </>
                      ) : (
                        <>
                          <UserChat chat={chat} />
                        </>
                      )}
                    </div>
                  ))}
                </div>
              ) : menu === "document" ? (
                <div className="px-3 text-sm">
                  {volumeData && volumeData.map((chat, index) => (
                    <div className="my-2 flex flex-row-reverse" key={index}>
                      {chat.from === "server" ? (
                        <>
                          <ServerChat chat={chat} />
                        </>
                      ) : (
                        <>
                          <UserChat chat={chat} />
                        </>
                      )}
                    </div>
                  ))}
                </div>
              ) : menu === "image" ? (
                <div className="px-3 text-sm">
                  {volumeData && volumeData.map((chat, index) => (
                    <div className="my-2" key={index}>
                      {chat.from === "server" ? (
                        <>
                          <ServerChat chat={chat} />
                        </>
                      ) : (
                        <>
                          <UserChat chat={chat} />
                        </>
                      )}
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          )}

          {open ? (
            <>
              <ModalDetail
                audio={audio}
                setAudio={setAudio}
                video={video}
                setVideo={setVideo}
                image={image}
                setImage={setImage}
                open={open}
                setOpen={setOpen}
                // onCloseRequest={() => setOpenLightbox(!openLightBox)}
              />
            </>
          ) : null}
        </>
      )}
    </>
  );
};

export default MenuDetailChat;
