import React from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Link } from "react-router-dom";
import { PhoneChiperText } from "helpers/PhoneChiperText";

const WhatsAppDetail = ({ phoneNumber }) => {
  function removePlus(phoneNumber) {
    const sendNumber = phoneNumber.replace(/\+/g, "");
    return sendNumber;
  }
  const data = phoneNumber;
  const modifiedPhoneNumber = removePlus(data.replace(/^0/, "62"));
  const linkToPhone = `/management-sales/ocs?number=${PhoneChiperText(modifiedPhoneNumber)}`;
  return (
    <>
      <div className="flex justify-center items-center">
        {phoneNumber && (
          <Link to={linkToPhone}>
            <WhatsAppIcon
              className={`${phoneNumber ? "text-green-500" : "text-gray-500"}`}
            />
          </Link>
        )}
      </div>
    </>
  );
};

export default WhatsAppDetail;
