import React, { useEffect, useState } from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import KeyboardArrowUpSharpIcon from '@mui/icons-material/KeyboardArrowUpSharp';
import { CircularProgress, IconButton, Table, TableCell, TableContainer, TableHead, TableRow, Paper, TableBody, Collapse, Box, Typography } from '@mui/material';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Lightbox from 'react-image-lightbox';
import swal from 'sweetalert';
import { editDataAprovalWHindo } from 'service/api';
import CloseIcon from '@mui/icons-material/Close';
// import AddIcon from '@mui/icons-material/Add';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { getUser } from 'helpers/parseJWT';
import { censorPhoneNumber } from 'helpers/cencorPhoneNumber';
import Cookies from 'js-cookie';


const TableApprovalWhIndo = ({
  data,
  isBolean,
  handleSendAproval,
  handleRejectAproval,
  handleRejectMultipleAproval,
  handleShowMore,
  setDataUpdated
}) => {
  const [open, setOpen] = useState([]);
  const [copyData, setCopyData] = useState([]);
  const [checkedRowDataPalet, setCheckedRowDataPalet] = useState([]);
  const [checkedRowData, setCheckedRowData] = useState([]);
  const [openLightbox, setOpenLightbox] = useState(false);
  const [editApprovalObj, setEditApprovalObj] = useState(false);
  const [productEdited, setProductEdited] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (data) {
      const copy_data = JSON.parse(JSON.stringify(data)); // Create completely new copy nested object of data
      setCopyData(copy_data);
    }
  }, [data]);

  const set_EditApprovalObj = (e, name, indexParent, indexProduk, idCarton, dataIndex) => {
    const newData = JSON.parse(JSON.stringify(copyData));
    let totalNumber =
      data[dataIndex].palet_body[indexParent].product[indexProduk].recieve +
      data[dataIndex].palet_body[indexParent].product[indexProduk].lost +
      data[dataIndex].palet_body[indexParent].product[indexProduk].damaged +
      data[dataIndex].palet_body[indexParent].product[indexProduk].unsuitable;

    newData[dataIndex].palet_body[indexParent].product[indexProduk][name] = parseInt(e.target.value);
    if (
      0 <=
      data[dataIndex].palet_body[indexParent].product[indexProduk].recieve -
        newData[dataIndex].palet_body[indexParent].product[indexProduk].lost -
        newData[dataIndex].palet_body[indexParent].product[indexProduk].damaged -
        newData[dataIndex].palet_body[indexParent].product[indexProduk].unsuitable
    ) {
      newData[dataIndex].palet_body[indexParent].product[indexProduk].recieve =
        totalNumber -
        newData[dataIndex].palet_body[indexParent].product[indexProduk].lost -
        newData[dataIndex].palet_body[indexParent].product[indexProduk].damaged -
        newData[dataIndex].palet_body[indexParent].product[indexProduk].unsuitable;

      setCopyData(newData);
      setProductEdited((prev) => [...new Set([...prev, idCarton])]);
    }
  };

  const removeImageApproval = (image, indexParent, indexImage, idCarton, dataIndex) => {
    swal({
      title: `Apakah Anda Yakin Ingin Menghapus Gambar ?`,
      text: `Url gambar : ${image}`,
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then(async (remove) => {
      if (remove) {
        const newData = JSON.parse(JSON.stringify(copyData));
        newData[dataIndex].palet_body[indexParent].foto.splice([indexImage], 1);
        setCopyData(newData);
        setProductEdited((prev) => [...new Set([...prev, idCarton])]);
      }
    });
  };

  const handleChangeImages = async (event, indexParent, idCarton) => {
    setIsLoading(true);
    let formData = new FormData();
    formData.append('gambar', event.target.files[0]);
    const response = await fetch(
      `${process.env.REACT_APP_URL_API_IMAGE_UPLOAD2}`,
      {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${Cookies.get('oms_token')}`,
        }
      }
    );
    const result = await response.json();
    if (result?.status === 200) {
      const newData = JSON.parse(JSON.stringify(copyData));
      newData[indexParent].gambar.push(result?.file);

      setCopyData(newData);
      setProductEdited((prev) => [...new Set([...prev, idCarton])]);
      setIsLoading(false);
    } else {
      swal('Oops', `Images ${result.message}`, 'error');
      setIsLoading(false);
    }
  };

  const saveChanges = (data, noPalet, index) => {
    const newData = {...data, palet: noPalet}
    console.log({newData})
    swal({
      title: `Apakah Anda Yakin Ingin Mengubah Data ?`,
      text: `Edit ID Order : ${data?.id_karton}`,
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then(async (Edit) => {
      if (Edit) {
        setIsLoading(true);
        const response = await editDataAprovalWHindo(JSON.stringify(newData));
        if (response.status === 200 || response.status === 201) {
          swal(
            'success',
            `Berhasil Mengedit, ID Order : ${data?.id_karton}`,
            'success'
          ).then(async () => {
            setProductEdited((prev) =>
              prev.filter((arr) => arr !== data?.id_karton)
            );
            setEditApprovalObj(false);
            handleShowMore(data?.id_karton);
            setIsLoading(false);
          });
          setDataUpdated((prev) => !prev);
        } else {
          swal('Error', `${response.message}`, 'error');
          setIsLoading(false);
        }
      }
    });
  };

  const resetData = (dataIndex, index, idSO) => {
    const newCopyData = JSON.parse(JSON.stringify(copyData));
    newCopyData[dataIndex].palet_body[index] = data[dataIndex].palet_body[index];
    setCopyData(newCopyData);
    setProductEdited((prev) => prev.filter((arr) => arr !== idSO));
    setEditApprovalObj(false);
  };
  
  const checkRowDataPalet = (value, datas) => {
    if(value) {
      datas?.palet_body?.forEach((item) => {
        const filterRowData = checkedRowData.find((data) => data.id_karton === item.id_karton)
        if(!filterRowData) {
          setCheckedRowData(prev => {
            // return [...prev, {id_so: ~~item.id_order, id_karton: item.id_karton}]
            return [...prev, item]
          })
        }
      })
      setCheckedRowDataPalet([...new Set([...checkedRowDataPalet, datas.no_palet])]);
    } else {
      let tempRowData = [...checkedRowData]
      datas?.palet_body?.forEach((item) => {
          const filterRowData = tempRowData.filter((data) => data.id_karton !== item.id_karton)
          tempRowData = filterRowData
        })
      setCheckedRowData(tempRowData)
      setCheckedRowDataPalet(checkedRowDataPalet.filter(e => e !== datas.no_palet));
    }
  }

  const checkRowData = (value, datas) => {
    if(value) {
      const filterRowData = checkedRowData.find((data) => data.id_karton === datas.id_karton)
      if(!filterRowData) {
        setCheckedRowData(prev => {return [...prev, {id_so: ~~datas.id_order, id_karton: datas.id_karton}]})
      }
    } else {
      const filterRowData = checkedRowData.filter((data) => data.id_karton !== datas.id_karton)
      setCheckedRowData(filterRowData)
    }
  }
  const checkAllRowData = (value) => {
    if(value) {
        let newRowDataPalet = []
        let newRowData = []
        data.forEach(element => {
          newRowDataPalet.push(element.no_palet)

          element.palet_body.forEach((item) => {
            newRowData.push({id_so: item.id_order, id_karton: item.id_karton})
          })
        });
        setCheckedRowDataPalet(newRowDataPalet)
        setCheckedRowData(newRowData)
    } else {
      setCheckedRowDataPalet([])
      setCheckedRowData([])
    }
  }

  const Row = ({ datas, dataIndex }) => {
    return (
      <>
        <TableRow>
          <TableCell
            style={{
              textAlign: "center",
            }}
          >
            {getUser().roles?.includes('admin') &&
              <input type="checkbox" 
              onChange={(e) => checkRowDataPalet(e.target.checked, datas)}
              checked={checkedRowDataPalet.includes(datas.no_palet)}
              />
            }
          </TableCell>
          <TableCell
            style={{
              textAlign: "center",
            }}
          >
            {datas.no_palet}
            
            <div className="text-center">
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => {
                  setOpen(open.includes(datas.no_palet) ? open.filter(i => i !== datas.no_palet) : [...open, datas.no_palet]);
                }}
              >
                {open.includes(datas.no_palet) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
              <span className="text-xs">Details</span>
            </div>
          </TableCell>
          <TableCell
            style={{
              textAlign: "center",
            }}
          >
            {datas.total_id_order}
          </TableCell>
          <TableCell
            style={{
              textAlign: "center",
            }}
          >
            {datas.total_karton}
          </TableCell>
          <TableCell
            style={{
              textAlign: "center",
            }}
          >
            {datas.tanggal}
          </TableCell>
          <TableCell
            style={{
              textAlign: "center",
            }}
          >
            <AccessTimeIcon className={`ml-2 ${datas?.warna}`} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={6}>
            <Collapse in={open.includes(datas.no_palet)} timeout="auto" unmountOnExit>
              <Box>
                <Table size="small" aria-label="purchases">
                  <TableHead className='bg-blue-200'>
                    <TableRow>
                      <TableCell style={{ width: "3%", background: 'white', border: 'none' }}></TableCell>
                      <TableCell style={{ width: "3%", background: 'white', border: 'none' }}></TableCell>
                      <TableCell style={{
                          width: "15%",
                          fontWeight: "bold",
                          fontSize: ".75rem",
                          textAlign: "center",
                        }}
                      >ID Order</TableCell>
                      <TableCell style={{
                          width: "15%",
                          fontWeight: "bold",
                          fontSize: ".75rem",
                          textAlign: "center",
                        }}
                      >ID Carton</TableCell>
                      <TableCell style={{
                          width: "15%",
                          fontWeight: "bold",
                          fontSize: ".75rem",
                          textAlign: "center",
                        }}
                      >Customer</TableCell>
                      <TableCell style={{
                          width: "15%",
                          fontWeight: "bold",
                          fontSize: ".75rem",
                          textAlign: "center",
                        }}
                      >No. Kontainer</TableCell>
                      <TableCell style={{
                          width: "15%",
                          fontWeight: "bold",
                          fontSize: ".75rem",
                          textAlign: "center",
                        }}
                      >Kota</TableCell>
                      <TableCell style={{
                          width: "15%",
                          fontWeight: "bold",
                          fontSize: ".75rem",
                          textAlign: "center",
                        }}
                      >Diterima</TableCell>
                      <TableCell style={{
                          width: "15%",
                          fontWeight: "bold",
                          fontSize: ".75rem",
                          textAlign: "center",
                        }}
                      >Hilang</TableCell>
                      <TableCell style={{
                          width: "15%",
                          fontWeight: "bold",
                          fontSize: ".75rem",
                          textAlign: "center",
                        }}
                      >Rusak</TableCell>
                      <TableCell style={{
                          width: "15%",
                          fontWeight: "bold",
                          fontSize: ".75rem",
                          textAlign: "center",
                        }}
                      >Tidak Sesuai</TableCell>
                      <TableCell style={{
                          width: "15%",
                          fontWeight: "bold",
                          fontSize: ".75rem",
                          textAlign: "center",
                        }}
                      >Catatan</TableCell>
                      <TableCell style={{
                          width: "15%",
                          fontWeight: "bold",
                          fontSize: ".75rem",
                          textAlign: "center",
                        }}
                      >User</TableCell>
                      <TableCell style={{
                          width: "15%",
                          fontWeight: "bold",
                          fontSize: ".75rem",
                          textAlign: "center",
                        }}
                      >Lampiran</TableCell>
                      <TableCell style={{
                          width: "15%",
                          fontWeight: "bold",
                          fontSize: ".75rem",
                          textAlign: "center",
                        }}
                      >Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {datas?.palet_body?.map((pallet, index) => (
                      <React.Fragment key={pallet.id_karton}>
                        <TableRow className={`${index%2 === 0 ? "" : "bg-blue-100"}`}>
                          <TableCell style={{ width: "3%", background: 'white', border: 'none' }}></TableCell>
                          <TableCell style={{ width: "3%", background: 'white', border: 'none' }} component="th" scope="row">
                              {getUser().roles?.includes('admin') &&
                                <div className="flex-shrink-0 w-[3%] text-center">
                                  <input type="checkbox" 
                                  onChange={(e) => checkRowData(e.target.checked, pallet)}
                                  checked={checkedRowData.map((data) => data.id_karton).includes(pallet.id_karton)}
                                  />
                                </div>
                              }
                          </TableCell>
                          <TableCell style={{ fontSize: '.75rem', textAlign: 'center' }} component="th" scope="row">
                            {pallet.id_order}
                            <IconButton
                              className={`${
                                isBolean?.isShowMore === pallet.id_karton ? 'rotate-0' : 'rotate-180'
                              } hover:bg-blue-100`}
                              onClick={(e) => handleShowMore(pallet.id_karton)}>
                              <KeyboardArrowUpSharpIcon
                                fontSize="small"
                                className={`border border-blue-500 hover:bg-blue-100 rounded-full text-blue-500`}
                              />
                            </IconButton>
                          </TableCell>
                          <TableCell style={{ fontSize: '.75rem', textAlign: 'center' }}>{pallet.id_karton}</TableCell>
                          <TableCell style={{ fontSize: '.75rem', textAlign: 'center' }}>{pallet.customer}</TableCell>
                          <TableCell style={{ fontSize: '.75rem', textAlign: 'center' }}>{pallet.no_kontainer}</TableCell>
                          <TableCell style={{ fontSize: '.75rem', textAlign: 'center' }}>{pallet.kota}</TableCell>
                          <TableCell style={{ fontSize: '.75rem', textAlign: 'center' }}>{pallet.diterima}</TableCell>
                          <TableCell style={{ fontSize: '.75rem', textAlign: 'center' }}>{pallet.hilang}</TableCell>
                          <TableCell style={{ fontSize: '.75rem', textAlign: 'center' }}>{pallet.rusak}</TableCell>
                          <TableCell style={{ fontSize: '.75rem', textAlign: 'center' }}>{pallet.tidak_sesuai}</TableCell>
                          <TableCell style={{ fontSize: '.75rem', textAlign: 'center' }}>{pallet.catatan ?? '-'}</TableCell>
                          <TableCell style={{ fontSize: '.75rem', textAlign: 'center' }}>{pallet.user}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            {datas?.foto?.length !== 0 ? 
                              <CheckCircleOutlineRoundedIcon className='text-green-500' />
                              : 
                              <HighlightOffRoundedIcon className='text-red-500' />
                            }
                          </TableCell>
                          <TableCell style={{ fontSize: '.75rem' }}>
                            {getUser().roles?.includes('admin') &&
                              <div className="w-full space-y-2">
                                {!datas?.is_checked && (
                                  <button
                                    onClick={(e) => handleSendAproval(pallet, setCheckedRowData)}
                                    className="bg-blue-500 hover:bg-blue-600 w-full text-white hover:scale-105 duration-100 px-3 rounded-sm">
                                    Konfirmasi
                                  </button>
                                )}
                                <button
                                  onClick={(e) => handleRejectAproval(pallet)}
                                  className="bg-red-500 hover:bg-red-600 w-full text-white hover:scale-105 duration-100 px-3 rounded-sm">
                                  Reject
                                </button>
                              </div>
                            }
                          </TableCell>
                        </TableRow>

                        {/* DROPDOWN ROW DETAILS */}
                        <TableRow>
                          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={15}>
                            <Collapse in={isBolean?.isShowMore === pallet.id_karton} timeout="auto" unmountOnExit>
                              <Box sx={{ margin: 1 }}>
                                <div className="w-full flex flex-col gap-2 px-2">
                                  <div className="flex gap-5">
                                    <div className="w-1/2 h-[530px] border-2 rounded-md">
                                      <div className="font-semibold text-gray-600 bg-gray-100 p-2">
                                        Tentang Customer
                                      </div>
                                      <hr />
                                      <div className="p-2 font-medium space-y-2">
                                        <div className="flex gap-x-3 ">
                                          <p className="w-20">Nama</p>
                                          <p>: {pallet?.customer}</p>
                                        </div>
                                        <div className="flex gap-x-3 ">
                                          <p className="w-20">Telepon</p>
                                          <p>: {getUser().isBlur ? censorPhoneNumber(pallet?.telepon) : pallet?.telepon}</p>
                                        </div>
                                        <div className="flex gap-x-3 ">
                                          <p className="w-20">Kota</p>
                                          <p>: {pallet?.kota}</p>
                                        </div>
                                        <div className="flex gap-x-3">
                                          <p className="w-20">Keterangan</p>
                                          <p>
                                            :{' '}
                                            {pallet?.catatan ?? '-'}
                                          </p>
                                        </div>
                                        <div className="flex gap-x-3 ">
                                          <p className="w-20">Palet</p>
                                          <p>: {datas.no_palet}</p>
                                        </div>
                                      </div>
                                      <hr />
                                      <div className="p-2 font-medium space-y-2">
                                        <div className="flex space-x-2">
                                          <p className="w-60">Total Product Di Beli Customer</p>
                                          <p>:</p>
                                          <p>{pallet?.product?.length}</p>
                                        </div>
                                        <div className="flex space-x-2">
                                          <p className="w-60">Total Karton</p>
                                          <p>:</p>
                                          <p>{datas?.total_karton}</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="w-1/2 h-[530px] overflow-scroll border-2 rounded-md">
                                      <div className="flex justify-between items-center font-medium text-gray-600 bg-gray-100 p-2 sticky top-0">
                                        Tentang Produk Customer
                                        {productEdited.includes(pallet?.id_karton) && (
                                          <div
                                            className="bg-blue-500 hover:bg-blue-600 text-white text-xs px-3 py-1 rounded-lg cursor-pointer"
                                            onClick={() => resetData(dataIndex, index, pallet?.id_karton)}>
                                            RESET
                                          </div>
                                        )}
                                      </div>
                                      <hr />
                                      <div className="flex font-medium p-2 gap-x-2">
                                        <p className="w-14">Produk</p>
                                        <p>:</p>
                                        <p className="line-clamp-3">
                                          {pallet?.product?.[0]?.produk}
                                        </p>
                                      </div>
                                      <div className="flex font-medium p-2 gap-x-2">
                                        <p className="w-14">Images</p>
                                        <p>:</p>
                                        <div className="flex items-center flex-wrap gap-2">
                                          {pallet?.foto?.map((image, idx) => (
                                            <div key={idx} className="relative">
                                              <CloseIcon
                                                fontSize="small"
                                                className="absolute top-0 right-0 z-10 text-white bg-black opacity-10 hover:opacity-75 rounded-xl"
                                                onClick={() =>
                                                  removeImageApproval(
                                                    image,
                                                    index,
                                                    idx,
                                                    pallet.id_karton,
                                                    dataIndex,
                                                  )
                                                }
                                              />
                                              <img
                                                key={idx}
                                                src={image}
                                                className="w-28 h-20 object-contain border rounded-lg overflow-hidden cursor-pointer"
                                                alt="approval"
                                                onClick={() => setOpenLightbox(image)}
                                              />
                                              {openLightbox === image && (
                                                <Lightbox
                                                  mainSrc={image}
                                                  onCloseRequest={() => setOpenLightbox(false)}
                                                />
                                              )}
                                            </div>
                                          ))}
                                          <label htmlFor={pallet.id_karton}>
                                            {/* <AddIcon className="opacity-50 hover:opacity-100 hover:scale-110 cursor-pointer" /> */}
                                            <div className="w-fit shrink-0 text-center text-white text-xs bg-green-500 hover:bg-green-600 px-2 py-1 rounded-xl cursor-pointer">
                                              {isLoading
                                                ? 'Uploading image...'
                                                : 'Tambah Lampiran'}
                                            </div>
                                          </label>
                                          <input
                                            type="file"
                                            name={pallet.id_karton}
                                            id={pallet.id_karton}
                                            hidden
                                            onChange={(e) =>
                                              !isLoading &&
                                              handleChangeImages(e, index, pallet.id_karton)
                                            }
                                            disabled={isLoading || !getUser().roles?.includes('admin')}
                                          />
                                        </div>
                                      </div>

                                      {getUser().roles?.includes('admin') && productEdited.includes(pallet?.id_karton) && (
                                        <div className="flex justify-end px-4">
                                          <div
                                            className="bg-blue-500 hover:bg-blue-600 text-white text-xs rounded-lg px-3 py-1 mb-2 cursor-pointer"
                                            onClick={() =>
                                              !isLoading && saveChanges(pallet, datas.no_palet, index)
                                            }>
                                            {isLoading
                                              ? 'Saving changes...'
                                              : 'Simpan Perubahan'}
                                          </div>
                                        </div>
                                      )}
                                      <hr />
                                      <div className="flex flex-col max-h-[275px] divide-y-2 space-y-5 overflow-y-auto p-2">
                                        {pallet?.product?.map((objProduk, idx) => (
                                          <div key={idx} className="mt-2 p-2 h-full relative">
                                            <div
                                              className={`${
                                                editApprovalObj === objProduk.id_produk
                                                  ? 'text-red-600'
                                                  : 'text-blue-600'
                                              } absolute top-2 right-2 text-xs font-semibold cursor-pointer`}
                                              onClick={() =>
                                                !getUser().roles?.includes('admin') ? null : editApprovalObj === objProduk.id_produk
                                                  ? setEditApprovalObj(false)
                                                  : setEditApprovalObj(objProduk.id_produk)
                                              }>

                                              {!getUser().roles?.includes('admin') ? null : editApprovalObj === objProduk.id_produk ? 'Close' : 'Edit'}
                                            </div>
                                            <div className="flex gap-5">
                                              <img
                                                className="w-[20%] object-contain"
                                                src={`${
                                                  objProduk?.gambar
                                                    ? objProduk?.gambar
                                                    : '/no-image.png'
                                                }`}
                                                alt={`${objProduk?.gambar}`}
                                              />
                                              <div className="font-medium">
                                                <div className="flex gap-x-2 pr-6">
                                                  <p className="w-14">Variant</p>
                                                  <p>:</p>
                                                  <p className="line-clamp-2">
                                                    {objProduk?.variant}
                                                  </p>
                                                </div>
                                                <div className="grid grid-cols-2">
                                                  <div>
                                                    <div className="flex gap-x-2">
                                                      <p className="w-14">Diterima</p>
                                                      <p>:</p>
                                                      <input
                                                        type="number"
                                                        value={objProduk?.recieve}
                                                        // onChange={(e) =>
                                                        //   set_EditApprovalObj(
                                                        //     e,
                                                        //     'recieve',
                                                        //     index,
                                                        //     idx,
                                                        //     pallet?.id_karton,
                                                        //     dataIndex
                                                        //   )
                                                        // }
                                                        className={`w-12 bg-white text-gray-400 font-semibold`}
                                                        disabled
                                                      />
                                                    </div>
                                                    <div className="flex gap-x-2">
                                                      <p className="w-14">Hilang</p>
                                                      <p>:</p>
                                                      <input
                                                        type="number"
                                                        value={objProduk?.lost}
                                                        onChange={(e) =>
                                                          set_EditApprovalObj(
                                                            e,
                                                            'lost',
                                                            index,
                                                            idx,
                                                            pallet?.id_karton,
                                                            dataIndex
                                                          )
                                                        }
                                                        className={`w-12 bg-white ${
                                                          editApprovalObj === objProduk.id_produk && 'border-b-2'
                                                        }`}
                                                        disabled={
                                                          editApprovalObj !== objProduk.id_produk
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                  <div>
                                                    <div className="flex gap-x-2">
                                                      <p className="w-14">Rusak</p>
                                                      <p>:</p>
                                                      <input
                                                        type="number"
                                                        value={objProduk?.damaged}
                                                        onChange={(e) =>
                                                          set_EditApprovalObj(
                                                            e,
                                                            'damaged',
                                                            index,
                                                            idx,
                                                            pallet?.id_karton,
                                                            dataIndex
                                                          )
                                                        }
                                                        className={`w-12 bg-white ${
                                                          editApprovalObj === objProduk.id_produk && 'border-b-2'
                                                        }`}
                                                        disabled={
                                                          editApprovalObj !== objProduk.id_produk
                                                        }
                                                      />
                                                    </div>
                                                    <div className="flex gap-x-2">
                                                      <p className="w-28">Tidak Sesuai</p>
                                                      <p>:</p>
                                                      <input
                                                        type="number"
                                                        value={objProduk?.unsuitable}
                                                        onChange={(e) =>
                                                          set_EditApprovalObj(
                                                            e,
                                                            'unsuitable',
                                                            index,
                                                            idx,
                                                            pallet?.id_karton,
                                                            dataIndex
                                                          )
                                                        }
                                                        className={`w-12 bg-white ${
                                                          editApprovalObj === objProduk.id_produk && 'border-b-2'
                                                        }`}
                                                        disabled={
                                                          editApprovalObj !== objProduk.id_produk
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };
  
  // if (!copyData) {
  //   return null;
  // }
  return (
    <div className="w-full h-auto rounded-md ">
      {getUser().roles?.includes('admin') && checkedRowData.length > 1 &&
        <div className='space-x-3'>
          <button
            onClick={(e) => handleSendAproval(checkedRowData, setCheckedRowData)}
            className="bg-blue-500 hover:bg-blue-600 w-fit text-white hover:scale-105 duration-100 px-3 rounded-sm mb-2">
            Konfirmasi Rows Terpilih
          </button>
          <button
            onClick={(e) => handleRejectMultipleAproval(checkedRowData)}
            className="bg-red-500 hover:bg-red-600 w-fit text-white hover:scale-105 duration-100 px-3 rounded-sm mb-2">
            Reject Rows Terpilih
          </button>
        </div>
      }
      
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead className='bg-blue-300'>
            <TableRow>
              <TableCell
                style={{
                  width: "3%",
                  textAlign: "center",
                }}
              >
                {getUser().roles?.includes('admin') &&
                  <input id="check-all" type="checkbox" onChange={(e) => checkAllRowData(e.target.checked)} />
                }
              </TableCell>
              <TableCell
                style={{
                  width: "15%",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                No. Palet
              </TableCell>
              <TableCell
                style={{
                  width: "15%",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Total ID Order
              </TableCell>
              <TableCell
                style={{
                  width: "15%",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Total Karton
              </TableCell>
              <TableCell
                style={{
                  width: "15%",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Tanggal
              </TableCell>
              <TableCell
                style={{
                  width: "15%",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {copyData?.length > 0 ? (
              copyData?.map((row, index) => (
                <Row datas={row} key={index} dataIndex={index} />
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={6}
                  align="center"
                  style={{ height: "70vh" }}
                >
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TableApprovalWhIndo;
