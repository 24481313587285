import Layout from 'components/Layout';
import { useEffect, useState } from 'react';
// import { getUser } from 'helpers/parseJWT';
import CmsWebConfigIndex from 'components/Cms/WebConfig/index';
import { getCmsWebConfigsData } from 'service/api';
import { SubRoutesCms as SUBROUTES } from 'components/Cms/SubRoutesCms';

const CmsWebConfig = () => {
    return (
        <Layout routes={SUBROUTES()} title="CMS">
            <CmsWebConfigIndex/>
        </Layout>
    );
};

export default CmsWebConfig;
