import { useState, useEffect } from 'react';
import { getAllOrdersData } from 'service/api';
import { Tooltip } from '@mui/material';
import Layout from 'components/Layout';
import swal from 'sweetalert';
import AllOrdersTable from 'components/ManagementSales/AllOrders/AllOrdersTable';
import FilterDate from 'components/ManagementSales/AllOrders/FilterDate';
import { SubRoutesManagementSales as SUBROUTES } from 'components/ManagementSales/SubRoutesManagementSales';
import {  useLocation } from 'react-router-dom';

const AllOrders = () => {
  const router = useLocation();
  const [data, setData] = useState([]);
  const [filterWithSales, setFilterWithSales] = useState([]);
  const [update, setUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [date, setDate] = useState({
    sales: '',
    start: '',
    end: '',
    status: '',
    id: '',
  });
  const paramsId = new URLSearchParams(router?.search).get('id');

  const fetchData = async () => {
    setIsLoading(true);

    const queryId = paramsId !== null ? `id=${paramsId}&` : '';
    const salesParams = date.sales !== '' ? `sales=${date.sales}&` : '';
    const startParams = date.start !== '' ? `start=${date.start}&` : '';
    const endParams = date.end !== '' ? `end=${date.end}&` : '';
    const statusParams = date.status !== '' ? `status=${date.status}&` : '';
    const idParams = date.id !== '' ? `id=${date.id}&` : '';

    const params =
      `page=${page}&limit=${limit}&` +
      salesParams +
      startParams +
      endParams +
      statusParams +
      idParams + 
      queryId;

    const response = await getAllOrdersData(params);
    if (response?.status === 200) {
      setData(response?.data);
      if(response.sales?.length !== 0) {
        setFilterWithSales(response.sales.map((user) => user.user));
      }
    } else if (response?.status === 500) {
      swal('Oops', response?.message || "There's a problem occured. Please try again in 15 minutes or contact IT", 'error');
    } else {
      swal('Oops', `${response?.message}`, 'error');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update, page, limit]);

  return (
    <Layout routes={SUBROUTES()} title="CRM">
      <Tooltip title="Refresh table" placement="right">
        <p
          className="my-4 bg-white w-32 p-2  rounded-md cursor-pointer text-center"
          onClick={fetchData}>
          All Orders
        </p>
      </Tooltip>
      <FilterDate
        page={page}
        date={date}
        setDate={setDate}
        setPage={setPage}
        setUpdate={setUpdate}
        filterWithSales={filterWithSales}
        isLoading={isLoading}
      />

      <AllOrdersTable
        page={page}
        limit={limit}
        dataTable={data?.data}
        isLoading={isLoading}
        totalPage={data.totalPage}
        setPage={setPage}
        setLimit={setLimit}
        setUpdate={setUpdate}
      />
    </Layout>
  );
};

export default AllOrders;
