import React from "react";
import Cookies from "js-cookie";
import { Route, Redirect, withRouter } from "react-router-dom";
import NotFound from "pages/NotFound";
import { getUser } from "helpers/parseJWT";

const MemberRoute = ({
  component: Component,
  match,
  path,
  location,
  isAllowed,
  byDivision,
  ...rest
}) => {
  const ok = Cookies.get("oms_token");
  return (
    <Route
      {...rest}
      render={(props) =>
        ok ? 
          isAllowed.some((i) => getUser().roles?.includes(i)) ||
          (byDivision && (isAllowed.some((i) => getUser().division?.includes(i)) || getUser().user === 'wahyu')) ? 
          <Component {...props} /> : 
          <NotFound /> : 
          <Redirect to={`/login`} />
      }
    />
  );
};

export default withRouter(MemberRoute);
