import { useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import InitRequestModal from './InitRequestModal';
import { useTranslation } from 'react-i18next';

export default function InitRequest({ setUpdate }) {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation()

  const handleOpen = () => setOpen(true);

  return (
    <div>
      <button
        className='rounded-md bg-blue-500 p-2 text-white flex items-center space-x-1 text-sm'
        onClick={() => {
          handleOpen();
        }}>
        <AddCircleIcon style={{ fontSize: 18 }} /> <span>{t("Add Request")}</span>
      </button>

      <InitRequestModal 
        setUpdate={setUpdate}
        open={open}
        setOpen={setOpen}
      />
    </div>
  );
}
