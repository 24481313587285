import { useEffect, useState } from "react";
import {
  CircularProgress,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  TableCell,
  IconButton,
  Collapse,
  Typography,
} from "@mui/material";
import swal from "sweetalert";
import { get_report_product_price } from "service/api";

export default function SolveIssueSalesTableTable({ 
  data,
  products,
  setProducts,
  productsAfter,
  setProductsAfter,
  isChangeSupplier,
  totalPaid,
  setTotalPaid,
  finalTotal,
  // setFinalTotal,
  finalQtyAll,
  setFinalQtyAll,
  // isQtyChanged,
  // setIsQtyChanged,
  solution,
  // setSolution,
  additionalShipping,
  setAdditionalShipping,
  initialShippingFee,
  localShipping,
  setShowAddAdjustments,
  adjustment,
  setAdjustment,
  total_adjustment,
  handleSubmitSolveIssue,
  dataChanged,
  setDataChanged,
  handleSubmitApproveOrReject
}) {
  const changeFinalQty = (e, id) => {
    let tempNewData = [...products];
    if (id !== undefined) {
      tempNewData[id].final_qty = e.target.value;
      setFinalQtyAll("");
    } else {
      tempNewData.forEach((data) => {
        if (data.disabled !== true) {
          data.final_qty = e.target.value;
        }
      });
      setFinalQtyAll(e.target.value);
    }
    setDataChanged(true)
    setProducts(tempNewData);
  };

  const set_AdditionalShipping = (value) => {
    const newValue = value.replaceAll('.','')
    setDataChanged(true)
    setAdditionalShipping((~~newValue).toLocaleString("id-ID"))
  }

  if (!products) {
    return null;
  }
  
  return (
    <>
      <div className="max-h-[45vh] overflow-y-auto">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  style={{
                    width: "5%",
                  }}>
                  PIC
                </TableCell>
                <TableCell
                  style={{
                    width: "20%",
                  }}>
                  SKU
                </TableCell>
                <TableCell
                  style={{
                    width: "20%",
                  }}>
                  Product
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    width: "10%",
                  }}>
                  Qty Ordered
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    width: "10%",
                  }}>
                  Qty Available
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    width: "10%",
                  }}>
                  Initial Price/pcs
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    width: "10%",
                  }}>
                  New Price/pcs
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    width: "10%",
                  }}>
                  MOQ
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    width: "10%",
                  }}>
                  Actual Stock
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    width: "10%",
                  }}>
                  Final Qty
                  <input
                    type="number"
                    name={`final-qty-all`}
                    id={`final-qty-all`}
                    className="border-2 rounded px-2 w-16 text-center"
                    style={{ fontSize: "0.75rem" }}
                    value={finalQtyAll}
                    onChange={changeFinalQty}
                  />
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    width: "10%",
                  }}>
                  Total per variant
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {products?.length > 0 ? (
                <>
                  {products?.map((rowData, id) => (                    
                    <TableRow key={id}>
                      <TableCell
                        align="center"
                        style={{
                          width: "5%",
                        }}>
                        <img src={isChangeSupplier ? productsAfter?.[id]?.pic : rowData.pic} alt="product image" width={18} height={18} className="rounded overflow-hidden mx-auto" />
                      </TableCell>
                      <TableCell
                        style={{
                          width: "20%",
                          fontSize: ".75rem",
                        }}>
                        <div className="w-full line-clamp-1 break-all" title={rowData.sku}>
                          {isChangeSupplier ? productsAfter?.[id]?.sku : rowData.sku}
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          width: "20%",
                          fontSize: ".75rem",
                        }}>
                        <div className="w-full line-clamp-2 break-all" title={rowData.product}>
                          {isChangeSupplier ? productsAfter?.[id]?.product : rowData.product}
                        </div>
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          width: "10%",
                          fontSize: ".75rem",
                        }}>
                        {isChangeSupplier ? rowData.qty : rowData.qty_available}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          width: "10%",
                          fontSize: ".75rem",
                          // color: `${(isChangeSupplier && rowData.qty < productsAfter?.[id]?.qty) || 
                          //           (!isChangeSupplier && rowData.qty < rowData.qty_available) ? 
                          //           "red" : ""
                          //         }`
                        }}>
                        {isChangeSupplier ? productsAfter?.[id]?.qty : rowData.qty_available}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          width: "10%",
                          fontSize: ".75rem",
                        }}>
                        Rp. {Math.ceil(rowData.price).toLocaleString('id-ID')}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          width: "10%",
                          fontSize: ".75rem",
                        }}>
                        Rp. {isChangeSupplier ? 
                          (Math.ceil(productsAfter[id].price + productsAfter[id].tax + productsAfter[id].processing_fee + productsAfter[id].cbm_calc)).toLocaleString('id-ID') 
                          : 
                          // (Math.ceil(rowData.changed_price_indo + rowData.tax + rowData.processing_fee + rowData.cbm_calc)).toLocaleString('id-ID')
                          (Math.ceil((rowData.new_price * data.currency) + 
                                    ((rowData.new_price * data.currency) * 0.11) + 
                                    ((rowData.new_price * data.currency) * rowData.level_discount) + 
                                    rowData.cbm_calc)).toLocaleString('id-ID')
                        }
                      </TableCell>
                      {isChangeSupplier &&
                        <>
                          <TableCell
                            align="center"
                            style={{
                              width: "10%",
                              fontSize: ".75rem",
                            }}>
                            {productsAfter[id].moq}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              width: "10%",
                              fontSize: ".75rem",
                            }}>
                            {productsAfter[id].actual_stock}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              width: "10%",
                              fontSize: ".75rem",
                            }}>
                            <input type="number" name="qty_available_after" id="qty_available_after" 
                              value={rowData.final_qty} 
                              className={`border-2 rounded px-2 w-16 text-center ${
                                ~~rowData.final_qty < rowData.qty ? "text-red-500" :
                                ~~rowData.final_qty > rowData.qty ? "text-green-500" :
                                "text-gray-800"
                              }`}
                              onChange={(e) => productsAfter[id].actual_stock >= e.target.value && changeFinalQty(e, id)} 
                              disabled={rowData.disabled}
                              title={rowData.disabled ? "Quantity of available item is less than the minimum order quantity (MOQ)" : null}
                            />
                          </TableCell>
                        </>
                      }
                      {!isChangeSupplier &&
                        <>
                          <TableCell
                            align="center"
                            style={{
                              width: "10%",
                              fontSize: ".75rem",
                            }}>
                            {rowData.moq}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              width: "10%",
                              fontSize: ".75rem",
                            }}>
                            {rowData.actual_stock}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              width: "10%",
                              fontSize: ".75rem",
                            }}>
                            <input type="number" name="qty_available_after" id="qty_available_after" 
                              value={rowData.final_qty} 
                              className={`border-2 rounded px-2 w-16 text-center ${
                                ~~rowData.final_qty < rowData.qty ? "text-red-500" :
                                ~~rowData.final_qty > rowData.qty ? "text-green-500" :
                                "text-gray-800"
                              }`}
                              onChange={(e) => rowData.actual_stock >= e.target.value && changeFinalQty(e, id)} 
                              disabled={rowData.disabled}
                              title={rowData.disabled ? "Quantity of available item is less than the minimum order quantity (MOQ)" : null}
                            />
                          </TableCell>
                        </>
                      }
                      <TableCell
                        style={{
                          width: "10%",
                          fontSize: ".75rem",
                        }}>
                        <div className="whitespace-nowrap">
                          Rp. {isChangeSupplier ? 
                              (rowData.final_qty * Math.ceil(productsAfter[id].price + productsAfter[id].tax + productsAfter[id].processing_fee + productsAfter[id].cbm_calc)).toLocaleString('id-ID') 
                              : 
                              // (rowData.qty * Math.ceil(rowData.changed_price_indo + rowData.tax + rowData.processing_fee + rowData.cbm_calc)).toLocaleString('id-ID')
                              (rowData.final_qty * Math.ceil((rowData.new_price * data.currency) + 
                                            ((rowData.new_price * data.currency) * 0.11) + 
                                            ((rowData.new_price * data.currency) * rowData.level_discount) + 
                                            rowData.cbm_calc)).toLocaleString('id-ID')
                            }
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={5}
                    align="center"
                    style={{ height: "30vh" }}>
                    No data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="grid grid-cols-2 gap-8 mt-3">
        <div className="new-link-form">
        </div>
        <div className="totals flex">
          <div className="w-1/3 px-3">
            <div className="w-full text-white text-xs text-center bg-green-600 hover:bg-green-500 rounded-md px-3 py-1 cursor-pointer" onClick={() => setShowAddAdjustments(true)}>
              Add Adjusment
            </div>
          </div>
          <div className="w-2/3 flex-shrink-0 ml-auto text-sm">
            <div className="space-y-1">
              <div className="flex justify-between items-center pr-2">
                <div>Final Total Product</div>
                <div>Rp. {(finalTotal).toLocaleString('id-ID')}</div>
              </div>
              <div className="flex justify-between items-center">
                <div>Additional Shipping Fee</div>
                {/* <input type="text" name="additional-shipping-fee" id="additional-shipping-fee" 
                  className="border-2 rounded w-28 px-2 text-right"
                  value={additionalShipping}
                  onChange={(e) => set_AdditionalShipping(e.target.value)}
                /> */}
                <div className="px-2">
                  Rp. {additionalShipping.toLocaleString('id-ID')}
                </div>
              </div>
              {!(~~adjustment.asuransi) ? null :
                <div className="flex justify-between items-center pr-2">
                  <div>Asuransi</div>
                  <div>
                    Rp. {adjustment.asuransi.toLocaleString('id-ID')}
                  </div>
                </div>
              }
              {!(~~adjustment.inspeksi) ? null :
                <div className="flex justify-between items-center pr-2">
                  <div>Inspeksi</div>
                  <div>
                    Rp. {adjustment.inspeksi.toLocaleString('id-ID')}
                  </div>
                </div>
              }
              {!(~~adjustment.packingKayu) ? null :
                <div className="flex justify-between items-center pr-2">
                  <div>Packing Kayu</div>
                  <div>
                    Rp. {adjustment.packingKayu.toLocaleString('id-ID')}
                  </div>
                </div>
              }
              {!(~~adjustment.ongkir_international) ? null :
                <div className="flex justify-between items-center pr-2">
                  <div>Ongkir Internasional</div>
                  <div>
                    Rp. {adjustment.ongkir_international.toLocaleString('id-ID')}
                  </div>
                </div>
              }
              {/* <div className="flex justify-between items-center pr-2">
                <div>Initial Shipping Fee</div>
                <div>Rp. {initialShippingFee.toLocaleString('id-ID')}</div>
              </div> */}
              <div className="flex justify-between items-center pr-2">
                <div>Final ID Shipping Fee</div>
                <div>
                  Rp. {localShipping.toLocaleString('id-ID')}
                </div>
              </div>
            </div>
            
            <div className="flex justify-between items-center pr-2 font-bold mt-3">
              <div>Final Total Purchace</div>
              <div>Rp. {(finalTotal + total_adjustment + ~~localShipping + ~~additionalShipping).toLocaleString('id-ID')}</div>
            </div>
            <div className="flex justify-between items-center pr-2 font-bold">
              <div>Customer Paid</div>
              <div>Rp. {totalPaid?.toLocaleString('id-ID')}</div>
            </div>
            <div className="flex justify-between items-center pr-2 text-lg font-bold mt-3">
              <div className="">{solution}</div>
              <div>Rp. {(Math.abs(finalTotal + total_adjustment + ~~localShipping + ~~additionalShipping - ~~totalPaid)).toLocaleString('id-ID')}</div>
            </div>
            {dataChanged &&
            <div className='text-right mt-3'>
              <hr />
              <button
                className='bg-blue-600 hover:bg-blue-500 text-white p-1 rounded-md px-8 mt-3'
                onClick={handleSubmitSolveIssue}
              >
                SUBMIT
              </button>
            </div>
            }
            {!dataChanged &&
              <div className="flex justify-end items-center gap-2 mt-4">
                <div className="btn-reject w-28 text-center bg-red-600 hover:bg-red-700 text-white font-bold rounded-md px-5 py-1 cursor-pointer" onClick={() => handleSubmitApproveOrReject("Rejected")}>Reject</div>
                <div className="btn-approve w-28 text-center bg-blue-600 hover:bg-blue-700 text-white font-bold rounded-md px-5 py-1 cursor-pointer" onClick={() => handleSubmitApproveOrReject("Approved")}>Approve</div>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
}
