import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addData: {},
};

const dataRoomsSlice = createSlice({
  name: "additionalData",
  initialState,
  reducers: {
    setRoomsSlice: (state, action) => {
      state.addData = action.payload;
    },
  },
});

export const { setRoomsSlice } = dataRoomsSlice.actions;

export const selectRoomsData = (state) => state.addData.addData;

export default dataRoomsSlice.reducer;