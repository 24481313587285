import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./locales/en/translation.json";
import translationID from "./locales/id/translation.json";
import translationCN from "./locales/cn/translation.json";
import Cookies from "js-cookie";

const current_language = Cookies.get('language')
const resources = {
  en: {
    translation: translationEN,
  },
  id: {
    translation: translationID,
  },
  cn: {
    translation: translationCN,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: current_language || "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;