import { useState } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import swal from 'sweetalert';
import { insert_random_sales } from 'service/api';
import data from 'helpers/data';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  maxHeight: '90vh',
  overFlowY: 'auto',
  bgcolor: 'background.paper',
  border: '1px solid lightgray',
  boxShadow: 24,
  borderRadius: '5px',
  p: 4,
};

export default function AssigneSales({ dataSales, setChangeData, showModalAssigneSales, setShowModalAssigneSales, showModalResponse, setShowModalResponse }) {
  const [sales, setSales] = useState('')
  const [salesTarget, setSalesTarget] = useState('')
  const [kuantiti, setKuantiti] = useState('')
  // const [showModalAssigneSales, setShowModalAssigneSales] = useState(false)
  // const [showModalResponse, setShowModalResponse] = useState(false)
  const [responseData, setResponseData] = useState(null)

  const insertRandomSales = async () => {
    const payload = {
      sales: sales,
      kuantiti: ~~kuantiti,
      target: salesTarget,
    }

    const response = await insert_random_sales(JSON.stringify(payload))
    if(response.status === 200) {
      setChangeData(prev => !prev)
      setResponseData(response)
      setShowModalResponse(true)
      setShowModalAssigneSales(false)
    } else {
      swal("Oops", response.message.Message, "error");
    }
  }

  const set_ShowModalResponse = () => {
    setResponseData(null)
    setShowModalResponse(false)
    setSales('')
    setSalesTarget('')
    setKuantiti('')
  }

  return (
    <>
      <button
        className='bg-blue-500 disabled:bg-gray-500 rounded-md p-2 text-white hover:bg-blue-400 transition-all uppercase'
        onClick={() => setShowModalAssigneSales(true)}>
        Assigne Sales
      </button>
      <Modal
        open={showModalAssigneSales}
        onClose={() => setShowModalAssigneSales(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style} className="overflow-y-scroll variant-scroll">
          <div className="flex justify-end -mt-5">
            <IconButton onClick={() => setShowModalAssigneSales(false)} style={{ textAlign: 'right' }}>
              <CloseIcon />
            </IconButton>
          </div>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Assigne Sales
          </Typography>
          <div className="mt-3 space-y-3">
            <div className="space-y-3">
              <div>
                <label>Sales</label>
                <select name='sales' value={sales} 
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-blue"
                  onChange={(e) => setSales(e.target.value)}
                  >
                  <option value=''>select sales</option>
                  {dataSales?.map((sales, index) => (
                    <option key={index} value={sales}>{sales}</option>
                  ))}
                </select>
              </div>
              <div>
                <label>Sales Target</label>
                <select name='sales-target' value={salesTarget} 
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-blue"
                  onChange={(e) => setSalesTarget(e.target.value)}
                  >
                  <option value=''>select sales target</option>
                  {dataSales?.filter((target) => target !== sales).map((sales, index) => (
                    <option key={index} value={sales}>{sales}</option>
                  ))}
                </select>
              </div>
              <div>
                <label>Kuantiti</label>
                <input
                  name='kuantiti'
                  type="number"
                  min="0"
                  value={kuantiti}
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-blue"
                  onChange={(e) => setKuantiti(e.target.value)}
                />
              </div>
            </div>
            
            <div className="flex justify-end">
              <button
                onClick={insertRandomSales}
                className="text-white bg-blue-500 px-5 py-1 rounded-md focus:outline-blue">
                SUBMIT
              </button>
            </div>
          </div>
        </Box>
      </Modal>

      {/* MODAL RESPONSE AFTER SUBMIT ASSIGNE SALES */}
      <Modal
        open={showModalResponse}
        onClose={() => set_ShowModalResponse(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style} className="overflow-y-scroll variant-scroll">
          <div className="flex justify-end -mt-5">
            <IconButton onClick={() => set_ShowModalResponse(false)} style={{ textAlign: 'right' }}>
              <CloseIcon />
            </IconButton>
          </div>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Assigne Sales {responseData?.status === 200 ? 'Success' : 'Failed'}
          </Typography>
          <div className="mt-3">
            {responseData?.status !== 200 ?
              <div className="text-center">{responseData?.message}</div>
              :
              <div className="space-y-3">
                <div className="text-lg">
                  List customer yang berhasil di-assign ke sales <span className='font-bold capitalize'>{salesTarget}</span>:
                </div>
                <ul>
                  {responseData && responseData?.data?.map((data, index) => (
                  <li key={index}>- {data.email}</li>
                  ))}
                </ul>
              </div>
            }
          </div>
        </Box>
      </Modal>
    </>
  )
}
