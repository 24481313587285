import React, { useState } from "react";
import MenuDetailChat from "./MenuDetailChat";
import { getUser } from "helpers/parseJWT";

const MenuDetailUser = (props) => {
  const { saveMessages, setContent, content, setShowMenuBar, showMenuBar } =
    props;

  const [menu, setMenu] = useState("image");

  function censorPhoneNumber(phoneNumber) {
    const censoredDigits = phoneNumber.slice(0, -5) + "xxxxx";
    return censoredDigits;
  }
  return (
    <div className="flex-shrink-0 w-1/3 border-l">
      {saveMessages && saveMessages.data ? (
        saveMessages.data.map((item, index) => {
          return (
              <div
                key={index}
                className="flex flex-col border-b cursor-pointer">
                <div className="flex items-center pt-2 px-2">
                  <div className="relative mr-2 h-full overflow-hidden">
                    <img
                      className="w-12 h-12 rounded-full"
                      src={`${process.env.REACT_APP_URL_FOTO_STORAGE}/9b04ed370f56c14fe3dd9011ddaa062d.png`}
                      alt=""
                    />
                    <span className="bottom-0 left-7 absolute  w-3.5 h-3.5 bg-green-400 border-2 border-white dark:border-gray-800 rounded-full"></span>
                  </div>
                  <div className="w-3/4 ">
                    <span className="line-clamp-1 text-[14px] font-bold">
                      {item.name}
                    </span>
                    <span className="line-clamp-1 text-gray-400 text-sm font-normal">
                      {getUser().isBlur ? censorPhoneNumber(item.phone) : item.phone}
                    </span>
                    <span className="line-clamp-1 text-gray-400 text-sm font-normal">
                      {item.email}
                    </span>
                  </div>
                  <div
                    className="h-full flex items-start"
                    onClick={() => setShowMenuBar(!showMenuBar)}>
                    <img src={`${process.env.REACT_APP_URL_FOTO_STORAGE}/x-iconsss.png`} />
                    {/* <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11 0.5C5.15 0.5 0.5 5.15 0.5 11C0.5 16.85 5.15 21.5 11 21.5C16.85 21.5 21.5 16.85 21.5 11C21.5 5.15 16.85 0.5 11 0.5ZM15.05 16.25L11 12.2L6.95 16.25L5.75 15.05L9.8 11L5.75 6.95L6.95 5.75L11 9.8L15.05 5.75L16.25 6.95L12.2 11L16.25 15.05L15.05 16.25Z" fill="#FF9900"/>
                                    </svg> */}
                  </div>
                </div>
                <div className="p-2 px-4 flex justify-center gap-3 mb-5">
                  {/* <img src={`${process.env.REACT_APP_URL_FOTO_STORAGE}/Card Membership Status.png`} />
                    <img src={`${process.env.REACT_APP_URL_FOTO_STORAGE}/Offline.png`} /> */}
                </div>

                <div>
                  <div className="flex w-full text-center text-sm cursor-pointer ">
                    <div
                      onClick={() => setMenu("image")}
                      className={
                        menu === "image"
                          ? "w-1/3 p-2 py-6 border-b-4 border-blue-500 text-blue-500 font-semibold"
                          : "w-1/3 p-2 py-6 border-b-4"
                      }>
                      <b>Image</b>
                    </div>
                    <div
                      onClick={() => setMenu("media")}
                      className={
                        menu === "media"
                          ? "w-1/3 p-2  py-6 border-b-4 border-blue-500 text-blue-500 font-semibold"
                          : "w-1/3 p-2 py-6 border-b-4"
                      }>
                      <b>Media</b>
                    </div>
                    <div
                      onClick={() => setMenu("document")}
                      className={
                        menu === "document"
                          ? "w-1/3 p-2 py-6 border-b-4 border-blue-500 text-blue-500 font-semibold"
                          : "w-1/3 p-2 py-6 border-b-4"
                      }>
                      <b>Doc</b>
                    </div>
                  </div>
                </div>
              </div>
          );
        })
      ) : (
        // Tampilkan pesan alternatif jika saveMessages.data adalah null
        <div className="flex justify-center items-center w-full">
          <p>No data available</p>
        </div>
      )}
      <MenuDetailChat menu={menu} />
    </div>
  );
};

export default MenuDetailUser;
