import {
  formatDateDDMMYY,
  formateDateDDMMYYhhss,
  formateHHSS,
} from "helpers/ConvertTime";
import { useEffect, useRef, useState } from "react";
import Lightbox from "react-image-lightbox";
import ModalDetail from "./ModalDetail";
import { CircularProgress } from "@mui/material";
import Sanitized from "react-sanitized";
import DeleteIcon from "@mui/icons-material/Delete";
import useLongPress from "helpers/useLongPress";
import ModalConfirm from "./ModalConfirm";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CheckIcon from "@mui/icons-material/Check";
import { getUser } from "helpers/parseJWT";
import { useDispatch, useSelector } from "react-redux";
import { triggerRefetch } from "redux/dataSocketSlice";
import { getRoomState } from "atom";
import { useRecoilState } from "recoil";

function ChatRoom(props) {
  const {
    globalMessages,
    setGlobalMessages,
    noTelpon,
    // rooms,
    createRoomSocketJs,
  } = props;
  const scrollRef = useRef();
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState("");
  const [video, setVideo] = useState("");
  const [audio, setAudio] = useState("");
  const [showChatOption, setShowChatOption] = useState(false);
  const [activeChat, setActiveChat] = useState(false);
  const [volumeMessages, setVolumeMessages] = useState(null);
  const [messageSocket, setMessageSocket] = useState(null);
  const [triggerReConnect, setTriggerReConnect] = useState(false);
  const [showAttachmentMenu, setShowAttachmentMenu] = useState(false);
  const [isLoadingWs, setIsLoadingWs] = useState(false);
  const dispatch = useDispatch();
  const refAttachmenu = useRef();
  const chatOptionRef = useRef();
  const status = useSelector((state) => state.dataSocket);
  const statusTelpon = useSelector((state) => state.dataTelp);
  const [getRoom, setGetRoom] = useRecoilState(getRoomState);

  console.log('messageSocket', messageSocket)

  const fetchWebsocket = async () => {
    setIsLoadingWs(true);
    const storedData = localStorage.getItem("no-telpon");
    const data = JSON.parse(storedData);
    if (data) {
      const connection = new WebSocket(
        `${
          process.env.REACT_APP_URL_API_GET_LIST_WA_SOCKET
        }/ws/joinRoom/${data}?username=${getUser().user}&userId=${
          getUser().user
        }`
      );

      connection.onopen = (event) => {
        console.log("Koneksi WebSocket terbuka");
      };

      // Menangani event saat pesan diterima
      connection.onmessage = function (event) {
        const data = JSON.parse(event.data);
        dispatch(triggerRefetch());
        setMessageSocket(data);
        // console.log("data", data);
      };

      connection.onclose = function (event) {
        // console.error('Socket disconnect: ', event);
        setTriggerReConnect((prev) => !prev);
      };
      connection.onerror = function (err) {
        // console.error('Socket error: ', err);
        setTriggerReConnect((prev) => !prev);
      };
      return () => {
        connection.close();
      };
    }
    setIsLoadingWs(false);
  };

  // useEffect(() => {
  //   fetchReadUnread();
  // }, [status]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (isLoadingWa === false) {
  //       fetchReadUnread();
  //     }
  //   }, 3000);
  //   // Clear the interval
  //   return () => clearInterval(interval);
  // });

  useEffect(() => {
    const storedData = localStorage.getItem("no-telpon");
    const data = JSON.parse(storedData);

    const existingRoom = getRoom?.find((room) => room?.id === data);
    if (!existingRoom) {
      // await fetchWebsocket();
      console.log("ada data rooms");
      createRoomSocketJs(data)
    } else {
      console.log("create rooms");
      if (isLoadingWs === false) {
         fetchWebsocket();
      }
    }
  }, [triggerReConnect]);

  useEffect(() => {
    if (messageSocket?.data) {
      const newDataWebsocket = {
        message: messageSocket.data,
      };

      setGlobalMessages((prev) => prev.filter((chat) => !chat.not_sent));
      setGlobalMessages((prev) => {
        const NoTelp = localStorage.getItem("no-telpon");
        const dataTelp = JSON.parse(NoTelp);
        if (messageSocket.roomId === dataTelp) {
          return [messageSocket.data, ...prev];
        } else {
          return prev;
        }
      });
    }
  }, [messageSocket, noTelpon]);

  const onClick = (id) => {
    // console.log("click is triggered", id);
  };

  const onLongPress = (event, id) => {
    // if (event.target.title === 'server') {
    //   setActiveChat(event.target.id)
    // }
    // if (event.target.title === 'admin') {
    setActiveChat(!activeChat);
    // }
  };

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 500,
  };

  const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);

  const deleteMessage = (id) => {
    setActiveChat(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        refAttachmenu.current &&
        !refAttachmenu.current.contains(event.target)
      ) {
        setShowAttachmentMenu(false);
      }
      if (
        chatOptionRef.current &&
        !chatOptionRef.current.contains(event.target)
      ) {
        setShowChatOption(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [refAttachmenu, chatOptionRef]);

  const scrollToEnd = () => {
    if (scrollRef && scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  useEffect(() => {
    scrollToEnd();
  }, [scrollRef.current]);

  useEffect(() => {
    scrollToEnd();
  }, [scrollRef]);

  const handleAudio = (file) => {
    setAudio(file);
    setOpen(true);
  };

  const handleVideo = (file) => {
    setVideo(file);
    setOpen(true);
  };

  const handleImage = (file) => {
    setImage(file);
    setOpen(true);
  };

  function convertTextToLink(text) {
    const urlPattern =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    return text.replace(
      urlPattern,
      '<a href="$1" target="_blank" style="color: #4C89FF; text-decoration: underline;">$1</a>'
    );
  }

  const UserChat = (props) => {
    const { chat } = props;
    return (
      <div>
        <div className="flex items-center mb-3 mt-5">
          <div>
            <div
              className={`flex-1 bg-white p-2 rounded-lg mb-2 p-4 pr-3 relative max-w-lg text-gray-800`}>
              <div className="flex justify-between">
                <div className="absolute left-0 top-1/2 transform -translate-x-1/2 rotate-45 w-2 h-2 bg-white"></div>

                {[".png", ".jpg", ".jpeg", ".jfif", ".bmp", ".gif"].includes(
                  chat?.media?.mime
                ) ? (
                  <div className="w-full h-fit max-h-[200px] ">
                    <img
                      onClick={() => handleImage(chat?.media?.file_name)}
                      className="w-full h-full object-cover max-h-[200px] cursor-pointer"
                      src={chat?.media?.file_name}
                    />
                  </div>
                ) : (
                  <div className="hidden"></div>
                )}

                {chat?.media.file_name != "" ? (
                  <div className="w-[200px] h-fit max-h-[200px]">
                    {[".pdf"].includes(chat?.media?.mime) ? (
                      <a
                        href={chat?.media?.file_name}
                        target="_blank"
                        className="cursor">
                        <div className="flex">
                          <img
                            src={`${process.env.REACT_APP_URL_FOTO_STORAGE}/pdf (1).png`}
                          />
                        </div>
                      </a>
                    ) : (
                      <div className="hidden"></div>
                    )}

                    {[".xlsx", ".xls"].includes(chat?.media?.mime) ? (
                      <a href={chat?.media?.file_name} target="_blank">
                        <div className="flex">
                          <img
                            src={`${process.env.REACT_APP_URL_FOTO_STORAGE}/xls (1) 1.png`}
                          />
                        </div>
                      </a>
                    ) : (
                      <div className="hidden"></div>
                    )}

                    {[".mp2", ".oga"].includes(chat?.media?.mime) ? (
                      <a
                        className="cursor-pointer"
                        onClick={() => handleAudio(chat?.media?.file_name)}>
                        <div className="flex">
                          <img
                            src={`${process.env.REACT_APP_URL_FOTO_STORAGE}/record 1.png`}
                          />
                        </div>
                      </a>
                    ) : (
                      <div className="hidden"></div>
                    )}

                    {[".doc", ".docx"].includes(chat?.media?.mime) ? (
                      <a href={chat?.media?.file_name} target="_blank">
                        <div className="flex">
                          <img
                            src={`${process.env.REACT_APP_URL_FOTO_STORAGE}/word 1.png`}
                          />
                        </div>
                      </a>
                    ) : (
                      <div className="hidden"></div>
                    )}

                    {[".ppt", ".pptx"].includes(chat?.media?.mime) ? (
                      <a href={chat?.media?.file_name} target="_blank">
                        <div className="flex">
                          <img
                            src={`${process.env.REACT_APP_URL_FOTO_STORAGE}/ppt 1.png`}
                          />
                        </div>
                      </a>
                    ) : (
                      <div className="hidden"></div>
                    )}

                    {[".m4v", ".mp4", ".avi"].includes(chat?.media?.mime) ? (
                      <a onClick={() => handleVideo(chat?.media?.file_name)}>
                        <div className="flex w-3/4 h-3/4">
                          <img
                            src={`${process.env.REACT_APP_URL_FOTO_STORAGE}/multimedia.png`}
                          />
                        </div>
                      </a>
                    ) : (
                      <div className="hidden"></div>
                    )}
                  </div>
                ) : (
                  <div className="hidden"></div>
                )}

                {chat?.body !== "" && chat?.body !== null ? (
                  <Sanitized
                    className="break-all"
                    html={convertTextToLink(chat?.body)}
                    style={{ whiteSpace: "pre-wrap" }}
                    options={{
                      allowedAttributes: {
                        a: ["href", "style"],
                      },
                    }}
                  />
                ) : (
                  <div className="hidden"></div>
                )}
              </div>
            </div>
            <div className="text-sm m-2">
              <DoneAllIcon className="text-gray-500" />
              <span className="text-sm ml-2">
                <b>{formateHHSS(chat?.date)}</b>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const ServerChat = (props) => {
    const { chat, index } = props;
    return (
      <div
        className="flex flex-col flex-row-reverse justify-end"
        // id={`${chat.id}`}
        // {...longPressEvent}
        // key={`${chat.id}-${index}`}
      >
        <div className="flex flex-row-reverse items-center">
          <div>
            <div className="flex items-center flex-row-reverse mt-5">
              <div>
                <div
                  className={`flex-1 pl-3  bg-custom-100 text-white p-3 rounded-lg mb-2 relative max-w-lg`}>
                  {[".png", ".jpg", ".jpeg", ".jfif", ".bmp", ".gif"].includes(
                    chat?.media?.mime
                  ) ? (
                    <div className="w-full w-1/2 my-1 h-fit object-cover max-h-[200px] ">
                      <img
                        onClick={() => handleImage(chat?.media?.file_name)}
                        className="w-full h-full max-h-[200px] object-cover cursor-pointer"
                        src={chat?.media?.file_name}
                      />
                    </div>
                  ) : (
                    <div className="hidden"></div>
                  )}

                  {chat?.media.file_name != "" ? (
                    <div className="w-[200px] h-fit max-h-[200px] my-1">
                      {[".pdf"].includes(chat?.media?.mime) ? (
                        <a href={chat?.media?.file_name} target="_blank">
                          <div className="flex">
                            <img
                              src={`${process.env.REACT_APP_URL_FOTO_STORAGE}/pdf (1).png`}
                            />
                          </div>
                        </a>
                      ) : (
                        <div className="hidden"></div>
                      )}

                      {[".xlsx", ".xls", ".csv"].includes(chat?.media?.mime) ? (
                        <a href={chat?.media?.file_name} target="_blank">
                          <div className="flex my-1">
                            <img
                              src={`${process.env.REACT_APP_URL_FOTO_STORAGE}/xls (1) 1.png`}
                            />
                          </div>
                        </a>
                      ) : (
                        <div className="hidden"></div>
                      )}

                      {[".mp2", ".oga", ".wav"].includes(chat?.media?.mime) ? (
                        <a onClick={() => handleAudio(chat?.media?.file_name)}>
                          <div className="flex my-1">
                            <img
                              src={`${process.env.REACT_APP_URL_FOTO_STORAGE}/record 1.png`}
                            />
                          </div>
                        </a>
                      ) : (
                        <div className="hidden"></div>
                      )}

                      {[".doc", ".docx"].includes(chat?.media?.mime) ? (
                        <a href={chat?.media?.file_name} target="_blank">
                          <div className="flex my-1">
                            <img
                              src={`${process.env.REACT_APP_URL_FOTO_STORAGE}/word 1.png`}
                            />
                          </div>
                        </a>
                      ) : (
                        <div className="hidden"></div>
                      )}

                      {[".ppt", ".pptx"].includes(chat?.media?.mime) ? (
                        <a onClick={() => handleVideo(chat?.media?.file_name)}>
                          <div className="flex w-3/4 h-3/4 my-1">
                            <img
                              src={`${process.env.REACT_APP_URL_FOTO_STORAGE}/ppt 1.png`}
                            />
                          </div>
                        </a>
                      ) : (
                        <div className="hidden"></div>
                      )}

                      {[".m4v", ".mp4", ".avi"].includes(chat?.media?.mime) ? (
                        <a onClick={() => handleVideo(chat?.media?.file_name)}>
                          <div className="flex w-3/4 h-3/4 my-1">
                            <img
                              src={`${process.env.REACT_APP_URL_FOTO_STORAGE}/multimedia.png`}
                            />
                          </div>
                        </a>
                      ) : (
                        <div className="hidden"></div>
                      )}
                    </div>
                  ) : (
                    <div className="hidden"></div>
                  )}

                  {chat?.body !== "" && chat?.body !== null ? (
                    <Sanitized
                      html={convertTextToLink(chat?.body)}
                      style={{ whiteSpace: "pre-wrap" }}
                      className="break-all"
                      options={{
                        allowedAttributes: {
                          a: ["href", "style"],
                        },
                      }}
                    />
                  ) : (
                    // <div dangerouslySetInnerHTML={{ __html: cleanHtml }}/>

                    <div className="hidden"></div>
                  )}

                  <div className="absolute right-0 top-1/2 transform translate-x-1/2 rotate-45 w-2 h-2 bg-custom-100"></div>
                </div>
              </div>
            </div>
            <div className="text-sm flex justify-end mr-2">
              <span className="text-sm">
                <b>{formateHHSS(chat?.date)}</b>
              </span>
              {chat?.not_sent === true ? (
                <CheckIcon className="ml-2 text-gray-500" />
              ) : chat?.not_sent === undefined ? (
                <DoneAllIcon className="text-gray-500" />
              ) : null}
            </div>
          </div>
          {/* <div className="mr-2 cursor-pointer ">
            <MoreHorizIcon
              fontSize="small"
              onClick={(e) => {
                e.stopPropagation();
                setShowChatOption(`${chat.id}-${index}`);
              }}
            />
            {showChatOption === `${chat.id}-${index}` && (
              <div
                ref={chatOptionRef}
                className={`${
                  chat.from === "server" ? "right-2" : "left-2"
                } absolute bottom-0 w-16 text-xs text-center bg-white py-2 rounded-lg shadow-lg transform -translate-y-[108%]`}>
                <ul>
                  <li
                    className="px-2 py-1"
                    onClick={() => setActiveChat(`${chat.id}-${index}`)}>
                    Delete
                  </li>
                </ul>
              </div>
            )}
          </div> */}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="w-full ">
        <div className="flex-1  px-4 h-[60vh] pt-5 overflow-y-auto flex-col-reverse flex">
          {globalMessages &&
            globalMessages.map((chat, index) => (
              <div
                key={index}
                className=""
                id={"awdawdawd"}
                // {...longPressEvent}
              >
                {formatDateDDMMYY(chat?.date) !==
                  formatDateDDMMYY(globalMessages[index + 1]?.date) && (
                  <div className="flex justify-center text-sm">
                    {formatDateDDMMYY(chat?.date)}
                  </div>
                )}
                {chat.from === "server" ? (
                  <>
                    <ServerChat chat={chat} index={index} />
                  </>
                ) : (
                  <>
                    <UserChat chat={chat} />
                  </>
                )}
              </div>
            ))}
          {open ? (
            <>
              <ModalDetail
                audio={audio}
                setAudio={setAudio}
                video={video}
                setVideo={setVideo}
                image={image}
                setImage={setImage}
                open={open}
                setOpen={setOpen}
                // onCloseRequest={() => setOpenLightbox(!openLightBox)}
              />
            </>
          ) : null}

          <ModalConfirm
            show={activeChat}
            onClose={() => setActiveChat(false)}
            title="Hapus Pesan?"
            text=""
            icon={<DeleteIcon />}
            cancelButtonText="Batal"
            confirmButtonText="Hapus"
            action={deleteMessage}
          />
        </div>
      </div>
    </>
  );
}

export default ChatRoom;