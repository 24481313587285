import React, { Suspense, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Fade, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";


const styleRegisterCarton = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: 600,
  overflow: "auto",
  bgcolor: "background.paper",
  border: "1px solid lightgray",
  boxShadow: 24,
  borderRadius: "5px",
  p: 4,
};

export default function ModalRegisterCarton({
  id_so,
  openRegisterCarton,
  closeRegisterCarton,
  setInputCarton,
  enterCarton,
  inputCarton,
  scannedCartons,
  deleteCarton,
}) {
  return (
    <Modal
      open={openRegisterCarton}
      onClose={closeRegisterCarton}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Fade in={openRegisterCarton}>
        <Box sx={styleRegisterCarton}>
          <div className="flex justify-between items-center font-bold text-2xl mb-2">
            Register Cartons
            <IconButton
              onClick={closeRegisterCarton}
              style={{ textAlign: "right" }}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className="flex justify-between items-end gap-3 mb-3">
            <div>
              Order Number: <strong>{id_so}</strong>
            </div>
            {/* <div className="ml-auto text-right">
            <div className="text-xs">
              Main Carton Scanned:
            </div>
            <strong>1122334455</strong>
          </div> */}
          </div>
          <div className="font">
            <input
              type="text"
              className="border-b-2 border-blue-600 focus:outline-none"
              onKeyDown={enterCarton}
              value={inputCarton}
              onChange={(e) => setInputCarton(e.target.value)}
              placeholder="input carton here"
              autoFocus
            />
          </div>

          <div className="table-scanned-cartons mt-3">
            <div className="flex justify-between items-center bg-blue-300 p-2 text-gray-600">
              <div className="font-bold">No.</div>
              <div className="font-bold ml-auto">Codes</div>
              <div className="w-5 flex-shrink-0"></div>
            </div>
            {scannedCartons?.map((carton, idx) => (
              <div
                key={carton}
                className={`${
                  idx % 2 === 0 ? "bg-blue-100" : "bg-blue-200"
                } flex justify-between items-center p-2 text-gray-600`}>
                <div className="font-bold">{idx + 1}</div>
                <div className="font-bold ml-auto">{carton}</div>
                <div
                  className="w-5 flex-shrink-0 text-center text-lg text-gray-400 hover:text-gray-600 cursor-pointer"
                  onClick={() => deleteCarton(carton)}
                  style={{ lineHeight: ".5" }}>
                  x
                </div>
              </div>
            ))}
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
