import { useEffect, useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  CircularProgress,
  IconButton,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  Paper,
  TableRow,
  Typography,
  Modal,
  Fade,
  Box,
  Backdrop,
} from "@mui/material";

const styleAddAdjustments = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  height: 'auto',
  maxHeight: "85vh",
  overFlowY: "auto",
  bgcolor: '#f8f8f8',
  border: '1px solid lightgray',
  boxShadow: 24,
  borderRadius: '5px',
  p: 4,
};

const AddAdjustmentsModal = ({ adjustment, setAdjustment, showAddAdjustments, setShowAddAdjustments, dataChanged, setDataChanged }) => {

const handleChange = (e) => {
  let { name, value } = e.target;
  const newValue = value.replaceAll('.','')
  setDataChanged(true)
  setAdjustment((prev) => {
    return { ...prev, [name]: (~~newValue).toLocaleString("id-ID") };
  });
};


  return (
    <Modal
    open={showAddAdjustments}
    onClose={() => setShowAddAdjustments(false)}
    aria-labelledby='modal-modal-title'
    aria-describedby='modal-modal-description'>
    <Box sx={styleAddAdjustments} className='overflow-y-scroll variant-scroll'>
        <div className='flex justify-end -mt-5'>
        <IconButton onClick={() => setShowAddAdjustments(false)} style={{ textAlign: 'right' }}>
            <CloseIcon />
        </IconButton>
        </div>
        <Typography id='modal-modal-title' variant='h4' component='h4'>
        Add Adjustments
        </Typography>
        <div>
        <div className='flex items-center space-x-4'>
            <label className='w-1/3 capitalize'>biaya asuransi</label>
            <div className='flex flex-col  w-2/3'>
            <input
                value={adjustment.asuransi}
                onChange={handleChange}
                name='asuransi'
                type='text'
                className='w-full p-1 border border-gray-400 rounded-md focus:outline-blue'
            />
            <div className='text-xs text-red-600 pl-2'>
                *Kosongkan apabila tidak menggunakan
            </div>
            </div>
        </div>
        <div className='flex items-center space-x-4'>
            <label className='w-1/3 capitalize'>biaya inspeksi</label>
            <div className='flex flex-col  w-2/3'>
            <input
                value={adjustment.inspeksi}
                onChange={handleChange}
                name='inspeksi'
                type='text'
                className='w-full p-1 border border-gray-400 rounded-md focus:outline-blue'
            />
            <div className='text-xs text-red-600 pl-2'>
                *Kosongkan apabila tidak menggunakan
            </div>
            </div>
        </div>
        <div className='flex items-center space-x-4'>
            <label className='w-1/3 capitalize'>biaya packing kayu</label>
            <div className='flex flex-col  w-2/3'>
            <input
                value={adjustment.packingKayu}
                onChange={handleChange}
                name='packingKayu'
                type='text'
                className='w-full p-1 border border-gray-400 rounded-md focus:outline-blue'
            />
            <div className='text-xs text-red-600 pl-2'>
                *Kosongkan apabila tidak menggunakan
            </div>
            </div>
        </div>
        <div className='flex items-center space-x-4'>
            <label className='w-1/3 capitalize'>Ongkir International</label>
            <div className='flex flex-col  w-2/3'>
            <input
                value={adjustment.ongkir_international}
                onChange={handleChange}
                name='ongkir_international'
                type='text'
                className='w-full p-1 border border-gray-400 rounded-md focus:outline-blue'
            />
            <div className='text-xs text-red-600 pl-2'>
                *Kosongkan apabila tidak menggunakan
            </div>
            </div>
        </div>
        

        <div className='text-center mt-5'>
            <hr />
            <button
            className='bg-blue-600 hover:bg-blue-500 text-white p-1 rounded-md px-5 mt-5'
            onClick={() => setShowAddAdjustments(false)}
            >
            OKE
            </button>
        </div>
        </div>
    </Box>
    </Modal>
  );
};

export default AddAdjustmentsModal;
