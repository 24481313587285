import React, { useEffect, useState } from 'react';
import { Box, Collapse, IconButton, Tooltip, Backdrop, Modal, Fade, Typography } from '@mui/material';
// import Backdrop from '@mui/material/Backdrop';
// import Modal from '@mui/material/Modal';
// import Fade from '@mui/material/Fade';
// import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { getUser } from 'helpers/parseJWT';
import { filterDatWarehouseSendIDNById, getListCourier, updateDataWarehouseSendIDN } from 'service/api';
import swal from 'sweetalert';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 450,
  maxHeight: 600,
  bgcolor: 'background.paper',
  border: '2px solid lightgray',
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};


const TooltipComp = ({ value }) => {
  return <div className="text-white text-xs px-2 py-2">{value}</div>;
};

const GudangTabel = React.memo(({ dataToMap, listDriver, handleChangeDate, name }) => {
  const [selectedPalet, setSelectedPalet] = useState(false)
  const [paletDetails, setPaletDetails] = useState(null)
  const [loadingPaletDetails, setLoadingPaletDetails] = useState(false)
  const [allJadwal, setAllJadwal] = useState('')
  const [allDriver, setAllDriver] = useState('')
  const [modalPilihPengiriman, setModalPilihPengiriman] = useState(false)
  const [courier, setCourier] = useState([]);
  const [loadingCourier, setLoadingCourier] = useState(false);
  const [activeIndexInPalet, setActiveIndexInPalet] = useState(false);
  const [applyToAllIdSo, setApplyToAllIdSo] = useState(false);
  
  useEffect(() => {
    if(selectedPalet) {
      getPaletDetails()
    } else {
      setPaletDetails(null)
    }
  }, [selectedPalet])

  const set_SelectedPalet = (paletId) => {
    if(selectedPalet !== paletId) {
      setSelectedPalet(paletId)
    } else {
      setSelectedPalet(false)
    }
    setAllJadwal('')
    setAllDriver('')
  }
  
  const getPaletDetails = async () => {
    setLoadingPaletDetails(true)
    const response = await filterDatWarehouseSendIDNById(selectedPalet)
    if(response.status === 200) {
      if(!response.data) {
        setPaletDetails([])
      } else {
        setPaletDetails(response.data)
      }
    }
    setLoadingPaletDetails(false)
  }
  
  const handle_ChangeDate = (e, paletIndex, index) => {
    const { value, name } = e.target;

    const tempPaletDetails = [...paletDetails]
    if (name === "single") {
      tempPaletDetails[index].jadwal = value
      tempPaletDetails[index].is_updated = value?.length !== 0 ? true : false;

      setPaletDetails(tempPaletDetails)

      const countUpdatedPalet = tempPaletDetails.filter((i) => i.is_updated === true)
      if(countUpdatedPalet.length !== 0) {
        swal(
          `${countUpdatedPalet.length} data telah di update. Silahkan ubah dengan menekan tombol Update Perubahan Data.`
        );
      }
    }

    if (name === "all") {
      setAllJadwal(value)
      tempPaletDetails.forEach((data) => {
        data.jadwal = value;
        data.is_updated = value?.length !== 0 ? true : false;
      });

      setPaletDetails(tempPaletDetails)
      
      const countUpdatedPalet = tempPaletDetails.filter((i) => i.is_updated === true)     
      if(countUpdatedPalet.length !== 0) {
        swal(
          `${countUpdatedPalet.length} data telah di update. Silahkan ubah dengan menekan tombol Update Perubahan Data.`
        );
      }
    }
  }
  
  const handle_ChangeDriver = (e, index) => {
    const { value, name } = e.target;

    const tempPaletDetails = [...paletDetails]
    if (name === "driver-single") {
      tempPaletDetails[index].driver = value
      tempPaletDetails[index].is_updated = value?.length !== 0 ? true : false;

      setPaletDetails(tempPaletDetails)

      const countUpdatedPalet = tempPaletDetails.filter((i) => i.is_updated === true)
      if(countUpdatedPalet.length !== 0) {
        swal(
          `${countUpdatedPalet.length} data telah di update. Silahkan ubah dengan menekan tombol Update Perubahan Data.`
        );
      }
    }

    if (name === "driver-all") {
      setAllDriver(value)
      tempPaletDetails.forEach((data) => {
        if((data.kurir === 'jabodetabek-custom - Ocistok Gratis Ongkir' && !data.edit_kurir) || 
            (data.kurir === 'jabodetabek-custom - Ocistok Gratis Ongkir' && data.edit_kurir && data.edit_kurir.service === 'Ocistok Gratis Ongkir')) {
          data.driver = value;
          data.is_updated = value?.length !== 0 ? true : false;
        }
      });

      setPaletDetails(tempPaletDetails)
      
      const countUpdatedPalet = tempPaletDetails.filter((i) => i.is_updated === true)     
      if(countUpdatedPalet.length !== 0) {
        swal(
          `${countUpdatedPalet.length} data telah di update. Silahkan ubah dengan menekan tombol Update Perubahan Data.`
        );
      } 
    }
  }

  const set_ModalPilihPengiriman = async (container, indexInPalet) => {
    setLoadingCourier(true);
    setModalPilihPengiriman(true)
    setActiveIndexInPalet(indexInPalet)
    
    const id_kecamatan = container?.id_kecamatan
    const kurir = "all"
    const id_group = container?.id_group
    const total_weight = container?.total_berat
    const response = await getListCourier(id_kecamatan, kurir, total_weight, id_group);
    const arr = [];
    arr.push({
      id: "jabodetabek-custom - Ocistok Gratis Ongkir",
      code: "jabodetabek-custom",
      service: "Ocistok Gratis Ongkir",
      min_weight: "",
      description: "",
      is_selected: container.kurir === "jabodetabek-custom - Ocistok Gratis Ongkir" ? true : false,
      price: 0,
    });
    if (response.status === 200) {
      response?.data?.courier?.map((courier, index) => {
        if (courier.costs.length > 0) {
          courier.costs.map((service, i) => {
            arr.push({
              id: courier.code + i,
              code: courier.code,
              service: service.service,
              min_weight: service.min_weight,
              description: service.description,
              is_selected: container.kurir === `${courier.code} - ${service.service}` ? true : false,
              price: service.cost[0].value,
            });
          });
        } else {
          arr.push({
            id: courier.code + index,
            code: courier.code,
            service: '',
            min_weight: '',
            description: '',
            is_selected: false,
            price: '-',
          });
        }
      });
      setCourier(arr);
    }
    setLoadingCourier(false);
  }

  const handleClickCourier = (courier) => {
    const edit_kurir = {
      service: courier.service,
      kurir: courier.code,
      ongkir: courier.price
    }

    const tempPaletDetails = [...paletDetails]
    
    if(applyToAllIdSo) {
      tempPaletDetails.forEach((palet, index) => {
        if(palet.id_so === tempPaletDetails[activeIndexInPalet].id_so) {
          palet.edit_kurir = edit_kurir
          palet.is_updated = true
          palet.driver = ''
        }
      })
    } else {
      tempPaletDetails[activeIndexInPalet].edit_kurir = edit_kurir
      tempPaletDetails[activeIndexInPalet].is_updated = true
      tempPaletDetails[activeIndexInPalet].driver = ''
    }
    closeModalPilihPengiriman()
    
      
    const countUpdatedPalet = tempPaletDetails.filter((i) => i.is_updated === true)     
    if(countUpdatedPalet.length !== 0) {
      swal(
        `${countUpdatedPalet.length} data telah di update. Silahkan ubah dengan menekan tombol Update Perubahan Data.`
      );
    }
  }

  const handleSubmit = async (data) => {
    let tempSubmitData = data?.filter((i) => i.is_updated === true);
    
    const body = JSON.stringify(tempSubmitData);
    
    if (!tempSubmitData?.length) {
      swal('Peringatan', 'Tidak ada jadwal yang berubah', 'warning');
      return;
    }
    const response = await updateDataWarehouseSendIDN(body);
    if (response?.status === 200) {
      swal(
        'Berhasil',
        `${tempSubmitData?.length} Data berhasil di Submit`,
        'success'
      );
      
      const tempPaletDetails = data.map((data) => {
          data.is_updated = false;
          return data
      });

      setPaletDetails(tempPaletDetails)
    } else {
      swal(
        'Berhasil',
        `Data Gagal di Submit, Error : ${response?.message}`,
        'success'
      );
    }
  };

  const openModalPilihPengiriman = () => {
    setModalPilihPengiriman(true)
  }

  const closeModalPilihPengiriman = () => {
    setCourier([])
    setActiveIndexInPalet(false)
    setModalPilihPengiriman(false)
    setApplyToAllIdSo(false)
  }
  
  return (
    <>
      {dataToMap?.map((items, index) => (
        <div key={index}>
          <div
            className={`flex justify-center items-center py-2 px-2 relative ${
              index % 2 ? 'bg-gray-50' : 'bg-gray-100'
            }`}>
            <div className="text-center w-[10%]">
              <div className="text-base font-bold">
                {items}
              </div>
              <div className="text-center">
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => {
                    set_SelectedPalet(items);
                  }}>
                  {selectedPalet === items ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
                <span className="text-xs">Details</span>
              </div>
            </div>
            <div className="text-xs text-center w-[10%]"></div>
            <div className="text-xs text-center w-[10%]"></div>
            <div className="text-xs text-center w-[15%] line-clamp-2" title=''></div>
            <div className="text-xs text-center w-[10%]"></div>
            <div className="text-xs text-center w-[10%]"></div>
            <div className="text-xs text-center w-[10%]"></div>
            <div className="text-xs text-center w-[5%]"></div>
            <div className="w-[10%] px-1">
              {getUser().roles?.includes('admin') && selectedPalet === items &&
                <select 
                  name='driver-all' 
                  value={allDriver} 
                  className="rounded-md text-xs p-1 border border-gray-200 focus:outline-blue cursor-pointer w-full"
                  onChange={(e) => handle_ChangeDriver(e)}
                  disabled={loadingPaletDetails}
                  title={allDriver}
                >
                  <option value="">Select Driver</option>
                  {listDriver?.map((driver, index) => (
                    <option key={index} value={driver}>{driver}</option>
                  ))}
                </select>
              }
            </div>
            <div className="w-[10%]">
              {getUser().roles?.includes('admin') && selectedPalet === items &&
                <input
                  type="text"
                  name='all'
                  value={allJadwal}
                  min={new Date().toLocaleDateString('en-ca')}
                  onChange={(e) => handle_ChangeDate(e, index)}
                  className="rounded-md text-xs text-center py-[5px] px-1 border border-gray-200 focus:outline-blue cursor-pointer w-full hover:outline-blue"
                  onFocus={(e) => (e.target.type = 'date')}
                  onBlur={(e) => (e.target.type = 'text')}
                />
              }
            </div>
          </div>
          <Collapse in={paletDetails && selectedPalet === items} timeout="auto" unmountOnExit>
            <Box sx={{}}>
              {loadingPaletDetails ? 
                <div className='text-center p-5'>Loading data...</div>
                : paletDetails?.length === 0 ?
                  <div className='text-center p-5'>No Data</div>
                  :
                  <>
                  {paletDetails?.map((container, idx) => (
                    <div
                      key={idx}
                      className={`flex justify-center items-center py-2 px-2 relative ${
                        idx % 2 ? 'bg-blue-50' : 'bg-blue-100'
                      }`}>
                      <div className="text-xs text-center px-1 w-[10%]"></div>
                      <div className="text-xs text-center px-1 w-[10%]">{container?.id_karton}</div>
                      <div className="text-xs text-center px-1 w-[10%]">{container?.wilayah}</div>
                      <Tooltip
                        className="p-0 m-0 cursor-help"
                        title={<TooltipComp value={container?.alamat} />}>
                        <div className="text-xs text-center px-1 line-clamp-2 w-[15%]">
                          {container?.alamat}
                        </div>
                      </Tooltip>
                      <Tooltip
                        className="p-0 m-0 cursor-help"
                        title={<TooltipComp value={container?.kurir} />}>
                          <div className="text-xs text-center px-1 line-clamp-2 w-[10%]">
                              <div className="flex justify-center items-center gap-2">
                                {container.edit_kurir ? 
                                  container.edit_kurir.kurir + "-" + container.edit_kurir.service
                                  :
                                  container?.kurir === 'jabodetabek-custom - Ocistok Gratis Ongkir' ? 'Ocistok' : container?.kurir
                                }
                                {container?.free_ongkir &&
                                  <div className="edit-btn bg-gray-400 hover:bg-gray-500 text-white px-2 rounded"
                                    onClick={() => set_ModalPilihPengiriman(container, idx)}
                                  >Edit</div>
                                }
                              </div>
                            
                          </div>
                      </Tooltip>
                      <div className="text-xs text-center px-1 w-[10%]">{container?.customer}</div>
                      <div className="text-xs text-center px-1 w-[10%]">{container?.container}</div>
                      <div className="text-xs text-center px-1 w-[5%]">{container?.id_palet}</div>
                      {/* <div className="text-xs text-center px-1 w-[10%]">{container?.driver}</div> */}
                      {getUser().roles?.includes('admin') &&
                        <>
                          <div className='w-[10%] px-1'>
                            <select 
                              name='driver-single' 
                              value={container?.driver} 
                              className="rounded-md text-xs p-1 border border-gray-200 focus:outline-blue cursor-pointer w-full"
                              onChange={(e) => handle_ChangeDriver(e, idx)}
                              title={container?.driver} 
                              disabled={(container?.kurir !== 'jabodetabek-custom - Ocistok Gratis Ongkir') || (container?.edit_kurir && container?.edit_kurir.service !== 'Ocistok Gratis Ongkir')}
                            >
                              <option value="" disabled>Select Driver</option>
                              {container?.kurir !== 'jabodetabek-custom - Ocistok Gratis Ongkir' && container?.driver !== '' &&
                                <option value={container?.driver}>{container?.driver}</option>
                              }
                              {listDriver?.map((driver, index) => (
                                <option key={index} value={driver}>{driver}</option>
                              ))}
                            </select>
                          </div>
                          <input
                            type="text"
                            name='single'
                            value={container?.jadwal}
                            min={new Date().toLocaleDateString('en-ca')}
                            onChange={(e) => handle_ChangeDate(e, index, idx)}
                            className="rounded-md text-xs text-center py-[5px] px-1 border border-gray-200 focus:outline-blue cursor-pointer w-[10%] hover:outline-blue"
                            onFocus={(e) => (e.target.type = 'date')}
                            onBlur={(e) => (e.target.type = 'text')}
                          />
                        </>
                      }
                    </div>
                  ))}
                  {getUser().roles?.includes('admin') &&
                    paletDetails?.filter((item) => item.is_updated === true).length !== 0 &&
                    <div className="flex justify-end my-3 px-2">
                      <button
                        onClick={() => handleSubmit(paletDetails)}
                        className="text-white bg-blue-400 text-sm px-5 py-2 rounded-md hover:bg-blue-500">
                        Update Perubahan Data
                      </button>
                    </div>
                  }
                  </>
              }
            </Box>
          </Collapse>
        </div>
      ))}
      
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={modalPilihPengiriman}
        onClose={closeModalPilihPengiriman}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalPilihPengiriman}>
          <Box sx={style} className='overflow-y-scroll modal-scroll'>
            <div className='flex justify-end -mt-5'>
              <IconButton onClick={closeModalPilihPengiriman} style={{ textAlign: 'right' }}>
                <CloseIcon />
              </IconButton>
            </div>
            <Typography id='transition-modal-title' variant='h6' component='h2'>
              Pilih Pengiriman Lokal
            </Typography>

            {loadingCourier ? (
              <div className='flex p-2 justify-center'>
                Loading Courier...
              </div>
            )
            :
            courier?.length > 0 ? (
              <>
              <div className="flex items-center gap-3 mt-2">
                <input type="checkbox" 
                  name="applyToAllIdSo"
                  id="applyToAllIdSo"
                  checked={applyToAllIdSo}
                  onChange={(e) => setApplyToAllIdSo(e.target.checked)}
                />
                <label htmlFor="applyToAllIdSo">Terapkan pada semua ID Karton dengan ID Order yang sama</label>
              </div>
              {courier?.map((courier, i) => {
                return (
                  <div
                    key={i}
                    onClick={() => {
                      handleClickCourier(courier);
                      // closeModalPilihPengiriman();
                    }}
                    className='w-full border-b border-gray-300 py-2 px-3 duration-200 cursor-pointer hover:bg-orange-200 hover:scale-110'
                  >
                    <div className='flex justify-between items-center'>
                      <div>
                        <div className='flex items-center space-x-2'>
                          <p className='font-semibold text-md line-clamp-1 capitalize'>
                            {courier.code} - {courier.service}
                          </p>
                          <p className='text-sm font-semibold text-marron-500'>
                            Rp {courier.price.toLocaleString('ID-id')}
                            {!courier.description ? null : <span className='text-sm text-marron-500'> ({courier.description})</span> }
                          </p>
                        </div>
                        <p className='text-sm'>
                          Harga diatas merupakan harga estimasi
                        </p>
                      </div>
                      {courier.is_selected && (
                        <CheckIcon className='text-orange-500' />
                      )}
                    </div>
                  </div>
                );
              })}
              </>
            ) : (
              <div className='flex flex-col items-center justify-center h-32'>
                <p className='font-bold text-marron-500'>Oops ..</p>
                <p className='text-center text-marron-500'>
                  Metode Pengiriman tidak ditemukan, <br />
                  silahkan lengkapi alamat pengiriman
                </p>
              </div>
            )}
          </Box>
        </Fade>
      </Modal>
    </>
  );
});

export default GudangTabel;
