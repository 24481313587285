import { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Button, CircularProgress, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { sendImageOCS } from "service/api";
import swal from "sweetalert";
import Cookies from "js-cookie";

const ModalOCSDoc = (props) => {
  const { openDoc, setOpenDoc, isLoading, setIsLoading, renderLoading, getDetailMessage, setGlobalMessages } = props;
  const [document, setDocument] = useState(null);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [loading, setLoading] = useState(false);
  const [telpon, setTelpon] = useState("");
  const [errorMessage, setErrorMessage] = useState({
    msg: "Ukuran gambar terlalu besar (maksimal 2MB).",
    error: true,
  });
  const fileInputRef = useRef(null);

  // const llsendMessageImage = async (e) => {
  //   setIsLoading(true);
  //   e.preventDefault();
  //   const getTelpon = localStorage.getItem("no-telpon");
  //   const telpon = JSON.parse(getTelpon);
  //   setTelpon(telpon);

  //   let formData = new FormData();
  //   formData.append("file", image);
  //   formData.append("phone", telpon);
  //   const respon = await fetch(
  //     'http://192.168.15.16:7777/send-message-image',
  //     {
  //       method: 'POST',
  //       body: formData,
  //       headers: {
  //         "Content-type": "multipart/form-data",
  //         "Key": "omsjaya",

  //       },
  //     }
  //   )
  //     if (respon.status === 200) {
  //       swal('Success', 'Success Upload Files', 'Success');
  //     } else {
  //       swal('warning', 'Failed Upload Files', 'warning');
  //     }
  //     setIsLoading(false);
  // }

  const sendMessageDoc = async (e) => {
    e.preventDefault();
    const getTelpon = localStorage.getItem("no-telpon");
    const telpon = JSON.parse(getTelpon);
    var myHeaders = new Headers();
    const Xid = Cookies.get('Session_id');
    const tokenCookies = Cookies.get('oms_token');
    myHeaders.append("Authorization",`Bearer ${tokenCookies}`);
    const randomId = (Math.floor(Math.random() * (999999 - 100000 + 1) + 100000)).toString()
    setGlobalMessages((prev) => [{
      id: randomId,
      phone: telpon,
      from: "server",
      to: telpon,
      user: "",
      body: "",
      // not_sent: true,
      is_read: false,
      date: new Date().toISOString(),
      media: {
          id: "",
          id_wa: "",
          file_name: selectedDoc,
          mime: "." + document.name.split('.').pop()
      }
    }, ...prev]);

    var formdata = new FormData();
    formdata.append("phone", telpon);
    formdata.append("file", document);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    setLoading(true);
    fetch(
      `${process.env.REACT_APP_URL_API_WA_CHAT}/send-message-doc`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 200) {
          swal("Success Upload Files", "Success");
        }
        setOpenDoc(false);
        setDocument("")
        setErrorMessage({
          error: true,
        });
        setLoading(false);
      })
      .catch((error) => console.log("error", error)).finally(() => {
        setLoading(false);
      });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    maxHeight: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const closeModal = () => {
    setOpenDoc(!openDoc);
    setDocument("");
    setErrorMessage({
      error: true,
    });
  };

  const validasiImage = () => {
    setDocument("");
    swal(errorMessage.msg);
  };

  useEffect(() => {}, [errorMessage]);

  const handleDocUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    if (file && file.size > 15 * 1024 * 1024) {
      swal("Ukuran document terlalu besar (maksimal 15MB).");
      setDocument("");
      setErrorMessage({
        msg: "Ukuran gambar terlalu besar (maksimal 15mb).",
        error: true,
      });
    } else {
      setErrorMessage({
        error: false,
      });
      setDocument(file);
    }

    reader.onload = () => {
      setSelectedDoc(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  return (
    <div>
      <Modal
        open={openDoc}
        onClose={() => setOpenDoc(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description">
        <Box sx={{ ...style }}>
          <div className="w-full flex justify-end">
            <IconButton
              aria-label="close"
              onClick={closeModal}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}>
              <CloseIcon />
            </IconButton>
          </div>

          <form onSubmit={(e) => sendMessageDoc(e)}>
            <div>
              <h3 className="text-lg text-center font-bold">Uploaded Doc:</h3>
            </div>
            {loading ? (
              <div className="w-full h-full my-10 flex items-center justify-center text-white ">
                <CircularProgress size={30} className="text-blue-300" />
              </div>
            ) : (
              <div>
                <div className="w-full flex justify-center mt-5">
                 <p>{document?.name}</p>
                </div>
                
                <div className="flex flex-col items-center my-10">
                  <label
                    htmlFor="doc_input"
                    className="text-lg text-white bg-gray-400 px-10 py-1 rounded-full cursor-pointer">
                    Select Document
                  </label>
                  <input
                    className="hidden text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                    type="file"
                    id="doc_input"
                    accept=".pdf,.doc,.docx,.xls,.xlsx,.txt,.ppt"
                    onChange={handleDocUpload}
                  />
                  <p
                    className="mt-1 text-xs text-gray-500 dark:text-gray-300"
                    id="file_input_help">
                     <b>PDF, DOC, XLS, TXT, PPT (Max. 15MB).</b>
                  </p>
                </div>
              </div>
            )}
            <div className="w-full justify-center flex mt-5">
              <div>
                {errorMessage.error === true || loading ? (
                  <button
                    className="bg-gray-300 cursor-default text-white py-3 px-7 rounded-lg"
                    disabled>
                    Send
                  </button>
                ) : (
                  <button className="bg-green-500 hover:bg-green-400 text-white py-3 px-7 rounded-lg">
                    Send
                  </button>
                )}
              </div>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalOCSDoc;
