import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import swal from "sweetalert";
import { styled } from "@mui/material/styles";
import { Button, IconButton, Tooltip, Collapse } from "@mui/material";
import CameraAlt from "@mui/icons-material/CameraAlt";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ModalChangePallete from "./ModalChangePallet";
import { getUser } from "helpers/parseJWT";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 700,
  maxWidth: "90%",
  maxHeight: "95%",
  overflowY: "auto",
  bgcolor: "background.paper",
  border: "1px solid lightgray",
  boxShadow: 24,
  borderRadius: "5px",
  p: 4,
};
export default function ModalDetailPallet({
  open,
  handleClose,
  palletList,
  changePalleteMass,
  changePallete,
  data,
  details,
  searchText,
  searchType,
  loading,
}) {
  const [openDetailPO, setOpenDetailPO] = useState(false);
  // const [openModalChangePallete, setOpenModalChangePallete] = useState(null);
  const [palletDetails, setPalletDetails] = useState([]);
  const [inputPallete, setInputPallete] = useState([]);
  const [inputPalleteMulti, setInputPalleteMulti] = useState('');
  const [selectedIdCartons, setSelectedIdCartons] = useState([]);
  const [multiSelectChecked, setMultiSelectChecked] = useState(false);
  const [lastSelectedRow, setLastSelectedRow] = useState(null);
  const set_OpenDetailPO = (value) => {
    if (!openDetailPO || openDetailPO !== value) {
      setOpenDetailPO(value);
    } else {
      setOpenDetailPO(false);
    }
  };

  // const handleCloseModalChangePallete = () => setOpenModalChangePallete(null);

  const handleChangeInputPallete = (e, i) => {
    const { name, value } = e.target;
    const values = [...inputPallete];
    values[i][name] = value;
    setInputPallete(values);
  };

	const handleChangeInputPalleteMulti = (e) => {
		setInputPalleteMulti(e.target.value)
    const values = [...inputPallete]
		values?.forEach((item) => {
			if (selectedIdCartons.includes(item.id_carton)) {
				item.id_palet = e.target.value
			}
		})
		setInputPallete(values)
	}

  const handleSubmit = () => {
    const pallete = inputPallete.filter((v) => {
      if (v.id_palet == null || v.id_palet == "") {
        return false;
      } else {
        return true;
      }
    });
		
    changePalleteMass(pallete);
  };

  useEffect(() => {
    setInputPallete(
      details
        ? details.map((v) => ({
            id_carton: v.id_carton,
            id_palet: "",
          }))
        : []
    );
		setPalletDetails(details?.filter((item) => item.customer.toLowerCase().includes(searchText.toLowerCase()) || item.id_so.toString() === searchText))
  }, [details]);

	const handleSelectSingle = (e, i) => {
		const { name, value, checked } = e.target
		
		if (checked) {
			if (e.shiftKey) {
				const selected_id_cartons = palletDetails.filter((e, index) => (lastSelectedRow < index && index <= i) || 
																																				(i <= index && index < lastSelectedRow))
																									.map((item => item.id_carton))
				
					const concatIdCartons = selectedIdCartons.concat(selected_id_cartons)
					setSelectedIdCartons([...new Set(concatIdCartons)])
			} else {
				setSelectedIdCartons(prev => [...prev, value])
			}
		} else {
			setSelectedIdCartons(prev => prev.filter(i => i !== value))
		}
		setLastSelectedRow(i)
	}
	
	const handleMultiSelect = (e) => {
		const { name, value, checked } = e.target
		setMultiSelectChecked(checked)
		if (checked) {
			const selected_id_cartons = palletDetails.map((e) => e.id_carton)
				
				setSelectedIdCartons(selected_id_cartons)
		} else {
			setSelectedIdCartons([])
		}
	}
	
	// if (!palletDetails) {
	// 	return null
	// }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>
        <div className="flex justify-end -mt-5">
          <IconButton onClick={handleClose} style={{ textAlign: "right" }}>
            <CloseIcon />
          </IconButton>
        </div>

        <div className="w-full flex justify-between items-center py-2">
          <div className="font-bold text-lg">ID Palet: {data.id}</div>
          {/* <div className="font-bold">Carton: {data.kuantiti}</div> */}
        </div>

        <div>
          <table className="w-full text-left">
            <thead>
              <tr className="border-b">
                <th>
                  <div className="py-1">
										<input type="checkbox" 
											name={`multi_select`}
											id={`multi_select`}
											checked={multiSelectChecked}
											onChange={handleMultiSelect}
										/>
									</div>
                </th>
                <th>
                  <div className="py-1">ID Order</div>
                </th>
                <th>
                  <div className="py-1">ID Carton</div>
                </th>
                <th>
                  <div className="py-1">Total Qty</div>
                </th>
                <th>
                  <div className="py-1">Nama Customer</div>
                </th>
                <th className="w-28">
									<div className="text-center">
										Pindah Palet
										<div className="mass-update">
											<select
												name="id_palet_multi"
												value={inputPalleteMulti}
												onChange={(e) => handleChangeInputPalleteMulti(e)}
												className="border w-28 rounded px-1"
												id="selectPallete_multi"
												disabled={!getUser().roles?.includes("admin")}>
												<option value=""></option>
												{palletList
													.filter((pallet) => pallet.id !== data.id)
													.map((item, i) => {
														return (
															<option key={i} value={item.id}>
																{item.id}
															</option>
														);
													})}
											</select>
										</div>
									</div>
								</th>
              </tr>
            </thead>
            <tbody>
              {palletDetails?.map((pallet, i) => {
                  return (
                    <tr key={pallet.id_carton}>
                      <td className="py-1">
												<input type="checkbox" 
													name={`select-${pallet.id_carton}`}
													id={`select-${pallet.id_carton}`}
													value={pallet.id_carton}
													checked={selectedIdCartons.includes(pallet.id_carton)}
													onChange={() => console.log('')}
													onClick={e => handleSelectSingle(e, i)}
												/>
											</td>
                      <td className="py-1">{pallet.id_so}</td>
                      <td className="py-1">{pallet.id_carton}</td>
                      <td className="py-1">{pallet.qty}</td>
                      <td className="py-1">{pallet.customer}</td>
                      <td className="py-1">
                        <select
                          name="id_palet"
                          value={inputPallete[i]?.id_palet}
                          onChange={(e) => handleChangeInputPallete(e, i)}
                          className="border w-28 rounded px-1"
                          id="selectPallete"
                          disabled={!getUser().roles?.includes("admin")}>
                          <option value=""></option>
                          {palletList
                            .filter((pallet) => pallet.id !== data.id)
                            .map((item, i) => {
                              return (
                                <option key={i} value={item.id}>
                                  {item.id}
                                </option>
                              );
                            })}
                        </select>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <div className="flex justify-center">
            {loading && (
              <div
                className="my-5 animate-spin inline-block w-8 h-8 border-[3px] border-current border-t-transparent text-blue-600 rounded-full"
                role="status"
                aria-label="loading">
                <span className="sr-only">Loading...</span>
              </div>
            )}
          </div>
          {getUser().roles?.includes("admin") && (
            <div className="text-center mt-4">
              <button
                onClick={handleSubmit}
                className=" text-center bg-blue-600 hover:bg-blue-700 text-white rounded-lg px-6 py-1 cursor-pointer">
                Submit
              </button>
            </div>
          )}
        </div>
        {/* <ModalChangePallete
          pallete={openModalChangePallete}
          handleClose={handleCloseModalChangePallete}
          changePallete={changePallete}
        /> */}
      </Box>
    </Modal>
  );
}
