import {
    atom,
  } from 'recoil';

export const triggerState = atom({
    key: 'textState', // unique ID (with respect to other atoms/selectors)
    default: false, // default value (aka initial value)
  });

  export const getNotifState = atom({
    key: 'getNotifState', // unique ID (with respect to other atoms/selectors)
    default: null, // default value (aka initial value)
  });

  export const getRoomState = atom({
    key: 'getRoomState', // unique ID (with respect to other atoms/selectors)
    default: null, // default value (aka initial value)
  });
  export const setOpenModalState = atom({
    key: 'setOpenModalState', // unique ID (with respect to other atoms/selectors)
    default: false, // default value (aka initial value)
  });

  export const currency_kurs = atom({
    key: 'currency_kurs',
    default: 0,
  });