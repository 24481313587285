import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  CircularProgress,
  IconButton,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  Paper,
  TableRow,
  Typography,
  Modal,
  Fade,
  Box,
  Backdrop,
} from "@mui/material";
import { changeReportSupplier, deleteMyTaskReport, get_list_pic, inputsalesVolumePIC, submit_full_refund, submit_report_change_variant, submit_report_price_rising } from 'service/api';
import swal from 'sweetalert';
import NumberFormat from 'react-number-format';
import { getMytaskProduct } from '../fetchData';
import SolveIssueProductDevelopmentTable from './SolveIssueProductDevelopmentTable';
import { getUser } from 'helpers/parseJWT';
import { useHistory } from "react-router-dom";
// import pic from 'helpers/pic'

const style = {
  position: 'absolute',
  top: '50%',
  left: '5%',
  transform: 'translate(0, -50%)',
  width: '90vw',
  height: 'auto',
  maxHeight: "95vh",
  overFlowY: "auto",
  bgcolor: '#f8f8f8',
  border: '1px solid lightgray',
  boxShadow: 24,
  borderRadius: '5px',
  p: 4,
};
const styleQuote = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '95vw',
  maxWidth: 600,
  height: 'auto',
  maxHeight: "95vh",
  overFlowY: "auto",
  bgcolor: 'white',
  border: '1px solid lightgray',
  boxShadow: 24,
  borderRadius: '5px',
  p: 4,
};

const SolveIssueProductDevelopmentModal = ({ idOrder, status, issue, setUpdate, isProductDevelopment }) => {
  const [open, setOpen] = useState(false);
  const [openQuoteModal, setOpenQuoteModal] = useState(false);
  const [openSelectPIC, setOpenSelectPIC] = useState(false);
  const [confirmationIssue, setConfirmationIssue] = useState('')
  const [originalMytaskProducts, setOriginalMytaskProducts] = useState(null)
  const [mytaskProducts, setMytaskProducts] = useState(null)
  const [additionalShipping, setAdditionalShipping] = useState('');
  const [newLink, setNewLink] = useState('');
  const [localShipping, setLocalShipping] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPic, setSelectedPic] = useState('');
  const [toko, setToko] = useState('');
  const [idPage, setIdPage] = useState('');
  const [showVariantAfter, setShowVariantAfter] = useState(false);
  const [pic, setPic] = useState([]);
  
  const handleOpen = () => {
    getMytaskProduct(idOrder, setMytaskProducts, setOriginalMytaskProducts, setIsLoading, setNewLink)
    setOpen(true)
  };

  const handleClose = () => {
    setToko('')
    setIdPage('')
    setShowVariantAfter(false);
    setMytaskProducts(originalMytaskProducts)
    setConfirmationIssue('')
    setOpen(false)
  };

  const history = useHistory();

  useEffect(() => {
    if (status === 'Quoted' || status === 'Quoting') {
      set_ConfirmationIssue('Found New Supplier')
    }
  }, [])

  const handleSubmitChangeSupplier = async () => {
    const productBefore = mytaskProducts.product.map((prod) => {
      return {
        id_request: mytaskProducts.id_request,
        id_product: prod.id_product,
        qty: prod.qty_available
      }
    })
    const productAfter = mytaskProducts.product.map((prod) => {
      return {
        id_request: prod.id_request_after,
        id_product: prod.id_product_after,
        qty: prod.qty_available_after
      }
    })

    const invalidProductAfter = productAfter.filter((item) => !item.id_request || !item.id_product || !item.qty)

    if(invalidProductAfter.length) {
      swal("Oops", "Please complete variant and quantity to replace product", "error")
      return
    }

    const payload = {
      id_so: mytaskProducts.id_so,
      aditional_shipping: ~~(additionalShipping.replace('.', '')),
      local_shipping: localShipping,
      product_before: productBefore,
      product_after: productAfter,
    }
    const response = await submit_report_change_variant(JSON.stringify(payload))
    if (response.status === 200) {
      setUpdate((prev) => !prev);
      handleClose()
    } else {
      swal("Failed", response.message, "error")
    }
  }

  const handleSubmitNeedAdjustment = async () => {
    const productsPayload = mytaskProducts.product.map((prod) => {
      return {
        id_request: mytaskProducts.id_request,
        id_product: prod.id_product,
        price: prod.price,
        new_price: prod.new_price,
      }
    })

    const invalidProductAfter = productsPayload.filter((item) => !item.new_price)

    if(invalidProductAfter.length) {
      swal("Oops", "Please complete all new prices", "error")
      return
    }

    const payload = {
      id_so: mytaskProducts.id_so,
      aditional_shipping: ~~(additionalShipping.replace('.', '')),
      product: productsPayload
    }
    
    const response = await submit_report_price_rising(JSON.stringify(payload))
    if (response.status === 200) {
      setUpdate((prev) => !prev);
      handleClose()
    } else {
      swal("Failed", response.message, "error")
    }
  }
  
  const delete_MyTaskReport = async () => {
    swal({
      title: "Solve Task: No Issue",
      text: "Are you sure want to remove this task from Product Development?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((yes) => {
      if (yes) {
        deleteMyTask()
      }
    });
  }

  const confirm_submitFullRefund = async () => {
    swal({
      title: "Solve Task: Full Refund",
      text: "Are you sure want to solve this task as Full Refund?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((yes) => {
      if (yes) {
        submitFullRefund()
      }
    });
  }

  const deleteMyTask = async () => {
    const response = await deleteMyTaskReport(idOrder)
    if (response.status === 200) {
      setMytaskProducts(null)
      setConfirmationIssue('')
      setUpdate((prev) => !prev);
      handleClose()
    }
  }

  const submitFullRefund = async () => {
    const payload = {id_so: idOrder}
    const response = await submit_full_refund(JSON.stringify(payload))
    if (response.status === 200) {
      setConfirmationIssue('')
      setUpdate((prev) => !prev);
      handleClose()
    }
  }
  
  const handleSubmitPIC = async () => {
    const body = JSON.stringify({ pic: getUser().user, link: newLink });

    if (newLink === '') {
      swal('warning', 'Please insert valid product link', 'warning');
      return;
    }

    const response = await inputsalesVolumePIC(body);
    if (response?.status === 201) {
      setOpen((prev) => !prev);
      swal('Success', 'PIC Submitted successfully', 'success');
      // setUpdate((prev) => !prev);
      history.push(`/quotation/check-volume`);
    } else {
      swal('Failed', `Error : ${response?.message}`, 'error');
    }
  };
  
  const handleSubmitQuote = async () => {
    const body = JSON.stringify({ pic: selectedPic, link: newLink });

    if (newLink === '') {
      swal('warning', 'Please insert valid product link', 'warning');
      return;
    }

    const response = await inputsalesVolumePIC(body);
    if (response?.status === 201) {
      swal('Success', 'PIC Submitted successfully', 'success');
      // setOpen(false)
      setOpenQuoteModal(false)
      setOpenSelectPIC(false)
      setUpdate((prev) => !prev);
    } else {
      swal('Failed', `Error : ${response?.message}`, 'error');
    }
  };

  const set_ConfirmationIssue = (value) => {
    setConfirmationIssue(value)
    if (value === 'Found New Supplier' || value === 'Need Adjustment') {
      setShowVariantAfter(true)
    } else {
      setShowVariantAfter(false)
    }
  }

  const set_OpenSelectPIC = async () => {
    const response = await get_list_pic();
    console.log("1212", response)
    if (response?.status === 200) {
      setPic(response.data);
      setOpenSelectPIC(true)
    } else {
      swal('Failed', `Error : ${response?.message}`, 'error');
    }
  }

  return (
    <>
      <button
        className={`${status == 'paid' ? 'bg-gray-300 text-white' : 'border text-blue-500 border-blue-500'} py-2 px-5 border text-blue-500 border-blue-500 rounded-md text-center cursor-pointer whitespace-nowrap`}
        onClick={() => {
          handleOpen();
        }}
        // disabled={status !== 'paid'}
      >
        Solve Issue
      </button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box sx={style} className='overflow-y-scroll variant-scroll'>
          <div className='flex justify-end -mt-5'>
            <IconButton onClick={handleClose} style={{ textAlign: 'right' }}>
              <CloseIcon />
            </IconButton>
          </div>
          {isProductDevelopment &&
          <>
            <Typography id='modal-modal-title' variant='h4' component='h4'>
              Solve Issue <span className='text-xl font-bold'>({idOrder})</span>
            </Typography>
            <div className='space-y-2 bg-white p-3 rounded-lg border mb-3'>
              <div className="flex justify-between gap-x-2 items-center">
                  <div className="w-1/3 shrink-0 flex justify-between">
                      Confirmation
                      <span className='font-bold'>:</span>
                  </div>
                  <div className="w-full px-2 py-1">
                      <select value={confirmationIssue} className='outline-none border rounded-lg px-2' onChange={(e) => set_ConfirmationIssue(e.target.value)}>
                        <option value="" disabled>Confirm the issue</option>
                        <option value="No issue">No issue</option>
                        {issue === 'No stock' &&
                          <>
                            <option value="Found New Supplier">Found New Supplier</option>
                            <option value="Don't find supplier">Don't find supplier</option>
                          </>
                        }
                        {issue === 'Price Rising' &&
                          <>
                            <option value="Need Adjustment">Need Adjustment</option>
                          </>
                        }
                      </select>
                  </div>
              </div>
            </div>
          </>
          }

          {mytaskProducts && 
            <SolveIssueProductDevelopmentTable 
              mytaskProducts={mytaskProducts} 
              setMytaskProducts={setMytaskProducts}
              originalMytaskProducts={originalMytaskProducts}
              setOriginalMytaskProducts={setOriginalMytaskProducts}
              additionalShipping={additionalShipping}
              setAdditionalShipping={setAdditionalShipping}
              newLink={newLink}
              setNewLink={setNewLink}
              localShipping={localShipping}
              setLocalShipping={setLocalShipping}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              openQuoteModal={openQuoteModal}
              setOpenQuoteModal={setOpenQuoteModal}
              showVariantAfter={showVariantAfter}
              setShowVariantAfter={setShowVariantAfter}
              toko={toko}
              setToko={setToko}
              idPage={idPage}
              setIdPage={setIdPage}
              confirmationIssue={confirmationIssue}
              setUpdate={setUpdate}
              idOrder={idOrder}
            />
          }
          <div className="flex justify-center items-center mt-5">
              <div className="bg-blue-500 text-white hover:bg-blue-600 px-5 py-1 rounded-md cursor-pointer"
              onClick={() => confirmationIssue === "Found New Supplier" ? handleSubmitChangeSupplier() 
                              : confirmationIssue === "No issue" ? delete_MyTaskReport() 
                              : confirmationIssue === "Don't find supplier" ? confirm_submitFullRefund() 
                              : confirmationIssue === "Need Adjustment" ? handleSubmitNeedAdjustment() 
                              : null}
              >Submit</div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openQuoteModal}
        onClose={() => setOpenQuoteModal(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box sx={styleQuote} className='overflow-y-scroll variant-scroll'>
          <div className='flex justify-end -mt-5'>
            <IconButton onClick={() => setOpenQuoteModal(false)} style={{ textAlign: 'right' }}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className='space-y-2 bg-white p-3 mb-3 text-center'>
            <div className="h2 text-lg">Once you click Quote, your request will be assigned to quotation team</div>
          </div>
          <div className="flex justify-center items-center gap-x-3">
            <div className="text-center bg-blue-700 hover:bg-blue-600 text-white w-32 px-3 py-1 rounded-lg cursor-pointer" onClick={handleSubmitPIC}>Check Directly</div>
            <div className="text-center bg-blue-700 hover:bg-blue-600 text-white w-32 px-3 py-1 rounded-lg cursor-pointer" onClick={set_OpenSelectPIC}>Quote</div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openSelectPIC}
        onClose={() => setOpenSelectPIC(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box sx={styleQuote} className='overflow-y-scroll variant-scroll'>
          <div className='flex justify-end -mt-5'>
            <IconButton onClick={() => setOpenSelectPIC(false)} style={{ textAlign: 'right' }}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className='space-y-2 bg-white p-3 mb-3 text-center'>
            <div className="h2 text-lg">Please select PIC for this order link.</div>
            <select
              name='select'
              value={selectedPic}
              onChange={(e) => setSelectedPic(e.target.value)}
              className='w-full border border-gray-300 p-1 rounded-md focus-within:outline-blue capitalize'>
              <option value='' disabled>
                Select PIC
              </option>
              {pic?.map((p) => (
                <option key={p.user} value={p.user}>{p.user}</option>
              ))}
            </select>
          </div>
          <div className="flex justify-center items-center gap-x-3">
            <div className={`${selectedPic !== "" ? "bg-blue-700 hover:bg-blue-600" : "bg-gray-300"} text-center  text-white w-32 px-3 py-1 rounded-lg cursor-pointer`} onClick={() => selectedPic !== "" && handleSubmitQuote()}>Submit</div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default SolveIssueProductDevelopmentModal;
