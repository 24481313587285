import callAPI from 'service/config';

// PRODUCTION

// DASHBOARD

let baseUrl = process.env.REACT_APP_URL_API_GATEWAY;
let baseUrlGolang = process.env.REACT_APP_URL_API_GATEWAY_GOLANG;

let baseUrlNewGolang = process.env.REACT_APP_URL_API_GATEWAY_NEW_GOLANG;
let baseUrlNewGolangMytask = process.env.REACT_APP_URL_API_GATEWAY_NEW_GOLANG_MYTASK;
let baseUrlNewGolangLogisticChina = process.env.REACT_APP_URL_API_GATEWAY_NEW_GOLANG_LOGISTIC_CHINA;
let tokenize = true;
let noHeader = 'false';

if (process.env.REACT_APP_ENV === 'DEVELOPMENT') {
  baseUrl = process.env.REACT_APP_URL_API_OMS_DEV;
  baseUrlGolang = process.env.REACT_APP_URL_API_GATEWAY_GOLANG;
  // tokenize = false; //BARIS INI DICOMMENT KALO MAU PUSH KE GITHUB
  // noHeader = 'true'; //BARIS INI DICOMMENT KALO MAU PUSH KE GITHUB
}

export async function getUserObe() {
  const url = `${baseUrlGolang}/user-obe`;
  return callAPI({
    url,
    method: 'GET',
    token: noHeader,
  });
}

export async function getAnalyticsData() {
  const url = `${baseUrl}/home/statistik2`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

// Sidebar
export async function getPurchasingCounter(params) {
  const url = `${baseUrlNewGolang}/purchasing/counter`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}
// MANAGEMENT SALES

// Customer Management
export async function getListCustomerManagement(searchData, setParams) {
  const url = `${baseUrl}/management-sales/customer-management${
    searchData ? searchData : setParams
  }`;

  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function addSalesData(data) {
  const url = `${baseUrl}/management-sales/customer-management`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
    allowAll: true,
  });
}

export async function add_multi_sales_data(data) {
  const url = `${baseUrlNewGolang}/management-sales/customer-management`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
    allowAll: true,
  });
}

export async function releaseSalesData(data) {
  const url = `${baseUrl}/management-sales/customer-management`;
  return callAPI({
    url,
    method: 'DELETE',
    data,
    token: tokenize,
    allowSpv: true,
  });
}

export async function addSalesActivity(data) {
  const url = `${baseUrl}/management-sales/customer-management/notes`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
    allowAll: true,
  });
}

export async function viewModal(email) {
  const url = `${baseUrl}/management-sales/customer-management/order/${email}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function trackingModal(id) {
  const url = `${baseUrl}/management-sales/customer-management/tracking/${id}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function updatePhoneNumber(data) {
  const url = `${baseUrl}/management-sales/customer-management/edit-number`;
  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
    allowAll: true,
  });
}

export async function setFlagDataCM(data) {
  const url = `${baseUrl}/management-sales/customer-management/prospect`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
    allowAll: true,
  });
}

// Sales Target
export async function getSalesData() {
  const url = `${baseUrl}/management-sales/sales-target`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function updateSalesTarget(data) {
  const url = `${baseUrl}/management-sales/sales-target`;
  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
    allowSpv: true,
  });
}

export async function filterSalesTarget(params) {
  const url = `${process.env.REACT_APP_URL_API_GATEWAY_GOLANG}/management-sales/sales-target?${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function setTargetContact(data) {
  const url = `${process.env.REACT_APP_URL_API_GATEWAY_GOLANG}/management-sales/sales-target/target-contact`;
  return callAPI({
    url,
    data,
    method: 'POST',
    token: tokenize,
    allowSpv: true,
  });
}

export async function getActivitySales(params) {
  const url = `${process.env.REACT_APP_URL_API_GATEWAY_GOLANG}/management-sales/sales-target/target-contact/${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

// OMS Accounts
export async function get_oms_accounts_list(params) {
  const url = `${baseUrlNewGolang}/oms-accounts/list-user?${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function submit_oms_accounts_list(data) {
  const url = `${baseUrlNewGolang}/oms-accounts/create-user`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
  });
}

export async function update_oms_accounts_list(data) {
  const url = `${baseUrlNewGolang}/oms-accounts/update-user`;
  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
  });
}

export async function set_active_oms_accounts_list(user) {
  const url = `${baseUrlNewGolang}/oms-accounts/set-active/${user}`;
  return callAPI({
    url,
    method: 'PUT',
    token: tokenize,
  });
}

export async function get_list_user_attribute() {
  const url = `${baseUrlNewGolang}/oms-accounts/list-user-attribute`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function get_departments(jabatan) {
  const url = `${baseUrlNewGolang}/oms-accounts/get-department/${jabatan} `;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function get_atasans(jabatan, department) {
  const url = `${baseUrlNewGolang}/oms-accounts/get-atasan?jabatan=${jabatan}&department=${department} `;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

// New Customer
export async function getNewCustomerData() {
  const url = `${process.env.REACT_APP_URL_API_GATEWAY_GOLANG}/management-sales/new-customer`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function filterNewCustomerGolang(params) {
  const url = `${process.env.REACT_APP_URL_API_GATEWAY_GOLANG}/management-sales/new-customer?${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function filterAllCustomerRequest(params) {
  const url = `${process.env.REACT_APP_URL_API_GATEWAY_GOLANG}/pre-sales/all-customer-request?${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function getDetailsNewCustomerData(email) {
  const url = `${process.env.REACT_APP_URL_API_GATEWAY_GOLANG}/management-sales/new-customer/${email}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function updateNewCustomerDetails(data) {
  const url = `${process.env.REACT_APP_URL_API_GATEWAY_GOLANG}/management-sales/new-customer/edit`;
  return callAPI({
    url,
    method: 'PUT',
    token: tokenize,
    data,
    allowSpv: true,
  });
}

export async function updateNewCustomerLocation(data) {
  const url = `${process.env.REACT_APP_URL_API_GATEWAY_GOLANG}/management-sales/new-customer/alamat/edit`;
  return callAPI({
    url,
    method: 'PUT',
    token: tokenize,
    data,
    allowSpv: true,
  });
}

export async function getGenerateResetPassword(params) {
  const url = `${process.env.REACT_APP_URL_API_GATEWAY_GOLANG}/reset-password/${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function insert_random_sales(data) {
  const url = `${baseUrlNewGolang}/management-sales/insert-random-sales`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
    allowSpv: true,
  });
}

// OBE
export async function getListOBE(page, limit, params) {
  const url = `${baseUrl}/management-sales/obe?${page || limit ? params : ''}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function addSalesOBE(data) {
  const url = `${baseUrl}/management-sales/obe`;
  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
    allowSpv: true,
  });
}

export async function releaseSalesOBE(email) {
  const url = `${baseUrl}/management-sales/obe/${email}`;
  return callAPI({
    url,
    method: 'DELETE',
    token: tokenize,
    allowSpv: true,
  });
}

export async function addCustomerOBE(data) {
  const url = `${baseUrl}/management-sales/obe`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
    allowAll: true,
  });
}

export async function importCustomerOBE(data) {
  const url = `${baseUrl}/management-sales/obe/import`
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
    allowSpv: true,
  });
}

export async function addSalesActivityOBE(data) {
  const url = `${baseUrl}/management-sales/obe/notes`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
    allowAll: true,
  });
}

export async function updatePhoneNumberOBE(data) {
  const url = `${baseUrl}/management-sales/obe/edit-number`;
  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
    allowAll: true,
  });
}

export async function setFlagData(data) {
  const url = `${baseUrl}/management-sales/obe/prospect`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
    allowAll: true,
  });
}

export async function updateEmailOBE(data) {
  const url = `${baseUrl}/management-sales/obe/edit-email`;
  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
    allowAll: true,
  });
}

// Performance
export async function getPerformanceData(params) {
  const url = `${baseUrl}/management-sales/performance?${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

// // PRE SALES

// Voucher
export async function getVoucherData() {
  const url = `${baseUrl}/pre-sales/voucher-generator`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function generateVoucher(data) {
  const url = `${baseUrl}/pre-sales/voucher-generator`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
  });
}

//Sales Request
export async function getSalesRequestData(params) {
  const url = `${baseUrl}/pre-sales/sales-request?${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function getDetailRequest(id) {
  const url = `${baseUrl}/pre-sales/sales-request/${id}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function inputSalesProduct(data) {
  const url = `${baseUrlGolang}/pre-sales/sales-request/draft-orders`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
  });
}

export async function inputSalesRequestData(data) {
  const url = `${baseUrlNewGolang}/pre-sales/sales-request`; 
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
  });
}

export async function inputSalesRequestDraftOrders(data) {
  const url = `${baseUrlGolang}/pre-sales/sales-request/draft-orders`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
  });
}

export async function inputsalesVolumePIC(data) {
  const url = `${baseUrlGolang}/pre-sales/sales-request/updater`;
  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
  });
}

export async function get_list_pic() {
  const url = `${baseUrlNewGolang}/pre-sales/sales-request/list-updater`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function submitCustomProductVariant(data) {
  const url = `${baseUrlGolang}/pre-sales/sales-request/custom-order`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
  });
}

export async function submit_edit_sales_request(data) {
  const url = `${baseUrlNewGolang}/pre-sales/check-volume/sales`;
  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
  });
}

export async function get_history_sales_request(idRequest) {
  const url = `${baseUrlNewGolang}/pre-sales/check-volume/sales/history/${idRequest}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

//PRODUCTION
export async function getChinaDetail(data) {
  const url = process.env.REACT_APP_URL_API_GET_CHINA;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: true,
  });
}
export async function getChinaDetail2(toko,id) {
  const url = `${process.env.REACT_APP_URL_API_GATEWAY1}/get-china/${toko}/${id}`;
  return callAPI({
    url,
    method: 'GET',
    token: true,
  });
}
export async function getChinaRawDetail(toko,id) {
  const url = `${baseUrlGolang}/get-china-raw/${toko}/${id}`;
  return callAPI({
    url,
    method: 'GET',
    token: true,
  });
}
export async function getChinaTestDetail(toko,id) {
  const url = `${baseUrlGolang}/get-china-test/${toko}/${id}`;
  return callAPI({
    url,
    method: 'GET',
    token: true,
    source: 'ocistok'
  });
}

export async function changeQuotation(data) {
  const url = `${baseUrlNewGolang}/quotation-change`;
  return callAPI({
    url,
    method: 'PUT',
    token: false,
    data,
  });
}



export async function cancelVolumeData(data) {
  const url = `${baseUrl}/pre-sales/sales-request`;
  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
  });
}

export async function cancelVolumeDataAll(data) {
  const url = `${baseUrlGolang}/pre-sales/sales-request/all`;
  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
  });
}

export async function checkLinkRequest(data) {
  const url = `${baseUrl}/pre-sales/sales-request/search`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
    allowAll:true
  });
}

export async function assignRequestToList(data) {
  const url = `${baseUrl}/pre-sales/sales-request/assign`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
  });
}

export async function checkLinkAddVariant(data) {
  const url = `${baseUrlGolang}/pre-sales/all-orders/addvariant`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
    allowAll: true,
  });
}

export async function postAddVariant(data) {
  const url = `${baseUrl}/pre-sales/all-orders/addvariant/insert`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
    allowAll: true,
  });
}

export async function replace_orders(data) {
  const url = `${baseUrlNewGolang}/replace-orders`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
    allowAll: true,
  });
}

// Customer Request
export async function getCustomerRequestData() {
  const url = `${baseUrl}/pre-sales/customer-request`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function getDetailProductRequest(id) {
  const url = `${baseUrl}/pre-sales/customer-request/${id}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function inputCustomerRequest(data) {
  const url = `${baseUrl}/pre-sales/customer-request`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
  });
}

// Check Details
export async function getVolumeDataAssign() {
  const url = `${baseUrlGolang}/pre-sales/check-volume/update
  `;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function cancelVolumeRequest(data) {
  const url = `${baseUrl}/pre-sales/check-volume`;
  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
  });
}

export async function inputVolumeData(data) {
  const url = `${baseUrlNewGolang}/pre-sales/check-volume`; 
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
  });
}

export async function getVolumeData() {
  const url = `${baseUrlGolang}/pre-sales/check-volume`; 
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function getVariants(id) {
  const url = `${baseUrlGolang}/pre-sales/check-volume/${id}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

// All Orders
export async function getAllOrdersData(params) {
  const url = `${baseUrlGolang}/pre-sales/all-orders?${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function submitManualPayment(data) {
  const url = `${baseUrl}/pre-sales/all-orders`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
    allowSpv: true,
  });
}

export async function getProductAllOrder(id) {
  const url = `${baseUrlGolang}/pre-sales/all-orders/${id}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function postAdjustment(data) {
  const url = `${baseUrlGolang}/pre-sales/all-orders/adjustment`;
  return callAPI({
    url,
    data,
    method: 'POST',
    token: tokenize,
    allowSpv: true,
  });
}

export async function get_rincian_cicilan(idGroup) {
  const url = `${baseUrlGolang}/purchasing/${idGroup}/installment`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function request_unpaid(data) {
  const url = `${baseUrlGolang}/pre-sales/all-orders/request-unpaid`;
  return callAPI({
    url,
    data,
    method: 'POST',
    token: tokenize,
  });
}

// Expired Orders
export async function get_expired_orders(params) {
  const url = `${baseUrlGolang}/pre-sales/expired-orders?${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function approve_expired_orders_to_unpaid(data) {
  const url = `${baseUrlGolang}/pre-sales/expired-orders/update`;
  return callAPI({
    url,
    data,
    method: 'POST',
    token: tokenize,
  });
}

export async function get_total_ongkir_local(data) {
  const url = `${baseUrlGolang}/pre-sales/all-orders/check-ongkir`;
  return callAPI({
    url,
    data,
    method: 'POST',
    token: tokenize,
  });
}

//All Customer Request
export async function getAllCustomerRequest(params) {
  const url = `${baseUrlGolang}/pre-sales/all-customer-request?${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function getDetailCustomerRequest(id) {
  const url = `${baseUrl}/pre-sales/all-customer-request/${id}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function exportAllCustomerRequest() {
  const url = `${baseUrlGolang}/pre-sales/all-customer-request/download`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

//My Tasks
export async function getMyTasks(params) {
  const url = `${baseUrlNewGolangMytask}/mytask/report?${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function get_mytask_product(params) {
  const url = `${baseUrlNewGolangMytask}/mytask/report/produk/${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function get_report_product_price(params) {
  const url = `${baseUrlNewGolangMytask}/mytask/report/produk/price?${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function get_order_report_product(idSo) {
  const url = `${baseUrlNewGolangMytask}/mytask/report/produk/${idSo}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function get_report_approval(params) {
  const url = `${baseUrlNewGolangMytask}/mytask/report/approval?${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function getReportDetailProduct(params) {
  const url = `${baseUrlGolang}/detail-produk/${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

// export async function adjustMyTask() {
//   const url = `${baseUrlNewGolangMytask}/mytask/report`;
//   return callAPI({
//     url,
//     method: 'PUT',
//     token: tokenize,
//   });
// }

export async function sendReport(data) {
  const url = `${baseUrlNewGolangMytask}/mytask/report`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
    allowAll: true
  });
}

export async function reduceQty(data) {
  const url = `${baseUrlNewGolangMytask}/mytask/report/reduce-qty`;
  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
  });
}

export async function deleteMyTaskReport(id) {
  const url = `${baseUrlNewGolangMytask}/mytask/report/${id}`;
  return callAPI({
    url,
    method: 'DELETE',
    token: tokenize,
  });
}

export async function submit_full_refund(data) {
  const url = `${baseUrlNewGolangMytask}/mytask/report/pd/full-refund`;
  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
  });
}

export async function solveIssue(data) {
  const url = `${baseUrlNewGolangMytask}/mytask/report`;
  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
  });
}

export async function solve_issue_sales_table(data) {
  const url = `${baseUrlNewGolangMytask}/mytask/report/sales`;
  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
    allowAll: true
  });
}

export async function solve_issue_sales_table_change_variant(data) {
  const url = `${baseUrlNewGolangMytask}/mytask/report/sales/change-variant`;
  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
    allowAll: true
  });
}

export async function solve_issue_sales_table_price_rising(data) {
  const url = `${baseUrlNewGolangMytask}/mytask/report/sales/price-rising`;
  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
    allowAll: true
  });
}

export async function submitFullRefund(data) {
  const url = `${baseUrlNewGolangMytask}/mytask/report/full-refund`;
  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
  });
}

export async function changeReportSupplier(data) {
  const url = `${baseUrlNewGolangMytask}/mytask/report/change-supplier`;
  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
  });
}

export async function submit_report_change_variant(data) {
  const url = `${baseUrlNewGolangMytask}/mytask/report/change-variant`;
  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
  });
}

export async function submit_report_price_rising(data) {
  const url = `${baseUrlNewGolangMytask}/mytask/report/pd/price-rising`;
  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
  });
}

export async function submit_payment_proof(data) {
  const url = `${baseUrlNewGolangMytask}/mytask/report/sales/proof`;
  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
  });
}

export async function submit_approve_or_reject(data) {
  const url = `${baseUrlNewGolangMytask}/mytask/report/approval`;
  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
  });
}
////////////////////

// UTILS

// Search Bar
export async function searchGlobalData(option, input) {
  const url = `${baseUrl}/search/${option}/${input}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

// Live search
export async function getLiveSearchResult(email) {
  const url = `${baseUrl}/management-sales/customer-management/${email}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function getAutoFillDataLiveSearch(input) {
  const url = `https://gateway2.ocistok.com/oms/customer/autofill${
    input ? '/' + input : ''
  }`;
  return callAPI({
    url,
    method: 'GET',
    token: true,
  });
}

// DEVELOPMENT

// Purchasing Data
export async function getDetailOrderData(id) {
  const url = `${baseUrlGolang}/detail-order/${id}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function getRepackingDetails(id) {
  const url = `${baseUrlNewGolangLogisticChina}/repacking-detail/${id}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function refundOrder(data) {
  const url = `${baseUrlGolang}/detail-order`;
  return callAPI({
    url,
    data,
    method: 'PUT',
    token: tokenize,
  });
}

export async function getOrderNotesData(id) {
  const url = `${baseUrl}/notes-order/${id}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function postOrderNotesData(data) {
  const url = `${baseUrl}/notes-order`;
  return callAPI({
    data,
    url,
    method: 'POST',
    token: tokenize,
    allowAll: true
  });
}

export async function refundAbnormal(data) {
  const url = `${baseUrl}/purchasing/abnormal/refund`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
  });
}

export async function resendAbnormal(data) {
  const url = `${baseUrl}/purchasing/abnormal/resend`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
  });
}

export async function reoderAbnormal(data) {
  const url = `${baseUrl}/purchasing/abnormal/reorder`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
  });
}

export async function cancelAbnormal(data) {
  const url = `${baseUrl}/purchasing/abnormal/cancel`;
  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
  });
}

export async function refundAbnormalChina(data) {
  const url = `${baseUrl}/logistic-china/abnormal/refund`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
  });
}

export async function resendAbnormalChina(data) {
  const url = `${baseUrl}/logistic-china/abnormal/resend`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
  });
}

export async function reoderAbnormalChina(data) {
  const url = `${baseUrl}/logistic-china/abnormal/reorder`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
  });
}

export async function cancelAbnormalChina(data) {
  const url = `${baseUrl}/logistic-china/abnormal/cancel`;
  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
  });
}

// New Orders
export async function getOrderData(params) {
  const url = `${baseUrlNewGolang}/purchasing/new-orders?${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function makePoToSupplier(data) {
  const url = `${baseUrl}/purchasing/new-orders`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
  });
}

export async function deleteOrderData(data) {
  const url = `${baseUrlGolang}/purchasing/new-orders`;
  return callAPI({
    url,
    method: 'DELETE',
    data,
    token: tokenize,
  });
}

export async function sendNotesData(data) {
  const url = `${baseUrl}/notes-product`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
  });
}

export async function sendAdjustmentRequest(data) {
  const url = `${baseUrl}/purchasing/new-orders/adjustment`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
  });
}

export async function send_purchasing(id, toko) {
  const url = `${baseUrlNewGolang}/purchase-order?toko=${toko}&id=${id}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

// Already PO
export async function getAlreadyPOData(params) {
  const url = `${baseUrlNewGolang}/purchasing/already-po?${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function getAlreadyPODataDetails(idPo) {
  const url = `${baseUrlNewGolang}/purchasing/already-po/${idPo}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function updatePaymentData(data) {
  const url = `${baseUrl}/purchasing/already-po`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
  });
}

export async function getAdjustmentDetails(idPo) {
  const url = `${baseUrlNewGolang}/purchasing/already-po/po-adjustment/${idPo}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function approveAdjustPaymentData(data) {
  const url = `${baseUrlNewGolang}/purchasing/already-po/po-adjustment`;
  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
  });
}

export async function changeSupplierData(data) {
  const url = `${baseUrl}/purchasing/already-po`;
  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
  });
}

export async function cancelAlreadyPO(data) {
  const url = `${baseUrl}/purchasing/already-po`;
  return callAPI({
    url,
    method: 'DELETE',
    data,
    token: tokenize,
  });
}

export async function synchronizeData(data) {
  const url = `${baseUrlNewGolang}/updatepo`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
  });
}

// PoPaid
export async function getPoPaidData(params) {
  const url = `${baseUrlNewGolang}/purchasing/po-paid?${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function updateTrackingData(data) {
  const url = `${baseUrl}/purchasing/po-paid`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
  });
}

export async function cancelPoPaid(data) {
  const url = `${baseUrlGolang}/purchasing/po-paid`;
  return callAPI({
    url,
    method: 'DELETE',
    data,
    token: tokenize,
  });
}

export async function sendAdjustmentPo(data) {
  const url = `${baseUrl}/purchasing/po-paid/adjustment`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
  });
}

// Otw Wh China
export async function getOtwChinaData(params) {
  const url = `${baseUrlNewGolang}/purchasing/otw-wh-china?${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function editTrackingData(data) {
  const url = `${baseUrl}/purchasing/otw-wh-china`;
  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
  });
}

export async function submitReceivedData(data) {
  const url = `${baseUrlNewGolangLogisticChina}/purchasing/otw-wh-china`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
  });
}

export async function sendBarcode(data) {
  const url = `${baseUrl}/purchasing/otw-wh-china/scan`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
  });
}

export async function submitReceivedDataScan(data) {
  const url = `${baseUrl}/purchasing/otw-wh-china/scan-insert`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
  });
}

// Abnormal Purchasing
export async function getAbnormalData(params) {
  const url = `${baseUrl}/purchasing/abnormal?${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

// Approval
export async function getApproval(params) {
  const url = `${baseUrl}/purchasing/approval?${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function approvalPayment(data) {
  const url = `${baseUrl}/purchasing/approval`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
  });
}

export async function get_detail_approval(params) {
  const url = `${baseUrlNewGolang}/purchasing/detail-approval?${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

// LOGISTIC CHINA

// Repacking
export async function getRepackingData(params) {
  const url = `${baseUrlNewGolang}/logistic-china/v2/repacking?${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

// export async function insertRepackingData(data) {
//   const url = `${baseUrl}/logistic-china/v2/repacking`;
//   return callAPI({
//     url,
//     method: 'POST',
//     data,
//     token: tokenize,
//   });
// }

export async function insertRepackingData(data) {
  const url = `${baseUrlNewGolangLogisticChina}/logistic-china/v2/repacking-marking-code`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
  });
}

export async function getScanDetailCarton(params) {
  const url = `${baseUrlGolang}/logistic-china/send-to-idn/detail-karton/${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function getWhIndoProductDetail(params) {
  const url = `${baseUrlGolang}/whindo/flutter/get-otw-whindo/produk/${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function insertTableContainer(data) {
  const url = `${baseUrlGolang}/logistic-china/send-to-idn`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
  });
}

export async function get_list_forwarder() {
  const url = `${baseUrlNewGolang}/logistic-china/list-forwarder`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function register_carton(data) {
  const url = `${baseUrlNewGolangLogisticChina}/logistic-china/register-karton`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
  });
}

export async function remove_carton(cartonID) {
  const url = `${baseUrlNewGolangLogisticChina}/logistic-china/register-karton/${cartonID}`;
  return callAPI({
    url,
    method: 'DELETE',
    token: tokenize,
  });
}

// Send to IDN
export async function getSendToIdnData(params) {
  const url = `${baseUrlGolang}/logistic-china/send-to-idn?${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function getSendToIdnDetainedData(params) {
  const url = `${baseUrlGolang}/logistic-china/send-to-idn-detained?${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function getSendToIdnDataV2(params) {
  const url = `${baseUrlNewGolang}/logistic-china/send-to-idn/v2?${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function updateContainerNumber(data) {
  const url = `${baseUrl}/logistic-china/send-to-idn`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
  });
}

export async function get_scanned_cartons(markingCode) {
  const url = `${baseUrlNewGolang}/logistic-china/send-to-idn/list-on-scanned-and-unscanned?marking_code=${markingCode}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function insert_scan_carton(data) {
  const url = `${baseUrlNewGolang}/logistic-china/send-to-idn/scan-karton`;
  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
  });
}

export async function delete_scan_carton(data) {
  const url = `${baseUrlNewGolang}/logistic-china/send-to-idn/unscan-karton`;
  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
  });
}

// export async function getDetailOrderDataRepacking(id) {
//   /const url = `${baseUrlNewGolang}/logistic-china/get-repacking-data?id_so=${id}`;
//   return callAPI({
//     url,
//     method: 'GET',
//     token: tokenize,
//   });
// }
export async function getDetailOrderDataRepacking(id) {
  const url = `${baseUrlNewGolang}/logistic-china/v1/get-repacking-data?id_so=${id}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

// export async function updateDetailOrderDataRepacking(data) {
//   /const url = `${baseUrlNewGolang}/logistic-china/update-repacking-data`;
//   return callAPI({
//     url,
//     method: 'PUT',
//     data,
//     token: tokenize,
//   });
// }

export async function updateDetailOrderDataRepacking(data) {
  const url = `${baseUrlNewGolang}/logistic-china/v2/update-repacking-data`;
  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
  });
}

// export async function insertDataExcel(data) {
//   // /const url = `${baseUrl}logistic-china/v2/repacking/import-excel`;
//   return callAPI({
//     url,
//     method: 'POST',
//     data,
//     token: 'excel',
//   });
// }

export async function get_marking_code_list(idOrder) {
  const url = `${baseUrlNewGolang}/logistic-china/list-marking-code?id_order=${idOrder}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function add_marking_code(data) {
  const url = `${baseUrlNewGolang}/logistic-china/add-mc`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
  });
}

export async function edit_marking_code(data) {
  const url = `${baseUrlNewGolang}/logistic-china/edit-marking-code`;
  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
  });
}

export async function export_marking_code_to_excel(params) {
  const url = `${baseUrlNewGolang}/logistic-china/send-to-idn/export?${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function confirm_acknowledge_returned_items(idSo) {
  const url = `${baseUrlNewGolang}/logistic-china/acknowledge-returned-item?id_so=${idSo}`;
  return callAPI({
    url,
    method: 'POST',
    token: tokenize,
  });
}

// Container

export async function getContainerData(params) {
  const url = `${baseUrl}/logistic-china/container/v2?${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function getAllContainerList() {
  const url = `${baseUrlGolang}/logistic-china/get-all-container`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function updateContainerSingle(data) {
  const url = `${baseUrlGolang}/logistic-china/container/single`;
  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
  });
}

export async function updateContainerAll(data) {
  const url = `${baseUrlGolang}/logistic-china/container`;
  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
  });
}

export async function deleteContainerSingle(idCarton) {
  const url = `${baseUrlGolang}/logistic-china/container/single/${idCarton}`;
  return callAPI({
    url,
    method: 'DELETE',
    token: tokenize,
  });
}

export async function checkContainerId(params) {
  const url = `${baseUrl}/logistic-china/container-by-id-karton?id_karton=${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function exportPackingList(containerNumber) {
  const url = `${baseUrlGolang}/sendemail-packing-list/${containerNumber}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function upload_file_packing_list(data) {
  const url = `${baseUrlNewGolang}/logistic-china/container/upload`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
  });
}

export async function updateETA(data) {
  const url = `${baseUrl}/logistic-china/container`;
  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
  });
}

export async function updateETD(data) {
  const url = `${baseUrl}/logistic-china/container/etd`;
  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
  });
}

export async function submit_draft_input_container(data) {
  const url = `${baseUrlNewGolang}/logistic-china/send-to-idn/insert-cont-schedule`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
  });
}

export async function get_products_by_karton(idOrder) {
  const url = `${baseUrlNewGolang}/logistic-china/send-to-idn/produk-by-karton?id_order=${idOrder}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function submit_cartons_to_abnormals(data) {
  const url = `${baseUrlNewGolang}/logistic-china/send-to-idn/karton-to-abnormal`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
  });
}

// Otw IDN
export async function getOtwIdnData(params) {
  const url = `${baseUrl}/logistic-china/otw-idn?${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function confirmArrivedContainer(data) {
  const url = `${baseUrl}/logistic-china/otw-idn`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
  });
}

// Arrived IDN
export async function getArrivedIdnData(params) {
  const url = `${baseUrl}/logistic-china/arrived-idn?${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function getDetailContainerData(params) {
  const url = `${baseUrl}/container/${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function confirmArrivedIdnContainer(data) {
  const url = `${baseUrl}/logistic-china/arrived-idn`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
  });
}

// Abnormals
export async function getAbnormalDataLogistcChina(params) {
  const url = `${baseUrl}/logistic-china/abnormal?${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}


// LOGISTIC INDO

// Pre Scan WH Indo
export async function getPreScanWhIndoData(params) {
  const url = `${baseUrlGolang}/logistic-indo/pre-scan-wh-indo?${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function pre_scan_carton(data) {
  const url = `${baseUrlGolang}/logistic-indo/pre-scan-wh-indo`;
  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
  });
}

export async function get_products_by_karton_id(idOrder) {
  const url = `${baseUrlNewGolang}/logistic-indo/produk-by-karton?id_order=${idOrder}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function submit_abnormal_onhold(data) {
  const url = `${baseUrlNewGolang}/logistic-indo/wh-indo-delivery/on-hold/abnormal`;
  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
  });
}

// Received WH Indo
export async function getReceivedWhIndoData(params) {
  const url = `${baseUrlGolang}/logistic-indo/received-wh-indo?${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function submitReceivedDataWhIndo(data) {
  const url = `${baseUrlGolang}/logistic-indo/received-wh-indo`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
  });
}

export async function getDataPrintLabelDetails(params) {
  const url = `${baseUrlNewGolangLogisticChina}/logistic-china/barcode/${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

// Delivery WH Indo
export async function getDataBarcodeWhIndo(id) {
  const url = `${baseUrlGolang}/logistic-indo/received-wh-indo/${id}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function getDataBarcodeWhIndoOut(id) {
  const url = `${baseUrlGolang}/logistic-indo/received-wh-indo/out/${id}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function getDeliveryWhIndoData(params) {
  const url = `${baseUrl}/logistic-indo/wh-indo-delivery?${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function getDeliveryWhIndoDataV2(params) {
  const url = `${baseUrlNewGolang}/logistic-indo/wh-indo-delivery?${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function sendProductToCustomer(data) {
  const url = `${baseUrl}/logistic-indo/wh-indo-delivery`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
  });
}

export async function getAllDataAprovalWHindo() {
  const url = `${baseUrlGolang}/logistic-indo/received-wh-indo/draft`;

  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function editDataAprovalWHindo(data) {
  const url = `${baseUrlGolang}/logistic-indo/received-wh-indo/draft/edit`;

  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
  });
}

export async function sendAprovalWHindo(data) {
  const url = `${baseUrlGolang}/logistic-indo/received-wh-indo/draft/verify`;

  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
  });
}

export async function recjectAprovalWHindo(id) {
  const url = `${baseUrlGolang}/logistic-indo/received-wh-indo/draft/${id}`;

  return callAPI({
    url,
    method: 'DELETE',
    token: tokenize,
  });
}

export async function recjectMultipleAprovalWHindo(data) {
  const url = `${baseUrlGolang}/logistic-indo/received-wh-indo/draft/multiple-delete`;

  return callAPI({
    url,
    method: 'DELETE',
    data,
    token: tokenize,
  });
}

export async function get_detail_tanda_terima(params) {
  const url = `${baseUrlNewGolang}/logistic-indo/wh-indo-delivery/surat-jalan?${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function submit_release_surat_jalan(params) {
  const url = `${baseUrlNewGolang}/logistic-indo/wh-indo-delivery/surat-jalan?${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function get_data_pengiriman(params) {
  const url = `${baseUrlNewGolang}/logistic-indo/wh-indo-delivery/data-pengiriman?${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function update_data_pengiriman(data) {
  const url = `${baseUrlNewGolang}/logistic-indo/wh-indo-delivery/edit-pengiriman`;

  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
  });
}

export async function unhold_order(data) {
  const url = `${baseUrlNewGolang}/logistic-indo/wh-indo-delivery/on-hold/unhold`;

  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
  });
}

// Gudang Pengiriman
export async function getDataWarehouseSendIDN(params) {
  const url = `${baseUrlGolang}/logistic-indo/sorting-wilayah?${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function updateDataWarehouseSendIDN(data) {
  const url = `${baseUrlGolang}/logistic-indo/sorting-wilayah/v2`;
  return callAPI({
    url,
    data,
    method: 'PUT',
    token: tokenize,
  });
}

export async function filterDatWarehouseSendIDN(data, params) {
  const url = `${baseUrlNewGolang}/logistic-indo/gudang-pengiriman/get-list-palet?${params}`;
  return callAPI({
    url,
    data,
    method: 'POST',
    token: tokenize,
  });
}

export async function filterDatWarehouseSendIDNById(params) {
  const url = `${baseUrlNewGolang}/logistic-indo/gudang-pengiriman/palet/${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function _filterDatWarehouseSendIDN(data, params) {
  const url = `${baseUrlGolang}/logistic-indo/received-wh-indo/filter?${params}`;
  return callAPI({
    url,
    data,
    method: 'POST',
    token: tokenize,
  });
}

export async function getDataWarehouseTotal() {
  const url = `${baseUrlGolang}/logistic-indo/sorting-wilayah/total`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function get_list_driver() {
  const url = `${baseUrlNewGolang}/list/driver`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function getDetailRelatedKarton(params) {
  const url = `${baseUrlGolang}/logistic-indo/received-wh-indo/in/related/${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function submitAllKarton(params) {
  const url = `${baseUrlGolang}/logistic-indo/received-wh-indo/in/all/${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function manual_received_whindo(data) {
  const url = `${baseUrlGolang}/logistic-indo/received-wh-indo/v2`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
  });
}

// Abnormal Logistic Indo
export async function getAbnormalDataLogistcIndo(params) {
  const url = `${baseUrl}/logistic-indo/abnormal?${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function refundAbnormalLogisticIndo(data) {
  const url = `${baseUrl}/logistic-indo/abnormal/refund`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
  });
}

export async function get_user_activity(params) {
  const url = `${baseUrlNewGolang}/logistic-indo/user-activity?${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

// Raja Ongkir
export async function getProvince() {
  const url = `${baseUrl}/rajaongkir/province`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function getCity(params) {
  const url = `${baseUrl}/rajaongkir/city/${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function getSubdistrict(params) {
  const url = `${baseUrl}/rajaongkir/subdistrict/${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function getListCourier(id, courier, weight, idGroup) {
  const url = `${baseUrl}/rajaongkir/courier?id=${id}&courier=${courier}&weight=${weight}&id_group=${idGroup}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

// Payment Logistic Indo
export async function getPaymentLogisticData(params) {
  const url = `${baseUrl}/logistic-indo/logistics-indo?${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function getPaymentLogisticDataV2(params) {
  const url = `${baseUrl}/logistic-indo/logistics-indo/v2?${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function submitPaymentLogisticData(data) {
  const url = `${baseUrl}/logistic-indo/logistics-indo`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
  });
}


export async function receivedManual(data) {
  const url = `${baseUrl}/logistic-indo/logistics-indo`;
  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
  });
}

export async function submit_input_resi(data) {
  const url = `${baseUrlNewGolang}/logistic-indo/input-resi`;
  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
  });
}

export async function submit_barang_diterima(params) {
  const url = `${baseUrlNewGolang}/logistic-indo/barang-diterima?${params}`;
  return callAPI({
    url,
    method: 'PUT',
    token: tokenize,
  });
}

// Form Actual Price
export async function getFormActualData(params) {
  const url = `${baseUrl}/logistic-indo/form-actual-price?${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function submitFormActualData(data) {
  const url = `${baseUrl}/logistic-indo/form-actual-price`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
  });
}

// Pallet Management
export async function getDataPallet() {
  const url = `${baseUrlGolang}/logistic-indo/palet`;

  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function getDataPalletFilter(query) {
  const url = `${baseUrlGolang}/logistic-indo/search/palet?${query}`;

  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function getDetailPallet(id) {
  const url = `${baseUrlGolang}/logistic-indo/palet/${id}`;

  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function createPallet(data) {
  const url = `${baseUrlGolang}/logistic-indo/palet`;

  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
  });
}

export async function editPallet(data) {
  const url = `${baseUrlGolang}/logistic-indo/palet`;

  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
  });
}

export async function changePallet(data) {
  const url = `${baseUrlGolang}/logistic-indo/change-palet`;
  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
  });
}

export async function changePalletMass(data) {
  const url = `${baseUrlGolang}/logistic-indo/change-palet-mass`;
  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
  });
}

export async function removePallet(param) {
  const url = `${baseUrlGolang}/logistic-indo/palet/${param}`;

  return callAPI({
    url,
    method: 'DELETE',
    token: tokenize,
  });
}

export async function getDetailBarcode(id) {
  const url = `${baseUrlGolang}/logistic-indo/palet/barcode/${id}`;

  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

// AFTER SALES

// Refund Customer
export async function getRefundCustomerData(params) {
  const url = `${baseUrl}/after-sales/refund-customer?${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function submitRefundCustomerData(data) {
  const url = `${baseUrl}/after-sales/refund-customer`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
  });
}

// Refund Oci
export async function getRefundOciData(params) {
  const url = `${baseUrl}/after-sales/refund-oci?${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function updateAccountBank(data) {
  const url = `${baseUrl}/after-sales/refund-approval/update`;
  return callAPI({
    url,
    data,
    method: 'PUT',
    token: tokenize,
  });
}

// export async function submitRefundCustomerData(data) {
//   const url = `${baseUrl}/after-sales/refund-customer`;
//   return callAPI({
//     url,
//     method: "POST",
//     data,
//     token: tokenize,
//   });
// }

// Cancel Order
export async function getCancelOrderData(params) {
  const url = `${baseUrl}/after-sales/canceled-order?${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

// Completed Order
export async function getCompletedOrderData(params) {
  const url = `${baseUrl}/after-sales/completed-order?${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

// Refund Approval
export async function getRefundApprovalData(params) {
  const url = `${baseUrl}/after-sales/refund-approval?${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function submitRefundApporvalData(data) {
  const url = `${baseUrl}/after-sales/refund-approval`;
  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
  });
}

export async function getDetailPoRefund(id) {
  const url = `${baseUrl}/detail-po/${id}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function getDetailProductData(id_so, id_po) {
  const url = `${baseUrl}/detail-product-po/${id_so}/${id_po}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function submitRefundApprovalData(data) {
  const url = `${baseUrl}/after-sales/refund-approval`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
  });
}

// WAREHOUSE
export async function getWarehouseChinaData(limit, page, search) {
  const url = `${
    process.env.REACT_APP_URL_API_GATEWAY
  }/warehouse/whchina?halaman=${page ? page : '1'}&pages=10&search=${
    search ? search : ''
  }`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function getWarehouseIndoData(limit, page, search) {
  const url = `${
    process.env.REACT_APP_URL_API_GATEWAY
  }/warehouse/whindo?halaman=${page ? page : '1'}&pages=10&search=${
    search ? search : ''
  }`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

// CATALOG
export async function getListCatalogProducts(params) {
  const url = `${baseUrlGolang}/catalog/catalog-product?${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function checkLinkData(data) {
  const url = `${baseUrlGolang}/catalog/pool-product/search`;
  return callAPI({
    url,
    data,
    method: 'POST',
    token: tokenize,
  });
}

export async function addNewProduct(data) {
  const url = `${baseUrlGolang}/catalog/pool-product`;
  return callAPI({
    url,
    data,
    method: 'POST',
    token: tokenize,
  });
}

export async function deleteProductCatalog(data) {
  const url = `${baseUrlGolang}/catalog/catalog-product`;
  return callAPI({
    url,
    data,
    method: 'DELETE',
    token: tokenize,
  });
}

export async function getListPoolProducts(params) {
  const url = `${baseUrlGolang}/catalog/pool-product?${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function addCatalogProduct(data) {
  const url = `${baseUrlGolang}/catalog/pool-product`;
  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
  });
}

export async function editPoolProduct(data) {
  const url = `${baseUrlGolang}/catalog/pool-product/edit`;
  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
  });
}

export async function getDetailProduct(data) {
  const url = `https://gateway.ocistok.com/update-gambar`;
  return callAPI({
    url,
    method: 'POST',
    data,
    token: tokenize,
  });
}

// EXPORT DATA
export async function exportNewOrders() {
  const url = `${baseUrl}/purchasing/new-orders/export`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function exportAlreadyPo() {
  const url = `${baseUrl}/purchasing/already-po/export`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function exportAbnormal() {
  const url = `${baseUrl}/purchasing/abnormal/export`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function exportOtwWhChina() {
  const url = `${baseUrl}/purchasing/otw-wh-china/export`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function exportPoPaid() {
  const url = `${baseUrl}/purchasing/po-paid/export`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

// BLOG SECTION

// Blog Posts
export async function getListBlogPost(limit, currentPages) {
  const url = `${baseUrlGolang}/blog/pages/${currentPages}/${limit}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function getListOrganization(params) {
  const url = `${process.env.REACT_APP_URL_API_BLOG}/organization/${params}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function getDetailsBlogPost(id) {
  const url = `${process.env.REACT_APP_URL_API_BLOG}/blog/detail/${id}`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function updateBlogPost(data, id) {
  const url = `${process.env.REACT_APP_URL_API_BLOG}/blog`;
  return callAPI({
    url,
    data,
    method: 'PUT',
    token: tokenize,
  });
}

export async function deleteBlogPost(id) {
  const url = `${process.env.REACT_APP_URL_API_BLOG}/blog/${id}`;
  return callAPI({
    url,
    method: 'DELETE',
    token: tokenize,
  });
}

export async function getListTags() {
  const url = `${process.env.REACT_APP_URL_API_BLOG}/blog/tags`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

// Blog Type

export async function submitBlogPost(data) {
  const url = `${process.env.REACT_APP_URL_API_BLOG}/blog`;
  return callAPI({
    url,
    data,
    method: 'POST',
    token: tokenize,
  });
}

export async function getListTypeBlog() {
  const url = `${process.env.REACT_APP_URL_API_BLOG}/type`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function submitTypeBlog(data) {
  const url = `${process.env.REACT_APP_URL_API_BLOG}/type`;
  return callAPI({
    url,
    data,
    method: 'POST',
    token: tokenize,
  });
}

export async function updateTypeBlog(id, data) {
  const url = `${process.env.REACT_APP_URL_API_BLOG}/type/${id}`;
  return callAPI({
    url,
    data,
    method: 'PUT',
    token: tokenize,
  });
}

export async function deleteTypeBlog(id) {
  const url = `${process.env.REACT_APP_URL_API_BLOG}/type/${id}`;
  return callAPI({
    url,
    method: 'DELETE',
    token: tokenize,
  });
}

// Blog Category
export async function getListCategoryBlog() {
  const url = `${process.env.REACT_APP_URL_API_BLOG}/category`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function submitCategoryBlog(data) {
  const url = `${process.env.REACT_APP_URL_API_BLOG}/category`;
  return callAPI({
    url,
    data,
    method: 'POST',
    token: tokenize,
  });
}

export async function updateCategoryBlog(id, data) {
  const url = `${process.env.REACT_APP_URL_API_BLOG}/category/${id}`;
  return callAPI({
    url,
    data,
    method: 'PUT',
    token: tokenize,
  });
}

export async function deleteCategoryBlog(id) {
  const url = `${process.env.REACT_APP_URL_API_BLOG}/category/${id}`;
  return callAPI({
    url,
    method: 'DELETE',
    token: tokenize,
  });
}

// Blog Tags
export async function getListTag() {
  const url = `${process.env.REACT_APP_URL_API_BLOG}/tag`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function submitTagBlog(data) {
  const url = `${process.env.REACT_APP_URL_API_BLOG}/tag`;
  return callAPI({
    url,
    data,
    method: 'POST',
    token: tokenize,
  });
}

export async function updateTagBlog(id, data) {
  const url = `${process.env.REACT_APP_URL_API_BLOG}/tag/${id}`;
  return callAPI({
    url,
    data,
    method: 'PUT',
    token: tokenize,
  });
}

export async function deleteTagBlog(id) {
  const url = `${process.env.REACT_APP_URL_API_BLOG}/tag/${id}`;
  return callAPI({
    url,
    method: 'DELETE',
    token: tokenize,
  });
}

export async function updateTagsName(id, data) {
  const url = `${process.env.REACT_APP_URL_API_BLOG}/tag/${id}`;
  return callAPI({
    url,
    method: 'PUT',
    data,
    token: tokenize,
  });
}

// CMS
export async function fetchWebConfig() {
  const url = `${baseUrlGolang}/cms/web-config`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function getCategoryDataIndo() {
  const url = `${baseUrlGolang}/cms/categories`; 
  return callAPI({
    url,
    method: 'GET',
    bigInt: true,
    token: tokenize,
  });
}
export async function getCategoryData(params) {
  const url = `${baseUrlGolang}/cms/categories/indo-china?${params}`; 
  return callAPI({
    url,
    method: 'GET',
    bigInt: true,
    token: tokenize,
  });
}

export async function submit_new_category(data) {
  const url = `${baseUrlGolang}/cms/categories`;
  return callAPI({
    url,
    data,
    bigInt: true,
    method: 'POST',
    token: true,
  });
}

export async function update_category(data) {
  const url = `${baseUrlGolang}/cms/categories/main`;
  return callAPI({
    url,
    data,
    method: 'PUT',
    token: true,
  });
}

export async function delete_category(id) {
  const url = `${baseUrlGolang}/cms/categories/${id}`;
  return callAPI({
    url,
    method: 'DELETE',
    token: true,
  });
}

export async function submit_new_subcategory(data) {
  const url = `${baseUrlGolang}/cms/categories/sub`;
  return callAPI({
    url,
    data,
    bigInt: true,
    method: 'POST',
    token: true,
  });
}

export async function update_sub_category(data) {
  const url = `${baseUrlGolang}/cms/categories/sub`;
  return callAPI({
    url,
    data,
    method: 'PUT',
    token: true,
  });
}
export async function delete_sub_category(id) {
  const url = `${baseUrlGolang}/cms/categories/sub/${id}`;
  return callAPI({
    url,
    method: 'DELETE',
    token: true,
  });
}

export async function getCategoryOciLogisticsData() {
  const url = `${baseUrlGolang}/cms/category-oci-logistics`; 
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}
export async function insert_category_oci_logistic(data) {
  const url = `${baseUrlGolang}/cms/category-oci-logistics`;
  return callAPI({
    url,
    data,
    method: 'POST',
    token: tokenize,
  });
}

export async function update_category_oci_logistic(data) {
  const url = `${baseUrlGolang}/cms/category-oci-logistics`;
  return callAPI({
    url,
    data,
    method: 'PUT',
    token: tokenize,
  });
}

export async function delete_category_oci_logistic(id) {
  const url = `${baseUrlGolang}/cms/category-oci-logistics/${id}`;
  return callAPI({
    url,
    method: 'DELETE',
    token: tokenize,
  });
}
export async function getMaterialsData() {
  const url = `${baseUrlGolang}/cms/materials`; 
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function insert_material(data) {
  const url = `${baseUrlGolang}/cms/materials`;
  return callAPI({
    url,
    data,
    method: 'POST',
    token: tokenize,
  });
}

export async function update_material(data) {
  const url = `${baseUrlGolang}/cms/materials`;
  return callAPI({
    url,
    data,
    method: 'PUT',
    token: tokenize,
  });
}

export async function delete_material(id) {
  const url = `${baseUrlGolang}/cms/materials/${id}`;
  return callAPI({
    url,
    method: 'DELETE',
    token: tokenize,
  });
}

export async function getAllBanners() {
  const url = `${baseUrlGolang}/cms/banners`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function add_banner(data) {
  const url = `${baseUrlGolang}/cms/banners`;
  return callAPI({
    url,
    data,
    method: 'POST',
    token: tokenize,
  });
}

export async function update_banner(data) {
  const url = `${baseUrlGolang}/cms/banner`;
  return callAPI({
    url,
    data,
    method: 'PUT',
    token: tokenize,
  });
}

export async function remove_banner(id) {
  const url = `${baseUrlGolang}/cms/banners/${id}`;
  return callAPI({
    url,
    method: 'DELETE',
    token: tokenize,
  });
}

export async function bulk_update_banner(data) {
  const url = `${baseUrlGolang}/cms/banners`;
  return callAPI({
    url,
    data,
    method: 'PUT',
    token: tokenize,
  });
}

export async function getPopUps() {
  const url = `${baseUrlGolang}/cms/pop-up`;
  return callAPI({
    url,
    method: 'GET',
    token: tokenize,
  });
}

export async function update_popup(data) {
  const url = `${baseUrlGolang}/cms/pop-up`;
  return callAPI({
    url,
    data,
    method: 'PUT',
    token: tokenize,
  });
}

export async function sendChatWa(data) {
  const url = `${process.env.REACT_APP_URL_API_WA_CHAT}/send-message`;
  return callAPI({
    url,
    method: "POST",
    token: tokenize,
    data: data,
    allowAll:true
  });
}

export async function sendImageOCS(data) {
  const url = `${process.env.REACT_APP_URL_API_WA_CHAT}/send-message-image`;
  return callAPI({
    url,
    method: "POST",
    token: tokenize,
    data: data,
    allowAll:true
  });
}

export async function getListWa() {
  const url = `${process.env.REACT_APP_URL_API_GET_LIST_WA}/get-list-whatsapp`;
  return callAPI({
    url,
    method: "GET",
    token: tokenize,
    allowAll:true
  });
}

export async function getListDetailWa(params) {
  const url = `${process.env.REACT_APP_URL_API_GET_LIST_WA}/get-list-whatsapp/${params}`;
  return callAPI({
    url,
    method: "GET",
    token: tokenize,
    allowAll:true
  });
}

export async function getReadUnread(id) {
  const url = `${process.env.REACT_APP_URL_API_GET_LIST_WA}/get-list-whatsapp?${id}`;
  return callAPI({
    url,
    method: "GET",
    token: tokenize,
    allowAll:true
  });
}
export async function getSalesOCS() {
  const url = `${process.env.REACT_APP_URL_API_GET_LIST_WA}/get-list-whatsapp/list-sales`;
  return callAPI({
    url,
    method: "GET",
    token: tokenize,
    allowAll:true
  });
}

export async function getListSalesOCS(params) {
  const url = `${process.env.REACT_APP_URL_API_GET_LIST_WA}/get-list-whatsapp?${params}`;
  return callAPI({
    url,
    method: "GET",
    token: tokenize,
    allowAll:true
  });
}

export async function getListCustomerOCS(input) {
  const url = `${process.env.REACT_APP_URL_API_GATEWAY_GOLANG}/customer/obe${
    input ? "/" + input : ""
  }`;
  return callAPI({
    url,
    method: "GET",
    token: tokenize,
    allowAll:true
  });
}

// export async function getListDetailSocketJs(params) {
//   const url = `${process.env.REACT_APP_URL_API_WA_CHAT}/ws/joinRoom/6289668578211?username=friska&userId=4235071533`;
//   return callAPI({
//     url,
//     method: "GET",
//     token: tokenize,
//   });
// }

export async function getRoomsSocket() {
  const url = `${process.env.REACT_APP_URL_API_GET_LIST_WA_SOCKET_ROOMS}/ws/getRooms`;
  return callAPI({
    url,
    method: "GET",
    token: tokenize,
    allowAll:true
  });
}

export async function createRoomsSocket(data) {
  const url = `${process.env.REACT_APP_URL_API_GET_LIST_WA_SOCKET_ROOMS}/ws/createRoom`;
  return callAPI({
    url,
    method: "POST",
    token: tokenize,
    data: data,
    allowAll:true
  });
}

export async function getCurrency() {
  const url = `${baseUrl}/get-currency`;
  return callAPI({
    url,
    method: 'GET',
    token: true,
  });
}

export async function getDataTotalOngkir(data) {
  const url = `${baseUrlGolang}/pre-sales/all-orders/adjustment-address/check-ongkir`;
  return callAPI({
    url,
    data,
    method: 'POST',
    token: tokenize,
  });
}

export async function postAdjustmentAddress(data) {
  const url = `${baseUrlGolang}/pre-sales/all-orders/adjustment-address`;
  return callAPI({
    url,
    data,
    method: 'POST',
    token: tokenize,
    // allowSpv: true,
  });
}