import { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { CircularProgress, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { getListCustomerOCS } from "service/api";
import swal from "sweetalert";
import useDebounce from "helpers/useDebounce";
import { getUser } from "helpers/parseJWT";

const ModalSearch = (props) => {
  const {
    handleNotelpon,
    date,
    setDate,
    fetchData,
    filterSales,
  } = props;
  const [loading, setLoading] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [showTooltip, setShowTooltip] = useState(true);
  const [showCustomer, setShowCustomer] = useState("-");
  const [searchBy, setSearchBy] = useState("search-by-customer");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    maxHeight: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    setDate({ ...date, [name]: value });
  };

  const closeModal = () => {
    setShowCustomer(!showCustomer);
  };

  function censorPhoneNumber(phoneNumber) {
    const censoredDigits = phoneNumber.slice(0, -4) + "xxxx";
    return censoredDigits;
  }

  const fetchDataCustomer = async (input) => {
    setLoading(true);
    const response = await getListCustomerOCS(input);
    setLoading(false);
    if (response?.status === 200) {
      setCustomer(response?.data);
    } else if (response?.status === 403) {
      swal("Oops", `${response?.message}`, "error");
    }
    setLoading(false);
  };

  const debouncedSearchTerm = useDebounce(searchTerm, 1000);

  useEffect(() => {
    if (debouncedSearchTerm) {
      fetchDataCustomer(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);
  
  return (
    <div>
      <Modal
        open={showCustomer}
        onClose={showCustomer}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description">
        <Box sx={{ ...style }}>
          <div className="w-full flex justify-end">
            <IconButton
              aria-label="close"
              onClick={closeModal}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className="flex flex-col">
            <div className="w-full h-full flex justify-start mb-5">
              <h1 className="text-[20px]">
                <b>Cari berdasarkan {showCustomer}</b>
              </h1>
            </div>
            {/* {showCustomer === "Customer" ? (
              <> */}
            
            <div className="bg-gray-100 p-5 rounded-xl flex justify-between items-center mb-4">
              Cari berdasarkan Customer
              <input
                type="radio"
                name="search-by"
                id="search-by"
                value="search-by-customer"
                className="w-6 h-6 border-2"
                checked={searchBy === "search-by-customer"}
                onChange={(e) => {
                  setSearchBy(e.target.value);
                }}
              />
            </div>
            {getUser().role === "admin" && (
              <>
                <div className="bg-gray-100 p-5 rounded-xl flex justify-between items-center mb-4">
                  Cari berdasarkan Sales
                  <input
                    type="radio"
                    name="search-by"
                    id="search-by"
                    value="search-by-sales"
                    className="w-6 h-6 border-2"
                    checked={searchBy === "search-by-sales"}
                    onChange={(e) => setSearchBy(e.target.value)}
                  />
                </div>
              </>
            )}

            {searchBy === "search-by-customer" ? (
              <>
                <div className="bg-gray-100 p-2 mt-3">
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <SearchIcon />
                    </div>
                    <input
                      type="text"
                      onClick={() => setShowTooltip(false)}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Search"
                    />
                  </div>
                </div>
                {showTooltip ? (
                  <div className="w-full flex justify-end">
                    <div className="w-[68%] z-10  inline-block px-3 text-center py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-60 tooltip dark:bg-gray-700">
                      Ketik sesuatu untuk mendapatkan data customer
                      <div className="tooltip-arrow" data-popper-arrow></div>
                    </div>
                  </div>
                ) : null}
                {loading ? (
                  <div className="w-full h-full my-10 flex items-center justify-center text-white ">
                    <CircularProgress size={30} className="text-white" />
                  </div>
                ) : (
                  <div className="mt-3 text-[21px] mx-1 overflow-y-auto h-[30vh]">
                    {customer &&
                      customer.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="flex w-full justify-between hover:bg-blue-50 p-3 cursor-pointer"
                            onClick={() => handleNotelpon(item.phone)}>
                            <h1>{item.label}</h1>
                            <h1>{getUser().isBlur ? censorPhoneNumber(item.phone) : item.phone}</h1>
                          </div>
                        );
                      })}
                  </div>
                )}
              </>
            ) : (
              <div>
                <form
                  onSubmit={fetchData}
                  className="flex space-x-3 items-center">
                  {getUser().role === "admin" && (
                    <>
                      <select
                        onChange={handleChange}
                        name="sales"
                        value={date?.sales}
                        className="block w-full p-2 mb-6 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        <option value="">Select sales</option>
                        {filterSales?.map((sales, index) => (
                          <option key={index} value={sales.user}>
                            {sales.user}
                          </option>
                        ))}
                      </select>
                    </>
                  )}
                </form>
              </div>
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalSearch;
