import {
  CircularProgress,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  TableCell,
  IconButton,
  Collapse,
  Typography,
} from '@mui/material';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { useState, useEffect } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DetailProductTable from './DetailProductTable';
import { exportPackingList, getAllContainerList, getContainerData, upload_file_packing_list } from 'service/api';
import PaginationFilter from '../../General/PaginationFilter';
import UpdateDateModal from './UpdateDateModal';
import EditContainerModal from './EditContainerModal';
import swal from 'sweetalert';
import Cookies from 'js-cookie';
import { getUser } from 'helpers/parseJWT';
import Filters from 'components/General/Filters';
import { useTranslation } from "react-i18next";
import AddDetailContainerModal from './AddDetailContainerModal';

export default function ContainerTable() {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [isLoadingUpload, setIsLoadingUpload] = useState(false);
  const [dataOrder, setDataOrder] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [pageInfo, setPageInfo] = useState({});
  const [update, setUpdate] = useState(false);
  const [editContainerModal, setEditContainerModal] = useState(false);
  const [addDetailContainerModal, setAddDetailContainerModal] = useState(false);
  const [filter, setFilter] = useState({
    container : ""
  })
  const [date, setDate] = useState({
    start: '',
    end: '',
    sorting: 'desc',
  });

  const searchContainerById = (e) => {
    if (e.key === 'Enter') {
      fetchOrderData(limit, page, filter.container);
    }
  };

  useEffect(() => {
    fetchOrderData(limit, page, filter.container);
  }, [update, limit, page]);


  const fetchOrderData = async (limit, page, container) => {
    setIsLoading(true);
    // const params = new URLSearchParams({ limit, page, container, date }).toString();
    

    const idParams = container && container !== '' ? `container=${container}&` : '';
    const startParams = date.start !== '' ? `start=${date.start}&` : '';
    const endParams = date.end !== '' ? `end=${date.end}&` : '';
    const sortingParams = date.sorting !== '' ? `sort=${date.sorting}` : '';
    
    const params =
      `page=${page}&limit=${limit}&` +
      idParams +
      startParams +
      endParams +
      sortingParams;

    const data = await getContainerData(params);
    if (data?.status === 200) {
      setDataOrder(data.data.data);
      setPageInfo({
        dataInPage: data.data.dataInPage,
        totalData: data.data.totalData,
        totalPage: data.data.totalPage,
      });
    }
    setIsLoading(false);
  };



  const send_EmailPackingList = async (containerNumber) => {
    setIsLoadingExport(containerNumber);
    const data = await exportPackingList(containerNumber);
    if (data?.status === 200) {
      swal(data?.message ?? "File has been sent, please check your email!");
    } else {
      swal(data?.message ?? "Fail to send file to email")
    }
    setIsLoadingExport(false);
  };

  const submitFile = (e, containerNumber) => {
    let formData = new FormData();
    formData.append('document', e.target.files[0]);
    // formData.append('secret', 'ocijaya');
    fetch(`${process.env.REACT_APP_URL_API_DOCUMENT_UPLOAD}`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${Cookies.get('oms_token')}`,
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          uploadFileDataContainer(containerNumber, data.file);
          e.target.value = ''
        } else {
          swal('Oops', data.message, 'error');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const uploadFileDataContainer = async (containerNumber, link) => {
    setIsLoadingUpload(containerNumber);

    const payloadDataContainer = {
      container_number: containerNumber,
      link: link,
    }

    const data = await upload_file_packing_list(JSON.stringify(payloadDataContainer));
    if (data?.status === 200) {
      swal(data?.message ?? "File has been sent, please check your email!");
      setUpdate(prev => !prev)
    } else {
      swal(data?.message ?? "Fail to send file to email")
    }
    setIsLoadingUpload(false);
  };
  
  const HandleExportPackingList = async (containerNumber) => {
    const URL_DOWNLOAD = `${process.env.REACT_APP_URL_API_GATEWAY_GOLANG}/export-packing-list-app/${containerNumber}`;
    const tokenCookies = Cookies.get('oms_token');

    setIsLoadingExport(containerNumber);

    // eslint-disable-next-line no-unused-vars
    const response = await fetch(URL_DOWNLOAD, {
      method: 'GET',
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${tokenCookies}`,
      },
    }).then((response) => {
        if(response.status === 404 && response.statusText === "Not Found") {
          swal('Sorry. data container not found.')
          return false;
        } else {
          return response.blob();
        }
      }).then((data) => {
        if(data) {   
          var a = document.createElement('a');
          a.href = window.URL.createObjectURL(data);
          a.download = 'Container-' + containerNumber;
          a.click();
      }
      }).catch((err) => {
        console.log(err.message)
      });

      setIsLoadingExport(false);
  };

  const searchOrderByFilters = () => {
      setLimit(5)
      setPage(1)
      fetchOrderData(5, 1, filter.container);
  };
  
  const renderLoading = isLoading ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'start',
        padding: '10px',
        borderRadius: 2,
        backgroundColor: 'white',
        marginBottom: 1,
      }}>
      <div className='flex space-x-3 items-center w-full bg-blue-100 p-3 rounded-md'>
        <CircularProgress size={20} />
        <p className='text-gray-500 text-sm '>Updating data ...</p>
      </div>
    </Box>
  ) : null;

  const Row = ({ rowData }) => {
    const [open, setOpen] = useState(false);
    return (
      <>
        <TableRow>
          <TableCell align='center'>{rowData.tanggal}</TableCell>
          <TableCell align='center'>
            <div className='flex flex-col items-center space-y-3'>
              <p className='font-semibold'>{rowData.container_number}</p>
              <div className='text-center'>
                <IconButton
                  aria-label='expand row'
                  size='small'
                  onClick={() => {
                    setOpen(!open);
                  }}>
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
                <span className='text-xs'>Details</span>
              </div>
            </div>
          </TableCell>
          <TableCell align='center'>{rowData.forwarder}-{rowData.marking_code}</TableCell>
          <TableCell align='center'>{(rowData.volume / 1000000).toFixed(2)} M<sup>3</sup></TableCell>
          <TableCell align='center'>{rowData.container_price.toLocaleString('en')}</TableCell>
          <TableCell align='center'>
            {rowData.container_cbm}
          </TableCell>
          {/* <TableCell align='center'>
            IDR {rowData.shipping.toLocaleString('id-ID')}
          </TableCell> */}
          <TableCell align='center'>
            {rowData.shipname_voyage ?? '-'}
          </TableCell>
          {/* <TableCell align='center'>
            <div className='flex flex-col justify-center items-center'>
              <p>{rowData.eta}</p>
              <UpdateDateModal
                estimationFor='eta'
                title={rowData.eta ? 'Edit' : 'Add'}
                setUpdate={setUpdate}
                container={rowData.container_number}
              />
            </div>
          </TableCell>
          <TableCell align='center'>
            <div className='flex flex-col justify-center items-center'>
              <p>{rowData.etd}</p>
              <UpdateDateModal
                estimationFor='etd'
                title={rowData.etd ? 'Edit' : 'Add'}
                setUpdate={setUpdate}
                container={rowData.container_number}
              />
            </div>
          </TableCell> */}
          {/* <TableCell align='center'>
            IDR {rowData.cogs.toLocaleString('id-ID')}
          </TableCell> */}
          {/* <TableCell align='center'>
            IDR {rowData.order_value.toLocaleString('id-ID')}
          </TableCell> */}
          <TableCell align='center'>{rowData.status}</TableCell>
          <TableCell align='center'>
            {!rowData.packing_list ? '-' : 
              <a href={rowData.packing_list} target="_blank">
                <InsertDriveFileOutlinedIcon />
              </a>
            }
          </TableCell>
          {/* <TableCell align='center'>
            IDR {rowData.profit.toLocaleString('id-ID')}
          </TableCell> */}
          <TableCell align='center'>
            <div className='flex flex-col gap-2'>
              {getUser().roles?.includes('admin') &&
                <button
                  className="bg-green-300 hover:bg-green-400 text-white px-3 py-1 rounded-md"
                  onClick={() => setEditContainerModal(rowData.container_number)}
                >
                  {t('Edit')}
                </button>
              }
              {/* <a className={`${isLoadingExport === rowData.container_number ? "bg-gray-300" : "bg-yellow-600 hover:bg-yellow-700"}  text-white px-3 py-1 rounded-md whitespace-nowrap`} 
                href={`${process.env.REACT_APP_URL_API_GATEWAY_GOLANG}/export-packing-list-app/${rowData.container_number}`}>
                Export (out)
              </a> */}
              <button
                className={`${isLoadingExport === rowData.container_number ? "bg-gray-300" : "bg-blue-600 hover:bg-blue-700"}  text-white px-3 py-1 rounded-md whitespace-nowrap`}
                onClick={() => !isLoadingExport && send_EmailPackingList(rowData.container_number)}
              >
                Container Packing List
              </button>
              <label className={`${isLoadingUpload === rowData.container_number ? "bg-gray-300" : "bg-blue-600 hover:bg-blue-700"}  text-white px-3 py-1 rounded-md whitespace-nowrap`}>
                {t('Upload')}
                <input
                  accept="image/*"
                  id="icon-button-file"
                  onChange={(e) => submitFile(e, rowData.container_number)}
                  type="file"
                  hidden
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-blue"
                />
              </label>
              {(['rifky', 'aris', 'kevin', 'ningpei'].includes(getUser().user.toLowerCase()) || getUser().division.includes('updater')) &&
                <button
                  className="bg-blue-600 hover:bg-blue-700 text-white px-3 py-1 rounded-md whitespace-nowrap"
                  onClick={() => setAddDetailContainerModal(rowData.container_number)}
                >
                  {t('Add Detail')}
                </button>
              }
              {/* <button
                className={`${isLoadingUpload === rowData.container_number ? "bg-gray-300" : "bg-blue-600 hover:bg-blue-700"}  text-white px-3 py-1 rounded-md whitespace-nowrap`}
                onClick={() => !isLoadingUpload && submitFile(rowData.container_number)}
              >
                Upload
              </button> */}
            </div>
            {editContainerModal === rowData.container_number &&
              <EditContainerModal
              // open={editContainerModal === rowData.container_number}
              close={() => setEditContainerModal(false)}
              containerNumber={rowData.container_number}
              container_price={rowData.container_price}
              container_cbm={rowData.container_cbm}
              setUpdate={setUpdate}
              />
            }
            {addDetailContainerModal === rowData.container_number &&
              <AddDetailContainerModal
              // open={editContainerModal === rowData.container_number}
              close={() => setAddDetailContainerModal(false)}
              containerNumber={rowData.container_number}
              container_price={rowData.container_price}
              container_cbm={rowData.container_cbm}
              setUpdate={setUpdate}
              scanned_cbm={(rowData.volume / 1000000).toFixed(2)}
              />
            }
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
            <Collapse in={open} timeout='auto' unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant='h6' gutterBottom component='div'>
                  Detail Container
                </Typography>
                <DetailProductTable
                  dataContainer={rowData.detail}
                  totalVolume={(rowData.volume / 1000000).toFixed(4)}
                  containerNumber={rowData.container_number}
                  setUpdate={setUpdate}
         
                />
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  return (
    <>
      {renderLoading}
      <div className='flex items-center justify-between bg-white p-2 px-3 rounded-md my-2'>
        <div className='flex items-center gap-x-3 text-sm font-semibold'>
          {pageInfo?.dataInPage
            ? `Showing ${pageInfo?.dataInPage} data of ${pageInfo?.totalData}`
            : null}

            
          <div className="filter-idso ml-3">
            Search:
            <input
              type="text"
              placeholder="search container number"
              className="border rounded-md px-2 py-1 ml-1"
              onChange={(e) => setFilter({...filter, "container" : e.target.value})}
              onKeyDown={searchContainerById}
            />
          </div>
          

          <Filters
            filterToko={false}
            idSo={filter.container}
            date={date}
            setDate={setDate}
            setUpdate={setUpdate}
            actionSubmit={searchOrderByFilters}
          />
        </div>
        <PaginationFilter
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          totalPage={pageInfo.totalPage}
        />
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell align='center'>Tanggal Input</TableCell>
              <TableCell align='center'>{t('Container Number')}</TableCell>
              <TableCell align='center'>{t('Marking Code')}</TableCell>
              <TableCell align='center'>{t('Scanned Volume')}</TableCell>
              <TableCell align='center'>{t('Container Cost')}</TableCell>
              <TableCell align='center'>{t('Input Volume')}</TableCell>
              {/* <TableCell align='center'>Estimasi Ongkir</TableCell> */}
              <TableCell align='center'>{t('Ship Name')}</TableCell>
              {/* <TableCell align='center'>{t('ETA')}</TableCell>
              <TableCell align='center'>{t('ETD')}</TableCell> */}
              {/* <TableCell align='center'>Total COGS Value</TableCell> */}
              {/* <TableCell align='center'>Total Order Value</TableCell> */}
              <TableCell align='center'>{t('Container Status')}</TableCell>
              <TableCell align='center'>Packing List</TableCell>
              {/* <TableCell align='center'>Profit</TableCell> */}
              <TableCell align='center'>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataOrder.length > 0 ? (
              <>
                {dataOrder.map((row, id) => (
                  <Row rowData={row} key={id} />
                ))}

                {dataOrder.length <= 3 && (
                  <TableRow>
                    <TableCell
                      colSpan={8}
                      align='center'
                      style={{ height: '50vh' }}></TableCell>
                  </TableRow>
                )}
              </>
            ) : (
              <>
                <TableRow>
                  <TableCell
                    colSpan={8}
                    align='center'
                    style={{ height: '70vh' }}>
                    No data available
                  </TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

const FilterContainer = () => {
  return (
    <div className='ml-3'>
      <div>
        <label htmlFor="searchConteiner">Container</label>
        <input type="text" id="searchConteiner" 
          className='ml-2 border px-2 py-1'
        />
      </div>
    </div>
  )
}