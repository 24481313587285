import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export default function ResendTable({ dataTable, checkRow, setCheckRow }) {
  const setProductQty = (e, id) => {
    const { value } = e.target;
    const values = [...dataTable];
    
    setCheckRow(prev => prev.map((row, index) => {
      if (row.id_abnormal === values[id].id_abnormal) {
          if (row.original_qty >= ~~value && 0 < ~~value) {
            row.resend_qty = ~~value
          }
        }
        return row
      })
    )
  };
  return (
    <TableContainer
      component={Paper}
      sx={{ maxHeight: 300 }}
      className="variant-scroll tabele-scroll"
    >
      <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>PIC</TableCell>
            <TableCell>SKU</TableCell>
            <TableCell>Product</TableCell>
            <TableCell>Variant</TableCell>
            <TableCell align="center">Original Quantity</TableCell>
            <TableCell align="center">Quantity</TableCell>
            <TableCell align="center">Price/pcs (RMB)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataTable.map((row, id) => (
            <TableRow
              key={id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>
                <img src={row.image} alt="product" />
              </TableCell>
              <TableCell>
                <p className="font-semibold">{row.sku}</p>
              </TableCell>
              <TableCell>
                <p className="line-clamp-1 w-24">{row.name}</p>
              </TableCell>
              <TableCell>
                <p className="font-semibold">{row.variant}</p>
              </TableCell>
              <TableCell align="center">
                <p className="font-semibold">{row.qty}</p>
              </TableCell>
              <TableCell align="center">
                <input type="number" name={`product_qty-${id}`} id={`product_qty-${id}`} 
                  value={row.resend_qty} 
                  className="w-20 border-2 rounded px-2 w-full text-center" 
                  onChange={(e) => setProductQty(e, id)} 
                />
              </TableCell>
              <TableCell align="center">
                <p>{row.highestPrice}</p>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
