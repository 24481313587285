import { forwardRef, useEffect, useState } from "react";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import {
  IconButton,
  Tooltip,
  Chip,
  CircularProgress,
  Typography,
  Fade,
  Modal,
  Backdrop,
} from "@mui/material";
import GppBadIcon from "@mui/icons-material/GppBad";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";
import MaterialTable from "material-table";
import tableIcons from "helpers/materialTableIcons";
import { FormatDate } from "helpers/ConvertTime";
import { MuiThemeProvider } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { getUser } from "helpers/parseJWT";
import moment from "moment";
import { AddCircle } from "@mui/icons-material";
import ModalAddOmsAccountUser from './ModalAddOmsAccountUser'
import { get_list_user_attribute, set_active_oms_accounts_list } from "service/api";
import swal from "sweetalert";
import ModalDetailOmsAccountUser from "./ModalDetailOmsAccountUser";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxHeight: '90vh',
  overflowY: 'auto',
  bgcolor: "white",
  border: "1px solid lightgray",
  boxShadow: 24,
  borderRadius: "5px",
  p: 4,
};

const OmsAccountsTable = ({
  dataNewCustomer,
  listSalesData,
  setChangeData,
  isLoading,
  isLimit,
}) => {
  const [showTeamMember, setShowTeamMember] = useState(false);
  const [teamMember, setTeamMember] = useState(null);
  const [selectedRow, setSelectedRow] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModalAddUser, setShowModalAddUser] = useState(false);
  const [showModalEditUser, setShowModalEditUser] = useState(false);
  const [showModalDetailUser, setShowModalDetailUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [listUserAttribute, setListUserAttribute] = useState(null);

  useEffect(() => {
    if (!isLoading) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [isLoading]);
  
  const renderLoading = loading ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "start",
        padding: "10px",
        borderRadius: 2,
        backgroundColor: "white",
        marginBottom: 1,
      }}>
      <div className="flex space-x-3 items-center w-full bg-blue-100 p-4 rounded-md">
        <CircularProgress size={20} />
        <p className="text-gray-500 text-sm ">Updating data ...</p>
      </div>
    </Box>
  ) : null;

  const selectRowsData = (evt, data) => {
    setSelectedRow(data);
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#4caf50",
      },
      secondary: {
        main: "#4caf50",
      },
    },
  });

  const NewTitle = () => {
    return <div className="flex items-center gap-2 text-white font-bold px-3 py-1 bg-blue-500 hover:bg-blue-600 rounded-lg cursor-pointer" onClick={set_ShowModalAddUser}>
      <AddCircle fontSize="small" /> New Account
    </div>;
  };

  const set_ShowModalAddUser = async () => {
    const response = await get_list_user_attribute()
    if (response.status === 200) {
      console.log(response)
      setShowModalAddUser(true)
      setListUserAttribute(response.data)
    } else {
      swal('Fail', response.message, 'error')
    }
  }

  const DetailsMemberButton = ({rowData}) => {
    return <div className="text-xs py-1 px-3 bg-green-400 text-white text-center rounded-md font-bold" onClick={() => set_showTeamMember(rowData)}>
    Details
  </div>
  }

  const set_showTeamMember = (rowData) => {
    setShowTeamMember(true)
    setTeamMember(rowData.team_member)
  }

  const close_showTeamMember = () => {
    setShowTeamMember(false)
    setTeamMember(null)
  }

  const createNewOmsAccountUser = () => {
    const payload = {
      user: "joko",
      nama: "joko susilo",
      password: "testing",
      jabatan: "supervisor",
      department: "obe",
      foto: "gambar.jpg",
      atasan: "ozy",
      telepon: "088123467" 
    }
  }

  const openModalDetailUser = async (user) => {
    const response = await get_list_user_attribute()
    if (response.status === 200) {
      setSelectedUser(user)
      setListUserAttribute(response.data)
      setShowModalDetailUser(true)
    } else {
      swal('Fail', response.message, 'error')
    }
  }

  const openModalUpdateUser = async (user) => {
    const response = await get_list_user_attribute()
    if (response.status === 200) {
      setSelectedUser(user)
      setListUserAttribute(response.data)
      setShowModalEditUser(true)
    } else {
      swal('Fail', response.message, 'error')
    }
  }

  const handleChangeStatusActive = (e, user) => {
    const check = e.target.checked
    swal({
      title: 'Are you sure?',
      text: ``,
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then( async (willChange) => {
      if (willChange) {
        const response = await set_active_oms_accounts_list(user);
        if (response.status === 200) {
          e.target.checked = check
        } else {
          e.target.checked = !check
        }
      } else {
        e.target.checked = !check
      }
    });

  }

  return (
    <>
      {renderLoading}
      <MuiThemeProvider theme={theme}>
        <MaterialTable
          localization={{
            toolbar: {
              searchPlaceholder: "Search Table",
            },
          }}
          isLoading={loading}
          icons={tableIcons}
          title={<NewTitle />}
          columns={[
            {
              title: "Full Name",
              field: "nama",
              render: (rowData) => (
                <div className="text-xs">{rowData.nama}</div>
              ),
            },
            {
              title: "Username",
              field: "user",
              render: (rowData) => (
                <div className="text-xs">{rowData.user}</div>
              ),
            },
            {
              title: "Role",
              field: "role",
              render: (rowData) => (
                <div className="text-xs">{rowData.jabatan}</div>
              ),
            },
            {
              title: "Team Member",
              field: "team_member",
              align: "center",
              render: (rowData) => !rowData.team_member ? '-' : <DetailsMemberButton rowData={rowData} />,
            },
            {
              title: "Created At",
              field: "created_at",
              align: "center",
              render: (rowData) => (
                <div className="text-xs">{rowData.tanggal}</div>
              ),
            },
            {
              title: "last_updated",
              field: "last_updated",
              align: "center",
              render: (rowData) => (
                <div className="text-xs">
                  {rowData.tanggal}
                  <br />
                  <span className="text-gray-500">
                    {moment(rowData.tanggal).fromNow()}
                  </span>
                </div>
              ),
            },
            {
              title: "Is Active",
              field: "is_active",
              align: "center",
              render: (rowData) => (
                <div class="oci-switch container">
                  <input type="checkbox" class="checkbox" id={`checkbox${rowData.user}`} defaultChecked={rowData.is_active} onChange={(e) => handleChangeStatusActive(e, rowData.user)} disabled={getUser().user !== 'Rifky'} />
                  <label class="switch" for={`checkbox${rowData.user}`}>
                    <span class="slider"></span>
                  </label>
                </div>
              ),
            },
            {
              title: "Action",
              field: "action",
              align: "center",
              sorting: false,
              render: (rowData) => (
                <div className="space-y-2">
                  <div className="text-xs py-1 px-3 bg-blue-300 hover:bg-blue-400 text-white text-center rounded-md font-bold cursor-pointer" onClick={() => openModalDetailUser(rowData)}>
                    Details
                  </div>
                  <div className="text-xs py-1 px-3 bg-blue-300 hover:bg-blue-400 text-white text-center rounded-md font-bold cursor-pointer" onClick={() => openModalUpdateUser(rowData)}>
                    Edit
                  </div>
                </div>
              ),
            },
          ]}
          data={dataNewCustomer}
          options={{
            // exportButton: true,
            pageSizeOptions: [],
            pageSize: Number(isLimit),
            searchFieldPlaceholder: "Search Table",
            isLoading: true,
            //     selection: ['admin'].some((i) => getUser()?.roles?.includes(i)),
            //     selectionProps: (rowData) => {
            //       const checked = rowData.email === null ? {checked: false} : {};
            //       return {
            //       disabled: rowData.email === null,
            //        ...checked
            //       }
            //    }
          }}
          //   actions={['admin'].some((i) => getUser()?.roles?.includes(i)) && [
          //     {
          //       tooltip: 'Add Sales',
          //       icon: tableIcons.AddCircle,
          //       onClick: (evt, data) => selectRowsData(evt, data)
          //     }
          //   ]}
        />
      </MuiThemeProvider>
      

      {/* MODAL DETAIL TEAM MEMBER */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showTeamMember}
        onClose={close_showTeamMember}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showTeamMember}>
          <Box sx={style}>
            <div className="flex justify-end -mt-5">
              <IconButton onClick={close_showTeamMember} style={{ textAlign: "right" }}>
                <CloseIcon />
              </IconButton>
            </div>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              TEAM MEMBERS
            </Typography>
            <div className="member-list grid grid-cols-2 gap-3 mt-2">
              {teamMember?.map((member, index) =>
                <div key={member}>- {member}</div>
              )}
            </div>
          </Box>
        </Fade>
      </Modal>

      {showModalAddUser &&
        <ModalAddOmsAccountUser
          showModal={showModalAddUser}
          setShowModal={setShowModalAddUser}
          listUserAttribute={listUserAttribute}
          setChangeData={setChangeData}
        />
      }

      {showModalEditUser &&
        <ModalAddOmsAccountUser
          selectedUser={selectedUser}
          showModal={showModalEditUser}
          setShowModal={setShowModalEditUser}
          listUserAttribute={listUserAttribute}
          setChangeData={setChangeData}
          isEditing
        />
      }

      {showModalDetailUser &&
        <ModalDetailOmsAccountUser
          selectedUser={selectedUser}
          showModal={showModalDetailUser}
          setShowModal={setShowModalDetailUser}
          listUserAttribute={listUserAttribute}
        />
      }
    </>
  );
};

export default OmsAccountsTable;
