import { Box, Button, Modal, Pagination, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import { getVoucher } from 'service/marketing';
import CloseIcon from '@mui/icons-material/Close';
import Cookies from 'js-cookie';
import swal from 'sweetalert';
import PaginationFilter from 'components/General/PaginationFilter';
import CheckIcon from '@mui/icons-material/Check';

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 'Frozen yoghurt', 'Frozen yoghurt', 'TEST'),
  createData('Frozen yoghurt', 'Frozen yoghurt', 'Frozen yoghurt', 'Frozen yoghurt'),
  createData('Frozen yoghurt', 'Frozen yoghurt', 'Frozen yoghurt', 'Frozen yoghurt'),
  createData('Frozen yoghurt', 'Frozen yoghurt', 'Frozen yoghurt', 'Frozen yoghurt'),
  createData('Frozen yoghurt', 'Frozen yoghurt', 'Frozen yoghurt', 'Frozen yoghurt'),
];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  maxHeight: 600,
  bgcolor: 'background.paper',
  border: '1px solid lightgray',
  boxShadow: 24,
  borderRadius: '5px',
};

const Voucher = () => {
  const [voucher, setVoucher] = useState(null)
  const [open, setOpen] = useState(false)
  const [inputData, setInputData] = useState({})
  const [fileData, setFileData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState({
    content: "",
    search: ""
  })

  const handleClose = () => {
    setOpen(!open)
    setInputData({})
    setFileData(null)
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleUploadFile = (e) => {
    const { value, files } = e.target
    const fileInput = files[0];
    if (fileInput?.size > 2 * 1024 * 1024) {
      swal.fire({
        icon: "error",
        title: "Folder terlalu besar",
      });
    } else {
      setFileData(fileInput)
    }
  }

  const handleFilter = (e) => {
    const { name, value } = e.target;
    setFilter((prev) => {
      return { ...prev, [name]: value };
    });
  }

  const handleSubmit = async () => {
    try {
      setLoading(true);
      let formData = new FormData();
      formData.append('file', fileData);
      formData.append('event', inputData.event);
      formData.append('expired', inputData.expired);

      const response = await fetch(
        `${process.env.REACT_APP_URL_API_GATEWAY_GOLANG}/marketing/voucher-event`,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: `Bearer ${Cookies.get('oms_token')}`,
          }
        }
      );

      const data = await response.json();

      if (data?.status === 200) {
        swal('Success', `Successfully added voucher`, 'success');
        setOpen(false);
        setInputData({});
        setFileData(null);
      } else {
        swal('Oops', 'Error adding voucher', 'error');
      }
    } catch (error) {
      console.error('There was an error!', error);
      swal('Error', 'There was an error!', 'error');
    } finally {
      setLoading(false);
    }
  }

  const getDataVoucher = async () => {
    setLoading(true)
    const pageData = page !== '' ? `page=${page}&` : '';
    const limiData = limit !== '' ? `limit=${limit}&` : '';
    const contentData = filter.content !== '' || filter.search !== '' ? `${filter.content}=${filter.search}&` : '';

    let params = '';

    if (contentData) {
        params = contentData;
    } else {
        params = pageData + limiData;
    }

    const response = await getVoucher(params);
    if (response?.status === 200) {
      setVoucher(response?.data)
    }
    setLoading(false)
  };

  useEffect(() => {
    getDataVoucher()
  }, [page, limit])

  return (
    <div>
      <div className='bg-white my-2 p-2 rounded-sm flex justify-between items-center'>
        <div className='flex gap-5 items-center'>
          <h1 className='font-bold'>Filter</h1>
          <div className='flex gap-2'>
            <select
              name="content"
              value={filter?.content}
              onChange={handleFilter}
              className="w-32 p-2 text-xs rounded-md focus:outline-blue border font-bold"
            >
              <option value="">Pilih</option>
              <option value="email">Email</option>
              <option value="event">Event</option>
              <option value="telepon">Telepon</option>
            </select>
            <input
              onChange={handleFilter}
              type="text"
              value={filter?.search}
              name="search"
              className="p-3 rounded-md text-xs focus:outline-blue border"
            />
            <button
              onClick={filter?.content?.trim() === "" || filter?.search?.trim() === "" ? null : getDataVoucher }
              type="submit"
              className={` ${filter?.content?.trim() === "" || filter?.search?.trim() === "" ? "bg-[#b7bdc7]" : "bg-blue-300"} flex justify-center items-center  text-white w-40 py-1 rounded-md`}>Filter
            </button>
          </div>
        </div>
        <button onClick={() => setOpen(true)} className='bg-blue-500 text-white rounded-md p-2'><AddIcon />Add Voucher</button>
      </div>
      <div className='bg-white h-full'>
        {voucher?.data?.length > 0 ?
          <>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: 'bold' }}>Event</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Kode Diskon</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Nama</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Email</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Telepon</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Dipakai</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Tanggal</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Expired</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>

                  {voucher?.data?.map((row, index) => (
                    <TableRow
                      key={index}
                    >
                      <TableCell >{row.event}</TableCell>
                      <TableCell >{row.kode_diskon}</TableCell>
                      <TableCell >{row.nama}</TableCell>
                      <TableCell >{row.email}</TableCell>
                      <TableCell >{row.telepon}</TableCell>
                      <TableCell >{row.is_use ? <><CheckIcon /></> : <><CloseIcon /></>}</TableCell>
                      <TableCell >{row.tanggal}</TableCell>
                      <TableCell >{row.expired}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
          :
          <div className='flex justify-center py-14 items-center w-full flex-col'>
            <img src="/oci-data-produk-tidakada.png" />
            <p className='bg-white text-center font-poppins font-semibold text-gray-700 '>
              Data tidak ditemukan
            </p>
          </div>
        }
        <div className='py-4 flex justify-end mx-2'>
          <PaginationFilter
            page={page}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            totalPage={voucher?.total}
          />
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className='p-4'>
            <div className='flex justify-between'>
              <h1 className='font-bold'>Upload Voucher</h1>
              <div className='rounded-full cursor-pointer p-1' onClick={handleClose}><CloseIcon /></div>
            </div>
            <div className='flex flex-col items-start'>
              <div className='flex w-full'>
                <div className='flex gap-4'>
                  <div>
                    <label htmlFor="uploadFile1"
                      className="flex bg-gray-700 hover:bg-gray-600 text-white text-base px-5 py-3 outline-none rounded cursor-pointer font-[sans-serif]">
                      <svg xmlns="http://www.w3.org/2000/svg" className="w-6 mr-2 inline" viewBox="0 0 32 32">
                        <path
                          d="M23.75 11.044a7.99 7.99 0 0 0-15.5-.009A8 8 0 0 0 9 27h3a1 1 0 0 0 0-2H9a6 6 0 0 1-.035-12 1.038 1.038 0 0 0 1.1-.854 5.991 5.991 0 0 1 11.862 0A1.08 1.08 0 0 0 23 13a6 6 0 0 1 0 12h-3a1 1 0 0 0 0 2h3a8 8 0 0 0 .75-15.956z"
                          fill="#fff" />
                        <path
                          d="M20.293 19.707a1 1 0 0 0 1.414-1.414l-5-5a1 1 0 0 0-1.414 0l-5 5a1 1 0 0 0 1.414 1.414L15 16.414V29a1 1 0 0 0 2 0V16.414z"
                          fill="#fff" />
                      </svg>
                      Upload
                      <input type="file" onChange={handleUploadFile} id="uploadFile1" className="hidden" accept=".xls,.xlsx" />
                    </label>
                  </div>
                  {fileData === null ? null :
                    <div className='flex items-end'>
                      <div className='bg-white flex justify-between relative border border-white rounded-md'>
                        <div className='flex'>
                          <img className='w-12 h-12 object-cover' src={`${process.env.REACT_APP_URL_FOTO_STORAGE}/xls (1) 1.png`} />
                        </div>
                        <div onClick={() => setFileData(null)} className='text-red-500 flex justify-center items-center absolute bg-red-500 rounded-full w-4 h-4 -right-1 -top-1'>
                          <p className='cursor-pointer text-xs text-white font-medium'>X</p>
                        </div>
                      </div>
                      <p className='text-xs'>{fileData?.name}</p>
                    </div>}
                </div>
              </div>
              <div className='w-full my-3 space-y-2'>
                <div>
                  <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nama Event</label>
                  <input onChange={handleChange} name='event' value={inputData?.event} type="text" id="first_name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Nama Event" required />
                </div>
                <div>
                  <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Expired Voucher</label>
                  <input onChange={handleChange} name='expired' value={inputData?.expired} type="date" id="first_name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Expired Voucher" required />
                </div>
              </div>
              <div className='w-full'>
                <button onClick={loading === true || inputData?.expired === undefined || inputData?.event === undefined || fileData === null ? null : handleSubmit} className={`${loading === true || inputData.expired === undefined || inputData.event === undefined || fileData === null ? 'bg-gray-300' : 'bg-green-500'}  text-white rounded-md w-full p-2`}>Submit</button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  )
}

export default Voucher