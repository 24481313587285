import React from "react";
import { IconButton } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EditPhoneNumber from "./EditPhoneNumber";
import { styled } from "@mui/material/styles";
import { getUser } from "helpers/parseJWT";
import { Link } from "react-router-dom";
import { PhoneChiperText } from "helpers/PhoneChiperText";

const WhatsAppDetail = ({
  phoneNumber,
  email,
  setChangeData,
  setLoading,
  id,
}) => {
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  function removePlus(phoneNumber) {
    const sendNumber = phoneNumber.replace(/\+/g, '');
    return sendNumber;
  }

  const data = phoneNumber;
  const modifiedPhoneNumber = data.replace(/^0/, "62");
  const removePlusData = removePlus(modifiedPhoneNumber)
  const linkToPhone = `/management-sales/ocs?number=${PhoneChiperText(removePlusData)}`;

  return (
    <>
      <HtmlTooltip
        title={
          <>
            <div className="cursor-pointer text-white flex space-x-3">
              <EditPhoneNumber
                email={email}
                setChangeData={setChangeData}
                setLoading={setLoading}
                id={id}
              />

              {phoneNumber && (
                <Link to={linkToPhone}>
                  Chat
                </Link>
              )}
            </div>
          </>
        }
        style={{ fontSize: 5 }}>
        <IconButton>
          <WhatsAppIcon className={`${!phoneNumber ? 'text-gray-300' : 'text-green-500'}`} />
        </IconButton>
      </HtmlTooltip>
    </>
  );
};

export default WhatsAppDetail;
