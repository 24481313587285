import React from "react";

const OrderStatusBadge = ({status}) => {
  return (
    <span
      className={`rounded-xl w-28 px-3 py-1 text-center capitalize whitespace-nowrap ring text-sm text-white
                ${
                  status.toLowerCase() === 'in progress'
                    ? 'bg-yellow-500 ring-yellow-300'
                    : (status.toLowerCase() === "forbidden" || status.toLowerCase() === "forbiden") 
                    ? "bg-red-500" : 'bg-green-500 ring-green-300'
                }`}>
      {status}
    </span>
  )
};

export default OrderStatusBadge;
