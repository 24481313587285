import { useEffect, useState } from "react";
import {
  CircularProgress,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  TableCell,
  IconButton,
  Collapse,
  Typography,
  Modal,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { delete_scan_carton, get_scanned_cartons, insert_scan_carton, submit_draft_input_container } from "service/api";
import swal from "sweetalert";
import { Close } from "@mui/icons-material";
import ModalScanCartons from "./ModalScanCartons";
import ModalWarningUnScannedCarton from "./ModalWarningUnScannedCarton";
import { getUser } from "helpers/parseJWT";
import { useTranslation } from "react-i18next";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  maxHeight: '90vh',
  bgcolor: 'background.paper',
  border: '1px solid lightgray',
  boxShadow: 24,
  borderRadius: '5px',
  p: 4,
};

export default function ModalInputContainer({
    showModalInputContainer,
    setShowModalInputContainer,
    inputContainerData,
    setInputContainerData,
    openModalScanCarton,
    setOpenModalScanCarton,
    tempScannedData,
    setTempScannedData,
    cartonIdInput,
    setCartonIdInput,
    setUpdate,
    closeModalScanCartons,
    scannedData,
    setScannedData,
    inputs,
    setInputs,
    getScannedCartons
}) {
  // const [openModalScanCarton, setOpenModalScanCarton] = useState(false)
  const [selectAllCartonIds, setSelectAllCartonIds] = useState(false)
  const [bulkTempScannedData, setBulkTempScannedData] = useState([])
  const [showWarningModal, setShowWarningModal] = useState(false)
  const [showInsertUnscannedDetails, setShowInsertUnscannedDetails] = useState(false)
  const [validForm, setValidForm] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { t } = useTranslation()
  
  // const [tempScannedData, setTempScannedData] = useState(scannedData.scanned)
  // const [cartonIdInput, setCartonIdInput] = useState('')

  useEffect(() => {
    if(inputContainerData) {
      setInputs((prev) => {
        return {
          ...prev,
          container: inputContainerData.container,
          shipname_voyage: inputContainerData.shipname_voyage,
          etd: inputContainerData.etd,
          eta: inputContainerData.eta,
        }
      })
      getScannedCartons(inputContainerData.marking_code)
    }
  }, [inputContainerData])

  useEffect(() => {
    if(inputs.container !== '' && inputs.shipname_voyage !== '' && inputs.etd !== '' && inputs.eta !== '') {
      setValidForm(true)
    } else {
      setValidForm(false)
    }
  }, [inputs])
  
  // const getScannedCartons = async (markingCode) => {
  //   const response = await get_scanned_cartons(markingCode)
  //   if(response.status === 200) {
  //     setScannedData({
  //         scanned: response.data.scanned || [],
  //         unscanned: response.data.unscanned || []
  //       }
  //     )
  //     setTempScannedData(response.data.scanned || [])
  //   }
  // }

  const set_OpenModalScanCarton = () => {
    getScannedCartons(inputContainerData.marking_code)
    setOpenModalScanCarton(true)
  }

  const bulkScan = () => {
    const body = {
      id_karton: bulkTempScannedData,
    };
    
    swal({
      title: 'Are you sure?',
      text: 'Bulk submit data on Table Container',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((yes) => {
      if (yes) {
        const submit = async () => {
          const response = await insert_scan_carton(JSON.stringify(body));
          console.log('resp', response)
          if (response.status === 200) {
            swal('Table Container has been Submited', {
              icon: 'success',
            });
            setBulkTempScannedData([])
            setSelectAllCartonIds(false)
            await getScannedCartons(inputContainerData.marking_code)
          } else {
            swal(
              `Failed Submit Table Container, Error : ${response?.message}`,
              {
                icon: 'error',
              }
            );
          }
        };

        submit();
      } else {
        swal('You canceled to Submit Table Container');
      }
    });
  }

  const handleChangeInputs = (e) => {
    const {value, name} = e.target
    setInputs((prev) => {return {...prev, [name]: value}})
  }

  const submit_InputContainer = (action) => {
    if(action === 'save') {
      submitInputContainer(action)
    } else {
      if(validForm) {
        // ADA PERUBAHAN FLOW, YANG SAAT INI TIDAK MAU MUNCUL MODAL WARNING/SET ABNORMAL, MESKIPUN ADA CARTON YANG MASIH BLOM DI-SCAN
        // if(scannedData?.unscanned?.length !== 0) {
        //   setShowWarningModal(true)
        // } else {
        //   submitInputContainer(action)
        // }
        swal({
          text: 'Are you sure want to submit these scanned cartons?',
          icon: 'warning',
          buttons: true,
        }).then((yes) => {
          if(yes) {
            submitInputContainer(action)
          }
        })
      } else {
        swal('Input Not Valid', 'Please complete input form', 'error')
        setIsLoading(false)
      }
    }
  }

  const submitInputContainer = async (action) => {
    setIsLoading(action)
    const act = action === 'save' ? false : true
    const payload = {
      marking_code:  inputContainerData.marking_code,
      container:  inputs.container,
      shipname_voyage:  inputs.shipname_voyage,
      etd:  inputs.etd,
      eta:  inputs.eta,
      is_submitted:  act
    }
    
    const response = await submit_draft_input_container(JSON.stringify(payload))
    if(response.status === 200) {
      if(action === 'submit') {
        setShowModalInputContainer(false)
        setInputContainerData(null)
        setShowWarningModal(false)
        setShowInsertUnscannedDetails(false)
      }
      setUpdate(prev => !prev)
    } else {
      swal('Failed', 'Input container number failed!', 'error')
    }
    setIsLoading(false)
  }

  const unScanCarton = async (cartonId) => {
    const body = {
      id_karton: cartonId
    }
    const response = await delete_scan_carton(JSON.stringify(body))
    if(response.status === 200) {
      getScannedCartons(inputContainerData.marking_code)
    }
  };

  const handleSelectCartonId = (e) => {
    const { name, checked, value } = e.target
    
    if (checked) {
      setBulkTempScannedData([...bulkTempScannedData, value])
    } else {
      setBulkTempScannedData(bulkTempScannedData.filter(i => i !== value))
    }
  }
  
  const handleSelectAllCartonId = (e) => {
    const { name, checked, value } = e.target
    
    if (checked) {
      setSelectAllCartonIds(true)
      setBulkTempScannedData(scannedData?.unscanned)
    } else {
      setSelectAllCartonIds(false)
      setBulkTempScannedData([])
    }
  }
  
  return (
    <>
      <Modal
        open={showModalInputContainer}
        onClose={() => {
          closeModalScanCartons()
          setShowModalInputContainer(false)
          setInputContainerData(null)
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style} className="overflow-y-scroll variant-scroll">
          <div className="flex justify-end -mt-5">
            <IconButton onClick={() => {
                setShowModalInputContainer(false)
                setInputContainerData(null)
              }}
              style={{ textAlign: 'right' }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {t("Container Number")}
          </Typography>
          <div className="mt-3 space-y-3">
            <div className="space-y-3">
              <div>
                <label>{t("Container Number")}</label>
                <input
                  name='container'
                  type="text"
                  value={inputs.container}
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-blue"
                  onChange={handleChangeInputs}
                />
              </div>
            </div>
            <div className="space-y-3">
              <div>
                <label>{t("Ship Name Voyage")}</label>
                <input
                  name='shipname_voyage'
                  type="text"
                  value={inputs.shipname_voyage}
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-blue"
                  onChange={handleChangeInputs}
                />
              </div>
            </div>
            <div className="flex gap-3">
              <div className="w-1/2">
                <label>{t("ETD")}</label>
                <input
                  name='etd'
                  type="date"
                  value={inputs.etd}
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-blue"
                  onChange={handleChangeInputs}
                />
              </div>
              <div className="w-1/2">
                <label>{t("ETA")}</label>
                <input
                  name='eta'
                  type="date"
                  value={inputs.eta}
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-blue"
                  onChange={handleChangeInputs}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-3 text-sm">
              <div className="scanned-cartons max-h-[300px] overflow-y-auto">
                Carton-ID (Scanned)
                {scannedData?.scanned?.map((carton, index) => (
                  <div key={index}>
                    - {carton} <span className="text-gray-400 hover:text-gray-600 cursor-pointer" onClick={() => unScanCarton(carton)}><Close style={{ fontSize: '.75rem' }} /></span>
                  </div>
                ))}
              </div>
              <div className="unscanned-cartons text-red-500 max-h-[300px] overflow-y-auto">
                {(getUser().division.includes('kepala_gudang') && scannedData?.unscanned?.length > 0) &&
                  <input type="checkbox" 
                    name="carton-ids-all"
                    id="carton-ids-all"
                    checked={selectAllCartonIds}
                    onChange={handleSelectAllCartonId} />
                  } Carton-ID (Not Scanned)
                {scannedData?.unscanned?.map((carton, index) => (
                  <div key={index} className="flex items-center gap-1">
                    {getUser().division.includes('kepala_gudang') ?
                      <input type="checkbox" 
                        name="carton-ids"
                        id="carton-ids"
                        value={carton}
                        checked={bulkTempScannedData.includes(carton)}
                        onChange={handleSelectCartonId} />
                      : '-'} 
                      <span>
                        {carton}
                      </span>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex justify-end gap-3">
              <button
                onClick={() => bulkTempScannedData.length === 0 ? set_OpenModalScanCarton() : bulkScan()}
                className="text-white bg-blue-500 px-5 py-1 rounded-md focus:outline-blue">
                {bulkTempScannedData.length === 0 ? t("SCAN") : "BULK SCAN"}
              </button>
              <button
                onClick={() => !isLoading && submit_InputContainer('save')}
                className="text-white bg-blue-500 px-5 py-1 rounded-md focus:outline-blue">
                {isLoading === 'save' ? 'saving...' : t("SAVE")}
              </button>
              <button
                onClick={() => validForm && !isLoading && submit_InputContainer('submit')}
                className={`${!validForm || isLoading ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500'} text-white px-5 py-1 rounded-md focus:outline-blue`}>
                {isLoading === 'submit' ? 'submitting...' : t("SUBMIT")}
              </button>
            </div>
          </div>
        </Box>
      </Modal>
      <ModalScanCartons
        inputContainerData={inputContainerData}
        scannedData={scannedData}
        setScannedData={setScannedData}
        inputs={inputs}
        openModalScanCarton={openModalScanCarton}
        setOpenModalScanCarton={setOpenModalScanCarton}
        getScannedCartons={getScannedCartons}
        tempScannedData={tempScannedData}
        setTempScannedData={setTempScannedData}
        cartonIdInput={cartonIdInput}
        setCartonIdInput={setCartonIdInput}
        closeModalScanCartons={closeModalScanCartons}
      />
      <ModalWarningUnScannedCarton 
        inputContainerData={inputContainerData}
        inputs={inputs}
        scannedData={scannedData}
        showWarningModal={showWarningModal}
        setShowWarningModal={setShowWarningModal}
        showInsertUnscannedDetails={showInsertUnscannedDetails}
        setShowInsertUnscannedDetails={setShowInsertUnscannedDetails}
        submitInputContainer={submitInputContainer}
        getScannedCartons={getScannedCartons}
      />
    </>
  )
}
