import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { saveAs } from 'file-saver';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 400,
  maxWidth: "70vh",
  maxHeight: "70vh",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: 5,
  boxShadow: 24,
  p: 4,
};

export default function ImageModal({ image, className }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      {image !== null && (
        <div className={className} onClick={handleOpen}>
          <img
            src={image}
            alt="attachment"
            className="w-12 h-8 object-cover overflow-hidden"
          />
        </div>
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="flex justify-end -mt-5">
              <IconButton onClick={handleClose} style={{ textAlign: "right" }}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className="w-full h-full relative">
              <img
                src={image}
                alt="attachment"
                className="w-full object-contain mb-5"
                style={{ height: 'calc(100% - 50px)', filter: 'drop-shadow(2px 2px 5px #ccc)' }}
              />
              <div className="absolute top-[50%] left-[50%] translate-y-50% translate-x-[-50%] cursor-pointer" onClick={() => saveAs(image, 'ocistok.png')}>
                <DownloadForOfflineIcon className="text-white  opacity-25 scale-150 hover:opacity-75" style={{ filter: 'drop-shadow(2px 2px 2px #666)' }} fontSize='large' />
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
