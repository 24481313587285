import { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button, CircularProgress, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import swal from "sweetalert";
import { importCustomerOBE } from "service/api";
import { uploadFile } from "helpers/file";

const ImportDataExcel = ({ openDoc, setOpenDoc, setChangeData }) => {
  const [document, setDocument] = useState(null);
  const [loading, setLoading] = useState(false);
  
  const handleUploadFile = async (e) => {
    e.preventDefault();
    setLoading(true)

    var formData = new FormData();
    formData.append("document", document);
    uploadFile(formData)
    .then((data) => {
      if (data.status === 200) {
        console.log('doc link', data.file)
        importCustomer(data.file)
      } else {
        swal('Oops', data.message, 'error');
        setLoading(false)
      }
    }).catch((error) => {
      setLoading(false)
      console.error(error);
    })

  };
  
  const importCustomer = async (link) => {
    importCustomerOBE(JSON.stringify({ link }))
      .then((data) => {
        if (data.status === 200) {
          setDocument(null)
          setChangeData(prev => !prev)
          setOpenDoc(false)
        } else {
          swal('Oops', data.message, 'error');
        }
      })
      .catch((error) => {
        console.error(error);
      }).finally(() =>{
        setLoading(false)
      });
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    maxHeight: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const closeModal = () => {
    setOpenDoc(false);
    setDocument(null)
  };

  const handleDocUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    if (file && file.size > 15 * 1024 * 1024) {
      swal("Ukuran document terlalu besar (maksimal 15MB).");
      setDocument("");
    } else {
      setDocument(file);
    }
  };
  return (
    <>
      <div className="bg-blue-500 disabled:bg-gray-500 rounded-md p-2 text-white hover:bg-blue-400 transition-all uppercase cursor-pointer" onClick={() => setOpenDoc(true)}>
        Import Data
      </div>
      <Modal
        open={openDoc}
        onClose={() => setOpenDoc(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description">
        <Box sx={{ ...style }}>
          <div className="w-full flex justify-end">
            <IconButton
              aria-label="close"
              onClick={closeModal}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}>
              <CloseIcon />
            </IconButton>
          </div>

          <form onSubmit={(e) => handleUploadFile(e)}>
            <div>
              <h3 className="text-lg text-center font-bold">Uploaded File:</h3>
            </div>
            {loading ? (
              <div className="w-full h-full my-8 flex items-center justify-center text-white ">
                <CircularProgress size={30} className="text-blue-300" />
              </div>
            ) : (
              <div>
                <div className="w-full flex justify-center mt-5">
                 <p>{document?.name}</p>
                </div>
                
                <div className="flex flex-col items-center my-8">
                  <label
                    htmlFor="doc_input"
                    className="text-lg text-white bg-gray-400 px-10 py-1 rounded-full cursor-pointer">
                    Select file
                  </label>
                  <input
                    className="hidden text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                    type="file"
                    id="doc_input"
                    accept=".xls, .xlsx"
                    onChange={handleDocUpload}
                  />
                  <p
                    className="mt-1 text-xs text-gray-500 dark:text-gray-300"
                    id="file_input_help">
                     <b>XLS, XLSX (Max. 15MB).</b>
                  </p>
                </div>
              </div>
            )}
            <div className="w-full flex flex-col justify-center items-center mt-5">
              <button
                className={`${!document || loading ? 'bg-gray-300' : 'bg-green-500'}  cursor-default text-white py-3 px-7 rounded-lg`}
                disabled={!document || loading}
              >
                {loading ? 'uploading...' : 'Import'}
              </button>
              <div className="mt-3 text-sm">
                Download file template <a href={`${process.env.REACT_APP_URL_DOCUMENT_STORAGE}/customer-obe.xlsx`} target="_blank" className="text-blue-600 font-bold underline">disini</a>
              </div>
            </div>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default ImportDataExcel;
