import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { approveAdjustPaymentData, getAlreadyPODataDetails, updatePaymentData } from 'service/api';
import swal from 'sweetalert';
import InitRequestModal from 'components/ManagementSales/SalesRequest/InitRequestModal';
import { useRecoilValue } from 'recoil';
import { currency_kurs } from 'atom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  minHeight: 550,
  maxHeight: '85%',
  overflowY: 'auto',
  bgcolor: 'background.paper',
  border: '1px solid lightgray',
  boxShadow: 24,
  borderRadius: '5px',
  p: 4,
};

export default function Payment({
  id,
  invoiceNumber,
  link,
  initValue,
  setUpdate,
}) {
  const kurs = useRecoilValue(currency_kurs)
  const [open, setOpen] = useState(false);
  const [openInitRequestModal, setOpenInitRequestModal] = useState(false);
  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = useState(false);
  const [paymentData, setPaymentData] = useState(null);
  const [totalAllinAdjustmentRMB, setTotalAllinAdjustmentRMB] = useState(0)
  const [totalAllinAdjustmentIDR, setTotalAllinAdjustmentIDR] = useState(0)
  const [isAdjustment, setIsAdjustment] = useState(false)
  
  useEffect(() => {
    if(kurs) {
      if (paymentData) {
        // const totalAdjRMB = ( (Number(paymentData?.forecastedTotalRMB) + (Number(paymentData?.forecastedTotalRMB) * 0.11)) - 
        //                        ((Number(paymentData?.newForecastedTotalRMB) * Number(paymentData?.tagVal)) + (Number(paymentData?.newForecastedTotalRMB) * Number(paymentData?.tagVal)) * 0.11) ) + 
        //                        (Number(paymentData?.woodenPackagingRMB) + (Number(paymentData?.woodenPackagingRMB) * 0.11) )
        const totalAdjRMB = Number(paymentData?.totalAllIn) + (Number(paymentData?.woodenPackagingRMB) + (Number(paymentData?.woodenPackagingRMB) * 0.11) )
        const totalAdjIDR = totalAdjRMB * kurs
        setTotalAllinAdjustmentRMB(totalAdjRMB.toLocaleString('id-ID'))
        setTotalAllinAdjustmentIDR((Math.ceil(totalAdjIDR)).toLocaleString('id-ID'))
      }
    } else {
      swal('Oops', 'Failed to fetch kurs', 'error');
    }
  }, [paymentData])

  const handleOpen = () => {
    get_AlreadyPODataDetails()
    setOpen(true)
  }

  const get_AlreadyPODataDetails = async () => {
    const response = await getAlreadyPODataDetails(invoiceNumber)
    if (response.status === 200) {
      setPaymentData({
        invoiceNumber: invoiceNumber,
        link: link,
        totalAllIn: response.data?.total_all_in,
        totalRMB: response.data?.final_total,
        initValue: response.data?.initial_price,
        forecastedTotalRMB: response.data?.forecast_po,
        newForecastedTotalRMB: response.data?.new_forecast,
        woodenPackagingRMB: response.data?.packing_kayu,
        localChinaShipping: response.data?.ongkir_china,
        tagVal: response.data?.tag_val,
        note: '',
        paymentNumber: '',
      })
      setIsAdjustment(response.data.is_adjustment)
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPaymentData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const updatePayment = async (e) => {
    e.preventDefault();

    setLoading(true);
    setUpdate((prev) => !prev);
    if (paymentData?.paymentNumber) {
      const body = JSON.stringify({
        id_so: id,
        invoice: invoiceNumber,
        payment_number: paymentData?.paymentNumber,
        note: paymentData?.note,
      });
      const data = await updatePaymentData(body);
      if (data?.status === 200) {
        swal('Success', 'Payment number updated successfully', 'success');
        handleClose();
        setUpdate((prev) => !prev);
      } else if (data?.status === 400) {
        swal('Oops', data.message, 'error');
      }
    } else {
      swal('Oops', 'Payment number required !', 'error');
    }
    setLoading(false);
  };

  const approveAdjustment = async (e) => {
    e.preventDefault();

    setLoading(true);
    setUpdate((prev) => !prev);
    
    const body = JSON.stringify({
      id_so: id,
      invoice: invoiceNumber,
      payment_number: paymentData?.paymentNumber,
      note: paymentData?.note,
      final_total: Number(paymentData.totalRMB),
      packing_kayu: Number(paymentData?.woodenPackagingRMB),
    });
    const data = await approveAdjustPaymentData(body);
    if (data?.status === 200) {
      swal('Success', 'Payment number approved successfully', 'success');
      handleClose();
      setUpdate((prev) => !prev);
    } else if (data?.status === 400) {
      swal('Oops', data.message, 'error');
    }

    setLoading(false);
  };

  const setUpdateQuote = () => {
    get_AlreadyPODataDetails()
  }
  return (
    <div>
      <div
        className='py-2 px-5 border text-blue-500  text-sm border-blue-500 rounded-md text-center cursor-pointer'
        onClick={() => {
          handleOpen();
        }}>
        Payment
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box sx={style}>
          <div className='flex justify-end -mt-5'>
            <IconButton onClick={handleClose} style={{ textAlign: 'right' }}>
              <CloseIcon />
            </IconButton>
          </div>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Purchase Order For: {id}
          </Typography>
          <div className='flex flex-col space-y-3 my-5'>
            <div className='flex flex-col space-y-1'>
              <label className='text-sm font-semibold'>Invoice Number*</label>
              <input
                type='text'
                disabled={true}
                defaultValue={paymentData?.invoiceNumber}
                className='p-1 rounded-md border border-gray-300'
              />
            </div>
            <div className='flex flex-col space-y-1'>
              <label className='text-sm font-semibold'>Link*</label>
              <input
                type='text'
                disabled={true}
                defaultValue={paymentData?.link}
                className='p-1 rounded-md border border-gray-300'
              />
            </div>
            <div className='flex flex-col space-y-1'>
              <label className='text-sm font-semibold'>Final Total RMB*</label>
              <input
                type='number'
                name='totalRMB'
                disabled={!isAdjustment}
                value={paymentData?.totalRMB}
                className='p-1 rounded-md border border-gray-300'
                onChange={(e) => isAdjustment && handleChange(e)}
              />
            </div>
            {initValue && initValue !== 0 ?
              <div className='flex flex-col space-y-1'>
                <label className='text-sm font-semibold'>Initial Total RMB</label>
                <input
                  type='text'
                  disabled={true}
                  defaultValue={paymentData?.initValue}
                  className='p-1 rounded-md border border-gray-300'
                />
              </div>
            : null }
            <div className='flex flex-col space-y-1'>
              <label className='text-sm font-semibold'>Forecasted Total RMB</label>
              <input
                type='text'
                disabled={true}
                defaultValue={paymentData?.forecastedTotalRMB}
                className='p-1 rounded-md border border-gray-300'
              />
            </div>
            <div className='flex flex-col space-y-1'>
              <label className='text-sm font-semibold'>New Forecast</label>
              <input
                type='text'
                disabled={true}
                defaultValue={paymentData?.newForecastedTotalRMB}
                className='p-1 rounded-md border border-gray-300'
              />
            </div>
            <div className='flex flex-col space-y-1'>
              <label className='text-sm font-semibold'>Wooden Packaging RMB (if any)</label>
              <input
                type='number'
                name='woodenPackagingRMB'
                disabled={!isAdjustment}
                value={paymentData?.woodenPackagingRMB}
                className='p-1 rounded-md border border-gray-300'
                onChange={(e) => isAdjustment && handleChange(e)}
              />
            </div>
            <div className='flex flex-col space-y-1'>
              <label className='text-sm font-semibold'>Local China Shipping</label>
              <input
                type='text'
                disabled={true}
                defaultValue={paymentData?.localChinaShipping}
                className='p-1 rounded-md border border-gray-300'
              />
            </div>
            <div className='flex flex-col space-y-1'>
              <label className='text-sm font-semibold'>Total All-in Adjustment (RMB)</label>
              <input
                type='text'
                // disabled={true}
                value={totalAllinAdjustmentRMB}
                className='p-1 rounded-md border border-gray-300'
                onChange={() => console.log('')}
              />
            </div>
            <div className='flex flex-col space-y-1'>
              <label className='text-sm font-semibold'>Total All-in Adjustment (IDR)</label>
              <input
                type='text'
                // disabled={true}
                value={totalAllinAdjustmentIDR}
                className='p-1 rounded-md border border-gray-300'
                onChange={() => console.log('')}
              />
            </div>
            <div className='flex flex-col space-y-1'>
              <label className='text-sm font-semibold'>Notes</label>
              <input
                name='note'
                value={paymentData?.note}
                onChange={handleChange}
                type='text'
                className='p-1 rounded-md border border-gray-300 focus:outline-blue'
              />
            </div>
            <div className='flex flex-col space-y-1'>
              <label className='text-sm font-semibold'>Payment Number*</label>
              <input
                name='paymentNumber'
                value={paymentData?.paymentNumber}
                onChange={handleChange}
                type='text'
                className='p-1 rounded-md border border-gray-300 focus:outline-blue'
              />
            </div>
          </div>

          <hr className='mb-3' />
          <div className='flex justify-center items-center gap-3'>
            {isAdjustment &&
              <>
                <button
                  onClick={() => setOpenInitRequestModal(true)}
                  // disabled={loading}
                  className={`bg-green-500 rounded-md p-2 px-4 text-sm text-white`}>
                  Quote
                </button>
                <button
                  onClick={approveAdjustment}
                  disabled={loading}
                  className={`${
                    loading ? 'bg-gray-500' : 'bg-orange-500'
                  } rounded-md p-2 px-4 text-sm text-white`}>
                  Approve
                </button>
              </>
            }
            {!isAdjustment &&
              <button
                onClick={updatePayment}
                disabled={loading}
                className={`${
                  loading ? 'bg-gray-500' : 'bg-blue-500'
                } rounded-md p-2 px-4 text-sm text-white`}>
                Pay
              </button>
            }
          </div>
        </Box>
      </Modal>
      
      {openInitRequestModal &&
        <InitRequestModal
          setUpdate={setUpdate}
          open={openInitRequestModal}
          setOpen={setOpenInitRequestModal}
          request_link={link}
          setUpdateQuote={setUpdateQuote}
          />
        }
    </div>
  );
}