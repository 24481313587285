import { useEffect, useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  CircularProgress,
  IconButton,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  Paper,
  TableRow,
  Typography,
  Modal,
  Fade,
  Box,
  Backdrop,
} from "@mui/material";
import SolveIssueSalesTableTable from './SolveIssueSalesTableTable';
import { get_order_report_product, solve_issue_sales_table, solve_issue_sales_table_change_variant, solve_issue_sales_table_price_rising } from 'service/api';
import swal from 'sweetalert';
import AddAdjustmentsModal from '../AddAdjustmentsModal';

const style = {
  position: 'absolute',
  top: 50,
  left: '5%',
  right: '5%',
  // left: '50%',
  // transform: 'translate(0, -50%)',
  maxWidth: '95vw',
  height: 'auto',
  maxHeight: "85vh",
  overFlowY: "auto",
  bgcolor: '#f8f8f8',
  border: '1px solid lightgray',
  boxShadow: 24,
  borderRadius: '5px',
  p: 4,
};

const SolveIssueSalesTableModal = ({ isShowDetailsOnly, data, idOrder, status, issue, setUpdate }) => {
  const [products, setProducts] = useState(null);
  const [productsAfter, setProductsAfter] = useState(null);
  const [totalPaid, setTotalPaid] = useState(0);
  const [finalTotal, setFinalTotal] = useState(0)
  const [finalQtyAll, setFinalQtyAll] = useState("");
  const [isQtyChanged, setIsQtyChanged] = useState(false);
  const [solution, setSolution] = useState('');
  const [additionalShipping, setAdditionalShipping] = useState('');
  const [localShipping, setLocalShipping] = useState(0);
  const [initialShippingFee, setInitialShippingFee] = useState(0);
  const [open, setOpen] = useState(false);
  const [isChangeSupplier, setIsChangeSupplier] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showAddAdjustments, setShowAddAdjustments] = useState(false);
  const [dataChanged, setDataChanged] = useState(false);
  const [adjustment, setAdjustment] = useState({
    asuransi: '',
    inspeksi: '',
    packingKayu: '',
    ongkir_international: '',
  });
  const [total_adjustment, setTotal_adjustment] = useState(0)
  // const total_adjustment = useRef(0) 
  const handleOpen = () => fetchData();
  const handleClose = () => setOpen(false);

  useEffect(() => {
    let asuransi = ~~(adjustment.asuransi.toString().replaceAll(".", ""))
    let inspeksi = ~~(adjustment.inspeksi.toString().replaceAll(".", ""))
    let packingKayu = ~~(adjustment.packingKayu.toString().replaceAll(".", ""))
    let ongkir_international = ~~(adjustment.ongkir_international.toString().replaceAll(".", ""))
    
    setTotal_adjustment(~~asuransi + ~~inspeksi + ~~packingKayu + ~~ongkir_international)
  }, [adjustment])
  
  const fetchData = async () => {
    setIsLoading(true);
    const response = await get_order_report_product(idOrder);
    if (response?.status === 200) {
      if(response?.data.is_change_supplier) {
        let newProductWithFinalQty = response.data.change_variant.product_before
        newProductWithFinalQty?.forEach((prod, index) => {
          prod.final_qty = response.data.change_variant.product_after[index].qty < response.data.change_variant.product_after[index].moq ? 0 : response.data.change_variant.product_after[index].qty
          prod.disabled = response.data.change_variant.product_after[index].qty < response.data.change_variant.product_after[index].moq ? true : false
        })
        setProducts(newProductWithFinalQty);
        setProductsAfter(response.data.change_variant.product_after);
        setInitialShippingFee(response.data.local_shipping_cost)
        setAdditionalShipping(response.data.actual_shipping_cost)
        setIsChangeSupplier(true)
      } else {
        let newProductWithFinalQty = response.data.product
        newProductWithFinalQty?.forEach((prod, index) => {
          prod.final_qty = prod.qty < prod.moq ? 0 : prod.qty
          // prod.disabled = prod.qty < prod.moq ? true : false
        })
        setProducts(newProductWithFinalQty);
        setInitialShippingFee(response.data.local_shipping_cost)
        setAdditionalShipping(response.data.actual_shipping_cost)
        setLocalShipping(response.data.local_shipping_cost)
        setIsChangeSupplier(false)
      }

      if (response.data.total_adjustment !== 0) {
        setAdjustment((prev) => {
          return {
            ...prev,
            asuransi: response.data?.adjustment?.asuransi,
            inspeksi: response.data?.adjustment?.inspeksi,
            packingKayu: response.data?.adjustment?.packing_kayu,
            ongkir_international: response.data?.adjustment?.ongkir_international,
          }
        })
      }
      setTotalPaid(response.data.total_paid)
      setOpen(true)
    } else if (response?.status === 500) {
      swal('Oops', response?.message || "There's a problem occured. Please try again in 15 minutes or contact IT", 'error');
    } else {
      swal('Oops', `${response?.message}`, 'error');
    }
    setIsLoading(false);
}

useEffect(() => {
  if(products?.length) {
    let total_initial_price
    let total_changed_price
    let check_changed_qty
    let local_shipping

    if(!isChangeSupplier) {
      total_initial_price = products.reduce((total, curr) => total + (curr.qty * curr.price), 0)
      total_changed_price = products.reduce((total, curr) => 
                            total + (curr.final_qty * Math.ceil(
                              (curr.new_price * data.currency) + 
                              ((curr.new_price * data.currency) * 0.11) + 
                              ((curr.new_price * data.currency) * curr.level_discount) + 
                              curr.cbm_calc)
                            ), 0)
      check_changed_qty = products.filter(prod => prod.qty_available && prod.final_qty !== prod.qty_available)
    } else {
      total_initial_price = products.reduce((total, curr) => total + (curr.qty * Math.ceil(curr.price)), 0)
      total_changed_price = products.reduce((total, curr, index) => total + (curr.final_qty * Math.ceil(productsAfter[index].price + productsAfter[index].tax + productsAfter[index].processing_fee + productsAfter[index].cbm_calc)), 0)
      check_changed_qty = products.filter(prod => prod.qty !== prod.final_qty)
      
      //count local_shipping
      const totalWeightAfter = products.reduce((total, curr, index) => total + (~~productsAfter[index].weight * ~~curr.final_qty), 0)
      const ongkirAfter = productsAfter[0].ongkir
      local_shipping = Math.ceil(totalWeightAfter / 1000) * ongkirAfter
      
      setLocalShipping(~~local_shipping)
    }

    const final_total = total_changed_price

    setFinalTotal(final_total)

    if(final_total + total_adjustment === 0) {
      setSolution('FULL REFUND')
    }
    if (total_initial_price < final_total + total_adjustment + ~~local_shipping) {
      setSolution('ADJUSTMENT')
    }
    if (final_total + total_adjustment + ~~local_shipping > 0 && total_initial_price > final_total + total_adjustment + ~~local_shipping) {
      setSolution('PARTIAL REFUND')
    }
    if (final_total + total_adjustment + ~~local_shipping > 0 && check_changed_qty.length) {
      setIsQtyChanged(true)
    } else {
      setIsQtyChanged(false)
    }
  }
}, [products, productsAfter, isChangeSupplier, total_adjustment])

const handleSubmitSolveIssue = async () => {
  let finalProductObj
  let payload

  if (isChangeSupplier) {
    finalProductObj = products.map((prod, index) => {
      return {
        id_product: productsAfter[index].id_product,
        qty: prod.final_qty
      }
    })

    payload = {
      id_so: idOrder,
      solution: solution,
      final_qty: finalProductObj,
      aditional_shipping: ~~(additionalShipping.toString().replaceAll('.','')),
      local_shipping: localShipping,
      adjustment: {
        asuransi: ~~((adjustment.asuransi).toString().replaceAll('.','')),
        inspeksi: ~~((adjustment.inspeksi).toString().replaceAll('.','')),
        ongkir_international: ~~((adjustment.ongkir_international).toString().replaceAll('.','')),
        packing_kayu: ~~((adjustment.packingKayu).toString().replaceAll('.','')),
      }
    }
    
    const response = await solve_issue_sales_table_change_variant(JSON.stringify(payload))
    if (response.status === 200) {
      setUpdate((prev) => !prev);
      handleClose()
    } else {
      swal("Failed", response.message, "error")
    }
  }

  if (!isChangeSupplier) {
    finalProductObj = products.map((prod, index) => {
      return {
        id_request: data.id_request,
        id_product: prod.id_product,
        price: prod.price,
        new_price: prod.changed_price,
        qty: prod.final_qty,
      }
    })

    payload = {
      id_so: idOrder,
      aditional_shipping: ~~(additionalShipping.toString().replaceAll('.','')),
      product: finalProductObj,
      adjustment: {
        asuransi: ~~((adjustment.asuransi).toString().replaceAll('.','')),
        inspeksi: ~~((adjustment.inspeksi).toString().replaceAll('.','')),
        ongkir_international: ~~((adjustment.ongkir_international).toString().replaceAll('.','')),
        packing_kayu: ~~((adjustment.packingKayu).toString().replaceAll('.','')),
      },
    }

    const response = await solve_issue_sales_table_price_rising(JSON.stringify(payload))
    if (response.status === 200) {
      setUpdate((prev) => !prev);
      handleClose()
    } else {
      swal("Failed", response.message, "error")
    }
  }
}

  return (
    <>
      <button
        className={`${status === 'Need Approval' && !isShowDetailsOnly ? "text-gray-300 border-gray-300 cursor-not-allowed" : "text-blue-500 border-blue-500 cursor-pointer"} py-2 px-5 border rounded-md text-center whitespace-nowrap`}
        onClick={() => {
          handleOpen();
        }}
        disabled={status === 'Need Approval' && !isShowDetailsOnly}
      >
        {isShowDetailsOnly ? "Issue Details" : "Solve Issue"}
      </button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box sx={style} className='overflow-y-scroll variant-scroll'>
          <div className='flex justify-end -mt-5'>
            <IconButton onClick={handleClose} style={{ textAlign: 'right' }}>
              <CloseIcon />
            </IconButton>
          </div>
          <Typography id='modal-modal-title' variant='h4' component='h4'>
            {isShowDetailsOnly ? "Issue Details" : "Solve Issue"} <span className='text-xl font-bold'>({idOrder})</span>
          </Typography>
          <Typography id='modal-modal-title' variant='h6' component='h6'>
            Solution: <u className={`${solution === 'FULL REFUND' ? "text-green-600" : solution === "ADJUSTMENT" ? "text-red-500" : "text-orange-500"}`}>{solution}</u> {isQtyChanged && (<i>( "Quantity Changed")</i>)}
          </Typography>
          <SolveIssueSalesTableTable 
            isShowDetailsOnly={isShowDetailsOnly}
            data={data}
            products={products} 
            setProducts={setProducts} 
            productsAfter={productsAfter} 
            setProductsAfter={setProductsAfter} 
            isChangeSupplier={isChangeSupplier}
            totalPaid={totalPaid}
            setTotalPaid={setTotalPaid}
            finalTotal={finalTotal}
            // setFinalTotal={setFinalTotal}
            finalQtyAll={finalQtyAll}
            setFinalQtyAll={setFinalQtyAll}
            // isQtyChanged={isQtyChanged}
            // setIsQtyChanged={setIsQtyChanged}
            solution={solution}
            // setSolution={setSolution}
            additionalShipping={additionalShipping}
            setAdditionalShipping={setAdditionalShipping}
            initialShippingFee={initialShippingFee}
            localShipping={localShipping}
            setShowAddAdjustments={setShowAddAdjustments}
            adjustment={adjustment}
            setAdjustment={setAdjustment}
            total_adjustment={total_adjustment}
            handleSubmitSolveIssue={handleSubmitSolveIssue}
          />
        </Box>
      </Modal>
      <AddAdjustmentsModal
        adjustment={adjustment}
        setAdjustment={setAdjustment}
        showAddAdjustments={showAddAdjustments}
        setShowAddAdjustments={setShowAddAdjustments}
        dataChanged={dataChanged}
        setDataChanged={setDataChanged}
      />
    </>
  );
};

export default SolveIssueSalesTableModal;
