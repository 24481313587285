import { useEffect, useRef, useState } from "react";
import {
  CircularProgress,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  TableCell,
  IconButton,
  Collapse,
  Typography,
} from "@mui/material";
import swal from "sweetalert";
import { get_report_product_price } from "service/api";
import Lightbox from 'react-image-lightbox';
import { getMytaskProduct } from '../fetchData';

export default function SolveIssueFoundNewSupplierTable({
  mytaskProducts,
  setMytaskProducts,
  originalMytaskProducts,
  setOriginalMytaskProducts,
  additionalShipping,
  setAdditionalShipping,
  localShipping,
  setLocalShipping,
  newLink,
  setNewLink,
  isLoading,
  setIsLoading,
  openQuoteModal,
  setOpenQuoteModal,
  confirmationIssue,
  showVariantAfter,
  setShowVariantAfter,
  toko,
  setToko,
  idPage,
  setIdPage,
  setUpdate,
  idOrder,
}) {
  const [checkedAll, setCheckedAll] = useState(false);
  const [newData, setNewData] = useState(null);
  const [variantAfter, setVariantAfter] = useState([]);
  const [selectedVariantAfterArray, setSelectedVariantAfterArray] = useState([]);
  const [finalTotal, setFinalTotal] = useState(0)
  const [searchVariant, setSearchVariant] = useState('')
  const [showSearchVariant, setShowSearchVariant] = useState(false)
  const [openLightbox, setOpenLightbox] = useState(false);
  
  const wrapperRef = useRef(null);

  useEffect(() => {
    if(mytaskProducts) {
      setNewData(mytaskProducts.product)
    }
  }, [mytaskProducts])

  useEffect(() => {
    if(newData?.length && mytaskProducts.product?.length) {
      if (confirmationIssue !== "Need Adjustment") {
        const totalWeightAfter = mytaskProducts.product.reduce((total, curr) => total + (~~curr.weight_after * ~~curr.qty_available_after), 0)
        const ongkirAfter = mytaskProducts.product[0].ongkir_after
        const local_shipping = Math.ceil(totalWeightAfter / 1000) * ongkirAfter

        // const total_allin_price = mytaskProducts.product.reduce((total, curr) => total + (~~curr.qty_available_after * Math.ceil(~~curr.price_allin_after + curr.tax + curr.processing_fee + curr.cbm_calc)), 0)
        const total_allin_price = mytaskProducts.product.reduce((total, curr) => total + (~~curr.qty_available_after * Math.ceil(~~curr.price_allin_after)), 0)

        const final_total = ~~total_allin_price
        
        setLocalShipping(~~local_shipping)
        setFinalTotal(final_total)
      } else {
        const final_total = mytaskProducts.product.reduce((total, curr) => 
                            total + 
                            (curr.qty * Math.ceil(
                              (curr.new_price * mytaskProducts.currency) + 
                              ((curr.new_price * mytaskProducts.currency) * 0.11) + 
                              ((curr.new_price * mytaskProducts.currency) * curr.level_discount) + 
                              curr.cbm_calc)
                            ), 0)
        
        setLocalShipping(mytaskProducts.local_shipping_cost)
        setFinalTotal(final_total)
      }
    }
  }, [newData, confirmationIssue])

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowSearchVariant(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  // const handleChecked = (e, id) => {
  //   const { checked } = e.target;
  //   const values = [...newData];
  //   if (id !== undefined) {
  //     values[id].isChecked = checked;
  //     setNewData(values);
  //     const isCheckAll = newData.filter(
  //       (data) => data.isChecked === false
  //     );
  //     if (isCheckAll?.length === 0) {
  //       setCheckedAll(true);
  //     } else {
  //       setCheckedAll(false);
  //     }
  //   } else {
  //     const newValues = newData.map(data => {
  //       data.isChecked = checked
  //       return data
  //     })
  //     setCheckedAll(checked);
  //     setNewData(newValues);
  //   }
  // };
  
  const getReportProdukPrice = async (toko, idPage) => {
    setIsLoading(true);

    const idPageParam = idPage !== '' ? `id_page=${idPage}&` : '';
    const tokoParam = toko !== '' ? `toko=${toko}&` : '';
    const idSoParam = `id_so=${mytaskProducts.id_so}&`;

    const params = idPageParam + tokoParam + idSoParam

    const response = await get_report_product_price(params);
    if (response?.status === 200) {
      if(response?.data?.length < newData.length) {
        swal('Oops', `Jumlah variant product dari link yang diinput tidak cukup!`, 'error');
      } else {
        setToko(toko)
        setIdPage(idPage)
        setVariantAfter(response?.data);
        setShowVariantAfter(true);
        setNewData(originalMytaskProducts)
        
        getMytaskProduct(idOrder, setMytaskProducts, setOriginalMytaskProducts, setIsLoading, setNewLink)
        setUpdate((prev) => !prev)
      }
    } else if (response?.status === 404) {
      swal('Oops', `${response?.message}`, 'error');
        
      getMytaskProduct(idOrder, setMytaskProducts, setOriginalMytaskProducts, setIsLoading, setNewLink)
      setUpdate((prev) => !prev)      
    } else if (response?.status === 500) {
      setVariantAfter([]);
      setShowVariantAfter(false);
      swal('Oops', response?.message || "There's a problem occured. Please try again in 15 minutes or contact IT", 'error');
    } else {
      swal('Oops', `${response?.message}`, 'error');
    }
    setIsLoading(false);
  }
  
  const setProductAfter = (e, id) => {
    const {name, value, type} = e.target

    const new_data = [...newData]
    if(name === 'product_after') {
      const selectedVariantAfter = variantAfter.filter((i) => i.sku === value)[0]
      if(selectedVariantAfter) {
        new_data[id][name] = type === 'number' ? ~~value : value
        new_data[id].product_after_name = selectedVariantAfter.product
        new_data[id].id_product_after = selectedVariantAfter.id_product
        new_data[id].id_request_after = selectedVariantAfter.id_request
        new_data[id].weight_after = selectedVariantAfter.weight
        new_data[id].ongkir_after = selectedVariantAfter.ongkir
        new_data[id].original_price_after = selectedVariantAfter.original_price
        new_data[id].price_allin_after = Math.ceil(selectedVariantAfter.price)
        new_data[id].actual_stock = Math.ceil(selectedVariantAfter.actual_stock)
        setNewData(new_data)

        // insert selected variant after into selectedVariantAfterArray
        const newVariantAfterArrayReplaced = new_data.filter((item) => item.product_after).map((data) => data.product_after)
        setSelectedVariantAfterArray(newVariantAfterArrayReplaced)
        setShowSearchVariant(false)
        setSearchVariant('')
      }
    } else {
      new_data[id][name] = type === 'number' ? ~~value : value
      setNewData(new_data)
    }
  }

  const set_AdditionalShipping = (value) => {
    const newValue = value.replaceAll('.','')
    setAdditionalShipping((~~newValue).toLocaleString("id-ID"))
  }
  
  const checkLink = () => {
    let keyword = newLink
    const pattern = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;

    var regex1688 = /(?:https?:\/\/)?(?:[^.]+\.)?1688\.com(\/.*)?$/gmi;
    var regexalibaba = /(?:https?:\/\/)?(?:[^.]+\.)?alibaba\.com(\/.*)?$/gmi;
    var regexMalibaba = /(?:https?:\/\/)?(?:[^.]+\.)?m.alibaba\.com(\/.*)?$/gmi;
    var regexIndonesianalibaba = /(?:https?:\/\/)?(?:[^.]+\.)?indonesian.alibaba\.com(\/.*)?$/gmi;
    var regextaobao = /(?:https?:\/\/)?(?:[^.]+\.)?taobao\.com(\/.*)?$/gmi;
    var regextmall = /(?:https?:\/\/)?(?:[^.]+\.)?tmall\.com(\/.*)?$/gmi;
  
    if (pattern.test(keyword)) {
      if (regex1688.test(keyword)) {
        const split = keyword.split("?");
        const url = split[0].split("/");
        const idProduct = url.pop().split(".")[0];
  
        // setToko('1688')
        // setIdPage(idProduct)
        getReportProdukPrice('1688', idProduct)
        return
      }
  
      if (regextaobao.test(keyword) || regextmall.test(keyword)) {
        if (/ocistok/.test(keyword)) {
          const split = keyword.split("?");
          const url = split[0].split("/");
          const idProduct = url.pop().split(".")[0];
  
          // setToko('taobao')
          // setIdPage(idProduct)
          getReportProdukPrice('taobao', idProduct)
          return
        } else {
          const split = keyword.match(/[\\?|\\&]id=([^&]*)/);
          if (split === null) {
            const url = keyword.split("/");
            const idProduct = url.pop().split(".")[0];
  
            // setToko('taobao')
            // setIdPage(idProduct)
            getReportProdukPrice('taobao', idProduct)
            return
          } else {
            // setToko('taobao')
            // setIdPage(split[1])
            getReportProdukPrice('taobao', split[1])
            return
          }
        }
      }
  
      if (regexMalibaba.test(keyword)) {
        const split = keyword.split("/");
        const idProduct = split[4];
  
        // setToko('alibaba')
        // setIdPage(idProduct)
        getReportProdukPrice('alibaba', idProduct)
        return
      }
  
      if (regexIndonesianalibaba.test(keyword)) {
        const split = keyword.split("?");
        const url = split[0].split("/");
        const productName = url.pop().split(".");
        const splitProductName = productName[productName.length - 2];
        const idProduct = splitProductName.split('-').pop();
  
        // setToko('alibaba')
        // setIdPage(idProduct)
        getReportProdukPrice('alibaba', idProduct)
        return
      }
  
      if (regexalibaba.test(keyword)) {
        const split = keyword.split("?");
        const url = split[0].split("/");
        const productName = url.pop().split(".")[0];
        const splitProductName = productName.split(/[_-]/);
        const idProduct = splitProductName.pop();
  
        // setToko('alibaba')
        // setIdPage(idProduct)
        getReportProdukPrice('alibaba', idProduct)
        return
      }

      if (!regex1688.test(keyword) && !regextaobao.test(keyword) && !regextmall.test(keyword) && !regexMalibaba.test(keyword) && !regexIndonesianalibaba.test(keyword) && !regexalibaba.test(keyword)) {
        swal('Oops', 'Maaf, link tidak dikenali!', 'error')
      }
    } else {
      swal('Fail', 'Maaf, link tidak valid!', 'error')
    }
  }
  
  const quoteLink = () => {
    let keyword = newLink
    const pattern = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;

    var regex1688 = /(?:https?:\/\/)?(?:[^.]+\.)?1688\.com(\/.*)?$/gmi;
    var regexalibaba = /(?:https?:\/\/)?(?:[^.]+\.)?alibaba\.com(\/.*)?$/gmi;
    var regexMalibaba = /(?:https?:\/\/)?(?:[^.]+\.)?m.alibaba\.com(\/.*)?$/gmi;
    var regexIndonesianalibaba = /(?:https?:\/\/)?(?:[^.]+\.)?indonesian.alibaba\.com(\/.*)?$/gmi;
    var regextaobao = /(?:https?:\/\/)?(?:[^.]+\.)?taobao\.com(\/.*)?$/gmi;
    var regextmall = /(?:https?:\/\/)?(?:[^.]+\.)?tmall\.com(\/.*)?$/gmi;
  
    if (pattern.test(keyword)) {
      if (!regex1688.test(keyword) && !regextaobao.test(keyword) && !regextmall.test(keyword) && !regexMalibaba.test(keyword) && !regexIndonesianalibaba.test(keyword) && !regexalibaba.test(keyword)) {
        swal('Oops', 'Maaf, link tidak dikenali!', 'error')
      } else {
        setOpenQuoteModal(true)
      }
    } else {
      swal('Fail', 'Maaf, link tidak valid!', 'error')
    }
  }

  if (!newData) {
    return null
  }
  return (
    <>
      {confirmationIssue !== '' &&
        <div className="max-h-[45vh] overflow-y-auto">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {/* <TableCell
                    align= 'center'
                    style={{
                      width: "5%",
                    }}
                  >
                    <input
                      type="checkbox"
                      style={{ width: '20px', height: '20px' }}
                      checked={checkedAll}
                      onChange={handleChecked}
                    />
                  </TableCell> */}
                  <TableCell
                    align= 'center'
                    style={{
                      width: "5%",
                    }}
                  >
                    PIC
                  </TableCell>
                  <TableCell
                    style={{
                      width: "20%",
                    }}
                  >
                    SKU
                  </TableCell>
                  <TableCell
                    style={{
                      width: "20%",
                    }}
                  >
                    Variant
                  </TableCell>
                  <TableCell
                    style={{
                      width: "20%",
                    }}
                  >
                    Link
                  </TableCell>
                  <TableCell
                    align= 'center'
                    style={{
                      width: "10%",
                    }}
                  >
                    Qty Ordered
                  </TableCell>
                  <TableCell
                    align= 'center'
                    style={{
                      width: "10%",
                    }}
                  >
                    Initial Price/pcs
                  </TableCell>
                  {confirmationIssue !== 'Need Adjustment' &&
                    <TableCell
                      align= 'center'
                      style={{
                        width: "10%",
                      }}
                    >
                      Qty Available
                    </TableCell>
                  }
                  {showVariantAfter &&
                  <>
                    {(confirmationIssue === 'Found New Supplier' && toko && idPage) &&
                      <>
                        <TableCell
                          align= 'center'
                          style={{
                            width: "10%",
                          }}
                        >
                          Variant
                        </TableCell>
                        <TableCell
                          align= 'center'
                          style={{
                            width: "10%",
                          }}
                        >
                          Actual Stock
                        </TableCell>
                        <TableCell
                          align= 'center'
                          style={{
                            width: "10%",
                          }}
                        >
                          Final Qty
                        </TableCell>
                      </>
                    }
                    {((confirmationIssue === 'Found New Supplier' && toko && idPage) || 
                        confirmationIssue === 'Need Adjustment') &&
                      <>
                        <TableCell
                          align= 'center'
                          style={{
                            width: "10%",
                          }}
                        >
                          New Price/pcs (Original)
                        </TableCell>
                        <TableCell
                          align= 'center'
                          style={{
                            width: "20%",
                          }}
                        >
                          New Price/pcs (All-in)
                        </TableCell>
                      </>
                    }
                    </>
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {newData?.length > 0 ? (
                  <>
                    {newData?.map((rowData, id) => (
                      // <Row rowData={row} key={index} id={index} />
                      <TableRow key={id}>
                      {/* <TableCell
                        align= 'center'
                        style={{
                          width: "5%",
                        }}
                      >
                        <input
                          type="checkbox"
                          style={{ width: '20px', height: '20px' }}
                          checked={rowData.isChecked}
                          onChange={(e) => handleChecked(e, id)}
                        />
                      </TableCell> */}
                      <TableCell
                        align= 'center'
                        style={{
                          width: "5%",
                        }}
                      >
                        <img width={25} height={25}
                          src={rowData.pic}
                          alt={rowData.product}
                          className="mx-auto"
                          onClick={() => setOpenLightbox(rowData.pic)}
                        />
                        {openLightbox === rowData.pic && (
                          <Lightbox
                            mainSrc={rowData.pic}
                            onCloseRequest={() => setOpenLightbox(false)}
                          />
                        )}
                      </TableCell>
                      <TableCell
                        style={{
                          width: "20%",
                          fontSize: ".75rem",
                        }}
                      >
                        <div className="w-full line-clamp-1" title={rowData.variant}>
                          {rowData.variant}
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          width: "20%",
                          fontSize: ".75rem",
                        }}
                      >
                        <div className="w-full line-clamp-2" title={rowData.product}>
                          {rowData.product}
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          width: "20%",
                          fontSize: ".75rem",
                        }}
                      >
                        <div className="w-full line-clamp-1 break-all" title={rowData.link}>
                          <a href={rowData.link} target='_blank'>
                            {rowData.link}
                          </a>
                        </div>
                      </TableCell>
                      <TableCell
                        align= 'center'
                        style={{
                          width: "10%",
                          fontSize: ".75rem",
                        }}
                      >
                        {rowData.qty}
                      </TableCell>
                      <TableCell
                        style={{
                          width: "10%",
                          fontSize: ".75rem",
                        }}
                      >
                        Rp. {(rowData.price).toLocaleString('id-ID')}
                      </TableCell>
                      {confirmationIssue !== 'Need Adjustment' &&
                          <TableCell
                            align= 'center'
                            style={{
                              width: "10%",
                              fontSize: ".75rem",
                            }}
                          >
                            {rowData.qty_available}
                          </TableCell>
                        }
                        {showVariantAfter &&
                        <>
                          {(confirmationIssue === 'Found New Supplier' && toko && idPage) &&
                            <>
                              <TableCell
                                align= 'center'
                                style={{
                                  width: "10%",
                                  fontSize: ".75rem",
                                }}
                              >
                                <div className="w-full h-[21px] relative">
                                  <div className="h-full w-[160px] border-2 rounded px-5 line-clamp-1" 
                                    title={rowData.product_after}
                                    onClick={() => setShowSearchVariant(id)}>
                                    {rowData.product_after}
                                  </div>
                                  {showSearchVariant === id && 
                                    <div ref={wrapperRef} className="absolute top-[23px] right-0">
                                      <input type="text" list="product_after" name='product_after' 
                                        className="border-2 rounded px-1"
                                        defaultValue={searchVariant} 
                                        placeholder="search variant"
                                        onChange={(e) => setProductAfter(e, id)}
                                        autoComplete="off" 
                                      />

                                      <datalist id="product_after">
                                        {variantAfter?.filter((i) => !selectedVariantAfterArray.includes(i.sku)).map((item, index) => (
                                          <option key={index} value={item.sku} />
                                        ))}
                                      </datalist>
                                    </div>
                                  }
                                </div>
                                {/* <select name='product_after' value={rowData.product_after} 
                                  className="border-2 rounded" 
                                  onChange={(e) => setProductAfter(e, id)} 
                                >
                                  <option value="">
                                    <input type="text" name="search-sku" id="search-sku" />
                                  </option>
                                  {variantAfter.map((item, index) => (
                                    <option key={index} value={`${item.id_product}->${item.id_request}`}>{item.sku}</option>
                                  ))}
                                </select> */}
                              </TableCell>
                              <TableCell
                                align= 'center'
                                style={{
                                  width: "10%",
                                  fontSize: ".75rem",
                                }}
                              >
                                {rowData.actual_stock}
                              </TableCell>
                              <TableCell
                                align= 'center'
                                style={{
                                  width: "10%",
                                  fontSize: ".75rem",
                                }}
                              >
                                <input type="number" name="qty_available_after" id="qty_available_after" 
                                  value={rowData.qty_available_after} 
                                  className="border-2 rounded px-2 w-16 text-center" 
                                  onChange={(e) => rowData.actual_stock >= e.target.value && setProductAfter(e, id)} 
                                />
                              </TableCell>
                            </>
                          }
                          {((confirmationIssue === 'Found New Supplier' && toko && idPage) || 
                              confirmationIssue === 'Need Adjustment') &&
                            <>
                              {confirmationIssue === 'Found New Supplier' &&
                                <>
                                  <TableCell
                                    align= 'center'
                                    style={{
                                      width: "10%",
                                      fontSize: ".75rem",
                                      color: "blue",
                                    }}
                                  >
                                    {rowData.original_price_after ? `RMB ${Math.ceil(rowData.original_price_after).toLocaleString('id-ID')}` : '-'}
                                  </TableCell>
                                  <TableCell
                                    align= 'center'
                                    style={{
                                      width: "20%",
                                      fontSize: ".75rem",
                                      color: "blue",
                                    }}
                                  >
                                    <div className="whitespace-nowrap">
                                      {rowData.price_allin_after ? `Rp. ${Math.ceil(rowData.price_allin_after).toLocaleString('id-ID')}` : '-'}
                                    </div>
                                  </TableCell>
                                </>
                              }
                              {confirmationIssue === 'Need Adjustment' &&
                                <>
                                  <TableCell
                                    align= 'center'
                                    style={{
                                      width: "10%",
                                      fontSize: ".75rem",
                                      color: "blue",
                                    }}
                                  >
                                    <input type="number" name="new_price" id="new_price" 
                                      value={rowData.new_price} 
                                      className="border-2 rounded px-2 w-16 text-center" 
                                      onChange={(e) => setProductAfter(e, id)} 
                                    />
                                  </TableCell>
                                  <TableCell
                                    align= 'center'
                                    style={{
                                      width: "20%",
                                      fontSize: ".75rem",
                                      color: "blue",
                                    }}
                                  >
                                    <div className="whitespace-nowrap">
                                      {/* Rp. {(Math.ceil((rowData.changed_price_indo) + rowData.tax + rowData.processing_fee + rowData.cbm_calc)).toLocaleString('id-ID')} */}
                                      Rp. {(Math.ceil((rowData.new_price * mytaskProducts.currency) + 
                                            ((rowData.new_price * mytaskProducts.currency) * 0.11) + 
                                            ((rowData.new_price * mytaskProducts.currency) * rowData.level_discount) + 
                                            rowData.cbm_calc)).toLocaleString('id-ID')}
                                    </div>
                                  </TableCell>
                                </>
                              }
                            </>
                          }
                        </>
                        }
                    </TableRow>
                    ))}
                  </>
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      align="center"
                      style={{ height: "30vh" }}
                    >
                      No data available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      }
      {(confirmationIssue === 'Found New Supplier' || confirmationIssue === 'Need Adjustment') &&
        <div className="grid grid-cols-2 gap-8 mt-3">
          <div className="new-link-form">
            {confirmationIssue === 'Found New Supplier' &&
            <>
              <div className="flex gap-x-3">
                <div className="whitespace-nowrap">New Link</div>
                <input type="text" name="new-link" id="new-link"
                  className="border-2 rounded w-full px-2"
                  value={newLink}
                  onChange={(e) => setNewLink(e.target.value)}
                />
                <div className="px-3 py-1 rounded-md bg-gray-300 hover:bg-blue-400 cursor-pointer transition duration-300" onClick={checkLink}>Check</div>
                {mytaskProducts.status === 'Quoting' &&
                  <div className="px-3 py-1 rounded-md bg-gray-300 hover:bg-blue-400 cursor-pointer transition duration-300" onClick={quoteLink}>Quote</div>
                }
              </div>
              
              {!variantAfter?.length ? null :
                <div className="product-info w-full line-clamp-2 mt-3" title={variantAfter[0].product}>
                  <strong className="text-blue-800">Product Name</strong> : {variantAfter[0].product || ''}
                </div>
              }
            </>
            }
          </div>
          <div className="totals">
            <div className="w-2/3 ml-auto text-sm">
              <div className="space-y-1">
                <div className="flex justify-between items-center">
                  <div>Additional Shipping Fee</div>
                  <input type="text" name="additional-shipping-fee" id="additional-shipping-fee" 
                    className="border-2 rounded w-28 px-2 text-right"
                    value={additionalShipping}
                    onChange={(e) => set_AdditionalShipping(e.target.value)}
                  />
                </div>
                {/* <div className="flex justify-between items-center pr-2">
                  <div>Shipping Process</div>
                  <div>Rp. 50.000</div>
                </div> */}
                <div className="flex justify-between items-center pr-2">
                  <div>Customer Paid</div>
                  <div>Rp. {(~~mytaskProducts.total_paid).toLocaleString('id-ID')}</div>
                </div>
                {/* <div className="flex justify-between items-center pr-2">
                  <div>Initial Shipping Fee</div>
                  <div>Rp. {(~~(mytaskProducts.local_shipping_cost?.toString().replaceAll('.',''))).toLocaleString('id-ID')}</div>
                </div> */}
                <div className="flex justify-between items-center pr-2">
                  <div>Final Shipping Fee</div>
                  <div>Rp. {localShipping.toLocaleString('id-ID')}</div>
                </div>
                <div className="flex justify-between items-center pr-2">
                  <div>Final Total</div>
                  <div>Rp. {finalTotal.toLocaleString('id-ID')}</div>
                </div>
                <div className="flex justify-between items-center pr-2 text-lg font-bold">
                  <div className="">Grand Total</div>
                  <div>Rp. {(finalTotal + ~~localShipping + ~~(additionalShipping?.replaceAll('.','')) - ~~mytaskProducts.total_paid).toLocaleString('id-ID')}**</div>
                </div>
              </div>

              <div className="note mt-4 text-red-500 font-bold text-sm flex gap-x-2">
                <span className="whitespace-nowrap">Note : </span>
                {(finalTotal + (~~(localShipping?.toString().replaceAll('.',''))) + ~~(additionalShipping.replaceAll('.','')) - ~~mytaskProducts.total_paid) < 0 ? (
                  <span>Need to refund customer for Rp. {(Math.abs(finalTotal + (~~(localShipping?.toString().replaceAll('.',''))) + ~~(additionalShipping.replaceAll('.','')) - ~~mytaskProducts.total_paid)).toLocaleString('id-ID')}</span>
                )
                : (finalTotal + (~~(localShipping?.toString().replaceAll('.',''))) + ~~(additionalShipping.replaceAll('.','')) - ~~mytaskProducts.total_paid) > 0 ?(
                  <span>Customer need to pay additional fee for Rp. {(Math.abs(finalTotal + (~~(localShipping?.toString().replaceAll('.',''))) + ~~(additionalShipping.replaceAll('.','')) - ~~mytaskProducts.total_paid)).toLocaleString('id-ID')}</span>
                )
                : <span>No additional fee charged</span>
                }
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}
