import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// import RepackingInputTable from './RepackingInputTable';
import swal from 'sweetalert';
import {
  // insertRepackingData,
  // getDetailOrderData,
  getDataPrintLabelDetails,
  getDetailOrderDataRepacking,
  updateDetailOrderDataRepacking,
} from 'service/api';
import { useSelector } from 'react-redux';
import { selectAddData } from 'redux/addDataSlice';
import PrintBarcodeModal from 'components/General/DetailOrderModal/CustomerDetail/PrintBarcodeModal';
import EditRepackingInputTable from './EditRepackingInputTable';
import { useTranslation } from "react-i18next";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1200,
  maxHeight: 650,
  bgcolor: 'background.paper',
  border: '1px solid lightgray',
  boxShadow: 24,
  borderRadius: '5px',
  p: 4,
};

export default function EditRepackingInput({
  id_so,
  id_po,
  products,
  setUpdate,
  totalQty,
  RowCategory,
  totalBox,
}) {
  const { category } = useSelector(selectAddData);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    getDetailOrder();
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const [initBox, setInitBox] = useState([]);
  const [box, setBox] = useState([]);
  const [repacking, setRepacking] = useState({ numOfCarton: '', category: '' });
  const [show, setShow] = useState(false);
  // const [newDataProduct, setNewDataProduct] = useState(
  //   products.map((data) => {
  //     return {
  //       idProduk: data.idProduk,
  //       sku: data.sku,
  //       name: data.name,
  //       qty: data.qty,
  //       avQty: data.qty_whchina,
  //       orderedQty: data.qty,
  //     };
  //   })
  // );
  const [selectedSku, setSelectedSku] = useState([
    {
      id: 1,
      product: '',
      quantity: '',
      reason: '',
    }
  ])
  const [validQtyDiff, setValidQtyDiff] = useState(true)

  const [dataBarcode, setDataBarcode] = useState(null);
  const [openBarcode, setOpenBarcode] = useState(false);
  const [detailOrder, setDetailOrder] = useState(null);
  const [isBolean, setIsBolean] = useState({
    isLoadingBarcode: false,
  });
  const [isLoading, setLoading] = useState(false);
  const [totalLeftoversProductsQty, setTotalLeftoversProductsQty] = useState()
  const [confirmAcknowledge, setConfirmAcknowledge] = useState(false)

  const { t } = useTranslation();

  useEffect(() => {
    if(detailOrder && box.length === 0) {
      generateCarton()
    }
  }, [detailOrder, box.length])
  
  useEffect(() => {
    if(box.length !== 0) {
      setRepacking((prev) => {
        return { ...prev, numOfCarton: box.length };
      });
    }
  }, [box])

  const handleChangeBoxData = (e) => {
    const { name, value } = e.target;
    if (name !== 'category') {
      setBox([]);
    }

    // if (name === 'numOfCarton') {
    //   setNewDataProduct(
    //     products.map((data) => {
    //       return {
    //         idProduk: data.idProduk,
    //         sku: data.sku,
    //         name: data.name,
    //         qty: data.qty,
    //         avQty: data.qty_whchina,
    //         orderedQty: data.qty,
    //       };
    //     })
    //   );
    // }
    setRepacking((prev) => {
      return { ...prev, [name]: value };
    });
  };
  
  const generateCarton = () => {
    if (repacking.numOfCarton !== '' && repacking.numOfCarton.toString() !== '0') {
      if (Number(repacking.numOfCarton <= detailOrder.totalQty)) {
          for (let i = 0; i < detailOrder?.box?.length; i++) {
            let totalOrdered = 0
            let totalReceived = 0
            const boxProduct = [...detailOrder?.box[i].box_product].map(
              (order, index) => {
                totalOrdered = i === 0 ? totalOrdered + order.qtyInput + order.qty_whchina : totalOrdered + order.qtyInput
                totalReceived = i === 0 ? totalReceived + order.qtyInput + order.qty_whchina : totalReceived + order.qtyInput

                return {
                  sku: order.sku,
                  name: order.product,
                  qty: order.qty_whchina,
                  actual_qty: order.qty_whchina,
                  idProduct: order.idvariant,
                  qtyInput: order.qtyInput,
                  avQty: order.qty_whchina,
                  orderedQty: order.qty,
                  image: order.image,
                };
              }
            );
            setBox((prev) => [
              ...prev,
              {
                id_box: detailOrder?.box[i].id_box,
                panjang: detailOrder?.box[i].panjang,
                width: detailOrder?.box[i].width,
                height: detailOrder?.box[i].height,
                weight: detailOrder?.box[i].weight / 1000 >= 1 ? detailOrder?.box[i].weight / 1000 : 1,
                image_packing_kayu: detailOrder?.box[i].image_packing_kayu,
                total_ordered: totalOrdered,
                total_received: totalReceived,
                box_product: boxProduct,
              },
            ]);
            setInitBox((prev) => [
              ...prev,
              {
                total_ordered: totalOrdered,
                total_received: totalReceived,
              },
            ]);
          }
          setShow(true);
      } else {
        swal('Oops', `Over maximum number of carton!`, 'error');
      }
    }
  };
  
  const addNewCarton = () => {
    const lastIdBoxNumber = parseInt(box[box.length - 1].id_box.split('-').slice(-1)[0])
    
    const boxProduct = [...box[0].box_product].map(
      (order) => {
        return {
          sku: order.sku,
          name: order.name,
          qty: order.qty,
          actual_qty: order.actual_qty,
          idProduct: order.idProduct,
          qtyInput: '',
          avQty: order.avQty,
          orderedQty: order.orderedQty,
          image: order.image,
        };
      }
    );
    
    setBox((prev) => [
      ...prev,
      {
        id_box: detailOrder.id_so + '-' + (lastIdBoxNumber + 1),
        panjang: '',
        width: '',
        height: '',
        weight: '',
        image_packing_kayu: '',
        box_product: boxProduct,
      },
    ]);
  }

  const checkSimilarID = (boxData) => {
    let arrayStatus = [];
    for (let i = 0; i < boxData.length; i++) {
      let similar = false;
      let uniqueId = [];
      for (let j = 0; j < boxData[i].box_product.length; j++) {
        if (uniqueId.includes(boxData[i].box_product[j].id_product)) {
          similar = true;
          break;
        } else {
          uniqueId.push(boxData[i].box_product[j].id_product);
        }
      }
      arrayStatus.push(similar);
    }
    return arrayStatus;
  };

  const submitRepackingData = async () => {
    const validateInput = box.filter((data) => ~~data.panjang === 0 || ~~data.width === 0 || ~~data.height === 0 || ~~data.weight === 0);
    
    const validateInputImage = box.filter((data) => (detailOrder.is_packing_kayu && !data.image_packing_kayu));
    
    if (validateInput.length > 0) {
      swal('Oops', 'Input not valid !', 'error');
      return;
    }
    if (validateInputImage.length > 0) {
      swal('Oops', 'Please complete upload image !', 'error');
      return;
    }
    
    setLoading(true);
    
    const boxDescription = box.map((item) => {
      return {
        id_box: item.id_box,
        panjang: item.panjang,
        width: item.width,
        height: item.height,
        weight: (Math.ceil(parseFloat(item.weight) * 1000)).toString(),
        image_packing_kayu: item.image_packing_kayu,
      }
    })

    const rejectedProducts = selectedSku.filter((sku) => sku.quantity !== '' && sku.quantity != '0')
    const newRejectedProducts = []
    if(rejectedProducts) {
      rejectedProducts.map((item) => {
        newRejectedProducts.push({
          idvariant: item.product.idvariant,
          qty: ~~item.quantity,
          reason: item.reason
        })
      })

    }

    const body = JSON.stringify({
      id_so: id_so.toString(),
      invoice: id_po,
      category: parseInt(repacking.category),
      box_description: boxDescription,
      rejected_product_lists: newRejectedProducts,
    });
    
    const data = await updateDetailOrderDataRepacking(body);
    if (data?.status === 200) {
      handleOpenBarcode();
      // swal('Success', 'Repacking submitted succesfully', 'success');
      // setBox([]);
      // setShow(false);
      // setUpdate((prev) => !prev);
    }
    if (data?.status === 400) {
      swal('Oops', data?.message, 'error');
    }
    setLoading(false);
  }
  // KODINGAN INI JANGAN DIHAPUS, UNTUK JAGA-JAGA ///
  // const submitRepackingData = async () => {
  //   const tempBox = []
  //   const emptyBox = []
  //   box.forEach((data) => {
  //     const isDirty = data.box_product.filter((boxData) => boxData.qtyInput !== '' && boxData.qtyInput != '0');
      
  //     if(isDirty.length !== 0) {
  //      tempBox.push(data)
  //     }
  //     if(isDirty.length === 0) {
  //      emptyBox.push(data.id_box)
  //     }
  //   });

  //   const finalBox = [...box]

  //   selectedSku.filter((sku) => sku.quantity !== '' && sku.quantity !== '0').forEach((variant, idx) => {
  //     let unreceivedItemsQty = parseInt(variant.quantity)
  //     while(unreceivedItemsQty > 0) {
  //       finalBox.forEach((box, index) => {
  //         box.box_product.map((prod, id) => {
  //           if(unreceivedItemsQty > 0 && prod.sku === variant.product.sku && prod.qtyInput > 0) {
  //             prod.qtyInput = prod.qtyInput - 1
  //             unreceivedItemsQty--
  //           }
  //         })
  //       })
  //     }
  //   })
    
  //   const box_submit = box.map((data) =>  {
  //     return {
  //       ...data,
  //       box_product: data.box_product.filter((boxData) => boxData.qtyInput !== '' && boxData.qtyInput != '0').map((product) => {
  //         return {
  //           id_product: product.idProduct,
  //           qty_input: Number(product.qtyInput),
  //           // actual_qty: Number(product.actual_qty),
  //         };
  //       }),
  //     };
  //   });

  //   const validateInput = box_submit.filter(
  //     (data) =>
  //       data.panjang === '' || data.panjang == '0' ||
  //       data.width === '' || data.width == '0' ||
  //       data.height === '' || data.height == '0' ||
  //       data.weight === '' || data.weight == '0' ||
  //       data.box_product.filter(
  //         (data) =>
  //           data.qty === '' || data.id_product === '' || data.actualQty === ''
  //       ).length > 0
  //   );

  //   const validateIdProduct = checkSimilarID(box_submit);

  //   if (validateIdProduct.includes(true)) {
  //     swal('Oops', "Product can't be same each carton !", 'error');
  //     return;
  //   }

  //   if (validateInput.length > 0) {
  //     swal('Oops', 'Input not valid !', 'error');
  //     return;
  //   }

  //   if (repacking.category === '') {
  //     swal('Oops', 'Please select category before submit !', 'error');
  //     return;
  //   }

  //   if (emptyBox.length > 0) {
  //     swal('Oops', 'You still have empty box!', 'error');
  //     return;
  //   }
  //   setLoading(true);

  //   const body = JSON.stringify({
  //     id_so: id_so.toString(),
  //     invoice: id_po,
  //     category: parseInt(repacking.category),
  //     // total_product: products.length,
  //     box: box_submit,
  //   });
  
  //   // const data = await updateDetailOrderDataRepacking(body);

  //   // if (data?.status === 200) {
  //   //   handleOpenBarcode();
  //   //   // swal('Success', 'Repacking submitted succesfully', 'success');
  //   //   // setBox([]);
  //   //   // setShow(false);
  //   //   // setUpdate((prev) => !prev);
  //   // }
  //   // if (data?.status === 400) {
  //   //   swal('Oops', data?.message, 'error');
  //   // }
  //   setLoading(false);
  // }; // KODINGAN INI JANGAN DIHAPUS, UNTUK JAGA-JAGA ///

  const getDetailOrder = async () => {
    setLoading(true);
    const data = await getDetailOrderDataRepacking(id_so);
    if (data?.status === 200) {
      setRepacking((prev) => {return {...prev, numOfCarton: data.data?.box.length, category: data.data?.category}})
      setDetailOrder(data.data);
    } else {
      // handleClose();
      swal('Oops', data?.message, 'error');
    }
    setLoading(false);
  };
  
  const handleOpenBarcode = async () => {
    const params = id_so;

    setIsBolean({
      isLoadingBarcode: true,
    });

    const response = await getDataPrintLabelDetails(params);
    if (response?.status === 200) {
      setIsBolean({
        isLoadingBarcode: false,
      });
      setDataBarcode(response?.data);
    } else {
      setIsBolean({
        isLoadingBarcode: false,
      });
    }

    setOpenBarcode(true);
    setShow(false);
  };
  const handleCloseBarcode = () => {
    setBox([]);
    setShow(false);
    setUpdate((prev) => !prev);
    // setOpenBarcode(false);
  };
  
  const copyBox = JSON.parse(JSON.stringify(initBox))
  const total_received_init = copyBox?.reduce((total, curr) => curr.total_received + total, 0)

  const totalUnreceivedItemQty = ~~selectedSku.reduce((total, curr) => ~~curr.quantity + total, 0)
  return (
    <div>
      <div
        className="py-2 px-5 border text-blue-500  text-sm border-blue-500 rounded-md text-center cursor-pointer"
        onClick={() => {
          handleOpen();
        }}>
        {t("Edit Processing")}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style} className="overflow-y-scroll variant-scroll">
          <div className="flex justify-end -mt-5">
            <IconButton onClick={handleClose} style={{ textAlign: 'right' }}>
              <CloseIcon />
            </IconButton>
          </div>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Detail Product: {id_so}
          </Typography>
          <div className="flex my-5 space-x-5">
            <div className="w-1/3 space-y-3">
              <div className="flex justify-between">
                <label className="text-sm font-semibold">
                  {t("Number of Carton")}
                </label>
                <input
                  type="text"
                  // onChange={handleChangeBoxData}
                  name="numOfCarton"
                  placeholder={`${t("Max")} ${totalQty} ${
                    totalQty > 1 ? t("Cartons") : t("Carton")
                  }`}
                  value={repacking.numOfCarton}
                  className="p-1 rounded-md border border-gray-300 w-52 focus:outline-blue"
                  disabled
                />
              </div>
              <div className="flex justify-between">
                <label className="text-sm font-semibold">Order Number</label>
                <input
                  type="text"
                  disabled={true}
                  defaultValue={id_so}
                  className="p-1 rounded-md border border-gray-300 w-52 focus:outline-blue"
                />
              </div>
              {/* <div className="flex justify-between">
                <label className="text-sm font-semibold">PO Number</label>
                <input
                  type="text"
                  disabled={true}
                  defaultValue={id_po}
                  className="p-1 rounded-md border border-gray-300 w-52 focus:outline-blue"
                />
              </div> */}
              <div className="flex justify-between">
                <label className="text-sm font-semibold">Category</label>
                <select
                  className="p-1 rounded-md border border-gray-300 w-52 focus:outline-blue"
                  name="category"
                  value={repacking.category}
                  onChange={handleChangeBoxData}
                  disabled={isLoading}>
                  <option value="">Choose Category</option>
                  {category?.map((cat, index) => (
                    <option key={index} value={cat.value}>{cat.category}</option>
                  ))}
                </select>
              </div>
            </div>
            {/* <div className="w-2/3 flex  items-end">
              <button
                className="p-1 px-2 bg-blue-500 text-white rounded-md"
                onClick={() =>
                  box.length === 0
                    ? repacking.category
                      ? generateCarton()
                      : swal('Oops', 'Please choose category first!', 'error')
                    : null
                }>
                {t("Add Carton")}
              </button>
            </div> */}
          </div>
          {show && (
            <>
              <EditRepackingInputTable
                id_so={id_so}
                box={box}
                setBox={setBox}
                setUpdate={setUpdate}
                products={products}
                // newDataProduct={newDataProduct}
                // setNewDataProduct={setNewDataProduct}
                total_received_init={total_received_init}
                totalUnreceivedItemQty={totalUnreceivedItemQty}
                detailOrderProducts={detailOrder?.box?.[0]?.box_product}
                detailOrder={detailOrder}
                selectedSku={selectedSku}
                setSelectedSku={setSelectedSku}
                setValidQtyDiff={setValidQtyDiff}
                isEditing={true}
                addNewCarton={addNewCarton}
                totalLeftoversProductsQty={totalLeftoversProductsQty}
                setTotalLeftoversProductsQty={setTotalLeftoversProductsQty}
                confirmAcknowledge={confirmAcknowledge}
                setConfirmAcknowledge={setConfirmAcknowledge}
              />
            </>
          )}
          <hr className="my-3" />
          <div className="flex justify-center">
            {show && (
              <button
                className={`${
                  box.length === 0 || !validQtyDiff || isLoading || (totalLeftoversProductsQty && totalLeftoversProductsQty !== 0 && !confirmAcknowledge) ? 'bg-gray-400' : 'bg-blue-500'
                } p-2 px-4 text-white rounded-md`}
                onClick={() => box.length !== 0 && validQtyDiff && !isLoading ? submitRepackingData() : null}
                disabled={box.length === 0 || !validQtyDiff || isLoading || (totalLeftoversProductsQty && totalLeftoversProductsQty !== 0 && !confirmAcknowledge)}>
                {isLoading ? "Loading..." : t("Submit")}
              </button>
            )}
          </div>
        </Box>
      </Modal>

      <PrintBarcodeModal
        customerData={dataBarcode?.[0]?.customer}
        // id_group={id_group}
        // id_so={id_so}
        open={openBarcode}
        handleClose={handleCloseBarcode}
        data={dataBarcode}
        isBolean={isBolean}
      />
    </div>
  );
}
