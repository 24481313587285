import Compressor from "compressorjs";
import React, { useState } from "react";
import swal from "sweetalert";
import MicIcon from "@mui/icons-material/Mic";
import SettingsVoiceIcon from "@mui/icons-material/SettingsVoice";
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

const ChatEditorOCS = (props) => {
  const {
    handleChatEditor,
    submitMessage,
    submitMessageKeyDown,
    setOpen,
    open,
    openVid,
    setOpenVid,
    openDoc,
    setOpenDoc,
    setFileUpload,
    setShowModalUpload,
    setFileUploadBlob,
    setShowAttachmentMenu,
    showAttachmentMenu,
    setOpenAudio,
    openAudio,
    isCounting
  } = props;
  const [editor, setEditor] = useState("");
  const [recording, setRecording] = useState(false);
  const [audioChunks, setAudioChunks] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const chunks = [];

  const startRecording = async () => {
    try {
      const stream = await navigator?.mediaDevices?.getUserMedia({
        audio: true,
      });
      const recorder = new MediaRecorder(stream);
      // console.log(recorder)


      if (!recorder) {
        // Mikrofon tidak tersedia
        swal("Mikrofon tidak tersedia atau tidak diizinkan.");
        return;
      }

      recorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunks.push(event.data);
        }
      };

      recorder.onstop = () => {
        const audioBlob = new Blob(chunks, { type: "audio/wav" });
        const filename = generateFilename();
        const convertName = new File([audioBlob], filename, {
          type: "audio/wav",
        });

        if (convertName && convertName.size > 15 * 1024 * 1024) {
          swal("Ukuran document terlalu besar (maksimal 15 MB).");
        } else {
          insertBlob(convertName);
          const blobURL = URL.createObjectURL(convertName);
          insertImage(blobURL);
          setShowModalUpload(true);
        }
      };

      recorder.start();
      setRecording(true);
      setMediaRecorder(recorder);
    } catch (error) {
      swal("Error accessing microphone:");
    }
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setRecording(false);
    }
  };

  const handleSubmit = (e) => {
    setEditor(e.target.value);
  };

  function generateRandomString(length) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
  }

  const generateFilename = () => {
    const timestamp = Date.now();
    return `recording_${timestamp}.wav`;
  };

  function createBlobWithFileInfo(blob, fileName, lastModified, types) {
    const blobWithInfo = new Blob([blob], { type: types });
    blobWithInfo.name = fileName;
    blobWithInfo.lastModified = lastModified;
    const file = new File([blobWithInfo], fileName, {
      type: types,
      lastModified: Date.now(),
    });
    return file;
  }

  const compressImage = async (blob) => {
    return new Promise((resolve, reject) => {
      new Compressor(blob, {
        quality: 0.9,
        maxWidth: 800,
        maxHeight: 800,
        success(resultBlob) {
          resolve(resultBlob);
        },
        error(err) {
          reject(err);
        },
      });
    });
  };

  const randomString = generateRandomString(10);
  const changeName = `image_${randomString}.jpeg`;

  const handlePaste = async (e) => {
    const items = (e.clipboardData || e.originalEvent.clipboardData).items;
    for (const item of items) {
      const itemType = item.type;

      if (item.type.indexOf("image") !== -1) {
        const blob = item.getAsFile();
        if (blob && blob.size > 5 * 1024 * 1024) {
          swal("Ukuran document terlalu besar (maksimal 15 MB).");
        } else {
          const compressedBlob = await compressImage(blob);
          const newBlobLastModified = compressedBlob.lastModified;
          const newBlobType = "image/jpeg";
          const newBlobName = changeName;
          const newBlob = createBlobWithFileInfo(
            compressedBlob,
            newBlobName,
            newBlobLastModified,
            newBlobType
          );
          insertBlob(newBlob);

          const reader = new FileReader();
          reader.onload = function (event) {
            const base64 = event.target.result;
            insertImage(base64);
          };
          reader.readAsDataURL(blob);
          setShowModalUpload(true);
        }
      } else if (itemType === "application/pdf") {
        const pdfBlob = item.getAsFile();
        if (pdfBlob && pdfBlob.size > 15 * 1024 * 1024) {
          swal("Ukuran document terlalu besar (maksimal 15 MB).");
        } else {
          insertBlob(pdfBlob);
          // const reader = new FileReader();
          // reader.onload = function (event) {
          //   const base64 = event.target.result;
          //   insertImage(base64);
          // };
          // reader.readAsDataURL(pdfBlob);
          setShowModalUpload(true);
        }
      } else if (
        itemType === "application/msword" ||
        itemType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        const wordBlob = item.getAsFile();
        if (wordBlob && wordBlob.size > 15 * 1024 * 1024) {
          swal("Ukuran document terlalu besar (maksimal 15 MB).");
        } else {
          insertBlob(wordBlob);
          setShowModalUpload(true);
        }
      } else if (
        itemType === "application/vnd.ms-powerpoint" ||
        itemType ===
        "application/vnd.openxmlformats-officedocument.presentationml.presentation"
      ) {
        const pptBlob = item.getAsFile();
        if (pptBlob && pptBlob.size > 15 * 1024 * 1024) {
          swal("Ukuran document terlalu besar (maksimal 15 MB).");
        } else {
          insertBlob(pptBlob);
          setShowModalUpload(true);
        }
      } else if (
        itemType === "text/csv" ||
        itemType === "application/vnd.ms-excel" ||
        itemType ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        const pptBlob = item.getAsFile();
        if (pptBlob && pptBlob.size > 15 * 1024 * 1024) {
          swal("Ukuran document terlalu besar (maksimal 15 MB).");
        } else {
          insertBlob(pptBlob);
          setShowModalUpload(true);
        }
      } else if (itemType === "video/mp4") {
        const videoBlob = item.getAsFile();
        if (videoBlob && videoBlob.size > 30 * 1024 * 1024) {
          swal("Ukuran video terlalu besar (maksimal 30 MB).");
        } else {
          insertBlob(videoBlob);
          const blobURL = URL.createObjectURL(videoBlob);
          insertImage(blobURL);
          setShowModalUpload(true);
        }
      }
    }
  };

  const handleDrag = async (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    const itemType = file.type;

    if (
      itemType === "image/png" ||
      itemType === "image/jpeg" ||
      itemType === "image/jpg"
    ) {
      if (file && file.size > 5 * 1024 * 1024) {
        swal("Ukuran document terlalu besar (maksimal 15 MB).");
      } else {
        const compressedBlob = await compressImage(file);
        const newBlobLastModified = compressedBlob.lastModified;
        const newBlobType = "image/jpeg";
        const newBlobName = changeName;
        const newBlob = createBlobWithFileInfo(
          compressedBlob,
          newBlobName,
          newBlobLastModified,
          newBlobType
        );
        insertBlob(newBlob);
        const reader = new FileReader();
        reader.onload = function (event) {
          const base64 = event.target.result;
          insertImage(base64);
        };
        reader.readAsDataURL(file);
        setShowModalUpload(true);
      }
    } else if (itemType === "application/pdf") {
      if (file && file.size > 15 * 1024 * 1024) {
        swal("Ukuran document terlalu besar (maksimal 15 MB).");
      } else {
        insertBlob(file);
        // const reader = new FileReader();
        // reader.onload = function (event) {
        //   const base64 = event.target.result;
        //   insertImage(base64);
        // };
        // reader.readAsDataURL(pdfBlob);
        setShowModalUpload(true);
      }
    } else if (
      itemType === "application/msword" ||
      itemType ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      if (file && file.size > 15 * 1024 * 1024) {
        swal("Ukuran document terlalu besar (maksimal 15 MB).");
      } else {
        insertBlob(file);
        setShowModalUpload(true);
      }
    } else if (
      itemType === "application/vnd.ms-powerpoint" ||
      itemType ===
      "application/vnd.openxmlformats-officedocument.presentationml.presentation"
    ) {
      if (file && file.size > 15 * 1024 * 1024) {
        swal("Ukuran document terlalu besar (maksimal 15 MB).");
      } else {
        insertBlob(file);
        setShowModalUpload(true);
      }
    } else if (
      itemType === "text/csv" ||
      itemType === "application/vnd.ms-excel" ||
      itemType ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      if (file && file.size > 15 * 1024 * 1024) {
        swal("Ukuran document terlalu besar (maksimal 15 MB).");
      } else {
        insertBlob(file);
        setShowModalUpload(true);
      }
    } else if (itemType === "video/mp4") {
      if (file && file.size > 15 * 1024 * 1024) {
        swal("Ukuran video terlalu besar (maksimal 30 MB).");
      } else {
        insertBlob(file);
        const blobURL = URL.createObjectURL(file);
        insertImage(blobURL);
        setShowModalUpload(true);
      }
    }
  };

  const insertBlob = (blob) => {
    const newBlob = blob;
    setFileUploadBlob(newBlob);
  };

  const insertImage = (base64) => {
    const newEditor = base64;
    setFileUpload(newEditor);
  };

  return (
    <>
      {showAttachmentMenu && (
        <div className="absolute bottom-[120px] left-[821] rounded-xl w-auto flex h-fit z-20">
          <div className="flex h-fit bg-[#eff1f5] rounded-xl">
            <div className="flex flex-col m-2 justify-center items-center">
              <div
                className="bg-custom-100 flex items-center justify-center mx-2 w-14 h-14 rounded-xl cursor-pointer"
                onClick={() => setOpenDoc(!openDoc)}>
                <svg
                  width="16"
                  height="20"
                  viewBox="0 0 16 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M14.2649 2.60781C12.0571 0.399997 8.46174 0.399997 6.25627 2.60781L0.139084 8.72031C0.0992406 8.76015 0.0781467 8.81406 0.0781467 8.87031C0.0781467 8.92656 0.0992406 8.98047 0.139084 9.02031L1.00393 9.88515C1.04346 9.92451 1.09697 9.94661 1.15276 9.94661C1.20854 9.94661 1.26205 9.92451 1.30158 9.88515L7.41877 3.77265C8.17815 3.01328 9.1883 2.59609 10.2617 2.59609C11.3352 2.59609 12.3453 3.01328 13.1024 3.77265C13.8617 4.53203 14.2789 5.54218 14.2789 6.61328C14.2789 7.68672 13.8617 8.69453 13.1024 9.4539L6.86799 15.6859L5.85783 16.6961C4.9133 17.6406 3.37815 17.6406 2.43362 16.6961C1.97658 16.2391 1.7258 15.632 1.7258 14.9852C1.7258 14.3383 1.97658 13.7312 2.43362 13.2742L8.61877 7.0914C8.7758 6.93672 8.98205 6.85 9.20236 6.85H9.20471C9.42502 6.85 9.62893 6.93672 9.78362 7.0914C9.94065 7.24843 10.025 7.45468 10.025 7.675C10.025 7.89297 9.9383 8.09922 9.78362 8.2539L4.72815 13.3047C4.6883 13.3445 4.66721 13.3984 4.66721 13.4547C4.66721 13.5109 4.6883 13.5648 4.72815 13.6047L5.59299 14.4695C5.63252 14.5089 5.68603 14.531 5.74182 14.531C5.7976 14.531 5.85112 14.5089 5.89065 14.4695L10.9438 9.4164C11.4102 8.95 11.6656 8.33125 11.6656 7.67265C11.6656 7.01406 11.4078 6.39297 10.9438 5.9289C9.98049 4.96562 8.41487 4.96797 7.45158 5.9289L6.85158 6.53125L1.26877 12.1117C0.889859 12.4884 0.589503 12.9366 0.38512 13.4302C0.180737 13.9239 0.0763961 14.4532 0.0781467 14.9875C0.0781467 16.0727 0.502365 17.0922 1.26877 17.8586C2.0633 18.6508 3.10393 19.0469 4.14455 19.0469C5.18518 19.0469 6.2258 18.6508 7.01799 17.8586L14.2649 10.6164C15.3313 9.54765 15.9219 8.125 15.9219 6.61328C15.9242 5.09922 15.3336 3.67656 14.2649 2.60781Z"
                    fill="#E5E7EB"
                  />
                </svg>
              </div>
              <span>Dokumen</span>
            </div>

            <div className="flex flex-col m-2 justify-center items-center">
              <div
                className="bg-[#ef4444] flex items-center justify-center mx-2 w-14 h-14 rounded-xl cursor-pointer"
                onClick={() => setOpen(!open)}>
                <svg
                  width="21"
                  height="16"
                  viewBox="0 0 21 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M18.499 0H2.49902C1.39602 0 0.499023 0.897 0.499023 2V14C0.499023 15.103 1.39602 16 2.49902 16H18.499C19.602 16 20.499 15.103 20.499 14V2C20.499 0.897 19.602 0 18.499 0ZM4.99902 3C5.39685 3 5.77838 3.15804 6.05968 3.43934C6.34099 3.72064 6.49902 4.10218 6.49902 4.5C6.49902 4.89782 6.34099 5.27936 6.05968 5.56066C5.77838 5.84196 5.39685 6 4.99902 6C4.6012 6 4.21967 5.84196 3.93836 5.56066C3.65706 5.27936 3.49902 4.89782 3.49902 4.5C3.49902 4.10218 3.65706 3.72064 3.93836 3.43934C4.21967 3.15804 4.6012 3 4.99902 3ZM10.499 13H3.49902L7.49902 8L8.99902 10L11.999 6L17.499 13H10.499Z"
                    fill="#E5E7EB"
                  />
                </svg>
              </div>
              <span>Galeri</span>
            </div>

            <div className="flex flex-col m-2 justify-center items-center">
              <div
                className="bg-[#4c89ff] flex items-center justify-center mx-2 w-14 h-14 rounded-xl cursor-pointer"
                onClick={() => setOpenVid(!openVid)}>
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <rect width="40" height="40" rx="16" fill="#4C89FF" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13 13C12.2044 13 11.4413 13.3161 10.8787 13.8787C10.3161 14.4413 10 15.2044 10 16V24C10 24.7956 10.3161 25.5587 10.8787 26.1213C11.4413 26.6839 12.2044 27 13 27H23C23.7956 27 24.5587 26.6839 25.1213 26.1213C25.6839 25.5587 26 24.7956 26 24V22.414L28.293 24.707C28.4329 24.8468 28.611 24.942 28.805 24.9806C28.9989 25.0192 29.2 24.9993 29.3827 24.9237C29.5654 24.848 29.7215 24.7199 29.8314 24.5555C29.9413 24.391 30 24.1978 30 24V16C30 15.8022 29.9413 15.609 29.8314 15.4445C29.7215 15.2801 29.5654 15.152 29.3827 15.0763C29.2 15.0007 28.9989 14.9808 28.805 15.0194C28.611 15.058 28.4329 15.1532 28.293 15.293L26 17.586V16C26 15.2044 25.6839 14.4413 25.1213 13.8787C24.5587 13.3161 23.7956 13 23 13H13Z"
                    fill="#E5E7EB"
                  />
                </svg>
              </div>
              <span>Video</span>
            </div>

            <div className="flex flex-col m-2 justify-center items-center">
              <div
                className="bg-yellow-400 flex items-center justify-center mx-2 w-14 h-14 rounded-xl cursor-pointer"
                onClick={() => setOpenAudio(!openAudio)}>
                <VolumeUpIcon className="text-white" />
              </div>
              <span>Audio</span>
            </div>
          </div>
        </div>
      )}

      <div className="w-full mb-4 border rounded-lg">
        <div className="flex items-center justify-between px-3 py-2 border-b dark:border-gray-600 hidden">
          <div className="flex flex-wrap items-center divide-gray-200 sm:divide-x dark:divide-gray-600">
            <div className="flex items-center space-x-1 sm:pr-4 justify-center">
              <button
                onClick={() => setOpen(!open)}
                type="button"
                className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600">
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M33.3334 8.33333H6.66671V31.6667L22.1534 16.1767C22.3082 16.0217 22.492 15.8988 22.6943 15.8149C22.8966 15.731 23.1135 15.6879 23.3325 15.6879C23.5516 15.6879 23.7684 15.731 23.9708 15.8149C24.1731 15.8988 24.3569 16.0217 24.5117 16.1767L33.3334 25.0167V8.33333ZM3.33337 6.655C3.33642 6.2173 3.51154 5.79836 3.8209 5.4887C4.13025 5.17903 4.54901 5.00349 4.98671 5H35.0134C35.9267 5 36.6667 5.74167 36.6667 6.655V33.345C36.6637 33.7827 36.4885 34.2016 36.1792 34.5113C35.8698 34.821 35.4511 34.9965 35.0134 35H4.98671C4.54806 34.9996 4.12754 34.825 3.81752 34.5147C3.50751 34.2043 3.33337 33.7836 3.33337 33.345V6.655ZM13.3334 18.3333C12.4493 18.3333 11.6015 17.9821 10.9764 17.357C10.3512 16.7319 10 15.8841 10 15C10 14.1159 10.3512 13.2681 10.9764 12.643C11.6015 12.0179 12.4493 11.6667 13.3334 11.6667C14.2174 11.6667 15.0653 12.0179 15.6904 12.643C16.3155 13.2681 16.6667 14.1159 16.6667 15C16.6667 15.8841 16.3155 16.7319 15.6904 17.357C15.0653 17.9821 14.2174 18.3333 13.3334 18.3333Z"
                    fill="#595959"
                  />
                </svg>
                <span className="sr-only">Upload image</span>
              </button>
              <button
                onClick={() => setOpenVid(!openVid)}
                type="button"
                className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600">
                <svg
                  width="27"
                  height="27"
                  viewBox="0 0 40 39"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M26.6666 6.5C27.1087 6.5 27.5326 6.67121 27.8451 6.97595C28.1577 7.2807 28.3333 7.69402 28.3333 8.125V14.95L37.0216 9.01875C37.1466 8.93337 37.2931 8.88306 37.4454 8.87331C37.5976 8.86357 37.7497 8.89476 37.885 8.96349C38.0203 9.03222 38.1336 9.13585 38.2127 9.26307C38.2918 9.3903 38.3335 9.53625 38.3333 9.685V29.315C38.3335 29.4638 38.2918 29.6097 38.2127 29.7369C38.1336 29.8642 38.0203 29.9678 37.885 30.0365C37.7497 30.1052 37.5976 30.1364 37.4454 30.1267C37.2931 30.1169 37.1466 30.0666 37.0216 29.9813L28.3333 24.05V30.875C28.3333 31.306 28.1577 31.7193 27.8451 32.024C27.5326 32.3288 27.1087 32.5 26.6666 32.5H3.33329C2.89127 32.5 2.46734 32.3288 2.15478 32.024C1.84222 31.7193 1.66663 31.306 1.66663 30.875V8.125C1.66663 7.69402 1.84222 7.2807 2.15478 6.97595C2.46734 6.67121 2.89127 6.5 3.33329 6.5H26.6666ZM25 9.75H4.99996V29.25H25V9.75ZM13.3333 13H16.6666V17.875H21.6666V21.125H16.665L16.6666 26H13.3333L13.3316 21.125H8.33329V17.875H13.3333V13ZM35 14.3666L28.3333 18.9166V20.0834L35 24.6334V14.3666Z"
                    fill="#595959"
                  />
                </svg>
                <span className="sr-only">Upload Video</span>
              </button>
              <button
                type="button"
                onClick={() => setOpenDoc(!openDoc)}
                className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600">
                <svg
                  width="27"
                  height="27"
                  viewBox="0 0 62 62"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M36.875 9.83332H12.2917V49.1667H46.7083V19.6667H36.875V9.83332ZM7.375 7.35532C7.375 6.00816 8.47387 4.91666 9.82842 4.91666H39.3333L51.625 17.2083V51.6053C51.6276 51.9284 51.5665 52.2488 51.4452 52.5482C51.3239 52.8476 51.1447 53.1201 50.918 53.3503C50.6913 53.5804 50.4215 53.7636 50.1239 53.8894C49.8263 54.0152 49.5069 54.0811 49.1839 54.0833H9.81612C9.17051 54.0788 8.55258 53.8205 8.09583 53.3642C7.63907 52.9079 7.38015 52.2903 7.375 51.6447V7.35532ZM27.0417 27.0417V19.6667H31.9583V27.0417H39.3333V31.9583H31.9583V39.3333H27.0417V31.9583H19.6667V27.0417H27.0417Z"
                    fill="#595959"
                  />
                </svg>
                <span className="sr-only">Add File</span>
              </button>
            </div>
          </div>
        </div>

        <div
          className="px-4 py-2 flex bg-gray-100 rounded-b-lg dark:bg-gray-800"
          draggable="true"
          onPaste={handlePaste}
          onDrop={handleDrag}>
          <label htmlFor="editor" className="sr-only">
            Publish post
          </label>
          <div
            className="h-fit flex items-center cursor-pointer"
            onClick={() => setShowAttachmentMenu(!showAttachmentMenu)}>
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13 3.49976C13 2.94748 12.5523 2.49976 12 2.49976C11.4477 2.49976 11 2.94748 11 3.49976V11.4998H3.00002C2.44773 11.4998 2.00002 11.9475 2.00002 12.4998C2.00002 13.052 2.44773 13.4998 3.00002 13.4998H11V21.4998C11 22.052 11.4477 22.4998 12 22.4998C12.5523 22.4998 13 22.052 13 21.4998V13.4998H21C21.5523 13.4998 22 13.052 22 12.4998C22 11.9475 21.5523 11.4998 21 11.4998H13V3.49976Z"
                fill="#4B5563"
              />
            </svg>
          </div>

          {isCounting === true ?
            <div className="w-full  ml-3 flex gap-2">
              <div className="bg-gray-300 h-20 w-full animate-pulse mb-3"></div>
              <div className="flex items-center">
                <div className="bg-gray-300 h-10 w-10 rounded-full animate-pulse mb-3"></div>
              </div>
            </div>
            :
            <div className={`w-full flex `}>
              <textarea
                id="chat"
                onChange={(e) => {
                  if (e.nativeEvent.inputType === "insertLineBreak") return
                  handleSubmit(e)
                }}
                value={editor}
                onKeyUp={(e) => submitMessageKeyDown(e, editor, setEditor)}
                rows="1"
                className="block mx-4 p-2.5 h-24 w-full text-sm text-gray-900 bg-gray-100 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Type message..."></textarea>
              <div className="h-fit flex items-center">
                {editor.trim() === "" ? (
                  <div onClick={recording ? stopRecording : startRecording}>
                    {recording ? (
                      <div >
                        <button className="bg-red-600 text-white rounded-full p-2 animate-bounce">
                          <SettingsVoiceIcon />
                        </button>
                      </div>
                    ) : (
                      <button className="bg-green-600 text-white rounded-full p-2">
                        <MicIcon />
                      </button>
                    )}
                  </div>
                ) : (
                  <button
                    type="submit"
                    onClick={() => submitMessage(editor, setEditor)}
                    className="inline-flex bg-blue-500 justify-center p-3 text-blue-600 rounded-full cursor-pointer hover:bg-blue-400 dark:text-blue-500 dark:hover:bg-gray-600">
                    <svg
                      width="21"
                      height="18"
                      viewBox="0 0 21 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M5.3648 10.3263L2.12322 5.91971C1.1954 4.65868 2.01127 2.86781 3.5722 2.74141L16.745 1.66938C18.4267 1.53215 19.5117 3.41143 18.552 4.7992L11.0372 15.6712C10.1468 16.9589 8.1884 16.7709 7.56022 15.3369L5.3648 10.3263ZM5.3648 10.3263L11.427 6.82629"
                        stroke="#EBEBEB"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span className="sr-only">Send message</span>
                  </button>
                )}
              </div>
            </div>}
        </div>
      </div>
    </>
  );
};

export default ChatEditorOCS;
