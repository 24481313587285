import { memo, useEffect, useState } from "react";
import {
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import NumberFormat from 'react-number-format';
import { useRecoilValue } from "recoil";
import { currency_kurs } from "atom";
import { getUser } from "helpers/parseJWT";

const DetailRequestChild = ({ simulationInput, row, id, handleChecked, handleInput, requiredBuyPerBox, completedExist, checkResult2 }) => {
  const kurs = useRecoilValue(currency_kurs)
  const [_simulationInput, setSimulationInput] = useState(row)
  const [prices, setPrices] = useState({
    silver: {
      seaPrice: 0,
      airPrice: 0,
    },
    gold: {
      seaPrice: 0,
      airPrice: 0,
    },
    diamond: {
      seaPrice: 0,
      airPrice: 0,
    },
  })

  useEffect(() => {
    setSimulationInput(row)
  }, [simulationInput])

  useEffect(() => {
    countPrices()
  }, [simulationInput, _simulationInput])

  const countPrices = () => {
    const berat = _simulationInput.weight / 1000
    const volume = _simulationInput.length * _simulationInput.width * _simulationInput.height
    const beratByVolume = volume / 5000
    const beratFinal = beratByVolume > berat ? beratByVolume : berat

    const rumusLaut = ((volume / 1000000) * _simulationInput.category) / _simulationInput.qty
    const rumusPesawat = beratFinal < 5 ?
      5 * _simulationInput.detailPrice.airkgByVolume
      :
      beratFinal * _simulationInput.detailPrice.airkgByVolume

    const totalHargaLautSilver = rumusLaut + ((_simulationInput.value * kurs) * 0.11) + (_simulationInput.level.silver * (_simulationInput.value * kurs))
    const totalHargaLautGold = rumusLaut + ((_simulationInput.value * kurs) * 0.11) + (_simulationInput.level.gold * (_simulationInput.value * kurs))
    const totalHargaLautDiamond = rumusLaut + ((_simulationInput.value * kurs) * 0.11) + (_simulationInput.level.diamond * (_simulationInput.value * kurs))
    const totalHargaPesawatSilver = rumusPesawat + ((_simulationInput.value * kurs) * 0.11) + (_simulationInput.level.silver * (_simulationInput.value * kurs))
    const totalHargaPesawatGold = rumusPesawat + ((_simulationInput.value * kurs) * 0.11) + (_simulationInput.level.gold * (_simulationInput.value * kurs))
    const totalHargaPesawatDiamond = rumusPesawat + ((_simulationInput.value * kurs) * 0.11) + (_simulationInput.level.diamond * (_simulationInput.value * kurs))

    const finalObjectOfPrices = {
      silver: {
        seaPrice: totalHargaLautSilver,
        airPrice: _simulationInput.detailPrice.airkgByVolume > 0 ? totalHargaPesawatSilver : '-',
      },
      gold: {
        seaPrice: totalHargaLautGold,
        airPrice: _simulationInput.detailPrice.airkgByVolume > 0 ? totalHargaPesawatGold : '-',
      },
      diamond: {
        seaPrice: totalHargaLautDiamond,
        airPrice: _simulationInput.detailPrice.airkgByVolume > 0 ? totalHargaPesawatDiamond : '-',
      },
    }
    setPrices(finalObjectOfPrices);
  }

  const _handleInput = (e, id) => {
    const { name, value } = e.target;
    setSimulationInput((prev) => {
      let newPrev = {
        ...prev,
        [name]: name !== 'value' ? ~~value : parseFloat(value)
      }
      handleInput(e, id)
      return newPrev
    });
  };

  return (
    <>
      <TableRow key={row.variant}>
        <TableCell>
          <input
            type="checkbox"
            name="isChecked"
            style={{ width: 20, height: 20 }}
            checked={_simulationInput.status === "Completed" ? row.isChecked : false}
            disabled={_simulationInput.status !== "Completed"}
            onChange={(e) => handleChecked(e, id)}
          />
        </TableCell>
        <TableCell align="center">
          {row.variant !== null ? (
            <Tooltip title={row.variant}>
              <p className="text-xs line-clamp-2 cursor-pointer">
                {row.variant}
              </p>
            </Tooltip>
          ) : (
            <p>-</p>
          )}
        </TableCell>
        <TableCell align="center">
          {
            checkResult2?.is_forbiden ? (
              <div>
                <p className="text-xs">{row.qty === 0 ? "-" : row.qty}</p>
              </div>
            ) : (
              (getUser().division[0] === "updater" || getUser().division[0] === "sales_support") &&
                row.status === 'Completed' ? (
                <>
                  <input
                    type="number"
                    name="qty"
                    className="border text-xs text-center border-gray-300 focus:outline-blue w-20 p-1 rounded-md"
                    value={_simulationInput.qty}
                    onChange={(e) => _handleInput(e, id)}
                  />
                </>
              ) : (
                <div>{row.qty === 0 ? "-" : row.qty}</div>
              )
            )
          }
        </TableCell>
        {completedExist && (
          <>
            <TableCell align="center">
              {_simulationInput.status === "Completed" ?
                <>
                  {
                    (getUser().role !== 'sales' || getUser().division[0] !== 'asmen' || getUser().division[0] !== 'manager' || getUser().division[0] !== 'supervisor') &&
                      row.status === 'Completed' ?
                      <>
                        <input
                          type="number"
                          name="kuantiti_kelipatan"
                          className={`border text-xs text-center border-gray-300 focus:outline-blue w-20 p-1 rounded-md`}
                          value={_simulationInput.kuantiti_kelipatan}
                          onChange={(e) => _handleInput(e, id)}
                          onKeyPress={(e) => {
                            if (!/[0-9]|\./.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          min="0"
                        />
                      </> : <div>{_simulationInput.kuantiti_kelipatan}</div>}
                </>

                : '-'}
            </TableCell>
            <TableCell align="center">
              {_simulationInput.status === "Completed" ?
                <>
                  {
                    checkResult2?.is_forbiden ? (
                      <div>{_simulationInput.min_qty}</div>
                    ) :
                      (getUser().role !== 'sales' || getUser().division[0] !== 'asmen' || getUser().division[0] !== 'manager' || getUser().division[0] !== 'supervisor') &&
                        row.status === 'Completed' ?
                        <>
                          <input
                            type="number"
                            name="min_qty"
                            className={`${simulationInput[id].invalid_min_qty ? 'text-red-500' : ''} border text-xs text-center border-gray-300 focus:outline-blue w-20 p-1 rounded-md`}
                            value={_simulationInput.min_qty}
                            onChange={(e) => _handleInput(e, id)}
                            onKeyPress={(e) => {
                              if (!/[0-9]|\./.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            min="0"
                          />
                        </> : <div>{_simulationInput.min_qty}</div>}
                </>

                : '-'}
            </TableCell>
          </>
        )}
        <TableCell align="center">
          {
            checkResult2?.is_forbiden ? (
              <div>
                <p className="text-xs">{_simulationInput.weight !== 0 ? row.weight : "-"}</p>
              </div>
            ) :
              (getUser().division[0] === "updater" || getUser().division[0] === "sales_support") &&
                row.status === 'Completed' ?
                <>
                  <input
                    type="number"
                    name="weight"
                    className="border text-xs text-center border-gray-300 focus:outline-blue w-20 p-1 rounded-md"
                    value={_simulationInput.weight}
                    onChange={(e) => _handleInput(e, id)}
                  />
                </> : <div>{_simulationInput.weight !== 0 ? row.weight : "-"}</div>}
        </TableCell>
        <TableCell align="center">
          <p className="text-xs">{_simulationInput.length * _simulationInput.width * _simulationInput.height}</p>
        </TableCell>
        <TableCell align="center">
          <>
            {
              checkResult2?.is_forbiden ? (
                <div>{_simulationInput.min_qty}</div>
              ) :
              (getUser().role !== 'sales' || getUser().division[0] !== 'asmen' || getUser().division[0] !== 'manager' || getUser().division[0] !== 'supervisor') &&
              row.status === 'Completed' ?
                  <>
                    <input
                      type="number"
                      name="length"
                      className="border text-xs text-center border-gray-300 focus:outline-blue w-20 p-1 rounded-md"
                      value={_simulationInput.length}
                      onChange={(e) => _handleInput(e, id)}
                    />
                  </> : <div>{_simulationInput.length}</div>}
          </>

        </TableCell>
        <TableCell align="center">
          <>
            {
              checkResult2?.is_forbiden ? (
                <div>{_simulationInput.min_qty}</div>
              ) :
              (getUser().role !== 'sales' || getUser().division[0] !== 'asmen' || getUser().division[0] !== 'manager' || getUser().division[0] !== 'supervisor') &&
              row.status === 'Completed' ?
                  <>
                    <input
                      type="number"
                      name="width"
                      className="border text-xs text-center border-gray-300 focus:outline-blue w-20 p-1 rounded-md"
                      value={_simulationInput.width}
                      onChange={(e) => _handleInput(e, id)}
                    />
                  </> : <div>{_simulationInput.width}</div>}
          </>
        </TableCell>
        <TableCell align="center">
          <>
            {
              checkResult2?.is_forbiden ? (
                <div>{_simulationInput.min_qty}</div>
              ) :
              (getUser().role !== 'sales' || getUser().division[0] !== 'asmen' || getUser().division[0] !== 'manager' || getUser().division[0] !== 'supervisor') &&
              row.status === 'Completed' ?
                  <>
                    <input
                      type="number"
                      name="height"
                      className="border text-xs text-center border-gray-300 focus:outline-blue w-20 p-1 rounded-md"
                      value={_simulationInput.height}
                      onChange={(e) => _handleInput(e, id)}
                    />
                  </> : <div>{_simulationInput.height}</div>}
          </>
        </TableCell>
        <TableCell align="center">
          <div className="text-xs">
            {row.hscode || "-"}
          </div>
        </TableCell>
        <TableCell align="center">
          <div className="text-xs">
            {
              row.is_lartas ? <span className="text-green-500">Yes</span> : <span className="text-red-500">No</span>
            }
          </div>
        </TableCell>
        <TableCell align="center">
          <NumberFormat
            value={_simulationInput.category}
            displayType={"text"}
            className="text-xs"
            thousandSeparator='.'
            decimalSeparator=','
            prefix={"IDR "}
            decimalScale={2}
          />
        </TableCell>
        <TableCell align="center">
          <div className="text-xs">
            <p
              className={`${_simulationInput.status === "In Progress"
                ? "text-yellow-400"
                : row.status === "Completed"
                  ? "text-green-600"
                  : "text-red-500"
                } rounded-md font-semibold `}>
              {_simulationInput.status}
            </p>
            {row.reason && (
              <p
                className="text-xs text-black mt-2 line-clamp-1"
                title={row.reason}>
                Reason :
                {row.reason === "Not Response"
                  ? " No Response"
                  : " " + row.reason}
              </p>
            )}
            {/* <div
                  className='p-1  space-x-1 bg-blue-500 w-24 text-white flex items-center rounded-md text-xs justify-center font-semibold cursor-pointer mt-1'
                  onClick={() => rollBack(id_request, row.id)}>
                  <RestoreIcon style={{ fontSize: 20 }} />
                  <p>Rollback</p>
                </div> */}
          </div>
        </TableCell>
        <TableCell align="center">
          <div className="max-w-xl min-w-max">
            <p className="text-xs">Ship</p>
            <p className="text-xs">Airplane</p>
          </div>
        </TableCell>
        <TableCell align="center">
          <div className="flex flex-col max-w-xl min-w-max">
            <NumberFormat
              value={_simulationInput.value * kurs * _simulationInput.qty}
              displayType={"text"}
              className="text-xs"
              thousandSeparator='.'
              decimalSeparator=','
              prefix={"IDR "}
              decimalScale={2}
            />
            {/* <NumberFormat
                value={
                  _simulationInput.detailPrice?.air?.pricePerBox !== 0
                    ? _simulationInput.detailPrice?.air?.pricePerBox
                    : "0"
                }
                displayType={"text"}
                className="text-xs"
                thousandSeparator='.'
                decimalSeparator=','
                prefix={"IDR "}
                decimalScale={2}
              /> */}
          </div>
        </TableCell>
        <TableCell align="center">
          <div className="flex flex-col max-w-xl min-w-max">
            <NumberFormat
              value={_simulationInput.value * kurs}
              displayType={"text"}
              className="text-xs"
              thousandSeparator='.'
              decimalSeparator=','
              prefix={"IDR "}
              decimalScale={2}
            />
            {/* <NumberFormat
                value={
                  _simulationInput.detailPrice?.air?.pricePerPcs !== 0
                    ? _simulationInput.detailPrice?.air?.pricePerPcs
                    : "0"
                }
                displayType={"text"}
                className="text-xs"
                thousandSeparator='.'
                decimalSeparator=','
                prefix={"IDR "}
                decimalScale={2}
              /> */}
          </div>
        </TableCell>
        <TableCell align="center">
          <div className="flex flex-col max-w-xl min-w-max">
            <NumberFormat
              value={prices.silver.seaPrice}
              displayType={"text"}
              className="text-xs"
              thousandSeparator='.'
              decimalSeparator=','
              prefix={"IDR "}
              decimalScale={2}
            />
            <NumberFormat
              value={prices.silver.airPrice}
              displayType={"text"}
              className="text-xs"
              thousandSeparator='.'
              decimalSeparator=','
              prefix={"IDR "}
              decimalScale={2}
            />
          </div>
        </TableCell>
        <TableCell align="center">
          <div className="flex flex-col max-w-xl min-w-max">
            <NumberFormat
              value={prices.gold.seaPrice}
              displayType={"text"}
              className="text-xs"
              thousandSeparator='.'
              decimalSeparator=','
              prefix={"IDR "}
              decimalScale={2}
            />
            <NumberFormat
              value={prices.gold.airPrice}
              displayType={"text"}
              className="text-xs"
              thousandSeparator='.'
              decimalSeparator=','
              prefix={"IDR "}
              decimalScale={2}
            />
          </div>
        </TableCell>
        <TableCell align="center">
          <div className="flex flex-col max-w-xl min-w-max">
            <NumberFormat
              value={prices.diamond.seaPrice}
              displayType={"text"}
              className="text-xs"
              thousandSeparator='.'
              decimalSeparator=','
              prefix={"IDR "}
              decimalScale={2}
            />
            <NumberFormat
              value={prices.diamond.airPrice}
              displayType={"text"}
              className="text-xs"
              thousandSeparator='.'
              decimalSeparator=','
              prefix={"IDR "}
              decimalScale={2}
            />
          </div>
        </TableCell>
        {completedExist &&
          <TableCell align="center">
            {_simulationInput.status === "Completed" ? (
              <>
                {
                  checkResult2?.is_forbiden ? (
                    <div>{_simulationInput.min_qty}</div>
                  ) :
                    getUser().role !== 'sales' &&
                      (getUser().division[0] !== 'asmen' &&
                        getUser().division[0] !== 'manager' &&
                        getUser().division[0] !== 'supervisor') ||
                      row.status === 'Completed' ?
                      <>
                        <input
                          type="number"
                          name="value"
                          disabled={!row.beginPrice}
                          className="border text-xs border-gray-300 focus:outline-blue w-20 p-1 rounded-sm"
                          value={_simulationInput.value}
                          onChange={(e) => _handleInput(e, id)}
                        />
                      </> : <div>{_simulationInput.value}</div>}
              </>
            )
              : '-'
            }
          </TableCell>
        }
      </TableRow>
    </>
  );
};

export default memo(DetailRequestChild);