import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  reFetch: true 
};

const dataTelponSlice = createSlice({
  name: "additionalData",
  initialState,
  reducers: {
    triggerTelp: (state) => {
      state.reFetch = !state.reFetch;
    }
  },
});

export const { triggerTelp } = dataTelponSlice.actions;

export const selectTelponData = (state) => state.addData.addData;

export default dataTelponSlice.reducer;