import { useCallback, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { CircularProgress, IconButton } from '@mui/material';
import { getProductAllOrder, postAdjustment, get_total_ongkir_local, getProvince, getCity, getSubdistrict, getDataTotalOngkir, postAdjustmentAddress } from 'service/api';
import swal from 'sweetalert';
import AdjustmentTable from './AdjustmentTable';
import Cookies from 'js-cookie';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import useDebounce from 'helpers/useDebounce';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 750,
  height: 1000,
  maxHeight: '85vh',
  bgcolor: 'background.paper',
  border: '1px solid lightgray',
  boxShadow: 24,
  borderRadius: '5px',
  p: 3,
};

const AdjustmentModal = ({ idOrder, status, setUpdate }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setChangeAddres(true)
    setPenambahan_harga(0)
    setAdjustment({
      type: '',
      value: '',
      destination_account: '',
      reason: '',
      // asuransi: 0,
      ongkir_china: 0,
      inspeksi: 0,
      packingKayu: 0,
      ongkir_international: 0,
    })
    setLoading(false);
  }
  const [sendInvoice, setSendInvoice] = useState(false);
  const [dataProduct, setDataProduct] = useState([]);
  const [image, setImage] = useState('');
  const [loading, setLoading] = useState(false);
  const [updateTotal, setUpdateTotal] = useState(false);
  const [penambahan_harga, setPenambahan_harga] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [customer, setCustomer] = useState(null)
  const [openModalCopyPayment, setOpenModalCopyPayment] = useState(false)
  const [loadingOngkir, setLoadingOngkir] = useState(false)
  const [adjustment, setAdjustment] = useState({
    type: '',
    value: '',
    destination_account: '',
    reason: '',
    // asuransi: 0,
    ongkir_china: 0,
    inspeksi: 0,
    packingKayu: 0,
    ongkir_international: 0,
  });
  const [shipping_address, setShipping_address] = useState({})
  const [changeAddres, setChangeAddres] = useState(true)
  const [ongkirLokal, setOngkirLokal] = useState(null);
  const [totalOngkirLokal, setTotalOngkirLokal] = useState(0);

  const GetDataProduct = async () => {
    const response = await getProductAllOrder(idOrder);
    if (response?.status === 200) {
      const data = response?.data?.products?.map((item) => {
        item.is_checked = false;
        item.addQty = 0;
        item.actualQty = item.qty;
        item.addPrice = 0;
        return item;
      });
      setCustomer(response?.data?.customer)
      setDataProduct(data);
      setOngkirLokal(response?.data?.ongkir)
      setShipping_address(response?.data?.shipping_address)

    } else {
      setDataProduct([]);
      setOngkirLokal(null)
    }
    setTotalOngkirLokal(0)
  };

  const submitImage = (e) => {
    let formData = new FormData();
    formData.append('gambar', e.target.files[0]);
    fetch(`${process.env.REACT_APP_URL_API_IMAGE_UPLOAD}`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${Cookies.get('oms_token')}`,
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data.status === 200) {
          setImage(data.file);
        } else {
          e.target.value = ''
          swal('Oops', "Gagal upload image! Silakan coba lagi.", 'error');
        }
      })
      .catch((error) => {
        swal('Oops', error, 'error');
      });
  };

  const handleChange = (e) => {
    let { name, value } = e.target;

    setUpdateTotal((prev) => !prev);
    setAdjustment((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const isNotEmptyAdditionalQty = useMemo(() => {
    return dataProduct.some(d => Number(d.addQty) != 0)
  }, [dataProduct])



  // const handleSumTotalPrice = (dataProduct) => {
  //   const dataProductPriceNumber = dataProduct.filter((data) => {
  //     return data?.addPrice !== 0;
  //   });
  //   const dataProductSelected = dataProductPriceNumber.filter((data) => {
  //     return data?.is_checked === true;
  //   });
  //   const dataProductNumber = dataProductSelected.map((data) => {
  //     return {
  //       ...data,
  //       addPrice: parseFloat(data.addPrice),
  //     };
  //   });
  //   const sumProductPrice = dataProductNumber.reduce(
  //     (prev, current) => prev + current?.addPrice,
  //     0
  //   );

  //   return sumProductPrice;
  // };

  useEffect(() => {
    if (open) {
      GetDataProduct();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);


  useEffect(() => {
    let totalPenambahan = 0
    // let total_OngkirLokal = 0

    // const sumPrice = handleSumTotalPrice(dataProduct);

    setTotalPrice(
      Number(totalPenambahan) +
      // Number(adjustment?.asuransi) +
      Number(adjustment?.ongkir_china) +
      Number(adjustment?.inspeksi) +
      Number(adjustment?.packingKayu) +
      Number(adjustment?.ongkir_international) +
      Number(totalOngkirLokal) +
      Number(penambahan_harga)
      // Number(sumPrice)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTotal, dataProduct, totalOngkirLokal, penambahan_harga]);

  // useEffect(() => {
  //   let totalPenambahan = 0

  //   if (dataProduct) {
  //     dataProduct
  //       .filter(v => v.is_checked)
  //       .forEach(item => {
  //         const addQty = ~~item.addQty
  //         const actualQty = ~~item.actualQty
  //         const addPrice = ~~item.addPrice
  //         const price = ~~item.price

  //         totalPenambahan += ((actualQty + addQty) * (price + addPrice)) - (actualQty * price)
  //         // total_OngkirLokal += Math.ceil(addQty * item.weight / 1000) * ongkirLokal?.ongkir
  //       })
  //     setPenambahan_harga(totalPenambahan)
  //     // setTotalOngkirLokal(total_OngkirLokal)
  //   }

  // }, [dataProduct])

  // const get_totalOngkirLokal = useCallback(async () => {
  //   // const product = dataProduct.filter((item) =>  item.is_checked && (item.addQty !== '' && item.addQty !== 0) );
  //   const product = dataProduct.filter(
  //     (item) => item.is_checked && item.addQty !== '' && item.addQty !== '0'
  //   );
  //   const totalAddQty = product.reduce((pV, cV) => {
  //     return pV + Number(cV.addQty)
  //   }, 0)

  //   if (product.length && totalAddQty > 0) {
  //     setLoading(true)
  //     const modifyDataProduct = product.map((obj) => {
  //       return {
  //         id_product: obj.id_product?.toString(),
  //         addQty: Number(obj.addQty),
  //       };
  //     });

  //     const payload = {
  //       id_so: idOrder,
  //       data: modifyDataProduct
  //     }

  //     const response = await get_total_ongkir_local(JSON.stringify(payload))
  //     if (response.status === 200) {
  //       setTotalOngkirLokal(response.data)
  //     }
  //     setLoading(false)
  //   } else {
  //     setTotalOngkirLokal(0)
  //   }
  // }, [dataProduct])

  // const debounceDataProductTotalOngkir = useDebounce(dataProduct, 1000);

  // useEffect(() => {
  //   get_totalOngkirLokal()
  // }, [debounceDataProductTotalOngkir])


  return (
    <>
      <button
        className={`py-2 px-5  ${status !== 'paid'
          ? 'bg-gray-300 text-white'
          : 'border text-blue-500 border-blue-500'
          } rounded-md text-center cursor-pointer`}
        onClick={() => {
          handleOpen();
        }}
        disabled={status !== 'paid'}>
        {/* disabled={status !== 'paid'}> */}
        Adjustment
      </button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box sx={style} className='overflow-y-scroll variant-scroll'>
          <div className='w-full text-xs'>
            <div className='flex items-center justify-between'>
              <Typography id='modal-modal-title' variant='h6' component='h2' >
                <h1 className='font-bold'>Adjustment</h1>
              </Typography>
              <div className='flex '>
                <IconButton onClick={handleClose} >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
            <div className='flex gap-3 text-white font-bold'>
              <div onClick={() => setChangeAddres(true)} className={`${changeAddres ? 'bg-[#556DED]' : "bg-[#C0D8FF]"} rounded-full p-2 px-3 cursor-pointer`}>
                <h1>Adjustment Order</h1>
              </div>
              <div onClick={() => setChangeAddres(false)} className={`${!changeAddres ? 'bg-[#556DED]' : "bg-[#C0D8FF]"} rounded-full p-2 px-3 cursor-pointer`}>
                <h1>Adjustment Change Address</h1>
              </div>
            </div>
          </div>

          <div className='mb-4 w-2/3 mt-4 font-medium text-xs'>
            <div className='flex items-center space-x-4'>
              <label className='w-1/3'>Name</label>
              <p>:</p>
              <div>{customer?.name}</div>
            </div>
            <div className='flex items-center space-x-4'>
              <label className='w-1/3'>Telephone</label>
              <p>:</p>
              <div>{customer?.phone}</div>
            </div>
            <div className='flex items-center space-x-4'>
              <label className='w-1/3'>Email</label>
              <p>:</p>
              <div>{customer?.email}</div>
            </div>
            <div className='flex items-center space-x-4 font-bold'>
              <label className='w-1/3'>ID ORDER</label>
              <p>:</p>
              <div >{idOrder}</div>
            </div>
          </div>

          {changeAddres ?
            <AdjusmentOrder
              idOrder={idOrder}
              status={status}
              setUpdate={setUpdate}
              handleClose={handleClose}
              open={open}
              dataProduct={dataProduct}
              setDataProduct={setDataProduct}
              ongkirLokal={ongkirLokal}
              setOpenModalCopyPayment={setOpenModalCopyPayment}
            /> :
            <AdjusmentAddress setUpdate={setUpdate} handleClose={handleClose} shipping_address={shipping_address} idOrder={idOrder} setOpenModalCopyPayment={setOpenModalCopyPayment} />
          }

        </Box>
      </Modal>
      <ModalCopyPaymentLink paymentLink={openModalCopyPayment} onClose={() => {
        setOpenModalCopyPayment(false);
        setUpdate((prev) => !prev);
        setLoading(false)
      }} />
    </>
  );
};

export default AdjustmentModal;


const styleModalCopyPaymentLink = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  maxHeight: '85dvh',
  bgcolor: 'background.paper',
  border: '1px solid lightgray',
  boxShadow: 24,
  borderRadius: '5px',
  p: 2,
};

function ModalCopyPaymentLink({ paymentLink, onClose }) {
  const [isCopied, setIsCopied] = useState(false)
  const handleCopy = (paymentLink) => {
    navigator.clipboard.writeText(paymentLink)
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, 3000);
  }
  return (
    <Modal
      open={!!paymentLink}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'>
      <Box sx={styleModalCopyPaymentLink}>
        <div className="flex justify-between items-start">
          <div>

            <h1 className='text-lg font-bold'>Congratulation</h1>
            <h2 className=''>The payment link has been create</h2>
          </div>
          <button onClick={onClose}>
            <CloseRoundedIcon />
          </button>
        </div>
        <div className="flex justify-center">
          <img src="/payment.png" alt="" className='h-40 text-center' />
        </div>
        <div className="rounded bg-gray-100 m-2 p-4 mt-4">
          <div className='text-center font-bold mb-2'>Share the payment link with your customer</div>
          <div className='bg-white flex rounded border'>
            <div className='w-full whitespace-nowrap overflow-hidden p-2'>{paymentLink}</div>
            <button onClick={e => handleCopy(paymentLink)} className='bg-blue-500 px-4 font-bold text-white whitespace-nowrap flex items-center gap-1 rounded-r'><ContentCopyIcon fontSize='small' />{isCopied ? "Copied!" : "Copy"}</button>
          </div>
        </div>
      </Box>
    </Modal>
  )
}

const AdjusmentOrder = ({ idOrder, status, setUpdate, handleClose, open, dataProduct, setDataProduct, ongkirLokal, setOpenModalCopyPayment }) => {
  const [sendInvoice, setSendInvoice] = useState(false);
  // const [dataProduct, setDataProduct] = useState([]);
  const [image, setImage] = useState('');
  const [loading, setLoading] = useState(false);
  const [updateTotal, setUpdateTotal] = useState(false);
  const [penambahan_harga, setPenambahan_harga] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [customer, setCustomer] = useState(null)
  const [loadingOngkir, setLoadingOngkir] = useState(false)
  const [adjustment, setAdjustment] = useState({
    type: '',
    value: '',
    destination_account: '',
    reason: '',
    // asuransi: 0,
    ongkir_china: 0,
    inspeksi: 0,
    packingKayu: 0,
    ongkir_international: 0,
  });
  const [totalOngkirLokal, setTotalOngkirLokal] = useState(0);
  const [loadingImg, setLoadingImg] = useState(false)

  // const GetDataProduct = async () => {
  //   const response = await getProductAllOrder(idOrder);
  //   if (response?.status === 200) {
  //     const data = response?.data?.products?.map((item) => {
  //       item.is_checked = false;
  //       item.addQty = 0;
  //       item.actualQty = item.qty;
  //       item.addPrice = 0;
  //       return item;
  //     });
  //     setCustomer(response?.data?.customer)
  //     setDataProduct(data);
  //     setOngkirLokal(response?.data?.ongkir)


  //   } else {
  //     setDataProduct([]);
  //     setOngkirLokal(null)
  //   }
  //   setTotalOngkirLokal(0)
  // };

  const handleRemoveImage = () => {
    setImage('')
  }

  const submitImage = async (e) => {
    setLoadingImg(true)
    let formData = new FormData();
    const file = e.target.files[0];
    try {
      const fileNameWithTimestamp = `${Date.now()}-${file.name}`;

      formData.append('gambar', file, fileNameWithTimestamp);

      const response = await fetch(
        `${process.env.REACT_APP_URL_API_IMAGE_UPLOAD}`,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: `Bearer ${Cookies.get('oms_token')}`,
          }
        }
      );
      const data = await response.json();
      if (data?.status === 200) {
        setImage(data.file);
      } else {
        swal('Oops', `Images ${data.message}`, 'error');
      }
    } catch (error) {
      swal('Oops', 'Something went wrong', 'error');
    }
    setLoadingImg(false)
  };

  const handleChange = (e) => {
    let { name, value } = e.target;

    setUpdateTotal((prev) => !prev);
    setAdjustment((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const isNotEmptyAdditionalQty = useMemo(() => {
    return dataProduct.some(d => Number(d.addQty) != 0)
  }, [dataProduct])

  const submitAdjustment = async () => {
    // if (adjustment.type === "") {
    //   swal('error', "Adjustment For is required", 'error');
    //   return
    // }
    // if(adjustment.destination_account === ""){
    //   swal('error', "Destination Account is Required", 'error');
    //   return
    // }
    // if(image === ""){
    //   swal('error', "Proof image is Required", 'error');
    //   return
    // }
    // if (adjustment.reason === "") {
    //   swal('error', "Reason is Required", 'error');
    //   return
    // }
    if (!(totalPrice || isNotEmptyAdditionalQty)) {
      swal('error', "Data tidak lengkap!", 'error');
      return
    }



    const { ongkir_china, inspeksi, packingKayu, ongkir_international } =
      adjustment;
    const product = dataProduct.filter((item) => item.is_checked);
    const modifyDataProduct = product.map((obj) => {
      return {
        ...obj,
        addPrice: Math.ceil(obj.addPrice),
        addQty: Number(obj.addQty),
      };
    });

    const payload = {
      id_so: idOrder,
      type: adjustment.type,
      value: parseInt(adjustment.value),
      reason: adjustment.reason,
      proof: image,
      // proof: 'default.jpg',
      sendEmail: sendInvoice,
      product: modifyDataProduct,
      destination_account: adjustment.destination_account,
      penambahan_harga: Number(penambahan_harga),
      // asuransi: Number(asuransi),
      ongkir_china: Number(adjustment.ongkir_china),
      inspeksi: Number(inspeksi),
      packing_kayu: Number(packingKayu),
      ongkir_international: Number(ongkir_international),
      ongkir_lokal: Number(totalOngkirLokal),
      totalPrice,
    };

    swal(`Are you sure? Id Order: ${idOrder}`, ` you will not be able to revert this change?`, {
      buttons: ["No!", "Yes!"],
    }).then(async (confirm) => {
      if (confirm) {
        setLoading(true);
        const response = await postAdjustment(JSON.stringify(payload));
        if (response?.status === 200) {
          handleClose();
          if (response.data.payment_link != "") {
            setOpenModalCopyPayment(response.data.payment_link)
            return
          }
          swal('Success', 'Adjustment submitted successfully', 'success');
          setUpdate((prev) => !prev);
          setLoading(false);
        } else {
          swal('error', response?.message, 'error');
          setLoading(false);
        }
      }
    })
  };

  // const handleSumTotalPrice = (dataProduct) => {
  //   const dataProductPriceNumber = dataProduct.filter((data) => {
  //     return data?.addPrice !== 0;
  //   });
  //   const dataProductSelected = dataProductPriceNumber.filter((data) => {
  //     return data?.is_checked === true;
  //   });
  //   const dataProductNumber = dataProductSelected.map((data) => {
  //     return {
  //       ...data,
  //       addPrice: parseFloat(data.addPrice),
  //     };
  //   });
  //   const sumProductPrice = dataProductNumber.reduce(
  //     (prev, current) => prev + current?.addPrice,
  //     0
  //   );

  //   return sumProductPrice;
  // };

  // useEffect(() => {
  //   if (open) {
  //     GetDataProduct();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [open]);


  useEffect(() => {
    let totalPenambahan = 0
    // let total_OngkirLokal = 0

    // const sumPrice = handleSumTotalPrice(dataProduct);

    setTotalPrice(
      Number(totalPenambahan) +
      // Number(adjustment?.asuransi) +
      Number(adjustment?.ongkir_china) +
      Number(adjustment?.inspeksi) +
      Number(adjustment?.packingKayu) +
      Number(adjustment?.ongkir_international) +
      Number(totalOngkirLokal) +
      Number(penambahan_harga)
      // Number(sumPrice)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTotal, dataProduct, totalOngkirLokal, penambahan_harga]);

  // useEffect(() => {
  //   let totalPenambahan = 0

  //   if (dataProduct) {
  //     dataProduct
  //       .filter(v => v.is_checked)
  //       .forEach(item => {
  //         const addQty = ~~item.addQty
  //         const actualQty = ~~item.actualQty
  //         const addPrice = ~~item.addPrice
  //         const price = ~~item.price

  //         totalPenambahan += ((actualQty + addQty) * (price + addPrice)) - (actualQty * price)
  //         // total_OngkirLokal += Math.ceil(addQty * item.weight / 1000) * ongkirLokal?.ongkir
  //       })
  //     setPenambahan_harga(totalPenambahan)
  //     // setTotalOngkirLokal(total_OngkirLokal)
  //   }

  // }, [dataProduct])

  const get_totalOngkirLokal = useCallback(async () => {
    // const product = dataProduct.filter((item) =>  item.is_checked && (item.addQty !== '' && item.addQty !== 0) );
    const product = dataProduct.filter(
      (item) => item.is_checked && item.addQty !== '' && item.addQty !== '0'
    );
    const totalAddQty = product.reduce((pV, cV) => {
      return pV + Number(cV.addQty)
    }, 0)

    if (product.length && totalAddQty > 0) {
      setLoading(true)
      const modifyDataProduct = product.map((obj) => {
        return {
          id_product: obj.id_product?.toString(),
          addQty: Number(obj.addQty),
        };
      });

      const payload = {
        id_so: idOrder,
        data: modifyDataProduct
      }

      const response = await get_total_ongkir_local(JSON.stringify(payload))
      if (response.status === 200) {
        setTotalOngkirLokal(response.data)
      }
      setLoading(false)
    } else {
      setTotalOngkirLokal(0)
    }
  }, [dataProduct])

  const debounceDataProductTotalOngkir = useDebounce(dataProduct, 1000);

  useEffect(() => {
    get_totalOngkirLokal()
  }, [debounceDataProductTotalOngkir])


  return (
    <>


      <div className='space-y-5 mt-5 mb-10 w-full text-xs'>

        {/* <div className='flex items-center space-x-4'>
              <label className='w-1/3'>Adjustment For* </label>
              <select
                className='p-1 border border-gray-400 rounded-md focus:outline-blue w-3/4'
                name='type'
                value={adjustment.type}
                onChange={handleChange}>
                <option value=''>Choose Reason</option>
                <option value='Additional Logistic'>Additional Logistic</option>
                <option value='Additional Purchasing'>Additional Purchasing</option>
                <option value='Change Variant'>Change Variant</option>
                <option value='Wooden Packing'>Wooden Packing</option>
              </select>
            </div> */}
        {/* <div className='flex items-center space-x-4'>
              <label className='w-1/3'>Destination Account* </label>
              <select
                className='p-1 border border-gray-400 rounded-md focus:outline-blue w-3/4'
                name='destination_account'
                value={adjustment.destination_account}
                onChange={handleChange}>
                <option value=''>Choose your destination account</option>
                <option value='BCA Ocommerce (277 050 5006)'>
                  BCA Ocommerce (277 050 5006)
                </option>
                <option value='BCA Ocommerce (277 269 8080)'>
                  BCA Ocommerce (277 269 8080)
                </option>
                <option
                  value='BRI Ocommerce (0520 01 000285 30 1)
'>
                  BRI Ocommerce (0520 01 000285 30 1)
                </option>
                <option
                  value='BCA Guntur (647 051 4289)
'>
                  BCA Guntur (647 051 4289)
                </option>
              </select>
            </div> */}
        <div className='flex items-center space-x-4 text-xs'>
          <label className='w-1/4 capitalize font-bold'>Penyesuaian Pesanan</label>
          <div className='flex flex-col  w-3/4'>
            <input
              type='text'
              value={penambahan_harga}
              name='penambahan_harga'
              onChange={e => { setPenambahan_harga(e.target.value) }}
              className='w-full p-1 border border-gray-400 rounded-md focus:outline-blue '
            />
            <div className='text-xs text-red-600 pl-2'>
              *Kosongkan apabila tidak menggunakan
            </div>
          </div>
        </div>
        {/* <div className='flex items-center space-x-4'>
              <label className='w-1/4 capitalize'>biaya asuransi</label>
              <div className='flex flex-col  w-3/4'>
                <input
                  value={adjustment.asuransi}
                  onChange={handleChange}
                  name='asuransi'
                  type='number'
                  className='w-full p-1 border border-gray-400 rounded-md focus:outline-blue'
                />
                <div className='text-xs text-red-600 pl-2'>
                  *Kosongkan apabila tidak menggunakan
                </div>
              </div>
            </div> */}
        <div className='flex items-center space-x-4'>
          <label className='w-1/4 capitalize font-bold '>biaya inspeksi</label>
          <div className='flex flex-col  w-3/4'>
            <input
              value={adjustment.inspeksi}
              onChange={handleChange}
              name='inspeksi'
              type='number'
              className='w-full p-1 border border-gray-400 rounded-md focus:outline-blue'
            />
            <div className='text-xs text-red-600 pl-2'>
              *Kosongkan apabila tidak menggunakan
            </div>
          </div>
        </div>
        <div className='flex items-center space-x-4'>
          <label className='w-1/4 capitalize font-bold '>biaya packing kayu</label>
          <div className='flex flex-col  w-3/4'>
            <input
              value={adjustment.packingKayu}
              onChange={handleChange}
              name='packingKayu'
              type='number'
              className='w-full p-1 border border-gray-400 rounded-md focus:outline-blue'
            />
            <div className='text-xs text-red-600 pl-2'>
              *Kosongkan apabila tidak menggunakan
            </div>
          </div>
        </div>
        <div className='flex items-center space-x-4'>
          <label className='w-1/4 capitalize font-bold '>Ongkir International</label>
          <div className='flex flex-col  w-3/4'>
            <input
              value={adjustment.ongkir_international}
              onChange={handleChange}
              name='ongkir_international'
              type='number'
              className='w-full p-1 border border-gray-400 rounded-md focus:outline-blue'
            />
            <div className='text-xs text-red-600 pl-2'>
              *Kosongkan apabila tidak menggunakan
            </div>
          </div>
        </div>
        <div className='flex items-center space-x-4'>
          <label className='w-1/4 capitalize font-bold '>Ongkir Lokal China</label>
          <div className='flex flex-col  w-3/4'>
            <input
              value={adjustment.ongkir_china}
              onChange={handleChange}
              name='ongkir_china'
              type='number'
              className='w-full p-1 border border-gray-400 rounded-md focus:outline-blue'
            />
            <div className='text-xs text-red-600 pl-2'>
              *Kosongkan apabila tidak menggunakan
            </div>
          </div>
        </div>
        <div className='flex items-center space-x-4'>
          <label className='w-1/4 font-bold '>
            Ongkir Lokal<br />
            {ongkirLokal &&
              <small className='text-gray-500'>( <span className='capitalize'>{ongkirLokal?.kurir}</span> - {ongkirLokal?.service} )</small>
            }
          </label>
          <div className='flex flex-col  w-3/4'>
            <input
              type='text'
              value={totalOngkirLokal.toLocaleString('id-ID')}
              disabled
              // onChange={handleChange}
              className='w-full p-1 border border-gray-400 rounded-md focus:outline-blue '
            />
            <div className='text-xs text-red-600 pl-2'>
              *Kosongkan apabila tidak menggunakan
            </div>
          </div>
        </div>
        <div className='flex items-center space-x-4'>
          <label className='w-1/4 font-bold '>Total Adjustment (IDR)</label>
          <input
            type='text'
            value={totalPrice.toLocaleString('id-ID')}
            disabled
            onChange={handleChange}
            className='p-1 border border-gray-400 rounded-md focus:outline-blue w-3/4'
          />
        </div>
        {/* <div className='flex items-center space-x-4'>
              <label className='w-1/4'>Choose file</label>
              <input
                accept='image/*'
                id='icon-button-file'
                onChange={(e) => submitImage(e)}
                type='file'
                className='p-1 border border-gray-400 rounded-md focus:outline-blue w-3/4'
              />
            </div> */}
        <div className='flex space-x-4 mb-4'>
          <label className='w-1/4 font-bold '>Reason</label>
          <div className='w-3/4'>
            <textarea
              rows='5'
              className='p-1 border border-gray-400 rounded-md focus:outline-blue w-full'
              name='reason'
              value={adjustment.reason}
              onChange={handleChange}
            />
            <div className='flex items-center space-x-2 mt-2'>
              <input
                type='checkbox'
                name='sendInvoice'
                checked={sendInvoice}
                style={{ width: '16px', height: '16px' }}
                onChange={() => setSendInvoice(!sendInvoice)}
              />
              <label>Send Invoice to customer</label>
            </div>
          </div>
        </div>

        <div className='flex justify-center'>
          <div className='border p-3 rounded-lg space-y-1 flex justify-center w-full flex gap-2'>
            <div>
              {image.trim() !== "" ?
                <div>
                  <div className='bg-[#121212] flex justify-between relative border border-white rounded-md'>
                    <div className='flex'>
                      <img className='w-24 h-24 object-cover' src={`https://s3x.ocistok.com/ocistok/content/foto/${image}`} />
                    </div>
                    <div className='text-red-500 flex font-bold justify-center items-center absolute right-1 cursor-pointer top-1 bg-white rounded-full px-2 py-1'>
                      <p
                        onClick={() => handleRemoveImage()}
                        className=''>X</p>
                    </div>
                  </div>
                </div>
                :
                <div className=' flex items-center w-fit gap-2 rounded-lg'>
                  <label htmlFor="dropzone-file" className="flex p-1 px-2 gap-1 items-center justify-center w-full rounded-lg cursor-pointer">
                    <div className={`${loadingImg ? "bg-gray-200" : "bg-blue-500"} text-white p-2 rounded-md flex items-center gap-2 py-2 my-4`}>
                      <svg width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17 12.25C17 13.4435 16.5259 14.5881 15.682 15.432C14.8381 16.2759 13.6935 16.75 12.5 16.75C11.3065 16.75 10.1619 16.2759 9.31802 15.432C8.47411 14.5881 8 13.4435 8 12.25C8 11.0565 8.47411 9.91193 9.31802 9.06802C10.1619 8.22411 11.3065 7.75 12.5 7.75C13.6935 7.75 14.8381 8.22411 15.682 9.06802C16.5259 9.91193 17 11.0565 17 12.25Z" fill="white" />
                        <path d="M17 4.75V0.25H8V4.75H0.5V18.25H8C9.2 19.15 10.85 19.75 12.5 19.75C14.15 19.75 15.8 19.15 17 18.25H24.5V4.75H17ZM9.5 1.75H15.5V4.75H9.5V1.75ZM12.5 18.25C9.2 18.25 6.5 15.55 6.5 12.25C6.5 8.95 9.2 6.25 12.5 6.25C15.8 6.25 18.5 8.95 18.5 12.25C18.5 15.55 15.8 18.25 12.5 18.25ZM23 7.75H20V6.25H23V7.75Z" fill="white" />
                      </svg>
                      Upload Image
                    </div>
                    <input
                      accept="image/*"
                      onChange={loadingImg ? undefined : submitImage}
                      id="dropzone-file"
                      type="file"
                      required
                      className="hidden" />
                  </label>
                </div>
              }
            </div>
          </div>
        </div>
        <div className='text-xs text-red-600 pl-2'>
          *dengan melakukan upload image, adjustment ini tidak akan generate payment link penagihan ke dashboard customer. Karena dianggap sebagai manual adjustment
        </div>
      </div>
      <AdjustmentTable
        id_so={idOrder}
        dataProduct={dataProduct}
        updateTotal={updateTotal}
        setUpdateTotal={setUpdateTotal}
        setDataProduct={setDataProduct}
        get_totalOngkirLokal={get_totalOngkirLokal}
      />

      <div className='text-center mt-5'>
        <hr />
        <button
          className={`${loading || !(totalPrice || isNotEmptyAdditionalQty)
            // || !image 
            // || !adjustment.reason
            // || !adjustment.destination_account 
            // || !adjustment.type 
            ? "bg-gray-400" : "bg-blue-400"} text-white p-1 rounded-md px-5 mt-5`}
          onClick={submitAdjustment}
          disabled={loading || !(totalPrice || isNotEmptyAdditionalQty)
            // || !image 
            // || !adjustment.reason
            // || !adjustment.destination_account 
            // || !adjustment.type
          }
        >
          {loading ? "Loading..." : "Submit"}
        </button>
      </div>
    </>
  )
}


const AdjusmentAddress = ({ shipping_address, idOrder, setOpenModalCopyPayment, handleClose, setUpdate }) => {
  const [province, setProvince] = useState(null)
  const [city, setCity] = useState(null)
  const [subDristric, setSubDistric] = useState(null)
  const [payloadEditAlamat, setPayloadEditAlamat] = useState({
    fname: "",
    alamat: "",
    id_kecamatan: "",
    kode_pos: "",
    lname: "",
    telepon: "",
    provinsi: ""
  });
  const [isLoading, setIsLoading] = useState(false);
  const [editButton, setEditButton] = useState(false)
  const [image, setImage] = useState("")
  const [ongkirLokal, setOngkirLokal] = useState("")
  const [loadingImg, setLoadingImg] = useState(false)
  const [syaratKetentuan, setSyaratKetentuan] = useState(false)
  const [loading, setLoading] = useState(false)

  const initGetProvince = async () => {
    setIsLoading((prev) => !prev);
    const data = await getProvince();
    if (data?.status === 200) {
      setProvince(data?.data);
      setIsLoading((prev) => !prev);
    }
  };

  const initGetCity = async (id) => {
    setIsLoading((prev) => !prev);
    const data = await getCity(id);
    if (data?.status === 200) {
      setCity(data?.data);
      setIsLoading((prev) => !prev);
    }
  };

  const initGetSubdistrict = async (id) => {
    setIsLoading((prev) => !prev);
    const data = await getSubdistrict(id);
    if (data?.status === 200) {
      setSubDistric(data?.data);
      setIsLoading((prev) => !prev);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'fname' || name === 'lname' ||
      name === 'telepon' || name === 'alamat' ||
      name === 'kode_pos' || name === 'id_kecamatan' ||
      name === 'ongkir' || name === 'proof') {
      if (name === 'fname') {
        setPayloadEditAlamat({
          ...payloadEditAlamat,
          fname: value,
        });
      } else if (name === 'lname') {
        setPayloadEditAlamat({
          ...payloadEditAlamat,
          lname: value,
        });
      } else if (name === 'telepon') {
        setPayloadEditAlamat({
          ...payloadEditAlamat,
          telepon: value,
        });
      } else if (name === 'alamat') {
        setPayloadEditAlamat({
          ...payloadEditAlamat,
          alamat: value,
        });
      } else if (name === 'kode_pos') {
        setPayloadEditAlamat({
          ...payloadEditAlamat,
          kode_pos: value,
        });
      } else if (name === 'id_kecamatan') {
        setPayloadEditAlamat({
          ...payloadEditAlamat,
          id_kecamatan: Number(value),
        });
      } else if (name === 'ongkir') {
        setPayloadEditAlamat({
          ...payloadEditAlamat,
          ongkir: Number(value),
        });
      } else if (name === 'proof') {
        setPayloadEditAlamat({
          ...payloadEditAlamat,
          proof: value,
        });
      }
    } else {
      const fixValue = JSON.parse(e.target.value);

      if (name === 'id_province') {
        initGetCity(fixValue.id);
        setPayloadEditAlamat({
          ...payloadEditAlamat,
          id_provinsi: Number(fixValue.id),
          provinsi: fixValue.name,
          id_kabupaten: "",
          kabupaten: "",
          id_kecamatan: "",
          kecamatan: "",
        });
        setCity(null)
        setSubDistric(null)
      } else if (name === 'id_city') {
        initGetSubdistrict(fixValue.id);
        setPayloadEditAlamat({
          ...payloadEditAlamat,
          id_kabupaten: Number(fixValue.id),
          kabupaten: fixValue.name,
          id_kecamatan: "",
          kecamatan: "",
        });
        setSubDistric(null)
      } else if (name === 'subdistrict_id') {
        setPayloadEditAlamat({
          ...payloadEditAlamat,
          id_kecamatan: Number(fixValue.id),
          kecamatan: fixValue.name,
        });
      }
    }
  };

  const getDataOngkirLokal = async () => {
    const payload = {
      id_so: idOrder,
      id_kecamatan: payloadEditAlamat.id_kecamatan
    }

    const response = await getDataTotalOngkir(JSON.stringify(payload))
    if (response.status === 200) {
      setPayloadEditAlamat({
        ...payloadEditAlamat,
        ongkir: Number(response.data),
      });
      setOngkirLokal(response.data)
    } else {
      toast.error(response.message)
    }
  }

  useEffect(() => {
    if (payloadEditAlamat?.kecamatan && payloadEditAlamat?.kecamatan.trim() !== "") {
      getDataOngkirLokal();
    }
  }, [payloadEditAlamat.kecamatan]);

  const submitImage = async (e) => {
    setLoadingImg(true)
    let formData = new FormData();
    const file = e.target.files[0];
    try {
      const fileNameWithTimestamp = `${Date.now()}-${file.name}`;

      formData.append('gambar', file, fileNameWithTimestamp);

      const response = await fetch(
        `${process.env.REACT_APP_URL_API_IMAGE_UPLOAD}`,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: `Bearer ${Cookies.get('oms_token')}`,
          }
        }
      );
      const data = await response.json();
      if (data?.status === 200) {
        setImage(data.file);
        setPayloadEditAlamat({
          ...payloadEditAlamat,
          proof: data.file,
        });
      } else {
        swal('Oops', `Images ${data.message}`, 'error');
      }
    } catch (error) {
      swal('Oops', 'Something went wrong', 'error');
    }
    setLoadingImg(false)
  };


  const saveEdit = () => {
    setPayloadEditAlamat({
      ...payloadEditAlamat,
      ongkir: ongkirLokal,
    });
    setEditButton(false)
  }

  const cancelEdit = () => {
    setEditButton(false)
    setOngkirLokal(payloadEditAlamat.ongkir)
  }

  const submitAdjustmentAddres = async () => {
    if (payloadEditAlamat.fname === "") {
      swal('error', "Name is required", 'error');
      return
    }
    if (payloadEditAlamat.lname === "") {
      swal('error', "Name is required", 'error');
      return
    }
    if (payloadEditAlamat.telepon === "") {
      swal('error', "Telepon is required", 'error');
      return
    }
    if (payloadEditAlamat.id_kecamatan === "") {
      swal('error', "Kecamatan is required", 'error');
      return
    }
    if (payloadEditAlamat.provinsi === "") {
      swal('error', "Provinsi is required", 'error');
      return
    }
    if (payloadEditAlamat.alamat === "") {
      return
    }
    if (payloadEditAlamat.kode_pos === "") {
      swal('error', "Kode Pos is required", 'error');
      return
    }
    if (payloadEditAlamat.ongkir === "") {
      swal('error', "Ongkir is required", 'error');
      return
    }
    if (syaratKetentuan === false) {
      swal('error', "Terms and condition is required", 'error');
      return
    }


    // if(adjustment.destination_account === ""){
    //   swal('error', "Destination Account is Required", 'error');
    //   return
    // }
    // if(image === ""){
    //   swal('error', "Proof image is Required", 'error');
    //   return
    // }
    // if (adjustment.reason === "") {
    //   swal('error', "Reason is Required", 'error');
    //   return
    // }
    // if (!(totalPrice || isNotEmptyAdditionalQty)) {
    //   swal('error', "Data tidak lengkap!", 'error');
    //   return
    // }



    // const { ongkir_china, inspeksi, packingKayu, ongkir_international } =
    //   adjustment;
    // const product = dataProduct.filter((item) => item.is_checked);
    // const modifyDataProduct = product.map((obj) => {
    //   return {
    //     ...obj,
    //     addPrice: Math.ceil(obj.addPrice),
    //     addQty: Number(obj.addQty),
    //   };
    // });

    const payload = {
      id_so: idOrder,
      fname: payloadEditAlamat.fname,
      lname: payloadEditAlamat.lname,
      telepon: payloadEditAlamat.telepon,
      alamat: payloadEditAlamat.alamat,
      kode_pos: payloadEditAlamat.kode_pos,
      id_kecamatan: payloadEditAlamat.id_kecamatan,
      ongkir: Number(payloadEditAlamat.ongkir),
      proof: payloadEditAlamat.proof
    };

    swal(`Are you sure? Id Order: ${idOrder}`, ` you will not be able to revert this change?`, {
      buttons: ["No!", "Yes!"],
    }).then(async (confirm) => {
      if (confirm) {
        setLoading(true);
        const response = await postAdjustmentAddress(JSON.stringify(payload));
        if (response?.status === 200) {
          handleClose();
          if (response.data.payment_link != "") {
            setOpenModalCopyPayment(response.data.payment_link)
            return
          }
          swal('Success', 'Adjustment submitted successfully', 'success');
          setUpdate((prev) => !prev);
          setLoading(false);
        } else {
          swal('error', response?.message, 'error');
          setLoading(false);
        }
      }
    })
  };

  const handleRemoveImage = () => {
    setImage('')
  }

  useEffect(() => {
    initGetProvince()
    // getKota()
    // getKecamatan()
  }, [])

  return (
    <div className='text-xs'>
      <div>
        <h1 className='font-bold mb-2 text-sm'>Current Address</h1>
        <div className='mb-4 w-2/3 font-medium border p-2 border-black '>
          <p>{shipping_address.nama}</p>
          <div className='flex space-x-2'>
            <span>{shipping_address.telepon} {shipping_address.alamat} {shipping_address.kecamatan}, {shipping_address.kode_pos}, {shipping_address.provinsi}</span>
          </div>
        </div>
      </div>
      <div>
        <h1 className='font-bold mb-2 text-sm'>Change Address</h1>
        <div className='flex flex-col mb-1'>
          <label className='capitalize font-semibold '>Nama Depan Penerima</label>
          <div className='flex flex-col'>
            <input
              value={payloadEditAlamat.fname}
              onChange={handleChange}
              name='fname'
              type='text'
              className='w-full p-1 border border-gray-400 rounded-md focus:outline-blue'
            />
          </div>
        </div>
        <div className='flex flex-col mb-1'>
          <label className='capitalize font-semibold mb-1 '>Nama Belakang Penerima</label>
          <div className='flex flex-col'>
            <input
              value={payloadEditAlamat.lname}
              onChange={handleChange}
              name='lname'
              type='text'
              className='w-full p-1 border border-gray-400 rounded-md focus:outline-blue'
            />
          </div>
        </div>
        <div className='flex flex-col mb-1'>
          <label className='capitalize font-semibold mb-1'>No Handphone Penerima</label>
          <div className='flex flex-col'>
            <input
              value={payloadEditAlamat.telepon}
              onChange={handleChange}
              name='telepon'
              type='number'
              className='w-full p-1 border border-gray-400 rounded-md focus:outline-blue'
            />
          </div>
        </div>
        <div className='flex flex-col mb-1'>
          <label className='capitalize font-semibold mb-1 '>Provinsi</label>
          <div className='flex flex-col'>
            <select
              name='id_province'
              className='w-full p-1 border border-gray-300 rounded-md'
              value={payloadEditAlamat?.id_provinsi}
              onChange={handleChange}>
              <option value="">
                {payloadEditAlamat?.provinsi ? payloadEditAlamat.provinsi : "Pilih provinsi"}
              </option>
              {province?.map((data) => (
                <option
                  key={data?.province_id}
                  value={`{"id" : "${data?.province_id}", "name" : "${data?.province}"}`}>
                  {data.province}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className='flex flex-col mb-1'>
          <label className='capitalize font-semibold mb-1 '>Kota / Kabupaten</label>
          <div className='flex flex-col'>
            <select
              name='id_city'
              disabled={!payloadEditAlamat?.provinsi?.trim()}
              className='w-full p-1 border border-gray-300 rounded-md'
              value={payloadEditAlamat?.id_kabupaten}
              onChange={handleChange}
            >
              <option value="">
                {payloadEditAlamat?.kabupaten ? payloadEditAlamat.kabupaten : "Pilih Kota/Kabupaten"}
              </option>
              {city?.map((data) => (
                <option
                  key={data?.id_city}
                  value={`{"id" : "${data?.id_city}", "name" : "${data?.name}"}`}>
                  {data.type} {data.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className='flex flex-col mb-1'>
          <label className='capitalize font-semibold mb-1 '>Kecamatan</label>
          <div className='flex flex-col'>
            <select
              name='subdistrict_id'
              disabled={!payloadEditAlamat?.provinsi?.trim() || !payloadEditAlamat?.kabupaten?.trim()}
              className='w-full p-1 border border-gray-300 rounded-md'
              value={payloadEditAlamat?.id_kecamatan}
              onChange={handleChange}
            >
              <option value="">
                {payloadEditAlamat?.kecamatan ? payloadEditAlamat.kecamatan : "Pilih Kecamatan"}
              </option>
              {subDristric?.map((data) => (
                <option
                  key={data.province_id}
                  value={`{"id" : "${data?.subdistrict_id}", "name" : "${data?.subdistrict_name}"}`}>
                  {data.subdistrict_name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className='flex flex-col mb-1'>
          <label className='capitalize font-semibold mb-1 '>Kode Pos</label>
          <div className='flex flex-col'>
            <input
              value={payloadEditAlamat.kode_pos}
              onChange={handleChange}
              name='kode_pos'
              type='number'
              className='w-full p-1 border border-gray-400 rounded-md focus:outline-blue'
            />
          </div>
        </div>
        <div className='flex flex-col mb-4'>
          <label className='capitalize font-semibold mb-1 '>Alamat Lengkap</label>
          <div className='flex flex-col'>
            <textarea
              rows='5'
              className='p-1 border border-gray-400 rounded-md focus:outline-blue w-full'
              name='alamat'
              value={payloadEditAlamat.alamat}
              onChange={handleChange}
            />

          </div>
        </div>

        <div className='flex justify-center'>
          <div className='border p-3 rounded-lg space-y-1 flex justify-center w-full flex gap-2'>
            <div>
              {image.trim() !== "" ?
                <div>
                  <div className='bg-[#121212] flex justify-between relative border border-white rounded-md'>
                    <div className='flex'>
                      <img className='w-24 h-24 object-cover' src={`https://s3x.ocistok.com/ocistok/content/foto/${image}`} />
                    </div>
                    <div className='text-red-500 flex font-bold justify-center items-center absolute right-1 cursor-pointer top-1 bg-white rounded-full px-2 py-1'>
                      <p
                        onClick={() => handleRemoveImage()}
                        className=''>X</p>
                    </div>
                  </div>
                </div>
                :
                <div className=' flex items-center w-fit gap-2 rounded-lg'>
                  <label htmlFor="dropzone-file" className="flex p-1 px-2 gap-1 items-center justify-center w-full rounded-lg cursor-pointer">
                    <div className={`${loadingImg ? "bg-gray-200" : "bg-blue-500"} text-white p-2 rounded-md flex items-center gap-2 py-2 my-4`}>
                      <svg width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17 12.25C17 13.4435 16.5259 14.5881 15.682 15.432C14.8381 16.2759 13.6935 16.75 12.5 16.75C11.3065 16.75 10.1619 16.2759 9.31802 15.432C8.47411 14.5881 8 13.4435 8 12.25C8 11.0565 8.47411 9.91193 9.31802 9.06802C10.1619 8.22411 11.3065 7.75 12.5 7.75C13.6935 7.75 14.8381 8.22411 15.682 9.06802C16.5259 9.91193 17 11.0565 17 12.25Z" fill="white" />
                        <path d="M17 4.75V0.25H8V4.75H0.5V18.25H8C9.2 19.15 10.85 19.75 12.5 19.75C14.15 19.75 15.8 19.15 17 18.25H24.5V4.75H17ZM9.5 1.75H15.5V4.75H9.5V1.75ZM12.5 18.25C9.2 18.25 6.5 15.55 6.5 12.25C6.5 8.95 9.2 6.25 12.5 6.25C15.8 6.25 18.5 8.95 18.5 12.25C18.5 15.55 15.8 18.25 12.5 18.25ZM23 7.75H20V6.25H23V7.75Z" fill="white" />
                      </svg>
                      Upload Image
                    </div>
                    <input
                      accept="image/*"
                      onChange={loadingImg ? undefined : submitImage}
                      id="dropzone-file"
                      type="file"
                      required
                      className="hidden" />
                  </label>
                </div>
              }
            </div>
          </div>
        </div>
        <div className='text-xs text-red-600 pl-2'>
          *dengan melakukan upload image, adjustment ini tidak akan generate payment link penagihan ke dashboard customer. Karena dianggap sebagai manual adjustment
        </div>

        <div className='flex items-center space-x-2 my-4'>
          <input
            type='checkbox'
            name='sendInvoice'
            checked={syaratKetentuan}
            style={{ width: '16px', height: '16px' }}
            onChange={() => setSyaratKetentuan(!syaratKetentuan)}
          />
          <label>Saya memahami syarat dan ketentuan adjustment yang berlaku</label>
        </div>

        <div className='flex items-center space-x-4'>
          <label className='w-1/4 font-bold '>
            Ongkir Lokal<br />
            <small className='text-gray-500'>( jnt - JNT Cargo )</small>
            {/* {ongkirLokal &&
              <small className='text-gray-500'>( <span className='capitalize'>{ongkirLokal?.kurir}</span> - {ongkirLokal?.service} )</small>
            } */}
          </label>
          <div className='flex flex-col  w-3/4'>
            {editButton ?
              <input
                type='number'
                name='alamat'
                value={ongkirLokal}
                onChange={(e) => setOngkirLokal(e.target.value)}
                className='w-full p-1 border border-gray-400 rounded-md focus:outline-blue '
              /> :
              <input
                type='number'
                value={payloadEditAlamat.ongkir}
                disabled
                // onChange={handleChange}
                className='w-full p-1 border border-gray-400 rounded-md focus:outline-blue '
              />}
          </div>
          <div className='flex gap-2'>
            {editButton ?
              <>
                <button onClick={saveEdit} className='flex bg-[#D7DEF2] rounded-full gap-1 font-bold text-[#556DED] px-3 py-1'>
                  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.5 1.5H3.5C2.96957 1.5 2.46086 1.71071 2.08579 2.08579C1.71071 2.46086 1.5 2.96957 1.5 3.5V15.5C1.5 16.0304 1.71071 16.5391 2.08579 16.9142C2.46086 17.2893 2.96957 17.5 3.5 17.5H15.5C16.0304 17.5 16.5391 17.2893 16.9142 16.9142C17.2893 16.5391 17.5 16.0304 17.5 15.5V5.328C17.4999 4.79761 17.2891 4.28899 16.914 3.914L15.086 2.086C14.711 1.7109 14.2024 1.50011 13.672 1.5H12.5M5.5 1.5V5.5C5.5 5.76522 5.60536 6.01957 5.79289 6.20711C5.98043 6.39464 6.23478 6.5 6.5 6.5H11.5C11.7652 6.5 12.0196 6.39464 12.2071 6.20711C12.3946 6.01957 12.5 5.76522 12.5 5.5V1.5M5.5 1.5H12.5M4.5 14.5V11.5C4.5 11.2348 4.60536 10.9804 4.79289 10.7929C4.98043 10.6054 5.23478 10.5 5.5 10.5H13.5C13.7652 10.5 14.0196 10.6054 14.2071 10.7929C14.3946 10.9804 14.5 11.2348 14.5 11.5V14.5" stroke="#556DED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  Save</button>
                <button onClick={() => setEditButton(false)} className='flex bg-[#FCB1B1] rounded-full gap-1 font-bold text-[#DF3535] px-3 py-1'>
                  Cancel</button>
              </>
              : <button onClick={payloadEditAlamat?.kecamatan && payloadEditAlamat?.kecamatan.trim() !== "" ? () => setEditButton(true) : undefined} className='flex bg-[#D7DEF2] rounded-full font-bold text-[#556DED] px-4 py-1'>
                <svg width="20" height="21" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.70596 4.36808L10.353 6.02737M7.60792 12.1114H12.0001M3.21577 9.89905L2.66675 12.1114L4.86283 11.5583L11.2238 5.15016C11.4296 4.94272 11.5453 4.6614 11.5453 4.36808C11.5453 4.07476 11.4296 3.79345 11.2238 3.586L11.1293 3.49087C10.9234 3.28349 10.6442 3.16699 10.353 3.16699C10.0619 3.16699 9.78262 3.28349 9.57671 3.49087L3.21577 9.89905Z" stroke="#556DED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                Edit</button>}
          </div>
        </div>

        <div className='py-4 flex justify-end'>
          <button onClick={submitAdjustmentAddres}
            className={`${loading
              // || !image 
              // || !adjustment.reason
              // || !adjustment.destination_account 
              // || !adjustment.type 
              ? "bg-gray-400" : "bg-[#4C89FF] "} p-2 text-lg px-3 rounded-md text-white`}
            disabled={loading}
          >{loading ? "Loading..." : "Submit Payment"}
          </button>
        </div>


        {/* <div className='flex flex-col mb-1'>
          <label className='capitalize font-semibold mb-1 '>Choose File</label>
          <input
            accept='image/*'
            id='icon-button-file'
            // onChange={(e) => submitImage(e)}
            type='file'
            className='p-1 border border-gray-400 rounded-md focus:outline-blue w-3/4'
          />
        </div> */}
      </div>
    </div>
  )
}