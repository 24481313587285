import { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Button, CircularProgress, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DownloadIcon from "@mui/icons-material/Download";
import Lightbox from "react-image-lightbox";

const ModalDetail = (props) => {
  const { open, setOpen, image, video, setImage, setVideo, audio, setAudio } =
    props;
  const [selectedVid, setSelectedVid] = useState(null);
  const [telpon, setTelpon] = useState("");
  const [openLightbox, setOpenLightbox] = useState(false);
  const [loading, setLoading] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    maxHeight: 700,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const closeModal = () => {
    setOpen(!open);
    setImage("");
    setVideo("");
    setAudio("");
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={open}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description">
        <Box sx={{ ...style }}>
          <div className="w-full flex justify-end">
            <IconButton
              aria-label="close"
              onClick={closeModal}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className="cursor-pointer mx-3"></div>
          <div className="mt-5 flex flex-col">
            <div className="w-full h-full flex justify-center">
              {image && (
                <div className="max-h-[500px] h-[400px] w-[90%] flex justify-center items-center bg-gray-100 m-5">
                  <img
                    className="max-w-sm w-full h-full mx-auto object-cover cursor-pointer"
                    src={image}
                    onClick={() => setOpenLightbox(image)}
                    alt="Gambar"
                  />
                </div>
              )}
              {video && (
                <div className="max-h-[500px] h-[500px] w-[90%] flex justify-center items-center bg-gray-300 m-5">
                  <video
                    className="w-full max-w-[500px]  max-h-[500px]"
                    src={video}
                    controls
                  />
                </div>
              )}
              {audio && (
                <div className="max-h-[200px] h-[200px] w-[90%] flex justify-center items-center bg-gray-300 m-5">
                  <audio className="w-full bg-slate-400" src={audio} controls />
                </div>
              )}
            </div>
            {openLightbox === image && (
              <Lightbox
                mainSrc={image}
                onCloseRequest={() => setOpenLightbox(false)}
              />
            )}
            <div className="w-full flex flex-col justify-center items-center"></div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalDetail;
