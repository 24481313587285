import { getUser } from 'helpers/parseJWT';

export const SubRoutesMarketing = () => [
  {
    name: `${getUser().role === 'admin' ? 'Blast Email' : ''}`,
    pathname: '/marketing/blast-email',
  },
  {
    name: `${getUser().role === 'admin' ? 'Email Template' : ''}`,
    pathname: '/marketing/email-template',
  },
  {
    name: `${getUser().role === 'admin' ? 'Voucher' : ''}`,
    pathname: '/marketing/voucher',
  },
  {
    name: `${getUser().role === 'admin' ? 'Oci Academy' : ''}`,
    pathname: '/marketing/oci-academy',
  },
];