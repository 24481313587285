import { useState, useEffect } from 'react';
import Container from 'components/Layout/Container';
import Navbar from 'components/Layout/Navbar';
import SearchBar from 'components/Layout/SearchBar';
import Sidebar from 'components/Layout/Sidebar';
import SendToIdnTableV2 from 'components/LogisticChina/SendToIdn/SendToIdnTableV2';
import { getSendToIdnData, getSendToIdnDataV2, get_scanned_cartons } from 'service/api';
import SearchModal from 'components/LogisticChina/SendToIdn/SearchModal';
import { NavLink, useLocation } from 'react-router-dom';
import ScanBarcode from 'components/LogisticChina/SendToIdn/ScanBarcode';
import { SubRoutesLogisticChina as SUBROUTES } from 'components/LogisticChina/SubRoutesLogisticChina';
import ModalInputContainer from 'components/LogisticChina/SendToIdn/ModalInputContainer';
import { useTranslation } from "react-i18next";

const SendToIdnV2 = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [dataOrder, setDataOrder] = useState([]);
  const [update, setUpdate] = useState(false);
  const [idSo, setIdSo] = useState('');
  const [highlightIdSo, setHighlightIdSo] = useState('');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [select, setSelect] = useState('');
  const [isActive, setIsActive] = useState('');
  const [date, setDate] = useState({
    start: '',
    end: '',
    sorting: 'asc',
  });
  const [pageInfo, setPageInfo] = useState({});
  
  const [inputContainerData, setInputContainerData] = useState(null)
  const [showModalInputContainer, setShowModalInputContainer] = useState(false)
  
  const [openModalScanCarton, setOpenModalScanCarton] = useState(false)
  const [tempScannedData, setTempScannedData] = useState([])
  const [cartonIdInput, setCartonIdInput] = useState('')
  const [scannedData, setScannedData] = useState({
    scanned: [],
    unscanned: []
  })
  const [inputs, setInputs] = useState({
    container: '',
    shipname_voyage: '',
    etd: '',
    eta: '',
  })
  
  const { search, pathname } = useLocation();
  
  const { t } = useTranslation();

  const fetchOrderData = async (limit, page, id) => {
    setIsLoading(true);

    const idParams = id && id !== '' ? `id=${id}&` : '';
    const selectParams = select !== '' ? `filter=${select}&` : '';
    const startParams = date.start !== '' ? `start=${date.start}&` : '';
    const endParams = date.end !== '' ? `end=${date.end}&` : '';
    const sortingParams = date.sorting !== '' ? `sort=${date.sorting}&` : '';
    const statusActiveParams = isActive !== '' ? `is_active=${isActive}&` : '';

    const params =
      `page=${page}&limit=${limit}&` +
      idParams +
      selectParams +
      startParams +
      endParams +
      sortingParams +
      statusActiveParams;

    const data = await getSendToIdnDataV2(params);
    if (data?.status === 200) {
      setDataOrder(data.data.data);
      setPageInfo({
        dataInPage: data.data.dataInPage,
        totalData: data.data.totalData,
        totalPage: data.data.totalPage,
      });
      if(id !== '') {
        setHighlightIdSo(id)
      } else {
        setHighlightIdSo('')
      }
    } else {
        setDataOrder([])
        setPageInfo({})
    }
    setIsLoading(false);
  };
  
  useEffect(() => {
    let id = '';
    if (search) {
      const query = new URLSearchParams(search);
      id = query.get('id');
    }
    fetchOrderData(limit, page, id);
  }, [search, update, limit, page, select]);
  

  const searchOrderByFilters = () => {
    setLimit(5)
    setPage(1)
    fetchOrderData(5, 1, idSo);
  };

  const getScannedCartons = async (markingCode) => {
    const response = await get_scanned_cartons(markingCode)
    if(response.status === 200) {
      setScannedData({
          scanned: response.data.scanned || [],
          unscanned: response.data.unscanned || []
        }
      )
      setTempScannedData(response.data.scanned || [])
    }
  }

  const closeModalScanCartons = () => {
    setOpenModalScanCarton(false)
    setTempScannedData([])
    setCartonIdInput('')
  }

  return (
    <>
      <Navbar />
      <div className='flex bg-gray-200 min-w-full min-h-screen  text-gray-600 '>
        <div className='w-30'>
          <Sidebar routes={SUBROUTES()} title='Logistic China' />
        </div>
        <Container>
          <div className='flex items-center justify-between mb-4'>
            <SearchBar />
            <SearchModal
              setDataOrder={setDataOrder}
              setIsLoading={setIsLoading}
              setPageInfo={setPageInfo}
            />
          </div>
          <div className='flex justify-between'>
            <div className="flex items-center gap-3">
              {/* <NavLink
                to={`/logistic-china/send-to-idn`}>
                  <div className={`${pathname === '/logistic-china/send-to-idn' ? 'bg-blue-500 text-white' : 'bg-white'} mb-2 p-2 rounded-md cursor-pointer text-center`}>
                    {t("Original")}
                  </div>
              </NavLink>
              <NavLink
                to={`/logistic-china/send-to-idn-detained`}>
                  <div className={`${pathname.includes('send-to-idn-detained') ? 'bg-blue-500 text-white' : 'bg-white'} mb-2 p-2 rounded-md cursor-pointer text-center`}>
                    {t("Detained")}
                  </div>
              </NavLink> */}
              <NavLink
                to={`/logistic-china/send-to-idn-v2`}>
                  <div className={`${pathname.includes('send-to-idn-v2') ? 'bg-blue-500 text-white' : 'bg-white'} mb-2 p-2 rounded-md cursor-pointer text-center`}>
                    {t("Marking Code")}
                  </div>
              </NavLink>
            </div>
            <div className='flex gap-2'>
              <ScanBarcode 
                inputContainerData={inputContainerData}
                setInputContainerData={setInputContainerData}
                setShowModalInputContainer={setShowModalInputContainer}
                getScannedCartons={getScannedCartons}
              />
            </div>
          </div>
          <SendToIdnTableV2
            isLoading={isLoading}
            idSo={idSo}
            setIdSo={setIdSo}
            page={page}
            pageInfo={pageInfo}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            setUpdate={setUpdate}
            select={select}
            setSelect={setSelect}
            isActive={isActive}
            setIsActive={setIsActive}
            date={date}
            setDate={setDate}
            dataOrder={dataOrder}
            actionSubmit={searchOrderByFilters}
            highlightIdSo={highlightIdSo}
            inputContainerData={inputContainerData}
            setInputContainerData={setInputContainerData}
            setShowModalInputContainer={setShowModalInputContainer}
          />
        </Container>
      </div>

      {/* MODAL INPUT CONTAINER */}
      <ModalInputContainer
        showModalInputContainer={showModalInputContainer}
        setShowModalInputContainer={setShowModalInputContainer}
        inputContainerData={inputContainerData}
        setInputContainerData={setInputContainerData}
        openModalScanCarton={openModalScanCarton}
        setOpenModalScanCarton={setOpenModalScanCarton}
        tempScannedData={tempScannedData}
        setTempScannedData={setTempScannedData}
        cartonIdInput={cartonIdInput}
        setCartonIdInput={setCartonIdInput}
        setUpdate={setUpdate}
        closeModalScanCartons={closeModalScanCartons}
        scannedData={scannedData}
        setScannedData={setScannedData}
        inputs={inputs}
        setInputs={setInputs}
        getScannedCartons={getScannedCartons}
      />
    </>
  );
};

export default SendToIdnV2;
