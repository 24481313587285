import Layout from 'components/Layout';
import CustomerTable from 'components/ManagementSales/CustomerManagement/CustomerTable';
import CustomFilter from 'components/ManagementSales/CustomerManagement/CustomFilter';
import { useEffect, useState } from 'react';
import swal from 'sweetalert';
import { Tooltip } from '@mui/material';
import { getListCustomerManagement } from 'service/api';
import { SubRoutesManagementSales as SUBROUTES } from 'components/ManagementSales/SubRoutesManagementSales';
import { getUser } from 'helpers/parseJWT';
import AssigneSales from 'components/ManagementSales/NewCustomer/AssigneSales';
import AddCustomer from 'components/ManagementSales/OBE/AddCustomer';

const CustomerManagement = () => {
  const [customerData, setCustomerData] = useState([]);
  const [changeData, setChangeData] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState('');
  const [searchData, setSearchData] = useState('');
  const [selectedRowsNumber, setSelectedRowsNumber] = useState(0);
  const [showModalAssigneSales, setShowModalAssigneSales] = useState(false)
  const [showModalResponse, setShowModalResponse] = useState(false)

  const fetchCustomerData = async (filterData, searchData, page, limit) => {
    setIsLoading(true);
    const params = new URLSearchParams({
      ...filterData,
      page,
      limit,
    }).toString();
    const setParams = `?${params}`;

    const data = await getListCustomerManagement(searchData, setParams);

    if (data?.status === 200) {
      if (data.data.data.length === 0 && data.data?.errorMessage)
        swal('Oops', `${data.data.errorMessage}`, 'error');
      setCustomerData(data.data);
      setSelectedRowsNumber(0)
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchCustomerData(filterData, searchData, page, limit);
  }, [changeData, filterData, searchData, page, limit]);

  return (
    <Layout routes={SUBROUTES()} title="CRM">
      <div className="flex justify-between items-center">
        <Tooltip title="Refresh table" placement="right">
          <p
            className="my-4 bg-white w-48 p-2 rounded-md cursor-pointer text-center"
            onClick={() => {
              setFilterData({});
              setSearchData('');
            }}>
            Customer Management
          </p>
        </Tooltip>
        <div className="flex items-center gap-3">
          <AddCustomer
            setChangeData={setChangeData}
            setLoading={setIsLoading}
            listSales={customerData?.listSales}
          />
          {['admin', 'supervisor'].some((role) => getUser()?.roles?.includes(role)) &&
            <AssigneSales
              dataSales={customerData?.listSales} 
              setChangeData={setChangeData}
              showModalAssigneSales={showModalAssigneSales}
              setShowModalAssigneSales={setShowModalAssigneSales}
              showModalResponse={showModalResponse}
              setShowModalResponse={setShowModalResponse}
            />
          }
        </div>
      </div>
      <CustomFilter
        setFilterData={setFilterData}
        setCustomerData={setCustomerData}
        setIsLoading={setIsLoading}
        setSearchData={setSearchData}
        listSales={customerData?.listSales}
        totalData={customerData?.totalData}
        dataCount={customerData?.dataInPage}
        setPage={setPage}
        page={page}
        setLimit={setLimit}
        limit={limit}
        totalPage={customerData?.totalPage}
      />
      <CustomerTable
        customerData={customerData}
        setChangeData={setChangeData}
        isLoading={isLoading}
        setSearchData={setSearchData}
        selectedRowsNumber={selectedRowsNumber}
        setSelectedRowsNumber={setSelectedRowsNumber}
      />
    </Layout>
  );
};

export default CustomerManagement;
