import Container from './Container';
import React, { useEffect } from 'react';
import Navbar from './Navbar';
import SearchBar from './SearchBar';
import Sidebar from './Sidebar';
import MobileNavbar from './MobileNavbar';
import ButtonBringToTop from './ButtonBringToTop';
import { useRecoilState } from "recoil";
import { currency_kurs } from 'atom';
import { getCurrency } from 'service/api';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
});

const Layout = ({
  searchBar = true,
  routes = null,
  children,
  title = null,
}) => {
  const [kurs, setKurs] = useRecoilState(currency_kurs);

  useEffect(() => {
    setCurrencyKurs()
  }, [])
  const setCurrencyKurs = async () => {
    const response = await getCurrency()
    if(response.status === 200) {
      setKurs(response.data?.currency)
    }
  }
  
  return (
    <>
     <ThemeProvider theme={theme}>
        <Navbar />
        <MobileNavbar />
        <div className='flex bg-gray-200 min-w-full min-h-screen  text-gray-600 '>
          {routes ? (
            <div className='w-30'>
              <Sidebar routes={routes} title={title} />
            </div>
          ) : (
            <></>
          )}
          <Container>
            {searchBar ? <SearchBar /> : <></>}
            {children}
          </Container>
          <ButtonBringToTop />
        </div>
     </ThemeProvider>
    </>
  );
};

export default Layout;