import React from "react";
import IdPO from "./IdPO";

const PoDetail = ({ detailPo, id_so }) => {
  return (
    <>
      <IdPO detailPo={detailPo} id_so={id_so} />
    </>
  );
};

export default PoDetail;
