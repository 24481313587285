import React from 'react';
import { Redirect } from 'react-router-dom';

const Quotation = () => {
  return (
    <>
      <Redirect to="quotation/check-volume" />
    </>
  );
};

export default Quotation;
