import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { CircularProgress, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import RepackingInputTable from './RepackingInputTable';
import swal from 'sweetalert';
import {
  insertRepackingData,
  getRepackingDetails,
  getDataPrintLabelDetails,
  get_list_forwarder,
} from 'service/api';
import { useSelector } from 'react-redux';
import { selectAddData } from 'redux/addDataSlice';
import PrintBarcodeModal from 'components/General/DetailOrderModal/CustomerDetail/PrintBarcodeModal';
import PoDetail from 'components/General/DetailOrderModal/PoDetail/PoDetail';
import { useTranslation } from "react-i18next";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1200,
  maxHeight: '80vh',
  bgcolor: 'background.paper',
  border: '1px solid lightgray',
  boxShadow: 24,
  borderRadius: '5px',
  p: 4,
};

export default function RepackingInput({
  openTriggerFromScanBarcode,
  setOpenTriggerFromScanBarcode,
  id_so,
  id_po,
  // products,
  setUpdate,
  // totalQty,
  RowCategory,
  totalBox,
}) {
  const { category } = useSelector(selectAddData);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    getDetailOrder();
    getListForwarder()
    setOpen(true);
  };

  const [initBox, setInitBox] = useState([]);
  const [box, setBox] = useState([]);
  const [totalQty, setTotalQty] = useState(0);
  const [repacking, setRepacking] = useState({ numOfCarton: '', category: '' });
  const [forwarder, setForwarder] = useState([]);
  const [selectedForwarder, setSelectedForwarder] = useState('');
  const [show, setShow] = useState(false);
  // const [newDataProduct, setNewDataProduct] = useState(
  //   products.map((data) => {
  //     return {
  //       idProduk: data.idProduk,
  //       sku: data.sku,
  //       name: data.name,
  //       qty: data.qty,
  //       avQty: data.qty_whchina,
  //       orderedQty: data.qty,
  //     };
  //   })
  // );
  const [selectedSku, setSelectedSku] = useState([
    {
      id: 1,
      product: '',
      quantity: '',
      reason: '',
    }
  ])
  const [validQtyDiff, setValidQtyDiff] = useState(true)

  const [dataBarcode, setDataBarcode] = useState(null);
  const [openBarcode, setOpenBarcode] = useState(false);
  const [detailOrder, setDetailOrder] = useState({});
  const [products, setProducts] = useState([]);
  const [isBolean, setIsBolean] = useState({
    isLoadingBarcode: false,
  });
  const [isLoading, setLoading] = useState(false);
  const [zones, setZones] = useState([]);
  const [selectedZone, setSelectedZone] = useState('');
  const [openMergeSelection, setOpenMergeSelection] = useState(false);
  const [selectedPoToMerge, setSelectedPoToMerge] = useState([id_po]);
  const [disableMergePO, setDisableMergePO] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (openTriggerFromScanBarcode) {
      handleOpen()
    }
  }, [openTriggerFromScanBarcode])


  // useEffect(() => {
  //   setRepacking({
  //     ...repacking,
  //     category: RowCategory,
  //   });
  // }, []);

  const handleClose = () => {
    setRepacking({ numOfCarton: '', category: '' })
    setSelectedZone('')
    setOpen(false)
    if (openTriggerFromScanBarcode) {
      setOpenTriggerFromScanBarcode(false)
    }
    setSelectedZone('')
    setBox([])
    setShow(false)
  };

  useEffect(() => {
    if (detailOrder) {
      setRepacking((prev) => {
        return { ...prev, category: detailOrder.category };
      });
    }
  }, [detailOrder])

  useEffect(() => {
    if (selectedPoToMerge) {
      setBox([]);
      setInitBox([])
      setInitProducts()
    }
  }, [selectedPoToMerge])

  // const _setInitProducts = () => {
  //     // setProducts(detailOrder?.poDetails?.filter((item) => item.id_po === id_po)?.[0]?.product)
  //     const newProducts = detailOrder?.poDetails?.filter((item) => item.id_po === id_po)?.[0]?.product.map((prod, index) => {
  //       return {
  //         ...prod, 
  //         qty_whchina_tobe_reduced: prod.qty_whchina
  //       }})
  //     setProducts(newProducts)
  // }

  const setInitProducts = () => {
    const newProductArray = detailOrder?.poDetails?.filter((po) => po.status !== 'closed' && po.id_po !== '' && po.product !== null)?.reduce((total, curr) => total.concat(curr.product), [])
    const newProducts = newProductArray?.map((prod, index) => {
      return {
        ...prod,
        qty_whchina_tobe_reduced: prod.qty_whchina
      }
    })

    setProducts(newProducts)
    set_totalQty(detailOrder)
  }

  const set_selectedPoToMerge = (e) => {
    if (e.target.checked) {
      setSelectedPoToMerge(prev => [...new Set([...prev, e.target.value])])
    } else {
      setSelectedPoToMerge(prev => prev.filter(i => i !== e.target.value))
    }
  }

  const handleChangeBoxData = (e) => {
    const { name, value } = e.target;
    if (name !== 'category') {
      setBox([]);
      setInitBox([])
      setInitProducts()
      setShow(false);
      setDisableMergePO(false)
    }

    // if (name === 'numOfCarton') {
    //   setNewDataProduct(
    //     products.map((data) => {
    //       return {
    //         idProduk: data.idProduk,
    //         sku: data.sku,
    //         name: data.name,
    //         qty: data.qty,
    //         avQty: data.qty_whchina,
    //         orderedQty: data.qty,
    //       };
    //     })
    //   );
    // }
    setRepacking((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const generateCarton = () => {
    if (repacking.numOfCarton !== '') {
      // if (Number(repacking.numOfCarton <= totalQty)) { {/* COMMENTED AT 29 JAN 2024 */}
      if (Number(repacking.numOfCarton) >= 100) {
        swal({
          title: 'Are you sure?',
          text: `You will generate ${repacking.numOfCarton} cartons !`,
          icon: 'warning',
          buttons: true,
          dangerMode: true,
        }).then((willGenerated) => {
          if (willGenerated) {
            spreadProductsToCartons()
          } else {
            swal('Generte carton canceled !');
            handleClose();
          }
        });
      } else {
        spreadProductsToCartons()
      }
      // } else { {/* COMMENTED AT 29 JAN 2024 */}
      //   swal('Oops', `Over maximum number of carton!`, 'error');
      // }
    }
  };

  const spreadProductsToCartons = () => {
    let totalQuantity = totalQty + 0
    for (let i = 0; i < Number(repacking.numOfCarton); i++) {
      if (totalQuantity > 0) {
        let totalOrdered = 0
        let totalReceived = 0
        let maxQtyPerCarton = i === Number(repacking.numOfCarton) - 1 ?
          Math.floor(totalQty / repacking.numOfCarton) + (totalQty % repacking.numOfCarton) :
          Math.floor(totalQty / repacking.numOfCarton)

        const boxProduct = products?.map(
          (order, index) => {
            let finalInputQty = 0
            if (maxQtyPerCarton > 0 && order.qty_whchina_tobe_reduced > 0) {

              finalInputQty = order.qty_whchina_tobe_reduced

              if (maxQtyPerCarton < finalInputQty) {
                finalInputQty = maxQtyPerCarton
              }

              order.qty_whchina_tobe_reduced -= finalInputQty

              maxQtyPerCarton -= finalInputQty
              totalQuantity -= finalInputQty

              totalOrdered += finalInputQty
              totalReceived += finalInputQty
              // return {
              //   sku: order.sku,
              //   name: order.product,
              //   qty: 0,
              //   // qty: order.qty_whchina, // JANGAN DIHAPUS UNTUK JAGA-JAGA KALAU MAU DIPAKE LAGI
              //   actual_qty: order.qty_whchina,
              //   idProduct: order.id_produk,
              //   qtyInput: finalInputQty,
              //   // qtyInput: '',  // JANGAN DIHAPUS UNTUK JAGA-JAGA KALAU MAU DIPAKE LAGI
              //   avQty: 0,
              //   // avQty: order.qty_whchina, // JANGAN DIHAPUS UNTUK JAGA-JAGA KALAU MAU DIPAKE LAGI
              //   orderedQty: order.qty,
              //   image: order.image,
              // };
            } else {
              // return {
              //   sku: order.sku,
              //   name: order.product,
              //   qty: 0,
              //   // qty: order.qty_whchina, // JANGAN DIHAPUS UNTUK JAGA-JAGA KALAU MAU DIPAKE LAGI
              //   actual_qty: order.qty_whchina,
              //   idProduct: order.id_produk,
              //   qtyInput: 0,
              //   // qtyInput: '',  // JANGAN DIHAPUS UNTUK JAGA-JAGA KALAU MAU DIPAKE LAGI
              //   avQty: 0,
              //   // avQty: order.qty_whchina, // JANGAN DIHAPUS UNTUK JAGA-JAGA KALAU MAU DIPAKE LAGI
              //   orderedQty: order.qty,
              //   image: order.image,
              // };
            }
          }
        );
        setBox((prev) => [
          ...prev,
          {
            id_box: `${id_so}-${i + 1 + ~~totalBox}`, // *totalBox being forced converted to integer (while it's not presented by backend yet)
            panjang: '',
            width: '',
            height: '',
            weight: '',
            total_ordered: totalOrdered,
            total_received: totalReceived,
            // box_product: boxProduct,
          },
        ]);
        setInitBox((prev) => [
          ...prev,
          {
            total_ordered: totalOrdered,
            total_received: totalReceived,
          },
        ]);
      }
    }
    setShow("processing");
    setDisableMergePO(true)
  }

  const _generateCarton = () => {
    if (repacking.numOfCarton !== '') {
      if (Number(repacking.numOfCarton <= totalQty)) {
        if (Number(repacking.numOfCarton) >= 100) {
          swal({
            title: 'Are you sure?',
            text: `You will generate ${repacking.numOfCarton} cartons !`,
            icon: 'warning',
            buttons: true,
            dangerMode: true,
          }).then((willGenerated) => {
            if (willGenerated) {
              for (let i = 0; i < Number(repacking.numOfCarton); i++) {
                let totalOrdered = 0
                let totalReceived = 0
                const boxProduct = [...products].map(
                  (order) => {
                    // totalOrdered += order.qty
                    // totalReceived += order.qty_whchina

                    let finalInputQty = 0
                    if (order.qty_whchina >= Number(repacking.numOfCarton)) {
                      const qtyModulo = order.qty_whchina % Number(repacking.numOfCarton)
                      const restQty = order.qty_whchina - qtyModulo
                      finalInputQty = i == 0 ? (restQty / Number(repacking.numOfCarton)) + qtyModulo : restQty / Number(repacking.numOfCarton)
                    } else {
                      if (i < order.qty_whchina) {
                        finalInputQty++
                      }
                    }
                    totalOrdered += finalInputQty
                    totalReceived += finalInputQty
                    return {
                      sku: order.sku,
                      name: order.product,
                      qty: 0,
                      // qty: order.qty_whchina, // JANGAN DIHAPUS UNTUK JAGA-JAGA
                      actual_qty: order.qty_whchina,
                      idProduct: order.id_produk,
                      qtyInput: finalInputQty,
                      // qtyInput: '',  // JANGAN DIHAPUS UNTUK JAGA-JAGA
                      avQty: 0,
                      // avQty: order.qty_whchina, // JANGAN DIHAPUS UNTUK JAGA-JAGA
                      orderedQty: order.qty,
                      image: order.image,
                    };
                  }
                );
                setBox((prev) => [
                  ...prev,
                  {
                    id_box: `${id_so}-${i + 1 + ~~totalBox}`, // *totalBox being forced converted to integer (while it's not presented by backend yet)
                    panjang: '',
                    width: '',
                    height: '',
                    weight: '',
                    total_ordered: totalOrdered,
                    total_received: totalReceived,
                    box_product: boxProduct,
                  },
                ]);
                setInitBox((prev) => [
                  ...prev,
                  {
                    total_ordered: totalOrdered,
                    total_received: totalReceived,
                  },
                ]);
              }
              setShow("processing");
            } else {
              swal('Generte carton canceled !');
              handleClose();
            }
          });
        } else {
          for (let i = 0; i < Number(repacking.numOfCarton); i++) {
            let totalOrdered = 0
            let totalReceived = 0
            const boxProduct = [...products].map(
              (order) => {
                // totalOrdered += order.qty
                // totalReceived += order.qty_whchina

                let finalInputQty = 0
                if (order.qty_whchina >= Number(repacking.numOfCarton)) {
                  const qtyModulo = order.qty_whchina % Number(repacking.numOfCarton)
                  const restQty = order.qty_whchina - qtyModulo
                  finalInputQty = i == 0 ? (restQty / Number(repacking.numOfCarton)) + qtyModulo : restQty / Number(repacking.numOfCarton)
                } else {
                  if (i < order.qty_whchina) {
                    finalInputQty++
                  }
                }
                totalOrdered += finalInputQty
                totalReceived += finalInputQty
                return {
                  sku: order.sku,
                  name: order.product,
                  qty: 0,
                  // qty: order.qty_whchina, // JANGAN DIHAPUS UNTUK JAGA-JAGA KALAU MAU DIPAKE LAGI
                  actual_qty: order.qty_whchina,
                  idProduct: order.id_produk,
                  qtyInput: finalInputQty,
                  // qtyInput: '',  // JANGAN DIHAPUS UNTUK JAGA-JAGA KALAU MAU DIPAKE LAGI
                  avQty: 0,
                  // avQty: order.qty_whchina, // JANGAN DIHAPUS UNTUK JAGA-JAGA KALAU MAU DIPAKE LAGI
                  orderedQty: order.qty,
                  image: order.image,
                };
              }
            );
            setBox((prev) => [
              ...prev,
              {
                id_box: `${id_so}-${i + 1 + ~~totalBox}`, // *totalBox being forced converted to integer (while it's not presented by backend yet)
                panjang: '',
                width: '',
                height: '',
                weight: '',
                total_ordered: totalOrdered,
                total_received: totalReceived,
                box_product: boxProduct,
              },
            ]);
            setInitBox((prev) => [
              ...prev,
              {
                total_ordered: totalOrdered,
                total_received: totalReceived,
              },
            ]);
          }
          setShow("processing");
        }
      } else {
        swal('Oops', `Over maximum number of carton!`, 'error');
      }
    }
  };

  // const checkSimilarID = (boxData) => {
  //   let arrayStatus = [];
  //   for (let i = 0; i < boxData.length; i++) {
  //     let similar = false;
  //     let uniqueId = [];
  //     for (let j = 0; j < boxData[i].box_product.length; j++) {
  //       if (uniqueId.includes(boxData[i].box_product[j].id_product)) {
  //         similar = true;
  //         break;
  //       } else {
  //         uniqueId.push(boxData[i].box_product[j].id_product);
  //       }
  //     }
  //     arrayStatus.push(similar);
  //   }
  //   return arrayStatus;
  // };

  const submitRepackingData = async () => {
    setLoading(true);
    const tempProducts = JSON.parse(JSON.stringify(products))
    const filteredSelectedSku = selectedSku.filter((sku) => sku.quantity !== '' && sku.quantity != '0')
    if (filteredSelectedSku.length > 0) {
      filteredSelectedSku.forEach((variant, idx) => {
        tempProducts.map((product, index) => {
          if (product.id_produk === variant.product.id_produk) {
            if (product.qty_whchina >= parseInt(variant.quantity)) {
              product.qty_whchina -= parseInt(variant.quantity)
            } else {
              product.qty_whchina = 0
            }
          }
          product.qty_whchina_tobe_reduced = product.qty_whchina
        })
      })
    }
    const filteredProducts = tempProducts.filter((product) => {
      product.qty_whchina_tobe_reduced = product.qty_whchina
      return product.qty_whchina != 0
    })

    const initTotalQuantity = filteredProducts?.reduce((total, curr) => curr.qty_whchina + total, 0)
    let totalQuantity = filteredProducts?.reduce((total, curr) => curr.qty_whchina + total, 0)

    const newBox = JSON.parse(JSON.stringify(box))
    newBox.forEach((carton, idx) => {
      carton.weight = Math.ceil(parseFloat(carton.weight) * 1000)
      if (totalQuantity > 0) {
        let maxQtyPerCarton = idx === box.length - 1 ?
          Math.floor(initTotalQuantity / box.length) + (initTotalQuantity % box.length) :
          Math.floor(initTotalQuantity / box.length)

        const boxProduct = filteredProducts.map(
          (order) => {
            let finalInputQty = 0
            if (maxQtyPerCarton > 0 && order.qty_whchina_tobe_reduced > 0) {

              finalInputQty = order.qty_whchina_tobe_reduced

              if (maxQtyPerCarton < finalInputQty) {
                finalInputQty = maxQtyPerCarton
              }

              order.qty_whchina_tobe_reduced -= finalInputQty

              maxQtyPerCarton -= finalInputQty
              totalQuantity -= finalInputQty

              return {
                id_product: order.id_produk,
                qty: finalInputQty,
                actual_qty: Number(order.qty_whchina),
              };
              // return {
              //   sku: order.sku,
              //   name: order.product,
              //   qty: 0,
              //   // qty: order.qty_whchina, // JANGAN DIHAPUS UNTUK JAGA-JAGA KALAU MAU DIPAKE LAGI
              //   actual_qty: order.qty_whchina,
              //   idProduct: order.id_produk,
              //   qtyInput: finalInputQty,
              //   // qtyInput: '',  // JANGAN DIHAPUS UNTUK JAGA-JAGA KALAU MAU DIPAKE LAGI
              //   avQty: 0,
              //   // avQty: order.qty_whchina, // JANGAN DIHAPUS UNTUK JAGA-JAGA KALAU MAU DIPAKE LAGI
              //   orderedQty: order.qty,
              //   image: order.image,
              // };
            }
          }
        );
        carton.box_product = boxProduct.filter((el) => el !== undefined)
      }
    })

    const validateInput = [...newBox].filter((data) => ~~data.panjang === 0 || ~~data.width === 0 || ~~data.height === 0 || ~~data.weight === 0);

    const validateInputImage = [...newBox].filter((data) => (detailOrder.is_packing_kayu && !data.image_packing_kayu));

    if (validateInput?.length > 0) {
      swal('Oops', 'Input not valid !', 'error');
      setLoading(false);
      return;
    }

    if (validateInputImage?.length > 0) {
      swal('Oops', 'Please complete upload image !', 'error');
      setLoading(false);
      return;
    }

    // const validateIdProduct = checkSimilarID(newBox);

    // if (validateIdProduct.includes(true)) {
    //   swal('Oops', "Product can't be same each carton !", 'error');
    //   setLoading(false);
    //   return;
    // }
    if (!validQtyDiff) {
      swal('Oops', 'Total Quantity difference does not match!', 'error');
      setLoading(false);
      return;
    }

    const body = JSON.stringify({
      id_so,
      invoice: id_po,
      category: ~~repacking.category,
      forwarder: selectedForwarder,
      zone: selectedForwarder === 'BL' ? ~~selectedZone : 1,
      total_product: filteredProducts.length,
      box: newBox,
      rejected_product_lists: filteredSelectedSku
    });

    const data = await insertRepackingData(body);
    const message = data.data?.is_detained ? `ID Order ${id_so} has moved to Send to IDN.\nThis order is detained.` :
      `ID Order ${id_so} has moved to Send to IDN.\nThe marking code for this order is ${data.data?.assigned_marking_code?.[0]?.marking_code_val}.`
    if (data?.status === 200) {
      swal(
        'Success',
        message,
        'success'
      ).then(() => {
        handleOpenBarcode();
        setOpen(false)
        // if (!filteredSelectedSku.length) {
        //   handleOpenBarcode();
        // } else {
        //   setBox([]);
        //   setInitBox([]);
        //   setShow(false);
        //   setUpdate((prev) => !prev);
        //   handleClose()
        // }
      })
      // swal('Success', 'Repacking submitted succesfully', 'success');
      // setBox([]);
      // setShow(false);
      // setUpdate((prev) => !prev);
    }
    if (data?.status === 400) {
      swal('Oops', data?.message, 'error');
    }
    if (data?.status === 409) {
      swal('Oops', data?.message, 'error');
      swal(
        'Oops', data?.message, 'error'
      ).then(() => {
        handleOpenBarcode();
      })
    }



    // const tempBox = []
    // const emptyBox = []
    // box.map((data) => {
    //   const isDirty = data.box_product.filter((boxData) => boxData.qtyInput !== '' && boxData.qtyInput != '0');

    //   if(isDirty.length !== 0) {
    //    tempBox.push(data)
    //   }
    //   if(isDirty.length === 0) {
    //    emptyBox.push(data.id_box)
    //   }
    // });

    // const validateInput = tempBox.filter(
    //   (data) =>
    //   data.panjang === '' || data.panjang == '0' ||
    //   data.width === '' || data.width == '0' ||
    //   data.height === '' || data.height == '0' ||
    //   data.weight === '' || data.weight == '0' ||
    //     data.box_product.filter(
    //       (data) =>
    //         data.qty === '' || data.id_product === '' || data.actualQty === ''
    //     ).length > 0
    // );

    // if (validateInput?.length > 0) {
    //   swal('Oops', 'Input not valid !', 'error');
    //   setLoading(false);
    //   return;
    // }

    // if (repacking.category === '') {
    //   swal('Oops', 'Please select category before submit !', 'error');
    //   setLoading(false);
    //   return;
    // }

    // if (emptyBox.length > 0) {
    //   swal('Oops', 'You still have empty box!', 'error');
    //   setLoading(false);
    //   return;
    // }
    // if (!validQtyDiff) {
    //   swal('Oops', 'Total Quantity difference does not match!', 'error');
    //   setLoading(false);
    //   return;
    // }

    // const finalBox = JSON.parse(JSON.stringify(box))
    // // const finalBox = [...box]
    // if(selectedSku.length !== 0) {
    //   selectedSku.reverse().filter((sku) => sku.quantity !== '' && sku.quantity != '0').forEach((variant, idx) => {
    //     let unreceivedItemsQty = parseInt(variant.quantity)
    //     while(unreceivedItemsQty > 0) {
    //       finalBox.reverse().forEach((box, index) => {
    //         box.box_product.map((prod, id) => {
    //           if(unreceivedItemsQty > 0 && prod.sku === variant.product.sku && prod.qtyInput > 0) {
    //             prod.qtyInput = prod.qtyInput - 1
    //             unreceivedItemsQty--
    //           }
    //         })
    //       })
    //     }
    //   })
    // }
    // setLoading(false);
    // return

    // const box_submit = finalBox.reverse().map((data, index) =>  {
    //   if(data.box_product.filter.length !== 0) {
    //     return {
    //       ...data,
    //       box_product: data.box_product.filter((boxData) => boxData.qtyInput !== '' && boxData.qtyInput != '0').map((product) => {
    //         return {
    //           id_product: product.idProduct,
    //           qty: Number(product.qtyInput),
    //           actual_qty: Number(product.actual_qty),
    //         };
    //       }),
    //     };
    //   }
    // });
    setLoading(false);
  };

  const getDetailOrder = async () => {
    setLoading(true);
    const data = await getRepackingDetails(id_so);
    if (data?.status === 200) {
      setDetailOrder(data.data);
      set_totalQty(data.data)
    } else {
      handleClose();
      swal('Oops', data?.message, 'error');
    }
    setLoading(false);
  };

  const set_totalQty = (data) => {
    const total_qty = data?.poDetails?.filter((po) => po.status !== 'closed')?.reduce((total, curr) => total + curr.qty, 0)
    setTotalQty(total_qty)
  }

  const getListForwarder = async () => {
    const data = await get_list_forwarder();
    if (data?.status === 200) {
      setSelectedForwarder('BL')
      setForwarder(data.data?.forwarder_list);
      setZones(data.data?.forwarder_list?.filter(i => i.kurir === 'BL')[0]?.zone);
    } else {
      // handleClose();
      swal('Oops', 'Failed to fetch Forwarder List', 'error');
    }
  };

  const set_SelectedForwarder = (value) => {
    setSelectedForwarder(value)
    if (value === 'BL') {
      setZones(forwarder?.filter(i => i.kurir === 'BL')[0]?.zone)
    } else {
      setZones([])
    }
  }

  const handleOpenBarcode = async () => {
    const params = id_so;

    setIsBolean({
      isLoadingBarcode: true,
    });

    const response = await getDataPrintLabelDetails(params);
    if (response?.status === 200) {
      setIsBolean({
        isLoadingBarcode: false,
      });
      setDataBarcode(response?.data);
    } else {
      setIsBolean({
        isLoadingBarcode: false,
      });
    }

    setOpenBarcode(true);
    setShow(false);
  };
  const handleCloseBarcode = () => {
    setBox([]);
    setInitBox([]);
    setShow(false);
    setUpdate((prev) => !prev);
    setOpenBarcode(false);
    handleClose()
  };

  const copyBox = JSON.parse(JSON.stringify(initBox))
  const total_received_init = copyBox?.reduce((total, curr) => curr.total_received + total, 0)

  const totalUnreceivedItemQty = ~~selectedSku.reduce((total, curr) => ~~curr.quantity + total, 0)
  return (
    <div>
      <div
        className={"py-2 px-5 border text-blue-500  text-sm border-blue-500 rounded-md text-center cursor-pointer " + (openTriggerFromScanBarcode && "hidden")}
        onClick={() => {
          handleOpen();
        }}>
        Repacking
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style} className="overflow-y-scroll variant-scroll">
          <div className="flex justify-end -mt-5">
            <IconButton onClick={handleClose} style={{ textAlign: 'right' }}>
              <CloseIcon />
            </IconButton>
          </div>
          <Typography id="modal-modal-title" className='flex items-center gap-x-3' variant="h6" component="h2">
            Detail Product: {id_so}
            {!detailOrder?.is_cicilan ? null :
              <div className='text-sm text-white bg-blue-800 px-4 py-1 rounded-full'>Instalment</div>
            }
            {isLoading && <CircularProgress size={20} />}
          </Typography>
          <div className="flex my-5 space-x-5">
            <div className="w-1/3 space-y-3">
              <div className="flex justify-between">
                <label className="text-sm font-semibold">
                  {t("Number of Carton")}
                </label>
                <input
                  type="text"
                  onChange={handleChangeBoxData}
                  name="numOfCarton"
                  // placeholder={`${t("Max")} ${totalQty} ${ {/* COMMENTED AT 29 JAN 2024 */}
                  //   totalQty > 1 ? t("Cartons") : t("Carton")
                  // }`}
                  placeholder='input number of cartons'
                  value={repacking.numOfCarton}
                  disabled={isLoading}
                  className="p-1 rounded-md border border-gray-300 w-52 focus:outline-blue"
                />
              </div>
              <div className="flex justify-between">
                <label className="text-sm font-semibold">Order Number</label>
                <input
                  type="text"
                  disabled={true}
                  defaultValue={id_so}
                  className="p-1 rounded-md border border-gray-300 w-52 focus:outline-blue"
                />
              </div>
              <div className="flex justify-between relative">
                <label className="text-sm font-semibold">PO Number</label>
                <input
                  type="text"
                  disabled={true}
                  defaultValue={id_po}
                  className="p-1 rounded-md border border-gray-300 w-52 focus:outline-blue"
                />
                {!isLoading &&
                  <div className="absolute top-1 -right-0 transform translate-x-full flex items-center gap-3 px-3">
                    <div className="merge-btn px-3 py-1 bg-green-600 hover:bg-green-500 text-white text-sm rounded-lg cursor-pointer"
                      onClick={() => setOpenMergeSelection(prev => !prev)}
                    >
                      {t("Merge with other PO")}
                    </div>
                    {openMergeSelection &&
                      <div className='absolute top-0 -right-0 transform translate-x-full bg-gray-100 shadow-lg max-h-28 overflow-y-auto p-3 rounded=lg'>
                        {detailOrder.poDetails?.filter(item => item.status !== 'closed').map((po) => (
                          <div key={po.id_po} className='flex gap-2'>
                            <input type='checkbox' name={po.id_po} id={po.id_po}
                              value={po.id_po}
                              disabled={po.id_po === id_po || disableMergePO}
                              onChange={(e) => !disableMergePO && set_selectedPoToMerge(e)}
                              checked={selectedPoToMerge.includes(po.id_po)}
                            />
                            <label htmlFor={po.id_po} className='cursor-default'>{po.id_po}</label>
                          </div>
                        ))}
                      </div>
                    }
                  </div>
                }
              </div>
              <div className="flex justify-between">
                <label className="text-sm font-semibold">Category</label>
                <select
                  className="p-1 rounded-md border border-gray-300 w-52 focus:outline-blue"
                  name="category"
                  value={repacking.category}
                  onChange={handleChangeBoxData}
                  disabled={isLoading}>
                  <option value="0" disabled>Choose Category</option>
                  {category?.map((cat, index) => (
                    <option key={index} value={cat.value}>{cat.category}</option>
                  ))}
                </select>
              </div>
              <div className="flex justify-between">
                <label className="text-sm font-semibold">{t("Forwarder")}</label>
                <select
                  className="p-1 rounded-md border border-gray-300 w-52 focus:outline-blue"
                  name="forwarder"
                  value={selectedForwarder}
                  onChange={(e) => set_SelectedForwarder(e.target.value)}
                  disabled={isLoading}
                >
                  <option value="" disabled>Choose Forwarder</option>
                  {forwarder?.map((fwd, index) => (
                    <option key={index} value={fwd.kurir}>{fwd.kurir}</option>
                  ))}
                </select>
              </div>
              {selectedForwarder === 'BL' &&
                <div className="flex justify-between">
                  <label className="text-sm font-semibold">{t("Zone")}</label>
                  <select
                    className="p-1 rounded-md border border-gray-300 w-52 focus:outline-blue"
                    name="zone"
                    value={selectedZone}
                    onChange={(e) => setSelectedZone(e.target.value)}
                    disabled={isLoading}
                  >
                    <option value="" disabled>Choose Zone</option>
                    {zones?.map((zone, index) => (
                      <option key={index} value={zone}>{zone}</option>
                    ))}
                  </select>
                </div>
              }
            </div>
            {!isLoading &&
              <div className="w-2/3 flex items-end">
                <button
                  className="p-1 px-2 bg-blue-500 text-white rounded-md"
                  onClick={() =>
                    box.length === 0
                      ? !repacking?.category ? swal('Oops', 'Please choose category first!', 'error')
                        : !selectedForwarder ? swal('Oops', 'Please choose forwarder first!', 'error')
                          : (selectedForwarder === 'BL' && !selectedZone) ? swal('Oops', 'Please choose zone first!', 'error')
                            : generateCarton()
                      : null
                  }>
                  {t("Add Carton")}
                </button>
              </div>
            }
          </div>
          {show !== false &&
            <div className="flex items-center gap-5 mb-5">
              <div className={`${show === "processing" ? "bg-blue-600 hover:bg-blue-500" : "bg-gray-300"} w-32 text-sm text-center py-[2px] text-white rounded-md cursor-pointer`}
                onClick={() => setShow("processing")}>Processing</div>
              <div className={`${show === "po detail" ? "bg-blue-600 hover:bg-blue-500" : "bg-gray-300"} w-32 text-sm text-center py-[2px] text-white rounded-md cursor-pointer`}
                onClick={() => setShow("po detail")}>PO Detail</div>
            </div>
          }
          {show === 'processing' && (
            <RepackingInputTable
              detailOrder={detailOrder}
              box={box}
              setBox={setBox}
              setUpdate={setUpdate}
              // newDataProduct={newDataProduct}
              // setNewDataProduct={setNewDataProduct}
              total_received_init={total_received_init}
              totalUnreceivedItemQty={totalUnreceivedItemQty}
              detailOrderProducts={products}
              selectedSku={selectedSku}
              setSelectedSku={setSelectedSku}
              setValidQtyDiff={setValidQtyDiff}
            />
          )}
          <hr className="my-3" />
          <div className="flex justify-center">
            {show === 'processing' && (
              <button
                className={`${box.length !== 0 && validQtyDiff && !isLoading ? 'bg-blue-500' : 'bg-gray-400'
                  } p-2 px-4 text-white rounded-md`}
                onClick={() => box.length !== 0 && validQtyDiff && !isLoading ? submitRepackingData() : null}
                disabled={box.length === 0 || !validQtyDiff || isLoading}>
                {isLoading ? "Loading..." : t("Submit")}
              </button>
            )}
          </div>

          {show === 'po detail' && (
            <PoDetail detailPo={detailOrder?.poDetails} id_so={id_so} />
          )}
        </Box>
      </Modal>

      <PrintBarcodeModal
        customerData={detailOrder?.customerDetail}
        // id_group={id_group}
        // id_so={id_so}
        open={openBarcode}
        handleClose={handleCloseBarcode}
        data={dataBarcode}
        isBolean={isBolean}
      />
    </div>
  );
}
