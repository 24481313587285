import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { IconButton } from "@mui/material";
import { manual_received_whindo } from "service/api";
import swal from "sweetalert";
import Cookies from "js-cookie";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  minHeight: 600,
  maxHeight: "95vh",
  bgcolor: "background.paper",
  border: "1px solid lightgray",
  boxShadow: 24,
  borderRadius: "5px",
  p: 4,
};

const ManualReceiveModal = ({ rowData, setUpdate }) => {
  const [open, setOpen] = useState(false);
  const [products, setProducts] = useState(null);
  const handleOpen = () => {
    createNewProductsObj()
    setOpen(true)
  };
  const handleClose = () => {
    setDataInputs({
      acceptedAll: false,
      palletNumber: "",
      keterangan: "",
    });
    setGambar([]);
    setProducts(null)
    setOpen(false);
  };
  const [gambar, setGambar] = useState([]);
  const [dataInputs, setDataInputs] = useState({
    acceptedAll: false,
    palletNumber: "",
    keterangan: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const createNewProductsObj = () => {
    let rowDataProducts = JSON.parse(JSON.stringify(rowData.product))
    const newRowDataProducts = rowDataProducts.map((prod) => {
      return {
        id_karton: rowData.id_karton,
        id_so: rowData.id_so,
        id_po: prod.id_po,
        id_product: prod.id_product,
        name: prod.name,
        image: prod.image,
        received: prod.received,
        lost: 0,
        damaged: 0,
        unsuitable: 0,
      }
    })
    setProducts(newRowDataProducts)
  }

  const submitImage = (e) => {
    let formData = new FormData();
    formData.append("gambar", e.target.files[0]);
    fetch(`${process.env.REACT_APP_URL_API_IMAGE_UPLOAD2}`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${Cookies.get('oms_token')}`,
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          setGambar((prev) => [
            ...prev,
            {
              gambar: data.file,
              id_karton: rowData.id_karton,
            },
          ]);
        } else {
          swal("Oops", data.message, "error");
        }
      })
      .catch((error) => {
        swal("Oops", error, "error");
      });
  };

  const handleChange = (e) => {
    let { name, value } = e.target;

    setDataInputs((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleChangeProducts = (e, index) => {
    const {name, value} = e.target
    let newProducts = [...products]
    newProducts[index][name] = ~~value
    setProducts(newProducts)
  }

  const set_Gambar = (index) => {
    let newGambar = [...gambar]
    newGambar.splice(index, 1)
    setGambar(newGambar)
  }

  const submitAdjustment = async () => {    
    if (dataInputs.palletNumber === "") {
      swal('Oops', 'Harap isi nomor palet', 'error')
      return
    }
    if (gambar?.length < 2) {
      swal('Oops', 'Lampiran gambar minimal 2!', 'error')
      return
    }

    setIsLoading(true)
    const payload = {
      id_so: rowData.id_so,
      Id_karton: rowData.id_karton,
      is_terima_semua: dataInputs.acceptedAll === 'on' ? true : false,
      palet: dataInputs.palletNumber,
      keterangan: dataInputs.keterangan,
      gambar: gambar,
      produk: products,
    };
    
    const response = await manual_received_whindo(JSON.stringify(payload));
    if (response?.status === 200) {
      setUpdate(prev => !prev)
    }
    setIsLoading(false)
  };
  
  return (
    <>
      <button
        className={`border-2 border-blue-300 py-1 px-3 text-gray-500 hover:text-gray-700 hover:border-blue-400 rounded-md cursor-pointer`}
        onClick={() => {
          handleOpen();
        }}
        disabled={false}>
        Terima Manual
      </button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style} className="overflow-y-scroll variant-scroll">
          <div className="flex justify-end -mt-5">
            <IconButton onClick={handleClose} style={{ textAlign: "right" }}>
              <CloseIcon />
            </IconButton>
          </div>
          <Typography id="modal-modal-title" variant="h6" component="h2" style={{ textAlign: 'center' }}>
            Terima Manual
          </Typography>
          <div className="">
            <label className="">Catatan:</label>
            <div className="">
              <textarea
                rows="5"
                className="p-1 border border-gray-400 rounded-md focus:outline-blue w-full"
                name="keterangan"
                value={dataInputs.keterangan}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="mt-3">
            <label className="">Nomor palet:</label>
            <div className="">
              <input type="text" 
              name="palletNumber" 
              id="palletNumber" 
              className="border border-gray-400 rounded-md focus:outline-blue w-28 px-2"
              value={dataInputs.palletNumber}
              onChange={handleChange}
              />
            </div>
          </div>
          <div className="mt-3">
            <label className="" >Gambar <small className="text-red-500">(minimal 2 dan maksimal 5)</small></label>
            <div className="flex items-center gap-3">
              {gambar?.length != 0 &&
                gambar?.map((img, index) => (
                  <div key={index} className="w-24 h-28 bg-gray-100 shadow-md rounded-md overflow-hidden relative">
                    <div className="absolute top-0 right-1 font-bold text-gray-300 hover:text-gray-500 drop-shadow cursor-pointer"
                    onClick={() => set_Gambar(index)}
                    >X</div>
                    <img src={img.gambar} className="w-full h-full object-cover" alt="gambar bukti terima manual" />
                  </div>
                ))
              }
              {gambar?.length < 5 &&
                <label htmlFor="icon-button-file" className="w-24 h-28 flex justify-center items-center text-gray-400 hover:text-gray-500 bg-red-100 shadow hover:shadow-md rounded-md cursor-pointer">add</label>
              }
              <input
                accept="image/*"
                id="icon-button-file"
                onChange={(e) => submitImage(e)}
                type="file"
                hidden
                disabled={gambar?.length >= 5}
                className="p-1 border border-gray-400 rounded-md focus:outline-blue"
              />
            </div>
          </div>
          <div className="mt-3">
            <label className="" >Produk yang diterima</label>
            {products?.map((prod, index) => (
              <div key={index} className="flex gap-2 p-2 mb-2 bg-gray-100 rounded-md shadow">
                <img src={prod.image} className="w-20 h-18 object-contain border rounded-md flex-shrink-0 bg-white" alt="product image" />
                <div className="details">
                  <div className="name text-sm line-clamp-1" title={prod.name}>{prod.name}</div>
                  <hr className="my-1" />
                  <div className="flex flex-wrap text-sm gap-3">
                    <div className="received flex flex-col items-center">
                      Diterima
                      <div className="w-14 h-6 border flex justify-center items-center rounded-md bg-gray-200">
                        {prod.received}
                      </div>
                    </div>
                    <div className="received flex flex-col items-center">
                      Hilang
                      <input type="number" 
                      name="lost"
                      value={prod.lost} 
                      className="w-14 h-6 border flex justify-center items-center rounded-md text-center"
                      onChange={(e) => handleChangeProducts(e, index)}
                      />
                    </div>
                    <div className="received flex flex-col items-center">
                      Rusak
                      <input type="number" 
                      name="damaged"
                      value={prod.damaged} 
                      className="w-14 h-6 border flex justify-center items-center rounded-md text-center"
                      onChange={(e) => handleChangeProducts(e, index)}
                      />
                    </div>
                    <div className="received flex flex-col items-center">
                      Tidak sesuai
                      <input type="number" 
                      name="unsuitable"
                      value={prod.unsuitable} 
                      className="w-14 h-6 border flex justify-center items-center rounded-md text-center"
                      onChange={(e) => handleChangeProducts(e, index)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* <div className='flex items-start space-x-2 mt-3'>
            <input
              type='checkbox'
              name='acceptedAll'
              checked={dataInputs.acceptedAll}
              style={{ width: '16px', marginTop: '.35rem' }}
              onChange={handleChange}
            />
            <label>
              Semua karton dengan nomor order ini telah diterima<br />
              <span className="text-gray-400 text-xs">Pastikan barang yang diterima dalam keadaan baik, tidak cacat, dan sesuai</span>
            </label>
          </div> */}
          <div className="text-center mt-5">
            <hr />
            <button
              className="bg-blue-400 text-white p-1 rounded-md px-5 mt-5"
              onClick={submitAdjustment}
              disabled={false}>
              Submit
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ManualReceiveModal;
