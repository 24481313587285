import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useHistory } from "react-router-dom";
// import Button from "../UI/Button";
// import Input from "../UI/Input";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import IconButton from "@mui/material/IconButton";
import { Tooltip } from "@mui/material";
import {
  createRoomsSocket,
  getPurchasingCounter,
  getReadUnread,
  getRoomsSocket,
} from "service/api";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "helpers/parseJWT";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import { useRecoilState } from "recoil";
import { triggerState } from "atom";
import { getRoomState } from "atom";

function Sidebar({ routes, title }) {
  const screenWidth = window.innerWidth;
  const [toggle, setToggle] = useState(false);
  const [notif, setNotif] = useState(1);
  const [purchasingCounter, setPurchasingCounter] = useState(null);
  const [triggerReConnect, setTriggerReConnect] = useState(false);
  const [triggerReFetchs, setTriggerReFetchs] = useState(false);
  const [isLoadingWs, setIsLoadingWs] = useState(false);
  // const [rooms, setRooms] = useState(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const [trigger, setTrigger] = useRecoilState(triggerState);
  const [getRoom, setGetRoom] = useRecoilState(getRoomState);
  const [dataSla, setDataSla] = useState('')
  const { search } = useLocation();
  const history = useHistory();
  const relativePath = location.pathname.split("/oms/")[1]?.split("?")[0];

  useEffect(() => {
    const query = new URLSearchParams(search);
    if (search) {
      setDataSla(query.get('sla'))
    } else {
      setDataSla(query.get('sla'))

    }
  }, [search]);

  useEffect(() => {
    if ((title === 'OCS' || title === 'CRM') || localStorage?.getItem('synching_po') === 'true') {
      fetchReadUnread();
    }
  }, []);

  useEffect(() => {
    if ((title === 'OCS' || title === 'CRM') || localStorage?.getItem('synching_po') === 'true') {
      const data = getUser().user;
      const existingRoom = getRoom?.find((room) => room.id === data);
      if (existingRoom) {
        // if(isLoadingWs === false) {
        fetchWebsocket();
        // }
        console.log('rooms')
      } else {
        createRoomSocketJs(data);
        console.log('create rooms')
        if (isLoadingWs === false) {
          fetchWebsocket();
        }
      }
    }
  }, [trigger]);

  useEffect(() => {
    if ((title === 'OCS' || title === 'CRM') || localStorage?.getItem('synching_po') === 'true') {
      getRoomsSocketJs();
    }
  }, [title]);

  useEffect(() => {
    if (location.pathname.startsWith("/purchasing") || location.pathname.startsWith("/logistic-china")) {
      fetchPurchasingCounter();
    }
  }, [location.pathname]);

  useEffect(() => {
    if (screenWidth < 600) {
      setToggle(true);
    }
  }, [screenWidth]);

  const fetchWebsocket = async () => {
    setIsLoadingWs(true);
    if (getUser) {
      const connection = new WebSocket(
        `${process.env.REACT_APP_URL_API_GET_LIST_WA_SOCKET}/ws/joinRoom/${getUser()?.user}?username=${getUser().user}&userId=${getUser().user}`
      );

      connection.onopen = (event) => {
        console.log("Koneksi WebSocket terbuka Sidebar");
      };

      connection.onmessage = function (event) {
        const data = JSON.parse(event.data);
        if (data?.data?.body === "sync_po") {
          toast.success('Synchronize data Completed', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
          localStorage.removeItem('synching_po')
          return
        }
        setTrigger((prev) => !prev);
        setTriggerReFetchs((prev) => !prev);
        toast.success(data?.data?.body, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        // console.log("daata", data);
      };

      connection.onclose = function (event) {
        // console.error('Socket disconnect: ', event);
        setTriggerReConnect((prev) => !prev);
      };
      connection.onerror = function (err) {
        // console.error('Socket error: ', err);
        setTriggerReConnect((prev) => !prev);
      };
      return () => {
        connection.close();
      };
    }
    setIsLoadingWs(false);
  };

  const fetchReadUnread = async () => {
    const contens = `is_read=all&page=1&limit=5`;
    const response = await getReadUnread(contens);
    if (response?.status === 200) {
      setNotif(response);
    } else if (response?.status === 403) {
      swal("Oops", `${response?.message}`, "error");
    }
  };

  const createRoomSocketJs = async () => {
    const data = getUser().user;
    const payload = {
      id: data,
      name: data,
    };
    const response = await createRoomsSocket(JSON.stringify(payload));
    if (response.status === 200) {
      console.log("sukses");
    }
  };

  const handleLinkClick = (pathname, value) => {
    const query = new URLSearchParams(location.search);

    // query.set('toko', 'all');
    if (value === true) {
      query.set('sla', 'true');
    } else {
      query.set('sla', 'false');
    }

    history.push({
      pathname: pathname,
      search: query.toString(),
    });
  };

  const getRoomsSocketJs = async () => {
    const response = await getRoomsSocket();
    setGetRoom(response?.collection)
  };

  // useEffect(() => {
  //   fetchWebsocket();
  // }, [statusTelpon]);

  const fetchPurchasingCounter = async () => {
    const res = await getPurchasingCounter();
    if (res?.status === 200) {
      setPurchasingCounter(res.data);
    }
  };
  return (
    <>
      <div
        className={`${toggle ? "-ml-2" : "-ml-10 bg-white "
          } transition-all duration-300 z-0 `}
        onClick={() => setToggle(false)}>
        <Tooltip title="Show Sidebar">
          <IconButton>
            <DoubleArrowIcon />
          </IconButton>
        </Tooltip>
      </div>
      <div
        className={`-mt-10 min-h-full transition-all duration-300 ${toggle
          ? "-ml-10 bg-gray-200"
          : "ml-0 bg-white border-r border-gray-100 w-52"
          }`}>
        <div className={`p-3 transition-all ${toggle && "hidden"} w-full`}>
          <div
            className={`${toggle ? "hidden" : "block"} flex justify-end -mt-5`}
            onClick={() => setToggle(true)}>
            <div className="-mr-2">
              <Tooltip title="Hide Sidebar">
                <IconButton>
                  <DoubleArrowIcon className="transform rotate-180 transition-all duration-300 " />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          {/* <NavLink to="/">
            <h1 className="text-md text-left font-semibold  -mt-5 mb-2">
              Dashboard
            </h1>
          </NavLink>
          <Input
            type="text"
            className="p-1 focus:outline-none rounded-md w-32 md:w-full"
          />
          <div className="text-white text-sm flex justify-between">
            <Button className="bg-blue-800 py-1 px-5 rounded-md hover:bg-blue-500 ">
              Search
            </Button>
            <Button className="bg-blue-300 py-1 px-5 rounded-md hover:bg-blue-100">
              Advance
            </Button>
          </div> */}
          <h1 className="text-sm text-gray-500 mt-3 text-center mb-1 font-bold">
            {title ? title : "Purchasing"}
          </h1>
        </div>
        <hr className="border bg-gray-100" />
        <div className="flex flex-col text-sm text-gray-600  py-4 relative">
          {routes
            .filter((data) => data.name)
            .map((route, id) => {
              let dataCounter = null;
              if (purchasingCounter) {
                if (route.name === "Already PO") {
                  dataCounter = purchasingCounter.already_po;
                } else if (route.name === "New Orders") {
                  dataCounter = purchasingCounter.new_orders;
                  // } else if (route.name === "OTW IDN") {
                  //   dataCounter = purchasingCounter.otw_whindo;
                } else if (route.name === "PO Paid") {
                  dataCounter = purchasingCounter.po_paid;
                  // } else if (route.name === "Processing") {
                  //   dataCounter = purchasingCounter.repacking;
                  // } else if (route.name === "Send to IDN") {
                  //   dataCounter = purchasingCounter.send_to_idn;
                  // } else if (route.name === "Arrived IDN") {
                  //   dataCounter = purchasingCounter.beacukai;
                } else if (route.name === "Received Items") {
                  dataCounter = purchasingCounter.arrive_whchina;
                } else if (route.name === "Processing") {
                  dataCounter = purchasingCounter.repacking;
                }
              }
              return (
                <div key={id} className="flex flex-col text-sm text-gray-600 relative">
                  <NavLink
                    to={`${route.pathname}`}
                    className={(isActive) =>
                      isActive
                        ? `bg-gray-200 p-3 border-l-4 border-blue-500 ${toggle && "hidden"}`
                        : `${toggle && "hidden"} p-3 hover:bg-gray-200 transition-all duration-300`
                    }
                  >
                    <div className="flex justify-between items-center">
                      <div>
                        <div>{route.name}</div>
                        <div className="text-xs">{dataCounter?.pic}</div>
                      </div>
                      {dataCounter && (
                        <div className="flex absolute right-0">
                          <Link
                            className={`${location.pathname === route.pathname & dataSla === "true" ? "border-black border-2" : ""} bg-green-600 rounded-full flex w-10 h-10 border text-white justify-center items-center text-xs`}
                            to="#"
                            onClick={(e) => {
                              e.preventDefault();
                              handleLinkClick(route.pathname, true);
                              e.stopPropagation();
                            }}
                          // onClick={(e) => e.stopPropagation()}
                          >
                            {dataCounter.total > 999 ? "+999" : dataCounter.total}
                          </Link>
                          <Link
                            to="#"
                            onClick={(e) => {
                              e.preventDefault();
                              handleLinkClick(route.pathname, false);
                              e.stopPropagation();
                            }}
                            className={`${location.pathname === route.pathname && dataSla === "false" ? "border-black border-2" : ""} rounded-full flex w-10 h-10 border text-white justify-center items-center bg-red-600 text-xs`}
                          >
                            {dataCounter.problem > 999 ? "+999" : dataCounter.problem}
                          </Link>
                        </div>
                      )}
                    </div>
                  </NavLink>
                  {route.pathname === "/management-sales/ocs" && (
                    <Link to={`/management-sales/ocs?contents=false`}>
                      <div className="justify-end flex absolute right-0 top-2">
                        <div className="ml-1 flex flex-shrink-0 p-1 max-w-6 h-6 z-60 text-base text-white bg-red-500 rounded-full justify-center items-center px-2">
                          {notif === null ? 0 : notif.total_unread}
                        </div>
                      </div>
                    </Link>
                  )}
                </div>
              );
            })}
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default Sidebar;
