import React, { useEffect, useState } from 'react';
import {
  filterDatWarehouseSendIDN,
  getDataWarehouseSendIDN,
  getDataWarehouseTotal,
  get_list_driver,
} from 'service/api';
import swal from 'sweetalert';
import Layout from 'components/Layout';
import Print from 'components/LogisticIndo/GudangPengiriman/Print';
import SearchBox from 'components/LogisticIndo/GudangPengiriman/SearchBox';
import ModalFilter from 'components/LogisticIndo/GudangPengiriman/ModalFilter';
import LayoutTable from 'components/LogisticIndo/GudangPengiriman/LayoutTable';
import HeaderPengiriman from 'components/LogisticIndo/GudangPengiriman/HeaderPengiriman';
import { SubRoutesLogisticIndo as SUBROUTES } from 'components/LogisticIndo/SubRoutesLogisticIndo';
import DownloadExcel from 'components/LogisticIndo/GudangPengiriman/DownloadExcel';
import ReactPaginate from "react-paginate";

const WarehouseSorting = () => {
  const [isLoading, setisLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataTotalGudang, setDataTotalGudang] = useState([]);
  const [listDriver, setListDriver] = useState([]);
  const [pageCount, setPageCount] = useState(0)
  const [inputs, setinputs] = useState({
    pencarian: '',
    resultPencarian: null,
    isModal: false,
    wilayah: 'all',
    kurir: '',
    container: '',
    tanggal: '',
    page: 0,
    limit: 10,
  });

  const handleClose = () =>
    setinputs({
      ...inputs,
      isModal: false,
    });

  const handleCariData = (value) => {
    // array -> arrayFiltered -> EditJadwal, gabungin Lagi
    let tempData = [...data];

    tempData.filter((i) => i === value);

    const result = tempData?.filter((i) => {
      return Object.values(i)
        .join('')
        .toLowerCase()
        .includes(value.toLowerCase());
    });

    setinputs({
      ...inputs,
      resultPencarian: result,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    handleCariData(value);
    setinputs((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleChangeDate = (e, id) => {
    const { value, name } = e.target;

    if (name === 'data') {
      let tempData = [...data];
      tempData[id].jadwal = value;
      tempData[id].is_updated = value?.length !== 0 ? true : false;

      setinputs({
        ...inputs,
        resultPencarian: tempData,
      });
      swal(
        `1 data telah di update. Silahkan ubah dengan menekan tombol Update Perubahan Data.`
      );
      return;
    }

    if (name === 'inputsResultPencarian') {
      let tempData = [...inputs?.resultPencarian];
      tempData[id].jadwal = value;
      tempData[id].is_updated = value?.length !== 0 ? true : false;

      setinputs({
        ...inputs,
        resultPencarian: tempData,
      });
      swal(
        `1 data telah di update. Silahkan ubah dengan menekan tombol Update Perubahan Data.`
      );
      return;
    }

    if (name === 'all') {
      let dataChange = data.forEach((data) => {
        data.jadwal = value;
        data.is_updated = value?.length !== 0 ? true : false;
      });

      setinputs({
        ...inputs,
        resultPencarian: dataChange,
      });

      swal(
        `${data?.length} data telah di update. Silahkan ubah dengan menekan tombol Update Perubahan Data.`
      );
      return;
    }

    if (name === 'allFiltered') {
      let dataChange = inputs?.resultPencarian.forEach((data) => {
        data.jadwal = value;
        data.is_updated = value?.length !== 0 ? true : false;
      });

      setinputs({
        ...inputs,
        pencarian: '',
        resultPencarian: dataChange,
      });

      swal(
        `${inputs?.resultPencarian?.length} data telah di update. Silahkan ubah dengan menekan tombol Update Perubahan Data.`
      );
      return;
    }
  };

  const handleFilter = () => {
    setinputs({
      ...inputs,
      isModal: true,
    });
  };

  const handleSubmitFilter = async () => {
    const { wilayah, kurir, container, tanggal } = inputs;
    setisLoading(true);

    let body = {
      wilayah,
      kurir,
      container,
      tanggal,
    };
    const params = new URLSearchParams({ page: 1, limit: inputs.limit }).toString();

    if (!wilayah && !kurir && !container && !tanggal) {
      swal('Oops', `Minimum 1 filter input dipilih`, 'info');
      return setisLoading(false);
    }

    const response = await filterDatWarehouseSendIDN(JSON.stringify(body), params);
    if (response?.status === 200) {
      setData(response?.data?.id_palet);
      setPageCount(Math.ceil(response?.total_data / inputs.limit))
      setinputs({
        ...inputs,
        resultPencarian: null,
        isModal: false,
      });
    } else {
      swal('error', `Gagal Filter Data, ${response.message}`, 'error');
    }

    return setisLoading(false);
  };

  const handleCariDataTerbanyak = async (nameWilayah) => {
    let body = {
      wilayah: nameWilayah,
      kurir: "",
      container: "",
      tanggal: "",
    };
    const params = new URLSearchParams({ page: 1, limit: inputs.limit}).toString();

    const response = await filterDatWarehouseSendIDN(JSON.stringify(body), params);
    if (response?.status === 200) {
      setData(response?.data?.id_palet);
      setPageCount(Math.ceil(response?.total_data / inputs.limit))
      setinputs({
        ...inputs,
        wilayah: nameWilayah,
        kurir: "",
        container: "",
        tanggal: "",
        pencarian: '',
        resultPencarian: null,
        isModal: false,
        page: 0,
      });
    } else {
      swal('error', `Gagal Filter Data, ${response?.message}`, 'error');
    }
  };

  useEffect(() => {
    fetchDataGudangPengiriman();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs.page, inputs.limit]);

  const fetchDataGudangPengiriman = async () => {
    setisLoading(true);
    const { wilayah, kurir, container, tanggal } = inputs;
    let body = {
      wilayah,
      kurir,
      container,
      tanggal,
    };
    const params = new URLSearchParams({ page: inputs.page + 1, limit: inputs.limit }).toString();

    const response = await filterDatWarehouseSendIDN(JSON.stringify(body), params);
    if (response?.status === 200) {
      setData(response?.data?.id_palet);
      setPageCount(Math.ceil(response?.total_data / inputs.limit))
    } else {
      swal('error', `Gagal Mendapatkan Data : ${response?.message}`, 'error');
    }

    return setisLoading(false);
  };

  useEffect(() => {
    const fetchDataGudangTotal = async () => {
      const response = await getDataWarehouseTotal();
      if (response?.status === 200) {
        setDataTotalGudang(response?.data);
      }
    };
    const getListDriver = async () => {
      const response = await get_list_driver();
      if (response?.status === 200) {
        setListDriver(response?.data);
      }
    };

    fetchDataGudangTotal();
    getListDriver();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const SUBROUTES = [
  //   {
  //     name: 'Received WH Indo',
  //     pathname: '/logistic-indo/received-wh-indo',
  //   },
  //   {
  //     name: 'Gudang Pengiriman',
  //     pathname: '/logistic-indo/warehouse-sorting',
  //   },
  //   {
  //     name: 'WH Indo Delivery',
  //     pathname: '/logistic-indo/wh-indo-delivery',
  //   },
  //   {
  //     name: 'Logistics Indo',
  //     pathname: '/logistic-indo/logistics-indo',
  //   },
  //   {
  //     name: 'Form Actual Price',
  //     pathname: '/logistic-indo/form-actual-price',
  //   },
  //   { name: 'Abnormal', pathname: '/logistic-indo/abnormal' },
  //   // { name: 'Persetujuan', pathname: '/logistic-indo/ApprovalWhIndo' },
  // ];
  
  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setinputs(prev => {return {...prev, page: selectedPage}})
};

  return (
    <Layout searchBar={false} routes={SUBROUTES()} title="Gudang Pengiriman">
      <HeaderPengiriman
        dataTotalPengiriman={dataTotalGudang}
        isLoading={isLoading}
        handleCariDataTerbanyak={handleCariDataTerbanyak}
      />

      <div
        style={{ fontFamily: 'Poppins' }}
        className="bg-white w-full rounded-lg p-5 mt-2">
        {/* <SearchBox
          inputs={inputs}
          setinputs={setinputs}
          handleChange={handleChange}
          handleRefreshTable={handleRefreshTable}
        /> */}

        <div className="mt-7">
          <div className="ml-4 space-x-5 relative mt-10">
            <Print inputs={data} />
            <DownloadExcel data={data} />

            <button
              onClick={handleFilter}
              className="bg-blue-500 rounded-t-lg duration-300 text-white text-sm px-5 pt-1 pb-px absolute bottom-0 right-10 hover:pb-5">
              Filter Data Server
            </button>
          </div>
          <LayoutTable
            isLoading={isLoading}
            inputs={inputs}
            data={data}
            listDriver={listDriver}
            handleChangeDate={handleChangeDate}
          />
          <div className="pagination-wrapper flex justify-end items-center">
              <div className="per-page flex items-center gap-x-2 mr-3">
                per-page:
                <select name="limit" 
                id="limit" 
                value={inputs.limit} 
                className='border rounded-md px-2 py-[3px]'
                onChange={(e) => setinputs(prev => {return {...prev, limit: e.target.value}})}
                >
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                </select>
              </div>
              <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                  forcePage={inputs.page}
                  onPageChange={(e) => handlePageClick(e)}
              />
          </div>
        </div>
      </div>
      <ModalFilter
        inputs={inputs}
        handleClose={handleClose}
        handleChange={handleChange}
        handleSubmitFilter={handleSubmitFilter}
      />
    </Layout>
  );
};

export default WarehouseSorting;
