import React from "react";
import { Redirect } from "react-router-dom";

const LogiscticIndo = () => {
  return (
    <>
      <Redirect to="logistic-indo/received-wh-indo" />
    </>
  );
};

export default LogiscticIndo;
