import {
    Table,
    TableContainer,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
    Paper,
    Collapse,
    IconButton,
  } from '@mui/material';
  import { useState } from 'react';
  import React from 'react';
  // import { Box } from '@mui/system';
  // import SubDetailProduct from './SubDetailProduct';
  // import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
  // import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
  // import InputContainerNumber from './InputContainerNumber';
  
  export default function DetailListContainerTableV2({ dataOrder, setUpdate, id_so, isLoading }) {
    const [newDataOrder, setNewDataOrder] = useState(
      dataOrder.list_carton.map((data) => {
        return { ...data, isChecked: false };
      })
    );
    const [checkedAll, setCheckedAll] = useState(false);
    const handleChecked = (e, id) => {
      const { checked } = e.target;
      const values = [...newDataOrder];
      if (id !== undefined) {
        values[id].isChecked = checked;
        setNewDataOrder(values);
        const isCheckAll = newDataOrder.filter(
          (data) => data.isChecked === false
        );
        if (isCheckAll.length === 0) {
          setCheckedAll(true);
        } else {
          setCheckedAll(false);
        }
      } else {
        setCheckedAll(checked);
        setNewDataOrder(
          newDataOrder.map((data) => {
            return { ...data, isChecked: checked };
          })
        );
      }
    };
  
  const Row = ({ rowData, handleChecked, id, isLoading }) => {
    const [open, setOpen] = useState(false);
    return (
      <TableRow style={{ display: "flex" }}>
        <TableCell
        align="center"
        style={{
            width: "15%",
        }}>
        </TableCell>
        <TableCell
        align="center"
        style={{
            width: "10%",
        }}>
          {rowData?.id_carton}/{dataOrder?.list_carton.length}
        </TableCell>
        <TableCell
        align="center"
        style={{
            width: "15%",
        }}>
          {rowData?.volume}
        </TableCell>
        <TableCell
        align="center"
        style={{
            width: "10%",
        }}>
        </TableCell>
        <TableCell
        align="center"
        style={{
            width: "10%",
        }}>
        </TableCell>
        <TableCell
        align="center"
        style={{
            width: "20%",
        }}>
        </TableCell>
        <TableCell
        align="center"
        style={{
            width: "20%",
        }}>
        </TableCell>
      </TableRow>
    );
  };

    return (
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={5} align="center" style={{ height: "50vh" }}>
                  Loading...
                </TableCell>
              </TableRow>
            ) : dataOrder?.list_carton?.length > 0 ? (
              <>
                {dataOrder?.list_carton?.map((row, index) => (
                  <Row rowData={row} key={index} id={index} isLoading={isLoading} />
                ))}
              </>
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center" style={{ height: "50vh" }}>
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
  