import { useEffect, useRef, useState } from "react";
// import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import {
  IconButton,
  // Tooltip,
  // Chip,
  // CircularProgress,
  Typography,
  Fade,
  Modal,
  Backdrop,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";
import { getUser } from "helpers/parseJWT";
import Cookies from "js-cookie";
// import swal from "sweetalert";
// import GppBadIcon from "@mui/icons-material/GppBad";
// import MaterialTable from "material-table";
// import tableIcons from "helpers/materialTableIcons";
// import { FormatDate } from "helpers/ConvertTime";
// import { MuiThemeProvider } from "@material-ui/core";
// import { createTheme } from "@material-ui/core/styles";
// import { add_multi_sales_data, submit_oms_accounts_list, update_oms_accounts_list } from "service/api";
// import moment from "moment";
// import { AddCircle, KeyboardReturnRounded } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  maxHeight: "90vh",
  overflowY: "auto",
  bgcolor: "white",
  border: "1px solid lightgray",
  boxShadow: 24,
  borderRadius: "5px",
  p: 4,
};

const ModalDetailOmsAccountUser = ({
  selectedUser,
  showModal,
  setShowModal,
  listUserAttribute
}) => {
  const [newUser, setNewUser] = useState({
    user: '',
    nama: '',
    password: '',
    jabatan: '',
    department: '',
    foto: '',
    atasan: '',
    telepon: '',
    quota: '',
  });
  // const [isLoading, setIsLoading] = useState(false)
  const imageUpload = useRef(null);
  
  useEffect(() => {
    if (selectedUser) {
      setNewUser({
        user: selectedUser.user,
        nama: selectedUser.nama,
        jabatan: selectedUser.jabatan,
        department: selectedUser.department,
        foto: selectedUser.foto,
        atasan: selectedUser.atasan,
        telepon: selectedUser.telepon,
        quota: selectedUser.quota,
      })
    }
  }, [selectedUser])

  // const handleChange = (e) => {
  //   const {name, value} = e.target
  //   setNewUser((prev) => {return {...prev, [name]: value}})
  // }

  // const handleChangeImages = async (event) => {
  //   let formData = new FormData();
  //   formData.append('gambar', event.target.files[0]);
  //   const response = await fetch(
  //     `${process.env.REACT_APP_URL_API_IMAGE_UPLOAD2}`,
  //     {
  //       method: 'POST',
  //       body: formData,
  //       headers: {
  //       Authorization: `Bearer ${Cookies.get('oms_token')}`,
  //       }
  //     }
  //   );
    
  //   const data = await response.json();

  //   if (data?.status === 200) {
  //     setNewUser((prev) => {return {...prev, foto: data?.file}})
  //   } else {
  //     swal('Oops', `Images ${data.message}`, 'error');
  //   }
  // };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={showModal}
      onClose={() => setShowModal(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={showModal}>
        <Box sx={style}>
          <div className="flex justify-end -mt-5">
            <IconButton
              onClick={() => setShowModal(false)}
              style={{ textAlign: "right" }}>
              <CloseIcon />
            </IconButton>
          </div>
          <Typography id="transition-modal-title" variant="h6" component="h2">
            OMS ACCOUNT USER DETAIL
          </Typography>
          <div className="flex flex-col items-center space-y-3 mt-3">
            <div className="flex items-center gap-x-3 w-full">
              <label htmlFor="user" className="w-28 flex-shrink-0">Username</label>
              <input type="text" name="user" id="user" 
                className="w-full border border-gray-300 rounded-md px-2"
                value={newUser.user} 
                disabled
              />
            </div>
            <div className="flex items-center gap-x-3 w-full">
              <label htmlFor="nama" className="w-28 flex-shrink-0">Nama Lengkap</label>
              <input type="text" name="nama" id="nama" 
                className="w-full border border-gray-300 rounded-md px-2"
                value={newUser.nama} 
                disabled
              />
            </div>
            <div className="flex items-center gap-x-3 w-full">
              <label htmlFor="password" className="w-28 flex-shrink-0">Password</label>
              <input type="text" name="password" id="password" 
                className="w-full border border-gray-300 rounded-md px-2"
                value={newUser.password} 
                disabled
              />
            </div>
            <div className="flex items-center gap-x-3 w-full">
              <label htmlFor="telepon" className="w-28 flex-shrink-0">Telepon</label>
              <input type="text" name="telepon" id="telepon" 
                className="w-full border border-gray-300 rounded-md px-2"
                value={newUser.telepon} 
                disabled
              />
            </div>
            <div className="flex items-center gap-x-3 w-full">
              <label htmlFor="jabatan" className="w-28 flex-shrink-0">Jabatan</label>
              <select name="jabatan" id="jabatan" 
                className="w-full border border-gray-300 rounded-md px-2"
                value={newUser.jabatan} 
                disabled
              >
                <option value="" disabled>-</option>
                {listUserAttribute?.jabatan?.map((item, index) => 
                  <option key={item.jabatan} value={item.jabatan} className="capitalize">{item.jabatan}</option>
                )}
              </select>
            </div>
            <div className="flex items-center gap-x-3 w-full">
              <label htmlFor="department" className="w-28 flex-shrink-0">Department</label>
              <select name="department" id="department" 
                className="w-full border border-gray-300 rounded-md px-2"
                value={newUser.department} 
                disabled
              >
                <option value="" disabled>-</option>
                {listUserAttribute?.department?.map((item, index) => 
                  <option key={item.department} value={item.department} className="capitalize">{item.department}</option>
                )}
              </select>
            </div>
            {(getUser().division.includes('bd') && (newUser.department.toLowerCase() === "obe-trainee" || newUser.department.toLowerCase() === "obe-staff" || newUser.department.toLowerCase() === "obe")) &&
              <div className="flex items-center gap-x-3 w-full">
                <label htmlFor="quota" className="w-28 flex-shrink-0">Quota</label>
                <input type="number" name="quota" id="quota" 
                  className="w-full border border-gray-300 rounded-md px-2"
                  value={newUser.quota} 
                  disabled
                />
              </div>
            }
            <div className="flex items-center gap-x-3 w-full">
              <label htmlFor="atasan" className="w-28 flex-shrink-0">Atasan</label>
              <select name="atasan" id="atasan" 
                className="w-full border border-gray-300 rounded-md px-2"
                value={newUser.atasan} 
                disabled
                >
                <option value="" disabled>-</option>
                {listUserAttribute?.atasan?.map((item, index) => 
                  <option key={item.atasan} value={item.atasan} className="capitalize">{item.atasan}</option>
                )}
              </select>
            </div>
            
            <label htmlFor="foto" className="w-28 h-32 border rounded-lg relative hover:scale-105 hover:shadow duration-300">
              <img
                src={newUser.foto || '/default_image.png'}
                alt=""
                className="w-full h-full object-contain"
              />
              <div
                className="absolute text-xs bottom-4 w-full text-center font-semibold cursor-pointer hover:font-bold text-gray-300 hover:text-gray-400"
                style={{
                  textShadow:
                    '1px 1px 0px white, -1px -1px 0 white, -1px 1px 0 white, 1px -1px 0 white',
                }}
                // onClick={() => imageUpload.current.click()}
              >
                {newUser.foto === '' ? 'Upload foto' : 'Ganti foto'}
              </div>
            </label>
            <input
              name="foto"
              id="foto"
              disabled
              className="hidden"
              type="file"
              ref={imageUpload}
            />
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ModalDetailOmsAccountUser;
