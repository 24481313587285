import Cookies from 'js-cookie';
import { generateRandomString } from './helpers';

export const uploadFile = async (file) => {
	try {
		const random = generateRandomString(5);
		const response = await fetch(
			`${process.env.REACT_APP_URL_API_DOCUMENT_UPLOAD}?random=${random}`, //to handle cache in browser
			{
				method: 'POST',
				body: file,
				headers: {
					Authorization: `Bearer ${Cookies.get('oms_token')}`,
				},
			}
		);

		if (!response.ok) {
			throw new Error('Network error');
		}
		const data = await response.json();
		return data;
	} catch (error) {
		throw error;
	}
};
