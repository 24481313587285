import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Layout from 'components/Layout';
import Container from 'components/Layout/Container';
import Navbar from 'components/Layout/Navbar';
import SearchBar from 'components/Layout/SearchBar';
import Sidebar from 'components/Layout/Sidebar';
import ReceivedItemsTable from 'components/LogisticChina/OtwWhChina/ReceivedItemsTable';
import ScanBarcode from 'components/LogisticChina/OtwWhChina/ScanBarcode/ScanBarcode';
import { exportOtwWhChina } from 'service/api';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import exportFromJSON from 'export-from-json';
import { SubRoutesLogisticChina as SUBROUTES } from 'components/LogisticChina/SubRoutesLogisticChina';
import swal from 'sweetalert';
import { useTranslation } from "react-i18next";

const ReceivedItems = () => {  
  const [update, setUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const { t } = useTranslation();

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const toko = query.get('toko');
  const auto = query.get('auto');
  const canceled = query.get('canceled');

  const handleExport = async () => {
    const response = await exportOtwWhChina();

    const exportType = exportFromJSON.types.xls;
    if (response.status === 200) {
      exportFromJSON({
        data: response.data,
        fileName: response.fileName,
        exportType,
      });
    } else {
      swal('Oops', response.message, 'error');
    }
  };

  return (
    <Layout routes={SUBROUTES()} title="Logistic China">
      <div className='flex gap-2'>
        <ScanBarcode 
          update={update}
          setUpdate={setUpdate}
        />
      </div>
    
      <div className='bg-white mt-2 rounded'>
        <div className= "flex items-center space-x-3 px-2">
          <span className='p-1'>{t("Marketplace")}:</span>
          <Link to={{search : "?toko=all"}} className={`${isLoading ? 'bg-gray-300 cursor-default' : (toko == "all" || toko == null)? 'bg-orange-500' : 'bg-blue-500'} text-xs px-3 py-1 text-white rounded-md cursor-pointer bg-blue-500 hover:bg-orange-400`}>
              All
          </Link>
          <Link to={{search : "?toko=1688"}} className={`${isLoading ? 'bg-gray-300 cursor-default' : toko == "1688" && (auto == 'false' || auto == null) ? "bg-orange-500" :'bg-blue-500'} text-xs px-3 py-1 text-white rounded-md cursor-pointer bg-blue-500 hover:bg-orange-400`}>
              1688
          </Link>
          <Link to={{search : "?toko=taobao"}} className={`text-xs px-3 py-1 text-white rounded-md cursor-pointer  ${toko == "taobao" ? "bg-orange-500" :"bg-blue-500"} hover:bg-orange-400`}>
            Taobao
          </Link>

          <Link to={{search : "?toko=alibaba"}} className={`text-xs px-3 py-1 text-white rounded-md cursor-pointer  ${toko == "alibaba" ? "bg-orange-500" :"bg-blue-500"} hover:bg-orange-400`}>
            Alibaba
          </Link>
        </div>
      </div>

      <ReceivedItemsTable
        update={update}
        setUpdate={setUpdate}
      />
    </Layout>
  );
};

export default ReceivedItems;