import { useEffect, useState } from 'react';
import { getScanDetailCarton, get_products_by_karton, insert_scan_carton, submit_cartons_to_abnormals } from 'service/api';
import DeleteIcon from '@mui/icons-material/Delete';
import swal from 'sweetalert';
import {
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Paper,
  IconButton,
  Collapse,
  Box,
  Tooltip,
  Modal
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Check } from '@mui/icons-material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  maxHeight: '90vh',
  overflow: 'auto',
  bgcolor: 'background.paper',
  border: '1px solid lightgray',
  boxShadow: 24,
  borderRadius: '5px',
  p: 4,
};

const style2 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 992,
  maxHeight: '90vh',
  overflow: 'auto',
  bgcolor: 'background.paper',
  border: '1px solid lightgray',
  boxShadow: 24,
  borderRadius: '5px',
  p: 4,
};

const ModalWarningUnScannedCarton = ({
  inputContainerData,
  inputs,
  scannedData,
  showWarningModal,
  setShowWarningModal,
  showInsertUnscannedDetails,
  setShowInsertUnscannedDetails,
  submitInputContainer,
  getScannedCartons
}) => {
  const [unscannedCartons, setUnscannedCartons] = useState([])
  const [detailOrderProducts, setDetailOrderProducts] = useState([])
  const [selectedSku, setSelectedSku] = useState([
    {
      id: Math.floor((Math.random() * 999999) + 100000),
      product: '',
      quantity: '',
      reason: 'lost',
    }
  ])
  const [searchSKU, setSearchSKU] = useState('')
  const [showVariants, setShowVariants] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if(scannedData) {
      const newScannedData = scannedData.unscanned.map((item) => {
        return {
          id_karton: item,
          id_so: item.split('-')[0],
          rejected_product: []
        }
      })
      setUnscannedCartons(newScannedData)
    }
  }, [scannedData])
  
  
  const addNewVariant = () => {
    setSelectedSku(prev => [...prev, 
      {
        id: Math.floor((Math.random() * 999999) + 100000),
        product: '',
        quantity: '',
        reason: 'lost',
      }
    ])
  }

  const removeLastVariant = () => {
    const newSelectedSku = [...selectedSku]
    newSelectedSku.pop()
    setSelectedSku(newSelectedSku)
  }
  
  const setSelectedSKU = (variant, index) => {
    const findIndex = selectedSku.findIndex((sku) => sku.product.sku === variant.sku)
    if (findIndex < 0) {
      const newSelectedVariant = [...selectedSku]
      newSelectedVariant[index].product = variant
      newSelectedVariant[index].quantity = ''
      setSelectedSku(newSelectedVariant)
    }
  }
  
  const setSelectedSKUQty = (qty, id, index, orderedQty, variant) => {
    let newAbnormalProducts = JSON.parse(JSON.stringify(abnormalProducts))

    const newUnScannedCartons = [...unscannedCartons]
    const currentIndex = newUnScannedCartons.findIndex(item => item.id_karton === showInsertUnscannedDetails)

    const indexSelectedSku = newUnScannedCartons[currentIndex].selected_sku?.findIndex(item => item.id === variant.id)
    
    if (indexSelectedSku === undefined || indexSelectedSku < 0) {
      const newTotalQty = newAbnormalProducts.reduce((total, curr) => curr.id_so == variant.product.id_so && curr.id_product == variant.product.produk_id ? total + ~~curr.qty : total, 0)
      if(newTotalQty + ~~qty <= orderedQty) {
        setQuantity()
      }
    } else {
      const indexOfAbnormal = newAbnormalProducts.findIndex(i => i.id === id)

      if(indexOfAbnormal > -1) {
        newAbnormalProducts[indexOfAbnormal].qty = ~~qty
      }
      const newTotalQty = newAbnormalProducts.reduce((total, curr) => curr.id_so == variant.product.id_so && curr.id_product == variant.product.produk_id ? total + ~~curr.qty : total, 0)
      if(newTotalQty <= orderedQty) {
        setQuantity()
      } else {
        newAbnormalProducts[indexOfAbnormal].qty = abnormalProducts[indexOfAbnormal].qty
      }
      setAbnormalProducts(newAbnormalProducts)
    }

    function setQuantity () {
      const newSelectedVariant = [...selectedSku]
      newSelectedVariant[index].quantity = ~~qty
      setSelectedSku(newSelectedVariant)
    }
  }

  const set_ShowInsertUnscannedDetails = (carton) => {
    const orderId = carton.id_karton.split('-')[0]
    setShowInsertUnscannedDetails(carton.id_karton)
    if(carton.selected_sku?.length) {
      setSelectedSku(carton.selected_sku)
    }
    getDetailOrderProducts(orderId)
  }

  const getDetailOrderProducts = async (orderId) => {
    setIsLoading(true)
    const response = await get_products_by_karton(orderId)
    if (response.status === 200) {
      setDetailOrderProducts(response.data)
    }    
    setIsLoading(false)
  }

  const saveAbnormal = () => {
    const newSelectedSku = selectedSku.filter((item) => item.product !== '' && item.quantity !== '' && item.quantity !== 0)
    
    let finalSelectedSku
    if (newSelectedSku.length) {
      finalSelectedSku = newSelectedSku.map((sku) => {
        return {
          id: sku.id,
          id_product: sku.product.produk_id,
          qty: ~~sku.quantity,
          id_po: sku.product.id_po,
          gambar: sku.product.image,
          reason: sku.reason
        }
      })
      const newUnScannedCartons = [...unscannedCartons]
      const currentIndex = newUnScannedCartons.findIndex(item => item.id_karton === showInsertUnscannedDetails)
      newUnScannedCartons[currentIndex].rejected_product = finalSelectedSku
      newUnScannedCartons[currentIndex].selected_sku = newSelectedSku
      setUnscannedCartons(newUnScannedCartons)
      close_ShowInsertUnscannedDetails()
    } else {
      swal('Please complete detail of missing product(s)', {
        icon: 'error',
      });
    }
  }
  
  const close_ShowInsertUnscannedDetails = () => {
    setDetailOrderProducts([])
    setSelectedSku([
      {
        id: Math.floor((Math.random() * 999999) + 100000),
        product: '',
        quantity: '',
        reason: 'lost',
      }
    ])
    setShowInsertUnscannedDetails(false)
  }

  const submitWithAbnormal = async () => {
    const checkedUnscannedCartons = unscannedCartons.filter((carton) => carton.rejected_product.length === 0)
    
    if(checkedUnscannedCartons.length === 0) {
      const payload = {
        container: inputs.container,
        list_of_rejected_karton: unscannedCartons
      }
      
      const responseSubmitToAbnormal = await submit_cartons_to_abnormals(JSON.stringify(payload))
      if (responseSubmitToAbnormal.status === 200) {
        swal('Table Container has been Submited', {
          icon: 'success',
        });
        await getScannedCartons(inputContainerData.marking_code)
        setShowWarningModal(false)
      } else {
        swal('Error', responseSubmitToAbnormal.message, 'error')
      }
    } else {
      swal('Invalid', 'Please complete all input details from all cartons', 'error')
    }
  }
  const [abnormalProducts, setAbnormalProducts] = useState([])
  useEffect(() => {
    let abnormal_Products = []
    unscannedCartons.map((i) => i.rejected_product.map((j) => 
      abnormal_Products.push({
        ...j, 
        id_so: i.id_so, 
        id_karton: i.id_karton
      })
    ))
    setAbnormalProducts(abnormal_Products)
  }, [unscannedCartons])
  
  return (
    <>
      {/* MODAL WARNING UNSCANNED CARTON */}
      <Modal
        name='modal_warning_unscanned_carton'
        open={showWarningModal}
        // onClose={() => setShowWarningModal(false)}
        >
        <Box sx={style}>
          <div className='flex justify-end -mt-5'>
            <IconButton
              name='modal_warning_unscanned_carton'
              onClick={() => setShowWarningModal(false)}
              style={{ textAlign: 'right' }}>
              <CloseIcon className='hover:text-red-600' />
            </IconButton>
          </div>
          <div className="w-full">
            <h2 className='text-2xl font-bold'>WARNING</h2>
            <p>There are some Carton IDs that have not been scanned. If you submit this, carton IDs that are listed below will automatically enter abnormal.</p>
            <div className=' max-h-[60vh] overflow-auto'>
              <table className='w-full'>
                <thead>
                  <tr className=''>
                    <th className='w-1/3 text-center'>Carton ID</th>
                    <th className='w-1/3 text-center'>Actions</th>
                    <th className='w-1/3 text-center'></th>
                  </tr>
                </thead>
                <tbody>
                  {unscannedCartons?.map((carton, index) => (
                    <tr key={index}>
                      <td className='text-center'>{carton.id_karton}</td>
                      <td className='text-center'>
                        <div className="bg-gray-400 hover:bg-gray-500 text-white rounded py-1 text-xs cursor-pointer" onClick={() => set_ShowInsertUnscannedDetails(carton)}>Input Details</div>
                      </td>
                      <td className='text-center'>
                        {carton.rejected_product.length !== 0 ? <Check /> : '-'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className='w-max px-5 py-2 text-center mx-auto bg-blue-500 hover:bg-blue-600 text-white rounded-lg mt-3 cursor-pointer' onClick={submitWithAbnormal}>Save</div>
          </div>
        </Box>
      </Modal>

      {/* MODAL INSERT UNSCANNED DETAILS */}
      <Modal
        name='modal_insert_unscanned_details'
        open={showInsertUnscannedDetails}
        onClose={close_ShowInsertUnscannedDetails}>
        <Box sx={style2}>
          <div className='flex justify-end -mt-5'>
            <IconButton
              name='modal_insert_unscanned_details'
              onClick={close_ShowInsertUnscannedDetails}
              style={{ textAlign: 'right' }}>
              <CloseIcon className='hover:text-red-600' />
            </IconButton>
          </div>
          
          <div className="unreceived-items mt-3">
            <div className="font-bold underline">Abnormal</div>
            <TableContainer
            component={Paper}
            sx={{ minHeight: 300 }}
            className="variant-scroll">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className='w-1/4'>Product Name</TableCell>
                    <TableCell className='w-1/4'>Variant</TableCell>
                    <TableCell className='w-16'>Image</TableCell>
                    <TableCell className='w-16'>Quantity</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading ? 
                    <TableRow>
                      <TableCell colSpan={5}>
                        <div className='text-center py-5'>Loading data...</div> 
                      </TableCell>
                    </TableRow> 
                    :
                    <TableRow>
                      <TableCell>{detailOrderProducts?.[0]?.product}</TableCell>
                      <TableCell>
                        <div className='relative space-y-2'>
                          {selectedSku.map((variant, index) => (
                            <div key={index}>
                            <div className='w-full border rounded focus:outline-none h-8 px-2 py-1 whitespace-nowrap max-w-[15rem] overflow-hidden' title={variant.product.sku} onClick={() => setShowVariants(index.toString())}>
                              {variant.product.sku}
                            </div>
                            {index === selectedSku.length - 1 && showVariants === index.toString() &&
                              <div className="absolute bottom-0 translate-y-full max-w-[12rem] overflow-x-hidden">
                                <input type="text" className='w-full border rounded focus:outline-none px-2 py-1'
                                  placeholder='search sku'
                                  value={searchSKU}
                                  onChange={(e) => setSearchSKU(e.target.value)}
                                />
                                {searchSKU !== '' &&
                                  <div className="absolute right-2 top-[5px] text-gray-400 hover:text-gray-500 font-semibold" onClick={() => setSearchSKU('')}>x</div>
                                }
                                <div className="bg-gray-100 rounded shadow py-2 max-h-[10rem] overflow-y-auto">
                                  {detailOrderProducts?.filter((prod) => prod.sku.includes(searchSKU))
                                  .map((variant, idx) => (
                                    <div key={idx} className="px-2 py-1 hover:bg-gray-200 cursor-pointer whitespace-nowrap" title={variant.sku} onClick={() => {
                                      setSelectedSKU(variant, index)
                                      setShowVariants(false)
                                      setSearchSKU('')
                                    }}>{variant.sku}</div>
                                  ))}
                                </div>
                              </div>
                            }
                            </div>
                          ))}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className='space-y-2'>
                          {selectedSku.map((variant, index) => (
                            <img key={index} src={selectedSku[index].product.image} className='h-8' />
                          ))}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className='space-y-2'>
                          {selectedSku.map((variant, index) => (
                            <div key={index} className='flex items-center gap-x-2'>
                              <input type="number" 
                              value={variant.quantity}
                              className='w-14 h-8 border rounded text-center focus:outline-none' 
                              min={1} 
                              max={~~variant.product?.qty - abnormalProducts.reduce((total, curr) => curr.id_so == variant.product.id_so && curr.id_product == variant.product.produk_id ? total + ~~curr.qty : total, 0)} 
                              onChange={(e) => setSelectedSKUQty(e.target.value, variant.id, index, variant.product?.qty, variant)}
                              />
                              {variant.product?.qty &&
                                <span className='text-sm'>({~~variant.product?.qty - abnormalProducts.reduce((total, curr) => curr.id_so == variant.product.id_so && curr.id_product == variant.product.produk_id ? total + ~~curr.qty : total, 0)})</span>
                              }
                            </div>
                          ))}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="flex items-center flex-wrap text-xs gap-2">
                          {selectedSku?.length < detailOrderProducts?.length  &&
                            <div className="bg-blue-500 text-white font-semibold rounded cursor-pointer px-2 py-1 whitespace-nowrap" onClick={addNewVariant}>Add Variant</div>
                          }
                          {selectedSku.length > 1 &&
                            <div className="bg-red-500 text-white font-semibold rounded cursor-pointer px-2 py-1 whitespace-nowrap" onClick={removeLastVariant}>Remove Variant</div>
                          }
                        </div>
                      </TableCell>
                    </TableRow>
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <div className='w-max px-5 py-2 text-center mx-auto bg-blue-500 hover:bg-blue-600 text-white rounded-lg mt-3 cursor-pointer' onClick={saveAbnormal}>Save</div>
        </Box>
      </Modal>
    </>
  );
};

export default ModalWarningUnScannedCarton;
