// import { getUser } from 'helpers/parseJWT';

import { SubRoutesAfterSales } from "components/AfterSales/SubRoutesAfterSales";
import { SubRoutesCatalog } from "components/Catalog/SubRoutesCatalog";
import { SubRoutesCms } from "components/Cms/SubRoutesCms";
import { SubRoutesLogisticChina } from "components/LogisticChina/SubRoutesLogisticChina";
import { SubRoutesLogisticIndo } from "components/LogisticIndo/SubRoutesLogisticIndo";
import { SubRoutesManagementSales } from "components/ManagementSales/SubRoutesManagementSales";
import { SubRoutesMarketing } from "components/Marketing/SubRoutesMarketing";
import { SubRoutesMyTasks } from "components/MyTasks/SubRoutesMyTasks";
// import { SubRoutesNotification } from "components/Notification/SubRoutesNotification";
import { SubRoutesPurchasing } from "components/Purchasing/SubRoutesPurchasing";
import { SubRoutesQuotation } from "components/Quotation/SubRoutesQuotation";
import { SubRoutesReport } from "components/Report/SubRoutesReport";
import { SubRoutesWarehouse } from "components/Warehouse/SubRoutesWarehouse";
import { allowAll, allowAdmin, allowSupervisor, allowSales, allowSalesSupport } from 'helpers/AccessRoles'
import { useTranslation } from "react-i18next";

export const MainRoutes = (roles) => {
  const { t } = useTranslation();
  return [
    {
      name: `${[...new Set([...allowSales, ...allowSalesSupport])].some((i) => roles?.includes(i)) ? "CRM" : ""}`,
      pathname: "/management-sales",
      subMenu: SubRoutesManagementSales()
    },
    // { name: 'Pre-Sales', pathname: '/pre-sales' },
    {
      name: `${allowSalesSupport.some((i) => roles?.includes(i)) ? t("Quotation") : ""}`,
      pathname: `/quotation`,
      subMenu: SubRoutesQuotation()
    },
    {
      name: `${allowSales.some((i) => roles?.includes(i)) ? t("Purchasing") : ''}`,
      pathname: `/purchasing`,
      subMenu: SubRoutesPurchasing()
    },
    {
      name: `${allowAdmin.some((i) => roles?.includes(i)) ? t("Logistic China") : ""}`,
      pathname: "/logistic-china",
      subMenu: SubRoutesLogisticChina()
    },
    {
      name: `${allowAdmin.some((i) => roles?.includes(i)) ? "Logistic Indo" : ""}`,
      pathname: "/logistic-indo",
      subMenu: SubRoutesLogisticIndo()
    },
    {
      name: `${allowAdmin.some((i) => roles?.includes(i)) ? "After Sales" : ""}`,
      pathname: "/after-sales",
      subMenu: SubRoutesAfterSales()
    },
    // {
    //   name: `${allowAdmin.some((i) => roles?.includes(i)) ? "Warehouse" : ""}`,
    //   pathname: "/warehouse",
    //   subMenu: SubRoutesWarehouse()
    // },
    // {
    //   name: `${allowAdmin.some((i) => roles?.includes(i)) ? "My Tasks" : ""}`,
    //   pathname: "/my-tasks",
    //   subMenu: SubRoutesMyTasks()
    // },
    {
      name: `${allowAdmin.some((i) => roles?.includes(i)) ? "Catalog" : ""}`,
      pathname: "/catalog",
      subMenu: SubRoutesCatalog()
    },
    // {
    //   name: `${allowAdmin.some((i) => roles?.includes(i)) ? "Tracking" : ""}`,
    //   pathname: "/tracking",
    // },
    // { name: "UangMe", pathname: "/uangme-dashboard" },
    {
      name: `${allowAdmin.some((i) => roles?.includes(i)) ? "Blog" : ""}`,
      pathname: "/Blog",
    },
    {
      name: `${allowAdmin.some((i) => roles?.includes(i)) ? "Report" : ""}`,
      pathname: "/report",
      subMenu: SubRoutesReport()
    },
    {
      name: `${allowAdmin.some((i) => roles?.includes(i)) ? "CMS" : ""}`,
      pathname: "/cms",
      subMenu: SubRoutesCms()
    },
    {
      name: `${allowAdmin.some((i) => roles?.includes(i)) ? "Marketing" : ""}`,
      pathname: "/marketing",
      subMenu: SubRoutesMarketing()
    },
    // {
    //   name: `${allowAdmin.some((i) => roles?.includes(i)) ? "Notification" : ""}`,
    //   pathname: "/notification",
    //   subMenu: SubRoutesNotification()
    // },
  ];
};
