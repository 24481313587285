import MaterialTable from 'material-table';
import {
  IconButton,
  Tooltip,
  Chip,
  CircularProgress,
  Box,
  Typography,
  Fade,
  Modal,
  Backdrop,} from '@mui/material';
import WhatsAppDetail from './WhatsAppDetail';
import AddSalesModal from './AddSalesModal';
import NotesModal from './NotesModal';
import ReleaseSales from './ReleaseSales';
import EditEmail from './EditEmail';
import FlagIcon from '@mui/icons-material/Flag';
import SetFlag from './SetFlag';
import tableIcons from 'helpers/materialTableIcons';
import CloseIcon from "@mui/icons-material/Close";
import { formatDateLong } from 'helpers/ConvertTime';
import { getUser } from 'helpers/parseJWT';
import { MuiThemeProvider } from '@material-ui/core';
import { useState } from 'react';
import { createTheme } from '@material-ui/core/styles'
import swal from 'sweetalert';
import { add_multi_sales_data } from 'service/api';
import { censorPhoneNumber } from 'helpers/cencorPhoneNumber';
import { allowAll, allowAdmin, allowSupervisor, allowSales, allowSalesSupport } from 'helpers/AccessRoles'
import { errorSelector } from 'recoil';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid lightgray",
  boxShadow: 24,
  borderRadius: "5px",
  p: 4,
};

const CustomerTable = ({
  customerData,
  isLoading,
  setChangeData,
  setLoading,
  selectedRowsNumber,
  setSelectedRowsNumber,
}) => {
  const [sales, setSales] = useState("");
  const [selectedRow, setSelectedRow] = useState([]);
  const [showModalAddSalesMulti, setShowModalAddSalesMulti] = useState(false)
  const listSales = customerData?.listSales;
  const renderLoading = isLoading ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'start',
        padding: '10px',
        borderRadius: 2,
        backgroundColor: 'white',
        marginBottom: 1,
      }}>
      <div className='flex space-x-3 items-center w-full bg-blue-100 p-4 rounded-md'>
        <CircularProgress size={20} />
        <p className='text-gray-500 text-sm '>Updating data ...</p>
      </div>
    </Box>
  ) : null;
  
  const selectRowsData = (evt, data) => {
    setSelectedRow(data)
    setShowModalAddSalesMulti(true)
  }

  const theme = createTheme({
    palette: {
      primary: {
        main: '#4caf50',
      },
      secondary: {
        main: '#4caf50',
      },
    },

  });

  const handleChange = (event) => {
    setSales(event.target.value);
  };
  
  const addSalesMultiple = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "Once add sales, you will not be able to revert this change!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        postSalesDataMultiple();
      } else {
        swal("Action canceled");
      }
    });
  };
  
  const postSalesDataMultiple = async () => {
    setLoading(true);
    const payload = selectedRow.filter((item) => item.email !== null).map((row) => {
      return {
        email: row.email,
        sales: sales
      }
    })

    const body = JSON.stringify(payload);
    const data = await add_multi_sales_data(body);

    if (data?.status === 200) {
      swal("Sales added successfully", {
        icon: "success",
      });
      setShowModalAddSalesMulti(false)
      setChangeData((prev) => !prev);
    } else {
      setLoading(false);
    }
  };

  const onSelectionChange = (selectedRows) => {
    const selectedRowsFiltered = selectedRows.filter((rowData) => rowData.email !== null)
    setSelectedRowsNumber(selectedRowsFiltered.length)
  }

  const showAlertEmailNotFound = (e) => {
    e.preventDefault()
    swal('Maaf, email belum tersedia!')
  }

  return (
    <div>
      {renderLoading}
      <MuiThemeProvider theme={theme}>
        <MaterialTable
          localization={{
            toolbar: {
              searchPlaceholder: 'Search Table',
            },
          }}
          isLoading={isLoading}
          icons={tableIcons}
          title={selectedRowsNumber > 0 ? 
            <div className='text-green-500 text-xl'>{selectedRowsNumber} row{selectedRowsNumber > 1 && 's'} selected</div>
            : 'Customer Table'}
          columns={[
            {
              title: 'Flag',
              field: 'flag',
              render: (rowData) => (
                <FlagIcon
                  fontSize='medium'
                  className={`${
                    rowData.level === 1
                      ? 'text-red-500'
                      : rowData.level === 2
                      ? 'text-blue-500'
                      : rowData.level === 3
                      ? 'text-green-500'
                      : 'text-gray-400'
                  }`}
                />
              ),
            },
            {
              title: 'Email',
              field: 'email',
              render: (rowData) => (
                <div className='flex'>
                  {rowData.status === 'new' ? (
                    rowData.email ? (
                      <div className='space-y-2'>
                        <p className='text-sm'>{rowData.email}</p>
                        <EditEmail
                          title='Edit'
                          setChangeData={setChangeData}
                          setLoading={setLoading}
                          id={rowData.id}
                        />
                      </div>
                    ) : (
                      <EditEmail
                        title='Add'
                        setChangeData={setChangeData}
                        setLoading={setLoading}
                        id={rowData.id}
                      />
                    )
                  ) : (
                    <p className='text-sm'>{rowData.email}</p>
                  )}
                </div>
              ),
            },
            {
              title: 'Name',
              field: 'name',
              cellStyle: {
                cellWidth: '100%',
              },
              render: (rowData) => <p className='text-sm'>{rowData.name}</p>,
            },
            {
              title: 'Phone',
              field: 'phone',
              render: (rowData) => <p className='text-sm'>{getUser().isBlur ? censorPhoneNumber(rowData.phone) : rowData.phone}</p>,
            },
            {
              title: 'Sales',
              field: 'sales',
              render: (rowData) => (
                <p className='text-sm'>
                  {rowData?.sales !== null
                    ? rowData?.sales?.slice(0, 1).toUpperCase() +
                      rowData?.sales?.slice(1)
                    : 'No Sales'}
                </p>
              ),
            },
            {
              title: 'Date',
              field: 'date',
              render: (rowData) => (
                <p className='min-w-[150px] w-full text-sm'>
                  {rowData.date}
                </p>
              ),
            },
            {
              title: 'Last Contact',
              field: 'lastContact',
              render: (rowData) =>
                rowData.lastContact !== null ? (
                  <p className='min-w-[150px] w-full text-sm'>
                    {formatDateLong(rowData.lastContact)}
                  </p>
                ) : (
                  <p className='min-w-[150px] w-full text-sm'>No Activity</p>
                ),
            },
            {
              title: 'Actions',
              field: 'actions',
              align: 'center',
              render: (rowData) => (
                <>
                  <div className='flex'>
                    {(['obe'].some((i) => getUser().division?.includes(i)) || 
                      (allowAdmin.some((i) => getUser().roles?.includes(i)) && !['obe', 'ae'].some((i) => getUser().division?.includes(i)))) &&
                      <AddSalesModal
                        listData={listSales}
                        email={[rowData.email]}
                        setChangeData={setChangeData}
                        setLoading={setLoading}
                        id={rowData.id}
                      />
                    }
                    {allowSupervisor.some((i) => getUser().roles?.includes(i)) &&
                      <ReleaseSales
                        email={rowData.email}
                        sales={rowData.sales}
                        setChangeData={setChangeData}
                        setLoading={setLoading}
                        id={rowData.id}
                      />
                    }
                    <NotesModal
                      email={rowData.email}
                      customer={rowData.name}
                      phone={rowData.rawPhone}
                      setChangeData={setChangeData}
                      notesData={rowData.note}
                    />
                    <SetFlag
                      phoneNumber={rowData.phone}
                      email={rowData.email}
                      setChangeData={setChangeData}
                      setLoading={setLoading}
                      id={rowData.id}
                    />
                    <WhatsAppDetail
                      phoneNumber={rowData.phone}
                      email={rowData.email}
                      setChangeData={setChangeData}
                      setLoading={setLoading}
                      id={rowData.id}
                    />
                  </div>
                  {/* <a href={`https://desktop-tshoot.ocistok.com/set-xemail?email=${rowData.email}`} 
                    className="block px-2 py-1 rounded-md text-sm text-white bg-blue-500 hover:bg-blue-600 cursor-pointer"
                    onClick={(e) => !rowData?.email ? showAlertEmailNotFound(e) : null}
                  >
                    Order Manual
                  </a> */}
                </>
              ),
            },
          ]}
          data={customerData.data}
          options={{
            // exportButton: true,
            pageSizeOptions: [],
            pageSize: 50,
            searchFieldPlaceholder: 'Search Table',
            tableLayout: 'auto',
            rowStyle: {
              overflowWrap: 'break-word',
            },
            selection: ['admin', 'supervisor'].some((i) => getUser()?.roles?.includes(i)),
            showTextRowsSelected: false,
            selectionProps: (rowData) => {
              const checked = rowData.email === null ? {checked: false} : {};
              return {
              disabled: rowData.email === null,
               ...checked
              }
           }
          }}
          onSelectionChange={onSelectionChange}
          actions={['admin', 'supervisor'].some((i) => getUser()?.roles?.includes(i)) && [
            {
              tooltip: 'Add Sales',
              icon: tableIcons.AddCircle,
              onClick: (evt, data) => selectRowsData(evt, data)
            }
          ]}
        />
      </MuiThemeProvider>

      {/* MODAL ADD SALES TO MULTIPLE USER */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showModalAddSalesMulti}
        onClose={() => setShowModalAddSalesMulti(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showModalAddSalesMulti}>
          <Box sx={style}>
            <div className="flex justify-end -mt-5">
              <IconButton onClick={() => setShowModalAddSalesMulti(false)} style={{ textAlign: "right" }}>
                <CloseIcon />
              </IconButton>
            </div>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Add Sales
            </Typography>
            <form
              onSubmit={(e) => ['admin', 'supervisor'].some((i) => getUser()?.roles?.includes(i)) && addSalesMultiple(e)}
              className="flex flex-col items-center space-y-2"
            >
              <select
                value={sales}
                onChange={handleChange}
                className="p-2 border border-gray-300 outline-none rounded-md w-full"
              >
                <option value="" disabled>
                  Select Sales
                </option>
                {listSales?.map((sales, id) => (
                  <option value={sales} key={id}>
                    {sales.slice(0, 1).toUpperCase() + sales.slice(1)}
                  </option>
                ))}
              </select>
              <button
                className="p-2 bg-blue-300 text-white rounded-md  w-full hover:bg-blue-200 transition-alll"
                type="submit"
              >
                Submit
              </button>
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default CustomerTable;
