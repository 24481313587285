import { getUser } from 'helpers/parseJWT';
import { allowAll, allowAdmin, allowSupervisor, allowSales, allowSalesSupport } from 'helpers/AccessRoles'
import { useTranslation } from 'react-i18next';

export const SubRoutesPurchasing = () => {
    const { t } = useTranslation()
    return[
    { 
        name: `${allowSales.some((i) => getUser().roles?.includes(i)) ? t('New Orders') : ''}`,
        pathname: '/purchasing/new-order' 
    },
    { 
        name: `${allowSales.some((i) => getUser().roles?.includes(i)) ? t('Already PO') : ''}`,
        pathname: '/purchasing/already-po' 
    },
    { 
        name: `${allowSales.some((i) => getUser().roles?.includes(i)) ? t('PO Paid') : ''}`, 
        pathname: '/purchasing/po-paid' 
    },
    {
        name: `${allowAdmin.some((i) => getUser().roles?.includes(i)) ? 'Abnormal' : ''}`,
        pathname: '/purchasing/abnormal',
    }
    ,
    {
        name: `${allowAdmin.some((i) => getUser().roles?.includes(i)) ? 'Approval' : ''}`,
        pathname: '/purchasing/approval',
    },
]};