import React from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Paper,
  IconButton,
  Collapse,
  Box,
} from '@mui/material';
// import WarningAmberIcon from '@mui/icons-material/WarningAmber';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// import { height } from '@mui/system';
// import { InputData } from './InputData';
// import Lightbox from 'react-image-lightbox';
  
export default function UnreceivedItems({
  totalUnreceivedItemQty,
  totalReceived,
  totalReceivedInit,
  detailOrderProducts,
  selectedSku,
  setSelectedSKU,
  showVariants,
  setShowVariants,
  searchSKU,
  setSearchSKU,
  isEditing,
  setSelectedSKUQty,
  setSelectedSKUReason,
  addNewVariant,
  removeLastVariant,
}) {
  return (
    <div className="unreceived-items mt-3">
          <div className="font-bold underline">Abnormal</div>
          <TableContainer
          component={Paper}
          sx={{ minHeight: 300 }}
          className="variant-scroll">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className='w-1/4'>Product Name</TableCell>
                  <TableCell className='w-1/4'>Variant</TableCell>
                  <TableCell className='w-16'>Image</TableCell>
                  <TableCell className='w-16'>Quantity</TableCell>
                  <TableCell className='w-16' align="center">Reason</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={3} style={{fontWeight: 'bold'}}>Total</TableCell>
                  <TableCell style={{fontWeight: 'bold'}}>
                    <div className={`w-14 rounded text-center ${totalUnreceivedItemQty !== totalReceivedInit - totalReceived ? 'text-red-600' : ''}`}>
                      {totalUnreceivedItemQty} / {totalReceivedInit - totalReceived}
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{detailOrderProducts?.[0]?.product}</TableCell>
                  <TableCell>
                    <div className='relative space-y-2'>
                      {selectedSku.map((variant, index) => (
                        <div key={index}>
                        <div className='w-full border rounded focus:outline-none h-8 px-2 py-1 whitespace-nowrap max-w-[15rem] overflow-hidden' title={variant.product.sku} onClick={() => setShowVariants(index.toString())}>
                          {variant.product.sku}
                        </div>
                        {index === selectedSku.length - 1 && showVariants === index.toString() &&
                          <div className="absolute bottom-0 translate-y-full max-w-[12rem] overflow-x-hidden">
                            <input type="text" className='w-full border rounded focus:outline-none px-2 py-1'
                              placeholder='search sku'
                              value={searchSKU}
                              onChange={(e) => setSearchSKU(e.target.value)}
                            />
                            {searchSKU !== '' &&
                              <div className="absolute right-2 top-[5px] text-gray-400 hover:text-gray-500 font-semibold" onClick={() => setSearchSKU('')}>x</div>
                            }
                            <div className="bg-gray-100 rounded shadow py-2 max-h-[10rem] overflow-y-auto">
                              {detailOrderProducts?.filter((prod) => prod.sku.includes(searchSKU))
                              .map((variant, idx) => (
                                <div key={idx} className="px-2 py-1 hover:bg-gray-200 cursor-pointer whitespace-nowrap" title={variant.sku} onClick={() => {
                                  setSelectedSKU(variant, index)
                                  setShowVariants(false)
                                  setSearchSKU('')
                                }}>{variant.sku}</div>
                              ))}
                            </div>
                          </div>
                        }
                        </div>
                      ))}
                      
                    </div>
                    
                  </TableCell>
                  <TableCell>
                    <div className='space-y-2'>
                      {selectedSku.map((variant, index) => (
                        <img key={index} src={selectedSku[index].product.image} className='h-8' />
                      ))}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className='space-y-2'>
                      {selectedSku.map((variant, index) => (
                        <div key={index} className='flex items-center gap-x-2'>
                        <input type="number" 
                        value={selectedSku[index].quantity}
                        className='w-14 h-8 border rounded text-center focus:outline-none' 
                        min={1} 
                        max={!isEditing ? variant.product?.qty_whchina : 9999999} 
                        onChange={(e) => isEditing ? setSelectedSKUQty(e.target.value, variant.id, index) : setSelectedSKUQty(e.target.value, variant.id, index, variant.product?.qty_whchina)}
                        />
                        {/* <span className='text-sm'>({variant.product?.qtyInput + variant.product?.qty_whchina})</span> */}
                        </div>
                      ))}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className='space-y-2'>
                      {selectedSku.map((variant, index) => (
                        <select key={index} 
                        className='h-8 border rounded focus:outline-none'
                        value={selectedSku[index].reason}
                        onChange={(e) => setSelectedSKUReason(variant, index, e.target.value)}
                        >
                          <option value="">Select Reason</option>
                          <option value="Damaged">Damaged</option>
                          <option value="Unreceived">Unreceived</option>
                          <option value="Wrong variant">Wrong variant</option>
                        </select>
                      ))}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="flex items-center flex-wrap gap-2">
                      {totalUnreceivedItemQty < (totalReceivedInit - totalReceived) &&
                        <div className="bg-blue-500 text-white font-semibold rounded cursor-pointer px-2 py-1 whitespace-nowrap" onClick={addNewVariant}>Add Variant</div>
                      }
                      {selectedSku.length > 1 &&
                        <div className="bg-red-500 text-white font-semibold rounded cursor-pointer px-2 py-1 whitespace-nowrap" onClick={removeLastVariant}>Remove Variant</div>
                      }
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
  );
}
    