import { useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  IconButton,
  Tooltip,
  Typography,
  Fade,
  Modal,
  Backdrop,
  Box,
} from "@mui/material";
import swal from "sweetalert";
import CloseIcon from "@mui/icons-material/Close";
import { get_marking_code_list, add_marking_code } from "service/api";
import { getUser } from "helpers/parseJWT";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid lightgray",
  boxShadow: 24,
  borderRadius: "5px",
  p: 4,
};

export default function AddMarkingCodeModal({ rowData, setUpdate }) {
  const [forwarderList, setForwarderList] = useState([]);
  const [selectedForwarder, setSelectedForwarder] = useState('')
  const [markingCodes, setMarkingCodes] = useState([])
  const [selectedMarkingCode, setSelectedMarkingCode] = useState('')
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const { t } = useTranslation();

  const handleClose = () => setOpen(false);
  const handleOpen = () => {
    getMarkingCodeList()
    setOpen(true)
  };
  const handleSelectForwarder = (event) => {
    setSelectedForwarder(event.target.value);

    const selectedMarkingCodesList = forwarderList.filter((item) => item.forwarder === event.target.value)[0].detail
    setMarkingCodes(selectedMarkingCodesList)
    setSelectedMarkingCode('')
  };
  
  const getMarkingCodeList = async () => {
    setIsLoading(true);
    const data = await get_marking_code_list(rowData?.idOrder?.id_so);
    if (data?.status === 200) {
      setForwarderList(data.data?.marking_code)
    } 
    setIsLoading(false)
  };

  const SubmitMarkingCode = (e) => {
    e.preventDefault();
    swal({
      title: "Add Marking Code",
      text: `You are adding this id order of ${rowData?.idOrder?.id_so} to \n marking code: ${selectedMarkingCode}`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        postMarkingCode();
        handleClose();
      } else {
        swal("Action canceled");
        handleClose();
      }
    });
  };

  const postMarkingCode = async () => {
    setIsLoading(true);
    const payload = {
      marking_code: selectedMarkingCode,
      id_order: rowData?.idOrder?.id_so,
    }
    
    const response = await add_marking_code(JSON.stringify(payload))
    
    if (response.status === 200) {
      swal('Post marking code successfully')
      setUpdate(prev => !prev)
      handleClose()
    } else {
      swal("Failed", response.message, 'error')
    }
    setIsLoading(false)
  }
  
  return (
    <>
      <div className={`${['admin'].some((i) => getUser()?.roles?.includes(i)) && !rowData.idOrder.is_left_over ? "text-blue-500 border-blue-500 cursor-pointer" : "text-gray-300 border-gray-300"} py-2 px-3 border  text-sm  rounded-md text-center space-x-2 flex items-center justify-center`}
      onClick={() => (['admin'].some((i) => getUser()?.roles?.includes(i)) && !rowData.idOrder.is_left_over) && handleOpen()}>
        {t("Add Marking Code")}
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="flex justify-end -mt-5">
              <IconButton onClick={handleClose} style={{ textAlign: "right" }}>
                <CloseIcon />
              </IconButton>
            </div>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              {t("Add Marking Code")}
            </Typography>
            <form
              onSubmit={(e) => ['admin'].some((i) => getUser()?.roles?.includes(i)) && SubmitMarkingCode(e)}
              className="flex flex-col items-center space-y-2 mt-3"
            >
              <select
                id="forwarder"
                value={selectedForwarder}
                onChange={handleSelectForwarder}
                className="p-2 border border-gray-300 outline-none rounded-md w-full"
              >
                <option value="" disabled>Select Forwarder</option>
                {forwarderList?.map((obj, id) => (
                  <option value={obj.forwarder} key={obj.forwarder}>
                    {obj.forwarder}
                  </option>
                ))}
              </select>
              <select
                id="markingcodes"
                value={selectedMarkingCode}
                onChange={(e) => setSelectedMarkingCode(e.target.value)}
                className="p-2 border border-gray-300 outline-none rounded-md w-full"
                disabled={selectedForwarder === ''}
              >
                <option value="" disabled>Select Marking Code</option>
                {markingCodes?.map((code, id) => (
                  <option value={code.marking_code} key={code.marking_code}>
                    {code.zone}-{code.marking_code}
                  </option>
                ))}
              </select>
              <button
                className={`${!selectedForwarder || !selectedMarkingCode ? " bg-gray-300" : " bg-blue-500 hover:bg-blue-600"} p-2 text-white rounded-md  w-full transition-alll`}
                type="submit"
                disabled={!selectedForwarder || !selectedMarkingCode}
              >
                Submit
              </button>
            </form>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
