import { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Button, CircularProgress, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { sendImageOCS } from "service/api";
import swal from "sweetalert";
import Cookies from "js-cookie";

const ModalOCSImage = (props) => {
  const {
    open,
    setOpen,
    isLoading,
    setGlobalMessages
  } = props;
  const [image, setImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [telpon, setTelpon] = useState("");
  const [errorMessage, setErrorMessage] = useState({
    msg: "Ukuran gambar terlalu besar (maksimal 2MB).",
    error: true,
  });
  const fileInputRef = useRef(null);

  const sendMessageImage = async (e) => {
    e.preventDefault();
    const getTelpon = localStorage.getItem("no-telpon");
    const telpon = JSON.parse(getTelpon);
    var myHeaders = new Headers();
    const Xid = Cookies.get('Session_id');
    const tokenCookies = Cookies.get('oms_token');
    myHeaders.append("Authorization",`Bearer ${tokenCookies}`);
    myHeaders.delete("Content-Type", 'multipart/form-data');
    const randomId = (Math.floor(Math.random() * (999999 - 100000 + 1) + 100000)).toString()
    setGlobalMessages((prev) => [{
      id: randomId,
      phone: telpon,
      from: "server",
      to: telpon,
      user: "",
      body: "",
      not_sent: true,
      is_read: false,
      date: new Date().toISOString(),
      media: {
          id: "",
          id_wa: "",
          file_name: selectedImage,
          mime: "." + image.name.split('.').pop()
      }
    }, ...prev]);
   
    var formdata = new FormData();
    formdata.append("phone", telpon);
    formdata.append("file", image);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    setLoading(true);
    fetch(
      `${process.env.REACT_APP_URL_API_WA_CHAT}/send-message-image`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 200) {
          swal("Success Upload Files", "Success");
        }
        setOpen(!open);
        setSelectedImage("");
        setErrorMessage({
          error: true,
        });
      })
      .catch((error) => console.log("error", error)).finally(() => {
        setLoading(false);
      });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    maxHeight: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const closeModal = () => {
    setOpen(!open);
    setSelectedImage("");
    setImage("");
    setErrorMessage({
      error: true,
    });
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const validasiImage = () => {
    setSelectedImage("");
    setImage("");
    swal(errorMessage.msg);
  };

  useEffect(() => {}, [errorMessage]);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    if (file && file.size > 5 * 1024 * 1024) {
      swal("Ukuran gambar terlalu besar (maksimal 5MB).");
      setSelectedImage("");
      setImage("");
      setErrorMessage({
        msg: "Ukuran gambar terlalu besar (maksimal 5mb).",
        error: true,
      });
    } else {
      setErrorMessage({
        error: false,
      });
      setImage(file);
    }

    reader.onload = () => {
      setSelectedImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };
  return (
    <div>
      {isLoading}
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description">
        <Box sx={{ ...style }}>
          <div className="w-full flex justify-end">
            <IconButton
              aria-label="close"
              onClick={closeModal}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}>
              <CloseIcon />
            </IconButton>
          </div>

          <form onSubmit={(e) => sendMessageImage(e)}>
            <div className="w-full flex justify-center">
              <div className="w-[250px] max-h-[280px] flex flex-col items-center ">
                <div>
                  <h3 className="text-lg text-center font-bold">
                    Uploaded Image:
                  </h3>
                </div>
                {/* <button onClick={handleImageDelete}>delete</button> */}
                {loading ? (
                  <div className="w-full h-full my-10 flex items-center justify-center text-white ">
                    <CircularProgress size={30} className="text-blue-300" />
                  </div>
                ) : (
                  <>
                    {selectedImage && (
                      <>
                        <div className="mt-4 flex h-full flex-col justify-center w-full">
                          <img
                            src={selectedImage}
                            alt="Uploaded"
                            className="mt-2 max-w-full max-h-full mb-10"
                          />
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>

            <div className="flex flex-col items-center my-10">
              <label htmlFor="image_input" className="text-lg text-white bg-gray-400 px-10 py-1 rounded-full cursor-pointer">Select Image</label>
              <input
                className="hidden text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                type="file"
                id="image_input"
                ref={fileInputRef}
                accept=".png, .jpg, .jpeg, .jfif, .bmp, .gif"
                onChange={handleImageUpload}
              />
              <p
                className="mt-1 text-xs text-gray-500 dark:text-gray-300"
                id="file_input_help">
                <b> SVG, PNG, JPG or GIF (Max.5MB)</b>
              </p>
            </div>
            <div className="w-full justify-center flex mt-5">
            {errorMessage.error === true || loading ? (
                  <button
                    className="bg-gray-300 cursor-default text-white py-3 px-7 rounded-lg"
                    disabled>
                    Send
                  </button>
                ) : (
                  <button className="bg-green-500 hover:bg-green-400 text-white py-3 px-7 rounded-lg">
                    Send
                  </button>
                )}
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalOCSImage;
