import { formatDateNumberDDMMYYYY } from "helpers/ConvertTime";
import React, { useEffect, useMemo, useState } from "react";
import Sanitized from "react-sanitized";
import { getReadUnread } from "service/api";
import swal from "sweetalert";
import { useSelector } from "react-redux";
// MATERIAL ICON
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import VideocamIcon from "@mui/icons-material/Videocam";
import ArticleIcon from "@mui/icons-material/Article";
import { useRecoilState } from "recoil";
import { triggerState } from "atom";

const MenuRead = (props) => {
  const statusTelpon = useSelector((state) => state.dataTelp)
  const { setActiv, activ, handleNotelpon, search, content, date, notif, setIsLoadingWa, setNotif, isLoadingWa } = props;
  const [read, setRead] = useState(null)
  const [trigger, setTrigger] = useRecoilState(triggerState);

  const memoizedParams = useMemo(() => {
    const contens = content !== "" ? `is_read=${content}&` : "";
    const salesParams =
      date?.sales !== "" ? `is_read=${content}&user=${date?.sales}&` : "";
    return salesParams + contens;
  }, [content, date.sales]);

  const fetchReadUnread = async () => {
    setIsLoadingWa(true);
    const response = await getReadUnread(memoizedParams);
    if (response?.status === 200) {
      setRead(response?.data);
      setNotif(response);
    } else if (response?.status === 403) {
      swal("Oops", `${response?.message}`, "error");
    }
    setIsLoadingWa(false);
  };

  useEffect(() => {
    fetchReadUnread();
  }, [content, date, notif?.status, trigger]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (isLoadingWa === false) {
  //       fetchReadUnread();
  //     }
  //   }, 3000);
  //   // Clear the interval
  //   return () => clearInterval(interval);
  // });
  return (
    <>
      <>
        <div className="h-[60vh] w-full overflow-y-auto">
          {read &&
            read
              .filter(
                (contact) =>
                  contact.phone.includes(search) ||
                  contact.name.toLowerCase().includes(search.toLowerCase())
              )
              .map((contact, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => setActiv(contact.phone)}
                    className={`flex items-center w-full p-2 border-b cursor-pointer ${
                      activ === contact.phone
                        ? "bg-blue-50"
                        : "hover:bg-blue-50"
                    }`}>
                    <div
                      key={index}
                      onClick={() => handleNotelpon(contact.phone)}
                      className="flex items-center w-full h-full cursor-pointer hover:bg-blue-50">
                      <div className="relative w-12 h-12 flex-shrink-0">
                        <img
                          className="w-12 h-12 rounded-full"
                          src={`${process.env.REACT_APP_URL_FOTO_STORAGE}/9b04ed370f56c14fe3dd9011ddaa062d.png`}
                          alt=""
                        />
                      </div>
                      <div className="w-full m-1">
                        <div className="flex justify-between w-full">
                          <div className="w-3/4 line-clamp-1 mr-2">
                            <span className="font-semibold line clamp">
                              {contact.name}
                            </span>
                          </div>
                          <div className="w-1/2 flex items-center justify-end">
                            <span className="text-sm">
                              {formatDateNumberDDMMYYYY(contact.date)}{" "}
                            </span>
                          </div>
                        </div>
                        <div className="flex mb-1 justify-between">
                          {[
                            ".png",
                            ".jpg",
                            ".jpeg",
                            ".jfif",
                            ".bmp",
                            ".gif",
                          ].includes(contact?.message?.media?.mime) ? (
                            <div className="flex">
                              <InsertPhotoIcon />
                              <div className="text-sm flex justify-center items-center">Picture</div>
                            </div>
                          ) : (
                            <div className="hidden"></div>
                          )}


                          {[".pdf"].includes(contact?.message?.media?.mime) ? (
                            <div className="flex">
                              <PictureAsPdfIcon />
                              <div className="text-sm flex justify-center items-center ml-1">
                                .pdf
                              </div>
                            </div>
                          ) : (
                            <div className="hidden"></div>
                          )}

                          {[".doc", ".docx"].includes(
                            contact?.message?.media?.mime
                          ) ? (
                            <div className="flex">
                              <ArticleIcon />
                              <div className="text-sm flex justify-center items-center ml-1">
                                word
                              </div>
                            </div>
                          ) : (
                            <div className="hidden"></div>
                          )}

                          {[".ppt", ".pptx"].includes(
                            contact?.message?.media?.mime
                          ) ? (
                            <div className="flex">
                              <ArticleIcon />
                              <div className="text-sm flex justify-center items-center ml-1">
                                ppt
                              </div>
                            </div>
                          ) : (
                            <div className="hidden"></div>
                          )}

                          {[".xlsx", ".xls",".csv"].includes(
                            contact?.message?.media?.mime
                          ) ? (
                            <div className="flex">
                              <ArticleIcon />
                              <div className="text-sm flex justify-center items-center ml-1">
                                xlsx
                              </div>
                            </div>
                          ) : (
                            <div className="hidden"></div>
                          )}

                          {[".m4v", ".mp4", ".avi"].includes(
                            contact?.message?.media?.mime
                          ) ? (
                            <div className="flex">
                              <VideocamIcon />
                              <div className="text-sm flex justify-center items-center ml-1">
                                video
                              </div>
                            </div>
                          ) : (
                            <div className="hidden"></div>
                          )}

                          {/* {[".doc", ".docx", ".xlsx", ".xls", ".csv", ".pdf", ".ppt", ".pptx", ].includes(
                            contact?.message?.media?.mime
                          ) ? (
                            <div className="flex">
                              <ArticleIcon />
                              <div className="text-sm flex justify-center items-center">Document</div>
                            </div>
                          ) : (
                            <div className="hidden"></div>
                          )} */}

                          <div>
                            <div className="line-clamp-1  text-xs break-all">
                              {/* <Sanitized
                                style={{ whiteSpace: "pre-wrap" }}
                                html={contact.message.body}
                                allowedTags={["a"]}
                              /> */}
                              {contact?.message?.media?.mime === "" &&
                              contact?.body !== "" &&
                              contact?.body !== null ? (
                                <Sanitized
                                  html={contact.message.body}
                                  style={{ whiteSpace: "pre-wrap" }}
                                  className="break-all"
                                  options={{
                                    allowedAttributes: {
                                      a: ["href", "style"],
                                    },
                                  }}
                                />
                              ) : (
                                <div className="hidden"></div>
                              )}
                            </div>
                          </div>

                          {contact.is_read === false ? (
                            <div className="justify-end flex">
                              <div className="ml-1 flex flex-shrink-0 w-5 h-5 text-xs text-white bg-red-500 rounded-full justify-center items-center"></div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div>
                          {/* <div className="flex justify-center gap-1 bg-blue-400 text-white text-base rounded-xl w-[80px] break-all">
                              <div className="flex justify-center items-center">
                                <div className="bg-green-400 rounded-full w-2 h-2"></div>
                              </div>
                              <div>Online</div>
                            </div> */}
                          {/* <div className="flex justify-center gap-1 bg-gray-300 text-gray-500 text-base rounded-xl w-[80px] break-all">
                              <div className="flex justify-center items-center">
                                <div className="bg-red-500 rounded-full w-2 h-2"></div>
                              </div>
                              <div>Offline</div>
                            </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
        </div>
      </>
    </>
  );
};

export default MenuRead;
