import React, { forwardRef, useEffect, useState } from "react";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import {
  IconButton,
  Tooltip,
  Chip,
  CircularProgress,
  Typography,
  Fade,
  Modal,
  Backdrop,
} from "@mui/material";
import swal from 'sweetalert';
import AddSalesModal from './AddSalesModal';
import GppBadIcon from "@mui/icons-material/GppBad";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";
import MaterialTable from "material-table";
import tableIcons from "helpers/materialTableIcons";
import NotesModal from "./NotesModal";
import ViewModal from "./ViewModal";
import WhatsAppDetail from "./WhatsAppDetail";
import { FormatDate } from "helpers/ConvertTime";
import EditCustomer from "./EditCustomer";
import { MuiThemeProvider } from "@material-ui/core";
import { createTheme } from '@material-ui/core/styles'
import { add_multi_sales_data } from "service/api";
import { getUser } from "helpers/parseJWT";
import { censorPhoneNumber } from "helpers/cencorPhoneNumber";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  border: "1px solid lightgray",
  boxShadow: 24,
  borderRadius: "5px",
  p: 4,
};

const NewCustomerTable = ({
  dataNewCustomer,
  listSalesData,
  setChangeData,
  isLoading,
  isLimit,
  selectedRowsNumber,
  setSelectedRowsNumber,
}) => {
  const [sales, setSales] = useState("");
  const [selectedRow, setSelectedRow] = useState([]);
  const [showModalAddSalesMulti, setShowModalAddSalesMulti] = useState(false)
  const [loading, setLoading] = useState(false);
  const dataFromSearch = dataNewCustomer?.length === 1;

  useEffect(() => {
    if (!isLoading) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [isLoading]);

  const renderLoading = loading ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "start",
        padding: "10px",
        borderRadius: 2,
        backgroundColor: "white",
        marginBottom: 1,
      }}>
      <div className="flex space-x-3 items-center w-full bg-blue-100 p-4 rounded-md">
        <CircularProgress size={20} />
        <p className="text-gray-500 text-sm ">Updating data ...</p>
      </div>
    </Box>
  ) : null;
  
  const selectRowsData = (evt, data) => {
    setSelectedRow(data)
    setShowModalAddSalesMulti(true)
  }

  const theme = createTheme({
    palette: {
      primary: {
        main: '#4caf50',
      },
      secondary: {
        main: '#4caf50',
      },
    },

  });

  const handleChange = (event) => {
    setSales(event.target.value);
  };

  const closeModalAddSalesMulti = () => {
    setSales('')
    setShowModalAddSalesMulti(false)
  }
  
  const addSalesMultiple = (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "Once add sales, you will not be able to revert this change!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        postSalesDataMultiple();
      } else {
        swal("Action canceled");
      }
    });
  };
  
  const postSalesDataMultiple = async () => {
    setLoading(true);
    const payload = selectedRow.map((row) => {
      return {
        email: row.email,
        sales: sales
      }
    })
    
    const body = JSON.stringify(payload);
    const data = await add_multi_sales_data(body);

    if (data?.status === 200) {
      swal("Sales added successfully", {
        icon: "success",
      });
      setShowModalAddSalesMulti(false)
      setSales('')
      setChangeData((prev) => !prev);
    } else {
      setLoading(false);
    }
  };

  const onSelectionChange = (selectedRows) => {
    const selectedRowsFiltered = selectedRows.filter((rowData) => rowData.email !== null)
    setSelectedRowsNumber(selectedRowsFiltered.length)
  }


  return (
    <>
      {renderLoading}
      <MuiThemeProvider theme={theme}>
        <MaterialTable
          localization={{
            toolbar: {
              searchPlaceholder: "Search Table",
            },
          }}
          isLoading={loading}
          icons={tableIcons}
          title={selectedRowsNumber > 0 ? 
            <div className='text-green-500 text-xl'>{selectedRowsNumber} row{selectedRowsNumber > 1 && 's'} selected</div>
            : 'New Customer Table'}
          columns={[
            {
              title: "Nama",
              field: "nama_lengkap",
              render: (rowData) => (
                <div>
                  <div className="text-xs">{rowData.nama_lengkap}</div>
                </div>
              ),
            },
            {
              title: "Email",
              field: "email",
              render: (rowData) => (
                <div>
                  <div className="text-xs">{rowData.email}</div>
                </div>
              ),
            },
            {
              title: "Telepon",
              field: "telepon",
              render: (rowData) => (
                <div>
                  <div className="text-xs">{getUser().isBlur ? censorPhoneNumber(rowData.telepon) : rowData.telepon}</div>
                </div>
              ),
            },
            {
              title: "Sales",
              field: "sales",
              render: (rowData) => (
                <div>
                  <div className="text-xs">
                    {rowData.sales}
                  </div>
                </div>
              ),
            },
            {
              title: "Tanggal",
              field: "tanggal",
              render: (rowData) => (
                <div>
                  <div className="text-xs">{FormatDate(rowData.tanggal)}</div>
                </div>
              ),
            },
            {
              title: "Verified",
              field: "is_verified",
              render: (rowData) => (
                <>
                  {rowData.is_verified ? (
                    <div className="flex gap-1 items-center">
                      <VerifiedUserIcon color="success" />
                      <div className="text-xs ">Verified</div>
                    </div>
                  ) : (
                    <div className="flex gap-1 items-center">
                      <GppBadIcon color="error" />
                      <div className="text-xs ">Not Verified</div>
                    </div>
                  )}
                </>
              ),
            },
            {
              title: "Action",
              field: "action",
              render: (rowData) => (
                <>
                  <div className="flex">
                  {/* {['admin', 'supervisor'].some((i) => getUser()?.roles?.includes(i)) && */}
                    <>
                      {/* <AddSalesModal
                        listData={listSales}
                        email={[rowData.email]}
                        setLoading={setLoading}
                        changeData={setChangeData}
                        dataFromSearch={dataFromSearch}
                        // setSearchData={setSearchData}
                      /> */}
                      <EditCustomer email={rowData.email} />
                    </>
                    {/* } */}
                    <NotesModal
                      email={rowData.email}
                      customer={rowData.name}
                      phone={rowData.rawPhone}
                      setChangeData={setChangeData}
                      notesData={rowData.notes}
                    />
                    <ViewModal email={rowData.email} />
                    <WhatsAppDetail phoneNumber={rowData.telepon} />
                  </div>
                  {/* <a href={`https://desktop-tshoot.ocistok.com/set-xemail?email=${rowData.email}`} 
                    className="block px-2 py-1 rounded-md text-sm text-center text-white bg-blue-500 hover:bg-blue-600 cursor-pointer"
                  >
                    Order Manual
                  </a> */}
                </>
              ),
            },
          ]}
          data={dataNewCustomer}
          options={{
            // exportButton: true,
            pageSizeOptions: [],
            pageSize: Number(isLimit),
            searchFieldPlaceholder: "Search Table",
            isLoading: true,
            selection: ['admin', 'supervisor'].some((i) => getUser()?.roles?.includes(i)),
            showTextRowsSelected: false,
            selectionProps: (rowData) => {
              const checked = rowData.email === null ? {checked: false} : {};
              return {
              disabled: rowData.email === null,
               ...checked
              }
           }
          }}
          onSelectionChange={onSelectionChange}
          actions={['admin', 'supervisor'].some((i) => getUser()?.roles?.includes(i)) && [
            {
              tooltip: 'Add Sales',
              icon: tableIcons.AddCircle,
              onClick: (evt, data) => selectRowsData(evt, data)
            }
          ]}
        />
      </MuiThemeProvider>

      {/* MODAL ADD SALES TO MULTIPLE USER */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showModalAddSalesMulti}
        onClose={() => closeModalAddSalesMulti()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showModalAddSalesMulti}>
          <Box sx={style}>
            <div className="flex justify-end -mt-5">
              <IconButton onClick={() => closeModalAddSalesMulti()} style={{ textAlign: "right" }}>
                <CloseIcon />
              </IconButton>
            </div>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Add Sales
            </Typography>
            <form
              onSubmit={(e) => ['admin', 'supervisor'].some((i) => getUser()?.roles?.includes(i)) && addSalesMultiple(e)}
              className="flex flex-col items-center space-y-2"
            >
              <select
                value={sales}
                onChange={handleChange}
                className="p-2 border border-gray-300 outline-none rounded-md w-full"
              >
                <option value="" disabled>
                  Select Sales
                </option>
                {listSalesData?.map((sales, id) => (
                  <option value={sales} key={id}>
                    {sales.slice(0, 1).toUpperCase() + sales.slice(1)}
                  </option>
                ))}
              </select>
              <button
                className="p-2 bg-blue-300 text-white rounded-md  w-full hover:bg-blue-200 transition-alll"
                type="submit"
              >
                Submit
              </button>
            </form>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default NewCustomerTable;
