import { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Button, CircularProgress, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { sendImageOCS } from "service/api";
import swal from "sweetalert";
import Cookies from "js-cookie";

const ModalOCSAudio = (props) => {
  const {
    open,
    setOpen,
    isLoading,
    setIsLoading,
    openAudio,
    setOpenAudio,
    setGlobalMessages,
  } = props;
  const [audio, setAudio] = useState(null);
  const [selectedAudio, setSelectedAudio] = useState(null);
  const [telpon, setTelpon] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    msg: "Ukuran gambar terlalu besar (maksimal 2MB).",
    error: true,
  });

  const sendMessageAudio = async (e) => {
    e.preventDefault();
    const getTelpon = localStorage.getItem("no-telpon");
    const telpon = JSON.parse(getTelpon);
    var myHeaders = new Headers();
    const Xid = Cookies.get("Session_id");
    const tokenCookies = Cookies.get("oms_token");
    myHeaders.append("Authorization", `Bearer ${tokenCookies}`);
    const randomId = Math.floor(
      Math.random() * (999999 - 100000 + 1) + 100000
    ).toString();
    setGlobalMessages((prev) => [
      {
        id: randomId,
        phone: telpon,
        from: "server",
        to: telpon,
        user: "",
        body: "",
        not_sent: true,
        is_read: false,
        date: new Date().toISOString(),
        media: {
          id: "",
          id_wa: "",
          file_name: selectedAudio,
          mime: "." + audio.name.split(".").pop(),
        },
      },
      ...prev,
    ]);

    var formdata = new FormData();
    formdata.append("phone", telpon);
    formdata.append("file", audio);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    setLoading(true);
    fetch(
      `${process.env.REACT_APP_URL_API_WA_CHAT}/send-message-audio`,
      requestOptions
    ).then((response) => response.json()).then((result) => {
        if (result.status === 200) {
          swal("Success Upload Files", "Success");
        }
        setErrorMessage({
          error: true,
        });
        setSelectedAudio("");
        setAudio("");
      }).catch((error) => console.log("error", error)).finally(() => {
        setLoading(false);
        setOpenAudio(!openAudio);
      });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    maxHeight: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const closeModal = () => {
    setOpenAudio(!openAudio);
    setSelectedAudio("");
    setAudio("");
    setErrorMessage({
      error: true,
    });
  };

  const validasiImage = () => {
    setSelectedAudio("");
    setAudio("");
    swal(errorMessage.msg);
  };

  useEffect(() => {}, [errorMessage]);

  const handleAudioUpload = (event) => {
    setLoading(true);
    const file = event.target.files[0];
    const reader = new FileReader();

    if (file.size > 15 * 1024 * 1024) {
      swal("Ukuran audio terlalu besar (maksimal 30MB).");
      setSelectedAudio("");
      setAudio("");
      setErrorMessage({
        error: true,
      });
    } else {
      setErrorMessage({
        error: false,
      });
      setAudio(file);
    }

    reader.onload = () => {
      setSelectedAudio(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
    setLoading(false);
  };
  return (
    <div>
      <Modal
        open={openAudio}
        onClose={() => setOpenAudio(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description">
        <Box sx={{ ...style }}>
          <div className="w-full flex justify-end">
            <IconButton
              aria-label="close"
              onClick={closeModal}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}>
              <CloseIcon />
            </IconButton>
          </div>

          <form onSubmit={(e) => sendMessageAudio(e)}>
            <div>
              <h3 className="text-lg text-center font-bold">Uploaded Audio:</h3>
            </div>
            {loading ? (
              <div className="w-full h-full my-10 flex items-center justify-center text-white ">
                <CircularProgress size={30} className="text-blue-300" />
              </div>
            ) : (
              <div>
                <div className="w-full flex justify-center">
                  <div className="w-[250px] max-h-[280px] flex flex-col items-center ">
                    {/* <button onClick={handleImageDelete}>delete</button> */}
                    {selectedAudio && (
                      <>
                        <div className="mt-4 flex h-full flex-col items-center w-full">
                          <audio controls className="">
                            <source
                              src={selectedAudio}
                              type="audio/wav"
                              className="w-full bg-slate-400"
                            />
                            Your browser does not support the audio element.
                          </audio>
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <div className="flex flex-col items-center my-10">
                  <label
                    htmlFor="vid_input"
                    className="text-lg text-white bg-gray-400 px-10 py-1 rounded-full cursor-pointer">
                    Select Audio
                  </label>
                  <input
                    className="hidden text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                    type="file"
                    id="vid_input"
                    // ref={fileInputRef}
                    accept="audio/mpeg,audio/wav,audio/ogg"
                    onChange={handleAudioUpload}
                  />
                  <p
                    className="mt-1 text-xs text-gray-500 dark:text-gray-300"
                    id="file_input_help">
                    <b>mp3, wav, ogg (Max 15mb)</b>
                  </p>
                </div>
              </div>
            )}
            <div className="w-full justify-center flex mt-5">
              <div>
                {errorMessage.error === true || loading ? (
                  <button
                    className="bg-gray-300 cursor-default text-white py-3 px-7 rounded-lg"
                    disabled>
                    Send
                  </button>
                ) : (
                  <button className="bg-green-500 hover:bg-green-400 text-white py-3 px-7 rounded-lg">
                    Send
                  </button>
                )}
              </div>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalOCSAudio;
