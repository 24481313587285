import Layout from 'components/Layout';
import UserActivityTable from 'components/LogisticIndo/UserActivity/UserActivityTable';
import { SubRoutesLogisticIndo as SUBROUTES } from 'components/LogisticIndo/SubRoutesLogisticIndo';

const UserActivity = () => {
  return (
    <Layout routes={SUBROUTES()} title="Logistic Indo">
      <UserActivityTable />
    </Layout>
  );
};

export default UserActivity;
