const FilterUserActivity = ({
  page,
  setPage,
  setIsLoading,
  isLoading,
  selectData,
  setSelectData,
  usersList,
  fetchNewDataList,
}) => {
  const handleSelectChange = (e) => {
    const { name, value } = e.target;

    name === 'start' && setPage(1);

    name === 'selected' && setPage(1);
    name === 'selected' && setSelectData({
        ...selectData,
        user: '',
        start: '',
        end: '',
      });

    setSelectData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const maxDate = new Date(
    new Date().getTime() - new Date().getTimezoneOffset() * 60000
  );

  return (
    <>
      <div className='flex justify-between bg-white gap-5 p-4 rounded-md items-center text-sm md:overflow-y-hidden overflow-y-scroll'>
        <div className='flex items-center gap-2'>
          <div className=''>Filter By : </div>
          <select
            value={selectData.selected}
            onChange={handleSelectChange}
            name='selected'
            className='border border-gray-200 p-2 rounded-md focus:outline-blue variant-scroll'>
            <option value='user'>User</option>
            <option value='tanggal'>Tanggal</option>
          </select>

          {selectData?.selected === 'user' && (
            <select
              value={selectData.sales}
              onChange={handleSelectChange}
              name='user'
              className='border border-gray-200 p-2 rounded-md focus:outline-blue variant-scroll'>
              <option value=''>select User</option>
              {usersList?.map((user, index) => (
                <option key={index} value={user}>{user}</option>
              ))}
            </select>
          )}

          {selectData?.selected === 'tanggal' && (
            <>
              <div className='w-60 flex items-center gap-2'>
                <input
                  type='text'
                  placeholder='Start date'
                  name='start'
                  max={maxDate}
                  value={selectData.start}
                  onChange={handleSelectChange}
                  className='p-2 rounded-md w-28 border border-gray-200 focus:outline-blue cursor-pointer'
                  onFocus={(e) => (e.target.type = 'date')}
                  onBlur={(e) => (e.target.type = 'text')}
                />
                <input
                  type='text'
                  name='end'
                  max={maxDate}
                  value={selectData.end}
                  onChange={handleSelectChange}
                  className='p-2 rounded-md border border-gray-200 w-28 focus:outline-blue cursor-pointer'
                  placeholder='End date'
                  onFocus={(e) => (e.target.type = 'date')}
                  onBlur={(e) => (e.target.type = 'text')}
                />
              </div>
            </>
          )}

          <button
            className='bg-blue-500 disabled:bg-gray-500 rounded-md p-2 text-white hover:bg-blue-400 transition-all uppercase'
            onClick={() => !isLoading && fetchNewDataList()}>
            {isLoading ? 'Loading...' : 'Filter'}
          </button>
        </div>
      </div>
    </>
  );
};

export default FilterUserActivity;
